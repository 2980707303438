import React from 'react';
import './loading.css';

function Loader({component: Component, ...props}) {

  return (
    <>
      {
        props.loading ?
        (
          <div className='loading-container'>
            <img src={require('./images/horizontal-loading.gif').default} alt="loading" />
          </div>
        ) :
        (<Component {...props} />)
      }
    </>
  );
}

export default Loader;
