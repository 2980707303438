import BaseService from './BaseService';

class FuelReceiptsService extends BaseService {

  getFuelReceipts(deviceId, startDate, endDate) {
    return this.axiosInstance.get(`/fuel_receipts/get/${deviceId}/?start_date=${startDate}&end_date=${endDate}&nocache=true`);
  }

  saveFuelReceipt(deviceId, receiptDate, receiptVolume, fuelPrice) {
    return this.axiosInstance.get(`/fuel_receipts/insert/${deviceId}/?receipt_date=${receiptDate}&receipt_volume=${receiptVolume}
    &fuel_price=${fuelPrice}`);
  }

  deleteFuelReceipt(receiptId) {
    return this.axiosInstance.get(`/fuel_receipts/delete/${receiptId}/?`);
  }

}

export default FuelReceiptsService;