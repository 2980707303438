import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';

import {Map, View, Feature} from 'ol';
import {Vector as VectorLayer} from 'ol/layer';
import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
// import SourceOSM from 'ol/source/OSM';
import {fromLonLat, transform} from 'ol/proj';
import {ZoomSlider} from 'ol/control';
import {XYZ, Vector as VectorSource, Cluster} from 'ol/source';
import {Icon, Style, Fill, Stroke, Circle as CircleStyle, Text} from 'ol/style';
import {LineString, Point, Polygon} from 'ol/geom';
import Overlay from 'ol/Overlay';
import DeviceOptionsService from '../../services/DeviceOptions';


import LayerSwitcher from 'ol-layerswitcher';
import MeasureControl from './measureControl';
import DrawControl from './drawControl';


class HMap {
    
    constructor(longlat) {
      this.layerGroup = null;
      this.layers = [];
      this.longlat = longlat;
      this.map = null;
      this.vectorSource = null;
      this.clusterSource = null;
      this.vectorLayer = null;
      this.popup_container = null;
      this.popup_content = null;
      this.popup_closer = null;
      this.overlay = null;
      this.selectedDevice = null;
      this.handlers = {
        "onareadrawend": () => {},
        "onpointdrawend": () => {}
      }
    }
  
    initMap(zoom=17, cluster=false, options) {
      let _options = options || {
        measure: false,
        drawArea: false,
        drawPoint: false,
        deviceId: null
      };

      this.popup_container = document.getElementById('popup');
      this.popup_content = document.getElementById('popup-content');
      this.popup_closer = document.getElementById('popup-closer');

      this.overlay = new Overlay({
        element: this.popup_container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250
        }
      });

      this.popup_closer.onclick = function() {
        this.overlay.setPosition(undefined);
        this.popup_closer.blur();
        return false;
      }.bind(this);

      this.vectorSource = new VectorSource();
      this.clusterSource = new Cluster({
        distance: 5,
        source: this.vectorSource,
      });

      var styleCache = {};
      var style = null;
      this.vectorLayer = new VectorLayer({
        source: this.clusterSource,
        style: function(feature) {
          var size = 0;
          if (feature.get('features')) {
             size = feature.get('features').length;
            if (size < 2) {
              return feature.get('features')[0].getStyle();
            }
          }

          if (cluster) {
            style = styleCache[size];
            if (!style) {
              style = new Style({
                text: new Text({
                  text: size.toString(),
                  scale: 3,
                  fill: new Fill({
                    color: '#fff',
                  }),
                  stroke: new Stroke({
                    color: '#176288',
                    width: 3
                  }),
                }),
                image: new CircleStyle({
                  radius: 20,
                  fill: new Fill({
                    color: '#3399CC',
                  })
                }),
              });
              styleCache[size] = style;
            }
            return style;
          }
          else {
            style = new Style({
              fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)'
              }),
              stroke: new Stroke({
                color: '#ffcc33',
                width: 2
              }),
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color: '#ffcc33'
                })
              })
            });
            return style;
          }
        }
      });

      this.layerGroup = new LayerGroup({
        'title': 'Maps',
        layers: []
      });

      this.setLayer(_options.deviceId);

      this.map = new Map({
        target: 'map',
        view: new View({
          center: fromLonLat(this.longlat),
          zoom: zoom,
          maxZoom: 18,
          minZoom: 2
        }),
        overlays: [this.overlay],
        layers: [ this.layerGroup ]
      });
      
      let layerSwitcher = new LayerSwitcher();
      this.map.addControl(layerSwitcher);

      let zoomslider = new ZoomSlider();
      this.map.addControl(zoomslider);
      
      if (_options.measure) {
        this.map.addControl(new MeasureControl(this.vectorSource));
      }

      if (_options.drawArea) {
        let drawControl = new DrawControl(this.vectorSource, {drawType: "Polygon"});
        drawControl.register("onareadrawend", this.handlers["onareadrawend"]);
        this.map.addControl(drawControl);
      }

      if (_options.drawPoint) {
        let drawControl = new DrawControl(this.vectorSource, {drawType: "Point", imgSrc: options.imgSrc});
        drawControl.register("onpointdrawend", this.handlers["onpointdrawend"]);
        this.map.addControl(drawControl);
      }
      
      if (cluster)
        this.vectorLayer.setSource(this.clusterSource);
      else
        this.vectorLayer.setSource(this.vectorSource);

      this.map.addLayer(this.vectorLayer);

      // events
      this.map.on('click', function(evt) {
        var feature = evt.map.forEachFeatureAtPixel(evt.pixel, function(feature) {
          return feature;
        });

        if (HMap.isCluster(feature)) {
          evt.map.getView().setZoom(evt.map.getView().getZoom() + 3);
          
          let locations = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
          evt.map.getView().setCenter(fromLonLat(locations));
        }
        else {
          if (feature) {
            feature = 'features' in feature.getProperties() ? feature.get('features')[0] : feature;
            
            let markerType = feature.get("markerType");
            let device_name = feature.get("name") || "";
            let device_id = feature.get("device_id") || 0;

            let locations = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
            
            let checked = "";
            if (this.selectedDevice && this.selectedDevice.device_id === device_id) {
              checked = "checked='checked'";
            }
            
            let speed_km = feature.get("speed_km") || "";
            let current_kilometers = feature.get("current_kilometers") || "";
            let voltage = feature.get("input_1_voltage") || "";
            let battery_voltage = feature.get("battery_voltage") || "";
            let latest_geocode_sent_at = feature.get("latest_geocode_sent_at") || "";
            let dec_lat = feature.get("dec_lat") || "";
            let dec_long = feature.get("dec_long") || "";
            let waiting_time = feature.get("waiting_time") || "";
            let driver_name = feature.get("driver_name") || "";
            let driver_last_name = feature.get("driver_last_name") || "";
            let driver_tel = feature.get("driver_tel") || "";
            let driver_email = feature.get("driver_email") || "";
            let address = feature.get("address") || "";

            let device_name_text = device_name ? `<b>${device_name}</b><br />` : "";
            let speed_km_text = speed_km ? `<b>Hız: </b> ${speed_km} km/sa<br />` : "";
            let current_kilometers_text = current_kilometers ? `<b>Toplam km: </b> ${current_kilometers.toFixed(2)} km<br />` : "";
            let voltage_text = voltage ? `<b>Akü Voltaj: </b> ${voltage}<br />` : "";
            let battery_voltage_text = battery_voltage ? `<b>Batarya: </b> ${battery_voltage}<br />` : "";
            let latest_geocode_sent_at_text = latest_geocode_sent_at ? `<b>Sinyal Zamanı: </b> ${latest_geocode_sent_at}<br />` : "";
            let dec_lat_text = dec_lat ? `<b>Lat: </b> ${dec_lat}<br />` : "";
            let dec_long_text = dec_long ? `<b>Lon: </b> ${dec_long}<br />` : "";
            let waiting_time_text = waiting_time ? `<b>Bekleme Süresi: </b> ${waiting_time}<br />` : "";
            let driver_name_text = driver_name ? `<b>Sürücü</b><br /><b>Ad Soyad: </b> ${driver_name} ${driver_last_name}<br />` : "";
            let driver_tel_text = driver_tel ? `<b>Telefon: </b> ${driver_tel}<br />` : "";
            let driver_email_text = driver_email ? `<b>E-Mail: </b> ${driver_email}<br />` : "";
            let address_text = address ? `<b>Adres: </b> ${address}` : "";
            let buttons_text = markerType === "all_devices" ? `Yakın Takip: <input type="checkbox" ${checked} id="ckSelectDevice" />` : "";

            this.popup_content.innerHTML = `
            ${device_name_text}
            ${speed_km_text}
            ${current_kilometers_text}
            ${voltage_text}
            ${battery_voltage_text}
            ${latest_geocode_sent_at_text}
            ${dec_lat_text}
            ${dec_long_text}
            ${waiting_time_text}
            ${driver_name_text}
            ${driver_tel_text}
            ${driver_email_text}
            ${address_text}
            <hr />
            ${buttons_text}
            `;

            let ckSelectDevice = document.getElementById('ckSelectDevice');
            if (ckSelectDevice) {
              ckSelectDevice.addEventListener("change", event => {
                if (event.target.checked) {
                  this.selectedDevice = {
                    device_id: device_id,
                  };
                  evt.map.getView().setZoom(15);
                }
                else
                  this.selectedDevice = false;

                this.overlay.setPosition(undefined);
                this.popup_closer.blur();
              });
            }

            if (markerType) {
              this.overlay.setPosition(fromLonLat(locations));
            }
          }
        }
      }.bind(this));
    }

    static isCluster(feature) {
      if (!feature || !feature.get('features')) { 
            return false; 
      }
      return feature.get('features').length > 1;
    }

    register(name, _function) {
      this.handlers[name] = _function;
    }
  
    getMap() {
      return this.map;
    }
    
    getVectorSource() {
      return this.vectorSource;
    }
    
    getVectorLayer() {
      return this.vectorLayer;
    }

    setLayer(deviceId, allDevices=false) {
      const deviceOptionsService = new DeviceOptionsService();

      const basarTilesSource = new XYZ({
        url: "https://www.gps-takip-sistemi.com/osm/tiles.php?z={z}&x={x}&y={y}&r=basar"
      });      

      const basarTileLayer = new LayerTile({
        title: 'Basarsoft',
        type: 'base',
        source: basarTilesSource
      });

      const horizontTilesSource = new XYZ({
        url: "https://jumbo-fahrrad-ortung.de/osm/{z}/{x}/{y}.png"
      });

      const horizontTileLayer = new LayerTile({
        title: 'Horizont Map',
        type: 'base',
        source: horizontTilesSource
      });

      this.layerGroup.getLayersArray().forEach((item) => {
        this.layerGroup.getLayers().remove(item);
      });

      if (allDevices) {
        deviceOptionsService.getAllDeviceOptions(2126).then((response) => {
          if (response.data) {
            if (response.data.result === "ok") {
              const basarMap = response.data;
              if (basarMap) {
                if (basarMap['result'] === "ok") {
                  this.layerGroup.getLayers().push(horizontTileLayer);
                  this.layerGroup.getLayers().push(basarTileLayer);
                }
                else {
                  this.layerGroup.getLayers().push(horizontTileLayer);
                }
              }
              else {
                this.layerGroup.getLayers().push(horizontTileLayer);
              }
            }
            else {
              this.layerGroup.getLayers().push(horizontTileLayer);
            }
          }
          else {
            this.layerGroup.getLayers().push(horizontTileLayer);
          }
        });
      }
      else {
        if (deviceId) {
          deviceOptionsService.getByDeviceOptionId(deviceId, 2126).then((response) => {
  
            const basarMap = response.data;
            if (basarMap) {
              if (basarMap['result'] === "ok") {
                this.layerGroup.getLayers().push(horizontTileLayer);
                this.layerGroup.getLayers().push(basarTileLayer);
              }
              else {
                this.layerGroup.getLayers().push(horizontTileLayer);
              }
            }
            else {
              this.layerGroup.getLayers().push(horizontTileLayer);
            }
            
          });
        } else {
          this.layerGroup.getLayers().push(horizontTileLayer);
        }
      }
    }

    calculateAngle(startNode, nextNode, alwaysUp) {
        if ((!startNode) || (!nextNode))
            return false;
        
        let x = (startNode[0] - nextNode[0]);
        let y = (startNode[1] - nextNode[1]);
        let angle = Math.atan(x/y);
        if (!alwaysUp) {
            angle = y > 0 ? angle + Math.PI : x < 0 ? angle + Math.PI*2 : angle;
        }
        return angle;
    }

    addArrow(longlat, iconPath, rotation, data={}) {
      let horizont = new Feature({
          geometry: new Point(fromLonLat(longlat))
        });
        
        let iconStyle = new Style({
            image: new Icon({
                
                src: iconPath,
                rotation: rotation
            })
        });
        
        data['markerType'] = "arrow";
        horizont.setStyle([iconStyle]);
        
        horizont.setProperties(data);
        this.vectorSource.addFeature(horizont);
        return horizont;
    }

    addStop(longlat, iconPath, data={}) {
      let horizont = new Feature({
          geometry: new Point(fromLonLat(longlat))
        });
        
        let iconStyle = new Style({
            image: new Icon({
                src: iconPath,
            })
        });
        
        data['markerType'] = "stop";
        horizont.setStyle([iconStyle]);
        
        horizont.setProperties(data);
        this.vectorSource.addFeature(horizont);
        return horizont;
    }

    removeMarker(marker) {
      try {
        this.vectorSource.removeFeature(marker);
      } catch {}
    }

    addPolygon(coordinates, zoneName="") {
      let polygon = new Polygon(coordinates);
      polygon.transform('EPSG:4326', 'EPSG:3857');

      let feature = new Feature(polygon);

      let polygonStyle = new Style({
        text: new Text({
          text: zoneName,
          scale: 2,
          fill: new Fill({
            color: '#000',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2
          }),
          overflow: true
        }),
        stroke: new Stroke({
          color: '#176288',
          lineDash: [4],
          width: 1
        }),
        fill: new Fill({
          color: 'rgba(23, 98, 136, 0.6)'
        })
      });

      feature.setStyle([polygonStyle]);

      this.vectorSource.addFeature(feature);
    }

    addMarker(longlat, iconPath, data={}, markerType="") {
      let horizont = new Feature({
        geometry: new Point(fromLonLat(longlat))
      });

      let _text = ``;
      
      if ('name' in data) {
        _text += `${data['name']}`;
      }
      
      // if ('latest_geocode_sent_at' in data) {
      //   _text += ` ${data['latest_geocode_sent_at']}`;
      // }

      // if ('speed_km' in data) {
      //   _text += ` - (${data['speed_km']} km/h)`;
      // }

      // if ('total_km' in data) {
      //   _text += `\r\n Total km: ${data['total_km']} km`;
      // }
        
      let iconStyle = new Style({
          image: new Icon({
              anchor: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              src: iconPath
          }),
          text: new Text({
            text: _text,
            scale: 1.3,
            backgroundFill: new Fill({
              color: data.blink ? '#FFF' : 'rgba(255, 255, 255, 0.7)',
            }),
            padding: [6, 5, 3, 8],
            fill: new Fill({
              color: data.blink ? '#688509' : '#176288',
            }),
            backgroundStroke: new Stroke({
              color: data.blink ? '#688509' : '#176288',
              width: 1
            }),
            offsetY: 30
          }),
          zIndex: 10001,
      });

      let circleStyle = new Style({
        image: new CircleStyle({
          radius: 25,
          stroke: new Stroke({
            color: data.blink ? '#688509' : '#3399CC',
            width: data.blink ? 6 : 3
          })
        })
      });
        
      if (data.ignition_status === "on") {
        horizont.setStyle([iconStyle, circleStyle]);
      }
      else {
        horizont.setStyle([iconStyle]);
      }
      
      data['markerType'] = markerType;
      horizont.setProperties(data);
      this.vectorSource.addFeature(horizont);

      if (this.selectedDevice && this.selectedDevice.device_id === data.device_id) {
        this.map.getView().setCenter(fromLonLat([data.dec_long, data.dec_lat]));
      }

      return horizont;
    }

    setMarkerProperties(marker, data) {
      marker.setProperties(data);
    }

    setMarkerText(marker, text) {
      let _style = marker.getStyle();
      if (_style.length > 0) {
        _style[0].getText().setText(text);
      }
    }

    addLocation(longlat, iconPath, data={}) {
      let horizont = new Feature({
        geometry: new Point(fromLonLat(longlat))
      });

      let iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 1],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          scale: 0.7,
          src: iconPath
        }),
        text: new Text({
          text: `${data['locationName']}`,
          scale: 1.3,
          backgroundFill: new Fill({
            color: 'rgba(255, 255, 255, 0.7)',
          }),
          padding: [6, 5, 3, 8],
          fill: new Fill({
            color: '#176288',
          }),
          backgroundStroke: new Stroke({
            color: '#176288',
            width: 1
          }),
          offsetY: 33
        })
      });

      horizont.setStyle([iconStyle]);
      this.vectorSource.addFeature(horizont);

      return horizont;
    }

    setMarkerPosition(markerFeature, longlat) {
      markerFeature.setGeometry(new Point(fromLonLat(longlat)));
    }
    
    setMarkerRotation(markerFeature, angle) {
      markerFeature.getStyle().forEach(element => {
        element.getImage().setRotation(angle);
      });
    }

    fromLonLat(longlat) {
      return fromLonLat(longlat);
    }

    setCenter(longlat, animate=false) {
      if (animate) {
        this.map.getView().animate({
          center: fromLonLat(longlat),
          duration: 1000
        });
      }
      else {
        this.map.getView().setCenter(fromLonLat(longlat));
      }
    }

    fitMap(maxZoom = 17) {
      try {
        this.map.getView().fit(this.vectorSource.getExtent(), {size:this.map.getSize(), maxZoom:maxZoom});
        this.map.getView().setZoom(this.map.getView().getZoom() - 0.2);
      }
      catch {}
    }

    setZoom(zoom) {
      let _zoom = zoom > 18 ? 18 : zoom;
      
      this.map.getView().setZoom(_zoom);
    }

    createLineString(routes = []) {
      return new LineString(routes);
    }

    createLineFeature(width = 4, color = 'rgba(50, 0, 255, .6)') {
      let feature = new Feature();
      feature.setStyle(new Style({
        stroke : new Stroke({
          color : color,
          width: width,
          // lineDash: [.5, 20]
        })
      }));

      this.vectorSource.addFeature(feature);

      return feature;
    }
}

export default HMap;