import BaseService from './BaseService';

class SignalService extends BaseService {

  getSignalAddress(deviceId, lat, lon) {
    return this.axiosInstance.get(`/adress/coordinate_to_adress/${deviceId}/?long=${lon}&lat=${lat}`, {cancelToken: this.cancelTokenSource.token});
  }

  getSignals(deviceId, filterDate) {
    let url = `device/signals/?devices=[${deviceId}]&start_date=${filterDate}%2000:00:00&end_date=${filterDate}%2023:59:59&display=5000`;
    return this.axiosInstance.get(url, {cancelToken: this.cancelTokenSource.token});
  }

  getSignalsDateRange(deviceId, startDate, endDate) {
    let url = `device/signals/?devices=[${deviceId}]&start_date=${startDate}&end_date=${endDate}&display=5000`;
    return this.axiosInstance.get(url, {cancelToken: this.cancelTokenSource.token});
  }

  getVehicleStopInformation(lat,long,signal_type,geocode_sent_at,device_id){
    let url = `adress/signals_popup_v2/${device_id}/?lat=${lat}&long=${long}&signal_type=${signal_type}&geocode_sent_at=${geocode_sent_at}`;
    return this.axiosInstance.get(url, {cancelToken: this.cancelTokenSource.token});
  }

  getOptimizedRoute(deviceId, filterDate) {
    let url = `device/${deviceId}/optimized_route/?start_date=${filterDate}%2000:00:00&end_date=${filterDate}%2023:59:59`;
    return this.axiosInstance.get(url, {cancelToken: this.cancelTokenSource.token});
  }

  getLocation(deviceId) {
    return this.axiosInstance.get(`/live/${deviceId}/`, {cancelToken: this.cancelTokenSource.token});
  }

  //signal_id'si verilen sinyali döndürür
  getSignalBySignalId(deviceId,signalId) {
    return this.axiosInstance.get(`/device/${deviceId}/signal/${signalId}/?`, {cancelToken: this.cancelTokenSource.token});
  }

  getDeviceSignalsWithAdr(device_id, start_date, end_date, offset, limit, onlyStartStop, orderBy) {
    if (typeof(start_date) === 'undefined')start_date = '';
    if (typeof(end_date) === 'undefined')end_date = '';
    if (typeof(offset) === 'undefined')offset = 0;
    if (typeof(limit) === 'undefined')limit = 25;
    if (typeof(onlyStartStop) === 'undefined')onlyStartStop = 0;
    if (typeof(orderBy) === 'undefined') orderBy = "DESC";
    return this.axiosInstance.get(`/device/${device_id}/signals_with_adress/?start_date=${start_date}&end_date=${end_date}&offset=${offset}&display=${limit}&only_start_stop=${onlyStartStop}&order_by=${orderBy}`, {cancelToken: this.cancelTokenSource.token});
  }

}

export default SignalService;