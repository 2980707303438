import React from 'react';
import './loading.css';

function Loading(props) {

  return (
    <div className='loading-container'>
      <img src={require('./images/updating.gif').default} alt="updating" />
    </div>
  );
}

export default Loading;
