import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import MessageWindow from "../Commons/messageWindow";

function DataDelete(props) {

	const [confirm, setConfirm] = useState(false);
	const [showMessage, setShowMessage] = useState({
		title: '',
		message: '',
		status: false
	});

	const handleSubmit = () => {
		if (confirm) {
			props.history.push('/data-delete-device/');
		} else {
			setShowMessage({
				title: <h5>Error</h5>,
				message: 'Lütfen metni okuduğunuzu onaylayınız. Araç seçimi sonraki adımda yapılacaktır.',
				status: true
			})
		}
	};

	useEffect(() => {
	}, []);


	return (
		<div className="row mt-2">
			<div className="col-md-8">
				<h4 id="section-1"><FormattedMessage id='GENERAL.DESCRIPTION'/></h4>
				<p>
					Değerli Müşterimiz,<br/><br/>
					Veri silme özelliği kullanıldığında aşağıdaki verileri tamamen silmiş olacağınızdan bu fonksiyonun dikkatli kullanılmasını öneririz.<br/>
					Seçilen tarihten önceki aşağıda yazılı olan tüm veriler sunucularımızdan tamamı ile silinecektir.
					Bu nedenle, onayınız ile silinen veriler için firmamız herhangi bir yükümlülük kabul etmemektedir.<br/>
					Onay kodu, cihazları satın alırken verdiğiniz mail adresine gönderilecektir.<br/>
					Gönderilen bu onay kodunu veri silme ikinci aşama kod kısmına girip onay vermeniz gerekmektedir.
					Veri silme için gönderilen kod 10 dakika için geçerli olmakla birlikte 10 dakikadan sonra yeniden kod talebinde bulunmanız gerekecektir.
				</p>
				<h4 id="section-2"><FormattedMessage id='DATA_DELETE.DATA_TO_BE_DELETED'/></h4>
				<div>
					Silinecek ve bir daha ulaşılamayacak veriler;<br/><br/>
					<ul>
						<li>Cihazdan gelen sinyalleriniz (rota)</li>
						<li>Kontak açma kapama sinyalleriniz</li>
						<li>Araç servisi üzerinden saklanmış sinyalleriniz</li>
						<li>Elektronik raporlar (saatlik, günlük, aylık, yıllık ve rota raporlarınızı oluşturduğumuz bilgiler)</li>
						<li>Sürücülere ait elektronik raporlar</li>
						<li>Hırsızlık koruması alan giriş/çıkış raporları</li>
						<li>Hırsızlık koruması izin verilen saatler dışında çalışma raporları</li>
						<li>Hırsızlık koruması 2 raporları</li>
						<li>Oto pilot hız bilgilendirme raporları</li>
						<li>Gönderilen mobil bildirimler</li>
					</ul>
				</div>
				<h4 id="section-3"><FormattedMessage id='DATA_DELETE.CONFIRMATION'/></h4>
				<p>
					Silinecek cihaz seçimi bir sonraki adımda yapılacaktır.
				</p>
				<span style={{width: '33%', float: 'left'}}>
					<div className='form-check'>
						<input checked={confirm}
									 onChange={(event) => setConfirm(event.target.checked)}
									 type="checkbox"
									 className="form-check-input"/>
						<label className="form-check-label">Okudum, anladım onaylıyorum.</label>
					</div>
					<button onClick={handleSubmit} type="button" className="btn btn-primary btn-block mt-3">
						<FormattedMessage id='TECHNICAL_SUPPORT.SEND'/>
					</button>
				</span>
			</div>
			<MessageWindow
				size='lg'
				show={showMessage.status}
				onHide={() => setShowMessage({...showMessage, status: false})}
				title={showMessage.title}
				message={showMessage.message}
			/>
		</div>
	);

}

export default DataDelete;
