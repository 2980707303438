import BaseService from './BaseService';

class AutoPilotService extends BaseService {

  getAutoPilot(deviceId) {
    return this.axiosInstance.get(`/auto_pilot/${deviceId}/?`, {cancelToken: this.cancelTokenSource.token});
  }

  saveAutoPilot(deviceId, data) {
    return this.axiosInstance.post(`/auto_pilot/update/${deviceId}/?`, data);
  }

}

export default AutoPilotService;