import React, {useState, useEffect} from 'react';

import SignalsService from '../../services/Signals';

function NoAddress(props) {
  const hideStatus = props.hideStatus;

  return (
    <div>
      {
        ((hideStatus) ?
            (<span>Adres Görüntülenemez</span>)
            :
            (<span>No GPS</span>)
        )
      }
    </div>
  );
}


function Address(props) {
  const lat = props.lat;
  const lon = props.lon;
  const deviceId = props.deviceId;
  const address_key = lat + "_" + lon;

  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const signalService = new SignalsService();

  const getAddress = async () => {
    await signalService.getSignalAddress(deviceId, lat, lon)
      .then((response) => {
        sessionStorage.setItem(address_key, response.data.address);
        setAddress(response.data.address);
        setIsLoading(false);
      });
  };

  
    useEffect(() => {

      if(sessionStorage.getItem(address_key) === null){
        getAddress();
      }
      else{
        setAddress(sessionStorage.getItem(address_key));
        setIsLoading(false);
      }
  
      // unmount
      return () => {
        signalService.getCancelToken().cancel();
      }
    }, [deviceId]);
 
  

  return (
    <div>
      {
        ((isLoading) ?
            (<img src={require("./images/gps_loading.gif").default} style={{marginTop: "5px", width: "28", height: "28"}} alt="address"/>)
            :
            (<span style={{fontSize:12}}>{address}</span>)
        )
      }
    </div>
  );
}

function SignalAddress(props) {
  const lat = props.lat;
  const lon = props.lon;
  const deviceId = props.deviceId;
  const noGps = props.noGps;
  const hideStatus = props.hideStatus;

  return (
    <div>
      {
        ((noGps || hideStatus ) ?
            (<NoAddress noGps={noGps} hideStatus={hideStatus}/>)
            :
            (<Address deviceId={deviceId} lat={lat} lon={lon}/>)
        )
      }
    </div>
  );
}

export default SignalAddress;
