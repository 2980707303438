import React, {useState} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {saveAs} from 'file-saver';
import Menu from '../Menu';
import MessageWindow from '../../Commons/messageWindow';
import Loading from '../../Commons/loading';
import Moment from 'moment';
import {tripTypes, toFixed, getFormattedDate, getTime} from '../../Assets/utils';
import '../index.css';

import SignalService from '../../../services/Signals';
import ReportService from '../../../services/Reports';
import UserService from '../../../services/Users';
import CarService from '../../../services/Cars';
import Export from '../../../services/Export';

const totalInitialState = {
	km: 0,
	office_km: 0,
	private_km: 0,
	home_km: 0,
	office_km_percent: 0,
	private_km_percent: 0,
	home_km_percent: 0
};

const mileageInitialState = {
	start: 0,
	end: 0
};

const messageInitialState = {
	message: '',
	title: '',
	status: false
};

function RouteReport(props) {

	const deviceId = props.match.params.deviceId;

	const [trips, setTrips] = useState([]);
	const [tripsCanceled, setTripsCanceled] = useState([]);
	const [favorites, setFavorites] = useState([]);
	const [onlyService, setOnlyService] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [total, setTotal] = useState(totalInitialState);
	const [mileage, setMileage] = useState(mileageInitialState);
	const [showMessage, setShowMessage] = useState(messageInitialState);

	const signalService = new SignalService();
	const reportService = new ReportService();
	const userService = new UserService();
	const carService = new CarService();
	const exportService = new Export();

	// data fetch
	let name = '';
	const handleFilter = async (name, id, start_date, end_date) => {
		setLoading(true);

		try {
			await Promise.all([
				reportService.getDeviceRouteTrips(id, start_date, end_date),
				userService.getUserFavouriteTrips()
			])
				.then(([tripResponse, favoriteResponse]) => {
					if (tripResponse.data.trips) {
						setRouteTrips(tripResponse.data.trips, favoriteResponse.data); // set trips here
						calculateTotal(tripResponse.data.trips); // set total here
					}
					if (tripResponse.data.trips_canceled) setTripsCanceled(tripResponse.data.trips_canceled);
					if (favoriteResponse.data.length > 0) setFavorites(favoriteResponse.data); // same for all devices

					// mileage requests - reset to default if no trips for device
					if (tripResponse.data.trips.length > 0) {
						getMileages(id, tripResponse.data.trips[0], tripResponse.data.trips[tripResponse.data.trips.length - 1]); // loading false

					} else {
						setTotal(totalInitialState);
						setLoading(false);
					}

				})

		} catch (error) {
			setLoading(false);

		}
	};

	// mileage calls
	const getMileages = async (deviceId, firstTrip, lastTrip) => {
		await Promise.all([
			signalService.getSignalBySignalId(deviceId, firstTrip.start_signal_id),
			signalService.getSignalBySignalId(deviceId, lastTrip.end_signal_id),
			carService.getCar(deviceId)
		])
			.then(([startSignalResponse, endSignalResponse, carResponse]) => {
				setMileage({
					start: toFixed(startSignalResponse.data.mileage + carResponse.data.car_mileage_at_install, 2),
					end: toFixed(endSignalResponse.data.mileage + carResponse.data.car_mileage_at_install, 2)
				});
				setLoading(false);

			});

	};

	// sum trip kilometers by private_trip
	const calculateTotal = (trips) => {
		let _total = totalInitialState;

		for (let i = 0; i < trips.length; i++) {
			_total.km += trips[i]['lenght_km'];

			if (trips[i]['private_trip'] === 1 || trips[i]['private_trip'] === 0) {
				_total.office_km += trips[i]['lenght_km'];
			} else if (trips[i]['private_trip'] === 2) {
				_total.private_km += trips[i]['lenght_km'];
			} else if (trips[i]['private_trip'] === 3) {
				_total.home_km += trips[i]['lenght_km'];
			}
		}

		_total.office_km = Number(toFixed(_total.office_km, 2));
		_total.private_km = Number(toFixed(_total.private_km, 2));
		_total.home_km = Number(toFixed(_total.home_km, 2));
		_total.km = Number(toFixed(_total.km, 2));

		if (_total.km > 0) {
			_total.office_km_percent = Number(toFixed(((_total.office_km * 100) / _total.km), 1));
			_total.private_km_percent = Number(toFixed(((_total.private_km * 100) / _total.km), 1));
			_total.home_km_percent = Number(toFixed(((_total.home_km * 100) / _total.km), 1));
		}

		setTotal({..._total});
	};

	// edit and set fetched data as usable in the view
	const setRouteTrips = function (trips, userFavorites) {
		for (let i = 0; i < trips.length; i++) {

			// check if trip jump to next day
			if (getFormattedDate(trips[i]['start_time'], 'DD-MM-YYYY') !== getFormattedDate(trips[i]['end_time'], 'DD-MM-YYYY')) {
				trips[i]['jump'] = true;
				trips[i]['jump_class'] = (i === 0) ? 'danger' : '';
			}

			// set driver name
			for (let k = 0; k < props.drivers.length; k++) {
				if (props.drivers[k].driver_id === trips[i].driver_id) {
					trips[i].driver_name = props.drivers[k].driver_first_name + ' ' + props.drivers[k].driver_last_name;
					break;
				}
			}

			// set favorites
			trips[i]['favorites'] = [];
			for (let k = 0; k < userFavorites.length; k++) {
				if (trips[i]['end_address'] === userFavorites[k]['end_address']) {
					trips[i]['favorites'].push(userFavorites[k]);
					// private_trip value changes when favorite detected
					trips[i]['private_trip'] = userFavorites[k].private_trip;
				}
			}

			// if there is at least one favorite
			if (trips[i]['favorites'][0] !== undefined) {
				trips[i]['selected_favorite'] = trips[i]['favorites'][0];
			}

			// if there is favorite, assign visited and backgrounds
			if (trips[i]['visited'].length === 0) {
				if (trips[i].favorites.length === 1) {
					trips[i]['visited'] = trips[i]['favorites'][0].visited;
					trips[i]['visited_class'] = 'bg1';
				}

				if (trips[i].favorites.length > 1) {
					trips[i]['visited_class'] = 'bg2';
				}
			}

			// create a visited old value to be able to understand changes made on visited during update
			trips[i]['visited_old'] = trips[i]['visited'];

		}

		setTrips(trips);
	};

	// display details of selected favorite trip on menu component
	const favoriteDetails = (selectedFavorite) => {

		if (selectedFavorite) {
			setShowMessage({
				message:
					<>
						<table className="table table-hover">
							<tbody>
							<tr>
								<td width="120"><b><FormattedMessage id="REPORTS.TRIP_TYPE"/> :</b></td>
								<td>{tripTypes.find(type => type.value === selectedFavorite.private_trip).label}</td>
							</tr>
							<tr>
								<td><b><FormattedMessage id="REPORTS.VISITED"/> :</b></td>
								<td>{selectedFavorite.visited}</td>
							</tr>
							<tr>
								<td><b><FormattedMessage id="GENERAL.ADDRESS"/> :</b></td>
								<td>{selectedFavorite.end_address}</td>
							</tr>
							<tr>
								<td><b><FormattedMessage id="REPORTS.POST_CODE"/> :</b></td>
								<td>{selectedFavorite.end_zip}</td>
							</tr>
							<tr>
								<td><b><FormattedMessage id="GENERAL.NR"/> :</b></td>
								<td>{selectedFavorite.end_no}</td>
							</tr>
							</tbody>
						</table>
						<div className="form-group">
							<button type="button" className="btn btn-danger btn-sm pull-right"
											onClick={() => deleteSelectedFavorite(selectedFavorite)}>
								<FormattedMessage id="GENERAL.DELETE"/> 
							</button>
						</div>
					</>,
				title: <FormattedMessage id="REPORTS.FAVORITES"/>,
				status: true,
			})
		}

	};

	// delete selected favorite trip on menu component
	const deleteSelectedFavorite = async (selectedFavorite) => {
		try {
			setLoading(true);

			await userService.deleteUserFavouriteTrip(selectedFavorite.visited, selectedFavorite.end_address)
				.then(async (response) => {
					// re-call the favorite trips to remove the deleted
					await userService.getUserFavouriteTrips().then((response) => {
						setFavorites(response.data);
						setLoading(false);
						setShowMessage({...showMessage, status: false});
						// need to re-calculate the route data to remove the deleted
						setRouteTrips(trips, response.data);
					});
				});

		} catch (e) {
			setShowMessage({
				title: <h5>Error</h5>,
				message: <h5><FormattedMessage id="GENERAL.ERROR"/></h5>,
				status: true
			})
		}
	};

	// onchange handler
	const handleTripChange = (event, index) => {
		trips[index][event.target.name] = event.target.value;
		setTrips([...trips]);
	};

	// onchange handler
	const handleChangeOnlyService = (event) => {
		setOnlyService(event.target.checked);
	};

	// onclick handler
	const handleUpdateTrip = async (trip) => {
		setLoading(true);

		try {
			var data = {};

			data.end_address = trip.end_address;
			data.private_trip = trip.private_trip;
			data.driver_id = trip.driver_id;
			data.start_no = trip.start_no;
			data.end_no = trip.end_no;
			data.about = trip.about;
			data.visited = trip.visited;

			// if there is visited_old value different than current visited, save trip as canceled trip
			if (trip.visited_old.toString().length > 0 && trip.visited.toString() !== trip.visited_old.toString()) {
				data.canceled_trip = 1;
			} else {
				data.canceled_trip = 0;
			}

			await reportService.updateDeviceTrips(trip.trip_id, data)
				.then(async (response) => {
					// re-call the favorite trips in case a new one is added
					await userService.getUserFavouriteTrips()
						.then((favoriteResponse) => {
							setFavorites(favoriteResponse.data);
							setRouteTrips(trips, favoriteResponse.data);
							setLoading(false);
						});
				})

		} catch (error) {
			setLoading(false);

		}
	};

	// onclick handler
	const handleExport = (selectedDateStart, selectedDateEnd, fileExtension) => {
		const start = Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss");
		const end = Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss");
		const data = [...trips, {dayTotal: total}];

		exportService.exportRouteReport(deviceId, start, end, data, fileExtension)
			.then((response) => {
					let blob = new Blob([response.data]);
					saveAs(blob, `Rota_Raporu_${deviceId}.` + fileExtension);
			});
	};

	return (
		<>
			{isLoading ? (<Loading/>) : null}
			<div className="reports">
				<Menu deviceId={deviceId}
							history={props.history}
							onFilter={handleFilter}
							only_private={onlyService}
							routerParams={props.match}
							mainReportType={'route'}
							userFavorites={favorites}
							handleChangeOnlyService={handleChangeOnlyService}
							handleFavoriteDetails={favoriteDetails}
							handleExport={handleExport}
				/>
				<div id="route-trips" className="col-md-12 p-0">
					{/** Trips Table **/}
					<div className="trips table-responsive">
						<table className="table table-hover">
							<thead>
							<tr>
								<th><FormattedMessage id="GENERAL.DATE"/></th>
								<th><FormattedMessage id="GENERAL.DRIVER"/></th>
								<th><FormattedMessage id="GENERAL.START"/></th>
								<th><FormattedMessage id="GENERAL.START_ADDRESS"/></th>
								<th><FormattedMessage id="GENERAL.NR"/></th>
								<th><FormattedMessage id="REPORTS.POST_CODE"/></th>
								<th><FormattedMessage id="GENERAL.STOP"/></th>
								<th><FormattedMessage id="GENERAL.END_ADDRESS"/></th>
								<th><FormattedMessage id="GENERAL.NR"/></th>
								<th><FormattedMessage id="REPORTS.POST_CODE"/></th>
								<th><FormattedMessage id="REPORTS.ABOUT"/></th>
								<th><FormattedMessage id="REPORTS.VISITED"/></th>
								<th><FormattedMessage id="REPORTS.FAVORITES"/></th>
								<th>Km</th>
								<th><FormattedMessage id="REPORTS.TRIP_TYPE"/></th>
								<th/>
							</tr>
							</thead>
							<tbody>
							{
								trips.map((trip, index) => {
									// only private filter
									if(!onlyService || (onlyService && trip.private_trip === 1)) {
										return (
											<tr className={(index % 2 === 1) ? 'active' : ''} key={`trip${trip.trip_id}`}>
												<td width="80">
													{getFormattedDate(trip.start_date, 'DD-MM-YYYY')}
													{(trip.jump) ? (<span><br/>{getFormattedDate(trip.end_date, 'DD-MM-YYYY')}</span>) : null}
												</td>
												<td>
													<select className="form-control form-control-sm" name='driver_id' value={trip.driver_id}
																	onChange={(event) => handleTripChange(event, index)}>
														<FormattedMessage id="GENERAL.PICK_DRIVER">
															{(message) => <option key={0} value={0} >{message}</option>}
														</FormattedMessage>
														{
															props.drivers.map((driver) => {
																return (
																	<option key={driver.driver_id} value={driver.driver_id}>
																		{driver.driver_first_name + ' ' + driver.driver_last_name}
																	</option>
																)
															})
														}
													</select>
												</td>
												<td width="50">{getTime(trip.start_time)}</td>
												<td width="150">{trip.start_address}</td>
												<td width="50">
													<input type="text" className="form-control form-control-sm" name='start_no'
																 value={trip.start_no}
																 onChange={(event) => handleTripChange(event, index)}/>
												</td>
												<td width="50">{trip.start_zip}</td>
												<td width="50">{getTime(trip.end_time)}</td>
												<td width="150">{trip.end_address}</td>
												<td width="50">
													<input type="text" className="form-control form-control-sm" name='end_no' value={trip.end_no}
																 onChange={(event) => handleTripChange(event, index)}/>
												</td>
												<td width="50">{trip.end_zip}</td>
												<td>
													<input type="text" className="form-control form-control-sm" name='about' value={trip.about}
																 onChange={(event) => handleTripChange(event, index)}/>
												</td>
												<td>
													<input type="text" name='visited' value={trip.visited}
																 onChange={(event) => handleTripChange(event, index)}
																 className={`form-control form-control-sm ${trip.visited_class ? trip.visited_class : ''}`}/>
												</td>
												<td width="80">
													{
														trip.favorites.length > 0 ?
															<select className="form-control form-control-sm"
																			onChange={(event) => handleTripChange(event, index)}>
																<FormattedMessage id="REPORTS.PICK_FAV">
																	{(message) => <option key={0} value={0} >{message}</option>}
																</FormattedMessage>
																{
																	trip.favorites.map((favorite, index) => {
																		return (
																			<option key={index} value={favorite.visited}>{favorite.visited}</option>
																		)
																	})
																}
															</select>
															: null
													}
												</td>
												<td>{trip.lenght_km}</td>
												<td width="80">
													<select className="form-control form-control-sm" name='private_trip' value={trip.private_trip}
																	onChange={(event) => handleTripChange(event, index)}>
														{
															tripTypes.map((type) =>
																<option key={type.value} value={type.value}>{type.label}</option>
															)
														}
													</select>
												</td>
												<td>
													<button onClick={() => handleUpdateTrip(trip)} className="btn btn-success btn-sm"><FormattedMessage id="GENERAL.SAVE"/></button>
												</td>
											</tr>
										)
									}

								})
							}
							</tbody>
						</table>
					</div>
					{/** Footer **/}
					<div className="row">
						<div className="col-md-3">
							<table className="table table-sm">
								<thead>
								<tr>
									<th>Km - <FormattedMessage id="GENERAL.MORNING"/></th>
									<th>Km - <FormattedMessage id="GENERAL.EVENING"/></th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>{mileage.start}</td>
									<td>{mileage.end}</td>
								</tr>
								</tbody>
							</table>
						</div>
						<div className="col-md-9">
							<table className="table table-sm">
								<thead>
								<tr>
									<th/>
									<th><FormattedMessage id="REPORTS.TRIP_TYPE_2"/></th>
									<th><FormattedMessage id="REPORTS.TRIP_TYPE_3"/></th>
									<th><FormattedMessage id="REPORTS.TRIP_TYPE_1"/></th>
									<th><FormattedMessage id="GENERAL.TOTAL"/></th>
								</tr>
								</thead>
								<tbody>
								<tr className="info">
									<td><b><FormattedMessage id="GENERAL.TOTAL"/></b></td>
									<td>{total.private_km} ({total.private_km_percent}%)</td>
									<td>{total.home_km} ({total.home_km_percent}%)</td>
									<td>{total.office_km} ({total.office_km_percent}%)</td>
									<td>{total.km} (100%)</td>
								</tr>
								</tbody>
							</table>
						</div>
						<div className="clearfix"/>
					</div>
					{/**  Trips Table **/}
					{
						tripsCanceled.length > 0 ?
							<>
								<br/>
								<hr/>
								<div className="trips trips-canceled table-responsive">
									<h4><FormattedMessage id="REPORTS.CANCELED_REPORTS"/></h4>
									<table className="table table-hover">
										<thead>
										<tr>
											<th><FormattedMessage id="GENERAL.DATE"/></th>
											<th><FormattedMessage id="GENERAL.DRIVER"/></th>
											<th><FormattedMessage id="GENERAL.START"/></th>
											<th><FormattedMessage id="GENERAL.START_ADDRESS"/></th>
											<th><FormattedMessage id="GENERAL.NR"/></th>
											<th><FormattedMessage id="REPORTS.POST_CODE"/></th>
											<th><FormattedMessage id="GENERAL.STOP"/></th>
											<th><FormattedMessage id="GENERAL.END_ADDRESS"/></th>
											<th><FormattedMessage id="GENERAL.NR"/></th>
											<th><FormattedMessage id="REPORTS.POST_CODE"/></th>
											<th><FormattedMessage id="REPORTS.ABOUT"/></th>
											<th><FormattedMessage id="REPORTS.VISITED"/></th>
											<th><FormattedMessage id="REPORTS.FAVORITES"/></th>
											<th>Km</th>
											<th><FormattedMessage id="REPORTS.TRIP_TYPE"/></th>
										</tr>
										</thead>
										<tbody>
										{
											tripsCanceled.map((tripCanceled, index) => {
												return (
													<tr className={(index % 2 === 1) ? 'active' : ''} key={tripCanceled.trip_canceled_id}>
														<td width="80">{getFormattedDate(tripCanceled.start_date, 'DD-MM-YYYY')}</td>
														<td>{tripCanceled.driver_last_name}</td>
														<td width="50">{tripCanceled.start_time}</td>
														<td width="150">{tripCanceled.start_address}</td>
														<td width="50">{tripCanceled.start_no}</td>
														<td width="50">{tripCanceled.start_zip}</td>
														<td width="50">{tripCanceled.end_time}</td>
														<td width="150">{tripCanceled.end_address}</td>
														<td width="50">{tripCanceled.end_no}</td>
														<td width="50">{tripCanceled.end_zip}</td>
														<td>{tripCanceled.about}</td>
														<td>{tripCanceled.visited}</td>
														<td>{tripCanceled.lenght_km}</td>
														<td
															width="80">{tripTypes.find(type => type.value === parseInt(tripCanceled.private_trip)).label}</td>
													</tr>
												)
											})
										}
										</tbody>
									</table>
								</div>
							</>
							: null
					}
					<MessageWindow
						size='md'
						show={showMessage.status}
						onHide={() => setShowMessage({...showMessage, status: false})}
						title={showMessage.title}
						message={showMessage.message}
					/>
				</div>
			</div>
		</>
	);

}

const mapStateToProps = function (state) {
	return {
		devices: state.deviceListReducer.devices,
		drivers: state.driverListReducer.drivers
	}
};

export default connect(mapStateToProps)(RouteReport);