import React from 'react';  
import { Route } from 'react-router-dom';  
  
const LoginLayout = ({ children }) => (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
  
  const LoginLayoutRoute = ({component: Component, ...rest}) => {
    return (
      <Route {...rest} render={matchProps => (
        <LoginLayout>
            <Component {...rest} {...matchProps} />
        </LoginLayout>
      )} />
    )
  };
  
export default LoginLayoutRoute;