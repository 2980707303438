import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import DatePicker from "react-datepicker";
import moment from 'moment';

import './index.css';

function HideDeviceService(props) {

	const [data, setData] = useState({...props.data});

	const handleChangeActivation = (event) => {
		if (!event.target.checked) {
			let obj = {};

			Object.keys(data.time).forEach((key) => {
				obj[key] = '';
			});

			obj["activation"] = 0;
			setData({...data, time: {...obj}});

		} else {
			setData({...data, time: {...data.time, activation: 1}});
		}

	};

	const handleSubmit = () => {
		props.onSubmit(data);
	};

	useEffect(() => {
		setData({...props.data});
	}, [props.data]);

	return (
		<Container fluid>
			<Row>
				<Col md={6} xs={12} className="service-border-box">
					<div className="service-title"><FormattedMessage id="SERVICE.PRIVATE_DRIVE"/></div>
					<Row>
						<Col md={6} xs={12}>
							<Row>
								<Col xs={4}><b><FormattedMessage id="GENERAL.DAY"/></b></Col>
								<Col xs={4}><b><FormattedMessage id="GENERAL.START"/></b></Col>
								<Col xs={4}><b><FormattedMessage id="GENERAL.STOP"/></b></Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.MONDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.time.day1_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day1_start: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.time.day1_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day1_stop: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.TUESDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.time.day2_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day2_start: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.time.day2_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day2_stop: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.WEDNESDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.time.day3_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day3_start: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.time.day3_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day3_stop: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.THURSDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.time.day4_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day4_start: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.time.day4_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day4_stop: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.FRIDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.time.day5_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day5_start: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.time.day5_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day5_stop: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.SATURDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.time.day6_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day6_start: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.time.day6_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day6_stop: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row className='mb-2'>
								<Col xs={4}><FormattedMessage id="DAYS.SUNDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.time.day7_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day7_start: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.time.day7_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({
																...data,
																time: {...data.time, day7_stop: moment(date).format("HH:mm")}
															})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
						</Col>
						<Col xs={1}/>
						<Col md={4} xs={12}>
							<b><FormattedMessage id="SERVICE.ACTIVATION_SETTINGS"/></b>
							<div className='service-divider'/>
							<div><FormattedMessage id="GENERAL.START_DATE"/></div>
							<DatePicker
								todayButton="Today"
								popperPlacement="bottom-start"
								dateFormat="dd-MM-yyyy"
								selected={data.time.start_date}
								onChange={(date) => setData({...data, time: {...data.time, start_date: date}})}/>
							<div style={{marginTop: 5}}><FormattedMessage id="GENERAL.END_DATE"/></div>
							<DatePicker
								todayButton="Today"
								popperPlacement="bottom-start"
								dateFormat="dd-MM-yyyy"
								selected={data.time.stop_date}
								onChange={(date) => setData({...data, time: {...data.time, stop_date: date}})}/>
							<br/><br/>
							<Form.Check
								type="switch"
								id="time.activation"
								onChange={handleChangeActivation}
								checked={Boolean(data.time.activation)}
								label={<FormattedMessage id="GENERAL.ACTIVE"/>}
							/>
						</Col>
					</Row>
					<div className='service-divider'/>
					<Row>
						<Col md={4} xs={12}>
							<Button onClick={handleSubmit} block variant="primary" type="button">
								<FormattedMessage id="GENERAL.SAVE"/>
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
}

export default HideDeviceService;