import React, { useEffect, useState } from 'react';
import HomeNav from './nav';
import RouteMap from '../Map/route';
import SignalService from '../../services/Signals';
import DateFilter from '../Commons/dateFilter';
import MessageWindow from '../Commons/messageWindow';
  
function OptimizedRoute(props) {
  
  const deviceId = props.match.params.deviceId;
  const signalService = new SignalService();

  const [signals, setSignals] = useState([]);
  const [device, setDevice] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filterDate, setFilterDate] = useState("");

  const handleChangeDate = date => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();
    
    setSelectedDate(date);
    setFilterDate(`${year}-${month}-${day}`);
  };

  const fetchData = async () => {
    try {
      const response = await signalService.getOptimizedRoute(deviceId, filterDate);
      setSignals(response.data[0].signals);
      let device = {type: response.data[0].type, 
        name: response.data[0].name, 
        device_id: response.data[0].device_id,
        address: ""
      };
      
      setDevice(device);

    } catch (error) {
    }
  };

  useEffect(() => {
    
    if (filterDate !== "" && props.access === true) {
      fetchData();
    }
    
    handleChangeDate(selectedDate);
    
    return () => {
      signalService.getCancelToken().cancel();
    };
  }, [deviceId, filterDate]);

  return (
    <>
      <MessageWindow
        title="Yetkiniz yok!"
        message="Paketiniz bu özelliği içermiyor. Bilgi için: 0(232) 339 60 06"
        show={!props.access} />

      <HomeNav deviceId={deviceId} active="optimized-route" />
      <div style={{ position: 'absolute', zIndex: 999, top: 36, left: 90 }}>
        <DateFilter
          onChange={handleChangeDate} />
      </div>
      <RouteMap {...props} device={device} lineColor={"rgba(255, 10, 80, .6)"} signals={signals} />
    </>
  );
}

export default OptimizedRoute;
