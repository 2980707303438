import BaseService from './BaseService';

class DataDelete extends BaseService {

	createToken(deviceId) {
		return this.axiosInstance.get(`/data_delete/create_token/${deviceId}/?`);
	}

	getDevicesWithDeletionOrder() {
		return this.axiosInstance.get(`/data_delete/devices_with_deletion_order/?`);
	}

	confirmToken(deviceId, data) {
		return this.axiosInstance.post(`/data_delete/confirm_token/${deviceId}/?`, data);
	}

	cancel(deviceId) {
		return this.axiosInstance.get(`/data_delete/cancel/${deviceId}/?`);
	}

}

export default DataDelete;