import BaseService from './BaseService';

class CarService extends BaseService {

  getCar(deviceId) {
    if (deviceId === 0) {
      deviceId = "all_cars_map_info";
    }
    return this.axiosInstance.get(`/cars/${deviceId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }

  saveCar(deviceId, data) {
    return this.axiosInstance.post(`/cars/update/${deviceId}/?`, data);
  }

}

export default CarService;