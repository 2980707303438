import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import DatePicker from "react-datepicker";

import UserInformation from '../../services/UserInformation';

function UserComponent(props) {
	const [isValid, setIsValid] = useState(props.isValid);

	const userInformationService = new UserInformation();

	const handleEmailValidation = async (event) => {
		event.preventDefault();
		const email = event.target.value;

		try {
			await userInformationService.isSubUserMailExists(email, props.user.user_id)
				.then((response) => {
					setIsValid({...isValid, user_email: !response.data.result});
				})
				.finally(() => props.handleUserChange('user_email', email));

		} catch (e) {}

	};

	const handleUserNameValidation = async (event) => {
		event.preventDefault();
		const userName = event.target.value;

		try {
			await userInformationService.isSubUserNameExists(userName, props.user.user_id)
				.then((response) => {
					setIsValid({...isValid, user_name: !response.data.result});
				})
				.finally(() => props.handleUserChange('user_name', userName));

		} catch (e) {}

	};

	return (
		<>
			<h4>{props.title}</h4>
			<>
				<form onSubmit={props.handleSubmit}>
					<div style={{marginBottom: '1rem'}}>
						<label><b><FormattedMessage id='GENERAL.NAME'/>&nbsp;*</b></label>
						<FormattedMessage id='GENERAL.NAME'>
							{(message) => <input type="text" className="form-control" placeholder={message} value={props.user.user_first_name} required
																	 onChange={(event) => props.handleUserChange('user_first_name', event.target.value)}/>}
						</FormattedMessage>
					</div>
					<div style={{marginBottom: '1rem'}}>
						<label><b><FormattedMessage id='GENERAL.SURNAME'/>&nbsp;*</b></label>
						<FormattedMessage id='GENERAL.SURNAME'>
							{(message) => <input type="text" className="form-control" placeholder={message} value={props.user.user_last_name} required
																	 onChange={(event) => props.handleUserChange('user_last_name', event.target.value)}/>}
						</FormattedMessage>
					</div>
					<div style={{marginBottom: '1rem'}}>
						<label><b><FormattedMessage id='GENERAL.MAIL'/>&nbsp;*</b></label>
						<FormattedMessage id='GENERAL.MAIL'>
							{(message) => <input type="email"
																	 className={`form-control ${(props.user.user_email.length > 0) ? ((isValid.user_email) ? "" : "is-invalid") : ""}`}
																	 placeholder={message}
																	 value={props.user.user_email}
																	 required
																	 onChange={handleEmailValidation}/>}
						</FormattedMessage>
						<FormattedMessage id='USER_RIGHTS.ER_MAIL_AE'>
							{(message) => <div className="invalid-feedback">{message}</div>}
						</FormattedMessage>
					</div>
					<div style={{marginBottom: '1rem'}}>
						<label><b><FormattedMessage id='GENERAL.USER_NAME'/>&nbsp;*</b></label>
						<FormattedMessage id='GENERAL.USER_NAME'>
							{(message) => <input type="text"
																	 className={`form-control ${(props.user.user_name.length > 0) ? ((isValid.user_name) ? "" : "is-invalid") : ""}`}
																	 placeholder={message}
																	 value={props.user.user_name}
																	 required
																	 onChange={handleUserNameValidation}/>}
						</FormattedMessage>
						<FormattedMessage id='USER_RIGHTS.ER_UN_AE'>
							{(message) => <div className="invalid-feedback">{message}</div>}
						</FormattedMessage>
					</div>
					<div style={{marginBottom: '1rem'}}>
						<label><b><FormattedMessage id='GENERAL.PASSWORD'/>&nbsp;*</b></label>
						<FormattedMessage id='GENERAL.PASSWORD'>
							{(message) => <input type="text" className="form-control" placeholder={message} value={props.user.user_pass} required
																	 onChange={(event) => props.handleUserChange('user_pass', event.target.value)}/>}
						</FormattedMessage>
					</div>
					<div className="form-check" style={{marginBottom: '1rem', float: 'right'}}>
						<input checked={props.user.active}
									 onChange={(event) => props.handleUserChange('active', event.target.checked ? 1 : 0)}
									 type="checkbox"
									 className="form-check-input" />
						<label className="form-check-label" ><b><FormattedMessage id='USER_RIGHTS.USER_STATUS'/></b></label>
					</div>
					<div>
						<label className='stack'><b><FormattedMessage id='USER_RIGHTS.ACT_START'/></b></label>
						<DatePicker
							className='stack'
							todayButton="Today"
							selected={props.user.users_added_at}
							dateFormat="dd-MM-yyyy"
							onChange={(date) => props.handleUserChange('users_added_at', date)}/>
						<label className='stack'><b><FormattedMessage id='USER_RIGHTS.ACT_END'/></b></label>
						<DatePicker
							className='stack'
							todayButton="Today"
							selected={props.user.active_until}
							dateFormat="dd-MM-yyyy"
							onChange={(date) => props.handleUserChange('active_until', date)}/>
					</div>
					<div className="form-group mt-2">
						<button className="btn btn-block btn-success" type="submit" disabled={!(isValid.user_email&&isValid.user_name)}>
							<FormattedMessage id='GENERAL.SAVE'/>
						</button>
					</div>
				</form>
			</>
		</>
	);
}

export default UserComponent;