import BaseService from './BaseService';

class DeviceService extends BaseService {

  getDevice(deviceId) {
    return this.axiosInstance.get(`/user/device/${deviceId}/?`, {cancelToken: this.cancelTokenSource.token});
  }

  getDevices(ivdc) {
    return this.axiosInstance.get(`/user/devices/?ivdc=${ivdc}`, {cancelToken: this.cancelTokenSource.token});
  }

  getDeviceAllInfo(deviceId) {
    return this.axiosInstance.get(`user/device/all_info/${deviceId}/?`, {cancelToken: this.cancelTokenSource.token});
  }

  getDevicesWithGroups(orderType, display) {
    return this.axiosInstance.get(`/user/devices_with_groups/?order_by=${orderType}&display=${display}`, {cancelToken: this.cancelTokenSource.token});
  }

  getDevicesOfGroup(deviceGroupId) {
    return this.axiosInstance.get(`/group/${deviceGroupId}/devices/?`, {cancelToken: this.cancelTokenSource.token});
  }

  update(deviceId, data) {
    return this.axiosInstance.post(`/device/update/${deviceId}/?`, data);
  }

}

export default DeviceService;