import React, {useState} from 'react';
import { connect } from 'react-redux';
import {Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {FormattedMessage} from 'react-intl';
import ReportService from '../../../services/Reports';
import '../index.css';
import Moment from 'moment';

function DailyTrips(props) {
    const [addTrip, setAddTrip] = useState(false);
    const [editTrip, setEditTrip] = useState(false);
    const [deleteTrip, setDeleteTrip] = useState(false);
    const [km, setKm] = useState(0);
    const [startAddress, setStartAddress] = useState('');
    const [endAddress, setEndAddress] = useState('');
    const [selectedDriver, setSelectedDriver] = useState(0);
    const [selectedDateStart, setSelectedDateStart] = useState(new Date());
    const [tripTime, setTripTime] = useState(new Date(1970,1,1,0,0,0));
    const [waitingTime, setWaitingTime] = useState(new Date(1970,1,1,0,0,0));
    const [choosenTrip, setChoosenTrip] = useState({});
    const [userDailyReportId, setUserDailyReportId] = useState(0);

    const reportService = new ReportService();

    const handleAddTrips = (trip) => {
        setStartAddress('');
        setEndAddress('');
        setSelectedDriver(0);
        setTripTime(new Date("01/01/1970 00:00:00"));
        setWaitingTime(new Date("01/01/1970 00:00:00"));
        setChoosenTrip(trip);
        setAddTrip(true);
    }

    const handleEditTrips = (trip) => {
        setStartAddress(trip.start_address);
        setEndAddress(trip.end_address);
        setSelectedDriver(trip.driver.driver_id);
        setSelectedDateStart(new Date(trip.start_date));
        setTripTime(new Date(trip.duration));
        setWaitingTime(new Date(trip.waiting_time));
        setChoosenTrip(trip);
        setEditTrip(true);
    }

    const handleSelectDriver = (value) => {
        setSelectedDriver(value);
    }

    const handleDeleteTrip = (id) => {
        setUserDailyReportId(id);
        setDeleteTrip(true);
    }

    const addTripFunction = async () => {

        let newTrip;
        let device_id = 0;
        let trip = {};
        trip.start_date = Moment(choosenTrip.end_time, "DD/MM/YYYY HH:mm").toDate();
        trip.duration = new Date("01/01/1970 00:00:00");
        trip.waiting_time = new Date("01/01/1970 00:00:00");
        trip.km = 0;
        trip.start_address = startAddress;
        trip.end_address = endAddress;
        trip.driver = "";

        newTrip = {};
        newTrip.start_date =  Moment(new Date(trip.start_date)).format("YYYY-MM-DD");
        newTrip.start_time =  String(Moment(new Date(trip.start_date)).format("HH:mm"));

        // calculate end_time
        let end_time = new Date(trip.start_date);
        end_time.setMinutes(end_time.getMinutes() + new Date(tripTime).getMinutes());
        end_time.setHours(end_time.getHours() + new Date(tripTime).getHours());

        newTrip.end_time = Moment(new Date(end_time)).format("HH:mm");
        newTrip.travel_time = Moment(new Date(tripTime)).format("HH:mm");
        newTrip.waiting_time = Moment(new Date(waitingTime)).format("HH:mm");
        newTrip.kilometer = parseInt(km);
        newTrip.start_address = trip.start_address;
        newTrip.end_address = trip.end_address;
        newTrip.driver_id = parseInt(selectedDriver);
        device_id = choosenTrip.device_id;

        try {
            await reportService.insertUserDailyReports(device_id, newTrip)
              .then(async(response) => {
                setAddTrip(false);
                props.manuelTrip(true);
              });
            } catch (error) {
        }
        props.manuelTrip(false);
    }

    const editTripFunction = async () => {
        let editedTrip;
        let trip = {};
        trip.start_date = Moment(choosenTrip.start_time, "DD/MM/YYYY HH:mm").toDate();
        trip.duration = Moment(choosenTrip.duration, "DD/MM/YYYY HH:mm").toDate();
        trip.waiting_time = Moment(choosenTrip.waiting_time, "DD/MM/YYYY HH:mm").toDate();
        trip.km = choosenTrip.lenght_km;
        trip.start_address = choosenTrip.start_address;
        trip.end_address = choosenTrip.end_address;
        trip.driver = choosenTrip.driver;

        editedTrip = {};
        editedTrip.start_date =  Moment(new Date(trip.start_date)).format("YYYY-MM-DD");
        editedTrip.start_time =  String(Moment(new Date(trip.start_date)).format("HH:mm"));

        // calculate end_time
        trip.start_date.setMinutes(trip.start_date.getMinutes() + new Date(tripTime).getMinutes());
        trip.start_date.setHours(trip.start_date.getHours() + new Date(tripTime).getHours());

        editedTrip.end_time = Moment(new Date(trip.start_date)).format("HH:mm");
        editedTrip.travel_time = Moment(new Date(tripTime)).format("HH:mm");
        editedTrip.waiting_time = Moment(new Date(waitingTime)).format("HH:mm");
        editedTrip.kilometer = parseInt(km);
        editedTrip.start_address = startAddress;
        editedTrip.end_address = endAddress;
        editedTrip.driver_id = parseInt(selectedDriver);

        try {
            await reportService.updateUserDailyReports(choosenTrip.user_daily_report_id, editedTrip)
              .then(async(response) => {
                setEditTrip(false);
                props.manuelTrip(true);
              });
            } catch (error) {
        }
        props.manuelTrip(false);
    }
    

    const deleteTripFunction = async (user_daily_report_id) => {

        try {
            await reportService.deleteUserDailyReports(user_daily_report_id)
              .then(async(response) => {
                props.manuelTrip(true);
                setDeleteTrip(false);
              });
            } catch (error) {
        }
        props.manuelTrip(false);
    }

    const timeToMinute = (time) => {
        let hour_minute = time.split(":");
        let hour = parseInt(hour_minute[0])*60;
        let minute = parseInt(hour_minute[1]);
        minute = minute + hour;
        return minute;
    }

    const percentageCalculation = (time) => {
        let percentage = (100 * timeToMinute(time)) / 450;
        return percentage.toFixed(0);
    }

    return (
        <React.Fragment>
            <div> 
                <div className="trips table-responsive" id="daily-trips">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th><FormattedMessage id="REPORTS.ROUTE_NO"/></th>
                                {
                                    props.show_device_name ? <th><FormattedMessage id="SERVICE.VEHICLE_NAME"/></th>  : null // show device name filter control
                                }
                                {
                                    props.reports_with_driver ? <th><FormattedMessage id="GENERAL.DRIVER"/></th>  : null // reports with drivers filter control
                                }
                                <th><FormattedMessage id="GENERAL.DATE"/></th>
                                <th><FormattedMessage id="GENERAL.START"/></th>
                                <th><FormattedMessage id="GENERAL.START_ADDRESS"/></th>
                                <th><FormattedMessage id="GENERAL.STOP"/></th>
                                <th><FormattedMessage id="GENERAL.END_ADDRESS"/></th>
                                <th><FormattedMessage id="GENERAL.KM"/></th>
                                <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
                                <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
                                <th className="last"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.trips.length !== 0 ? 
                                props.trips.map((trip,index) => {
                                    let date = Moment(new Date(trip.start_date)).format("DD/MM/YYYY").substring(0,11);
                                    if(!props.only_private){ // only private filter control
                                        return  <tr key={'trip-' + index} id={'trip-' + index} className={trip.class !== undefined ? trip.class : trip.dayTotal ? 'day-total' : index%2 !== 0 ? 'active' :  ''}>
                                                    <td>
                                                        {!trip.dayTotal ? <span> {index} </span>: <span></span>}
                                                    </td>
                                                    {   
                                                        props.show_device_name ? 
                                                            !trip.dayTotal ?
                                                                props.devices.map((device,index) => { // show device name filter control
                                                                    if(device.device_id === trip.device_id)
                                                                        return  <td key={index}>{device.name}</td>
                                                                })  
                                                                :
                                                                <td></td>
                                                            :
                                                             null
                                                    }
                                                    {
                                                        props.reports_with_driver ? // reports with drivers filter control
                                                            !trip.dayTotal ? 
                                                                <td>{trip.driver.driver_last_name}</td>
                                                                :
                                                                <td></td>
                                                            : 
                                                            null
                                                    }
                                                    <td>{date}</td>
                                                    <td>{trip.start_time !== undefined ?  trip.start_time.substring(11,16) : ''}</td>
                                                    <td width="220">{trip.start_address !== undefined ?  trip.start_address : ''}</td>
                                                    <td>{trip.end_time !== undefined ?  trip.end_time.substring(11,16) : ''}</td>
                                                    <td width="220">{trip.end_address !== undefined ?  trip.end_address : ''}</td>
                                                    <td>{trip.lenght_km !== undefined ?  trip.lenght_km : ''}</td>
                                                    <td className='cell-with-comment'>{trip.duration !== undefined ?  trip.dayTotal ? trip.duration.substring(11,16) +' ('+ '%' + percentageCalculation(trip.duration.substring(11,16)) +')' : trip.duration.substring(11,16) : ''}{trip.dayTotal ? <span className="cell-comment">Çalışma süresi 7 saat 30 dakika baz alınarak yüzdelik hesaplanmıştır!</span> : null}</td>
                                                    <td>{trip.waiting_time !== undefined ?  trip.waiting_time.substring(11,16) : ''}</td>
                                                    {
                                                        props.user.user_group_id === 2 ?
                                                            <td className="last">
                                                            { 
                                                                    !props.reportType.url.includes('driver') ? 
                                                                    <div>
                                                                        {!trip.dayTotal ? <span onClick={() => handleAddTrips(trip)} className="fa fa-plus tip"></span>: <span></span>}
                                                                        {trip.user_daily_report_id ? <span onClick={() => handleDeleteTrip(trip.user_daily_report_id)} className="fa fa-minus"></span>: <span></span>}
                                                                        {trip.user_daily_report_id ? <span onClick={() => handleEditTrips(trip)} className="fa fa-pencil"></span>: <span></span>}
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            </td>
                                                            :
                                                            null
                                                    }
                                                </tr>
                                    }else{
                                        if(trip.private_trip === 2){
                                            return  <tr key={'trip-' + index} id={'trip-' + index} className={trip.class !== undefined ? trip.class : trip.dayTotal ? 'day-total' : index%2 !== 0 ? 'active' :  ''}>
                                                        <td>
                                                            {!trip.dayTotal ? <span> {index} </span>: <span></span>}
                                                        </td>
                                                        {   
                                                            props.show_device_name ? 
                                                                !trip.dayTotal ?
                                                                    props.devices.map((device,index) => { // show device name filter control
                                                                        if(device.device_id === trip.device_id)
                                                                            return  <td key={index}>{device.name}</td>
                                                                    })  
                                                                    :
                                                                    <td></td>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            props.reports_with_driver ? // reports with drivers filter control
                                                            !trip.dayTotal ? 
                                                                <td>{trip.driver.driver_last_name}</td>
                                                                :
                                                                <td></td>
                                                            : 
                                                            null
                                                        }
                                                        <td>{date}</td>
                                                        <td>{trip.start_time !== undefined ?  trip.start_time.substring(11,16) : ''}</td>
                                                        <td width="220">{trip.start_address !== undefined ?  trip.start_address : ''}</td>
                                                        <td>{trip.end_time !== undefined ?  trip.end_time.substring(11,16) : ''}</td>
                                                        <td width="220">{trip.end_address !== undefined ?  trip.end_address : ''}</td>
                                                        <td>{trip.lenght_km !== undefined ?  trip.lenght_km : ''}</td>
                                                        <td className='cell-with-comment'>{trip.duration !== undefined ?  trip.dayTotal ? trip.duration.substring(11,16) +' ('+ '%' + percentageCalculation(trip.duration.substring(11,16)) +')' : trip.duration.substring(11,16) : ''}{trip.dayTotal ? <span className="cell-comment">Çalışma süresi 7 saat 30 dakika baz alınarak yüzdelik hesaplanmıştır!</span> : null}</td>
                                                        <td>{trip.waiting_time !== undefined ?  trip.waiting_time.substring(11,16) : ''}</td>
                                                        {
                                                            props.user.user_group_id === 2 ?
                                                                <td className="last">
                                                                { 
                                                                        !props.reportType.url.includes('driver') ? 
                                                                        <div>
                                                                            {!trip.dayTotal ? <span onClick={() => handleAddTrips(trip)} className="fa fa-plus tip"></span>: <span></span>}
                                                                            {trip.user_daily_report_id ? <span onClick={() => handleDeleteTrip(trip.user_daily_report_id)} className="fa fa-minus"></span>: <span></span>}
                                                                            {trip.user_daily_report_id ? <span onClick={() => handleEditTrips(trip)} className="fa fa-pencil"></span>: <span></span>}
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                </td>
                                                                :
                                                                null
                                                        }
                                                    </tr>
                                        }
                                    }
                                }): null
                            }
                        </tbody>
                    </table>
                </div>
                <div>
                    {props.reportType.url.includes('device') ? 
                    <div className="col-md-3 table-responsive pull-left">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Km - <FormattedMessage id="GENERAL.MORNING"/></th>
                                    <th>Km - <FormattedMessage id="GENERAL.EVENING"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="warning">
                                    <td>{props.start_mil}</td>
                                    <td>{props.end_mil}</td>
                                </tr>
                            </tbody>
                        </table> 
                    </div> : null }
                    <div className="col-md-9 table-responsive pull-right">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                <th></th>
                                <th><FormattedMessage id="GENERAL.KM"/></th>
                                <th><FormattedMessage id="GENERAL.KM"/>(<FormattedMessage id="GENERAL.PRIVATE"/>)</th>
                                <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
                                <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
                                <th><FormattedMessage id="REPORTS.TOTAL_ACTIVE_TIME"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="info">
                                <td><b><FormattedMessage id="GENERAL.TOTAL"/></b></td>
                                <td>{props.total.km}</td>
                                <td>{props.total.private_km}</td>
                                <td>{props.total.duration}</td>
                                <td>{props.total.waiting_time}</td>
                                <td>{props.total.active}</td>
                                </tr>
                            </tbody>
                        </table>  
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
            <Modal
                size="lg"
                show={addTrip || editTrip ? true : false}
                aria-labelledby="addEditTrip"
                onHide={addTrip ? () => setAddTrip(false) : () => setEditTrip(false)}>
                
                <Modal.Header closeButton>
                    <Modal.Title id="addEditTrip">
                        {addTrip ? 'Sefer Ekle' : 'Seferi Düzenle' }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="addEditTrip">
                    <div className="ngdialog-message">
                        <table className="table table-hover add-trip">
                            <tbody>
                            <tr>
                                <td><FormattedMessage id="GENERAL.DRIVER"/></td>
                                <td>
                                <div className="form-group">
                                    <select className="form-control select-car input-sm"  value={selectedDriver}  onChange={(event) => handleSelectDriver(event.target.value)}>
                                        <FormattedMessage id="GENERAL.PICK_DRIVER">
                                            {(message) => <option key={0} value={0}>{message}</option>}
                                        </FormattedMessage>
                                        {
                                            props.drivers.length !== undefined ? 
                                            props.drivers.map((driver) => {
                                                return <option key={driver.driver_id} value={driver.driver_id}>{driver.driver_first_name + " " + driver.driver_last_name}</option>
                                            })
                                            :
                                            null
                                        }
                                    </select>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.START_ADDRESS"/></td>
                                <td><input type="text" value={startAddress} className="form-control" onChange={(e) => setStartAddress(e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.START_DATE"/></td>
                                <td>
                                    <DatePicker
                                        value={selectedDateStart}
                                        todayButton="Today"
                                        maxDate={new Date()}
                                        dateFormat="dd-MM-yyyy HH:mm"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        timeCaption="time"
                                        selected={selectedDateStart}
                                        onChange={(date)=> setSelectedDateStart(date)} />
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.END_ADDRESS"/></td>
                                <td><input type="text" value={endAddress} className="form-control" onChange={(e) => setEndAddress(e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.KM"/></td>
                                <td><input type="number"  value={km} className="form-control" onChange={(e) => setKm(e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.TRIP_TIME"/></td>
                                <td>
                                    <DatePicker
                                        value={tripTime}
                                        selected={tripTime}
                                        onChange={date => setTripTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.WAIT_TIME"/></td>
                                <td>
                                    <DatePicker
                                        value={waitingTime}
                                        selected={waitingTime}
                                        onChange={date => setWaitingTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                        <div className="ngdialog-buttons">
                            <div className="form-group">
                                <button  onClick={addTrip ? addTripFunction : editTripFunction} type="button" className="btn btn-primary pull-right"><FormattedMessage id="GENERAL.SAVE"/></button>
                                <button  onClick={addTrip ? () => setAddTrip(false) : () => setEditTrip(false)} type="button" className="btn btn-warning pull-right"><FormattedMessage id="GENERAL.TURN_BACK"/></button>
                            </div>
                        </div>
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={deleteTrip}
                aria-labelledby="deleteTrip"
                onHide={ () => setDeleteTrip(false)}>
                
                <Modal.Header closeButton>
                    <Modal.Title id="deleteTrip">
                        <FormattedMessage id="REPORTS.DELETE_TRIP"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="deleteTrip">
                    <div className="ngdialog-message">
                        <p><FormattedMessage id="REPORTS.CONFIRM_DELETE_TRIP"/></p>
                    </div>
                    <div className="ngdialog-buttons">
                        <div className="form-group">
                        <button type="button" className="badge badge-danger pull-right" onClick={() => deleteTripFunction(userDailyReportId)}><FormattedMessage id="GENERAL.DELETE"/></button>
                        <button type="button" className="badge badge-warning pull-right" onClick={() => setDeleteTrip(false)} ><FormattedMessage id="GENERAL.TURN_BACK"/></button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    
  );

}
const mapStateToProps = function(state) {
    return {
        devices: state.deviceListReducer.devices,
        drivers: state.driverListReducer.drivers,
        user: state.userInformationReducer.user
    }
};
export default connect(mapStateToProps)(DailyTrips);


