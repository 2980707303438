import React, {useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import MessageWindow from "../Commons/messageWindow";

import DeviceOptions from "../../services/DeviceOptions";

function FavoriteOptionWindow(props) {
	const [favoriteOption, setFavoriteOption] = useState({
		id: 0,
		name: '',
		options: []
	});
	const [status, setStatus] = useState(false);

	const deviceOptionsService = new DeviceOptions();

	const handleHide = () => {
		setStatus(false);
		props.onHide();
	};

	useEffect(() => {
		setFavoriteOption({
			id: props.favorite.user_favorite_device_options_id,
			name: props.favorite.favorite_name,
			options: props.favorite.device_options
		});

		setStatus(props.show);

	}, [props.show]);

	const handleChange = (event, index) => {
		const optionsClone = Object.assign([], favoriteOption.options);
		optionsClone[index].checked = event.target.checked ? 1 : 0;
		setFavoriteOption({
			...favoriteOption,
			options: [...optionsClone]
		});
	};

	const handleSubmit = async () => {
		const optionsSelected = [];
		favoriteOption.options.forEach((option) => {if (option.checked) optionsSelected.push(option.device_option_id)});

		const data = {
			favorite_name: favoriteOption.name,
			device_options: `[${optionsSelected.toString()}]`
		};

		if (favoriteOption.id) {
			await deviceOptionsService.updateFavoriteOption(favoriteOption.id, data)
				.then((response) => handleHide());

		} else {
			await deviceOptionsService.createFavoriteOption(data)
				.then((response) => handleHide());
		}

	};

	const handleDelete = async () => {
		await deviceOptionsService.deleteFavoriteOption(favoriteOption.id)
			.then((response) => handleHide());
	};

	return (
		<MessageWindow
			show={status}
			onHide={handleHide}
			title={
				<input value={favoriteOption.name}
							 onChange={(event) => setFavoriteOption({...favoriteOption, name: event.target.value})}
							 type="text"
							 className="form-control"
				/>
			}
			message= {
				<>
					{
						favoriteOption.options.map((option, index) => (
							<div className='form-check' style={{width: '33%', float: 'left'}} key={option.device_option_id}>
								<input checked={option.checked}
											 onChange={(event) => handleChange(event, index)}
											 type="checkbox"
											 className="form-check-input"/>
											 <label className="form-check-label"><FormattedMessage id={`USER_RIGHTS.${option.sap_id}`}/></label>
							</div>
					))}
				</>
			}
			footer= {
				<>
					<button className="btn btn-success" style={{width: '30%'}} onClick={handleSubmit}>
						<FormattedMessage id='GENERAL.SAVE'/>
					</button>
					{
						favoriteOption.id
							?
							<button className="btn btn-danger" style={{width: '30%'}} onClick={handleDelete}>
								<FormattedMessage id='GENERAL.DELETE'/>
							</button>
							:
							null
					}
				</>
			}
		/>
	);
}

export default FavoriteOptionWindow;