import React, {useState, useEffect} from 'react';
import HomeNav from './nav';
import LiveMap from '../Map/live';

import SignalService from '../../services/Signals';

function Location(props) {
  
  const signalService = new SignalService();
  const deviceId = props.match.params.deviceId;
  const [device, setDevice] = useState({});

  const fetchData = async () => {
    try {
      const response = await signalService.getLocation(deviceId);
      setDevice(response.data[0]);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      signalService.getCancelToken().cancel();
    };
  }, [deviceId]);

  return (
    <div>
      <HomeNav deviceId={deviceId} active="location" />
      <LiveMap device={device} {...props} />
    </div>
  );
}

export default Location;
