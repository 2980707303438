import BaseService from './BaseService';

class VehicleGroups extends BaseService {

	getGroupDevices(device_group_id) {
		return this.axiosInstance.get(`/group/${device_group_id}/get_devices/?`, {cancelToken: this.cancelTokenSource.token});
	};

	updateGroupDevices(device_group_id, query_string) {
		return this.axiosInstance.post(`/group/update_devices/${device_group_id}/?`, query_string, {cancelToken: this.cancelTokenSource.token});
	};

	deleteGroup(device_group_id) {
		return this.axiosInstance.get(`/group/delete/${device_group_id}/?`, {cancelToken: this.cancelTokenSource.token});
	};

	createNewGroup(query_string) {
		return this.axiosInstance.post(`/group/create/?`, query_string, {cancelToken: this.cancelTokenSource.token});
	};

	updateGroupName(device_group_id,group_name) {
		return this.axiosInstance.get(`/group/update_name/${device_group_id}/?group_name=${group_name}`, {cancelToken: this.cancelTokenSource.token});
	};

}

export default VehicleGroups;