import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {updateDeviceListType, updateDeviceSortType, updateDeviceGroupSort,updateSearchContent} from '../../redux/actions';
import { Collapse, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import './index.css';


function DeviceListToolbar(props) {
  const [searchCollapse, setSearchCollapse] = useState(false);
  const [allRoutesCollapse, setAllRoutesCollapse] = useState(false);
  const [value, setValue] = useState('');
  const [filteredDeviceList, setDeviceList] = useState([]);
  // const [cursor, setCursor] = useState(-1);

  const deviceList = props.devices.map(item => {
    return {name: item['name'], device_id: item['device_id']}
  });

  let input;
  const handleChange = () => {
    filterDeviceList(input.value);
  };

  const filterDeviceList = (text) => {
    let newData = [];
    let filteredList = [];
    if(text !== ''){
      newData = deviceList.filter(function(item){
        const itemData = item.name.toUpperCase()
        const textData = text.toUpperCase()
        return itemData.indexOf(textData) > -1
      })
    }else{
        filteredList.pop();
        filteredList.push(deviceList);
        setOpacity('');
    }
    filteredList.pop();
    filteredList.push(newData);
    setValue(text);
    setDeviceList(filteredList)
  }

  const setOpacity = (text) => {
    let filteredArray = [];
    setValue(text);
    setDeviceList([]);
    for (var i = 0; i < deviceList.length; i++) {
      const textData = text.toUpperCase();
      if(textData === deviceList[i].name.toUpperCase()){
        filteredArray.push({
            ...deviceList[i],
            opacity_check: 1,
        });
      }else{
        filteredArray.push({
            ...deviceList[i],
            opacity_check: 0.3,
        });
      }
    }
    
    if(text !== ''){
      props.updateSearchContent({contents: filteredArray});
      sessionStorage.setItem('scroll_status','true');
    }else{
      let filteredArray = [];
      for (var i = 0; i < deviceList.length; i++) {
          filteredArray.push({
              ...deviceList[i],
              opacity_check: 1,
          });
      }
      props.updateSearchContent({contents: filteredArray});
      sessionStorage.setItem('scroll_status','false');
    }
    
  }


  const handleListType = (listType) => {
    props.updateDeviceListType({listType: listType});
  };

  const handleSortType = (groupSort, sortType) => {
    props.updateDeviceSortType({sortType: sortType});
    props.updateDeviceGroupSort({groupSort: groupSort});
  };

/*   const handleKeyDown = (e) => {
    console.log("e",e.keyCode)
    // arrow up/down button should select next/previous list element
    if(filteredDeviceList[0] !== undefined){
      if (e.keyCode === 38 && cursor > 0) {
        setCursor(cursor - 1)
      } else if (e.keyCode === 40 && cursor < filteredDeviceList[0].length - 1) {
        setCursor(cursor + 1)
      }
    }
  } */

  return (
    <div>
      <div className='device-list-toolbar'>
        <div className='btn-group' style={{width: "100%"}}>
          <Dropdown>
            <Dropdown.Toggle id='sort-devices' size="sm">
              <FormattedMessage id="HOME.SORT" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className={classNames('sort-dropdown-item', {'active': (props.sortType === 'name')})}>
                <span>A-Z</span>
                <div className='sort-menu'>
                  <ul>
                    <li onClick={() => handleSortType(false, 'name')}><FormattedMessage id="HOME.SORT_ALL_DEVICES" /></li>
                    <li onClick={() => handleSortType(true, 'name')}><FormattedMessage id="HOME.SORT_BY_GROUP" /></li>
                  </ul>
                </div>
              </Dropdown.Item>
              <Dropdown.Item className={classNames('sort-dropdown-item', {'active': (props.sortType === '-name')})}>
                <span>Z-A</span>
                <div className='sort-menu'>
                  <ul>
                    <li onClick={() => handleSortType(false, '-name')}><FormattedMessage id="HOME.SORT_ALL_DEVICES" /></li>
                    <li onClick={() => handleSortType(true, '-name')}><FormattedMessage id="HOME.SORT_BY_GROUP" /></li>
                  </ul>
                </div>
              </Dropdown.Item>
              <Dropdown.Item className={classNames('sort-dropdown-item', {'active': (props.sortType === 'ignition')})}>
                <span><FormattedMessage id="HOME.IGNITION" /></span>
                <div className='sort-menu'>
                  <ul>
                    <li onClick={() => handleSortType(false, 'ignition')}><FormattedMessage id="HOME.SORT_ALL_DEVICES" /></li>
                    <li onClick={() => handleSortType(true, 'ignition')}><FormattedMessage id="HOME.SORT_BY_GROUP" /></li>
                  </ul>
                </div>
              </Dropdown.Item>
              <Dropdown.Item className={classNames('sort-dropdown-item', {'active': (props.sortType === 'daily-mileage')})}>
                <span><FormattedMessage id="HOME.DAILY_MILEAGE" /></span>
                <div className='sort-menu'>
                  <ul>
                    <li onClick={() => handleSortType(false, 'daily-mileage')}><FormattedMessage id="HOME.SORT_ALL_DEVICES" /></li>
                    <li onClick={() => handleSortType(true, 'daily-mileage')}><FormattedMessage id="HOME.SORT_BY_GROUP" /></li>
                  </ul>
                </div>
              </Dropdown.Item>
              <Dropdown.Item className={classNames('sort-dropdown-item', {'active': (props.sortType === 'speed')})}>
                <span><FormattedMessage id="HOME.SPEED" /></span>
                <div className='sort-menu'>
                  <ul style={{listStyleType: "none"}}>
                    <li onClick={() => handleSortType(false, 'speed')}><FormattedMessage id="HOME.SORT_ALL_DEVICES" /></li>
                    <li onClick={() => handleSortType(true, 'speed')}><FormattedMessage id="HOME.SORT_BY_GROUP" /></li>
                  </ul>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle id='list-types' size="sm">
              <FormattedMessage id="HOME.LIST_TYPE" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleListType('standart')}
                             className={classNames({'active': (props.listType === 'standart')})}>
                <FormattedMessage id="HOME.LIST_TYPE_STANDART"/>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleListType('standart-big')}
                             className={classNames({'active': (props.listType === 'standart-big')})}>
                <FormattedMessage id="HOME.LIST_TYPE_STANDART_BIG"/>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleListType('klein')}
                             className={classNames({'active': (props.listType === 'klein')})}>
                <FormattedMessage id="HOME.LIST_TYPE_KLEIN"/>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleListType('detailed')}
                             className={classNames({'active': (props.listType === 'detailed')})}>
                <FormattedMessage id="HOME.LIST_TYPE_DETAILERT"/>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleListType('mini')}
                             className={classNames({'active': (props.listType === 'mini')})}>
                <FormattedMessage id="HOME.LIST_TYPE_MINI"/>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleListType('big')}
                             className={classNames({'active': (props.listType === 'big')})}>
                <FormattedMessage id="HOME.LIST_TYPE_GROSSANSICHT"/>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle id='options' size="sm">
              <FormattedMessage id="HOME.OPTIONS" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {setSearchCollapse(!searchCollapse);}}
                             className={classNames({'active': searchCollapse})}>
                <FormattedMessage id="GENERAL.SEARCH"/>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => {setAllRoutesCollapse(!allRoutesCollapse);}}
                             className={classNames({'active': allRoutesCollapse})}>
                <FormattedMessage id="HOME.ALL_ROUTES"/>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {/** collapse **/}
        <Collapse in={searchCollapse}>
          <div id="device_search">
              <FormattedMessage id="SERVICE.VEHICLE_NAME">
                {
                    placeholder => (
                        <input 
                          className="form-control form-control-sm"
                          ref={n => input = n}
                          type="text"
                          value={value}
                          placeholder={placeholder}
                          onChange={handleChange}
                          /* onKeyDown={handleKeyDown} *//>
                    )
                }
              </FormattedMessage>
              <div className="w-100" style = {{backgroundColor: '#FFF',   position: 'absolute', zIndex: 999, opacity: 0.9}}> 
              {
                filteredDeviceList.length !== 0 ?
                filteredDeviceList[0].map((item, index) => (
                  <div className='complete-row' key={index + item} onClick={()=>{setOpacity(item.name)}}>{item.name}</div>
                )) : null
              } 
              </div>
          </div>
        </Collapse>

        <Collapse in={allRoutesCollapse}>
          <div><FormattedMessage id="HOME.ALL_ROUTES"/></div>
        </Collapse>

      </div>
    </div>
  );
}

const mapStateToProps = function(state) {
  return {
    devices: state.deviceListReducer.devices,
    listType: state.deviceListTypeReducer.listType,
    sortType: state.deviceSortTypeReducer.sortType,
    groupSort: state.deviceGroupSortReducer.groupSort,
    contents: state.deviceSearchContent.contents,
  }
};

const mapDispatchToProps = {
  updateDeviceListType,
  updateDeviceSortType,
  updateDeviceGroupSort,
  updateSearchContent
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceListToolbar);
