import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';

import {makeGeoJson} from '../Assets/utils';
import TheftProtectionMap from '../Map/theftProtection';
import MessageWindow from '../Commons/messageWindow';

import GeofenceZones from '../../services/GeofenceZones';

import Step1 from './step1';

import './index.css';

let _zones = {};

function TheftProtection2(props) {

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [modalStyles, setModalStyles] = useState({
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 99,
    left: 0,
    top: 0,
    backgroundColor: '#000',
    opacity: .4
  });
  const [showMessage, setShowMessage] = useState({
    "show": false,
    "title": "",
    "message": ""
  });

  const [zones, setZones] = useState({});

  const geofenceZones = new GeofenceZones();

  const deviceList = props.devices.map(item => {
    return {name: item['name'], device_id: item['device_id']}
  });

  const handleSelectVehicle = (selectedOption) => {
    setSelectedVehicle(selectedOption);
    setModalStyles({...modalStyles, display: 'none'});
  };

  const handleDrawEnd = (data) => {
    let feature = {
      "type": "Feature",
      "id": "OpenLayers.Feature.Vector_" + data.id,
      "properties": {
        "name": "Zone " + data.id,
        "start_hour": "",
        "end_hour": "",
        "active": "false",
        "ban_area": "false",
        "email": "false",
        "sms": "false",
        "in": "true",
        "out": "true"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [data.coordinates]
      }
    };
     
    let json = {};
    if ("geojson" in _zones) {
      json = makeGeoJson(JSON.parse(_zones.geojson), feature);
    }
    else {
      json = makeGeoJson({"features" : []}, feature);
    }
    
    setZones({});
    _zones.geojson = JSON.stringify(json);
    _zones.json_avg_lat = data.avgLat;
    _zones.json_avg_lon = data.avgLong;
    _zones.json_max_lat = data.maxLatLong[0];
    _zones.json_max_lon = data.maxLatLong[1];
    _zones.json_min_lat = data.minLatLong[0];
    _zones.json_min_lon = data.minLatLong[1];
    setZones(_zones);
  };

  const handleZoneDelete = (data) => {
    setZones({...zones, geojson: JSON.stringify(data)});
  };

  const handleZoneSubmit = (data) => {
    let json = {
      "url": "geofence_zones/update/" + selectedVehicle.value,
      "action_list": "geofence_zones_insert_update",
      "device_id": selectedVehicle.value,
      "geojson": JSON.stringify({
        "features": data.features,
        "type": "FeatureCollection"
      }),
      "json_avg_lat": _zones.json_avg_lat,
      "json_avg_lon": _zones.json_avg_lon,
      "json_max_lat": _zones.json_max_lat,
      "json_max_lon": _zones.json_max_lon,
      "json_min_lat": _zones.json_min_lat,
      "json_min_lon": _zones.json_min_lon
    };

    geofenceZones.insertAreaControl(json).then((data) => {
      alert("success");
    });
  };

  const fetchData = async () => {
    const responseZones = await geofenceZones.getAreaControlByDeviceId(selectedVehicle.value);

    setZones({});
    _zones = {};
    
    setZones(responseZones.data);
    _zones = responseZones.data;
    
  };

  useEffect(() => {
    if (selectedVehicle) {
      fetchData();
      setSelectedDevice(props.devices.find(device => device.device_id === selectedVehicle.value));
    }
  }, [selectedVehicle]);
  
  return (
    <>
      <MessageWindow
        size="md"
        show={showMessage.show}
        onHide={() => setShowMessage({...showMessage, show: false})}
        title={showMessage.title}
        message={showMessage.message} />

      <Row>
        <Col md={2} style={{minHeight: 600}}>
          <h5>Hırsızlık Koruması</h5>
          <Select
            value={selectedVehicle}
            onChange={handleSelectVehicle}
            placeholder={<FormattedMessage id="GENERAL.PICK_DEVICE"/>}
            options={deviceList.map((device) => {
              return {value: device.device_id, label: device.name}
            })}
          />

        </Col>
        <Col md={10}>
          <div style={modalStyles}>&nbsp;</div>
          <TheftProtectionMap
            zones={zones}
            onDrawEnd={handleDrawEnd}
            selectedDevice={selectedDevice}
            {...props} />
        </Col>
      </Row>
      <Row style={{marginTop: 5}}>
        <Col md={12}>
          <Step1
            deviceId={selectedVehicle}
            onDelete={handleZoneDelete}
            onSubmit={handleZoneSubmit}
            zones={zones} />
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = function(state) {
  return {
    devices: state.deviceListReducer.devices
  }
};

export default connect(mapStateToProps)(TheftProtection2);
