import React, { useState, useEffect } from 'react';
import {Col, Row, Form, Button} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

function Step2(props) {

    const [disabled, setDisabled] = useState(true);
    const [cars, setCars] = useState({
        "sms_ignition": 0,
        "sms_contact": 0,
        "main_power_off": 0,
        "saveAll": 0
    });

    const handleChangeCars = (event) => {
        setCars({...cars, [event.target.id]: event.target.checked ? 2: 0});
    };

    useEffect(() => {
        if (props.deviceId) {
            setCars({...cars, ...props.data});
            setDisabled(false);
        }
    }, [props.data]);

    return (
        <>
            <div className="theft-title"><FormattedMessage id="THEFT.STEP2"/></div>
            <Row style={{marginTop: 5, paddingTop: 5}}>
                <Col xs={12}>
                <Form.Check
                    type="switch"
                    id="main_power_off"
                    onChange={handleChangeCars}
                    checked={Boolean(cars.main_power_off)}
                    disabled={disabled}
                    label={<FormattedMessage id="THEFT.AKU"/>}
                />
                </Col>
            </Row>
            <Row style={{marginTop: 5, paddingTop: 5}}>
                <Col xs={12}>
                <Form.Check
                    type="switch"
                    id="sms_ignition"
                    onChange={handleChangeCars}
                    checked={Boolean(cars.sms_ignition)}
                    disabled={disabled}
                    label={<FormattedMessage id="THEFT.WHEN_DEVICE_RUN"/>}
                />
                </Col>
            </Row>
            <Row style={{marginTop: 5, paddingTop: 5}}>
                <Col xs={12}>
                <Form.Check
                    type="switch"
                    id="sms_contact"
                    onChange={handleChangeCars}
                    checked={Boolean(cars.sms_contact)}
                    disabled={disabled}
                    label={<FormattedMessage id="THEFT.WHEN_DOOR_OPEN"/>}
                />
                </Col>
            </Row>
            <Row style={{marginTop: 5, paddingTop: 5}}>
                <Col xs={12}>
                    <Form.Check
                        type="switch"
                        id="saveAll_step2"
                        onChange={(event) => setCars({...cars, saveAll:event.target.checked ? 1:0})}
                        checked={Boolean(cars.saveAll)}
                        disabled={disabled}
                        label={<FormattedMessage id="THEFT.SAVEALL"/>}
                    /><br />
                    <Button onClick={() => props.onSubmit(cars)}
                        disabled={disabled}
                        block variant="success"><FormattedMessage id="GENERAL.SAVE"/></Button>
                </Col>
            </Row>
        </>
    )
};

export default Step2;