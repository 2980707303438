import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';

import WebSiteIntegrationService from '../../services/WebSiteIntegration';

function WebSiteIntegration() {

    const webSiteIntegrationService = new WebSiteIntegrationService();

    const [link, setLink] = useState('');
    const [userDeviceOnMapShow, setUserDeviceOnMapShow] = useState(false);

    const getDeviceOnMapToken = () => {
        setUserDeviceOnMapShow(false);
        webSiteIntegrationService.getDeviceOnMapToken()
        .then((response) => {
            let userDeviceOnMap = "https://gps-takip-sistemi.com/device_on_map.html?" + response.data.token;
            setLink(userDeviceOnMap)
            setUserDeviceOnMapShow(true);
        });
    }

    return (
		<>
            <div className="col-md-12">
                <h3><FormattedMessage id="WEB_INTEGRATION.TITLE"/></h3>
                <p><FormattedMessage id="WEB_INTEGRATION.CONTENT1"/></p>
                {
                    !userDeviceOnMapShow ?  <button className="btn btn-info" onClick={() => getDeviceOnMapToken()}>Get Link</button>
                    :
                    null
                }
                {
                    userDeviceOnMapShow ?  <p><b>URL:</b> <input className="col-md-10" type="text" value={link} style={{color: '#00f'}}/></p>
                    :
                    null
                }
            </div>
		</>
	);
}

export default (WebSiteIntegration);