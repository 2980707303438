import {transform} from 'ol/proj';
import Draw from 'ol/interaction/Draw';
import {unByKey} from 'ol/Observable';
import {Control} from 'ol/control';
import {Style, Text, Fill, Stroke, Icon} from 'ol/style';
import {getTopLeft, getBottomRight} from 'ol/extent';

var DrawControl = (function (Control) {
    function DrawControl(vectorSource, opt_options) {
      this.options = opt_options || {};
      this.vectorSource = vectorSource;
      this.drawEndListener = null;
      this.active = false;
      this.draw = null;
      this.handlers = {
        "onareadrawend": () => {},
        "onpointdrawend": () => {}
      };
  
      this.button = document.createElement('button');
      this.button.innerHTML = 'D';
  
      var element = document.createElement('div');
      element.className = 'measure-button ol-unselectable ol-control';
      element.style.left = "5.5em";
      element.appendChild(this.button);
  
      Control.call(this, {
        element: element,
        target: this.options.target
      });

      this.button.addEventListener('click', this.handleDraw.bind(this), false);
    }
  
    if ( Control ) DrawControl.__proto__ = Control;
    DrawControl.prototype = Object.create( Control && Control.prototype );
    DrawControl.prototype.constructor = DrawControl;

    DrawControl.prototype.register = function register (name, _function) {
      this.handlers[name] = _function;
    };

    DrawControl.prototype.handleDraw = function handleDraw () {
      this.active = !this.active;

      if (this.active) {
        this.button.classList.add('active');
        this.draw = new Draw({
          source: this.vectorSource,
          type: this.options.drawType
        });

        this.drawEndListener = this.draw.on('drawend', (evt) => {
          var coordinates = [];
          var count = 1;
          var feature = evt.feature;
          var coords = feature.getGeometry().getCoordinates();
          var extents = feature.getGeometry().getExtent();
          let name = feature.ol_uid;

          if (this.options.drawType === "Polygon") {
            const polygonStyle = new Style({
              text: new Text({
                text: name,
                scale: 2,
                fill: new Fill({
                  color: '#000',
                }),
                stroke: new Stroke({
                  color: '#fff',
                  width: 2
                }),
                overflow: true
              }),
              stroke: new Stroke({
                color: '#176288',
                lineDash: [4],
                width: 1
              }),
              fill: new Fill({
                color: 'rgba(23, 98, 136, 0.1)'
              })
            });

            feature.setStyle([polygonStyle]);

            let maxLatLong = transform(getTopLeft(extents), 'EPSG:3857', 'EPSG:4326');
            let minLatLong = transform(getBottomRight(extents), 'EPSG:3857', 'EPSG:4326');
            let avgLat = (maxLatLong[1] + minLatLong[1]) / 2;
            let avgLong = (maxLatLong[0] + minLatLong[0]) / 2;

            coords[0].forEach((element, index, arr) => {
              coordinates.push(transform(element, 'EPSG:3857', 'EPSG:4326'));

              if (count === arr.length) {
                let jsonData = {
                  "coordinates": coordinates,
                  "maxLatLong": maxLatLong,
                  "minLatLong": minLatLong,
                  "avgLat": avgLat,
                  "avgLong": avgLong,
                  "id": name
                };

                this.handlers["onareadrawend"](jsonData);
              }
              count++;
            });
          }

          if (this.options.drawType === "Point") {
            let iconStyle = new Style({
              image: new Icon({
                anchor: [0.5, 1],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                scale: 0.7,
                src: this.options.imgSrc
              }),
              text: new Text({
                text: name,
                scale: 1.3,
                backgroundFill: new Fill({
                  color: 'rgba(255, 255, 255, 0.7)',
                }),
                padding: [6, 5, 3, 8],
                fill: new Fill({
                  color: '#176288',
                }),
                backgroundStroke: new Stroke({
                  color: '#176288',
                  width: 1
                }),
                offsetY: 33
              })
            });

            feature.setStyle([iconStyle]);

            let pointData = {
              "portal_id": name,
              "point_name": name,
              "dec_long": transform(coords, 'EPSG:3857', 'EPSG:4326')[0],
              "dec_lat": transform(coords, 'EPSG:3857', 'EPSG:4326')[1],
              "icon_type": 0
            };

            this.handlers["onpointdrawend"](pointData);
          }

        });

        this.getMap().addInteraction(this.draw);
      }
      else {
        this.button.classList.remove('active');
        unByKey(this.drawEndListener);
        this.getMap().removeInteraction(this.draw);
        this.draw = null;
      }
    };
  
    return DrawControl;
  }(Control));

  export default DrawControl;