import './index.css';

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateUserInformation } from '../../redux/actions';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "./images/ufuk-arac-takip-sistemleri-logo.png";
import CookieConsent from "react-cookie-consent";

import Auth from '../../services/Auth';


function Login(props) {
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState('');
    const [authentication_warning, setAccessToken] = useState(false);

    const auth = new Auth();

    if (localStorage.getItem('listType') === null) {
        localStorage.setItem('listType', ('standart'));
    }

    if (localStorage.getItem('sortType') === null) {
        localStorage.setItem('sortType', ('name'));
    }

    const handleChangeUsername = (e) =>{
        setUsername(e.target.value);
    };

    const handleChangePassword = (e) =>{
        setPassword(e.target.value);
    };

    const onSubmit = async () => {
        await auth.getToken(username, password)
        .then(response => response.data)
        .then(responseJson => {
          if(responseJson.access_token) {
            let b64 = btoa(unescape(encodeURIComponent(responseJson.access_token)));
            sessionStorage.setItem('access_token', b64);
            let _userData = btoa(unescape(encodeURIComponent(username + " " + password)));
            sessionStorage.setItem('user', _userData);
            sessionStorage.setItem('userId', responseJson.user_id)

            setRedirect('/');
          }
          else {
            setAccessToken(true);
            console.warn("Authentication error!");
          }
        })
        .catch((err) => {
            setAccessToken(true);
            console.warn("Login Server Connection Error!" + err);
        });
    };

    useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            setRedirect("/");
        }

        //modal+
        // var modals = document.getElementById("myModal");
        // var modals2 = document.getElementById("myModal2");
        // var spans = document.getElementsByClassName("close-modals")[0];
        // var spans2 = document.getElementsByClassName("close-modals-2")[0];
        // var spans3 = document.getElementsByClassName("close-modals-3")[0];
        // var spans4 = document.getElementsByClassName("close-modals-4")[0];
        // var labels = document.getElementsByClassName("open-modals")[0];
        // var openModals = document.getElementsByClassName("open-modals-text-wrapper")[0];
        // var hideModals = document.getElementsByClassName("hide-modals")[0];

        // if (modals2) {
        //     openModals.addEventListener("click", function() {
        //         modals2.style.display = "flex";
        //     });
        //     window.onclick = function(event) {
        //         if (event.target == modals2) {
        //             modals2.style.display = "none";
        //         }
        //     }
        //     spans3.onclick = function() {
        //         modals2.style.display = "none";
        //     }
        //     spans4.onclick = function() {
        //         modals2.style.display = "none";
        //     }
        //     hideModals.addEventListener("click", function() {
        //         if (hideModals.classList.contains("-opened")) {
        //             labels.style.right = '-93px';
        //             hideModals.classList.remove("-opened")
        //         } else {
        //             labels.style.right = '-51px';
        //             hideModals.classList.add("-opened")
        //         }
            
        //     });
        // }

        // if (modals) {
        //     window.onload = function() {
        //         modals.style.display = "flex";
        //     };
        //     window.onclick = function(event) {
        //         if (event.target == modals) {
        //             modals.style.display = "none";
        //         }
        //     }
        //     spans.onclick = function() {
        //         modals.style.display = "none";
        //     }
        //     spans2.onclick = function() {
        //         modals.style.display = "none";
        //     }
        // }
    }, []);

    const handleKeyPress = (e) =>{
        if (e.key === 'Enter') {
            onSubmit();
          }
    };
  
    if (redirect)
        return <Redirect to={redirect} />;
    return (
        <div>
            <div>
                <div className="container" style={{paddingTop: 10}}>
                    <div className="row">
                        {/* <h5 style={{backgroundColor: "#FF0000", color: "#FFF", marginBottom: "2rem", padding: "1rem"}}>
                            15-10-2020 tarihinde saat 10:00 - 13:00 arası sunucularımızda bakım yapılacaktır. Anlayışınız için teşekkür ederiz...
                        </h5> */}
                        <img src={Logo} alt="horizont" style={{display: 'block', margin:'auto'}}/>
                    </div>
                    <div className="row" style={{alignItems:'center', justifyContent:'center'}}>
                        <div className="col-md-5 col-md-offset-5">
                        <h4 className="text-center">Kullanıcı Adı ve Şifrenizi Giriniz</h4>
                            {
                                authentication_warning === true ?
                                <div style={{textAlign:'center'}} className="alert alert-danger" role="alert" data-dismiss="alert">Kullanıcı Adı veya Şifre Hatalı.<br></br> Lütfen Tekrar Deneyiniz.</div> : null

                            }
                            <form id="login_form" onKeyPress={handleKeyPress}>
                                <div className="form-group">
                                    <input name="username" className="form-control" placeholder="Kullanıcı Adı" type="text" onChange={handleChangeUsername}/> 
                                </div>
                                <div className="form-group">
                                    <input name="password" className="form-control" placeholder="Şifre" type="password" onChange={handleChangePassword}/>         
                                </div>
                                <button onClick={onSubmit} type="button" className="btn btn-primary btn-block">Giriş Yap</button>

                                <div className="form-group">
                                    <p style={{textAlign:'center'}}><br></br><a href="https://www.gps-takip-sistemi.com/v1/password-reset.php">Şifremi unuttum</a></p>
                                </div>
                                <hr />
                                <div className="form-group">
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.gps-takip-sistemi.com/v1/templates/agb.html">Kullanım Koşulları ve Sözleşmesi</a> - 
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.gps-takip-sistemi.com/v1/templates/haftung.html">Gizlilik ilkesi</a> - 
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.gps-takip-sistemi.com/v1/templates/impressum.html">İletişim</a>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="row" style={{height: 150, borderBottom: '3px solid #eee', borderTop: '3px solid #eee'}}>
                        <div className="col-md-12">
                            <table border="0">
                                <tbody>
                                    <tr>
                                        <td align="center">
                                            <br />
                                            <span style={{fontSize: 18}}>Bilgi almak için müşteri temsilcilerimizi arayın: </span><br /><br />
                                            <span className="sp2"><i className="fa fa-phone"></i> 0 (232) 339 60 06</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <br />
                            <p>
                                <b>PAKETE NELER DAHİL:<br />
                                - 12 Ay SİM Kart Faturası Dahil.<br />
                                - 12 Ay Harita (OpenStreet) Bedeli Dahil .<br />
                                - Kargo (Ücretsiz)<br />
                                - 12 Aylık Sözleşme Dahil<br />
                                - 2.Yıl kullanım mecburiyeti yoktur.<br />
                                </b>
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <CookieConsent
                location='bottom'
                buttonText="Tamam"
                cookieName="cookie"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "white", fontSize: "17px", background: "#4b81e8", borderRadius: "5px"}}>
                Web sitemizi en iyi şekilde sunmak ve geliştirmek için çerezleri kullanıyoruz. Sitemize devam ederseniz bunu kabul etmiş olursunuz. <a target="_blank" style={{color:'white',textDecoration:'underline'}} rel="noopener noreferrer" href="https://www.ufuk-aractakip.com.tr/gizlilik-ilkesi">Gizlilik Politikası</a>
            </CookieConsent>

            <div class="open-modals">
                <div class="open-modals-text-wrapper">
                <span class="open-modals-text">Kampanya</span>
                </div>
                <span class="hide-modals -opened">X</span>
            </div>
            <div id="myModal2" class="modals">
                <div class="modals-content">
                    <span class="modals-title">3 SATIN AL, 2 ÖDE</span>
                    <img class="modals-img" src={require("../Assets/images/kampanya-gps-takip-cihazi.jpg").default} alt="modal" />
                    <span class="modals-para">3 Takip Cihazı Satın Aldığınızda 2 Takip Cihazı Ücreti Ödersiniz.</span>
                    <div class="modals-footer">
                        <span>Örneğin: 12 takip cihazı satın alımınızda 8 GPS takip cihazı için ödeme yaparsınız.</span>
                    </div>
                    <span class="close-modals-3">Kapat</span>
                    <span class="close-modals-4">X</span>
                </div>
            </div>

            <div id="myModal" class="modals">
                <div class="modals-content">
                <span class="modals-title">3 SATIN AL, 2 ÖDE</span>
                <img class="modals-img" src={require("../Assets/images/kampanya-gps-takip-cihazi.jpg").default} alt="modal" />
                <span class="modals-para">3 Takip Cihazı Satın Aldığınızda 2 Takip Cihazı Ücreti Ödersiniz.</span>
                <div class="modals-footer">
                    <span>Örneğin: 12 takip cihazı satın aldığınızda 8 GPS takip cihazı için ödeme yaparsınız.</span>
                </div>
                <span class="close-modals">Kapat</span>	
                <span class="close-modals-2">X</span>
                </div>
            </div>
        </div>
    );
  }

const mapDispatchToProps = {
    updateUserInformation,
};

export default connect(null, mapDispatchToProps)(Login);