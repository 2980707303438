import BaseService from './BaseService';

class SupportService extends BaseService {

  sendTechnicalReport(name, company, phone, email, message, call_time) {
    return this.axiosInstance.get(`/send_mail/technical_support/?name=${name}&company=${company}&phone=${phone}&email=${email}&message=${message}&call_time=${call_time}`, {cancelToken: this.cancelTokenSource.token});
  }

}

export default SupportService;