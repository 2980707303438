import React from 'react';
import { connect } from 'react-redux';


function SignalInputs(props) {
    const signal = props.signal;
    let tag = '';
    var inputs = [];
    var input_name;
    
    for (var i = 0; i < signal.input_count; i++) {
        input_name = i+2;

        if (signal['input_' + input_name] === 1) {
            tag = "green" + input_name;
        } else {
            tag = "red" + input_name;
        }
        
        inputs.push( 
            props.listType === 'standart_big'?
            <img key={i} src={require('../Assets/images/standart-big/' + tag + '.png').default} alt=""/>
            :
            <img key={i} src={require('../Assets/images/' + tag + '.gif').default} alt=""/>
        );
    }

    if(signal.input_count > 0){
        return (
            <a style={{width: 29, display: 'inline-block'}}>
               {inputs}
            </a>
        );
    }
    else{
        return null;
    }
    
}

const mapStateToProps = function(state) {
    return {
        listType: state.deviceListTypeReducer.listType
    }
};

export default connect(mapStateToProps)(SignalInputs);
