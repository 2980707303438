import React, {useState, useEffect} from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

function Contacts(props) {

    const [data, setData] = useState({...props.data});

    const handleChange = (event) => {
        let value;
        if (event.target.type === "checkbox") {
            value = event.target.checked ? 1 : 0;
        }
        else {
            value = event.target.value;
        }

        setData({...data, [event.target.id]: value});
    };

    const handleSubmit = () => {
        props.onSubmit(data);
    };

    useEffect(() => {
        setData({...props.data});
    }, [props.data]);

    return (
        <>
            <Row>
                <Col sm={4}>
                    <Form.Group controlId="contact_2_name">
                        <Form.Label><FormattedMessage id="SERVICE.IGNITION"/> 2</Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.contact_2_name} />
                    </Form.Group>

                    <Form.Group controlId="contact_2_pull">
                        <Form.Check
                            onChange={handleChange}
                            checked={data.contact_2_pull}
                            type="checkbox"
                            label="Çevir" />
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group controlId="contact_3_name">
                        <Form.Label><FormattedMessage id="SERVICE.IGNITION"/> 3</Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.contact_3_name} />
                    </Form.Group>

                    <Form.Group controlId="contact_3_pull">
                        <Form.Check
                            onChange={handleChange}
                            checked={data.contact_3_pull}
                            type="checkbox"
                            label="Çevir" />
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group controlId="contact_4_name">
                        <Form.Label><FormattedMessage id="SERVICE.IGNITION"/> 4</Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.contact_4_name} />
                    </Form.Group>

                    <Form.Group controlId="contact_4_pull">
                        <Form.Check
                            onChange={handleChange}
                            checked={data.contact_4_pull}
                            type="checkbox"
                            label="Çevir" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group controlId="contact_5_name">
                        <Form.Label><FormattedMessage id="SERVICE.IGNITION"/> 5</Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.contact_5_name} />
                    </Form.Group>

                    <Form.Group controlId="contact_5_pull">
                        <Form.Check
                            onChange={handleChange}
                            checked={data.contact_5_pull}
                            type="checkbox"
                            label="Çevir" />
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group controlId="contact_6_name">
                        <Form.Label><FormattedMessage id="SERVICE.IGNITION"/> 6</Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.contact_6_name} />
                    </Form.Group>

                    <Form.Group controlId="contact_6_pull">
                        <Form.Check
                            onChange={handleChange}
                            checked={data.contact_6_pull}
                            type="checkbox"
                            label="Çevir" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <Button onClick={handleSubmit} variant="primary" type="button">
                        <FormattedMessage id="GENERAL.SAVE"/>
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default Contacts;