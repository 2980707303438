import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Select from 'react-select';
import {FormattedMessage} from 'react-intl';
import './index.css';

import DriverService from '../../services/Drivers';

function Reports(props) {
  
  const [drivers, setDrivers] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const driverService = new DriverService();

  const deviceList = props.devices.map(item => {
    return {name: item['name'], device_id: item['device_id']}
  });

  const handleSelectVehicle = (selectedOption) => {
    setSelectedVehicle(selectedOption);
  };

  const handleSelectDriver = (selectedOption) => {
    setSelectedDriver(selectedOption);
  };

  const fetchData = async () => {
    try {
      return await driverService.getDrivers()
        .then(async (response) => {
          return response.data;
        });

    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData()
      .then((data) => {
        if (data.length > 0) setDrivers(data);
      });

    //unmount
    return () => {
      driverService.getCancelToken().cancel();
    }
  }, []);

  return (
    <div className="reports">
      <div className="select-page">
        <h3><FormattedMessage id="MENU.REPORTS"/></h3>
        <img className="header-img" src={require("./images/reports_bg.png").default} alt=""/>
        <br/>
        <br/>
        <div className="col-md-3 device-reports">
          <p className="text-center"><b><FormattedMessage id="REPORTS.DEVICE_REPORTS"/></b></p>
          <div className="form-group">
            <Select
              value={selectedVehicle}
              onChange={handleSelectVehicle}
              placeholder={<FormattedMessage id="GENERAL.PICK_DEVICE"/>}
              options={deviceList.map((device) => {
                return {value: device.device_id, label: device.name}
              })}
            />
          </div>
          <br/>
          <p className="report-icon">
            <Link to={`/electronic-reports/daily/device/${selectedVehicle ? selectedVehicle.value : 0}`}>
              <img src={require("./images/daily-reports.gif").default} alt=""/>
              <br/>
              <FormattedMessage id="REPORTS.DAILY"/>
            </Link>
          </p>
          <p className="report-icon">
            <Link to={`/electronic-reports/monthly/device/${selectedVehicle ? selectedVehicle.value : 0}`}>
              <img src={require("./images/montly-reports.gif").default} alt=""/>
              <br/>
              <FormattedMessage id="REPORTS.MONTHLY"/>
            </Link>
          </p>
          <p className="report-icon">
            <Link to={`/electronic-reports/route/device/${selectedVehicle ? selectedVehicle.value : 0}`}>
              <img src={require("./images/route-reports.gif").default} alt="route-report"/>
              <br/>
              <FormattedMessage id="REPORTS.ROUTE"/>
            </Link>
          </p>
          <p className="report-icon">
            <Link to={`/electronic-reports/drivers/device/${selectedVehicle ? selectedVehicle.value : 0}`}>
              <img src={require("./images/driver-reports.gif").default} alt=""/>
              <br/>
              <FormattedMessage id="MENU.DRIVERS_LIST"/>
            </Link>
          </p>
          <p className="report-icon">
            <Link to={`/electronic-reports/hourly/device/${selectedVehicle ? selectedVehicle.value : 0}`}>
              <img src={require("./images/hourly-reports.png").default} alt=""/>
              <br/>
              <FormattedMessage id="GENERAL.HOURLY_REPORT"/>
            </Link>
          </p>
        </div>
        <div className="col-md-3 device-reports">
          <p className="text-center"><b><FormattedMessage id="REPORTS.DRIVER_REPORTS"/></b></p>
          <div className="form-group">
            <Select
              value={selectedDriver}
              onChange={handleSelectDriver}
              placeholder={<FormattedMessage id="GENERAL.PICK_DRIVER"/>}
              options={drivers.map((driver) => {
                return {value: driver.driver_id, label: (driver.driver_first_name + " " + driver.driver_last_name)}
              })}
            />
          </div>
          <br/>
          <p className="report-icon">
            <Link to={`/electronic-reports/daily/driver/${selectedDriver ? selectedDriver.value : 0}`}>
              <img src={require("./images/daily-reports.gif").default} alt=""/>
              <br/>
              <FormattedMessage id="REPORTS.DAILY"/>
            </Link>
          </p>
          <p className="report-icon">
            <Link to={`/electronic-reports/monthly/driver/${selectedDriver ? selectedDriver.value : 0}`}>
              <img src={require("./images/montly-reports.gif").default} alt=""/>
              <br/>
              <FormattedMessage id="REPORTS.MONTHLY"/>
            </Link>
          </p>
        </div>
        <div className="clearfix"/>
      </div>

    </div>

  );

}

const mapStateToProps = function (state) {
  return {
    devices: state.deviceListReducer.devices
  }
};
export default connect(mapStateToProps)(Reports);


