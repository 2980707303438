import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import moment from 'moment';
import {connect} from 'react-redux';
import {Col, Row, Form, Button, Table} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import DateFilter from '../../Commons/dateFilter';
import Loading from '../../Commons/loading';
import MessageWindow from '../../Commons/messageWindow';
import {saveAs} from "file-saver";
import {dayDiff} from "../../Assets/utils";

import SpeedReportsMap from '../../Map/speedReports';

import AutoPilotSpeedWarningsService from '../../../services/AutoPilotSpeedWarnings';
import Export from '../../../services/Export';
import Signals from '../../../services/Signals';

function SpeedReport(props) {

  const deviceId = props.match.params.deviceId;
  const autoPilotSpeedWarningsService = new AutoPilotSpeedWarningsService();
  const exportService = new Export();
  const signalService = new Signals();

  let _startDate = new Date();
  let _endDate = new Date();

  _startDate.setHours(0);
  _startDate.setMinutes(0);
  _endDate.setHours(23);
  _endDate.setMinutes(59);

  const [startDate, setStartDate] = useState(_startDate);
  const [endDate, setEndDate] = useState(_endDate);
  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState([]);
  const [signals, setSignals] = useState([]);
  const [reports, setReports] = useState([]);
  const [showMessage, setShowMessage] = useState({
    message: '',
    title: '',
    status: false
  });

  const fetchData = async () => {

    try {
      setLoading(true);
      let diff = dayDiff(startDate, endDate);

      if (-1 > diff)
        setShowMessage({
          title: <h5>Error</h5>,
          message: <h5>Başlangıç tarihi bitiş tarihinden büyük olamaz.</h5>,
          status: true
        });

      else if (diff > 2)
        setShowMessage({
          title: <h5>Error</h5>,
          message: <h5>Tarih aralığı 3 günden fazla olamaz.</h5>,
          status: true
        });

      else {
        Promise.all([
          signalService.getSignalsDateRange(deviceId, moment(startDate).format("YYYY-MM-DD H:mm:00"), moment(endDate).format("YYYY-MM-DD H:mm:59")),
          autoPilotSpeedWarningsService.get(deviceId, moment(startDate).format("YYYY-MM-DD H:mm:00"), moment(endDate).format("YYYY-MM-DD H:mm:59"))])
          .then(async ([signalsResponse, warningsResponse]) => {
            let promises = [];
            setSignals(signalsResponse.data[0].signals);
            setDevice({
              type: signalsResponse.data[0].type,
              name: signalsResponse.data[0].name,
              device_id: signalsResponse.data[0].device_id,
              address: "",
              speed_km: "0"
            });

            warningsResponse.data.forEach((_response) => {
              promises.push(signalService.getSignalAddress(deviceId, _response.dec_lat, _response.dec_long))
            });

            await Promise.all(promises)
              .then((results) => {
                const reports = results.map((result, index) => {
                  warningsResponse.data[index]['address'] = result.data.address;
                  return warningsResponse.data[index];
                });
                setReports(reports);
              });
          });
      }

    } catch (e) {
      setShowMessage({
        title: <h5>Error</h5>,
        message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
        status: true
      })
    } finally {
      setLoading(false);
    }

  };

  // onclick handler
  const handleExport = async () => {
    const start = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    const end = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
    setLoading(true);

    try {
      await exportService.exportSpeedReport(deviceId, start, end, reports, 'xlsx')
        .then((response) => {
          let blob = new Blob([response.data]);
          saveAs(blob, `Hız_Raporu_${deviceId}.xlsx`);
        });

    } catch (e) {
      setShowMessage({
        title: <h5>Error</h5>,
        message: <h5>Rapor oluşturulamadı.</h5>,
        status: true
      });

    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    fetchData();

    return () => {
      autoPilotSpeedWarningsService.getCancelToken().cancel();
    };
  }, [deviceId]);

  return (
    <>
      <Row>
        <Col sm={3}>
          <h6><FormattedMessage id={"GENERAL.FILTER_OPTIONS"}/></h6>
          <DateFilter
            arrows={false}
            showTime={true}
            dateFormat="dd-MM-yyyy HH:mm:00"
            selectedDate={startDate}
            onChange={(date) => setStartDate(date)}
            wrapperClassName="react-datepicker-wrapper-full-width mb-1"/>

          <DateFilter
            arrows={false}
            showTime={true}
            dateFormat="dd-MM-yyyy HH:mm:59"
            selectedDate={endDate}
            onChange={(date) => setEndDate(date)}
            wrapperClassName="react-datepicker-wrapper-full-width"/><br/>

          <Button onClick={fetchData} variant="success" size="sm" block>
            <FormattedMessage id="GENERAL.FILTER"/>
          </Button>

          <hr/>

          <Link className="btn btn-primary btn-block btn-sm" to={`/signals/${deviceId}`}>
            <FormattedMessage id="ROUTES.SHOW_ROUTES"/>
          </Link>

          <Link className="btn btn-primary btn-block btn-sm" to={`/geofence-reports/${deviceId}`}>
            <FormattedMessage id="QMENU.GEOFENCE_REPORTS"/>
          </Link>

          <Link className="btn btn-primary btn-block btn-sm" to={`/geofence-reports-2/${deviceId}`}>
            <FormattedMessage id="QMENU.GEOFENCE_REPORTS"/> - 2
          </Link>

          <Link className="btn btn-primary btn-block btn-sm disabled" to={`/speed-report/${deviceId}`}>
            <FormattedMessage id="USER_RIGHTS.speed"/>
          </Link>

          <Button variant="primary" size="sm" block onClick={handleExport}>
            <FormattedMessage id="GENERAL.EXPORT_EXCEL"/>
          </Button>
        </Col>

        <Col style={{minHeight: 600}} sm={9}>
          {loading ? (<Loading/>) : null}
          <SpeedReportsMap
            device={device}
            signals={signals}
            points={reports}
            active="horizont"/>

          <Table striped className="table" responsive="sm">
            <thead>
            <tr>
              <th><FormattedMessage id="GENERAL.DATE"/></th>
              <th><FormattedMessage id="GENERAL.ADDRESS"/></th>
              <th><FormattedMessage id="SIGNALS.LAT"/></th>
              <th><FormattedMessage id="SIGNALS.LONG"/></th>
              <th><FormattedMessage id="AUTOPILOT.NOTIFICATION_TYPE"/></th>
              <th><FormattedMessage id="AUTOPILOT.SPEED_LIMIT"/></th>
              <th><FormattedMessage id="AUTOPILOT.VEHICLE_SPEED"/></th>
            </tr>
            </thead>
            <tbody>
              {
                reports.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{moment(item.geocode_sent_at).format("DD-MM-YYYY H:mm:ss")}</td>
                      <td>{item.address}</td>
                      <td>{item.dec_lat}</td>
                      <td>{item.dec_long}</td>
                      <td>{item.limit_type}</td>
                      <td>{item.speed_limit}</td>
                      <td>{item.speed_km}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      <MessageWindow
        size='md'
        show={showMessage.status}
        onHide={() => setShowMessage({...showMessage, status: false})}
        title={showMessage.title}
        message={showMessage.message}
      />
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    devices: state.deviceListReducer.devices
  }
};

export default connect(mapStateToProps)(SpeedReport);