import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import DatePicker from "react-datepicker";
import moment from 'moment';

import './index.css';

function IvdcService(props) {

	const [data, setData] = useState({...props.data});

	const handleChangeActivation = (event) => {
		if (!event.target.checked) {
			let obj = {};

			Object.keys(data).forEach((key) => {
				obj[key] = '';
			});

			obj["aktif"] = 0;
			setData({...obj});

		} else {
			setData({...data, aktif: 1});
		}

	};

	const alwaysHide = (hide) => {
		let value = hide ? 1 : 0;
		let obj = {};

		Object.keys(data).forEach((key) => {
			obj[key] = '';
		});

		obj["aktif"] = value;
		props.onSubmit(obj);
	};

	const handleSubmit = () => {
		props.onSubmit(data);
	};

	useEffect(() => {
		setData({...props.data});
	}, [props.data]);

	return (
		<Container fluid>
			<Row>
				<Col md={6} xs={12} className="service-border-box">
					<div className="service-title"><FormattedMessage id="SERVICE.HIDE_DEVICE_FOR_TIME"/></div>
					<Row>
						<Col md={6} xs={12}>
							<Row>
								<Col xs={4}><b><FormattedMessage id="GENERAL.DAY"/></b></Col>
								<Col xs={4}><b><FormattedMessage id="GENERAL.START"/></b></Col>
								<Col xs={4}><b><FormattedMessage id="GENERAL.STOP"/></b></Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.MONDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.day1_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day1_start: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.day1_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day1_stop: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.TUESDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.day2_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day2_start: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.day2_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day2_stop: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.WEDNESDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.day3_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day3_start: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.day3_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day3_stop: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.THURSDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.day4_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day4_start: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.day4_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day4_stop: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.FRIDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.day5_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day5_start: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.day5_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day5_stop: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row>
								<Col xs={4}><FormattedMessage id="DAYS.SATURDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.day6_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day6_start: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.day6_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day6_stop: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
							<div className='service-divider'/>
							<Row className='mb-2'>
								<Col xs={4}><FormattedMessage id="DAYS.SUNDAY"/></Col>
								<Col xs={4}>
									<DatePicker value={data.day7_start} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day7_start: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
								<Col xs={4}>
									<DatePicker value={data.day7_stop} showTimeSelect
															showTimeSelectOnly timeIntervals={1} timeCaption="Time"
															onChange={date => setData({...data, day7_stop: moment(date).format("HH:mm")})}
															disabled={false}
															dateFormat="HH:mm" timeFormat="HH:mm"/>
								</Col>
							</Row>
						</Col>
						<Col xs={1}/>
						<Col md={4} xs={12}>
							<b><FormattedMessage id="SERVICE.ACTIVATION_SETTINGS"/></b>
							<div className='service-divider'/>
							<div><FormattedMessage id="GENERAL.START_DATE"/></div>
							<DatePicker
								todayButton="Today"
								popperPlacement="bottom-start"
								dateFormat="dd-MM-yyyy"
								selected={data.start_date}
								onChange={(date) => setData({...data, start_date: date})}/>
							<div style={{marginTop: 5}}><FormattedMessage id="GENERAL.END_DATE"/></div>
							<DatePicker
								todayButton="Today"
								popperPlacement="bottom-start"
								dateFormat="dd-MM-yyyy"
								selected={data.stop_date}
								onChange={(date) => setData({...data, stop_date: date})}/>
							<br/><br/>
							<Form.Check
								type="switch"
								id="aktif"
								onChange={handleChangeActivation}
								checked={Boolean(data.aktif)}
								label={<FormattedMessage id="GENERAL.ACTIVE"/>}
							/>
						</Col>
					</Row>
					<div className='service-divider'/>
					<Row>
						<Col md={4} xs={12}>
							<Button onClick={() => handleSubmit()} block variant="primary" type="button">
								<FormattedMessage id="GENERAL.SAVE"/>
							</Button>
						</Col>
					</Row>
				</Col>
				<Col xs='auto'/>
				<Col md={4} xs={12} className="service-border-box">
					<div className="service-title"><FormattedMessage id="SERVICE.HIDE_DEVICE_FOR_ALWAYS"/></div>
					<p><FormattedMessage id="SERVICE.HIDE_DEVICE_FOR_ALWAYS_COMMENT"/></p>
					<Row>
						<Col md={6} xs={12}>
							{
								props.data.aktif?
									<Button onClick={() => alwaysHide( false)} block variant="primary" type="button">
										<FormattedMessage id="SERVICE.SHOW_ALWAYS"/>
									</Button>
								:
									<Button onClick={() => alwaysHide(true)} block variant="primary" type="button">
										<FormattedMessage id="SERVICE.HIDE_ALWAYS"/>
									</Button>
							}
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
}

export default IvdcService;