const langTr = {
    tr: {
        "LANG.TR": "Türkçe",
        "LANG.EN": "İngilizce",
        "LANG.DE": "Almanca",
        "LANG.NL": "Hollandaca",
        "GENERAL.DEVICES": "Araçlar",
        "GENERAL.DRIVERS": "Sürücüler",
        "GENERAL.DATE": "Tarih",
        "GENERAL.KM": "Kilometre",
        "GENERAL.PRIVATE": "Özel",
        "GENERAL.SAVE": "Kaydet",
        "GENERAL.SAVE_ALL": "Hepsini Kaydet",
        "GENERAL.START": "Başlangıç",
        "GENERAL.STOP": "Bitiş",
        "GENERAL.ADDRESS": "Adres",
        "GENERAL.START_DATE": "Başlangıç Tarihi",
        "GENERAL.END_DATE": "Bitiş Tarihi",
        "GENERAL.START_ADDRESS": "Başlangıç Adresi",
        "GENERAL.END_ADDRESS": "Bitiş Adresi",
        "GENERAL.TRIP_TIME": "Seyahat Süresi",
        "GENERAL.WAIT_TIME": "Bekleme Süresi",
        "GENERAL.IDLE_TIME": "Rölanti Süresi",
        "GENERAL.ACTIVE_TIME": "Aktif Süre",
        "GENERAL.TOTAL_TIME": "Toplam Süre",
        "GENERAL.TIME": "Zaman",
        "GENERAL.TOTAL": "Toplam",
        "GENERAL.DRIVER": "Sürücü",
        "GENERAL.YEAR": "Yıl",
        "GENERAL.MONTH": "Ay",
        "GENERAL.DAY": "Gün",
        "GENERAL.DAYS": "Gün",
        "GENERAL.KMH": "km/s",
        "GENERAL.HOUR": "Saat",
        "GENERAL.MINUTE": "Dakika",
        "GENERAL.MIN": "Dk.",
        "GENERAL.SECOND": "Saniye",
        "GENERAL.SEC": "Sn.",
        "GENERAL.TURN_BACK": "Geri Dön",
        "GENERAL.YES": "Evet",
        "GENERAL.NO": "Hayır",
        "GENERAL.NR": "No",
        "GENERAL.DEMO": "Demo",
        "GENERAL.BUY": "Satın Al!",
        "GENERAL.NO_DATA": "Veri Bulunamadı",
        "GENERAL.ERROR": "Bir hata oluştu. Lütfen tekrar deneyin.",
        "GENERAL.PLS_PICK_DEVICE": "Lütfen bir araç seçin",
        "GENERAL.PICK_DEVICE": "Bir araç seçin",
        "GENERAL.PICK_DRIVER": "Bir sürücü seçin",
        "GENERAL.CLOSE": "Kapat",
        "GENERAL.PLEASE_WAIT": "Lütfen bekleyin..",
        "GENERAL.ADD": "Ekle",
        "GENERAL.EDIT": "Düzenle",
        "GENERAL.DELETE": "Sil",
        "GENERAL.FILTER": "Filtrele",
        "GENERAL.FILTER_OPTIONS": "Filtre Seçenekleri",
        "GENERAL.PRINT": "Yazıcıdan Çıkart",
        "GENERAL.EXPORT_PDF": "PDF Çıktısı",
        "GENERAL.EXPORT_EXCEL": "Excel Çıktısı",
        "GENERAL.UNDEFINED": "Tanımsız",
        "GENERAL.USER_NAME": "Kullanıcı Adı",
        "GENERAL.PASSWORD": "Şifre",
        "GENERAL.PASSWORD_AGAIN": "Şifre (Tekrar)",
        "GENERAL.NAME": "Adı",
        "GENERAL.SURNAME": "Soyadı",
        "GENERAL.MAIL": "E-Posta",
        "GENERAL.CITY": "Şehir",
        "GENERAL.CHANGE": "Değiştir",
        "GENERAL.ALL": "Tümü",
        "GENERAL.PASSIVE": "Pasif",
        "GENERAL.SERVICE": "Hizmet",
        "GENERAL.REQUIRED_FIELDS": "Zorunlu Alanlar",
        "GENERAL.SELECT": "Seçiniz",
        "GENERAL.SELECTED": "Seçili",
        "GENERAL.ACTIVE": "Aktif",
        "GENERAL.SMS": "SMS",
        "GENERAL.NOTIFICATIONS": "Bildirimler",
        "GENERAL.FAX": "Fax",
        "GENERAL.TEL": "Tel",
        "GENERAL.DESCRIPTION": "Açıklama",
        "GENERAL.TOTAL_MILEAGE": "Toplam Gidilen Yol",
        "GENERAL.OK": "Tamam",
        "GENERAL.NEW": "Yeni",
        "GENERAL.CANCEL": "Vazgeç",
        "GENERAL.DEVICE_NAME": "Araç İsmi",
        "GENERAL.MORNING": "Sabah",
        "GENERAL.EVENING": "Akşam",
        "GENERAL.AUTO_RELOAD_ON": "Sayfanın 2 dakikada bir yenilenmesini istiyorsanız tıklayın.",
        "GENERAL.AUTO_RELOAD_OFF": "Sayfa 2 dakikada bir yenileniyor..",
        "GENERAL.PREVIOUS": "Önceki",
        "GENERAL.NEXT": "Sonraki",
        "GENERAL.WARNING": "Uyarı",
        "GENERAL.RESULTS_PER_PAGE": "Sayfa Başına Sonuç :",
        "GENERAL.PREV_DAY": "Önceki Gün",
        "GENERAL.NEXT_DAY": "Sonraki Gün",
        "GENERAL.ICON": "İkon",
        "GENERAL.MAIL_WRITE": "E-Posta adreslerinizi yazınız",
        "GENERAL.PHONE_WRITE": "Telefon numaranızı yazınız",
        "GENERAL.CHOOSE": "Seçiniz",
        "GENERAL.STEP": "Adım",
        "GENERAL.SAVE_FOR_ALL_DEVICE": "Tüm araçlarım için kaydet",
        "GENERAL.SEARCH": "Ara",
        "GENERAL.FILTER_RESULTS": "Sonuçları Filtrele",
        "GENERAL.USER": "Kullanıcı",
        "GENERAL.INACTIVE": "Pasif",
        "GENERAL.TODAY": "Bugün",
        "GENERAL.21_FUNCTIONS": "Bir bakışta 21 fonksiyon!",
        "GENERAL.TOGGLEALL": "Tümünü Seç",
        "GENERAL.LAST": "Son",
        "GENERAL.PHONE_NUMBER": "0232 339 60 06",
        "GENERAL.PHONE_NUMBER_TEXT": "Telefon Destek",
        "GENERAL.IDLE": "Boşta - Kontak Açık",
        "GENERAL.ONE_HOUR": "1 saatten fazla GPS/GSM sinyali alınamıyor",
        "GENERAL.ONE_HOUR_LINK": "1 saatten beri güncel konumu olmayan cihazlar",
        "GENERAL.LATEST_SIGNAL_DATE": "Son sinyal tarihi",
        "GENERAL.NORESULT": "Sonuç Bulunamadı",
        "GENERAL.NO_ACCESS": "Bu seçeneği online olarak satın alamazsınız. Lütfen destek ile iletişime geçin 030 3553 0677 0",
        "GENERAL.HIDE_DEVICE_CONTENT": "Özel Rota'nin dataları kayıt olamamaktadır.",
        "GENERAL.HIDE_ATTENTION": "Dikkat! Özel amaçlı sürüşleriniz için Aktif seçimini tercih ederseniz Data kaydedilmeyecektir.",
        "GENERAL.DISABLE": "Pasif",
        "GENERAL.SOMETHING_WENT_WRONG": "Birşeyler ters gitti. Lütfen sayfayı yenileyiniz.",
        "GENERAL.HOURLY_REPORT": "Saatlik Rapor",
        "DAYS.MONDAY": "Pazartesi",
        "DAYS.TUESDAY": "Salı",
        "DAYS.WEDNESDAY": "Çarşamba",
        "DAYS.THURSDAY": "Perşembe",
        "DAYS.FRIDAY": "Cuma",
        "DAYS.SATURDAY": "Cumartesi",
        "DAYS.SUNDAY": "Pazar",
        "DAYS_NUMERIC.DAY_1": "Pazartesi",
        "DAYS_NUMERIC.DAY_2": "Salı",
        "DAYS_NUMERIC.DAY_3": "Çarşamba",
        "DAYS_NUMERIC.DAY_4": "Perşembe",
        "DAYS_NUMERIC.DAY_5": "Cuma",
        "DAYS_NUMERIC.DAY_6": "Cumartesi",
        "DAYS_NUMERIC.DAY_0": "Pazar",
        "MONTHS_NUMERIC.MONTH_1": "Ocak",
        "MONTHS_NUMERIC.MONTH_2": "Şubat",
        "MONTHS_NUMERIC.MONTH_3": "Mart",
        "MONTHS_NUMERIC.MONTH_4": "Nisan",
        "MONTHS_NUMERIC.MONTH_5": "Mayıs",
        "MONTHS_NUMERIC.MONTH_6": "Haziran",
        "MONTHS_NUMERIC.MONTH_7": "Temmuz",
        "MONTHS_NUMERIC.MONTH_8": "Ağustos",
        "MONTHS_NUMERIC.MONTH_9": "Eylül",
        "MONTHS_NUMERIC.MONTH_10": "Ekim",
        "MONTHS_NUMERIC.MONTH_11": "Kasım",
        "MONTHS_NUMERIC.MONTH_12": "Aralık",
        "HOME.SORT": "Sıralama",
        "HOME.SORT_ALL_DEVICES": "Bütün nesneleri sırala.",
        "HOME.SORT_BY_GROUP": "Grup halinde sırala.",
        "HOME.FIRST_RUN": "İlk Çalışma",
        "HOME.IGNITION": "Ateşleme",
        "HOME.DAILY_MILEAGE": "Günlük Km",
        "HOME.SPEED": "Hız",
        "HOME.RESULTS": "Sonuçlar",
        "HOME.SHOWING": "Göster",
        "HOME.DISPLAY": "Görünüm",
        "HOME.LIST_TYPE": "Liste Tipi",
        "HOME.LIST_TYPE_STANDART": "Standart",
        "HOME.LIST_TYPE_STANDART_BIG": "Standart Yeni",
        "HOME.LIST_TYPE_KLEIN": "Klein",
        "HOME.LIST_TYPE_MINI": "Mini",
        "HOME.LIST_TYPE_DETAILERT": "Detaylı",
        "HOME.LIST_TYPE_GROSSANSICHT": "Büyük Görünüm",
        "HOME.LIST_TYPE_ROUND": "Yuvarlak",
        "HOME.LIST_TYPE_MODERN": "Modern",
        "HOME.OPTIONS": "Seçenekler",
        "HOME.DEVICE_SEARCH": "Araç Arama",
        "HOME.ALL_ROUTES": "Tüm Rotalar",
        "HOME.CUSTOMER_REPORTS": "Müşteri Raporları",
        "HOME.SHOW_SELECTED": "Seçili Olanları Göster",
        "HOME.DEVICE_FILTER": "Gösterilecek Araçlar",
        "HOME.CURRENT_COORDINATES": "Güncel Koordinatlar",
        "HOME.COPY_COORDINATES": "Koordinatları CTRL-C ile kopyalabilir ve herhangi bir haritanın adres arama bölümüne CTRL-V ile yapıştırabilirsiniz.",
        "HOME.AUFTRAGS": "Auftrags App",
        "HOME": "Anasayfa",
        "MENU.ALL_DEVICES": "Tüm Araçlar",
        "MENU.ROUTES": "Rota Sorgulama",
        "MENU.REPORTS": "Elektronik Raporlar",
        "MENU.REPORTS2": "Raporlar",
        "MENU.FUNCTIONS": "Diğer Fonksiyonlar",
        "MENU.THEFT": "Hırsızlık Koruması",
        "MENU.SERVICE": "Araç Servisi",
        "MENU.RENT": "Araç Kiralama",
        "MENU.GROUPS": "Araç Grupları",
        "MENU.USER_RIGHTS": "Kullanıcı Hakları",
        "MENU.TECHNICAL_SUPPORT": "Destek",
        "MENU.APPLICATIONS": "Ücretsiz Uygulamalar",
        "MENU.OPINIONS": "Görüşleriniz",
        "MENU.LANGUAGE": "Dil Seçimi",
        "MENU.TIMEZONE": "Zaman Dilimi",
        "MENU.SETTINGS": "Ayarlar",
        "MENU.LOGOUT": "Oturumu Kapat",
        "MENU.DRIVERS_LIST": "Sürücü Listesi",
        "MENU.MYLOCATIONS": "Konumlarım",
        "MENU.CUSTOMER_GROUPS": "Müşteri Grupları",
        "MENU.SMS_CREDITS": "SMS Siparişleri",
        "MENU.APPS": "Apple & Android Uygulamaları",
        "MENU.ANDROID_APP": "Horizont Android Uygulaması",
        "MENU.APPLE_APP": "Horizont Apple Uygulaması",
        "MENU.INSTRUCTIONS": "Talimatlar",
        "MENU.APPLE_APP2": "Yeni Horizont Apple Uygulaması",
        "MENU.SATALLITE": "Uydu Bilgileri",
        "MENU.SAVED_PLACES": "Kayıtlı alanlar",
        "MENU.EDIT_PLACES": "Alan Kaydet-Düzenle",
        "MENU.PLACES": "Rota Kontrolü",
        "MENU.ROUTE_TRACKING": "Rota Takibi",
        "MENU.GRAPHIC": "Grafikler",
        "MENU.WEB_INTEGRATION": "Web sitenize entegrasyon",
        "MENU.NOTIFICATION_SETTINGS": "Hava Alarmı",
        "MENU.DUAL_SCREEN": "Çift Ekran",
        "MENU.LATEST_LOGIN": "En Son Giriş",
        "MENU.LOGIN_DATE": "Giriş Zamanı",
        "MENU.PLATFORM": "Platform",
        "MENU.BROWSER": "Tarayıcı",
        "MENU.BROWSER_VERSION": "Tarayıcı Versiyonu",
        "MENU.LOGIN_DEVICES": "Oturum Açılan Cihazlar",
        "MENU.APP_VERSION": "Uygulama Versiyonu",
        "MENU.PLATFORM_VERSION": "Platform Versiyonu",
        "MENU.DATA_DELETION": "Veri Silme",
        "TEMPLATES.CHOOSE": "Tema Seçimi",
        "TEMPLATES.TEMPLATE_DEFAULT": "Varsayılan",
        "TEMPLATES.TEMPLATE_1": "Tema 1",
        "TEMPLATES.TEMPLATE_2": "Tema 2",
        "TEMPLATES.TEMPLATE_3": "Tema 3",
        "TEMPLATES.TEMPLATE_4": "Tema 4",
        "TEMPLATES.TEMPLATE_5": "Tema 5",
        "TEMPLATES.TEMPLATE_6": "Tema 6",
        "QMENU.LOCATION": "Konum",
        "QMENU.ROUTE": "Rota",
        "QMENU.LIVE": "Canlı",
        "QMENU.DAILY_REPORT": "Günlük Rapor",
        "QMENU.MONTHLY_REPORT": "Aylık Rapor",
        "QMENU.SPEED_CHART": "Hız Grafiği",
        "QMENU.FUEL": "Yakıt Tüketimi",
        "QMENU.AUTO_PILOT": "Oto Pilot",
        "QMENU.GRAPHS": "Grafikler",
        "QMENU.GEOFENCE_REPORTS": "Geofence Raporları",
        "QMENU.OTHER": "Diğer Fonksiyonlar",
        "QMENU.COLORED_ROUTE": "Renkli Rota",
        "QMENU.OP_ROUTE": "Optimize Rotalar",
        "QMENU.AUTO_PLAY": "Auto Play",
        "GRAPHS.START_STOP_TIMES": "Başlangıç - Bitiş Saatleri",
        "GRAPHS.LAST_SEVEN_DAY": "Günlük Sürüş Süreleri",
        "GRAPHS.LAST_SEVEN_DAY_KM": "Günlük Katedilen Yollar",
        "GRAPHS.DRIVE_TIME": "Sürüş Süresi",
        "GRAPHS.DRIVE_KM": "Katedilen Yol",
        "GRAPHS.TODAY_SPEED": "Bugüne Ait Hız / Zaman Grafiği",
        "GRAPHS.YESTER_CON": "Düne Ait Hareket Durumu",
        "GRAPHS.MAX_SEVEN": "En fazla 1 haftalık tarih aralığı seçebilirsiniz.",
        "GRAPHS.ALLDEVKM": "Araçların günlük katettiği yollar",
        "GRAPHS.ALLDEVSPEED": "Araçların Hızları",
        "GRAPHS.AVG_SPEED": "Ortalama Hız",
        "GRAPHS.MAX_SPEED": "Maksimum Hız",
        "GRAPHS.downloadJPEG": "JPEG Resmi İndir",
        "GRAPHS.downloadPNG": "PNG Resmi İndir",
        "GRAPHS.downloadSVG": "SVG Vektörü İndir",
        "GRAPHS.downloadPDF": "PDF Dökümanı İndir",
        "GRAPHS.printChart": "Grafiği Yazdır",
        "GRAPHS.loading": "Yükleniyor...",
        "GRAPHS.contextButtonTitle": "Grafik Menüsü",
        "GRAPHS.drillUpText": "{series.name} Geri Dön",
        "GRAPHS.invalidDate": "Hatalı Tarih",
        "GRAPHS.noData": "Gösterilecek veri yok",
        "GRAPHS.resetZoom": "Zoom'u Sıfırla",
        "GRAPHS.ACT_STATUS": "Toplam Hareket Durumu",
        "GRAPHS.FUEL_CHART": "Günlük Yakıt Miktarı Grafiği",
        "GRAPHS.FUEL_TABLE": "Günlük Yakıt Miktarı Tablosu",
        "GRAPHS.START_FUEL": "Gün Başı Yakıt Miktarı",
        "GRAPHS.END_FUEL": "Gün Sonu Yakıt Miktarı",
        "GRAPHS.FUEL_CONSUME": "Yakıt Tüketimi",
        "GRAPHS.FUEL_BUY": "Yakıt Alımı",
        "GRAPHS.TEMP_CHART": "Günlük Isı Sensörü Grafiği",
        "GRAPHS.TEMP_TABLE": "Günlük Isı Sensörü Tablosu",
        "GRAPHS.TEMP_AVG": "Günlük Ortalama Sıcaklık",
        "GRAPHS.FUEL_PER": "Yakıt Yüzdesi",
        "GRAPHS.FUEL_LT": "Yakıt Litresi",
        "GRAPHS.WEATHER_FORECAST": "Hava Durumu Tahmini",
        "GRAPHS.FUEL_FULLING": "Depo Fulleme",
        "GRAPHS.RPM": "Devir",
        "GRAPHS.RPM_CHART": "Günlük Devir Grafiği",
        "GRAPHS.FUEL_GES": "Kilometerstand beim Tanken KM",
        "GRAPHS.FUEL_GETANKE": "Getankte Liter",
        "GRAPHS.VOLLTANK": "Details zum Volltank",
        "GRAPHS.WARM_CHART": "Motor Sıcaklığı Grafiği",
        "GRAPHS.SEVEN_FIRST_LAST": "7 Günlük İlk Çalıştırma ve Son Durma",
        "GRAPHS.INFO": "Detaylı bilgi için imleci grafik üzerinde gezdirin.",
        "SIGNALS.SHOW_MAP": "Haritayı Göster",
        "SIGNALS.SHOW_STREET_NAMES": "Sokak İsimlerini Göster",
        "SIGNALS.SHOW_SIGNALS": "Sinyalleri Göster",
        "SIGNALS.ONLY_STARTSTOP": "Sadece Kalkış ve Duruşları Göster",
        "SIGNALS.EXCEPT_ZERO": "Sadece Hız Bilgisi Olanları Göster",
        "SIGNALS.SHOW_THESE": "Şu Sensörleri Göster",
        "SIGNALS.SIGNAL_TYPE": "Sinyal Tipi",
        "SIGNALS.LOC_CHG": "Konum Değiştirme",
        "SIGNALS.SIGNAL_STR": "Sinyal Gücü",
        "SIGNALS.NR_SAT": "Uydu Sayısı",
        "SIGNALS.AKU_VOLT": "Akü Gerilimi",
        "SIGNALS.BAT_VOLT": "Batarya Gerilimi",
        "SIGNALS.IN_OUT": "Giriş / Çıkış",
        "SIGNALS.TOTAL_DURATION": "Toplam Süre",
        "SIGNALS.IN": "Girdi",
        "SIGNALS.OUT": "Çıktı",
        "SIGNALS.MAX_3_DAYS": "En fazla 3 günlük bir aralık seçebilirsiniz.",
        "SIGNALS.signal_mode_0": "Periyodik sinyal",
        "SIGNALS.signal_mode_1": "--",
        "SIGNALS.signal_mode_2": "Stand By",
        "SIGNALS.signal_mode_3": "Start",
        "SIGNALS.signal_mode_4": "Stop",
        "SIGNALS.signal_mode_5": "--",
        "SIGNALS.signal_mode_6": "--",
        "SIGNALS.signal_mode_7": "--",
        "SIGNALS.signal_mode_8": "--",
        "SIGNALS.signal_mode_9": "--",
        "SIGNALS.signal_mode_10": "--",
        "SIGNALS.signal_mode_11": "Kontakt 2 açıldı",
        "SIGNALS.signal_mode_12": "Kontakt 2 kapatıldı",
        "SIGNALS.signal_mode_13": "Kontakt 3 açıldı",
        "SIGNALS.signal_mode_14": "Kontakt 3 kapatıldı",
        "SIGNALS.signal_mode_15": "Kontakt 4 açıldı",
        "SIGNALS.signal_mode_16": "Kontakt 5 kapatıldı",
        "SIGNALS.signal_mode_17": "--",
        "SIGNALS.signal_mode_18": "--",
        "SIGNALS.signal_mode_19": "--",
        "SIGNALS.signal_mode_20": "--",
        "SIGNALS.signal_mode_21": "--",
        "SIGNALS.signal_mode_22": "--",
        "SIGNALS.signal_mode_23": "--",
        "SIGNALS.signal_mode_24": "--",
        "SIGNALS.signal_mode_25": "--",
        "SIGNALS.signal_mode_26": "--",
        "SIGNALS.signal_mode_27": "--",
        "SIGNALS.signal_mode_28": "--",
        "SIGNALS.signal_mode_29": "--",
        "SIGNALS.signal_mode_30": "--",
        "SIGNALS.signal_mode_31": "--",
        "SIGNALS.signal_mode_32": "--",
        "SIGNALS.signal_mode_33": "--",
        "SIGNALS.signal_mode_34": "--",
        "SIGNALS.signal_mode_35": "--",
        "SIGNALS.signal_mode_36": "--",
        "SIGNALS.signal_mode_37": "--",
        "SIGNALS.signal_mode_38": "--",
        "SIGNALS.signal_mode_39": "--",
        "SIGNALS.signal_mode_40": "--",
        "SIGNALS.signal_mode_41": "--",
        "SIGNALS.signal_mode_42": "--",
        "SIGNALS.signal_mode_43": "--",
        "SIGNALS.signal_mode_44": "--",
        "SIGNALS.signal_mode_45": "--",
        "SIGNALS.signal_mode_46": "--",
        "SIGNALS.signal_mode_47": "--",
        "SIGNALS.signal_mode_48": "--",
        "SIGNALS.signal_mode_49": "--",
        "SIGNALS.signal_mode_50": "--",
        "SIGNALS.signal_mode_51": "--",
        "SIGNALS.signal_mode_52": "--",
        "SIGNALS.signal_mode_53": "--",
        "SIGNALS.signal_mode_54": "--",
        "SIGNALS.signal_mode_55": "--",
        "SIGNALS.signal_mode_56": "--",
        "SIGNALS.signal_mode_57": "--",
        "SIGNALS.signal_mode_58": "--",
        "SIGNALS.signal_mode_59": "--",
        "SIGNALS.signal_mode_60": "--",
        "SIGNALS.signal_mode_61": "--",
        "SIGNALS.FUEL": "Yakıt Miktarı",
        "SIGNALS.ENGINE": "Motor Devri",
        "SIGNALS.ENGINE_TEMP": "Motor Sıcaklığı",
        "SIGNALS.LAT": "Enlem",
        "SIGNALS.LONG": "Boylam",
        "SERVICE.VEHICLE_NAME": "Araç Adı",
        "SERVICE.CURRENT_MILEAGE": "Anlık Kilometre",
        "SERVICE.INSTALL_MILEAGE": "Kurulum Kilometresi",
        "SERVICE.AVG_FUEL": "Ortalama Yakıt Tüketimi",
        "SERVICE.CURRENT_RUN_TIME": "Anlık Çalışma Süresi",
        "SERVICE.INSTALL_RUN_TIME": "Kurulum Çalışma Süresi",
        "SERVICE.EMAIL_ADDRESS": "Servis Uyarı Mail Adresleri ",
        "SERVICE.INFORMED_BY_MAIL": "Servis uyarılarını mail ile almak istiyorum",
        "SERVICE.CURRENT_ICON": "Mevcut Araç İkonu",
        "SERVICE.ICON_GALLERY": "İkon Galerisi",
        "SERVICE.CHOOSE_NEW_ICON": "Buradan yeni bir ikon seçebilirsiniz",
        "SERVICE.BASIC_SETTINGS": "Temel Ayarlar",
        "SERVICE.IGNITION": "Kontak",
        "SERVICE.IGNITIONS": "Kontaklar",
        "SERVICE.OIL_SERVICE": "Yağ Servisi",
        "SERVICE.BRAKE_SERVICE": "Fren Servisi",
        "SERVICE.BELT_SERVICE": "Triger Kayışı Servisi",
        "SERVICE.INSPECTION_SERVICE": "Muayene/Emisyon",
        "SERVICE.TACHOGRAPH_SERVICE": "Takograf Servisi",
        "SERVICE.TRAILER_SERVICE": "Römork Servisi",
        "SERVICE.HIDE_SERVICE": "Araç Gizleme",
        "SERVICE.HIDE_SERVICE_DATA": "Özel Sürüş Veri Silme",
        "SERVICE.FUEL_SERVICE": "Yakıt Sensörü",
        "SERVICE.TEMP_SERVICE": "Isı Sensörü",
        "SERVICE.SERVICE_INTERVAL": "Servis Sıklığı",
        "SERVICE.NEW_SERVICE_HISTORY": "Yeni Servis Kaydı",
        "SERVICE.CREATE_SERVICE_HISTORY": "Yeni servis kaydı oluştur",
        "SERVICE.DELETE_SERVICE_HISTORY": "Kayıt Sil",
        "SERVICE.DELETE_CONFIRM": "Kaydı silmek istediğinize emin misiniz?",
        "SERVICE.DELETE_SUCCESS": "Kayıt Silindi",
        "SERVICE.DELETE_ERROR": "Kayıt silinemedi. Bir hata oluştu.",
        "SERVICE.BACK_TO_SERVICE": "Servise dönmek için tıklayın",
        "SERVICE.SERVICE_HISTORY": "Geçmiş Servis Kayıtları",
        "SERVICE.UPDATE_SUCCESS": "Bilgiler başarıyla kaydedildi.",
        "SERVICE.UPDATE_ERROR": "Kaydetme sırasında bir hata oluştu. Lütfen tekrar deneyin.",
        "SERVICE.INTERVAL_MONTH": "Süre (Ay)",
        "SERVICE.ACTIVATION_SETTINGS": "Aktifleştirme Ayarları",
        "SERVICE.DATE_RANGE": "Tarih Aralığı",
        "SERVICE.ACTIVE_STATUS": "Aktif Durumu",
        "SERVICE.RESET_DATES": "Tarihleri Sıfırla",
        "SERVICE.RESET_ALL_DATA": "Tüm Verileri Sıfırla",
        "SERVICE.ROMORK_PLATE": "Römork Plakası",
        "SERVICE.ALERT_TOP": "Servis uyarılarınız var!",
        "SERVICE.ALERT_KM": "Bir sonraki service kalan kilometre:",
        "SERVICE.ALERT_DATE": "Bir sonraki servise kalan süre (gün):",
        "SERVICE.ALERT_KM_EXCEEDED": "Servis kilometreniz geçmiş görünüyor lütfen geçmiş servis kayıtlarınızı kontrol edin.",
        "SERVICE.ALERT_DATE_EXCEEDED": "Servis tarihiniz geçmiş görünüyor lütfen geçmiş servis kayıtlarınızı kontrol edin.",
        "SERVICE.INTERVAL_INFO": "Servis sıklığını kilometre olarak ya da aylık bazlı olarak belirleyebilirsiniz",
        "SERVICE.CREATE_HISTORY_INFO": "Yukarıdaki tarih ve km ile yeni bir kayıt oluşturmak isterseniz kutucuğu işaretleyin",
        "SERVICE.IN_DEMO": "Bu özelliğin demo sürümünü kullanıyorsunuz.",
        "SERVICE.DEMO_EXPIRED": "Seçtiğiniz cihaz için bu özelliğin demo kullanım süresi doldu.",
        "SERVICE.DEMO_OR_BUY": "Seçtiğiniz cihaz için bu özelliği kullanmak istiyorsanız şimdi satın alabilir veya 10 günlük deneme sürümünü kullanabilirsiniz",
        "SERVICE.NOT_PERMITTED": "Seçtiğiniz cihaz için bu özelliği kullanma izniniz bulunmamaktadır.",
        "SERVICE.NOT_PERMITTED_MULTI": "Seçtiğiniz cihazlardan bazıları ya da tümü için bu özelliği kullanma izniniz bulunmamaktadır.",
        "SERVICE.IVDC_DATE_ALERT": "Başlangıc tarihi bitiş tarihinden büyük olamaz",
        "SERVICE.HIDE_DEVICE_FOR_TIME": "Aracı belirli zamanlarda gizleme",
        "SERVICE.HIDE_DEVICE_FOR_ALWAYS": "Aracı her zaman gizleme",
        "SERVICE.HIDE_DEVICE_FOR_ALWAYS_COMMENT": "Bu aracı herhangi bir zamana bağlı olmadan her zaman gizler",
        "SERVICE.HIDE_ALWAYS": "Aracı Gizle",
        "SERVICE.SHOW_ALWAYS": "Aracı Göster",
        "SERVICE.DEVICE_HIDDEN": "Bu araç şu an gizli.",
        "SERVICE.DEVICE_PRIVATE": "Özel sürüş şu an aktif. Hiçbir veri kaydedilmez!",
        "SERVICE.IVDC_INFO": "Saat ve tarih seçimlerini yaptıktan sonra ayarları aktifleştirmek için kutuyu işaretleyin",
        "SERVICE.SERVICE_ALERT": "Servis Uyarısı!",
        "SERVICE.SERVICE_TYPE_1": "Yağ Servsi",
        "SERVICE.SERVICE_TYPE_2": "Fren Servisi",
        "SERVICE.SERVICE_TYPE_3": "Triger Kayışı",
        "SERVICE.SERVICE_TYPE_4": "Emisyon/Muayene",
        "SERVICE.SERVICE_TYPE_5": "Takograf Servisi",
        "SERVICE.SERVICE_TYPE_6": "Römork Servisi",
        "SERVICE.POSTPONE_ALERTS": "Serviceanzeige fuer 7 Tage nicht anzeigen",
        "SERVICE.PRIVATE_DRIVE": "Aktif etmeniz durumunda verileriniz kaydedilmeyecek!",
        "SERVICE.PRIVATE_DRIVE_FOR_TIME": "Belirli bir süre için özel sürüş. Lütfen dönemi seçin!",
        "SERVICE.PRIVATE_DRIVE_FOR_ALWAYS": "Her zaman özel sürüş ",
        "SERVICE.PRIVATE_DRIVE_FOR_ALWAYS_COMMENT": "Kalıcı bir özel gezi için lütfen tıklayınız. Aktif etmeniz durumunda verileriniz kaydedilmeyecek!",
        "SERVICE.PRIVATE_SHOW_ALWAYS": "Özel Sürüş Deaktif Et",
        "SERVICE.PRIVATE_HIDE_ALWAYS": "Özel Sürüş Aktif Et",
        "SERVICE.FUEL_INFO": "Yakıt deposu hacmi (lt): Yakıt deposunu fulledikten sonra yakıt miktarını ( litre olarak ) giriniz:",
        "SERVICE.INFO_CURRENT_MILEAGE": "Aracın toplam kilometresi (kurulum kilometresi dahil)",
        "SERVICE.INFO_AVG_FUEL": "Aracın 100 kilometrede tükettiği ortalama yakıt miktarı",
        "SERVICE.INFO_INSTALL_MILEAGE": "Aracın cihaz takılmadan önceki toplam kilometresi",
        "SERVICE.INFO_INSTALL_RUN_TIME": "Aracın cihaz takılmadan önceki toplam tahmini aktif çalışma süresi",
        "SERVICE.INFO_CURRENT_RUN_TIME": "Aracın toplam aktif çalışma süresi (kurulum çalışma süresi dahil)",
        "SERVICE.FUEL_INFO2": "Montajdan sonra ‚Araç Servisi‘ altinda bulunan yakıt sensörü bölümünde yüzdelik olarak yakıt miktarını girin. Ardından portala girip yakıt mikltarı verisini kontrol edin.",
        "SERVICE.STEP1": "Adım 1",
        "SERVICE.STEP2": "Adım 2",
        "GROUPS.ALL": "Tüm Gruplar",
        "GROUPS.NAME": "Grup Adı",
        "GROUPS.UPDATE": "Grubu Güncelle",
        "GROUPS.CREATE": "Grup Oluştur",
        "GROUPS.EDIT_NAME": "Grup Adını Düzenle",
        "GROUPS.DELETE": "Grubu Sil",
        "GROUPS.CONFIRM_DELETE": "Grubu silmek istediğinize emin misiniz?",
        "GROUPS.DELETE_SUCCESS": "Grup başarıyla silindi.",
        "GROUPS.DELETE_ERROR": "Grup silinirken bir hata oluştu. Lütfen tekrar deneyin.",
        "GROUPS.CREATE_NEW_GROUP": "Yeni Grup Oluştur",
        "GROUPS.FILL_GROUP_NAME": "Lütfen bir grup adı girin",
        "GROUPS.DEVICES_IN_GROUP": "Gruptaki Araçlar",
        "GROUPS.ADD_REMOVE_DEVICE": "Araç Ekle - Çıkar",
        "GROUPS.SELECTED_DEVICES": "Seçilen Araçlar",
        "GROUPS.DEVICES_TO_ADD": "Gruba Eklenecek Araçlar",
        "REPORTS.DAILY": "Günlük Rapor",
        "REPORTS.MONTHLY": "Aylık Rapor",
        "REPORTS.ROUTE": "Rota Raporu",
        "REPORTS.DEVICE_REPORTS": "Araç Raporları",
        "REPORTS.DRIVER_REPORTS": "Sürücü Raporları",
        "REPORTS.WITH_DRIVERS": "Sürücü Adı Göster",
        "REPORTS.ONLY_PRIVATE": "Sadece Özel Seferler",
        "REPORTS.MANUAL_TRIP": "Elle Eklenen Sefer",
        "REPORTS.DAY_TOTAL": "Günlük Toplam",
        "REPORTS.ALL_TOTAL": "Genel Toplam",
        "REPORTS.ROUTE_NO": "Rota No",
        "REPORTS.ADD_TRIP": "Sefer Ekle",
        "REPORTS.EDIT_TRIP": "Seferi Düzenle",
        "REPORTS.ADD_TRIP_INFO": "Bu seferin altına yeni bir sefer eklemek için tıklayın",
        "REPORTS.DELETE_TRIP_INFO": "Bu seferi silmek için tıklayın",
        "REPORTS.DELETE_TRIP": "Seferi Sil",
        "REPORTS.CONFIRM_DELETE_TRIP": "Bu seferi silmek istediğinize emin misiniz?",
        "REPORTS.DAILY_STANDBY": "Dinlenme Süresi",
        "REPORTS.TOTAL_ACTIVE_TIME": "Toplam Aktif Süre",
        "REPORTS.REPORT_DETAIL": "Rapor Detayı",
        "REPORTS.ADD_REPORT": "Rapor Ekle",
        "REPORTS.DELETE_REPORT": "Raporu Sil",
        "REPORTS.EDIT_REPORT": "Raporu Güncelle",
        "REPORTS.PICK_DEVICE_OR_DRIVER": "Lütfen bir araç ya da sürücü seçin",
        "REPORTS.TRIP_TYPE": "Sefer Tipi",
        "REPORTS.TRIP_TYPE_0": "Bilinmiyor",
        "REPORTS.TRIP_TYPE_1": "Görev",
        "REPORTS.TRIP_TYPE_2": "Özel",
        "REPORTS.TRIP_TYPE_3": "Ev",
        "REPORTS.FAVORITES": "Favoriler",
        "REPORTS.PICK_FAV": "Favori seçin",
        "REPORTS.FAV_DETAIL": "Favori Detayı",
        "REPORTS.FAV_INFO": "Daha önce kaydettiginiz 'Gidilen Yer' kayıtlarınız favorilerinize eklenir. Eğer bu kayıtlar sonraki seferlerdeki bitiş adresi ile eşleşirse aşağıdaki tabloda gösterilir",
        "REPORTS.POST_CODE": "Posta Kodu",
        "REPORTS.ABOUT": "Gidilen Yol",
        "REPORTS.VISITED": "Gidilen Yer",
        "REPORTS.REPORT_TYPE": "Raporlama Türü",
        "REPORTS.SHOW_DEVICE_NAME": "Araç Adı Göster",
        "REPORTS.MONTHLY_REPORT_FOR_ALL_DEVICE": "Tüm araçlar için aylık rapor",
        "REPORTS.DAILY_REPORT_FOR_ALL_DEVICE": "Tüm araçlar için günlük rapor",
        "REPORTS.DAILY_REPORT_THIS_DEVICE": "Bu araç için günlük rapor",
        "REPORTS.SPEED_REPORT_THIS_DEVICE": "Bu araç için hız raporu",
        "REPORTS.SELECT_REPORT": "istenilen raporları seçin",
        "REPORTS.YEARLY": "Yıllık Rapor",
        "REPORTS.YEARLY_PDF": "Yıllık Rapor PDF Çıktısı",
        "REPORTS.SELECT_YEAR": "Yıl Seçiniz",
        "REPORTS.FILTER_BY_KM": "0.0 - 0.2 göster/gizle",
        "REPORTS.DATE_LIMIT_ALERT": "Seçebileceğiniz maksimum tarih aralığı:",
        "REPORTS.DATE_LIMIT_ALERT_2": "Bu tarih aralığını arttırmak için paketinizi yükseltmeniz gerekmektedir. \nPaketinizi yükseltmek için lütfen müşteri hizmetlerimiz ile iletişime geçin.",
        "REPORTS.CANCELED_REPORTS": "İptal Edilen Raporlar",
        "REPORTS.DAYS_7": "7 gün veri arşivleme ile Mesafe görüntüleme",
        "REPORTS.SHOW_SERVICE_TRIPS": "Sadece Görev Sürüşlerini Göster",
        "REPORTS.REPORT_NO": "Rapor No",
        "ALERT.FILL_THE_BLANKS": "Lütfen gerekli alanları doldurunuz.",
        "ALERT.UPDATE_SUCCESS": "Bilgiler başarıyla güncellendi.",
        "ALERT.DELETE_SUCCESS": "Bilgiler başarıyla silindi.",
        "ALERT.SAVE_SUCCESS": "Bilgiler başarıyla kaydedildi.",
        "ALERT.DEVICE_DISABLE": "Bu cihaz kullanıma kapatılmıştır bunun nedenini öğrenmek için lütfen bizimle iletişime geçin.",
        "ALERT.SMS_SENT_SUCCESS": "Sms , başarıyla gönderildi.",
        "ALERT.SMS_SENT_FAIL": "Sms gönderme sırasında bir hata oluştu.",
        "USER_RIGHTS.ALL_USERS": "Tüm Kullanıcılar",
        "USER_RIGHTS.ADD_REMOVE_USER_RIGHT": "Erişim Hakkı Ekle-Çıkar",
        "USER_RIGHTS.NEW_USER": "Yeni Kullanıcı",
        "USER_RIGHTS.REMOVE_USER": "Kullanıcıyı Sil",
        "USER_RIGHTS.UPDATE_USER": "Kullanıcıyı Güncelle",
        "USER_RIGHTS.USER_STATUS": "Kullanıcı Aktif",
        "USER_RIGHTS.ACT_START": "Aktivasyon Başlangıç",
        "USER_RIGHTS.ACT_END": "Aktivasyon Bitiş",
        "USER_RIGHTS.ER_MAIL_AE": "Bu Mail Adresi Alınmış.",
        "USER_RIGHTS.ER_UN_AE": "Bu Kullanıcı Adı Alınmış.",
        "USER_RIGHTS.ER_PW_1": "Girdiğiniz şifreler uyuşmamaktadır. Lütfen kontrol ediniz.",
        "USER_RIGHTS.ER_NN": "Lütfen doldurulması zorunlu olan alanları doldurunuz.",
        "USER_RIGHTS.FILL_OBLIGATION": "Bu alanın doldurulması zorunludur.",
        "USER_RIGHTS.ER_MAIL_VAL": "Geçersiz mail adresi.",
        "USER_RIGHTS.NOTHING_FOUND": "Gösterilecek erişim hakkı bulunamadı.",
        "USER_RIGHTS.CHOOSE_USER": "Kullanıcı seçiniz, ya da yeni kullanıcı ekleyiniz. ",
        "USER_RIGHTS.CONFIRM_DELETE": "Bu alt kullanıcıyı silmek istediğinize emin misiniz ? ",
        "USER_RIGHTS.tracking": "Araç Takip",
        "USER_RIGHTS.live": "Canlı İzleme",
        "USER_RIGHTS.all_tracks": "Tüm Rotalar",
        "USER_RIGHTS.velocity": "Hız Sayfası",
        "USER_RIGHTS.tracking_route": "Rotayı Göster",
        "USER_RIGHTS.unlimited_tracking": "7 günlük sınırı olmadan",
        "USER_RIGHTS.routing": "Rota",
        "USER_RIGHTS.daily": "Günlük Rapor",
        "USER_RIGHTS.monthly": "Aylık Rapor",
        "USER_RIGHTS.drive": "Seyir defteri",
        "USER_RIGHTS.driver": "Sürücü Raporu",
        "USER_RIGHTS.sensoren": "İletişim raporu",
        "USER_RIGHTS.speed": "Hız Raporu",
        "USER_RIGHTS.electronic": "Elektronik rapor",
        "USER_RIGHTS.theft": "Hırsızlık Koruması",
        "USER_RIGHTS.phone_2": "2. Cep Telefonu",
        "USER_RIGHTS.sms": "Sms",
        "USER_RIGHTS.service": "Araç Servisleri",
        "USER_RIGHTS.device_groups": "Araç Gruplama",
        "USER_RIGHTS.roaming1": "Dolaşım",
        "USER_RIGHTS.zugriffsrechte": "Erişim Hakları",
        "USER_RIGHTS.gsm_eplus": "GSM E-Plus",
        "USER_RIGHTS.gsm_tmobile": "GSM T-Mobile",
        "USER_RIGHTS.email_berichte": "günlük / aylık raporlaru E-posta ile gönder",
        "USER_RIGHTS.play": "Animasyon",
        "USER_RIGHTS.USER_TYPE": "Kullanıcı Tipi",
        "USER_RIGHTS.ACTIVE_USERS": "Aktif Kullanıcılar",
        "USER_RIGHTS.PASSIVE_USERS": "Pasif Kullanıcılar",
        "USER_RIGHTS.SUBUSER_DEVICE_NAME": "Alt kullanıcı aracı şu isimle kullansın:",
        "USER_RIGHTS.EDIT_DEVICE_NAME": "Alt kullanıcı araç adını değiştirebilsin",
        "USER_RIGHTS.autoplay": "Otomatik oynatma",
        "USER_RIGHTS.measure": "Mesafe Ölçüm",
        "USER_RIGHTS.signal_tabelle": "Sinyal Sayfası",
        "USER_RIGHTS.rent_a_car": "Araç Kiralama",
        "USER_RIGHTS.meine_standorte": "Alan Belirleme",
        "USER_RIGHTS.auto_pilot": "Otomaik Pilot",
        "USER_RIGHTS.diebstahlschutz_indi": "Hırsızlık Koruması 2",
        "USER_RIGHTS.mobil": "Mobil",
        "USER_RIGHTS.basarsoft_map": "Basarsorft Harita",
        "USER_RIGHTS.18_months": "18 Ay Veri Saklama",
        "USER_RIGHTS.ADD_FAVORI": "Yeni Favori Ekle",
        "USER_RIGHTS.DELETE_FAVORI": "Favori Sil",
        "USER_RIGHTS.UPDATE_FAVORI": "Güncelle",
        "USER_RIGHTS.FAVORI_NAME": "Favori Adı",
        "USER_RIGHTS.NAME_ALARM": "Lütfen Favori Adını Boş Bırakmayınız.",
        "USER_RIGHTS.SELECT_FAVORITE": "Favori Seçiniz",
        "USER_RIGHTS.ALARM_OPTIONS": "Lütfen Opsiyonlardan en az birini seçiniz.",
        "USER_RIGHTS.CONFIRM_FAVORI_DELETE": "Silmek istediğinize emin misiniz?",
        "USER_RIGHTS.SUCCESS_DELETE": "Silme işlemi Başarılı.",
        "USER_RIGHTS.SUCCESS_SAVE": "Kaydetme işlemi Başarılı.",
        "USER_RIGHTS.SUCCESS_UPDATE": "Güncelleme işlemi Başarılı",
        "USER_RIGHTS.FAVORITE_OPTIONS": "Favori Seçenekleri",
        "USER_RIGHTS.FAVORITE_FUNC": "Favori Fonksiyonu",
        "USER_RIGHTS.FAVORITE": "Favori",
        "USER_RIGHTS.SAVEALL": "Bütün araçlar için bu ayarları sağla",
        "USER_RIGHTS.air_notifications": "Hava Alarmı Bildirimleri",
        "RENTACAR.NEW_RENTACAR": "Yeni Kiralama Kaydı Ekle",
        "RENTACAR.OLD_RENTACAR": "Eski Kiralama Kayıtları",
        "RENTACAR.IDENTITY_NO": "Kimlik Numarası",
        "RENTACAR.LICENSE_NO": "Ehliyet Numarası",
        "RENTACAR.LICENSE_CLASS": "Ehliyet Sınıfı",
        "RENTACAR.BIRTH_DATE": "Doğum Tarihi",
        "RENTACAR.PHONE_NO": "Telefon Numarası",
        "RENTACAR.ADDRESS": "Adres",
        "RENTACAR.RENT_DATE": "Kiraya Verildiği Tarih",
        "RENTACAR.RENT_BACK_DATE": "Kiradan Döneceği Tarih",
        "RENTACAR.VEHICLE_KM": "Araç Kilometresi",
        "RENTACAR.FUEL_AMOUNT": "Yakıt Miktarı",
        "RENTACAR.EQUIPMENT": "Ekipman",
        "RENTACAR.NOTES": "Notlar",
        "RENTACAR.RENT_PRICE": "Kiralama Bedeli",
        "RENTACAR.BAILS": "Kefiller",
        "RENTACAR.READ_ACCEPT_AGR1": "Sözleşme Şartları",
        "RENTACAR.READ_ACCEPT_AGR2": "'nı okudum , kabul ediyorum. ",
        "RENTACAR.CHOOSE_DEV": "Bir araç seçiniz.",
        "RENTACAR.DRIVER_NAME": "Sürücü Adı",
        "RENTACAR.CHOOSE": "Seçiniz",
        "RENTACAR.ADD_NEW": "Yeni Ekle",
        "RENTACAR.UPDATE": "Güncelle",
        "RENTACAR.RENTER": "Kiralayan",
        "RENTACAR.DAMAGES": "Hasarlar",
        "GAUGAE.FULL_SCREEN": "Tam Ekran",
        "GAUGAE.LIVE_ON": "Canlı Yenileme Açık",
        "GAUGAE.LIVE_OFF": "Canlı Yenileme Kapalı",
        "GAUGAE.ANIM_ON": "Canlı Animasyon Açık",
        "GAUGAE.ANIM_OFF": "Canlı Animasyon Kapalı",
        "GAUGAE.GAUGAE_OPEN": "Göstergeleri Aç",
        "GAUGAE.GAUGAE_CLOSE": "Göstergeleri Kapa",
        "GAUGAE.SEARCH_ADR": "Adres Arama",
        "GAUGAE.PAGE_NOT_FOUND": "Sayfa bulunamadı",
        "GAUGAE.FUEL_AMOUNT": "Yakıt Miktarı",
        "GAUGAE.TOTAL_KM": "Toplam Km",
        "GAUGAE.DAILY_KM": "Günlük Km",
        "GAUGAE.TEMP": "Sıcaklık",
        "GAUGAE.HUMIDITY": "Nem",
        "GAUGAE.WEATHER_TEXT": "Önümüzdeki günlerde hava koşulları ve hava durumu tahmini.",
        "GAUGAE.CANBUS_TAGES": "7 Günlük KM",
        "GAUGAE.ZURLIVE": "Canlı Yenileme",
        "GAUGAE.ZURANIM": "Canlı Animasyon",
        "GAUGAE.M_DISTANCE": "Ölçülen Mesafe",
        "DRIVERS.GENERAL_INFO": "Genel Bilgiler",
        "DRIVERS.DRIVER_NO": "Sürücü No",
        "DRIVERS.DRIVER_TEL": "Telefon",
        "DRIVERS.LICENSE_CONTROL": "Ehliyet Kontrolü",
        "DRIVERS.FAVORITE_DEVICE": "Favori Araç",
        "DRIVERS.CREATE_DRIVER": "Yeni Sürücü Ekle",
        "DRIVERS.BUTTON_LIST": "i-Button Listesi",
        "DRIVERS.BUTTON_NO": "Anahtar No",
        "DRIVERS.BUTTON_SERIAL_NO": "i-Button Seri No",
        "DRIVERS.BUTTON_TYPE": "i-Button Tipi",
        "DRIVERS.ASSIGN_DRIVER": "Sürücü Atama",
        "DRIVERS.NEW_DRIVER": "Yeni Sürücü",
        "DRIVERS.CREATE_NEW_DRIVER": "Yeni Sürücü Oluştur",
        "DRIVERS.WORK_HOURS_CONTROL": "Mesai Saatleri Kontrolü",
        "DRIVERS.WORK_HOURS": "Düzenli Çalışma Saatleri",
        "DRIVERS.WORK_HOURS_START": "Çalışma Başlangıç Saati",
        "DRIVERS.WORK_HOURS_STOP": "Çalışma Bitiş Saati",
        "DRIVERS.MAX_OVERTIME": "Max. Fazla Mesai Saati",
        "DRIVERS.INSPECTION_INTERVAL": "Muayene Aralığı",
        "DRIVERS.SPECIFIED_DATE": "İstenilen Tarih",
        "DRIVERS.UPDATE_DRIVER": "Sürücü Düzenle",
        "DRIVERS.DELETE_DRIVER": "Sürücü Sil",
        "DRIVERS.CONFIRM_DELETE_DRIVER": "Sürücüyü silmek istediğinizden emin misiniz?",
        "DRIVERS.DRIVER_NOT_FOUND": "Sürücü bulunamadı",
        "DRIVERS.FAV_DRIVER": "Favori Sürücü",
        "DRIVERS.HAS_FAV_DRIVER": "aracı için seçilen favori sürücü:",
        "DRIVERS.HAS_NO_FAV_DRIVER": "Bu araç için bir sürücü seçilmemiş",
        "DRIVERS.UNSELECT_DRIVER": "Sürücü seçimini kaldırmak için tıklayın",
        "DRIVERS.DRIVERS_NOTIFICATION": "İkinci cep telefonunun sayısından daha hırsızlık koruması olarak (değil IButton mümkün için)",
        "DRIVERS.ENTER_PHONE_NUMBER": "Hırsızlık koruması için 2 cep telefonu numarası girilmesi.",
        "DRIVERS.ENTER_PHONE_NUMBER_TITLE": "Hırsızlık koruması için 2'ten cep telefonu numarası üzerinden otomatik almak.",
        "DRIVERS.MOBILE_COMMUNICATION": "Sürücü iletişim için Erişim uygulaması",
        "DRIVERS.DRIVER_NO_AUTO": "Sürücü No (Kullanıcı adı)",
        "DRIVERS.DRIVER_PASS": "Sürücü Şifre",
        "DRIVERS.MOBILE_APP_STATUS": "Mobil Uygulama Durumu",
        "DRIVERS.ACTIVE": "Aktif",
        "DRIVERS.DISABLE": "Pasif",
        "DRIVERS.24_HOUR_DRIVER": "Sürücü otomatik olarak kaydedilir 24 saat",
        "DRIVERS.ALWAYS_DRIVER": "Sürücüler her zaman oturum açık kalmalıdır (başkalarına kaydedilir)",
        "DRIVERS.CLOSE": "Kapat",
        "DRIVERS.MOBILE_DEVICES": "Mobile Cihazlar",
        "THEFT.STEP1": "1. Adım : Araç hareketlerinizin takibi",
        "THEFT.STEP1_REPORTS": "Hırsızlık Koruması Raporları",
        "THEFT.STEP2": "2. Adım : Bildirimler",
        "THEFT.STEP2_INFO": "Zaman belirlenmezse 24 saat aktif haldedir.Lütfen istediğiniz zaman aralığını belirleyiniz.",
        "THEFT.STEP3": "3. Adım : İletişim bilgilerinizi yazınız.",
        "THEFT.STEP4": "4. Adım : Geofence Alan aktivasyonu",
        "THEFT.WHEN_DEVICE_RUN": "Araç her çalıştığında",
        "THEFT.WHEN_DOOR_OPEN": "Kapı ya da bagaj kapısı her açıldığında",
        "THEFT.ALARM": "Alarm",
        "THEFT.SAVEALL": "Bütün araçlar için bu ayarları sağla",
        "THEFT.NO_ENTER_ZONE": "Bölge içinde kaldıkça bildirim",
        "THEFT.SMS_ON": "Motor Açık",
        "THEFT.SMS_OFF": "Motor Kapalı",
        "THEFT.SMS_GPS": "Periyodik",
        "THEFT.SMS_RESET": "Restart",
        "THEFT.THEFT_TWO_STEP1": "1. Adım : Geofence Alan aktivasyonu",
        "THEFT.SAVE_ALL_STEP4": "SMS ve EMAIL kutucularının başındaki checkbox seçilir ise seçimi yapılan SMS veya Email değeri tüm araçlar için kaydedilir",
        "THEFT.AIR_PRESSURE_TIMER": "Sadece Araç Alarm ayarları için",
        "THEFT.AKU": "Akü Kontrolü",
        "THEFT.IN": "Giriş Raporu",
        "THEFT.OUT": "Çıkış Raporu",
        "CUSTOMER_GROUPS.NEW": "Yeni Müşteri Grubu",
        "CUSTOMER_GROUPS.NEW_CUSTOMER": "Yeni Müşteri",
        "CUSTOMER_GROUPS.EDIT_CUSTOMER": "Müşteri Güncelle",
        "CUSTOMER_GROUPS.CUSTOMER_NO": "Müşteri No",
        "CUSTOMER_GROUPS.FIRMA_NAME": "Müşteri Adı",
        "CUSTOMER_GROUPS.RESP_PARTNER": "Sorumlu Partner",
        "CUSTOMER_GROUPS.CHOOSE_FUNCTION": "Lütfen gruba fonksiyon seçiniz.",
        "CUSTOMER_GROUPS.FUNC1": "Eşleştirilen Araçların Farklı Bölgelere(Müşterilere) Ulaşımının Kontrolü",
        "CUSTOMER_GROUPS.FUNC2": "Varış süresi kontrolü",
        "CUSTOMER_GROUPS.FUNC3": "Belirlenen bölgelere tüm araçların ulaşımının kontrolü",
        "CUSTOMER_GROUPS.CONFIRM_DELETE": "Bu veriyi silmek istediğinize emin misiniz?",
        "CUSTOMER_GROUPS.ADDRESS": "Kayıtlı Adresler",
        "CUSTOMER_GROUPS.PLS_PICK_CG": "Lütfen bir müşteri grubu seçin",
        "CUSTOMER_GROUPS.PLS_PICK_ADR": "Lütfen bir adres seçin",
        "CUSTOMER_GROUPS.NEW_ADR": "Yeni Adres",
        "CUSTOMER_GROUPS.NEW_ADR_EXP": "Yeni adrese bir açıklama yazın , ardından haritada adresinizin yerini ve alanını seçin. İşleminizi tamamladıktan sonra kaydetmek için yeşil kaydet butonuna, yeni adres eklemekten vazgeçmek için kırmızı vazgeç butonuna tıklayın.",
        "CUSTOMER_GROUPS.REPORTS_PAGE": "Müşteri Grupları Raporlar Sayfası",
        "CUSTOMER_GROUPS.SHOW_REPORTS": "Raporları Göster",
        "CUSTOMER_GROUPS.REPORT_DETAIL_NOT_FOUND": "Rapora ait detay bulunmamaktadır..",
        "CUSTOMER_GROUPS.ENTER_TIME": "Giriş Saati",
        "CUSTOMER_GROUPS.EXIT_TIME": "Çıkış Saati",
        "CUSTOMER_GROUPS.PICK_GRP": "Listedeki gruplardan birini seçiniz. Açılan penceredeki yönergeleri izleyiniz.",
        "CUSTOMER_GROUPS.DEV_SYNC": "Araç eşleştirme (sadece grup 1 ve grup 2)",
        "SYSTEM._comment": "IMAGES_FOLDER  bu dilde yazılmış görselleri içeren klasörün adresi.Sistemin düzgün çalısması için her dil dosyasında bulunmalıdır, aksi halde resimler yüklenemez.",
        "SYSTEM.IMAGES_FOLDER": "./assets/img/tr",
        "FUEL.CREATE_RECEIPT": "Yakıt Fişi Oluştur",
        "FUEL.PRICE_LITER": "Litre Fiyatı",
        "FUEL.PRICE_TOTAL": "Yakıt Fiyatı (Toplam)",
        "FUEL.LITER": "Litre Miktarı",
        "FUEL.LT_100_KM": "Litre/100 km",
        "FUEL.KM_DEFLECTION": " +- 10% sapma olabilir",
        "FUEL.USER_AVG": "Sizin Girdiğiniz Tüketim",
        "FUEL.REAL_AVG": "Gerçek Tüketim (Hesaplanan)",
        "AUTOPILOT.AUTOPILOT_SETTINGS": "Oto-Pilot Ayarları",
        "AUTOPILOT.DAILY_KM": "Günlük km kontrol",
        "AUTOPILOT.SPEED_CONTROL_1": "Şehiriçi hız kontrol",
        "AUTOPILOT.SPEED_CONTROL_2": "Çevreyolu hız kontrol",
        "AUTOPILOT.SPEED_CONTROL_3": "Otoyol hız kontrol",
        "AUTOPILOT.IGNITION": "Araç Ateşleme Uyarısı",
        "AUTOPILOT.12V": "Cihaz 12V altına bildirimi",
        "AUTOPILOT.OHNE_GSM": "GPS/GSM bağlantısı kopma bildirimi",
        "AUTOPILOT.ATOB": "A noktasından B'ye kontrol",
        "AUTOPILOT.DRIVING_TIME": "Sürüş Süresi",
        "AUTOPILOT.FROM": "itibaren",
        "AUTOPILOT.DEPERTURE_TIME": "Aracın tahmin edilen hareket saati",
        "AUTOPILOT.UNTIL": "Kadar",
        "AUTOPILOT.PARKED": "Park hali",
        "AUTOPILOT.LOCATION_TIME": "Konum Saati Bildirimi",
        "AUTOPILOT.CHOOSE_PERIOD": "Lütfen periyot seçin",
        "AUTOPILOT.IGNITION_CONTROL": "Kontak kontrol",
        "AUTOPILOT.IGNITION_CHANGE": "Kontak değişikliği bildirimi",
        "AUTOPILOT.TOWING": "Çekici bildirimi",
        "AUTOPILOT.EMAIL_INTERVAL": "E-Posta gönderilme sıklığını seçin",
        "AUTOPILOT.DEVIATION": "+/- 10% Sapma",
        "AUTOPILOT.MOBILE_COMMENT": "Adım 1'den 11'e kadar yapılmış olan ayarları Apple ve Android cihazınızda direk bildirim olarak alacaksınız",
        "AUTOPILOT.BEACON_DEVICE": "Beacon cihazı bildirimi",
        "AUTOPILOT.VEHICLE_NOTIFICATIONS_END_OF_DAY_REPORT": "Araç bildirimleri ve gün sonu raporu",
        "AUTOPILOT.REPORTS_FOR_ALL_VEHICLES": "Bütün araçlar için raporlar",
        "AUTOPILOT.NOTIFICATION_TYPE": "Bildirim Türü",
        "AUTOPILOT.SPEED_LIMIT": "Hız Sınırı",
        "AUTOPILOT.VEHICLE_SPEED": "Araç Hızı",
        "ROUTES.SHOW_ROUTES": "Rota Sorgulama",
        "ROUTES.RESPITE": "Dinlenme",
        "ROUTES.WAITING": "Bekleme",
        "ROUTES.FOR_MISSION": "Görevsel",
        "ROUTES.PRIVATE": "Özel",
        "ROUTES.TRIP": "Seyahat",
        "ROUTES.DAILY": "Günlük",
        "ROUTES.VEHICLE": "Araç",
        "ROUTES.VEHICLE_GROUP": "Araç Grupları",
        "ROUTES.DATE": "Tarih",
        "ROUTES.ALL_VEHICLE_GROUP": "Tüm Araç Grupları",
        "ROUTES.DIGITAL_INPUT": "Dijital Giriş",
        "ANKUFT.ANKUFT": "Varış",
        "ANKUFT.ANKUFT_TITLE": "Canlı Varış Takibi",
        "ANKUFT.STATUS_0": "Yaklaşmış",
        "ANKUFT.STATUS_1": "Geç Kalmış",
        "ANKUFT.STATUS_2": "Zamanında Varmış",
        "ANKUFT.PLATE": "Plaka",
        "ANKUFT.PLAN": "Planlanan",
        "ANKUFT.EXCEPTED": "Beklenen",
        "ANKUFT.STATUS": "Durum",
        "ANKUFT.ARRIVE_IN": "Varış",
        "ANKUFT.DISTANCE": "Mesafe",
        "ANKUFT.ORDER": "Emir",
        "ANKUFT.FROM": "Çıkış",
        "ANKUFT.TO": "Hedef",
        "TECHNICAL_SUPPORT.HEAD": "Teknik Destek Formu",
        "TECHNICAL_SUPPORT.GENERAL": "Genel Bilgiler",
        "TECHNICAL_SUPPORT.MESSAGE": "Mesajınız",
        "TECHNICAL_SUPPORT.CALL_TIME": "Aranmak istediğiniz saat",
        "TECHNICAL_SUPPORT.NAME": "Adınız Soyadınz",
        "TECHNICAL_SUPPORT.FIRMA": "Firma",
        "TECHNICAL_SUPPORT.PHONE": "Telefon Numaranız",
        "TECHNICAL_SUPPORT.EMAIL": "Email Adresiniz",
        "TECHNICAL_SUPPORT.INFO": "Teknik destek hizmetimiz ücretsizdir. Sorunuzu bu alana yazabilirsiniz.",
        "TECHNICAL_SUPPORT.NOW": "Hemen",
        "TECHNICAL_SUPPORT.SEND": "Gönder",
        "TECHNICAL_SUPPORT.REQUIRED_NAME": "Lütfen adınızı yazın.",
        "TECHNICAL_SUPPORT.REQUIRED_EMAIL": "Lütfen email adresinizi yazın.",
        "TECHNICAL_SUPPORT.REQUIRED_MESSAGE": "Lütfen mesajınızı yazın.",
        "TECHNICAL_SUPPORT.SUCCESS": "Mesajınız gönderildi! Ekibimiz en kısa sürede sizinle iletişime geçecektir.",
        "SMS_OPTIONS.SMS_OPTIONS": "SMS Seçenekleri",
        "SMS_OPTIONS.SMS_DESCRIPTION": "Seçtiğiniz seçenek Aylık seçilen ödeme dönemi düşülecektir. Abonelikten çıkmak için, e-posta yoluyla destek ekibimizle irtibata geçiniz.",
        "SMS_OPTIONS.EXTRA_PACKAGE": "Aylık Estra Paket",
        "SMS_OPTIONS.SPENT": "Kullanılan",
        "SMS_OPTIONS.BALANCE": "Kalan Bakiye",
        "SMS_OPTIONS.ORDER": "Sipariş",
        "SMS_OPTIONS.SMS_ORDER": "SMS Siparişleri",
        "SMS_OPTIONS.CHOOSE_PACKAGE": "Lütfen bir paket seçin.",
        "SMS_OPTIONS.CHOOSE_DEVICE": "Lütfen bir araç seçin.",
        "USER_SATELLITE.TITLE": "Kullanıcı Uydu Bilgileri",
        "USER_SATELLITE.PLACEHOLDER": "Lütfen Api Key Giriniz",
        "INOUT.PLACE": "Yer",
        "INOUT.DATE": "Tarih",
        "INOUT.SEARCH": "Ara",
        "INOUT.INSTRUCTION": "Adım 1: Seçilen yerin nerede olduğu koleksiyonu",
        "INOUT.INFO1": "Bu sayfada berlilenen alan içinde ve tarihlerde araçların olup olmadığını kontrol eder.",
        "INOUT.INFO2": "Kullanımını aşağıdaki gibidir:",
        "INOUT.INFO3": "Sağ üstte arama kutusunu seçin ve istenilen adresi giriniz.",
        "INOUT.INFO4": "Bulunan adreslerden istediğiniz adresi seçiniz. Alternatif olarak, mouse'un ok'u ile harita üzerinde yer bulabilirsiniz.",
        "INOUT.INFO5": "Kalem simgesini harita üzerine tıklayın ve istediğiniz konuma ve yarıçapı bir daireyi sürükleyiniz.",
        "INOUT.INFO6": "Seçilen alanda cihaz arama için sağ tarafında tarih seçiniz.",
        "INOUT.INFO7": "Sağdaki arama için başlangıç ​​ve bitiş zamanlarını giriniz. Başlangıç ve bitiş zamanlarını boş bıraktığınız takdirde o tarihin tüm gününü kontrol edecektir.",
        "INOUT.INFO8": "Tüm alanları doldurduktan sonra simgenin 'Ara' butonuna tıklayın.",
        "INOUT.INFO9": "Belirlenen alan ve zamanda araçların olup olmadığını gösteren sonuç tabloda listelenecektir. Listede hangi araçların o alana giriş yaptığını ve ilk ve son sinyal zamanı görünmektedir.",
        "INOUT.INFO10": "Tablodaki büyüteç ikon'u tıklandığında ayrıntılı liste açılacaktır. Bu listede araçın o alan içinde kaç adet rota yaptığını ve başlangıç ve bitiş zamanlarını ve adreslerini görüntüleyebilirsiniz.",
        "INOUT.INFO11": "Araç ismi Tıklandığında ise tablodaki başlangıç ve bitiş zamanları arasındaki rotanın harita üzerinde gösterimi açılır pencedere görüntülenecektir.",
        "INOUT.TIME": "Zaman",
        "INOUT.PLEASE_INSERT": "Lütfen Alan Belirleyiniz.",
        "INOUT.ENTRY": "Giriş",
        "INOUT.EXIT": "Çıkış",
        "INOUT.TOTAL": "Toplam",
        "INOUT.STOP": "İptal",
        "MYINOUT.INSTRUCTION": "Aşama 2: depolama yerleri",
        "MYINOUT.INFO1": "Rota arama için istenen tarihe sağ tarafında.",
        "MYINOUT.INFO2": "Yalnızca tercih tarihinde belli bir zaman kontrol etmek istiyorsanız, o zaman doğru başlangıç ve bitiş zamanında seçin.",
        "MYINOUT.INFO3": "Eğer tüm seçenekleri seçtikten sonra size sorgulanan ne olmaya Önceden oluşturulan sitenin adını tıklayabilirsiniz.",
        "MYINOUT.INFO4": "Sonuç: Yapılan sitelerinin seçiminde seçilen tarih ve saate idi cihazları listeler.",
        "MYINOUT.INFO5": "Harita üzerinde rotayı görüntülemek için yeni bir pencerede isterseniz istenen araç üzerinde tıklayın.",
        "MYINOUT.INFO6": "Rota sorgusu için depolanmış yerleri Buraya.",
        "MYINOUT.TITLE": "Kayıtlı konumlar",
        "MYINOUT.TABLE_TITLE": "Belirlenen yerde araçların Görüntü",
        "MYINOUT.SAVED_PLACES": "Kaydedilen konum",
        "MYINOUT.DEVICE": "Araç Adı",
        "MYINOUT.FIRST": "İlk",
        "MYINOUT.ZONE": "Sinyal",
        "MYINOUT.LAST": "Son",
        "MYINOUTFORM.INSTRUCTION": "3. Adım: depolanan yerler ile konum listesi isteği",
        "MYINOUTFORM.INFO1": "İstenilen adres arama adres çubuğuna girin. Alternatif olarak, harita üzerinde yerleştirmek için ok aracıyla arama yapabilirsiniz.",
        "MYINOUTFORM.INFO2": "Eğer konum kalem simgesi ortasında bulunan sorgulanmak zorunda sonra yukarıdaki tıklayın.",
        "MYINOUTFORM.INFO3": "Şimdi harita üzerinde tıklayıp istediğiniz konuma ve yarıçapı için daireyi sürükleyin.",
        "MYINOUTFORM.INFO4": "Seçtiğiniz konuma bir isim girin ve gelen kaydedin.",
        "MYINOUTFORM.INFO5": "Site seçimlerinizi listesinin en altında görüntülenir.",
        "MYINOUTFORM.INFO6": "Başarıyla yer seçimi depolama sonra listenin altında görüntülenir.",
        "MYINOUTFORM.INFO7": "Daha önce kaydedilmiş bir konum düzenlemek için, kurşun kalem simgesini tıklayın.",
        "MYINOUTFORM.NAME": "Alan Adı:",
        "MYINOUTFORM.TABLE_TITLE": "Yol sorguları için saklanan yerler",
        "MYINOUTFORM.EDIT_PLACES": "Kaydedilen konum düzenle",
        "ROUTE_TRACKING.START_BUTTON": "Harita üzerinde manuel başlangıç giriniz",
        "ROUTE_TRACKING.STOP_BUTTON": "Harita üzerinde manuel bitiş giriniz",
        "ROUTE_TRACKING.STATUS": "Durum",
        "ROUTE_TRACKING.DETAIL": "Detay",
        "ROUTE_TRACKING.START_ADRESS_INPUT": "Kalkış adresini giriniz",
        "ROUTE_TRACKING.DEVICE": "Araç",
        "ROUTE_TRACKING.DRIVER": "Sürücü",
        "ROUTE_TRACKING.START_ADRESS": "Başlangıç Adresi",
        "ROUTE_TRACKING.END_ADRESS": "Bitiş Adresi",
        "ROUTE_TRACKING.PLAN_START_TIME": "Planlanan Başlangıç Tarihi",
        "ROUTE_TRACKING.EMAILS": "Bildirim E-mailleri",
        "ROUTE_TRACKING.PLAN_KM_TIME": "Tahmini KM ve Süre",
        "ROUTE_TRACKING.PLAN_KM": "Tahmini KM",
        "ROUTE_TRACKING.PLAN_TIME": "Tahmini Süre",
        "ROUTE_TRACKING.SELECT_DEVICE": "Araç Seçiniz",
        "ROUTE_TRACKING.SELECT_DRIVER": "Sürücü Seçiniz",
        "ROUTE_TRACKING.INTERVAL": "Bildirim Aralığı",
        "ROUTE_TRACKING.SAVE": "Rota Planı Kaydet",
        "ROUTE_TRACKING.GENERAL_INFO": "Genel Bilgi",
        "ROUTE_TRACKING.LATEST_ADRESS": "Şuan Bulunduğu Adres",
        "ROUTE_TRACKING.ALERT_COORDINATE": "Lütfen Başlanıç ve Bitiş Yerlerini Giriniz.",
        "ROUTE_TRACKING.ALERT_DEVICE": "Lütfen Araç Seçiniz",
        "ROUTE_TRACKING.ALERT_EMAIL": "Lütfen en az 1 adet Email adresi yazınız!",
        "ROUTE_TRACKING.ALERT_INTERVAL": "Lütfen bildirim gönderme aralığı giriniz.",
        "ROUTE_TRACKING.NOTIFICATION_TIME": "Bildirim Zamanı",
        "ROUTE_TRACKING.NOTIFICATION_ADRESS": "Bildirim Adresi",
        "ROUTE_TRACKING.DONE_INFO_1": "",
        "ROUTE_TRACKING.DONE_INFO_2": "tarihinde tamamlandı",
        "ROUTE_TRACKING.DELETE_SURE": "Silmek istediğinizden Emin misiniz?",
        "ROUTE_TRACKING.TITLE": "Rota Tahmini",
        "ROUTE_TRACKING.NOTIFICATION_REPORTS": "Bildirim Raporları",
        "ROUTE_TRACKING.DEFINED_START": "Başlangıç Yeri Seçilmiştir",
        "ROUTE_TRACKING.DEFINED_STOP": "Bitiş Yeri Seçilmiştir",
        "AIR_ALARM_SETTINGS.AIR_ALARM": "Alarm Ayarları",
        "AIR_ALARM_SETTINGS.SOUND_SETTINGS": "Ses",
        "AIR_ALARM_SETTINGS.SOUND_SETTINGS_INFO": "Ses seviyesi,  alarm devreye girdiğinde siren sesinin kaç saniye boyunca çalması gerektiğini belirler.",
        "AIR_ALARM_SETTINGS.SENSITIVITY_SETTINGS": "Hassasiyet",
        "AIR_ALARM_SETTINGS.SENSITIVITY_SETTINGS_INFO": "Hassasiyet ayarı bulunduğunuz ortama ve araca göre değişkenlik gösterebilir. Önerilen ayar 1300 dır, bu değerin altındaki değerler alarmın kendiliğinden devreye girmesine neden olabilir.",
        "CHAT.IS_TYPING": " yazıyor...",
        "CHAT.CHAT_INPUT_BOX": "Mesajınızı giriniz...",
        "CHAT.CHAT_PROGRESS": "Chatting as ",
        "CHAT.CHAT_QUICK_REPLY_YES": "EVET",
        "CHAT.CHAT_QUICK_REPLY_NO": "HAYIR",
        "CHAT.CHAT_QUICK_REPLY_OK": "TAMAM",
        "CHAT.CHAT_NOT_DELIVERED": "Mesaj gönderilemedi",
        "CHAT.DRIVER_LIST_HEADER": "Sürücü Listesi",
        "CHAT.USER_LIST_HEADER": "Kullanıcı Listesi",
        "CHAT.TOPUSER_LIST_HEADER" : "Üst Kullanıcı Listesi",
        "CHAT.TOPUSER_NOT_FOUND": "Üst Kullanıcı Bulunamdı!",
        "CHAT.SUBUSER_LIST_HEADER": "Alt Kullanıcı Listesi",
        "CHAT.USER_NOT_FOUND": "Kayıtlı Kullanıcı Bulunamadı!",
        "CHAT.DRIVER_NOT_FOUND": "Kayıtlı Sürücü Bulunamadı!",
        "CHAT.SUBUSER_NOT_FOUND": "Alt Kullanıcı Bulunamadı!",
        "CHAT.DRIVING_TYPE_MODAL_TITLE" : "Sürüş Tipi Seçiniz",
        "CHAT.DRIVING_TYPE_MODAL_CLOSE" : "Kapat",
        "CHAT.DRIVING_TYPE_PRIVATE": "ÖZEL",
        "CHAT.DRIVING_TYPE_PUBLIC" : "İş",
        "CHAT.DRIVING_TYPE_POPUP_TITLE" : "Sürüş Tipi Hatası",
        "CHAT.DRIVING_TYPE_ERROR" : "Sürüş Tipi Kaydedilemedi!",
        "WEB_INTEGRATION.TITLE": "Web sitenize entegrasyon",
        "WEB_INTEGRATION.CONTENT1": "Web entegrasyonunun kaynak kodunu oluşturmak için lütfen aşağıdaki bağlantıyı kopyalayın. Böylece siz veya web tasarımcınız aracınızın görünümünü kendi web sitenize entegre edebilir.",
        "BEACON.DEVICE_NAME": "Cihaz Adı",
        "BEACON.LAST_SIGNAL_TIME": "Son Sinyal Zamanı",
        "BEACON.SIGNAL_STRENGTH": "Sinyal Gücü",
        "BEACON.CONNECTION_STATUS": "Bağlantı Durumu",
        "BEACON.DEVICES": "Beacon Cihazları",
        "BEACON.PERFECT": "Mükemmel",
        "BEACON.VERY_GOOD": "Çok iyi",
        "BEACON.GOOD": "İyi",
        "BEACON.BAD": "Kötü",
        "BEACON.VERY_BAD": "Çok Kötü",
        "DATA_DELETE.DATA_TO_BE_DELETED": "Silinecek Veriler",
        "DATA_DELETE.DEVICES_TO_BE_DELETED": "Silinecek Cihazlar",
        "DATA_DELETE.DATA_DELETING": "Veriler Siliniyor",
        "DATA_DELETE.DELETED_DEVICES": "Silinmiş Cihazlar",
        "DATA_DELETE.CREATE_DATE": "Oluşturulma Tarihi",
        "DATA_DELETE.DELETE_DATE": "Silinme Tarihi",
        "DATA_DELETE.CONFIRMATION_CODE": "Onay Kodu",
        "DATA_DELETE.TYPE_CONFIRMATION_CODE": "Lütfen onay kodunuzu girin",
        "DATA_DELETE.CONFIRMATION": "Onay",
    }
};

export default langTr;