import BaseService from './BaseService';

class Notifications extends BaseService {

  getNotificationByDeviceId = (deviceId) => {
    return this.axiosInstance.get(`/notifications/${deviceId}/`, {cancelToken: this.cancelTokenSource.token});
  };

  update = (deviceId, data) => {
    return this.axiosInstance.post(`/notifications/update/${deviceId}/`, data);
  };

}

export default Notifications;