import React from 'react';
import HomeMap from '../Map/home';
import {connect} from 'react-redux';
  
function Home(props) {
  
  return (
    <HomeMap devices={props.devices} />
  );
}

const mapStateToProps = function (state) {
  return {
    devices: state.deviceListReducer.devices
  }
};

export default connect(mapStateToProps)(Home);
