import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import HomeNav from './nav';
import FuelConsumptionChart from '../Charts/fuelConsumptionChart';
import DateFilter from '../Commons/dateFilter';
import Loading from '../Commons/loading';
import MessageWindow from '../Commons/messageWindow';
import IconTextWithFa from '../Commons/iconTextWithFa';
import {toFixed} from '../Assets/utils';
import Moment from 'moment';

import CarService from './../../services/Cars';
import FuelReceiptsService from './../../services/FuelReceipts';
import DeviceService from './../../services/Devices';

const dataInitialState = {
  car: {
    avg_fuel_consumption: 0
  },
  device: {
    device_id: 0,
    name: '',
  }
};

const receiptInitialState = {
  receipt_date: new Date(),
  receipt_volume: '',
  fuel_price: ''
};

const fuelReceiptsInitialState = {
  calculated_avg_fuel: 0,
  fuel_receipts: [],
  total_mileage: 0,
  total_liter: 0,
  total_fuel_price: 0
};

function FuelConsumption(props) {

  const deviceId = props.match.params.deviceId;
  const carService = new CarService();
  const fuelReceiptsService = new FuelReceiptsService();
  const deviceService = new DeviceService();
  const date = new Date();

  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState(dataInitialState);
  const [fuelReceipts, setFuelReceipts] = useState(fuelReceiptsInitialState);
  const [newReceipt, setNewReceipt] = useState(receiptInitialState);
  const [showMessage, setShowMessage] = useState({
    message: '',
    title: '',
    status: false
  });

  const handleChange = (event) => {
    if (event.target.name.indexOf(".") !== -1) {
      let _names = event.target.name.split(".");

      setData({
        ...data,
        [_names[0]]: {
          ...data[_names[0]],
          [_names[1]]: event.target.value
        }
      });
    }
  };

  const saveCar = async (event) => {
    event.preventDefault();

    await carService.saveCar(deviceId, data.car)
      .then((response) => {
        (response.data.result === "success") ?
          setShowMessage({
            title: <h5>Success</h5>,
            message: <h5><FormattedMessage id="USER_RIGHTS.SUCCESS_SAVE"/></h5>,
            status: true
          })
          :
          setShowMessage({
            title: <h5>Error</h5>,
            message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
            status: true
          })
      })
  };

  const saveReceipt = async (event) => {
    event.preventDefault();

    await fuelReceiptsService.saveFuelReceipt(deviceId,
      Moment(newReceipt.receipt_date).format("YYYY-MM-DD HH:mm:ss"),
      newReceipt.receipt_volume,
      newReceipt.fuel_price)
      .then((response) => {
        (response.data === "success") ?
          fetchReceipts()
          :
          setShowMessage({
            title: <h5>Error</h5>,
            message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
            status: true
          })
      })
      .then(() => {
        setNewReceipt({
          receipt_date: newReceipt.receipt_date,
          receipt_volume: '',
          fuel_price: ''
        });
      })
  };

  const deleteReceipt = async (receiptId) => {
    try {
      setIsLoading(true);

      await fuelReceiptsService.deleteFuelReceipt(receiptId)
        .then((response) => {
          if (response.data === 'success') {
            fetchReceipts();

          } else {
            setIsLoading(false);
            setShowMessage({
              title: <h5>Error</h5>,
              message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
              status: true
            })
          }
        });

    } catch (error) {
      setIsLoading(false);
      setShowMessage({
        title: <h5>Error</h5>,
        message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
        status: true
      })
    }
  };

  const fetchDeviceData = async () => {
    try {
      setIsLoading(true);

      await Promise.all([
        carService.getCar(deviceId),
        deviceService.getDevice(deviceId)
      ])
        .then(([carResponse, deviceResponse]) => {
          setData({
            car: {
              avg_fuel_consumption: carResponse.data.avg_fuel_consumption
            },
            device: {
              device_id: deviceResponse.data.device_id,
              name: deviceResponse.data.name,
            }
          });
          setIsLoading(false);
        });

    } catch (error) {
      setData(dataInitialState);
      setIsLoading(false);
      // display error
      setShowMessage({
        title: <h5>Error</h5>,
        message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
        status: true
      })
    }
  };

  const fetchReceipts = async () => {
    try {
      setIsLoading(true);

      await fuelReceiptsService.getFuelReceipts(
        deviceId,
        Moment(startDate).format("YYYY-MM-DD"),
        Moment(endDate).format("YYYY-MM-DD"))
        .then((response) => {
          if (response.data.hasOwnProperty('fuel_receipts') && response.data.fuel_receipts.length > 0) {
            setFuelReceipts(response.data);

          } else {
            // set receipts to initial state
            setFuelReceipts(fuelReceiptsInitialState);
            // display no data warning
            setShowMessage({
              title: <h5><FormattedMessage id="GENERAL.WARNING"/></h5>,
              message: <h5><FormattedMessage id="GENERAL.NO_DATA"/>.</h5>,
              status: true
            })
          }
          setIsLoading(false);
        });

    } catch (error) {
      setFuelReceipts(fuelReceiptsInitialState);
      setIsLoading(false);
      // display error
      setShowMessage({
        title: <h5>Error</h5>,
        message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
        status: true
      })
    }
  };

  useEffect(() => {
    fetchDeviceData();

  }, [deviceId]);

  useEffect(() => {
    fetchReceipts();

  }, [deviceId, startDate, endDate]);

  const routeToDevice = (event) => {
    props.history.push(`/home/fuel-consumption/${event.target.value}`);
  };

  return (
    <>
      {isLoading ? (<Loading/>) : null}
      <HomeNav deviceId={deviceId} active="fuel-consumption"/>

      <div className="container-fluid">
        <h3 className="mt-2"><FormattedMessage id="QMENU.FUEL"/></h3>
        <hr/>
        {/** Header **/}
        <div className="row">
          <div className="col-md-3">
            <h6><FormattedMessage id="GENERAL.DEVICES"/></h6>
            <select className="custom-select custom-select-sm" value={data.device.device_id} onChange={routeToDevice}>
              {
                props.devices.map((device) => {
                  return (
                    <option key={device.device_id} value={device.device_id}>
                      {device.name}
                    </option>
                  )
                })
              }
            </select>
          </div>
          <div className="col-md-3">
            <h6><FormattedMessage id="QMENU.FUEL"/> <FormattedMessage id="FUEL.LT_100_KM"/></h6>
            <div className="input-group input-group-sm">
              <input type="number" className="form-control" name="car.avg_fuel_consumption"
                     value={data.car.avg_fuel_consumption} onChange={handleChange}/>
              <div className="input-group-append">
                <button type="button" className="btn btn-warning" onClick={saveCar}><FormattedMessage id="GENERAL.SAVE"/></button>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <h6><FormattedMessage id="FUEL.REAL_AVG"/></h6>
            <h5>
              <span className="badge badge-primary">{toFixed((fuelReceipts.calculated_avg_fuel), 2)} Litre/100 km</span>
            </h5>
          </div>
        </div>
        <hr/>
        {/** Chart **/}
        <div className="row">
          <div className="col-md-12">
            <FuelConsumptionChart
              yAxisTitle={"Ortalama Yakıt Tüketimi"}
              startDate={startDate}
              endDate={endDate}
              calculatedAvgFuel={fuelReceipts.calculated_avg_fuel}
              avgFuel={data.car.avg_fuel_consumption}
            />
          </div>
        </div>
        <hr/>
        {/** Form **/}
        <div className="row">
          <div className="col-md-9">
            <form onSubmit={saveReceipt}>
              <div className="row">
                <div className="col-md-2">
                  <h6><FormattedMessage id="FUEL.CREATE_RECEIPT"/> <IconTextWithFa icon="hand-point-right"/></h6>
                </div>

                <div className="col-md-3">
                  <DateFilter onChange={(date) => setNewReceipt({...newReceipt, receipt_date: date})} selectedDate={newReceipt.receipt_date}/>
                </div>

                <div className="col-md-2">
                  <input type="number" className="form-control form-control-sm" value={newReceipt.receipt_volume}
                         onChange={(event) => setNewReceipt({...newReceipt, receipt_volume: event.target.value})}
                         placeholder='Litre Miktarı'/>
                </div>

                <div className="col-md-3">
                  <input type="number" className="form-control form-control-sm" value={newReceipt.fuel_price}
                         onChange={(event) => setNewReceipt({...newReceipt, fuel_price: event.target.value})}
                         placeholder='Yakıt Fiyatı (Toplam)'/>
                </div>

                <div className="col-md-2">
                  <button type="submit" className="btn btn-success btn-sm btn-block">
                    <FormattedMessage id="GENERAL.SAVE"/>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="col-md-1">
            <h6><FormattedMessage id="GENERAL.FILTER"/> <IconTextWithFa icon="hand-point-right"/></h6>
          </div>

          <div className="pull-left">
            <DateFilter onChange={(date) => setStartDate(date)} selectedDate={startDate}/>
            <DateFilter onChange={(date) => setEndDate(date)}/>
          </div>
        </div>

        <hr/>
        {/** Receipts **/}
        <div className="row">
          <div className="col-md-10 col-xs-12">
            <table className="table table-sm table-hover text-center">
              <thead>
              <tr>
                <th><FormattedMessage id="GENERAL.DATE"/></th>
                <th><FormattedMessage id="FUEL.PRICE_LITER"/></th>
                <th><FormattedMessage id="FUEL.LITER"/></th>
                <th><FormattedMessage id="FUEL.PRICE_TOTAL"/></th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {fuelReceipts.fuel_receipts.map((receipt) => {
                return (
                  <tr key={receipt.fuel_receipt_id}>
                    <td>{Moment(receipt.receipt_date).format("DD-MM-YYYY HH:mm")}</td>
                    <td>{toFixed((receipt.fuel_price / receipt.receipt_volume), 2)}</td>
                    <td>{receipt.receipt_volume}</td>
                    <td>{receipt.fuel_price}</td>
                    <td>
                      <button className="btn btn-danger btn-sm btn-block"
                              onClick={() => deleteReceipt(receipt.fuel_receipt_id)}>
                        <FormattedMessage id="GENERAL.DELETE"/>
                      </button>
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan="2"><b><FormattedMessage id="GENERAL.TOTAL"/></b></td>
                <td><b>{toFixed(fuelReceipts.total_liter, 2)}</b></td>
                <td><b>{fuelReceipts.total_fuel_price}</b></td>
                <td/>
              </tr>
              <tr>
                <td colSpan="5">
                  <p>
                    <FormattedMessage id="GENERAL.TOTAL_MILEAGE"/>: <b>{toFixed(fuelReceipts.total_mileage, 2)} km</b> (<FormattedMessage id="FUEL.KM_DEFLECTION"/>)
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <MessageWindow
        size='md'
        show={showMessage.status}
        onHide={() => setShowMessage({...showMessage, status: false})}
        title={showMessage.title}
        message={showMessage.message}
      />
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    devices: state.deviceListReducer.devices
  }
};

const fuelConsumptionWithRouter = withRouter(FuelConsumption);
export default connect(mapStateToProps)(fuelConsumptionWithRouter);
