import BaseService from './BaseService';

class Reports extends BaseService {

    getDeviceSleepTrips(device_id,start_date,end_date) {
        return this.axiosInstance.get(`/trips/reports_sleeps/${device_id}/?start_date=${start_date}&end_date=${end_date}`, {cancelToken: this.cancelTokenSource.token});
    }

    getDeviceDailyTrips(device_id,start_date,end_date) {
        return this.axiosInstance.get(`/trips/daily/device/${device_id}/?start_date=${start_date}&end_date=${end_date}&manual_trips=1`, {cancelToken: this.cancelTokenSource.token});
    }

    getDriverDailyTrips(driver_id,start_date,end_date) {
        return this.axiosInstance.get(`/trips/daily/driver/${driver_id}/?start_date=${start_date}&end_date=${end_date}&manual_trips=1`, {cancelToken: this.cancelTokenSource.token});
    }

    insertUserDailyReports(device_id, newTrip) {
        return this.axiosInstance.post(`/user_daily_reports/insert/${device_id}/?`, newTrip);
    }

    deleteUserDailyReports(user_daily_report_id) {
        return this.axiosInstance.post(`/user_daily_reports/delete/${user_daily_report_id}/?`);
    }

    updateUserDailyReports(user_daily_report_id, editedTrip) {
        return this.axiosInstance.post(`/user_daily_reports/update/${user_daily_report_id}/?`, editedTrip);
    }

    getDeviceMonthlyTrips(device_id,start_date,end_date) {
        return this.axiosInstance.get(`/trips/monthly/device/${device_id}/?start_date=${start_date}&end_date=${end_date}&manual_trips=1`, {cancelToken: this.cancelTokenSource.token});
    }

    getDriverMonthlyTrips(driver_id,start_date,end_date) {
        return this.axiosInstance.get(`/trips/monthly/driver/${driver_id}/?start_date=${start_date}&end_date=${end_date}&manual_trips=1`, {cancelToken: this.cancelTokenSource.token});
    }

    insertUserMonthlyReports(device_id, newTrip) {
        return this.axiosInstance.post(`/user_monthly_reports/insert/${device_id}/?`, newTrip);
    }

    deleteUserMonthlyReports(user_monthly_report_id) {
        return this.axiosInstance.post(`/user_monthly_reports/delete/${user_monthly_report_id}/?`);
    }

    updateUserMonthlyReports(user_monthly_report_id, editedTrip) {
        return this.axiosInstance.post(`/user_monthly_reports/update/${user_monthly_report_id}/?`, editedTrip);
    }

    getDeviceRouteTrips(deviceId, startDate, endDate) {
        return this.axiosInstance.get(`/trips/route/device/${deviceId}/?start_date=${startDate}&end_date=${endDate}&nocache=true`)
    }

    updateDeviceTrips(trip_id, data) {
        return this.axiosInstance.post(`/trips/update/${trip_id}/?`, data);
    }

    getDeviceYearlyReport(device_id,start_date,end_date) {
        return this.axiosInstance.get(`/trips/yearly/device/${device_id}/?start_date=${start_date}&end_date=${end_date}`, {cancelToken: this.cancelTokenSource.token});
    }

    getDriverYearlyReport(driver_id,start_date,end_date) {
        return this.axiosInstance.get(`/trips/yearly/driver/${driver_id}/?start_date=${start_date}&end_date=${end_date}`, {cancelToken: this.cancelTokenSource.token});
    }

    getGeofenceReports(device_id, start_date, end_date) {
        return this.axiosInstance.get(`/geofence_reports/${device_id}/?start_date=${start_date}&end_date=${end_date}&offset=0&display=500`, {cancelToken: this.cancelTokenSource.token});
    }

    getAreaControlReports(device_id, start_date, end_date) {
        return this.axiosInstance.get(`/area_control_reports/${device_id}/?start_date=${start_date}&end_date=${end_date}&offset=0&display=500`, {cancelToken: this.cancelTokenSource.token});
    }
}

export default Reports;