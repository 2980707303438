import BaseService from './BaseService';

class Drivers extends BaseService {

  getDriver = (driverId) => {
    return this.axiosInstance.get(`/user/driver/${driverId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  };

  getDrivers = () => {
    return this.axiosInstance.get(`/user/drivers/?`, {cancelToken: this.cancelTokenSource.token});
  };

  getUserIButtons = () => {
    return this.axiosInstance.get(`/user/ibuttons/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  };

  getFavoriteDriver = (deviceId) => {
    return this.axiosInstance.get(`/user/drivers/favorite/${deviceId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  };

  insertDriver(newDriver) {
    return this.axiosInstance.post(`/user/drivers/insert/?`, newDriver);
  }

  deleteDriver = (driverId) => {
    return this.axiosInstance.get(`/user/drivers/delete/${driverId}/?`, {cancelToken: this.cancelTokenSource.token});
  };

  deleteFavoriteDriver = (deviceId) => {
    return this.axiosInstance.get(`/user/drivers/favorite/delete/${deviceId}/?`, {cancelToken: this.cancelTokenSource.token});
  };

  updateFavoriteDriver = (deviceId, driverId) => {
    return this.axiosInstance.get(`/user/drivers/favorite/update/${deviceId}/${driverId}/?`, {cancelToken: this.cancelTokenSource.token});
  };

  driver_mobile_all_devices = (driverId) => {
    return this.axiosInstance.get(`/driver_mobile_devices/get_all_devices/${driverId}/?`, {cancelToken: this.cancelTokenSource.token});
  };

  getDriverByPhoneNumber = (area_code,phone_number) => {
    return this.axiosInstance.get(`/user/drivers/check/${area_code}/${phone_number}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  };

  update_devices = (driver_id,query_string) => {
    return this.axiosInstance.get(`/driver_mobile_devices/update_devices/${driver_id}/?devices=[${query_string}]`, {cancelToken: this.cancelTokenSource.token});
  };

  updateDriver(driver_id, query_string) {
    return this.axiosInstance.post(`/user/drivers/update/${driver_id}/?`, query_string);
  };

  updateIButton = (i_btn_id, i_btn_number, trip_typ, driver_id) => {
    return this.axiosInstance.get(`/user/ibuttons/update/?i_btn_id=${i_btn_id}&i_btn_number=${i_btn_number}&trip_typ=${trip_typ}&driver_id=${driver_id}`, {cancelToken: this.cancelTokenSource.token});
  };
  
}

export default Drivers;