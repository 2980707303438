import React from 'react';
import './index.css';


function GroupName(props) {

  return (
    <div className={`${props.listType}-group-name`}>
      {props.groupName}
    </div>
  );
}

export default GroupName;
