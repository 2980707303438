import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import MessageWindow from '../Commons/messageWindow';
import {sortAllDevices, sortByGroup} from '../Assets/utils';
import './index.css';

import {
  updateUserInformation,
  updateUserGroups,
  updateDeviceList,
  updateDeviceListWithGroups
} from "../../redux/actions";

import UserService from '../../services/Users';
import UserInformation from '../../services/UserInformation';
import DeviceService from '../../services/Devices';


function DeviceListFooter(props) {
  const [showOneHourDevices, setShowOneHourDevices] = useState(false);
  const [oneHourDevices, setOneHourDevices] = useState([]);
  const [devicesOnDisplay, setDevicesOnDisplay] = useState(props.user.devices_on_display);
  const [selectedUserGroup, setSelectedUserGroup] = useState();

  const userService = new UserService();
  const userInformationService = new UserInformation();
  const deviceService = new DeviceService();

  const handleOneHourDevices = () => {
    var now = new Date();
    now.setDate(now.getDate());
    var now_ = now.toISOString();
    let oneHourDevicesList = [];
    if (props.devices.length !== 0) {
      for (var i = 0; i < props.devices.length; i++) {
        let a = props.devices[i].latest_geocode_sent_at.split(' ');
        a[1] = 'T' + a[1] + 'Z';
        let device_hour = new Date(a.join(''));
        device_hour.setDate(device_hour.getDate());
        device_hour.setHours(device_hour.getHours() + 1);
        let device_hour_ = device_hour.toISOString();
        if (now_ >= device_hour_) {
          oneHourDevicesList.push(props.devices[i]);
        }
      }
      setOneHourDevices(oneHourDevicesList);
    }
  };

  const displayDevices = async (event) => {
    if (event.keyCode === 13) {
      let value = parseInt(event.target.value, 0);

      if (Number.isInteger(value)) {
        value = (value > props.totalCount) ? (props.totalCount) : (value);

        await userInformationService.setDevicesOnDisplay(value)
          .then((response) => {
            if (response.data.result === 'success') {
              props.updateUserInformation({user:{...props.user, devices_on_display: value}});
              setDevicesOnDisplay(value);
            }
          });
      }
    }
  };

  const displayAllDevices = async () => {
    try {
      const devices = await deviceService.getDevicesWithGroups(props.sortType, props.totalCount)
        .then(async (response) => {
          props.updateDeviceList({
            devices: response.data[0]["results"],
            totalCount: response.data[1]["total_count"]
          });

          return response.data[0]["results"];
        });

      const groups = await userService.getUserGroups()
        .then((response) => {
          props.updateUserGroups({userGroups: response.data[0]["results"]});

          return response.data[0]["results"];
        });

      props.updateUserInformation({user:{...props.user, devices_on_display: props.totalCount}});
      setDevicesOnDisplay(props.totalCount);
      let deviceListWithGroup = (props.groupSort) ? sortByGroup(devices, groups) : sortAllDevices(devices, groups);
      props.updateDeviceListWithGroups({devicesWithGroups: deviceListWithGroup});

    } catch (error) {
    }

  };

  const displayGroupDevices = async () => {

    (typeof selectedUserGroup === 'undefined') ? displayAllDevices() : (
      await deviceService.getDevicesOfGroup(selectedUserGroup.device_group_id)
        .then(async (response) => {
          props.updateDeviceList({
            devices: response.data[0]["results"],
            totalCount: props.totalCount
          });

          props.updateDeviceListWithGroups({
            devicesWithGroups: sortAllDevices(response.data[0]["results"], [selectedUserGroup])
          });
        })
    );

  };

  useEffect(() => {
    handleOneHourDevices();
    setDevicesOnDisplay(props.user.devices_on_display);

  }, [props.devices.length]);

  return (
    <div>
      <div className="display-devices">
        {
          oneHourDevices.length !== 0 ?
            <div className="results">
              <div className="row">
                <div className="col-md-12" onClick={() => setShowOneHourDevices(true)}>
                  <a><FormattedMessage id="GENERAL.ONE_HOUR_LINK"/></a>
                </div>
              </div>
            </div> : null
        }
        <div className="results">
          <div className="row">
            <div className="col-md-6" style={{paddingLeft: '14px'}}>
              {<FormattedMessage id="HOME.RESULTS"/>} : {props.totalCount}
            </div>
            <div className="col-md-6" style={{paddingLeft: '14px'}}>
              {<FormattedMessage id="HOME.SHOWING"/>} : 1 - {props.devices.length}
            </div>
          </div>
        </div>
        <div className="title"><FormattedMessage id="HOME.DEVICE_FILTER"/></div>
        <div className="device-filters">
          <div className="row">

            <div className="col-md-7 col-xs-7">
              <input type="text" className="form-control" value={devicesOnDisplay}
                     onChange={(event) => setDevicesOnDisplay(event.target.value)} onKeyUp={(event) => displayDevices(event)} />
            </div>
            <div className="col-md-5 col-xs-5">
              <button className="btn btn-primary btn-sm btn-block" onClick={displayAllDevices}>
                <FormattedMessage id="GENERAL.ALL"/>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7 col-xs-7" style={{paddingLeft: "14px"}}>
              <select className="form-control" onChange={(event) => setSelectedUserGroup(props.userGroups[event.target.value])}>
                <FormattedMessage id="GROUPS.ALL">
                  {(message) => <option key={0} value={-1} disabled>{message}</option>}
                </FormattedMessage>
                {
                  props.userGroups.map((userGroup, index) => {
                    return <option key={userGroup.device_group_id} value={index}>{userGroup.group_name}</option>
                  })
                }
              </select>
            </div>
            <div className="col-md-5 col-xs-5">
              <button className="btn btn-primary btn-sm btn-block" onClick={displayGroupDevices}>
                <FormattedMessage id="GENERAL.FILTER"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <MessageWindow
        show={showOneHourDevices}
        onHide={() => setShowOneHourDevices(false)}
        title={<p><FormattedMessage id="GENERAL.ONE_HOUR_LINK"/></p>}
        message={
          <table className="table">
            <thead>
              <tr>
                <th><FormattedMessage id="GENERAL.DEVICE_NAME"/></th>
                <th><FormattedMessage id="GENERAL.LATEST_SIGNAL_DATE"/></th>
              </tr>
            </thead>
            <tbody>
              {oneHourDevices.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item['name']}</td>
                    <td>{item['latest_geocode_sent_at']}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
      />
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    user: state.userInformationReducer.user,
    userGroups: state.userGroupsReducer.userGroups,
    devices: state.deviceListReducer.devices,
    sortType: state.deviceSortTypeReducer.sortType,
    groupSort: state.deviceGroupSortReducer.groupSort,
    totalCount: state.deviceListReducer.totalCount
  }
};

const mapDispatchToProps = {
  updateUserInformation,
  updateUserGroups,
  updateDeviceList,
  updateDeviceListWithGroups
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceListFooter);
