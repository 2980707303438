import React, {useState, useEffect} from 'react';
import HomeNav from './nav';
import LiveMap from '../Map/live';
import {connect} from 'react-redux';
import MessageWindow from '../Commons/messageWindow';


function Live(props) {
  
  
  const deviceId = props.match.params.deviceId;
  const [device, setDevice] = useState({});
  
  useEffect(() => {
    
    if (props.devices.length > 0 && props.access === true) {
      let device = props.devices.filter(device => parseInt(device['device_id']) === parseInt(deviceId));
      
      setDevice(device[0]);
      
    }
    

  }, [props.devices, deviceId]);

  return (
    <div>
      <MessageWindow
        title="Yetkiniz yok!"
        message="Paketiniz bu özelliği içermiyor. Bilgi için: 0(232) 339 60 06"
        show={!props.access} />

      <HomeNav deviceId={deviceId} active="live" />
      <LiveMap {...props} device={device} />
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    devices: state.deviceListReducer.devices
  }
};

export default connect(mapStateToProps)(Live);
