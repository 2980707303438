import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import store from "./redux/store";

/** Layouts **/ 
import LoginLayoutRoute from "./layouts/LoginLayout";
import DashboardLayoutWidthSideBarRoute from "./layouts/DashboardLayoutWidthSideBar";
import DashboardLayoutRoute from "./layouts/DashboardLayout";
import DashboardLayoutBigList from "./layouts/DashboardLayoutBigList";

/** Components **/
import Home from './components/Home';
import Login from './components/Login';
import Routes from './components/Routes';
import Reports from './components/Reports';
import DailyReport from './components/Reports/DailyReport';
import MonthlyReport from './components/Reports/MonthlyReport';
import RouteReport from './components/Reports/RouteReport';
import DriverList from './components/Reports/DriverList';
import HourlyReport from './components/Reports/HourlyReport';
import ResetPassword from './components/Login/ResetPassword';
import TheftProtection from './components/TheftProtection';
import TheftProtection2 from './components/TheftProtection2';
import RentACar from './components/RentACar';
import TankSignals from './components/TankSignals';
import WebSiteIntegration from './components/WebSiteIntegration';
import Locations from './components/Locations';
import VehicleServices from './components/VehicleServices';
import Signals from './components/Signals';
import GeofenceReports from './components/Reports/Geofence';
import AreaControlReports from './components/Reports/Geofence/areaControl';
import SpeedReport from './components/Reports/SpeedReport';
import UserRights from './components/UserRights';
import NewUser from './components/UserRights/newUser';
import EditUser from './components/UserRights/editUser';
import VehicleGroups from './components/VehicleGroups';
import DataDelete from './components/DataDelete';
import DataDeleteDevice from './components/DataDelete/dataDeleteDevice';

/** Home **/
import Route from './components/Home/route';
import Location from './components/Home/location';
import Live from './components/Home/live';
import DailyReportHome from './components/Home/dailyReport';
import MonthlyReportHome from './components/Home/monthlyReport';
import ColorRoute from './components/Home/colorRoute';
import AnimRoute from './components/Home/animRoute';
import OptimizedRoute from './components/Home/optimizedRoute';
import SpeedRoute from './components/Home/speedRoute';
import AutoPilot from './components/Home/autoPilot';
import FuelConsumption from './components/Home/fuelConsumption';
import Graphs from './components/Home/graphs';

ReactDOM.render(
  <Provider store={store}>
      <HashRouter>
        <Switch>
          <DashboardLayoutWidthSideBarRoute exact path="/" component={Home} />
          <DashboardLayoutWidthSideBarRoute path="/home/location/:deviceId" component={Location} />
          <DashboardLayoutWidthSideBarRoute path="/home/route/:deviceId" component={Route} />
          <DashboardLayoutWidthSideBarRoute path="/home/live/:deviceId" component={Live} />
          <DashboardLayoutWidthSideBarRoute path="/home/daily-report/:deviceId" component={DailyReportHome} />
          <DashboardLayoutWidthSideBarRoute path="/home/monthly-report/:deviceId" component={MonthlyReportHome} />
          <DashboardLayoutWidthSideBarRoute path="/home/color-route/:deviceId" component={ColorRoute} />
          <DashboardLayoutWidthSideBarRoute path="/home/anim-route/:deviceId" component={AnimRoute} />
          <DashboardLayoutWidthSideBarRoute path="/home/optimized-route/:deviceId" component={OptimizedRoute} />
          <DashboardLayoutWidthSideBarRoute path="/home/speed-route/:deviceId" component={SpeedRoute} />
          <DashboardLayoutWidthSideBarRoute path="/home/auto-pilot/:deviceId" component={AutoPilot} />
          <DashboardLayoutWidthSideBarRoute path="/home/fuel-consumption/:deviceId" component={FuelConsumption} />
          <DashboardLayoutWidthSideBarRoute path="/home/graphs-reports/:deviceId" component={Graphs} />
          <LoginLayoutRoute store={store} path="/login" component={Login} />
          <LoginLayoutRoute store={store} path="/reset-password" component={ResetPassword} />
          <DashboardLayoutRoute path="/routes" component={Routes} />
          <DashboardLayoutRoute path="/theft-protection" component={TheftProtection} />
          <DashboardLayoutRoute path="/theft-protection-2" component={TheftProtection2} />
          <DashboardLayoutRoute path="/rent-a-car" component={RentACar} />
          <DashboardLayoutRoute path="/tank-signals" component={TankSignals} />
          <DashboardLayoutRoute path="/web-site-integration" component={WebSiteIntegration} />
          <DashboardLayoutRoute path="/locations" component={Locations} />
          <DashboardLayoutRoute path="/electronic-reports/daily/device/:deviceId" component={DailyReport} />
          <DashboardLayoutRoute path="/electronic-reports/daily/driver/:driverId" component={DailyReport} />
          <DashboardLayoutRoute path="/electronic-reports/monthly/device/:deviceId" component={MonthlyReport} />
          <DashboardLayoutRoute path="/electronic-reports/monthly/driver/:driverId" component={MonthlyReport} />
          <DashboardLayoutRoute path="/electronic-reports/route/device/:deviceId" component={RouteReport} />
          <DashboardLayoutRoute path="/electronic-reports/drivers/device/:deviceId" component={DriverList} />
          <DashboardLayoutRoute path="/electronic-reports/hourly/device/:deviceId" component={HourlyReport} />
          <DashboardLayoutRoute path="/electronic-reports" component={Reports} />
          <DashboardLayoutRoute path="/vehicle-service/:deviceId" component={VehicleServices} />
          <DashboardLayoutRoute path="/vehicle-service" component={VehicleServices} />
          <DashboardLayoutRoute path="/signals/:deviceId" component={Signals} />
          <DashboardLayoutRoute path="/geofence-reports/:deviceId" component={GeofenceReports} />
          <DashboardLayoutRoute path="/geofence-reports-2/:deviceId" component={AreaControlReports} />
          <DashboardLayoutRoute path="/speed-report/:deviceId" component={SpeedReport} />
          <DashboardLayoutRoute path="/user-rights/new" component={NewUser} />
          <DashboardLayoutRoute path="/user-rights/edit/:subUserId" component={EditUser} />
          <DashboardLayoutRoute path="/user-rights" component={UserRights} />
          <DashboardLayoutRoute path="/vehicle-groups" component={VehicleGroups} />
          <DashboardLayoutRoute path="/data-delete" component={DataDelete} />
          <DashboardLayoutRoute path="/data-delete-device/" component={DataDeleteDevice} />
          <DashboardLayoutBigList store={store} path="/big-list" component={Home} />
          <DashboardLayoutBigList path="/location/:deviceId" component={Location} />
          <DashboardLayoutBigList path="/route/:deviceId" component={Route} />
          <DashboardLayoutBigList path="/live/:deviceId" component={Live} />
          <DashboardLayoutBigList path="/daily-report/:deviceId" component={DailyReportHome} />
          <DashboardLayoutBigList path="/monthly-report/:deviceId" component={MonthlyReportHome} />
          <DashboardLayoutBigList path="/color-route/:deviceId" component={ColorRoute} />
          <DashboardLayoutBigList path="/anim-route/:deviceId" component={AnimRoute} />
          <DashboardLayoutBigList path="/optimized-route/:deviceId" component={OptimizedRoute} />
          <DashboardLayoutBigList path="/speed-route/:deviceId" component={SpeedRoute} />
          <DashboardLayoutBigList path="/auto-pilot/:deviceId" component={AutoPilot} />
          <DashboardLayoutBigList path="/fuel-consumption/:deviceId" component={FuelConsumption} />
          <DashboardLayoutBigList path="/graphs-reports/:deviceId" component={Graphs} />
        </Switch>
      </HashRouter>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
