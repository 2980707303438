import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import HomeNav from './nav';
import RouteMap from '../Map/route';
import DateFilter from '../Commons/dateFilter';
import HourChart from '../HourChart';
import moment from 'moment';
import MessageWindow from '../Commons/messageWindow';

import DriverService from '../../services/Drivers';
import Reports from '../../services/Reports';
import SignalService from '../../services/Signals';
import GeofencePoints from '../../services/GeofencePoints';
import CarService from '../../services/Cars';

import {getTime, pad} from '../Assets/utils';
import Loading from "../Commons/loading";

function Route(props) {

  const deviceId = props.match.params.deviceId;

  const signalService = new SignalService();
  const reportService = new Reports();
  const driverService = new DriverService();
  const geofencePointsService = new GeofencePoints();
  const carService = new CarService();

  const [size, setSize] = useState([window.innerWidth - 530, 50]);
  const [signals, setSignals] = useState([]);
  const [geofencePoints, setGeofencePoints] = useState([]);
  const [trips, setTrips] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [sleepTrips, setSleepTrips] = useState([]);
  const [hours, setHours] = useState([]);
  const [device, setDevice] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filterDate, setFilterDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState({});
  const [mileage, setMileage] = useState({
    start: 0,
    end: 0
  });

  const handleChangeDate = date => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();

    setSelectedDate(date);
    setFilterDate(`${year}-${month}-${day}`);
    setHours([]);
  };

  const handleClickHour = (item) => {
    let startDate = moment(item.dates.startDate).format("YYYY-MM-DD H:mm:ss");
    let endDate = moment(item.dates.endDate).format("YYYY-MM-DD H:mm:ss");
    signalService.getSignalsDateRange(deviceId, startDate, endDate)
      .then((response) => {
        setSignals(response.data[0].signals);
      });
  };

  const updateSize = () => {
    setSize([window.innerWidth - 530, 50]);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setSignals([]);

      await Promise.all([
        signalService.getSignals(deviceId, filterDate),
        reportService.getDeviceDailyTrips(deviceId, filterDate + " 00:00:00", filterDate + " 23:59:59"),
        driverService.getDrivers(),
        reportService.getDeviceSleepTrips(deviceId, filterDate + " 00:00:00", filterDate + " 23:59:59"),
        geofencePointsService.getGeofencePoints()
      ]).then(([signalsResponse, tripsResponse, driversResponse, sleepResponse, geofenceResponse]) => {

        setTrips(tripsResponse.data);
        setDrivers(driversResponse.data);
        setSleepTrips(sleepResponse.data);
        setGeofencePoints(geofenceResponse.data);

        if (signalsResponse.data[0].signals.length > 0) {

          setSignals(signalsResponse.data[0].signals);

          let device = {
            type: signalsResponse.data[0].type,
            name: signalsResponse.data[0].name,
            device_id: signalsResponse.data[0].device_id,
            address: ""
          };

          setDevice(device);

        } else {

          signalService.getLocation(deviceId).then((signalsResponse) => {
            setSignals([signalsResponse.data[0]]);

            let device = {
              type: signalsResponse.data[0].type,
              name: signalsResponse.data[0].name,
              device_id: signalsResponse.data[0].device_id,
              address: ""
            };

            setDevice(device);
          });
        }

        tripsResponse.data.forEach((item) => {
          setHours(hours => [...hours, {
            startDate: new Date(item.start_time),
            endDate: new Date(item.end_time),
            selectedDate: new Date(filterDate)
          }]);
        });

        calculateTotal(tripsResponse.data);
        fetchMileages(tripsResponse.data[0], tripsResponse.data[tripsResponse.data.length - 1]);

      });

    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  };

  const fetchMileages = (firstTrip, lastTrip) => {
    if (firstTrip !== undefined && lastTrip !== undefined) {
      Promise.all([
        signalService.getSignalBySignalId(deviceId, firstTrip.start_signal_id),
        signalService.getSignalBySignalId(deviceId, lastTrip.end_signal_id),
        carService.getCar(deviceId)
      ])
        .then(([startSignalResponse, endSignalResponse, carResponse]) => {
          const start_signal = startSignalResponse.data;
          const end_signal = endSignalResponse.data;
          const car = carResponse.data;

          setMileage({
            start: start_signal.mileage + car.car_mileage_at_install,
            end: end_signal.mileage + car.car_mileage_at_install
          });
        });
    }
  };

  const calculateTotal = (_trips) => {
    let dayText = 'Gün';
    let day, hour, min, i;
    let _total = {}, km = 0, office_km = 0, private_km = 0, home_km = 0, total_duration_sec = 0, total_waiting_time_sec = 0;

    for (i = 0; i < _trips.length; i++) {
      // görev
      if (_trips[i]['private_trip'] == 1 || _trips[i]['private_trip'] == 0) office_km += _trips[i]['lenght_km'];
      // özel
      if (_trips[i]['private_trip'] == 2) private_km += _trips[i]['lenght_km'];
      // ev
      if (_trips[i]['private_trip'] == 3) home_km += _trips[i]['lenght_km'];

      km += _trips[i]['lenght_km'];
      total_waiting_time_sec += _trips[i]['waiting_time_sec'];
      total_duration_sec += _trips[i]['duration_sec'];
    }

    // total kms
    _total.office_km = Number(office_km.toFixed(2));
    _total.private_km = Number(private_km.toFixed(2));
    _total.home_km = Number(home_km.toFixed(2));
    _total.km = Number(km.toFixed(2));

    // calculate percents
    _total.office_km_percent = (office_km > 0) ? Number(((office_km * 100) / km).toFixed(1)) : 0;
    _total.private_km_percent = (private_km > 0) ? Number(((private_km * 100) / km).toFixed(1)) : 0;

    // calculate total duration
    day = Math.floor(total_duration_sec / (3600 * 24));
    hour = Math.floor((total_duration_sec / 3600) % 24);
    min = Math.floor((total_duration_sec / 60) % 60);
    _total.duration = day + " " + dayText + " " + pad(hour) + ":" + pad(min);

    // calculate total waiting time
    day = Math.floor(total_waiting_time_sec / (3600 * 24));
    hour = Math.floor((total_waiting_time_sec / 3600) % 24);
    min = Math.floor((total_waiting_time_sec / 60) % 60);
    _total.waiting_time = day + " " + dayText + " " + pad(hour) + ":" + pad(min);

    // calculate total active time
    day = Math.floor((total_duration_sec + total_waiting_time_sec) / (3600 * 24));
    hour = Math.floor(((total_duration_sec + total_waiting_time_sec) / 3600) % 24);
    min = Math.floor(((total_duration_sec + total_waiting_time_sec) / 60) % 60);
    _total.active_time = day + " " + dayText + " " + pad(hour) + ":" + pad(min);

    setTotal(_total);
  };

  const getDriverById = (driverId) => {
    if (drivers.length) {
      let driver = drivers.find(elements => elements.driver_id === driverId);
      if (driver)
        return driver.driver_first_name;
      else
        return "";
    } else {
      return "";
    }
  };

  const getSleepTripByTripId = (tripId) => {
    let sleepTrip = sleepTrips.find(elements => parseInt(elements.trip_id) === parseInt(tripId));

    if (sleepTrip)
      return sleepTrip.duraction_sec;
    else
      return "-";
  };

  useEffect(() => {
    if (filterDate !== "") {
      fetchData();
    }

    window.addEventListener('resize', updateSize);

    handleChangeDate(selectedDate);

    return () => {
      signalService.getCancelToken().cancel();
      reportService.getCancelToken().cancel();
      window.removeEventListener('resize', updateSize);
    };
  }, [deviceId, filterDate]);

  return (
    <>
      {loading ? (<Loading/>) : null}

      <MessageWindow
        title="Yetkiniz yok!"
        message="Paketiniz bu özelliği içermiyor. Bilgi için: 0(232) 339 60 06"
        show={!props.access}
      />

      <HomeNav deviceId={deviceId} active="route"/>

      <div style={{float: "left", marginTop: 12}}>
        <DateFilter
          onChange={handleChangeDate}/>
      </div>

      <div style={{float: "left"}}>
        <HourChart color={false} onClick={handleClickHour} offsetLeft={292} offsetTop={42} size={size} hours={hours}/>
      </div>

      <RouteMap device={device} points={geofencePoints} signals={signals} {...props} />

      <>
        <table style={{textAlign: 'center', fontSize: 12}} className="table table-hover">
          <thead>
          <tr>
            <th><FormattedMessage id="GENERAL.DATE"/></th>
            <th><FormattedMessage id="GENERAL.START"/></th>
            <th><FormattedMessage id="GENERAL.STOP"/></th>
            <th><FormattedMessage id="GENERAL.KM"/></th>
            <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
            <th><FormattedMessage id="GENERAL.IDLE_TIME"/></th>
            <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
            <th><FormattedMessage id="GENERAL.DRIVER"/></th>
            <th><FormattedMessage id="REPORTS.TRIP_TYPE"/></th>
          </tr>
          </thead>
          <tbody>
          {
            trips.map((trip, index) => {
              return (
                <tr key={index}>
                  <td>{trip.start_date}</td>
                  <td>
                    {getTime(trip.start_time)}<br/>
                    {trip.start_address}
                  </td>
                  <td>
                    {getTime(trip.end_time)}<br/>
                    {trip.end_address}
                  </td>
                  <td>{trip.lenght_km}</td>
                  <td>{trip.duration.substr(0, 5) === "00:00" ? ' ' : trip.duration.substr(0, 5)}</td>
                  <td>{getSleepTripByTripId(trip.trip_id)} </td>
                  <td>{trip.waiting_time.substr(0, 5)}</td>
                  <td>{getDriverById(trip.driver_id)}</td>
                  <td>{trip.private_trip ? <FormattedMessage id="REPORTS.TRIP_TYPE_1"/> :
                    <FormattedMessage id="REPORTS.TRIP_TYPE_2"/>}</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </>
      <div className="row">
        <div className="col-md-3">
          <table className="table table-hover">
            <thead>
            <tr>
              <th>Km - {<FormattedMessage id="GENERAL.MORNING"/>}</th>
              <th>Km - {<FormattedMessage id="GENERAL.EVENING"/>}</th>
            </tr>
            </thead>
            <tbody>
            <tr style={{backgroundColor: '#fcf8e3'}}>
              <td>{mileage.start}</td>
              <td>{mileage.end}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-9 pull-right">
          <table className="table table-hover">
            <thead>
            <tr>
              <th/>
              <th>{<FormattedMessage id="GENERAL.TRIP_TIME"/>}</th>
              <th>{<FormattedMessage id="GENERAL.WAIT_TIME"/>}</th>
              <th>Km - {<FormattedMessage id="REPORTS.TRIP_TYPE_2"/>}</th>
              <th>Km - {<FormattedMessage id="REPORTS.TRIP_TYPE_1"/>}</th>
              <th>{<FormattedMessage id="GENERAL.TOTAL"/>} Km</th>
            </tr>
            </thead>
            <tbody>
            <tr style={{backgroundColor: '#d9edf7'}}>
              <td><b>{<FormattedMessage id="GENERAL.TOTAL"/>}</b></td>
              <td>{total.duration}</td>
              <td>{total.waiting_time}</td>
              <td>{total.private_km} ({total.private_km_percent}%)</td>
              <td>{total.office_km} ({total.office_km_percent}%)</td>
              <td>{total.km} (100%)</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Route;
