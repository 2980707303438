import React, {useEffect, useState} from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from "react-intl";
import 'bootstrap/dist/css/bootstrap.min.css';
import './DashboardLayout.css';
import {optionPermissions} from '../config';

import {langTr, langEn, langDe, langNl} from "../lang";

import HeaderNav from '../components/HeaderNav';
import DeviceList from '../components/DeviceList';
import DeviceListToolbar from '../components/DeviceListToolbar';
import DeviceListFooter from '../components/DeviceListFooter';
import {sortAllDevices, sortByGroup} from '../components/Assets/utils';

import UserService from '../services/Users';
import DeviceService from '../services/Devices';
import DeviceOptionsService from '../services/DeviceOptions';

import {
  updateUserGroups,
  updateDeviceList,
  updateDeviceListWithGroups,
  updateSearchContent
} from '../redux/actions';

const langConfig = {...langTr, ...langEn, ...langDe, ...langNl};
const langs = ['de', 'en', 'tr', 'nl'];

const DashboardLayout = ({children}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toggled, setToggled] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const userService = new UserService();
  const deviceService = new DeviceService();

  function handleNavbarTogglerClick() {
    setIsOpen(!isOpen);
    setToggled(!isOpen ? 'toggled' : '');
  }

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const [devices, groups] = await Promise.all([
        deviceService.getDevicesWithGroups(children.props.sortType, children.props.devices_on_display),
        userService.getUserGroups()
      ])
        .then(([devicesResponse, groupsResponse]) => {
          children.props.updateDeviceList({
            devices: devicesResponse.data[0]["results"],
            totalCount: devicesResponse.data[1]["total_count"]
          });

          children.props.updateUserGroups({userGroups: groupsResponse.data[0]["results"]});

          return [devicesResponse.data[0]["results"], groupsResponse.data[0]["results"]];
        });

      return [devices, groups];

    } catch (error) {
    }
  };

  useEffect(() => {

    fetchData()
      .then((data) => {
        if (data) {
          let deviceListWithGroup = (children.props.groupSort) ? sortByGroup(data[0], data[1]) : sortAllDevices(data[0], data[1]);
          children.props.updateDeviceListWithGroups({devicesWithGroups: deviceListWithGroup});
          children.props.updateSearchContent({contents: []});
          setIsLoading(false);
        }
      });

    //unmount
    return () => {
      deviceService.getCancelToken().cancel();
    }
  }, [children.props.sortType, children.props.groupSort, children.props.devices_on_display]);

  return (
    <React.Fragment>
      <HeaderNav {...children.props} isOpen={isOpen} onClick={handleNavbarTogglerClick}/>
      <div id="wrapper" className={toggled}>
        <div id="sidebar-wrapper">
          <DeviceListToolbar {...children.props} />
          {
            isLoading ?
              (
                <div>
                  <br/>
                  <center><img src={require('../components/Assets/images/horizontal-loading.gif').default} alt=""/></center>
                  <br/>
                </div>
              ) :
              (<DeviceList {...children.props} />)
          }
          <DeviceListFooter {...children.props} />
        </div>

        <div id="page-content-wrapper">
          <div className="container-fluid nopadding">
            <div className="main">
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

const DashboardLayoutRoute = ({component: Component, ...rest}) => {  

  const [access, setAccess] = useState(true);

  if (!sessionStorage.getItem('access_token')) {
    window.location.href = process.env.PUBLIC_URL + "/#login";
  }

  useEffect(() => {
    const deviceOptionsService = new DeviceOptionsService();
    let options = [];

    let url = rest.computedMatch.path;
    if (url.indexOf('deviceId') !== -1) {
      let deviceId = rest.computedMatch.params.deviceId;
      let option = optionPermissions[url];

      if (!sessionStorage.getItem(`options_${deviceId}`)) {
        deviceOptionsService.getByDeviceId(deviceId).then((response) => {
          sessionStorage.setItem(`options_${deviceId}`, JSON.stringify(response.data));
          options = response.data;
        });
      }
      else {
        options = JSON.parse(sessionStorage.getItem(`options_${deviceId}`));
      }

      if (option) {
        if (options.length > 0) {
          let found = options.find((element) => element.device_option_id === option);
          if (found) {
            setAccess(Boolean(found.active));
          }
          else {
            setAccess(false);
          }
        }
      }
    }
    
  }, [rest.computedMatch]);
  

  return (
    <IntlProvider locale={langs[rest.lang]} messages={langConfig[langs[rest.lang]]}>
      <Route {...rest} render={matchProps => (
        <DashboardLayout>
          <Component access={access} {...rest} {...matchProps} />
        </DashboardLayout>
      )}/>
    </IntlProvider>
  )
};

const mapStateToProps = function (state) {
  return {
    lang: state.userInformationReducer.user.language,
    devices_on_display: state.userInformationReducer.user.devices_on_display,
    listType: state.deviceListTypeReducer.listType,
    sortType: state.deviceSortTypeReducer.sortType,
    groupSort: state.deviceGroupSortReducer.groupSort
  }
};

const mapDispatchToProps = {
  updateUserGroups,
  updateDeviceList,
  updateDeviceListWithGroups,
  updateSearchContent
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayoutRoute);