import React from 'react';

function MapLinkExport(props) {
  const listType = props.listType;
  const device_id = props.deviceId;
  const encoded_access_token = sessionStorage.getItem('access_token');
  const decoded_access_token = decodeURIComponent(escape(window.atob(encoded_access_token)));

  let span = '';
  if (listType === 'round-list') {
    span = '<span class="round"><b> </b></span>';
  }

  let mapLink = 'export_html.php?device_id=' + device_id + '&api_key=' + decoded_access_token;

  return (
    <div>
        {
            decoded_access_token !== null ?  <a href= {mapLink} target="_blank"  rel="noopener noreferrer"> <img src={require('../Assets/images/map_link2.png').default}  alt="google maps link"/> {span} </a>
            : null
        }
    </div>
  );
}

export default MapLinkExport;
