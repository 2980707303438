import React from 'react';
import PropTypes from 'prop-types';

function IconTextWithImg(props) {
  return (
    <span>
      {
        props.newLine ?
        (
          <>
          <center>
            <img
              width={`${props.size[0]}px`}
              height={`${props.size[1]}px`}
              alt={props.text}
              src={props.icon} />
            </center>
            <span>{props.text}</span>
          </>
        ):
        (
          <>
            <img
              width={`${props.size[0]}px`}
              height={`${props.size[1]}px`}
              alt={props.text}
              src={props.icon} />
              <span>{props.text}</span>
          </>
        )
      }
    </span>
  );
}

IconTextWithImg.defaultProps = {
  newLine: false,
  size: [24, 24]
};

IconTextWithImg.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string
};

export default IconTextWithImg;