import BaseService from './BaseService';

class DeviceOptionsService extends BaseService {

  getByDeviceId(deviceId) {
    return this.axiosInstance.get(`/device_options/${deviceId}/?`,
    {cancelToken: this.cancelTokenSource.token});
  }

  getByDeviceOptionId(deviceId, deviceOptionId) {
    return this.axiosInstance.get(`/device_options_control/${deviceId}/${deviceOptionId}/?`,
    {cancelToken: this.cancelTokenSource.token});
  }
  
  getAllDeviceOptions(deviceOptionId) {
    return this.axiosInstance.get(`/all_device_options_control/${deviceOptionId}/?`,
    {cancelToken: this.cancelTokenSource.token});
  }

  getSubUserDeviceOptions(userId, deviceId) {
    return this.axiosInstance.get(`/device_options/sub_user_device_information/${deviceId}/${userId}/?`, {cancelToken: this.cancelTokenSource.token});
  }

  updateSubUserDeviceOptions(deviceId, userId, data) {
    return this.axiosInstance.post(`/device_options/sub_user_device/${deviceId}/${userId}/update/?`, data);
  }

  getFavoriteOptions() {
    return this.axiosInstance.get(`/user_favorite_device_options/?`, {cancelToken: this.cancelTokenSource.token});
  }

  getFavoriteOptionDetails(favoriteId) {
    return this.axiosInstance.get(`/user_favorite_device_options/${favoriteId}/?`, {cancelToken: this.cancelTokenSource.token});
  }

  createFavoriteOption(data) {
    return this.axiosInstance.post(`/user_favorite_device_options/create/?`, data);
  }

  updateFavoriteOption(favoriteId, data) {
    return this.axiosInstance.post(`/user_favorite_device_options/update/${favoriteId}/?`, data);
  }

  deleteFavoriteOption(favoriteId) {
    return this.axiosInstance.get(`/user_favorite_device_options/delete/${favoriteId}/?`);
  }

}

export default DeviceOptionsService;