import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Modal, Container} from "react-bootstrap";

function MessageWindow(props) {

  const [show, setShow] = useState(props.show);

  const handleHide = () => {
    setShow(false);
    
    if (props.onHide)
      props.onHide();
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <Modal
      size={(props.size) ? props.size : "lg"}
      show={show}
      aria-labelledby="messageWindow"
      onHide={handleHide}
    >
      {(props.title) ?
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        : null
      }
      <Modal.Body className="dropdown-menu-navbar">
        <Container>
          {props.message}
        </Container>
      </Modal.Body>
      {
        props.footer ?
          <Modal.Footer>
            {props.footer}
          </Modal.Footer>
          : null
      }
    </Modal>
  );
}

MessageWindow.propTypes = {
  size: PropTypes.string,
  show: PropTypes.bool.isRequired,
  title: PropTypes.node,
  message: PropTypes.node.isRequired,
};

export default MessageWindow;
