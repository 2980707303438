import React, { createRef, useEffect, useState } from 'react';
import moment from 'moment';

let shapes = [];
function HourChart(props) {

    const getColor = index => {
        switch (index % 31) {
            case 0 :
                return "#3300ff";
            case 1 :
                return "#FF0000";
            case 2 :
                return "#7FFF00";
            case 3 :
                return "#6495ED";
            case 4 :
                return "#FFD700";
            case 5 :
                return "#9400D3";
            case 6 :
                return "#00BFFF";
            case 7 :
                return "#ADFF2F";
            case 8 :
                return "#D3D3D3";
            case 9 :
                return "#FF7F50";
            case 10 :
                return "#7B68EE";
            case 11 :
                return "#FFE4E1";
            case 12 :
                return "#2E8B57";
            case 13 :
                return "#F4A460";
            case 14 :
                return "#A0522D";
            case 15 :
                return "#87CEEB";
            case 16 :
                return "#DA70D6";
            case 17 :
                return "#32CD32";
            case 18 :
                return "#FFFF00";
            case 19 :
                return "#0000FF";
            case 20 :
                return "#C0C0C0";
            case 21:
                return "#8B0000";
            case 22:
                return "#00CED1";
            case 23:
                return "#FF6347";
            case 24:
                return "#F5DEB3";
            case 25:
                return "#FFA500";
            case 26:
                return "#6B8E23";
            case 27:
                return "#F0E68C";
            case 28:
                return "#F08080";
            case 29:
                return "#ADD8E6";
            case 30:
                return "#7CFC00";
            default  :
                return "#3300ff";
        }
    }
    
    const canvasRef = createRef();
    const [cursor, setCursor] = useState('default');
    const [showTip, setShowTip] = useState('none');
    const [xTip, setXTip] = useState(0);
    const [textTip, setTextTip] = useState('');
    const _offsetLeft = props.offsetLeft || 0;
    const _offsetTop = props.offsetTop || 0;

    const calculateHoursBetweenSpace = (_width) => {
        return (_width / 25);
    };

    const calculateMinutesBetweenSpace = (hour_between_space) => {
		return (hour_between_space / 60);
    };
    
    const pad2 = (number) => {
		return (number < 10 ? '0' : '') + number;
    };

    const getDateDiff = (start_date, end_date) => {
        return (start_date.getDate() - end_date.getDate());
    };

    const isIntersect = (point, shape, c) => {
        var x = point.pageX - c.offsetLeft - _offsetLeft || 0,
            y = point.pageY - c.offsetTop - _offsetTop || 0;
            
        return shape.x <= x && x <= shape.x + shape.width &&
               shape.y <= y && y <= shape.y + shape.height;
    };

    const handleMouseMove = event => {
        let hit = false;
        shapes.forEach((item) => {
            if (isIntersect(event, item, canvasRef.current)) {
                hit = true;
                setCursor('pointer');
                setShowTip('block');
                setXTip(event.pageX - _offsetLeft);
                let hourText = "";
                let minuteText = `${item.minutes} dakika`;

                if (item.hours > 0) {
                    hourText = `${item.hours} saat`;
                    let _tmp =  item.minutes - (item.hours * 60);
                    minuteText = `${_tmp} dakika`;
                }

                setTextTip(`${hourText} ${minuteText} 
                ${moment(item.startTime).format('H:mm')} - ${moment(item.endTime).format('H:mm')}`);
            }
        });
        
        if (!hit) {
            setCursor('default');
            setShowTip('none');
            setTextTip('');
        }
    };

    const handleClick = (event) => {
        let hit = false;
        shapes.forEach((item) => {
            if (isIntersect(event, item, canvasRef.current)) {
                hit = item;
            }
        });

        if (hit)
            props.onClick(hit);
    };

    const addRect = (ctx, rect, index) => {
        ctx.fillStyle = props.color ? getColor(index) : getColor(0);
        ctx.beginPath();
        ctx.rect(rect.x, rect.y, rect.width, rect.height);
        ctx.fill();

        shapes.push(rect);
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const fontSize = 12;

        let hours_between_space = calculateHoursBetweenSpace(canvas.width);
        let minutes_between_space = calculateMinutesBetweenSpace(hours_between_space);

        ctx.fillStyle = '#dad9d9';
        ctx.fillRect(0,0, canvas.width, canvas.height);
        
        ctx.fillStyle = '#edecec';
        ctx.fillRect(0,0, canvas.width, (canvas.height / 2) - 5);

        // draw hour lines
        for(let i = 0; i < 25; i++) {
            let leftPosition = ((i * hours_between_space) + hours_between_space / 2);
            ctx.fillStyle = 'black';
            ctx.font = fontSize + 'px serif';
            ctx.fillText(pad2(i), (leftPosition - fontSize), 12);
            ctx.strokeStyle = 'white';
            ctx.beginPath();
            ctx.moveTo(leftPosition - (fontSize / 2), (canvas.height / 2) - 5);
            ctx.lineTo(leftPosition - (fontSize / 2), (canvas.height / 2) + 5);
            ctx.stroke();
        }

        // draw half hour lines
        for(let i = 1; i < 25; i++) {
            let leftPosition = (i * hours_between_space) - (fontSize / 2);
            ctx.strokeStyle = 'white';
            ctx.beginPath();
            ctx.moveTo(leftPosition, (canvas.height / 2) - 5);
            ctx.lineTo(leftPosition, (canvas.height / 2));
            ctx.stroke();
        }

        props.hours.forEach((item, index) => {
            let date_diff = getDateDiff(item.startDate, item.endDate);
            let start_minutes = parseInt(item.startDate.getHours() * 60) + parseInt(item.startDate.getMinutes());
            let total_hour = 24 - item.startDate.getHours();
			total_hour = total_hour + item.endDate.getHours();

			if (total_hour >= 24) {
				total_hour = total_hour - 24;
			}

            let total_day = Math.abs(date_diff) * 24;
            
            if (total_hour < 24) {
				if (date_diff != 0) {
					total_day = (Math.abs(date_diff) - 1) * 24;
				}
			}
			
            total_hour = total_day + total_hour;
            
            let total_minutes = 60 - item.startDate.getMinutes();
			total_minutes = total_minutes + item.endDate.getMinutes();

			if (total_minutes >= 60) {
				total_minutes = total_minutes - 60;
			} else {
				total_hour -= 1;
			}

            total_minutes = (total_hour * 60) + total_minutes;
            
            let _width = total_minutes * minutes_between_space;
            let start_x = 0;

			if (item.selectedDate.getDate() > item.startDate.getDate()) {
				start_x = 0;
				_width = ((item.endDate.getHours() * 60) + item.endDate.getMinutes() + 30) * minutes_between_space;
			}
			else {
				start_x = ((start_minutes * minutes_between_space) + (hours_between_space / 2)) - (fontSize / 2);
            }

            let element = {
                x: start_x,
                y: canvas.height / 2,
                width: _width,
                height: (canvas.height / 2) - 5,
                id: "n_" + index,
                dates: item,
                minutes: total_minutes,
                hours: total_hour,
                startTime: item.startDate,
                endTime: item.endDate
            };
    
            addRect(ctx, element, index);
        });

        return (() => {
            shapes = [];
        });
        
    }, [props.hours, props.size]);
    
    return (
        <>
            <div style={{left: xTip, position: 'absolute', color: '#fff', backgroundColor: '#404040', padding: 4, fontSize: 12, display: showTip}}>{textTip}</div>
            <canvas onClick={handleClick} onMouseMove={handleMouseMove} style={{cursor: cursor}} ref={canvasRef} width={props.size[0]} height={props.size[1]}/>
        </>
    );
}

HourChart.defaultProps = {
    color: true,
    onClick: () => {}
};

export default HourChart;
