import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DatePicker from "react-datepicker";
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import Moment from 'moment';
import MessageWindow from "../../Commons/messageWindow";

import DeviceService from '../../../services/Devices';
import SignalService from '../../../services/Signals';
let signal_mode = ["RGTK",
                   "LGTK",
                   "STBY",
                   "LACN",
                   "LACF",
                   "IBTN",
                   "IBTF",
                   "LGSK",
                   "LGEK",
                   "LMPN",
                   "LMPF",
                   "LD2N",
                   "LD2F",
                   "LD3N",
                   "LD3F",
                   "LD4N",
                   "LD4F",
                   "LD5N",
                   "LD5F",
                   "LD6N",
                   "LD6F",
                   "ROAM",
                   "LGUT",
                   "RGUT",
                   "ACCD",
                   "LANG",
                   "LDIS",
                   "LIDL",
                   "LMOV",
                   "LSIN",
                   "LSUT",
                   "RGRK",
                   "LGRK",
                   "LIBT",
                   "IBTA",
                   "IBTI",
                   "SMS",
                   "SOS",
                   "LACC",
                   "RMTK",
                   "LMTK",
                   "LGPS",
                   "LBAT",
                   "LVML",
                   "LGIN",
                   "LKIN",
                   "LUIN",
                   "LUOT",
                   "LMSK",
                   "LMEK",
                   "RMRK",
                   "LMRK",
                   "TOW",
                   "HBM",
                   "HBMA",
                   "HBMB",
                   "HBMT",
                   "HBMX",
                   "HBMY",
                   "AIRN",
                   "AIRF",
                   "BEAC"];

function HourlyReport(props) {

  const deviceId = parseInt(props.match.params.deviceId);

  let start_date = new Date();
  let end_date = new Date();

  // set first and last days of the month
  if (props.mainReportType === 'monthly') {
    start_date = new Date(start_date.getFullYear(), start_date.getMonth(), 1, 0, 0, 0);
    end_date = new Date(end_date.getFullYear(), end_date.getMonth() + 1, 0, 0, 0, 0);

  } else {
    start_date.setHours(0, 0, 0);
    end_date.setHours(23, 59, 0);

  }

  const deviceService = new DeviceService();
  const signalService = new SignalService();

  const [selectedDateStart, setSelectedDateStart] = useState(start_date);
  const [selectedDateEnd, setSelectedDateEnd] = useState(end_date);
  const [selectedVehicle, setSelectedVehicle] = useState({value:deviceId,label:''});
  const [showMessage, setShowMessage] = useState({
    message: '',
    title: '',
    status: false
  });
  const [firstSignal, setFirstSignal] = useState({});
  const [lastSignal, setLastSignal] = useState({});
  const [signals, setSignals] = useState([]);
  const [km, setKm] = useState(0);

  const getDeviceDetails = async () => {
    try {
      if (selectedVehicle.value > 0) {
        await deviceService.getDevice(selectedVehicle.value)
          .then((response) => {
            setSelectedVehicle({value:selectedVehicle.value, label:response.data.name});
            // get initial data
            getSignals();
          });

      } else {
        setShowMessage({
          title: <h5><FormattedMessage id="GENERAL.WARNING"/></h5>,
          message: <h5><FormattedMessage id="GENERAL.PLS_PICK_DEVICE"/></h5>,
          status: true
        })
      }

    } catch (err) {
      setShowMessage({
        title: <h5><FormattedMessage id="GENERAL.ERROR"/></h5>,
        message: <h5><FormattedMessage id="GENERAL.ERROR"/></h5>,
        status: true
      })
    }
  };

    const getSignals = async () => {
        let start_date, start_date_time, end_date, end_date_time;
        start_date = Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss")
        start_date_time = Moment(selectedDateStart).format("YYYY-MM-DD 23:59:59");
        end_date = Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss");
        end_date_time = Moment(selectedDateEnd).format("YYYY-MM-DD 00:00:00");
        let firstSignal = {};
        let lastSignal = {};
        let signals = [];

        await signalService.getDeviceSignalsWithAdr(deviceId, start_date, start_date_time, 0, 20, 0, 'ASC').then((data) => {
            if (data) {
                if (data.data[0].results.length > 1) {

                    for (var i = 0; i < data.data[0].results.length; i++) {
                        
                        if (data.data[0].results[i].signal_mode == 2) {
                            continue;
                        }
                        else {
                            firstSignal = data.data[0].results[i];
                            break;
                        }
                    }
                }
            }
        }).then(() => {
                signalService.getDeviceSignalsWithAdr(deviceId, end_date_time, end_date, 0, 20, 0, 'DESC').then((data) => {
                    if (data) {
                        if (data.data[0].results.length > 1) {

                            for (var i = 0; i < data.data[0].results.length; i++) {
                                
                                if (data.data[0].results[i].signal_mode == 2) {
                                    continue;
                                }
                                else {
                                    lastSignal = data.data[0].results[i];
                                    break;
                                }
                            }
                        }
                    }
                    let firstSignal_mileage = firstSignal.mileage === undefined ? 0 : firstSignal.mileage;
                    let lastSignal_mileage = lastSignal.mileage === undefined ? 0 : lastSignal.mileage;
                    signals = [firstSignal, lastSignal];
                    setKm(lastSignal_mileage - firstSignal_mileage);
                    setFirstSignal(firstSignal);
                    setLastSignal(lastSignal);
                    setSignals(signals);
                });
            }
        );
    };

  const handleSelectVehicle = (selectedOption) => {
    setSelectedVehicle(selectedOption);
    props.history.push(`/electronic-reports/hourly/device/${selectedOption.value}`);
  };

  useEffect(() => {
    getDeviceDetails();
  }, []);

  return (
    <>
        <div className="reports">
            <div>
                <h4>
                    <span> <FormattedMessage id="REPORTS.DEVICE_REPORTS"/> >&nbsp;
                        <FormattedMessage id="GENERAL.HOURLY_REPORT"/> 
                    </span>
                </h4>
            </div>
            <hr/>
            <div className="row">
                <div className="top col-md-2 devices">
                    <p className="mb-0"><b><FormattedMessage id="SERVICE.VEHICLE_NAME"/></b></p>
                    <Select
                        value={selectedVehicle}
                        onChange={handleSelectVehicle}
                        placeholder={<FormattedMessage id="GENERAL.PICK_DEVICE"/>}
                        options={props.devices.map((device) => {
                            return {value: device.device_id, label: device.name}
                        })}
                    />
                </div>
                <div className="col-md-10 filters">
                    <div className="row">
                        <div className="col-md-2 col-xs-12 date-pickers">
                            <p className="mb-0"><b><FormattedMessage id="GENERAL.START_DATE"/></b></p>
                            <DatePicker
                                className="mb-1"
                                todayButton="Today"
                                maxDate={new Date()}
                                dateFormat="dd-MM-yyyy HH:mm"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={60}
                                timeCaption="time"
                                selected={selectedDateStart}
                                onChange={(date) => setSelectedDateStart(date)}/>
                        </div>
                        <div className="col-md-2 col-xs-12 date-pickers">
                            <p className="mb-0"><b><FormattedMessage id="GENERAL.END_DATE"/></b></p>
                            <DatePicker
                                todayButton="Today"
                                maxDate={new Date()}
                                dateFormat="dd-MM-yyyy HH:mm"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={60}
                                timeCaption="time"
                                selected={selectedDateEnd}
                                onChange={(date) => setSelectedDateEnd(date)}/>
                        </div>
                        <div className="pull-left no-padding-left">
                            <div className="form-group">
                                <p className="mb-0"><b><FormattedMessage id="GENERAL.FILTER"/></b></p>
                                <button className="btn btn-primary btn-sm btn-block" onClick={() => getSignals()}>
                                    <FormattedMessage id="GENERAL.FILTER"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <hr />

            <div className="trips table-responsive" id="daily-trips">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th><FormattedMessage id="GENERAL.START"/></th>
                            <th><FormattedMessage id="GENERAL.START_ADDRESS"/></th>
                            <th><FormattedMessage id="GENERAL.STOP"/></th>
                            <th><FormattedMessage id="GENERAL.END_ADDRESS"/></th>
                            <th><FormattedMessage id="GENERAL.KM"/></th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr ng-class-even="'active'">
                                <td>{firstSignal.geocode_sent_at}</td>
                                <td>{firstSignal.address}</td>
                                <td>{lastSignal.geocode_sent_at}</td>
                                <td>{lastSignal.address}</td>
                                <td>{(km).toFixed(2)}</td>
                            </tr>
                    </tbody>
                </table>
            </div>
            <hr />
            <div className="trips table-responsive" id="daily-trips">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th><FormattedMessage id="GENERAL.DATE"/></th>
                                <th><FormattedMessage id="GENERAL.ADDRESS"/></th>
                                <th><FormattedMessage id="SIGNALS.SIGNAL_TYPE"/></th>
                                <th><FormattedMessage id="HOME.SPEED"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                signals.map((signal,index) => {
                                    return  <tr key={'signal-' + index} id={'signal-' + index} className={index%2 !== 0 ? 'active' : ''}>
                                                <td>{signal.geocode_sent_at}</td>
                                                <td>{signal.address}</td>
                                                <td>{signal_mode[signal.signal_mode]}</td>
                                                <td>{signal.speed_km} km/h</td>
                                            </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
        </div>
    <hr/>
    <div className="clearfix"/>
    <MessageWindow
      size='md'
      show={showMessage.status}
      onHide={() => setShowMessage({...showMessage, status: false})}
      title={showMessage.title}
      message={showMessage.message}/>
    </>

  );
}

const mapStateToProps = function (state) {
  return {
    devices: state.deviceListReducer.devices,
    drivers: state.driverListReducer.drivers,
    user: state.userInformationReducer.user
  }
};
export default connect(mapStateToProps)(HourlyReport);