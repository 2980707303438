import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import classNames from 'classnames';
import {toShow, toFixed} from '../../Assets/utils';
import './index.css';

import {updateDeviceListType} from '../../../redux/actions';

function BigList(props) {

  const [redirect, setRedirect] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleListType = (listType) => {
    props.updateDeviceListType({listType: listType});
    if(listType === 'standart' || listType === 'standart-big' || listType === 'klein' || listType === 'detailed' || listType === 'mini'){
      setRedirect('/');
    }
  };

  const vehicles_in_groups = (arr) => {
    var vehicles = [];
    for (var i = 0; i < arr.length; i++) {
      vehicles.push(
        <div className="gros-plate" key={arr[i].device_id}>
          <div onClick={() => handleListType('big-list')} className="car-icon">
            <Link to={`/location/${arr[i].device_id}`}>
              <img src={require('../../Assets/images/device-icons/car_' + arr[i].type +'.gif').default}  alt="" width="27"/>
            </Link>
          </div>
          <div>
            <div onClick={() => handleListType('big-list')} className="device-name">
              <Link className="name" to={`/route/${arr[i].device_id}`}>{toShow(arr[i].name, 9)}</Link>
            </div>
            <div onClick={() => handleListType('big-list')} className="device-live">
              <Link to={`/live/${arr[i].device_id}`}>Live</Link>
            </div>
          </div>
          <div className="pull-right text-center">
            <div onClick={() => handleListType('big-list')} className="mileage">
              <Link className="dm" to={`/daily-report/${arr[i].device_id}`}>
                <span className="value">{toFixed(arr[i].daily_mileage, 0)}</span>km
              </Link>
            </div>
            <div className="ignition">
              <img width="24" height="9" border="0"
                   src={arr[i].ignition_status === 'on' ? require('./images/ign_on.png').default : require('./images/ign_off.png').default}
                   alt=""/>
            </div>
          </div>
        </div>
      )
    }
    return vehicles;
  };

  useEffect(() => {
    (props.devicesWithGroups.length)? setIsLoading(false): setIsLoading(true);

  }, [props.devicesWithGroups.length]);

  if (redirect)
    return <Redirect to={redirect}/>;

  else {
    return (
      <div className="grossansicht">
        <div className="grossansicht-menu">
          <div className="dropdown">
            <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
              Menu <span className="caret"> </span>
            </button>
            <Dropdown className="dropdown-content dropdown-menu">
              <Dropdown.Toggle id='list-types' size="sm">
                <FormattedMessage id="HOME.LIST_TYPE"/>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleListType('standart')}
                               className={classNames({'active': (props.listType === 'standart')})}>
                  <FormattedMessage id="HOME.LIST_TYPE_STANDART"/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleListType('standart-big')}
                               className={classNames({'active': (props.listType === 'standart-big')})}>
                  <FormattedMessage id="HOME.LIST_TYPE_STANDART_BIG"/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleListType('klein')}
                               className={classNames({'active': (props.listType === 'klein')})}>
                  <FormattedMessage id="HOME.LIST_TYPE_KLEIN"/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleListType('detailed')}
                               className={classNames({'active': (props.listType === 'detailed')})}>
                  <FormattedMessage id="HOME.LIST_TYPE_DETAILERT"/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleListType('mini')}
                               className={classNames({'active': (props.listType === 'mini')})}>
                   <FormattedMessage id="HOME.LIST_TYPE_MINI"/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleListType('big')}
                               className={classNames({'active': (props.listType === 'big')})}>
                   <FormattedMessage id="HOME.LIST_TYPE_GROSSANSICHT"/>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="grossansicht-groups">
          {
            isLoading ?
              (
                <div>
                  <br/>
                  <center><img src={require('../../Assets/images/horizontal-loading.gif').default} alt=""/></center>
                  <br/>
                </div>) :
              (
                props.devicesWithGroups.map((deviceGroup, index) => {
                  return (
                    (deviceGroup[1].length < 1) ? (null) :
                      <Dropdown className="dropdown" key={index}>
                        <Dropdown.Toggle id={index} className="btn btn-success group-name dropdown-toggle">
                          {deviceGroup[0]} <span className="caret"> </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-content dropdown-menu">
                          {
                            vehicles_in_groups(deviceGroup[1])
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                  )
                })
              )
          }
        </div>
      </div>
    );
  }

}

const mapStateToProps = function (state) {
  return {
    user: state.userInformationReducer.user,
    devicesWithGroups: state.deviceListWithGroupsReducer.devicesWithGroups,
    listType: state.deviceListTypeReducer.listType
  }
};

const mapDispatchToProps = {
  updateDeviceListType
};

export default connect(mapStateToProps, mapDispatchToProps)(BigList);

