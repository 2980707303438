import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';

import MessageWindow from '../Commons/messageWindow';
import TankSignalService from '../../services/TankSignals';


function TankSignals(props) {

  const tankSignalService = new TankSignalService();

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [done, setDone] = useState(true);
  const [fuelData, setFuelData] = useState([]);
  const [showMessage, setShowMessage] = useState({
    "show": false,
    "title": "",
    "message": ""
  });



  const deviceList = props.devices.map(item => {
    return {name: item['name'], device_id: item['device_id']}
  });

  const handleSelectVehicle = (selectedOption) => {
    setSelectedVehicle(selectedOption);
  };

  const fetchData = async () => {
    setDone(false);
    await tankSignalService.getData(parseInt(selectedVehicle.value))
        .then(async(response) => {
            setFuelData(response.data);
            setDone(true);
        });
  };

  useEffect(() => {
    if (selectedVehicle) {
      fetchData();
    }
  }, [selectedVehicle]);

  return (
    <>
        <MessageWindow
            size="md"
            show={showMessage.show}
            onHide={() => setShowMessage({...showMessage, show: false})}
            title={showMessage.title}
            message={showMessage.message} />

        <div className="sms-credits">
            <h3>Tank-<FormattedMessage id="MYINOUT.ZONE"/></h3>
            <div className="row sms-options-text">
                <div className="col-md-12">
                    <div className="col-md-8 col-title">
                        <Select
                            value={selectedVehicle}
                            onChange={handleSelectVehicle}
                            placeholder={<FormattedMessage id="GENERAL.PICK_DEVICE"/>}
                            options={deviceList.map((device) => {
                            return {value: device.device_id, label: device.name}
                            })}
                        /><br />
                    </div>
                </div>
            </div>
            {   
                done ? 
                <div className="row">
                    <div className="col-md-8">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th style={{textAlign: "left"}}>Beacon ID</th>
                                        <th style={{textAlign: "left"}}>Data</th>
                                        <th style={{textAlign: "left"}}><FormattedMessage id="GENERAL.TIME"/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fuelData.map((data) => {
                                            return  <tr>
                                                        <td>{data.beacon_id}</td>
                                                        <td>{data.fuel_data}</td>
                                                        <td>{data.date}</td>
                                                    </tr>
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> : null
            }
        </div>
    </>
  );
}

const mapStateToProps = function(state) {
  return {
    devices: state.deviceListReducer.devices
  }
};

export default connect(mapStateToProps)(TankSignals);
