import React, {useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import {Col, Row, Tabs, Tab} from 'react-bootstrap';
import MessageWindow from '../Commons/messageWindow';
import IconTextWithImg from '../Commons/iconTextWithImg';
import {toShow} from '../Assets/utils';
import moment from 'moment';

import Loader from '../Commons/loader';

/** Components **/
import BasicSettings from './basicSettings';
import Contacts from './contacts';
import OilService from './oil';
import BrakeService from './brake';
import BeltService from './belt';
import InspectionService from './inspection';
import TachographService from './tachograph';
import TrailerService from './trailer';
import IvdcService from './ivdc';
import HideDeviceService from './hideDevice';

/** Services **/
import DeviceService from '../../services/Devices';
import CarService from '../../services/Cars';
import VehicleService from '../../services/VehicleService';
import IvdcDevicesService from '../../services/IvdcDevices';
import TemperatureService from '../../services/TemperatureService';
import FuelService from '../../services/FuelService';
import HideDevicesService from '../../services/HideDevicesService';

import './index.css';

function VehicleServices(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedVehicle, setSelectedVehicle] = useState();
    const [activeTab, setActiveTab] = useState("basic-settings");
    const [showMessage, setShowMessage] = useState({status: false, title: "", message: ""});
    const [deviceList, setDeviceList] = useState([]);
    const [basicSettings, setBasicSettings] = useState({
        name: "",
        avg_fuel_consumption: 0,
        total_mileage: 0,
        car_mileage_at_install: 0,
        total_motor_mins: 0,
        start_motor_mins: 0,
        email_1: "",
        email_2: "",
        email_3: "",
        warning_at_vehicle_service: 0,
        type: 1,
        input_count: 0
    });

    const [contacts, setContacts] = useState({
        contact_2_name: "",
        contact_2_pull: 0,
        contact_3_name: "",
        contact_3_pull: 0,
        contact_4_name: "",
        contact_4_pull: 0,
        contact_5_name: "",
        contact_5_pull: 0,
        contact_6_name: "",
        contact_6_pull: 0
    });

    const [oil, setOil] = useState({
        new_service_date: new Date(),
        interval_for_oil_change_as_km: 0,
        new_km_at_service: 0,
        interval_for_oil_change_as_date: 0,
        serviceAlert: {status: false}
    });

    const [brake, setBrake] = useState({
        new_service_date: new Date(),
        interval_for_brake_change_as_km: 0,
        new_km_at_service: 0,
        interval_for_brake_change_as_date: 0,
        serviceAlert: {status: false}
    });
    
    const [belt, setBelt] = useState({
        new_service_date: new Date(),
        interval_for_belt_change_as_km: 0,
        new_km_at_service: 0,
        interval_for_belt_change_as_date: 0,
        serviceAlert: {status: false}
    });

    const [inspection, setInspection] = useState({
        new_service_date: new Date(),
        new_km_at_service: 0,
        interval_for_vehicle_insp_change_as_date: 0,
        serviceAlert: {status: false}
    });

    const [tachograph, setTachograph] = useState({
        new_service_date: new Date(),
        new_km_at_service: 0,
        interval_for_taxometer_change_as_date: 0,
        serviceAlert: {status: false}
    });

    const [trailer, setTrailer] = useState({
        new_service_date: new Date(),
        new_km_at_service: 0,
        trailer_plate: '',
        interval_for_trailer_change_as_date: 0,
        serviceAlert: {status: false}
    });

    const [ivdc, setIvdc] = useState({
        start_date: "",
        stop_date: "",
        day1_start: "",
        day1_stop: "",
        day2_start: "",
        day2_stop: "",
        day3_start: "",
        day3_stop: "",
        day4_start: "",
        day4_stop: "",
        day5_start: "",
        day5_stop: "",
        day6_start: "",
        day6_stop: "",
        day7_start: "",
        day7_stop: "",
        aktif: 0
    });

    const [hideDevices, setHideDevices] = useState({
        device: {
            hide_device_id: 0
        },
        time: {
            start_date: "",
            stop_date: "",
            day1_start: "",
            day1_stop: "",
            day2_start: "",
            day2_stop: "",
            day3_start: "",
            day3_stop: "",
            day4_start: "",
            day4_stop: "",
            day5_start: "",
            day5_stop: "",
            day6_start: "",
            day6_stop: "",
            day7_start: "",
            day7_stop: "",
            activation: 0
        }
    });
    
    const deviceService = new DeviceService();
    const carService = new CarService();
    const vehicleService = new VehicleService();
    const ivdcDevicesService = new IvdcDevicesService();
    const temperatureService = new TemperatureService();
    const fuelService = new FuelService();
    const hideDevicesService = new HideDevicesService();

    const handleSelectVehicle = (selectedOption) => {
        props.history.push(`/vehicle-service/${selectedOption.value}`);
    };

    const handleSelectTab = (key) => {
        setActiveTab(key);
    };

    const fetchData = async (deviceId) => {
        setIsLoading(true);

        await Promise.all([
            deviceService.getDeviceAllInfo(deviceId),
            carService.getCar(deviceId),
            vehicleService.get(deviceId),
            vehicleService.getHistoryAll(deviceId),
            vehicleService.getAlerts(deviceId),
            ivdcDevicesService.get(deviceId),
            temperatureService.get(deviceId),
            fuelService.get(deviceId),
            hideDevicesService.get(deviceId),
            hideDevicesService.getTime(deviceId)
        ]).then(([
            devicesRespnse, carResponse, vehicleServiceResponse,
            vehicleServiceHistoryResponse, vehicleServiceAlertResponse,
            ivdcResponse, temperatureResponse, fuelResponse, hideDevicesResponse,
            hideDevicesTimeResponse]) => {
                let device = devicesRespnse.data;
                let cars = carResponse.data;
                let vehicleService = vehicleServiceResponse.data;
                let serviceHistory = vehicleServiceHistoryResponse.data;
                let serviceAlert = vehicleServiceAlertResponse.data;
                let ivdc = ivdcResponse.data;
                let hideDevices = hideDevicesResponse.data;
                let hideDevicesTime = hideDevicesTimeResponse.data;

                setBasicSettings({...basicSettings,
                    name: device.name,
                    avg_fuel_consumption: cars.avg_fuel_consumption,
                    total_mileage: (parseFloat(device.latest_mileage) + cars.car_mileage_at_install).toFixed(2),
                    car_mileage_at_install: cars.car_mileage_at_install || 0,
                    total_motor_mins: vehicleService.total_motor_mins || 0,
                    start_motor_mins: vehicleService.start_motor_mins || 0,
                    email_1: cars.email_1,
                    email_2: cars.email_2,
                    email_3: cars.email_3,
                    warning_at_vehicle_service: cars.warning_at_vehicle_service,
                    type: device.type,
                    input_count: device.input_count
                });

                setContacts({...contacts,
                    contact_2_name: cars.contact_2_name,
                    contact_2_pull: cars.contact_2_pull,
                    contact_3_name: cars.contact_3_name,
                    contact_3_pull: cars.contact_3_pull,
                    contact_4_name: cars.contact_4_name,
                    contact_4_pull: cars.contact_4_pull,
                    contact_5_name: cars.contact_5_name,
                    contact_5_pull: cars.contact_5_pull,
                    contact_6_name: cars.contact_6_name,
                    contact_6_pull: cars.contact_6_pull
                });

                setOil({...oil,
                    new_service_date: moment().format("YYYY-MM-DD HH:mm:00"),
                    interval_for_oil_change_as_km: vehicleService.interval_for_oil_change_as_km,
                    new_km_at_service: (parseFloat(device.latest_mileage) + cars.car_mileage_at_install).toFixed(2),
                    interval_for_oil_change_as_date: vehicleService.interval_for_oil_change_as_date,
                    create_history_oil: 0,
                    serviceHistory: serviceHistory.filter((item) => item.service_type === 1),
                    serviceAlert: serviceAlert && serviceAlert.oil
                });

                setBrake({...brake,
                    new_service_date: moment().format("YYYY-MM-DD HH:mm:00"),
                    interval_for_brake_change_as_km: vehicleService.interval_for_brake_change_as_km,
                    new_km_at_service: (parseFloat(device.latest_mileage) + cars.car_mileage_at_install).toFixed(2),
                    interval_for_brake_change_as_date: vehicleService.interval_for_brake_change_as_date,
                    create_history_brake: 0,
                    serviceHistory: serviceHistory.filter((item) => item.service_type === 2),
                    serviceAlert: serviceAlert && serviceAlert.brake
                });
                
                setBelt({...belt,
                    new_service_date: moment().format("YYYY-MM-DD HH:mm:00"),
                    interval_for_belt_change_as_km: vehicleService.interval_for_belt_change_as_km,
                    new_km_at_service: (parseFloat(device.latest_mileage) + cars.car_mileage_at_install).toFixed(2),
                    interval_for_belt_change_as_date: vehicleService.interval_for_belt_change_as_date,
                    create_history_belt: 0,
                    serviceHistory: serviceHistory.filter((item) => item.service_type === 3),
                    serviceAlert: serviceAlert && serviceAlert.belt
                });
                
                setInspection({...inspection,
                    new_service_date: moment().format("YYYY-MM-DD HH:mm:00"),
                    new_km_at_service: (parseFloat(device.latest_mileage) + cars.car_mileage_at_install).toFixed(2),
                    interval_for_vehicle_insp_as_date: vehicleService.interval_for_vehicle_insp_as_date,
                    create_history_insp: 0,
                    serviceHistory: serviceHistory.filter((item) => item.service_type === 4),
                    serviceAlert: serviceAlert && serviceAlert.inspection
                });

                setTachograph({...tachograph,
                    new_service_date: moment().format("YYYY-MM-DD HH:mm:00"),
                    new_km_at_service: (parseFloat(device.latest_mileage) + cars.car_mileage_at_install).toFixed(2),
                    interval_for_taxometer_change_as_date: vehicleService.interval_for_taxometer_change_as_date,
                    create_history_tachograph: 0,
                    serviceHistory: serviceHistory.filter((item) => item.service_type === 5),
                    serviceAlert: serviceAlert && serviceAlert.tachograph
                });

                setTrailer({...trailer,
                    new_service_date: moment().format("YYYY-MM-DD HH:mm:00"),
                    new_km_at_service: (parseFloat(device.latest_mileage) + cars.car_mileage_at_install).toFixed(2),
                    interval_for_trailer_change_as_date: vehicleService.interval_for_trailer_change_as_date,
                    create_history_trailer: 0,
                    trailer_plate:cars.trailer_plate,
                    serviceHistory: serviceHistory.filter((item) => item.service_type === 6),
                    serviceAlert: serviceAlert && serviceAlert.trailer
                });

                setIvdc({
                    start_date: (Date.parse(ivdc.start_date)) ? new Date(ivdc.start_date) : '',
                    stop_date: (Date.parse(ivdc.start_date)) ? new Date(ivdc.stop_date): '',
                    day1_start: timeFormat(ivdc.day1_start),
                    day1_stop: timeFormat(ivdc.day1_stop),
                    day2_start: timeFormat(ivdc.day2_start),
                    day2_stop: timeFormat(ivdc.day2_stop),
                    day3_start: timeFormat(ivdc.day3_start),
                    day3_stop: timeFormat(ivdc.day3_stop),
                    day4_start: timeFormat(ivdc.day4_start),
                    day4_stop: timeFormat(ivdc.day4_stop),
                    day5_start: timeFormat(ivdc.day5_start),
                    day5_stop: timeFormat(ivdc.day5_stop),
                    day6_start: timeFormat(ivdc.day6_start),
                    day6_stop: timeFormat(ivdc.day6_stop),
                    day7_start: timeFormat(ivdc.day7_start),
                    day7_stop: timeFormat(ivdc.day7_stop),
                    aktif: ivdc.aktif
                });

                setHideDevices({
                    device: {
                        hide_device_id: hideDevices.hide_device_id
                    },
                    time: {
                        start_date: (Date.parse(hideDevicesTime.start_date)) ? new Date(hideDevicesTime.start_date) : '',
                        stop_date: (Date.parse(hideDevicesTime.stop_date)) ? new Date(hideDevicesTime.stop_date) : '',
                        day1_start: timeFormat(hideDevicesTime.day1_start),
                        day1_stop: timeFormat(hideDevicesTime.day1_stop),
                        day2_start: timeFormat(hideDevicesTime.day2_start),
                        day2_stop: timeFormat(hideDevicesTime.day2_stop),
                        day3_start: timeFormat(hideDevicesTime.day3_start),
                        day3_stop: timeFormat(hideDevicesTime.day3_stop),
                        day4_start: timeFormat(hideDevicesTime.day4_start),
                        day4_stop: timeFormat(hideDevicesTime.day4_stop),
                        day5_start: timeFormat(hideDevicesTime.day5_start),
                        day5_stop: timeFormat(hideDevicesTime.day5_stop),
                        day6_start: timeFormat(hideDevicesTime.day6_start),
                        day6_stop: timeFormat(hideDevicesTime.day6_stop),
                        day7_start: timeFormat(hideDevicesTime.day7_start),
                        day7_stop: timeFormat(hideDevicesTime.day7_stop),
                        activation: hideDevicesTime.activation
                    }
                });

                setIsLoading(false);
        });        
    };

    const handleSubmitBasicSettings = async (data) => {
        let deviceId = props.match.params.deviceId;

        try {
            await Promise.all([
              deviceService.update(deviceId, {
                  name: data.name,
                  type: data.type
              }),
              carService.saveCar(deviceId, {
                  car_mileage_at_install: data.car_mileage_at_install,
                  email_1: data.email_1,
                  email_2: data.email_2,
                  email_3: data.email_3,
                  warning_at_vehicle_service: data.warning_at_vehicle_service,
                  avg_fuel_consumption: data.avg_fuel_consumption
              }),
              vehicleService.update(deviceId, {
                  start_motor_mins: data.start_motor_mins
              })
            ]);

            setShowMessage({status: true,  title: "İşlem Başarılı", message: "Bilgiler başarıyla kaydedildi."});

        } catch(err) {
            setShowMessage({status: true,  title: "Hata", message: "Hata oluştu."});
        }

    };

    const handleSubmitContacts = async (data) => {
        let deviceId = props.match.params.deviceId;
        
        try {
            await carService.saveCar(deviceId, {...data});
            setShowMessage({status: true,  title: "İşlem Başarılı", message: "Bilgiler başarıyla kaydedildi."});

        } catch(err) {
            setShowMessage({status: true,  title: "Hata", message: "Hata oluştu."});
        }

    };

    const handleDeleteService = (id) => {
        try {
            vehicleService.deleteHistory(id);

        } catch(err) {
            setShowMessage({status: true,  title: "Hata", message: "Hata oluştu."});
        }
    };

    const handleSubmitService = (data) => {
        let deviceId = props.match.params.deviceId;
        let date = moment(data.new_service_date, 'YYYY-MM-DD HH:mm:00').format("YYYY-MM-DD HH:mm:00");
        
        let insertData = {
            service_date: date,
            km_at_service: data.new_km_at_service,
            service_type: data.service_type
        };

        let updateData = {};
        if (data.service_type === 1) {
            updateData = {
                interval_for_oil_change_as_km: data.interval_for_oil_change_as_km,
                interval_for_oil_change_as_date: data.interval_for_oil_change_as_date
            };
        }
        else if (data.service_type === 2) {
            updateData = {
                interval_for_brake_change_as_km: data.interval_for_brake_change_as_km,
                interval_for_brake_change_as_date: data.interval_for_brake_change_as_date
            };
        }
        else if (data.service_type === 3) {
            updateData = {
                interval_for_belt_change_as_km: data.interval_for_belt_change_as_km,
                interval_for_belt_change_as_date: data.interval_for_belt_change_as_date
            };
        }
        else if (data.service_type === 4) {
            updateData = {
                interval_for_vehicle_insp_as_date: data.interval_for_vehicle_insp_as_date
            };
        }
        else if (data.service_type === 5) {
            updateData = {
                interval_for_taxometer_change_as_date: data.interval_for_taxometer_change_as_date
            };
        }
        else if (data.service_type === 6) {
            updateData = {
                interval_for_trailer_change_as_date: data.interval_for_trailer_change_as_date,
                trailer_plate: data.trailer_plate
            };
        }
        
        try {
            vehicleService.update(deviceId, updateData).then(() => {
                if (data.create_history_oil === 1 ||
                    data.create_history_brake === 1 ||
                    data.create_history_belt === 1 ||
                    data.create_history_insp === 1 ||
                    data.create_history_tachograph === 1 ||
                    data.create_history_trailer === 1 ) {

                    vehicleService.insertHistory(deviceId, insertData).then(() => {
                        vehicleService.getHistoryAll(deviceId).then((response) => {
                            setOil({...data, 
                                serviceHistory: response.data.filter((item) => item.service_type === 1),
                                create_history_oil: 0
                            });
    
                            setBrake({...data, 
                                serviceHistory: response.data.filter((item) => item.service_type === 2),
                                create_history_brake: 0
                            });
                            
                            setBelt({...data, 
                                serviceHistory: response.data.filter((item) => item.service_type === 3),
                                create_history_belt: 0
                            });

                            setInspection({...data, 
                                serviceHistory: response.data.filter((item) => item.service_type === 4),
                                create_history_insp: 0
                            });

                            setTachograph({...data, 
                                serviceHistory: response.data.filter((item) => item.service_type === 5),
                                create_history_tachograph: 0
                            });

                            setTrailer({...data, 
                                serviceHistory: response.data.filter((item) => item.service_type === 6),
                                create_history_trailer: 0
                            });
                        });
                    });
                }
            });

            setShowMessage({status: true,  title: "İşlem Başarılı", message: "Bilgiler başarıyla kaydedildi."});

        } catch(err) {
            setShowMessage({status: true,  title: "Hata", message: "Hata oluştu."});
        }

    };

    const handleSubmitIvdc = async (data) => {
        let deviceId = props.match.params.deviceId;

        let updateData = {
            device_id: deviceId,
            start_date: data.start_date ? moment(data.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
            stop_date: data.stop_date ? moment(data.stop_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
            day1_start: data.day1_start ? moment(data.day1_start, 'HH:mm:00').format('HH:mm:00') : null,
            day1_stop: data.day1_stop ? moment(data.day1_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day2_start: data.day2_start ? moment(data.day2_start, 'HH:mm:00').format('HH:mm:00') : null,
            day2_stop: data.day2_stop ? moment(data.day2_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day3_start: data.day3_start ? moment(data.day3_start, 'HH:mm:00').format('HH:mm:00') : null,
            day3_stop: data.day3_stop ? moment(data.day3_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day4_start: data.day4_start ? moment(data.day4_start, 'HH:mm:00').format('HH:mm:00') : null,
            day4_stop: data.day4_stop ? moment(data.day4_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day5_start: data.day5_start ? moment(data.day5_start, 'HH:mm:00').format('HH:mm:00') : null,
            day5_stop: data.day5_stop ? moment(data.day5_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day6_start: data.day6_start ? moment(data.day6_start, 'HH:mm:00').format('HH:mm:00') : null,
            day6_stop: data.day6_stop ? moment(data.day6_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day7_start: data.day7_start ? moment(data.day7_start, 'HH:mm:00').format('HH:mm:00') : null,
            day7_stop: data.day7_stop ? moment(data.day7_stop, 'HH:mm:00').format('HH:mm:00') : null,
            aktif: data.aktif
        };

        try {
            await ivdcDevicesService.update(deviceId, updateData)
              .then(async () => {
                  await ivdcDevicesService.get(deviceId)
                    .then((response) => {
                        let ivdc = response.data;

                        setIvdc({
                            start_date: (Date.parse(ivdc.start_date)) ? new Date(ivdc.start_date) : '',
                            stop_date: (Date.parse(ivdc.start_date)) ? new Date(ivdc.stop_date): '',
                            day1_start: timeFormat(ivdc.day1_start),
                            day1_stop: timeFormat(ivdc.day1_stop),
                            day2_start: timeFormat(ivdc.day2_start),
                            day2_stop: timeFormat(ivdc.day2_stop),
                            day3_start: timeFormat(ivdc.day3_start),
                            day3_stop: timeFormat(ivdc.day3_stop),
                            day4_start: timeFormat(ivdc.day4_start),
                            day4_stop: timeFormat(ivdc.day4_stop),
                            day5_start: timeFormat(ivdc.day5_start),
                            day5_stop: timeFormat(ivdc.day5_stop),
                            day6_start: timeFormat(ivdc.day6_start),
                            day6_stop: timeFormat(ivdc.day6_stop),
                            day7_start: timeFormat(ivdc.day7_start),
                            day7_stop: timeFormat(ivdc.day7_stop),
                            aktif: ivdc.aktif
                        });

                        setShowMessage({status: true, title: "İşlem Başarılı", message: "Bilgiler başarıyla kaydedildi."});
                    })
              });

        } catch(err) {
            setShowMessage({status: true, title: "Hata", message: "Hata oluştu."});
        }

    };

    const handleSubmitHideDevices = async (data) => {
        let deviceId = props.match.params.deviceId;

        let updateTime = {
            start_date: data.time.start_date ? moment(data.time.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
            stop_date: data.time.stop_date ? moment(data.time.stop_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
            day1_start: data.time.day1_start ? moment(data.time.day1_start, 'HH:mm:00').format('HH:mm:00') : null,
            day1_stop: data.time.day1_stop ? moment(data.time.day1_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day2_start: data.time.day2_start ? moment(data.time.day2_start, 'HH:mm:00').format('HH:mm:00') : null,
            day2_stop: data.time.day2_stop ? moment(data.time.day2_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day3_start: data.time.day3_start ? moment(data.time.day3_start, 'HH:mm:00').format('HH:mm:00') : null,
            day3_stop: data.time.day3_stop ? moment(data.time.day3_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day4_start: data.time.day4_start ? moment(data.time.day4_start, 'HH:mm:00').format('HH:mm:00') : null,
            day4_stop: data.time.day4_stop ? moment(data.time.day4_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day5_start: data.time.day5_start ? moment(data.time.day5_start, 'HH:mm:00').format('HH:mm:00') : null,
            day5_stop: data.time.day5_stop ? moment(data.time.day5_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day6_start: data.time.day6_start ? moment(data.time.day6_start, 'HH:mm:00').format('HH:mm:00') : null,
            day6_stop: data.time.day6_stop ? moment(data.time.day6_stop, 'HH:mm:00').format('HH:mm:00') : null,
            day7_start: data.time.day7_start ? moment(data.time.day7_start, 'HH:mm:00').format('HH:mm:00') : null,
            day7_stop: data.time.day7_stop ? moment(data.time.day7_stop, 'HH:mm:00').format('HH:mm:00') : null,
            activation: data.time.activation
        };

        try {
            await hideDevicesService.update(deviceId, data.time.activation)
              .then(async (response) => {
                  // update time if status updated
                  if (response.data.result === "success") {
                      await hideDevicesService.updateTime(deviceId, updateTime)
                        .then(async (response) => {
                            await Promise.all([hideDevicesService.get(deviceId), hideDevicesService.getTime(deviceId)])
                              .then(([hideDevicesResponse, hideDevicesTimeResponse]) => {
                                  let hideDevices = hideDevicesResponse.data;
                                  let hideDevicesTime = hideDevicesTimeResponse.data;

                                  setHideDevices({
                                      device: {
                                          hide_device_id: hideDevices.hide_device_id
                                      },
                                      time: {
                                          start_date: (Date.parse(hideDevicesTime.start_date)) ? new Date(hideDevicesTime.start_date) : '',
                                          stop_date: (Date.parse(hideDevicesTime.stop_date)) ? new Date(hideDevicesTime.stop_date) : '',
                                          day1_start: timeFormat(hideDevicesTime.day1_start),
                                          day1_stop: timeFormat(hideDevicesTime.day1_stop),
                                          day2_start: timeFormat(hideDevicesTime.day2_start),
                                          day2_stop: timeFormat(hideDevicesTime.day2_stop),
                                          day3_start: timeFormat(hideDevicesTime.day3_start),
                                          day3_stop: timeFormat(hideDevicesTime.day3_stop),
                                          day4_start: timeFormat(hideDevicesTime.day4_start),
                                          day4_stop: timeFormat(hideDevicesTime.day4_stop),
                                          day5_start: timeFormat(hideDevicesTime.day5_start),
                                          day5_stop: timeFormat(hideDevicesTime.day5_stop),
                                          day6_start: timeFormat(hideDevicesTime.day6_start),
                                          day6_stop: timeFormat(hideDevicesTime.day6_stop),
                                          day7_start: timeFormat(hideDevicesTime.day7_start),
                                          day7_stop: timeFormat(hideDevicesTime.day7_stop),
                                          activation: hideDevicesTime.activation
                                      }
                                  });

                                  setShowMessage({status: true,  title: "İşlem Başarılı", message: "Bilgiler başarıyla kaydedildi."});

                              });
                        });
                  }
              });

        } catch(err) {
            setShowMessage({status: true, title: "Hata", message: "Hata oluştu."});
        }

    };

    // string time formatting
    const timeFormat = (timeString) => {
        let HH_mm_ss = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/.test(timeString);
        if (HH_mm_ss) return toShow(timeString, 5);
        return '';
    };

    useEffect(() => {

        const fetchDevices = async () => {
            await deviceService.getDevices(false)
              .then((response) => setDeviceList(response.data));
        };

        fetchDevices();

    }, []);

    useEffect(() => {

        if (props.match.params.deviceId) {
            let selected = deviceList.find(element => parseInt(element.device_id) === parseInt(props.match.params.deviceId));
            
            if (selected) {
                fetchData(selected.device_id);
                setSelectedVehicle({label: selected.name, value: selected.device_id});
            }
        }
        else {
            setShowMessage({status: true, title: "Uyarı", message: "Lütfen bir araç seçin."});
        }

    }, [deviceList, props.match.params]);

    return (
        <>
            <MessageWindow
                show={showMessage.status}
                title={showMessage.title}
                onHide={() => setShowMessage({...showMessage, status: false})}
                message={showMessage.message}
                size="sm" />

            <h4>Araç Servisi</h4>
            <Row>
                <Col sm={3}>
                    <Select
                        value={selectedVehicle}
                        onChange={handleSelectVehicle}
                        placeholder={<FormattedMessage id="GENERAL.PICK_DEVICE"/>}
                        options={deviceList.map((device) => {
                            return {value: device.device_id, label: device.name}
                        })}
                    />
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col sm={12}>
                    <Tabs
                        activeKey={activeTab}
                        onSelect={handleSelectTab}>
                        <Tab eventKey="basic-settings"
                            title={<IconTextWithImg
                                    newLine
                                    size={[50, 50]}
                                    icon={require('../Assets/images/main.png').default}
                                    text={<FormattedMessage id="SERVICE.BASIC_SETTINGS"/>} />}>
                            <Loader
                                loading={isLoading}
                                data={basicSettings}
                                onSubmit={handleSubmitBasicSettings}
                                component={BasicSettings} />
                        </Tab>
                        {
                            basicSettings.input_count > 0 && 
                            (
                                <Tab eventKey="contacts" title={<IconTextWithImg
                                    newLine
                                    size={[50, 50]}
                                    icon={require('../Assets/images/inputs.png').default}
                                    text={<FormattedMessage id="SERVICE.IGNITIONS"/>}/>}>
                                    <Loader
                                        loading={isLoading}
                                        data={contacts}
                                        onSubmit={handleSubmitContacts}
                                        component={Contacts} />
                                </Tab>
                            )
                        }
                        <Tab
                            tabClassName={oil.serviceAlert && oil.serviceAlert.status ? "service-alert": ""}
                            eventKey="oil-service"
                            title={<IconTextWithImg
                                newLine
                                size={[50, 50]}
                                icon={require('../Assets/images/oil.png').default}
                                text={<FormattedMessage id="SERVICE.OIL_SERVICE"/>} />}>
                            <Loader
                                loading={isLoading}
                                data={oil}
                                onSubmit={handleSubmitService}
                                onDelete={handleDeleteService}
                                component={OilService} />
                        </Tab>
                        <Tab
                            tabClassName={brake.serviceAlert && brake.serviceAlert.status ? "service-alert": ""}
                            eventKey="break-service"
                            title={<IconTextWithImg
                                newLine
                                size={[50, 50]}
                                icon={require('../Assets/images/brake.png').default}
                                text={<FormattedMessage id="SERVICE.BRAKE_SERVICE"/>} />}>
                            <Loader
                                loading={isLoading}
                                data={brake}
                                onSubmit={handleSubmitService}
                                onDelete={handleDeleteService}
                                component={BrakeService} />
                        </Tab>
                        <Tab
                            tabClassName={belt.serviceAlert && belt.serviceAlert.status ? "service-alert": ""}
                            eventKey="timing-belt-service"
                            title={<IconTextWithImg
                                newLine
                                size={[50, 50]}
                                icon={require('../Assets/images/belt.png').default}
                                text={<FormattedMessage id="SERVICE.BELT_SERVICE"/>} />}>
                            <Loader
                                loading={isLoading}
                                data={belt}
                                onSubmit={handleSubmitService}
                                onDelete={handleDeleteService}
                                component={BeltService} />
                        </Tab>
                        <Tab
                            tabClassName={inspection.serviceAlert && inspection.serviceAlert.status ? "service-alert": ""}
                            eventKey="inspection-service"
                            title={<IconTextWithImg
                                newLine
                                size={[50, 50]}
                                icon={require('../Assets/images/inspection.png').default}
                                text={<FormattedMessage id="SERVICE.INSPECTION_SERVICE"/>} />}>
                            <Loader
                                loading={isLoading}
                                data={inspection}
                                onSubmit={handleSubmitService}
                                onDelete={handleDeleteService}
                                component={InspectionService} />
                        </Tab>
                        <Tab
                            tabClassName={tachograph.serviceAlert && tachograph.serviceAlert.status ? "service-alert": ""}
                            eventKey="tachograph-service"
                            title={<IconTextWithImg
                                newLine
                                size={[50, 50]}
                                icon={require('../Assets/images/tachograph.png').default}
                                text={<FormattedMessage id="SERVICE.TACHOGRAPH_SERVICE"/>} />}>
                            <Loader
                                loading={isLoading}
                                data={tachograph}
                                onSubmit={handleSubmitService}
                                onDelete={handleDeleteService}
                                component={TachographService} />
                        </Tab>
                        <Tab
                            tabClassName={trailer.serviceAlert && trailer.serviceAlert.status ? "service-alert": ""}
                            eventKey="trailer-service"
                            title={<IconTextWithImg
                                newLine
                                size={[50, 50]}
                                icon={require('../Assets/images/trailer.png').default}
                                text={<FormattedMessage id="SERVICE.TRAILER_SERVICE"/>} />}>
                            <Loader
                                loading={isLoading}
                                data={trailer}
                                onSubmit={handleSubmitService}
                                onDelete={handleDeleteService}
                                component={TrailerService} />
                        </Tab>
                        <Tab
                            eventKey="vehicle-hidding"
                            title={<IconTextWithImg
                                newLine
                                size={[50, 50]}
                                icon={require('../Assets/images/hide-device.png').default}
                                text={<FormattedMessage id="SERVICE.HIDE_SERVICE"/>}  />}>
                            <Loader
                              loading={isLoading}
                              data={ivdc}
                              onSubmit={handleSubmitIvdc}
                              component={IvdcService} />
                        </Tab>
                        <Tab
                            eventKey="private-driving"
                            title={<IconTextWithImg
                                newLine
                                size={[50, 50]}
                                icon={require('../Assets/images/dataon.png').default}
                                text={<FormattedMessage id="SERVICE.HIDE_SERVICE_DATA"/>} />}>
                            <Loader
                              loading={isLoading}
                              data={hideDevices}
                              onSubmit={handleSubmitHideDevices}
                              component={HideDeviceService} />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col>
                    <h3>Araç Servisi Hakkında</h3>
                    <b>Yağ Servisi:</b> Yağ Servis ikonunu tıklayınız. Açılan sayfada anlık kilometre yazan kısım şu anki cihaz kilometrenizdir.
                    Aracınızın kaç kilometre sonra uyarı vermesini istiyorsanız anlık kilometre üzerine o kilometreyi ekleyip kilometre kısmına bu rakamları yazmanız, sol kısımdaki <b>Yeni Servis Kaydı Oluştur</b> kısmını işaretleyip, <b>Kaydet</b> butonuna basmanız gereklidir.
                    Uyarılar bu ekranda <b>(kırmızı renkte)</b> görüntülenebileceği gibi servis mailine yazdığınız mail adresine de iletilecektir.
                    Örnek olarak anlık kilometreniz 12500 ve servis uyarısının 1000 km sonra vermesini istiyorsanız kilometre yazan kısma 13500 (12500+1000=13500) yazmanız ve yukarıda belirtilen şekilde prosedürü tamamlamanız yerinde olacaktır.<br /><br />

                    <b>Fren Servisi:</b> Fren Servis ikonuna tıklayınız.Açılan sayfada anlık kilometre yazan kısım şu anki cihaz kilometrenizdir.
                    Aracınızın kaç kilometre sonra  fren servis uyarısı vermesini istiyorsanız anlık kilometre üzerine o kilometreyi ekleyip kilometre kısmına bu rakamları yazmanız, sol kısımdaki <b>Yeni Servis Kaydı Oluştur</b> kısmını işaretleyip, <b>Kaydet</b> butonuna basmanız gereklidir.
                    Uyarılar bu ekranda <b>(kırmızı renkte)</b> görüntülenebileceği gibi servis mailine yazdığınız mail adresine de iletilecektir.
                    Örnek olarak anlık kilometreniz 5000 ve servis uyarısının 1000 km sonra vermesini istiyorsanız kilometre yazan kısma 6000 (5000+1000=6000) yazmanız ve yukarıda belirtilen şekilde prosedürü tamamlamanız yerinde olacaktır.<br /><br />

                    <b>Triger Kayışı Servisi:</b> Triger Kayışı Servisi ikonuna tıklayınız.Açılan sayfada anlık kilometre yazan kısım şu anki cihaz kilometrenizdir.
                    Aracınızın kaç kilometre sonra  triger kayışı servisi uyarısı vermesini istiyorsanız anlık kilometre üzerine o kilometreyi ekleyip kilometre kısmına bu rakamları yazmanız, sol kısımdaki <b>Yeni Servis Kaydı Oluştur</b> kısmını işaretleyip, <b>Kaydet</b> butonuna basmanız gereklidir.
                    Uyarılar bu ekranda <b>(kırmızı renkte)</b> görüntülenebileceği gibi servis mailine yazdığınız mail adresine de iletilecektir.
                    Örnek olarak anlık kilometreniz 500 ve servis uyarısının 100000 km sonra vermesini istiyorsanız kilometre yazan kısma 100500 (500+100000=100500) yazmanız ve yukarıda belirtilen şekilde prosedürü tamamlamanız yerinde olacaktır.<br /><br />

                    <b>Muayene/Emisyon:</b> Muayene/Emisyon ikonuna tıklayınız. Açılan sayfada Servis Sıklığı kısmına kaç ayda bir uyarı almak istiyorsanız (6 ay-12 ay) rakamla yazınız, sol kısımdaki <b>Yeni Servis Kaydı Oluştur</b> kısmını işaretleyip, <b>Kaydet</b> butonuna basınız.
                    Böylelikle belirttiğiniz süre geldiğinde uyarılar bu ekranda <b>(kırmızı renkte)</b> görüntülenebileceği gibi servis mailine yazdığınız mail adresine de iletilecektir.<br /><br />

                    <b>Takograf Servisi:</b> Takograf Servisi ikonuna tıklayınız. Açılan sayfada Servis Sıklığı kısmına kaç ayda bir uyarı almak istiyorsanız (6 ay-12 ay) rakamla yazınız, sol kısımdaki <b>Yeni Servis Kaydı Oluştur</b> kısmını işaretleyip, <b>Kaydet</b> butonuna basınız.
                    Böylelikle belirttiğiniz süre geldiğinde uyarılar bu ekranda <b>(kırmızı renkte)</b> görüntülenebileceği gibi servis mailine yazdığınız mail adresine de iletilecektir.<br /><br />

                    <b>Römork Servisi:</b> Römork Servisi ikonuna tıklayınız.Açılan sayfada Servis Sıklığı kısmına kaç ayda bir uyarı almak istiyorsanız (6 ay-12 ay) rakamla yazınız, sol kısımdaki <b>Yeni Servis Kaydı Oluştur</b> kısmını işaretleyip, <b>Kaydet</b> butonuna basınız.
                    Böylelikle belirttiğiniz süre geldiğinde uyarılar bu ekranda <b>(kırmızı renkte)</b> görüntülenebileceği gibi servis mailine yazdığınız mail adresine de iletilecektir.<br /><br />

                </Col>
            </Row>
        </>
    );
}

export default VehicleServices;