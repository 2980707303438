import React from 'react';


function calculateBattery(device_type, voltage_value) {

    var return_value = {
        bar_value: 0,
        title: ''
    };

    if (device_type === 'GL200') {

        if (voltage_value >= 4.0) {
            return_value.bar_value = 6;

        } else if (voltage_value >= 3.9) {
            return_value.bar_value = 5;

        } else if (voltage_value >= 3.8) {
            return_value.bar_value = 4;

        } else if (voltage_value >= 3.7) {
            return_value.bar_value = 3;

        } else if (voltage_value >= 3.6) {
            return_value.bar_value = 2;

        } else if (voltage_value >= 3.5) {
            return_value.bar_value = 1;

        }
        else if (voltage_value <= 3.5) {
            return_value.bar_value = 0;
            return_value.title = 'Akku Leer';
        }

    } else if (device_type === 'GL505') {

        if (voltage_value >= 3.7) {
            return_value.bar_value = 6;

        } else if (voltage_value >= 2.8) {
            return_value.bar_value = 5;

        } else if (voltage_value >= 2.4) {
            return_value.bar_value = 4;

        } else if (voltage_value >= 1.7) {
            return_value.bar_value = 3;

        } else if (voltage_value >= 1.5) {
            return_value.bar_value = 2;

        } else if (voltage_value >= 0.8) {
            return_value.bar_value = 1;

        } else if (voltage_value < 0.74) {
            return_value.bar_value = 0;
            return_value.title = 'Akku Leer';
        }

    } else if (device_type == 'GL300') {

        if (voltage_value >= 4.0) {
            return_value.bar_value = 6;

        } else if (voltage_value >= 3.9) {
            return_value.bar_value = 5;

        } else if (voltage_value >= 3.8) {
            return_value.bar_value = 4;

        } else if (voltage_value >= 3.7) {
            return_value.bar_value = 3;

        } else if (voltage_value >= 3.6) {
            return_value.bar_value = 2;

        } else if (voltage_value >= 3.5) {
            return_value.bar_value = 1;

        }
        else if (voltage_value <= 3.5) {
            return_value.bar_value = 0;
            return_value.title = 'Akku Leer';
        }

    } else if (device_type === 'TMT250') {

        if (voltage_value > 4.2) {
            return_value.bar_value = 6;
            return_value.title = '100%';

        } else if (voltage_value >= 4.0) {
            return_value.bar_value = 5;
            return_value.title = '80%';

        } else if (voltage_value >= 3.9) {
            return_value.bar_value = 4;
            return_value.title = '60%';

        } else if (voltage_value >= 3.8) {
            return_value.bar_value = 3;
            return_value.title = '50%';

        } else if (voltage_value >= 3.7) {
            return_value.bar_value = 2;
            return_value.title = '40%';

        } else if (voltage_value >= 3.5) {
            return_value.bar_value = 1;
            return_value.title = '20%';

        }
        else if (voltage_value <= 3.4) {
            return_value.bar_value = 0;
            return_value.title = 'Akku Leer';
        }

    } else {

        if (voltage_value >= 4.2) {
            return_value.bar_value = 6;

        } else if (voltage_value >= 4.1) {
            return_value.bar_value = 5;

        } else if (voltage_value >= 4.0) {
            return_value.bar_value = 4;

        } else if (voltage_value >= 3.9) {
            return_value.bar_value = 3;

        } else if (voltage_value >= 3.8) {
            return_value.bar_value = 2;
        }
        else if (voltage_value <= 3.7) {
            return_value.bar_value = 0;
            return_value.title = 'Akku Leer';
        }
    }

    return return_value;
};

function BatteryStatus(props) {
  const type = props.type;
  const voltage_data = props.voltage_data;
  const list = props.list;
  const battery_values = calculateBattery(type,voltage_data);

  return (
    <div>
        {/* <a> {battery_values.title} </a> */}
        <div style = {{textAlign:'right', marginRight:5}} > <img src={require('../Assets/images/battery/' + list + '_bbar_' + battery_values.bar_value + '.png').default} alt="" style={{transform: `rotate(${-90}deg)`}}/> </div>
    </div>
  );
}

export default BatteryStatus;
