import Overlay from 'ol/Overlay';
import {getArea, getLength} from 'ol/sphere';
import Draw from 'ol/interaction/Draw';
import {LineString, Polygon} from 'ol/geom';
import {unByKey} from 'ol/Observable';
import {Control} from 'ol/control';
import {Style, Fill, Stroke, Circle as CircleStyle} from 'ol/style';

var MeasureControl = (function (Control) {
    function MeasureControl(vectorSource, opt_options) {
      var options = opt_options || {};
      this.vectorSource = vectorSource;
      this.drawStartListener = null;
      this.drawEndListener = null;
      this.geometryListener = null;
      this.active = false;
      this.draw = null;
      this.sketch = null;
      this.measureTooltipElement = null;
      this.measureTooltip = null;
  
      this.button = document.createElement('button');
      this.button.innerHTML = 'M';
  
      var element = document.createElement('div');
      element.className = 'measure-button ol-unselectable ol-control';
      element.appendChild(this.button);
  
      Control.call(this, {
        element: element,
        target: options.target
      });
  
      this.button.addEventListener('click', this.handleMeasure.bind(this), false);
    }
  
    if ( Control ) MeasureControl.__proto__ = Control;
    MeasureControl.prototype = Object.create( Control && Control.prototype );
    MeasureControl.prototype.constructor = MeasureControl;
    MeasureControl.prototype._formatLength = function _formatLength(line) {
      var length = getLength(line);
      var output;
      if (length > 100) {
        output = (Math.round(length / 1000 * 100) / 100) +
            ' ' + 'km';
      } else {
        output = (Math.round(length * 100) / 100) +
            ' ' + 'm';
      }
      return output;
    };
  
    MeasureControl.prototype._formatArea = function _formatArea(polygon) {
      var area = getArea(polygon);
      var output;
      if (area > 10000) {
        output = (Math.round(area / 1000000 * 100) / 100) +
            ' ' + 'km<sup>2</sup>';
      } else {
        output = (Math.round(area * 100) / 100) +
            ' ' + 'm<sup>2</sup>';
      }
      return output;
    };
  
    MeasureControl.prototype._createMeasureTooltip = function _createMeasureTooltip() {
        if (this.measureTooltipElement) {
          this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
        }
        this.measureTooltipElement = document.createElement('div');
        this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
        this.measureTooltip = new Overlay({
          element: this.measureTooltipElement,
          offset: [0, -15],
          positioning: 'bottom-center'
        });
        this.getMap().addOverlay(this.measureTooltip);
    };
  
    MeasureControl.prototype.handleMeasure = function handleMeasure () {
      // this.getMap().getView().setRotation(180);
      this.active = !this.active;
      
      if (this.active) {
        this.button.classList.add('active');
  
        let type = "LineString";
        this.draw = new Draw({
          source: this.vectorSource,
          type: type,
          style: new Style({
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.5)',
              lineDash: [10, 10],
              width: 2
            }),
            image: new CircleStyle({
              radius: 5,
              stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.7)'
              }),
              fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)'
              })
            })
          })
        });
        this.getMap().addInteraction(this.draw);
  
  
        this._createMeasureTooltip();
  
        this.drawStartListener = this.draw.on('drawstart',
          evt => {
            // set sketch
            this.sketch = evt.feature;
  
            var tooltipCoord = evt.coordinate;
  
            this.geometryListener = this.sketch.getGeometry().on('change', evt => {
              var geom = evt.target;
              var output;
              if (geom instanceof Polygon) {
                output = this._formatArea(geom);
                tooltipCoord = geom.getInteriorPoint().getCoordinates();
              } else if (geom instanceof LineString) {
                output = this._formatLength(geom);
                tooltipCoord = geom.getLastCoordinate();
              }
              this.measureTooltipElement.innerHTML = output;
              this.measureTooltip.setPosition(tooltipCoord);
            });
        });
  
        this.drawEndListener = this.draw.on('drawend',
          () => {
            this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
            this.measureTooltip.setOffset([0, -7]);
            // unset sketch
            this.sketch = null;
            // unset tooltip so that a new one can be created
            this.measureTooltipElement = null;
            this._createMeasureTooltip();
            unByKey(this.geometryListener);
          });
  
        this.getMap().removeInteraction(this.draw);
        this.getMap().addInteraction(this.draw);
      }
      else {
        this.button.classList.remove('active');
        unByKey(this.drawStartListener);
        unByKey(this.drawEndListener);
        unByKey(this.geometryListener);
        this.getMap().removeInteraction(this.draw);
        this.draw = null;
      }
    };
  
    return MeasureControl;
  }(Control));

  export default MeasureControl;