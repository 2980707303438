import React, {useState, useEffect, useRef} from 'react';
import {Col, Row, Form, Button} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

function Step3(props) {

    const [disabled, setDisabled] = useState(true);
    const email_inputs = useRef([]);

    const [emails, setEmails] = useState({
        "email_1": "",
        "email_2": "",
        "email_3": "",
        "saveAll": 0
    });

    const handleChangeEmail = event => {
        setEmails({...emails, [event.target.id]: event.target.value});
    };

    const handleClickSubmit = () => {
        let error = false;

        email_inputs.current.forEach(element => {
            if (element.checkValidity() === false) {
                error = true;
                return;
            }
        });

        if (error) {
            props.onSubmit(false);
        } else {
            props.onSubmit(emails);
        }
    };

    useEffect(() => {
        if (props.deviceId) {
            setEmails({...emails, ...props.data});
            setDisabled(false);
        }
    }, [props.data]);

    return (
        <>            
            <div className="theft-title"><FormattedMessage id="THEFT.STEP3"/></div>
            <Row style={{marginTop: 5, paddingTop: 5}}>
                <Col xs={12}>
                    <input
                        id="email_1"
                        ref={el => email_inputs.current[0] = el}
                        value={emails.email_1}
                        placeholder="E-Posta 1"
                        style={{width: '100%'}}
                        disabled={disabled}
                        onChange={handleChangeEmail}
                        type="email" />
                </Col>
            </Row>
            <Row style={{marginTop: 5, paddingTop: 5}}>
                <Col xs={12}>
                    <input
                        id="email_2"
                        ref={el => email_inputs.current[1] = el}
                        value={emails.email_2}
                        placeholder="E-Posta 2"
                        style={{width: '100%'}}
                        disabled={disabled}
                        onChange={handleChangeEmail}
                        type="email" />
                </Col>
            </Row>
            <Row style={{marginTop: 5, paddingTop: 5}}>
                <Col xs={12}>
                    <input
                        id="email_3"
                        ref={el => email_inputs.current[2] = el}
                        value={emails.email_3}
                        placeholder="E-Posta 3"
                        style={{width: '100%'}}
                        disabled={disabled}
                        onChange={handleChangeEmail}
                        type="email" />
                </Col>
            </Row>
            <Row style={{marginTop: 5, paddingTop: 5}}>
                <Col xs={12}>
                    <Form.Check
                        type="switch"
                        id="saveAll_step3"
                        onChange={(event) => setEmails({...emails, saveAll:event.target.checked ? 1:0})}
                        checked={Boolean(emails.saveAll)}
                        disabled={disabled}
                        label={<FormattedMessage id="THEFT.SAVEALL"/>}/><br />
                    <Button onClick={handleClickSubmit}
                        disabled={disabled}
                        block variant="success"><FormattedMessage id="GENERAL.SAVE"/></Button>
                </Col>
            </Row>
        </>
    )
};

export default Step3;