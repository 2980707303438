import "react-datepicker/dist/react-datepicker.css";
import './datefilter.css';

import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import moment from 'moment';
import IconTextWithFa from './iconTextWithFa';

function DateFilter(props) {

  const [selectedDate, setSelectedDate] = useState((props.selectedDate) ? (props.selectedDate) : new Date());

  const handleChange = date => {
    setSelectedDate(date);
    props.onChange(date);
  };

  const movePrevDay = evt => {
    evt.preventDefault();
    let prevDate = moment(selectedDate).subtract(1, 'day');
    let date = new Date(prevDate.toISOString());
    handleChange(date);
  };

  const moveNextDay = evt => {
    evt.preventDefault();
    let nextDate = moment(selectedDate).add(1, 'day');
    let date = new Date(nextDate.toISOString());
    if (date <= new Date()) {
      handleChange(date);
    }
  };

  return (
    <div className='date-filter-container'>
      { props.arrows === true &&
        <a style={{color: '#333'}} href="#" onClick={movePrevDay}><IconTextWithFa icon="arrow-alt-circle-left"/></a>
      }
      <DatePicker
        todayButton="Today"
        popperPlacement="bottom-start"
        maxDate={new Date()}
        dateFormat={props.dateFormat}
        timeFormat="HH:mm"
        timeIntervals={props.timeIntervals}
        showTimeSelect={props.showTime}
        selected={selectedDate}
        onChange={handleChange}
        wrapperClassName={props.wrapperClassName}
      />
      { props.arrows === true &&
        (
          <>
            &nbsp;
            <a style={{color: '#333'}} href="#" onClick={moveNextDay}><IconTextWithFa icon="arrow-alt-circle-right"/></a>
          </>
        )
      }
    </div>
  );
}

DateFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date)
};

DateFilter.defaultProps = {
  showTime: false,
  arrows: true,
  selectedDate: new Date(),
  wrapperClassName: "",
  dateFormat: "dd-MM-yyyy",
  timeIntervals: 5
};

export default DateFilter;