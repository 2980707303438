import React, { useEffect, useState } from 'react';
import HomeNav from './nav';
import ColorRouteMap from '../Map/colorRoute';
import DateFilter from '../Commons/dateFilter';
import HourChart from '../HourChart';
import moment from 'moment';

import SignalService from '../../services/Signals';
import Reports from '../../services/Reports';
  
function ColorRoute(props) {
  
  const deviceId = props.match.params.deviceId;
  const signalService = new SignalService();
  const reportService = new Reports();

  const [signals, setSignals] = useState([]);
  const [device, setDevice] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [size, setSize] = useState([window.innerWidth - 530, 50]);
  const [hours, setHours] = useState([]);
  const [filterDate, setFilterDate] = useState("");

  const handleChangeDate = date => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();
    
    setSelectedDate(date);
    setFilterDate(`${year}-${month}-${day}`);
    setHours([]);
  };

  const handleClickHour = (item) => {
    let startDate = moment(item.dates.startDate).format("YYYY-MM-DD H:mm:ss");
    let endDate = moment(item.dates.endDate).format("YYYY-MM-DD H:mm:ss");
    signalService.getSignalsDateRange(deviceId, startDate, endDate)
    .then((response) => {
      setSignals(response.data[0].signals);
    });
  };

  const updateSize = () => {
    setSize([window.innerWidth - 530, 50]);
  };

  const fetchData = async () => {
    try {

      await Promise.all([
        signalService.getSignals(deviceId, filterDate),
        reportService.getDeviceDailyTrips(deviceId, filterDate + " 00:00:00", filterDate + " 23:59:59"),
      ]).then(([signalsResponse, tripsResponse]) => {

        setSignals(signalsResponse.data[0].signals);
        let device = {type: signalsResponse.data[0].type, 
          name: signalsResponse.data[0].name, 
          device_id: signalsResponse.data[0].device_id,
          address: ""
        };
        setDevice(device);

        tripsResponse.data.forEach((item) => {
          setHours(hours => [...hours, {startDate: new Date(item.start_time),
            endDate: new Date(item.end_time),
            selectedDate: new Date(filterDate)}]);
        });

      });

      

    } catch (error) {
    }
  };

  useEffect(() => {
    
    if (filterDate !== "") {
      fetchData();
    }

    window.addEventListener('resize', updateSize);
    
    handleChangeDate(selectedDate);
    
    return () => {
      signalService.getCancelToken().cancel();
    };
  }, [deviceId, filterDate]);

  return (
    <>
      <HomeNav deviceId={deviceId} active="color-route" />
      <div style={{float: "left", marginTop: 12}}>
        <DateFilter
          onChange={handleChangeDate} />
      </div>
      <div style={{float: "left"}}>
        <HourChart onClick={handleClickHour} offsetLeft={292} offsetTop={42} size={size} hours={hours} />
      </div>
      <div style={{clear: "both"}}></div>
      <ColorRouteMap device={device} signals={signals} {...props} />
    </>
  );
}

export default ColorRoute;
