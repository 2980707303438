import BaseService from './BaseService';

class MobileOnline extends BaseService {

  getLoginDevices = (userId) => {
    return this.axiosInstance.get(`/mobile_online/${userId}/`, {cancelToken: this.cancelTokenSource.token});
  }

}

export default MobileOnline;