import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {toShow} from '../Assets/utils';
import './nav.css';

import DeviceService from './../../services/Devices';

const deviceInitialState = {
  name: '',
  driver_last_name: ''
};

function HomeNav(props) {

  const [device, setDevice] = useState(deviceInitialState);
  const deviceService = new DeviceService();

  const fetchDevice = async () => {
    try {
      await deviceService.getDeviceAllInfo(props.deviceId)
        .then((response) => {
          setDevice(response.data);
        });

    } catch (error) {
      setDevice(deviceInitialState);
    }
  };

  useEffect(() => {
    fetchDevice();

  }, [props.deviceId]);
  
  return (
    <div className="container-fluid nav-tabs">
      <div className="row">
        <div className="column">
          {(props.deviceId > 0 && device.name !== '') ? (
              <p id="qmenu-device">
                <span id="qmenu-blink"/>
                {device.name}
                {(device.driver_last_name !== '') ? (
                    <span id="qmenu-driver">
                      <span>
                        <img src={require('../Assets/images/man.png').default} alt="man" width="14"/>
                      </span>
                      {toShow(device.driver_last_name, 6)}
                    </span>
                  ) :
                  null}
              </p>
            ) :
            null
          }
        </div>
        <div className="column qmenu-tabs">
          <ul>
            <li>
                <Link className={classNames('nav-link', {'active': (props.active === "location")})}
                to={`/home/location/${props.deviceId}`} ><FormattedMessage id="QMENU.LOCATION"/></Link>
            </li>
            <li>
                <Link className={classNames('nav-link', {'active': (props.active === "route")})}
                to={`/home/route/${props.deviceId}`} ><FormattedMessage id="QMENU.ROUTE"/></Link>
            </li>
            <li>
                <Link className={classNames('nav-link', {'active': (props.active === "anim-route")})}
                to={`/home/anim-route/${props.deviceId}`} ><FormattedMessage id="QMENU.ROUTE"/>-2</Link>
            </li>
            <li>
                <Link className={classNames('nav-link', {'active': (props.active === "live")})}
                to={`/home/live/${props.deviceId}`} ><FormattedMessage id="QMENU.LIVE"/></Link>
            </li>
            <li>
                <Link className={classNames('nav-link', {'active': (props.active === "daily-report")})}
                to={`/home/daily-report/${props.deviceId}`} ><FormattedMessage id="REPORTS.DAILY"/></Link>
            </li>
            <li>
                <Link className={classNames('nav-link', {'active': (props.active === "monthly-report")})}
                to={`/home/monthly-report/${props.deviceId}`} ><FormattedMessage id="REPORTS.MONTHLY"/></Link>
            </li>
            <li>
                <Link className={classNames('nav-link', {'active': (props.active === "optimized-route")})}
                to={`/home/optimized-route/${props.deviceId}`} ><FormattedMessage id="QMENU.OP_ROUTE"/></Link>
            </li>
            <li>
                <Link className={classNames('nav-link', {'active': (props.active === "speed-route")})}
                to={`/home/speed-route/${props.deviceId}`} ><FormattedMessage id="QMENU.SPEED_CHART"/></Link>
            </li>
            <li>
              <Link className={classNames('nav-link', {'active': (props.active === "auto-pilot")})}
                    to={`/home/auto-pilot/${props.deviceId}`} ><FormattedMessage id="QMENU.AUTO_PILOT"/></Link>
            </li>
            <li>
              <Link className={classNames('nav-link', {'active': (props.active === "fuel-consumption")})}
                    to={`/home/fuel-consumption/${props.deviceId}`} ><FormattedMessage id="QMENU.FUEL"/></Link>
            </li>
            <li>
              <Link className={classNames('nav-link', {'active': (props.active === "graphs")})}
                    to={`/home/graphs-reports/${props.deviceId}`} ><FormattedMessage id="QMENU.GRAPHS"/></Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default React.memo(HomeNav);
