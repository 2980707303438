import React, {useState, useEffect} from 'react';
import {Row, Col, Form, Button, Alert} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import DateFilter from '../Commons/dateFilter';

import CloseIcon from '../Assets/images/close.png';

function BeltService(props) {

    const [data, setData] = useState({...props.data});

    const handleChange = (event) => {
        let value;
        if (event.target.type === "checkbox") {
            value = event.target.checked ? 1 : 0;
        }
        else {
            value = event.target.value;
        }

        setData({...data, [event.target.id]: value});
    };

    const handleChangeDate = (date) => {
        setData({...data, new_service_date: date});
    };

    const handleDelete = (id) => {
        let _data = data.serviceHistory.filter((elements) => elements.service_history_id !== id);
        setData({...data, serviceHistory: _data});
        props.onDelete(id);
    }

    const handleSubmit = () => {
        data['service_type'] = 3;
        data['create_history_oil'] = 0;
        data['create_history_brake'] = 0;
        data['create_history_insp'] = 0;
        props.onSubmit(data);
    };

    useEffect(() => {
        setData({...props.data});
    }, [props.data]);

    return (
        <>
            <Row>
                <Col sm={3}>
                    <h5><FormattedMessage id="SERVICE.NEW_SERVICE_HISTORY"/></h5>
                    <Form.Group controlId="new_service_date">
                        <Form.Label><FormattedMessage id="GENERAL.DATE"/></Form.Label>
                        <DateFilter
                            dateFormat="dd-MM-yyyy HH:mm"
                            showTime={true}
                            arrows={false}
                            onChange={handleChangeDate} />
                    </Form.Group>

                    <Form.Group controlId="new_km_at_service">
                        <Form.Label><FormattedMessage id="SERVICE.CURRENT_MILEAGE"/></Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.new_km_at_service} />
                    </Form.Group>

                    <Form.Group controlId="create_history_belt">
                        <Form.Check
                            onChange={handleChange}
                            checked={data.create_history_belt}
                            type="checkbox"
                            label={<FormattedMessage id="SERVICE.CREATE_SERVICE_HISTORY"/>} />
                    </Form.Group>
                </Col>

                <Col sm={3}>
                    <h5><FormattedMessage id="SERVICE.SERVICE_INTERVAL"/></h5>
                    <Form.Group controlId="interval_for_belt_change_as_km">
                        <Form.Label><FormattedMessage id="GENERAL.KM"/></Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.interval_for_belt_change_as_km} />
                    </Form.Group>

                    <Form.Group controlId="interval_for_belt_change_as_date">
                        <Form.Label><FormattedMessage id="SERVICE.INTERVAL_MONTH"/></Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.interval_for_belt_change_as_date} />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <h5><FormattedMessage id="SERVICE.SERVICE_HISTORY"/></h5>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th><FormattedMessage id="GENERAL.DATE"/></th>
                                <th>KM</th>
                                <th><FormattedMessage id="GENERAL.DELETE"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.serviceHistory.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.service_date}</td>
                                            <td>{item.km_at_service}</td>
                                            <td>
                                                <img
                                                    onClick={() => handleDelete(item.service_history_id)}
                                                    style={{cursor: 'pointer'}}
                                                    src={CloseIcon}
                                                    alt="Delete" />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <Button onClick={handleSubmit} variant="primary" type="button">
                        <FormattedMessage id="GENERAL.SAVE"/>
                    </Button>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm={6}>
                    {data.serviceAlert && data.serviceAlert.status === true && (
                        <>
                            <Alert variant="danger">
                                Servis tarihiniz geçmiş görünüyor lütfen geçmiş servis kayıtlarınızı kontrol edin.
                            </Alert>
                            
                            <Alert variant="warning">
                                Bir sonraki service kalan kilometre: {data.serviceAlert.km} 
                            </Alert>
                        </>
                    )}
                </Col>
            </Row>
        </>
    );
}

export default BeltService;