import BaseService from './BaseService';

class UserInformation extends BaseService {

  getUserInformation = () => {
    return this.axiosInstance.get(`/user_information/`, {cancelToken: this.cancelTokenSource.token});
  };

  setLanguage = (langId) => {
    return this.axiosInstance.get(`/user_information/language/update/${langId}/`, {cancelToken: this.cancelTokenSource.token});
  };

  setTimezone = (timezone) => {
    return this.axiosInstance.get(`/user_information/timezone/update/?timezone=${timezone}`, {cancelToken: this.cancelTokenSource.token});
  };

  getLoginLogs = () => {
    return this.axiosInstance.get(`/user_information/login_logs/`, {cancelToken: this.cancelTokenSource.token});
  };

  setDevicesOnDisplay = (devices_on_display) => {
    return this.axiosInstance.get(`/user_information/devices_on_display/update/?devices_on_display=${devices_on_display}`, {cancelToken: this.cancelTokenSource.token});
  };

  getAllSubUsers = () => {
    return this.axiosInstance.get(`/user_information/get_all_sub_users/?`, {cancelToken: this.cancelTokenSource.token});
  };

  getSubUser = (subUserId) => {
    return this.axiosInstance.get(`/user_information/sub_user/get/${subUserId}/?`, {cancelToken: this.cancelTokenSource.token});
  };

  createSubUser = (data) => {
    return this.axiosInstance.post(`/user_information/sub_user/create/?`, data);
  };

  editSubUser = (subUserId, data) => {
    return this.axiosInstance.post(`/user_information/sub_user/update/${subUserId}/?`, data);
  };

  deleteSubUser = (subUserId) => {
    return this.axiosInstance.get(`/user_information/sub_user/delete/${subUserId}/?`);
  };

  getSubUserDevice(userId, deviceId) {
    return this.axiosInstance.get(`/user_devices/get/${userId}/${deviceId}/?`, {cancelToken: this.cancelTokenSource.token});
  }

  updateSubUserDevice(userDeviceId, data) {
    return this.axiosInstance.post(`/user_devices/update/${userDeviceId}/?`, data);
  }

  isSubUserNameExists = (userName, userId) => {
    return this.axiosInstance.get(`/user_information/is_username_exist/?username=${userName}&user_id=${userId}`);
  };

  isSubUserMailExists = function (email, userId) {
    return this.axiosInstance.get(`/user_information/is_usermail_exist/?email=${email}&user_id=${userId}`);
  };

}

export default UserInformation;