import moment from 'moment';

/** CONSTANTS **/

const dayNames = [
  "Pazar",
  "Pazartesi",
  "Salı",
  "Çarşamba",
  "Perşembe",
  "Cuma",
  "Cumartesi"
];

const monthNames = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık"
];

const tripTypes = [
  {value: 0, label: 'Bilinmiyor'},
  {value: 1, label: 'Görev'},
  {value: 2, label: 'Özel'},
  {value: 3, label: 'Ev'}
];


/** FUNCTIONS **/

// character limit for strings
const toShow = (content, limit) => content.toString().substring(0, limit);

// number limit after the comma for floats
const toFixed = (number, limit) => parseFloat(number).toFixed(limit);

// all devices sort
const sortAllDevices = (devices, groups) => {
  let groupNames = groups.map((group) => group.group_name).concat(['Ungrouped']);
  let obj = [];

  devices.forEach((device) => {
    groupNames.forEach((groupName) => {
      if (device.groups.includes(groupName)) {
        obj.push([groupName, [device]]);
      }
    });
  });

  return obj;
};

// group sort
const sortByGroup = (devices, groups) => {
  let groupNames = groups.map((group) => group.group_name).concat(['Ungrouped']);
  let data, obj = [];

  groupNames.forEach((groupName) => {
    data = devices.filter(function (device) {
      if (device.groups.includes(groupName)) {
        return device;
      }
    });

    obj.push([groupName, data]);

  });

  return obj;
};

// scroll to selected device
const handleScroll = (id, refs) => {
  sessionStorage.setItem('scroll_status','false');
  refs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
  });
};

// zero padded strings
const pad = (value) => {
  return ( value.toString().length < 2 ) ? '0' + value : value;
};

// printer
const printData = (table_id) => {

  var table, newWin, link;

  table = document.getElementById(table_id);
  newWin = window.open("", "", "width=767, height=500");

  newWin.document.write(table.outerHTML);
  newWin.document.getElementsByClassName('table')[0].style.textAlign = 'center';
  newWin.document.getElementsByClassName('table')[0].style.fontSize = '13px';

  link = newWin.document.createElement('link');
  link.id = "bootstrap";
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.media = 'all';
  link.href = 'assets/css/bootstrap.min.css';

  newWin.print();
  newWin.close();
};

// convert string to date in given format
const getFormattedDate = (dateString, format='DD-MM-YYYY HH:mm:ss') => {
  let date = new Date(dateString);
  return moment(date).format(format);
};

// extract day
const getDay = (day) => {
  let date = new Date(day);
  return moment(date).format("DD");
};

// extract time
const getTime = (day) => {
  let date = new Date(day);
  return moment(date).format("HH:mm");
};

// extract name of the day
const getDayName = (day) => {
  let date = new Date(day);
  return dayNames[date.getDay()];
};

// extract name of the month
const getMonthName = (day) => {
  let date = new Date(day);
  return monthNames[date.getMonth()];
};

const makeGeoJson = (json, feature) => {
  
  if ("features" in json) {
    json.features.push(feature);
  }
  else {
    json = {
      "type": "FeatureCollection",
      "features": [feature]
    }
  }

  return json;
};

const timeToText = (minute, _options) => {
  let options = _options || {
    minuteText: "dakika",
    hourText: "saat",
    dayText: "gün"
  };

  let _minute = parseInt(minute),
    _hour = 0,
    _day = 0,
    _format = "";

  if (_minute < 60) {
    _format = _minute +  " " + options.minuteText;
  }
  else if (_minute >= 60 && _minute < 1440) {
    _hour = Math.floor(_minute / 60);
    _minute = _minute - (_hour * 60);
    
    _format = _hour + " " +options.hourText;

    if (_minute)
      _format += " " + _minute + " " + options.minuteText;
  }
  else {
    _day = Math.floor(_minute / 24 / 60);
    _hour = Math.floor(_minute / 60) - (_day * 24);
    _minute = _minute - (_day * 24 * 60) - (_hour * 60);
    
    _format = _day + " " + options.dayText;
    
    if (_hour)
      _format += " " + _hour + " " + options.hourText;
    
    if (_minute)
      _format += " " + _minute + " " + options.minuteText;
  }

  return _format;
};

const dayDiff = (start, end) => {
  if (start instanceof Date && end instanceof Date) {
    let diffMs = (end - start); // milliseconds
    return Math.floor(diffMs / 86400000); // days
  }

  return false;
};


export {
  tripTypes,
  toShow,
  toFixed,
  sortAllDevices,
  sortByGroup,
  handleScroll,
  pad,
  printData,
  getFormattedDate,
  getDay,
  getTime,
  getDayName,
  getMonthName,
  makeGeoJson,
  timeToText,
  dayDiff
};