import BaseService from './BaseService';

class RentACar extends BaseService {
    
	getDeviceRentAcar(device_id) {
		return this.axiosInstance.get(`/rentacar/${device_id}/?`, {cancelToken: this.cancelTokenSource.token});
	}

	getRentAcar(rentacar_id) {
		return this.axiosInstance.get(`/rentacar/get/${rentacar_id}/?`, {cancelToken: this.cancelTokenSource.token});
	}

	insertDeviceRentAcar(device_id, query_string) {
		return this.axiosInstance.post(`/rentacar/insert/${device_id}/?`, query_string);
	}

	updateDeviceRentAcar(rentacar_id, query_string) {
		return this.axiosInstance.post(`/rentacar/update/${rentacar_id}/?`, query_string);
    }
    
    deleteDeviceRentAcar(rentacar_id) {
		return this.axiosInstance.post(`/rentacar/delete/${rentacar_id}/?`);
	}

}

export default RentACar;