import BaseService from './BaseService';

class HideDevicesService extends BaseService {

  get(deviceId) {
    return this.axiosInstance.get(`/hide_devices/${deviceId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }
  
  getTime(deviceId) {
    return this.axiosInstance.get(`/hide_devices_time/${deviceId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }
  
  update(deviceId, hideStatus) {
    return this.axiosInstance.get(`/hide_devices/${deviceId}/update/?hide_status=${hideStatus}`, {cancelToken: this.cancelTokenSource.token});
  }

  updateTime(deviceId, data) {
    return this.axiosInstance.post(`/hide_devices_time/${deviceId}/update/`, data);
  }

}

export default HideDevicesService;