import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';

import {makeGeoJson} from '../Assets/utils';
import TheftProtectionMap from '../Map/theftProtection';
import MessageWindow from '../Commons/messageWindow';

import CarService from '../../services/Cars';
import Notifications from '../../services/Notifications';
import GeofenceZones from '../../services/GeofenceZones';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

import './index.css';

let _zones = {};

function TheftProtection(props) {

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [modalStyles, setModalStyles] = useState({
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 99,
    left: 0,
    top: 0,
    backgroundColor: '#000',
    opacity: .4
  });
  const [showMessage, setShowMessage] = useState({
    "show": false,
    "title": "",
    "message": ""
  });

  const [step1, setStep1] = useState({
    "day_1_active": 0,
    "day_2_active": 0,
    "day_3_active": 0,
    "day_4_active": 0,
    "day_5_active": 0,
    "day_6_active": 0,
    "day_7_active": 0,
    "day_1_start": "",
    "day_2_start": "",
    "day_3_start": "",
    "day_4_start": "",
    "day_5_start": "",
    "day_6_start": "",
    "day_7_start": "",
    "day_1_end": "",
    "day_2_end": "",
    "day_3_end": "",
    "day_4_end": "",
    "day_5_end": "",
    "day_6_end": "",
    "day_7_end": ""
  });
  
  const [step2, setStep2] = useState({
    "sms_ignition": 0,
    "sms_contact": 0
  });

  const [step3, setStep3] = useState({
    "email_1": "",
    "email_2": "",
    "email_3": ""
  });

  const [zones, setZones] = useState({});

  const carService = new CarService();
  const notificationService = new Notifications();
  const geofenceZones = new GeofenceZones();

  const deviceList = props.devices.map(item => {
    return {name: item['name'], device_id: item['device_id']}
  });

  const handleSelectVehicle = (selectedOption) => {
    setSelectedVehicle(selectedOption);
    setModalStyles({...modalStyles, display: 'none'});
  };

  /*** STEP 1 ***/
  const handleSubmitStep1 = (dataStep1) => {
    notificationService.update(selectedVehicle.value, dataStep1)
    .then(() => {
      setShowMessage({...showMessage, 
        show: true,
        title: "İşlem Başarılı",
        message: "Bilgiler başarıyla kaydedildi."
      });
    }).catch(() => {
      setShowMessage({...showMessage, 
        show: true,
        title: "Hata",
        message: "Bilgiler kaydedilirken hata oluştu."
      });
    });
  };
  /*** STEP 1 END ***/

  /*** STEP 2 ***/
  const handleSubmitStep2 = (dataStep2) => {
    carService.saveCar(selectedVehicle.value, dataStep2)
    .then((data) => {
        if (data.status === 200) {
            setShowMessage({...showMessage, 
                show: true,
                title: "İşlem Başarılı",
                message: "Bilgiler başarıyla kaydedildi."
            });
        }
    }).catch(() => {
        setShowMessage({...showMessage, 
            show: true,
            title: "Hata",
            message: "Bilgiler kaydedilirken hata oluştu."
        });
    });
  };
  /*** STEP 2 END ***/

  /*** STEP 3 ***/
  const handleSubmitStep3 = (dataStep3) => {
    if (dataStep3 === false) {
      setShowMessage({...showMessage, 
        show: true,
        title: "Hata",
        message: "Lütfen geçerli bir email adresi giriniz."
      });
      return;
    }

    carService.saveCar(selectedVehicle.value, dataStep3)
    .then((data) => {
        if (data.status === 200) {
            setShowMessage({...showMessage, 
                show: true,
                title: "İşlem Başarılı",
                message: "Bilgiler başarıyla kaydedildi."
            });
        }
    }).catch(() => {
        setShowMessage({...showMessage, 
            show: true,
            title: "Hata",
            message: "Bilgiler kaydedilirken hata oluştu."
        });
    });
  };
  /*** STEP 3 END ***/

  const handleDrawEnd = (data) => {
    let feature = {
      "type": "Feature",
      "id": "OpenLayers.Feature.Vector_" + data.id,
      "properties": {
        "name": "Zone " + data.id,
        "start_hour": "",
        "end_hour": "",
        "active": "false",
        "ban_area": "false",
        "email": "false",
        "sms": "false",
        "in": "true",
        "out": "true"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [data.coordinates]
      }
    };
     
    let json = {};
    if ("geojson" in _zones) {
      json = makeGeoJson(JSON.parse(_zones.geojson), feature);
    }
    else {
      json = makeGeoJson({"features" : []}, feature);
    }
    
    setZones({});
    _zones.geojson = JSON.stringify(json);
    _zones.json_avg_lat = data.avgLat;
    _zones.json_avg_lon = data.avgLong;
    _zones.json_max_lat = data.maxLatLong[0];
    _zones.json_max_lon = data.maxLatLong[1];
    _zones.json_min_lat = data.minLatLong[0];
    _zones.json_min_lon = data.minLatLong[1];
    setZones(_zones);
  };

  const handleZoneDelete = (data) => {
    setZones({...zones, geojson: JSON.stringify(data)});
  };

  const handleZoneSubmit = (data) => {
    let json = {
      "url": "geofence_zones/update/" + selectedVehicle.value,
      "action_list": "geofence_zones_insert_update",
      "device_id": selectedVehicle.value,
      "geojson": JSON.stringify({
        "features": data.features,
        "type": "FeatureCollection"
      }),
      "json_avg_lat": _zones.json_avg_lat,
      "json_avg_lon": _zones.json_avg_lon,
      "json_max_lat": _zones.json_max_lat,
      "json_max_lon": _zones.json_max_lon,
      "json_min_lat": _zones.json_min_lat,
      "json_min_lon": _zones.json_min_lon,
      "saveAll": data.saveAll
    };

    geofenceZones.insert(json).then((data) => {
      if (data.status === 200) {
        setShowMessage({...showMessage, 
            show: true,
            title: "İşlem Başarılı",
            message: "Bilgiler başarıyla kaydedildi."
        });
      }
    }).catch(() => {
      setShowMessage({...showMessage, 
        show: true,
        title: "Hata",
        message: "Bilgiler kaydedilirken hata oluştu."
      });
    });

    setZones({...zones, geojson: JSON.stringify({
      "features": data.features,
      "type": "FeatureCollection"
    })});

    _zones = json;
  };

  const fetchData = async () => {
    const responseStep1 = await notificationService.getNotificationByDeviceId(selectedVehicle.value);

    if ("day_1_active" in responseStep1.data) {
      setStep1({...step1, 
        day_1_active: responseStep1.data.day_1_active,
        day_2_active: responseStep1.data.day_2_active,
        day_3_active: responseStep1.data.day_3_active,
        day_4_active: responseStep1.data.day_4_active,
        day_5_active: responseStep1.data.day_5_active,
        day_6_active: responseStep1.data.day_6_active,
        day_7_active: responseStep1.data.day_7_active,
        day_1_start: responseStep1.data.day_1_start,
        day_2_start: responseStep1.data.day_2_start,
        day_3_start: responseStep1.data.day_3_start,
        day_4_start: responseStep1.data.day_4_start,
        day_5_start: responseStep1.data.day_5_start,
        day_6_start: responseStep1.data.day_6_start,
        day_7_start: responseStep1.data.day_7_start,
        day_1_end: responseStep1.data.day_1_end,
        day_2_end: responseStep1.data.day_2_end,
        day_3_end: responseStep1.data.day_3_end,
        day_4_end: responseStep1.data.day_4_end,
        day_5_end: responseStep1.data.day_5_end,
        day_6_end: responseStep1.data.day_6_end,
        day_7_end: responseStep1.data.day_7_end
      });
    } else {
      setStep1({...step1, 
        day_1_active: 0,
        day_2_active: 0,
        day_3_active: 0,
        day_4_active: 0,
        day_5_active: 0,
        day_6_active: 0,
        day_7_active: 0,
        day_1_start: "",
        day_2_start: "",
        day_3_start: "",
        day_4_start: "",
        day_5_start: "",
        day_6_start: "",
        day_7_start: "",
        day_1_end: "",
        day_2_end: "",
        day_3_end: "",
        day_4_end: "",
        day_5_end: "",
        day_6_end: "",
        day_7_end: ""
      });
    };

    const response = await carService.getCar(selectedVehicle.value);
    setStep2({...step2,
      sms_ignition: response.data.sms_ignition,
      sms_contact: response.data.sms_contact,
      main_power_off: response.data.main_power_off
    });

    setStep3({...step3, 
      email_1: response.data.email_1,
      email_2: response.data.email_2,
      email_3: response.data.email_3
    });

    const responseZones = await geofenceZones.getByDeviceId(selectedVehicle.value);

    setZones({});
    _zones = {};
    
    setZones(responseZones.data);
    _zones = responseZones.data;
    
  };

  useEffect(() => {
    if (selectedVehicle) {
      fetchData();
      setSelectedDevice(props.devices.find(device => device.device_id === selectedVehicle.value));
    }

  }, [selectedVehicle]);
  
  return (
    <>
      <MessageWindow
        size="md"
        show={showMessage.show}
        onHide={() => setShowMessage({...showMessage, show: false})}
        title={showMessage.title}
        message={showMessage.message} />

      <Row>
        <Col md={3}>
          <h5>Hırsızlık Koruması</h5>
          <Select
            value={selectedVehicle}
            onChange={handleSelectVehicle}
            placeholder={<FormattedMessage id="GENERAL.PICK_DEVICE"/>}
            options={deviceList.map((device) => {
              return {value: device.device_id, label: device.name}
            })}
          /><br />

          <Step1 onSubmit={handleSubmitStep1}
            data={step1}
            deviceId={selectedVehicle} /><br />
          
          <Step2 onSubmit={handleSubmitStep2}
            data={step2}
            deviceId={selectedVehicle} /><br />

        </Col>
        <Col md={9}>
          <div style={modalStyles}>&nbsp;</div>
          <TheftProtectionMap
            zones={zones}
            onDrawEnd={handleDrawEnd}
            selectedDevice={selectedDevice}
            {...props} />
        </Col>
      </Row>
      <Row style={{marginTop: 5}}>
        <Col md={9}>
          <Step4
            deviceId={selectedVehicle}
            onDelete={handleZoneDelete}
            onSubmit={handleZoneSubmit}
            zones={zones} />
        </Col>
        <Col md={3}>
          <Step3 onSubmit={handleSubmitStep3}
            data={step3}
            deviceId={selectedVehicle} />
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = function(state) {
  return {
    devices: state.deviceListReducer.devices
  }
};

export default connect(mapStateToProps)(TheftProtection);
