import BaseService from './BaseService';

class VehicleService extends BaseService {

  get(deviceId) {
    return this.axiosInstance.get(`/vehicle_service/${deviceId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }
  
  getHistoryAll(deviceId) {
    return this.axiosInstance.get(`/service_history/${deviceId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }
  
  getAlerts(deviceId) {
    return this.axiosInstance.get(`/vehicle_service/alerts/${deviceId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }

  update(deviceId, data) {
    return this.axiosInstance.post(`/vehicle_service/update/${deviceId}/?`, data);
  }

  insertHistory(deviceId, data) {
    return this.axiosInstance.post(`/service_history/insert/${deviceId}/?`, data);
  }

  deleteHistory(serviceHistoryId) {
    return this.axiosInstance.get(`/service_history/delete/${serviceHistoryId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }

}

export default VehicleService;