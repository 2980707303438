import React, {useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import LocationsMap from '../Map/locations';

import GeofencePoints from '../../services/GeofencePoints';
import MessageWindow from '../Commons/messageWindow';

import CloseIcon from '../Assets/images/close.png';
import './index.css';

function Locations(props) {

	const [geofencePoints, setGeofencePoints] = useState([]);
	const [changeFlag, setChangeFlag] = useState(false);
	const [showMessage, setShowMessage] = useState({
		"show": false,
		"title": "",
		"message": ""
	});

	const geofencePointsService = new GeofencePoints();

	// onchange handler
	const handleNameChange = (event, index) => {
		geofencePoints[index][event.target.name] = event.target.value;
		setGeofencePoints([...geofencePoints]);
		setChangeFlag(true);
	};

	// onmouseleave handler
	const handleNameMouseLeave = async () => {
		if (changeFlag) {
			await geofencePointsService.updateGeofencePoints(geofencePoints)
				.then(response => {
					if (response.data.result !== "success") {
						setShowMessage({
							show: true,
							title: "Hata",
							message: "Bilgiler kaydedilirken hata oluştu."
						});
						// restore original names
						fetchData();
					}
					setChangeFlag(false);
				})
		}
	};

	// onchange handler
	const handleIconChange = async (icon_type, index) => {
		// clone the array to be able to send before updating the state
		let data = JSON.parse(JSON.stringify(geofencePoints));
		data[index]['icon_type'] = icon_type;

		await geofencePointsService.updateGeofencePoints(data)
			.then(response => {
				if (response.data.result === "success") {
					// update state if change is successfully made
					geofencePoints[index]['icon_type'] = icon_type;
					setGeofencePoints([...geofencePoints]);
				}
				else {
					setShowMessage({
						show: true,
						title: "Hata",
						message: "Bilgiler kaydedilirken hata oluştu."
					});
				}
			})
	};

	// draw handler
	const handleDrawEnd = async (point) => {
		await geofencePointsService.createGeofencePoint(point)
			.then(response => {
				if (typeof response.data.result == 'number') {
					// update state if point saved
					point["point_id"] = response.data.result;
					setGeofencePoints(prevState => [...prevState, point]);
				}
				else {
					setShowMessage({
						show: true,
						title: "Hata",
						message: "Lokasyon kaydedilirken hata oluştu."
					});
				}
			})
	};

	// onclick handler
	const handleDelete = async (point, index) => {
		await geofencePointsService.deleteGeofencePoints(point.point_id)
			.then(response => {
				if (response.data.result === "success") {
					// update state if point deleted
					geofencePoints.splice(index, 1);
					setGeofencePoints([...geofencePoints]);
				}
				else {
					setShowMessage({
						show: true,
						title: "Hata",
						message: "Lokasyon silinemedi."
					});
				}
			})

	};

	const fetchData = async () => {
		await geofencePointsService.getGeofencePoints()
			.then(response => {
				setGeofencePoints(response.data);
			})
	};

	useEffect(() => {
		fetchData();
	}, []);

	const IconMenu = function (props) {

		return (
			<div className='icon-menu'>
				<ul>
					<li>
						{[0, 1, 2, 3, 4, 5, 6].map((id) => {
							return (
								<img src={require(`../Assets/images/locations/${id}.png`).default} alt={`${id}.png`} height="36" width="36"
										 className={`img-thumbnail ${(props.icon_type === id) ? 'active' : ''}`}
										 key={`${id}.png`} onClick={() => handleIconChange(id, props.index)} />
							)
						})}
					</li>
					<li>
						{[7, 8, 9, 10, 11, 12, 13].map((id) => {
							return (
								<img src={require(`../Assets/images/locations/${id}.png`).default} alt={`${id}.png`} height="36" width="36"
										 className={`img-thumbnail ${(props.icon_type === id) ? 'active' : ''}`}
										 key={`${id}.png`} onClick={() => handleIconChange(id, props.index)} />
							)
						})}
					</li>
					<li>
						{[14, 15, 16, 17, 18, 19, 20].map((id) => {
							return (
								<img src={require(`../Assets/images/locations/${id}.png`).default} alt={`${id}.png`} height="36" width="36"
										 className={`img-thumbnail ${(props.icon_type === id) ? 'active' : ''}`}
										 key={`${id}.png`} onClick={() => handleIconChange(id, props.index)} />
							)
						})}
					</li>
				</ul>
			</div>
		)
	};

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-md-3'>
					<h5><FormattedMessage id="MENU.MYLOCATIONS"/></h5>
					<hr/>
					<table className="table table-borderless table-center">
						<thead>
							<tr>
								<th scope="col"><FormattedMessage id="GENERAL.NAME"/></th>
								<th scope="col"><FormattedMessage id="GENERAL.ICON"/></th>
								<th scope="col"><FormattedMessage id="GENERAL.DELETE"/></th>
							</tr>
						</thead>
						<tbody>
							{
								geofencePoints.map((point, index) => {
									return (
										<tr key={point.point_id || point.portal_id}>
											<td>
												<div className="input-group input-group-sm">
													<input type="text" className="form-control" name="point_name" value={point.point_name}
																 onChange={(event) => handleNameChange(event, index)}
																 onMouseLeave={handleNameMouseLeave}/>
												</div>
											</td>
											<td>
												<div className='icon'>
													<img src={require(`../Assets/images/locations/${point.icon_type}.png`).default} alt={`${point.icon_type}.png`}
															 height="36" width="36" className="img-thumbnail" />
													<IconMenu icon_type={point.icon_type} index={index}/>
												</div>
											</td>
											<td>
												<img
													onClick={() => handleDelete(point, index)}
													style={{cursor: 'pointer'}}
													src={CloseIcon}
													alt="Delete" />
											</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</div>
				<div className='col-md-9'>
					<LocationsMap
						points={geofencePoints}
						onDrawEnd={handleDrawEnd}
						active="horizont"
					/>
				</div>
			</div>

			<MessageWindow
				size="md"
				show={showMessage.show}
				onHide={() => setShowMessage({...showMessage, show: false})}
				title={showMessage.title}
				message={showMessage.message} />
		</div>
	);
}

export default Locations;
