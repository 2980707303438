import React, {useState} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {saveAs} from 'file-saver';
import Moment from 'moment';
import '../index.css';

import ReportService from '../../../services/Reports';
import Export from '../../../services/Export';


function MonthlyTrips(props) {
    
    const [addTrip, setAddTrip] = useState(false);
    const [editTrip, setEditTrip] = useState(false);
    const [deleteTrip, setDeleteTrip] = useState(false);
    const [showDailyTrip, setShowDailyTrip] = useState(false);
    const [dailyTripDate, setDaiyTripDate] = useState(new Date());
    const [selectedDateStart, setSelectedDateStart] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date(1970,1,1,0,0,0));
    const [endTime, setEndTime] = useState(new Date(1970,1,1,0,0,0));
    const [km, setKm] = useState(0);
    const [private_km, setPrivateKm] = useState(0);
    const [tripTime, setTripTime] = useState(new Date(1970,1,1,0,0,0));
    const [tripPrivateTime, setPrivateTripTime] = useState(new Date(1970,1,1,0,0,0));
    const [waitingTime, setWaitingTime] = useState(new Date(1970,1,1,0,0,0));
    const [parkedTime, setParkedTime] = useState(new Date(1970,1,1,0,0,0));
    const [choosenTrip, setChoosenTrip] = useState({});
    const [dailyTrips, setDailyTrips] = useState([]);
    const [userMonthlyReportId, setUserMonthlyReportId] = useState(0);

    const reportService = new ReportService();
    const exportService = new Export();

    const handleAddTrips = (trip) => {
        setStartTime(new Date("01/01/1970 00:00:00"));
        setEndTime(new Date("01/01/1970 00:00:00"));
        setTripTime(new Date("01/01/1970 00:00:00"));
        setPrivateTripTime(new Date("01/01/1970 00:00:00"));
        setWaitingTime(new Date("01/01/1970 00:00:00"));
        setParkedTime(new Date("01/01/1970 00:00:00"));
        setChoosenTrip(trip);
        setAddTrip(true);
    };

    const handleEditTrips = (trip) => {
        setSelectedDateStart(Moment(trip.start_date).toDate());
        setStartTime(Moment(trip.start_time, "DD/MM/YYYY HH:mm").toDate());
        setEndTime(Moment(trip.end_time, "DD/MM/YYYY HH:mm").toDate());
        setTripTime(Moment(trip.duration, "HH:mm").toDate());
        setPrivateTripTime(Moment(trip.private_duration, "HH:mm").toDate());
        setWaitingTime(Moment(trip.waiting_time, "HH:mm").toDate());
        setParkedTime(Moment(trip.parked_time, "HH:mm").toDate());
        setKm(trip.km);
        setPrivateKm(trip.private_kilometer);
        setChoosenTrip(trip);
        setEditTrip(true);
    };

    const handleDeleteTrip = (id) => {
        setUserMonthlyReportId(id);
        setDeleteTrip(true);
    };

    const addTripFunction = async () => {

        let newTrip;
        let device_id = 0;
        let trip = {};
        trip.start_date = Moment(choosenTrip.end_time, "DD/MM/YYYY").toDate();
        trip.start_time = new Date("01/01/1970 00:00:00");
        trip.end_time = new Date("01/01/1970 00:00:00");
        trip.duration = new Date("01/01/1970 00:00:00");
        trip.private_duration = new Date("01/01/1970 00:00:00");
        trip.waiting_time = new Date("01/01/1970 00:00:00");
        trip.parked_time = new Date("01/01/1970 00:00:00");

        newTrip = {};
        newTrip.driver_id = 0;
        newTrip.start_date =  Moment(new Date(selectedDateStart)).format("YYYY-MM-DD");
        newTrip.start_time =  Moment(new Date(startTime)).format("HH:mm");
        newTrip.end_time = Moment(new Date(endTime)).format("HH:mm");
        newTrip.travel_time = Moment(new Date(tripTime)).format("HH:mm");
        newTrip.private_travel_time = Moment(new Date(tripPrivateTime)).format("HH:mm");
        newTrip.waiting_time = Moment(new Date(waitingTime)).format("HH:mm");
        newTrip.empty_time = Moment(new Date(parkedTime)).format("HH:mm");
        newTrip.kilometer = parseInt(km);
        newTrip.private_kilometer = parseInt(private_km);
        newTrip.description = '';
        device_id = props.deviceId;

        try {
            await reportService.insertUserMonthlyReports(device_id, newTrip)
              .then(async(response) => {
                setAddTrip(false);
                props.manuelTrip(true);
              });
            } catch (error) {
        }
        props.manuelTrip(false);
    };

    const editTripFunction = async () => {
        let editedTrip;

        editedTrip = {};
        editedTrip.driver_id = 0;
        editedTrip.start_date =  Moment(selectedDateStart).format("YYYY-MM-DD");
        editedTrip.start_time =  Moment(new Date(startTime)).format("HH:mm");
        editedTrip.end_time = Moment(new Date(endTime)).format("HH:mm");
        editedTrip.travel_time = Moment(new Date(tripTime)).format("HH:mm");
        editedTrip.private_travel_time = Moment(new Date(tripPrivateTime)).format("HH:mm");
        editedTrip.waiting_time = Moment(new Date(waitingTime)).format("HH:mm");
        editedTrip.empty_time = Moment(new Date(parkedTime)).format("HH:mm");
        editedTrip.private_kilometer = parseInt(private_km);
        editedTrip.kilometer = parseInt(km);
        editedTrip.description = '';

        try {
            await reportService.updateUserMonthlyReports(choosenTrip.user_monthly_report_id, editedTrip)
              .then(async(response) => {
                setEditTrip(false);
                props.manuelTrip(true);
              });
            } catch (error) {
        }
        props.manuelTrip(false);
    };

    const deleteTripFunction = async (user_monthly_report_id) => {
        try {
            await reportService.deleteUserMonthlyReports(user_monthly_report_id)
              .then(async(response) => {
                props.manuelTrip(true);
                setDeleteTrip(false);
              });
            } catch (error) {
        }
        props.manuelTrip(false);
    };

    const reportDetail = async (report) => {
        setShowDailyTrip(false);
        let start_time = Moment(report.start_time, "DD/MM/YYYY HH:mm:ss").toDate();
        let start_date = Moment(new Date(start_time)).format("YYYY-MM-DD HH:mm:ss");


        let end_time = Moment(report.end_time, "DD/MM/YYYY HH:mm:ss").toDate();
        let end_date = Moment(new Date(end_time)).format("YYYY-MM-DD HH:mm:ss");

        if (props.routerParams.url.includes('device')) {

            try {
                await reportService.getDeviceDailyTrips(props.deviceId, start_date, end_date)
                  .then(async(response) => {
                    setDailyTrips(setReportDetail(response.data));
                    setShowDailyTrip(true);
                    setDaiyTripDate(start_date);
                  });
                } catch (error) {
            }

        } 
        else if (props.routerParams.url.includes('driver')) {

            try {
                await reportService.getDriverDailyTrips(props.driverId, start_date, end_date)
                  .then(async(response) => {
                    setDailyTrips(setReportDetail(response.data));
                    setShowDailyTrip(true);
                    setDaiyTripDate(start_date);
                  });
                } catch (error) {
            }
        }
    };

    const setReportDetail = (trips) => {
        
        for (let i = 0; i < trips.length; i++) {
            // convert date object start_time and end_time
            if (!(trips[i]['start_time'] instanceof Date)) {
                trips[i]['start_time'] = Moment(new Date(Moment(trips[i]['start_time'], "YYYY/MM/DD HH:mm:ss").toDate())).format("YYYY/MM/DD HH:mm:ss");
                trips[i]['end_time'] = Moment(new Date(Moment(trips[i]['end_time'], "YYYY/MM/DD HH:mm:ss").toDate())).format("YYYY/MM/DD HH:mm:ss");
            }

            trips[i]['duration'] =  Moment(new Date(Moment('1970/01/01 ' + trips[i]['duration'], "YYYY/MM/DD HH:mm:ss").toDate())).format("YYYY/MM/DD HH:mm:ss");
            trips[i]['waiting_time'] = Moment(new Date(Moment('1970/01/01' + trips[i]['waiting_time'], "YYYY/MM/DD HH:mm:ss").toDate())).format("YYYY/MM/DD HH:mm:ss");
            // check if trip jump
            if (Moment(new Date(trips[i]['start_time'])).format("YYYY/MM/DD") !== Moment(new Date(trips[i]['end_time'])).format("YYYY/MM/DD")) {
                trips[i]['jump'] = true;

                // if trip is jump from previous day, class: 'danger'
                // if trip is jump to next day, class: ''
                trips[i]['jump_class'] = (i === 0) ? 'danger' : '';
            }

            if (props.routerParams.url.includes('device')) {  // set driver
                for (let j = 0; j < props.drivers.length; j++) {
                    if (trips[i]['driver_id'] === props.drivers[j]['driver_id']) {

                        trips[i]['driver'] = props.drivers[j];
                    }
                }
            }
        }
        return trips;
    };

    // onclick handler
    const handleExport = async (fileExtension) => {
        const start = Moment(dailyTripDate).format("DD-MM-YYYY") + ' 00:00:00';
        const end = Moment(dailyTripDate).format("DD-MM-YYYY") + ' 23:59:59';

        if (props.deviceId > 0)
            await exportService.exportDailyReport(props.deviceId, start, end, dailyTrips, fileExtension)
              .then((response) => {
                  let blob = new Blob([response.data]);
                  saveAs(blob, `Günlük_Rapor.` + fileExtension);
              });
        else
            await exportService.exportDriverDailyReport(props.driverId, start, end, dailyTrips, fileExtension)
              .then((response) => {
                  let blob = new Blob([response.data]);
                  saveAs(blob, 'Günlük_Rapor.' + fileExtension);
              });
    };
    
    return (
        <React.Fragment>
            <div> 
                <div className="trips table-responsive" id="monthly-trips">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                {
                                    props.user.user_group_id === 2 ? <th></th>  : null 
                                }
                                <th><FormattedMessage id="GENERAL.DATE"/></th>
                                <th><FormattedMessage id="GENERAL.START"/></th>
                                <th><FormattedMessage id="GENERAL.STOP"/></th>
                                <th>Km - <FormattedMessage id="REPORTS.TRIP_TYPE_2"/></th>
                                <th>Km - <FormattedMessage id="REPORTS.TRIP_TYPE_1"/></th>
                                <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
                                <th><FormattedMessage id="GENERAL.TRIP_TIME"/>(<FormattedMessage id="GENERAL.PRIVATE"/>)</th>
                                <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
                                <th><FormattedMessage id="REPORTS.DAILY_STANDBY"/></th>
                                {
                                    props.user.user_group_id === 2 ? <th></th>  : null
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.trips.length !== 0 ? 
                                props.trips.map((trip,index) => {
                                    let date = Moment(new Date(trip.start_date)).format("DD/MM/YYYY").substring(0,11);
                                        return  <tr key={'trip-' + index} id={'trip-' + index} className={trip.class !== undefined ? trip.class : index%2 !== 0 ? 'active' : ''}>
                                                    {
                                                        props.user.user_group_id === 2 ?
                                                            <td><img onClick={() => reportDetail(trip)} className="report-detail-icon" src={require("../images/search-icon.png").default} width="15" alt=""/></td>
                                                            :
                                                            null
                                                    }
                                                    <td>{date}
                                                    {
                                                        trip.end_date ?   <span><br></br>{Moment(new Date(trip.end_date)).format("DD/MM/YYYY").substring(0,11)}</span> : null
                                                    } 
                                                    </td>
                                                    <td>{trip.start_time !== undefined ?  trip.start_time.substring(11,16) : ''}</td>
                                                    <td>{trip.end_time !== undefined ?  trip.end_time.substring(11,16) : ''}</td>
                                                    <td>{trip.private_km !== undefined ?  trip.private_km : ''}</td>
                                                    <td>{trip.km !== undefined ?  trip.km : ''}</td>
                                                    <td>{trip.duration !== undefined ?  trip.duration.substring(0,5) : ''}</td>
                                                    <td>{trip.private_duration !== undefined ?  trip.private_duration.substring(0,5) : ''}</td>
                                                    <td>{trip.waiting_time !== undefined ?  trip.waiting_time.substring(0,5) : ''}</td>
                                                    <td>{trip.parked_time !== undefined ?  trip.parked_time.substring(0,5) : ''}</td>
                                                    {
                                                        props.user.user_group_id === 2 ?
                                                            <td>
                                                            { 
                                                                !props.reportType.url.includes('driver') ? 
                                                                <div>
                                                                    <span onClick={() => handleAddTrips(trip)} className="fa fa-plus tip"/>
                                                                    {
                                                                        trip.user_monthly_report_id
                                                                          ?
                                                                          <span onClick={() => handleDeleteTrip(trip.user_monthly_report_id)} className="fa fa-minus"/>
                                                                          : null
                                                                    }
                                                                    {
                                                                        trip.user_monthly_report_id
                                                                          ?
                                                                          <span onClick={() => handleEditTrips(trip)} className="fa fa-pencil"/>
                                                                          :
                                                                          null
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            </td>
                                                            :
                                                            null
                                                    }
                                                </tr>
                                }): null
                            }
                        </tbody>
                    </table>
                </div>
                <div className="trips-total table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th/>
                                <th><FormattedMessage id="GENERAL.ACTIVE_TIME"/></th>
                                <th>Km - <FormattedMessage id="REPORTS.TRIP_TYPE_2"/></th>
                                <th>Km - <FormattedMessage id="REPORTS.TRIP_TYPE_1"/></th>       
                                <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
                                <th><FormattedMessage id="GENERAL.TRIP_TIME"/>(<FormattedMessage id="GENERAL.PRIVATE"/>)</th>
                                <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
                                <th><FormattedMessage id="REPORTS.DAILY_STANDBY"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="info">
                            <td><b><FormattedMessage id="GENERAL.TOTAL"/></b></td>
                            <td>{props.total.active}</td>
                            <td>{props.total.private_km}</td>
                            <td>{props.total.km}</td>
                            <td>{props.total.duration}</td>
                            <td>{props.total.private_duration}</td>
                            <td>{props.total.waiting_time}</td>
                            <td>{props.total.parked_time}</td>
                            </tr>
                        </tbody>
                    </table>  
                </div>
                <div className="clearfix"/>
            </div>
            <Modal
                size="lg"
                show={addTrip || editTrip}
                aria-labelledby="addEditTrip"
                onHide={addTrip ? () => setAddTrip(false) : () => setEditTrip(false)}>
                <Modal.Header closeButton>
                    <Modal.Title id="addEditTrip">
                        {addTrip ? <FormattedMessage id="REPORTS.ADD_REPORT"/> : <FormattedMessage id="REPORTS.EDIT_REPORT"/>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="addEditTrip">
                    <div className="ngdialog-message">
                        <table className="table table-hover add-trip">
                            <tbody>
                            <tr>
                                <td><FormattedMessage id="GENERAL.START_DATE"/></td>
                                <td>
                                    <DatePicker
                                        value={selectedDateStart}
                                        todayButton="Today"
                                        maxDate={new Date()}
                                        dateFormat="dd-MM-yyyy"
                                        selected={selectedDateStart}
                                        onChange={(date)=> setSelectedDateStart(date)}/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.START"/></td>
                                <td>
                                    <DatePicker
                                        value={startTime}
                                        selected={startTime}
                                        onChange={date => setStartTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.STOP"/></td>
                                <td>
                                    <DatePicker
                                        value={endTime}
                                        selected={endTime}
                                        onChange={date => setEndTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.KM"/></td>
                                <td>
                                    <input type="number"  value={km} className="form-control" onChange={(e) => setKm(e.target.value)}/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.KM"/>(<FormattedMessage id="GENERAL.PRIVATE"/>)</td>
                                <td>
                                    <input type="number"  value={private_km} className="form-control" onChange={(e) => setPrivateKm(e.target.value)}/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.TRIP_TIME"/></td>
                                <td>
                                    <DatePicker
                                        value={tripTime}
                                        selected={tripTime}
                                        onChange={date => setTripTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.TRIP_TIME"/> (<FormattedMessage id="GENERAL.PRIVATE"/>)</td>
                                <td>
                                    <DatePicker
                                        value={tripPrivateTime}
                                        selected={tripPrivateTime}
                                        onChange={date => setPrivateTripTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.WAIT_TIME"/></td>
                                <td>
                                    <DatePicker
                                        value={waitingTime}
                                        selected={waitingTime}
                                        onChange={date => setWaitingTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="REPORTS.DAILY_STANDBY"/></td>
                                <td>
                                    <DatePicker
                                        value={parkedTime}
                                        selected={parkedTime}
                                        onChange={date => setParkedTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                        <div className="ngdialog-buttons">
                            <div className="form-group">
                                <button  onClick={addTrip ? addTripFunction : editTripFunction} type="button" className="btn btn-primary pull-right">
                                    <FormattedMessage id="GENERAL.SAVE"/>
                                </button>
                                <button  onClick={addTrip ? () => setAddTrip(false) : () => setEditTrip(false)} type="button" className="btn btn-warning pull-right">
                                    <FormattedMessage id="GENERAL.TURN_BACK"/>
                                </button>
                            </div>
                        </div>
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={showDailyTrip}
                aria-labelledby="showDailyTrip"
                onHide={ () => setShowDailyTrip(false)}>
                
                <Modal.Header closeButton>
                    <Modal.Title id="showDailyTrip">
                        <FormattedMessage id="REPORTS.REPORT_DETAIL"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="showDailyTrip">
                    <div className="ngdialog-message">
                        <table className="table table-hover report-detail">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="REPORTS.ROUTE_NO"/></th>
                                    <th><FormattedMessage id="GENERAL.DATE"/></th>
                                    <th><FormattedMessage id="GENERAL.START"/></th>
                                    <th><FormattedMessage id="GENERAL.START_ADDRESS"/></th>
                                    <th><FormattedMessage id="GENERAL.STOP"/></th>
                                    <th><FormattedMessage id="GENERAL.END_ADDRESS"/></th>
                                    <th><FormattedMessage id="GENERAL.KM"/></th>
                                    <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
                                    <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
                                    <th>{props.reportType.url.includes('device') ? <FormattedMessage id="GENERAL.DRIVER"/> : <FormattedMessage id="SERVICE.VEHICLE_NAME"/>}</th>
                                    <th className="last"/>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dailyTrips.length !== 0 ? 
                                    dailyTrips.map((trip,index) => {
                                        let date = Moment(new Date(trip.start_date)).format("DD/MM/YYYY").substring(0,11);
                                            return  <tr key={'trip-' + index} id={'trip-' + index} className={trip.jump ? trip.jump_class : index%2 !== 0 ? 'active' : ''}>
                                                        <td>
                                                            <span> {index} </span>
                                                        </td>
                                                        <td>{date}</td>
                                                        <td>{trip.start_time !== undefined ?  Moment(new Date(trip.start_time)).format("DD/MM/YYYY HH:mm").substring(11,16) : ''}</td>
                                                        <td width="500">{trip.start_address !== undefined ?  trip.start_address : ''}</td>
                                                        <td>{trip.end_time !== undefined ?   Moment(new Date(trip.end_time)).format("DD/MM/YYYY HH:mm").substring(11,16) : ''}</td>
                                                        <td width="500">{trip.end_address !== undefined ?  trip.end_address : ''}</td>
                                                        <td>{trip.lenght_km !== undefined ?  trip.lenght_km : ''}</td>
                                                        <td>{trip.duration !== undefined ?  Moment(new Date(trip.duration)).format("DD/MM/YYYY HH:mm").substring(11,16) : ''}</td>
                                                        <td>{trip.waiting_time !== undefined ?  Moment(new Date(trip.waiting_time)).format("DD/MM/YYYY HH:mm").substring(11,16) : ''}</td>
                                                        <td style={{fontWeight:'normal'}}>{props.reportType.url.includes('device') ? trip.driver !== undefined ? trip.driver.driver_last_name : '' : trip.device_name}</td>
                                                    </tr>
                                    }): null
                                }
                            </tbody>
                        </table>
                        </div>
                        <div className="ngdialog-buttons pull-right">
                            <div className="form-group">
                                <button onClick={() => handleExport('xlsx')} type="button" className="badge badge-success">
                                    <FormattedMessage id="GENERAL.EXPORT_EXCEL"/>
                                </button>
                                <button onClick={() => handleExport('pdf')} type="button" className="badge badge-danger">
                                    <FormattedMessage id="GENERAL.EXPORT_PDF"/>
                                </button>
                                <button onClick={() => setShowDailyTrip(false)} type="button" className="badge badge-warning">
                                    <FormattedMessage id="GENERAL.TURN_BACK"/>
                                </button>
                            </div>
                        </div>
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={deleteTrip}
                aria-labelledby="deleteTrip"
                onHide={ () => setDeleteTrip(false)}>
                
                <Modal.Header closeButton>
                    <Modal.Title id="deleteTrip">
                        <FormattedMessage id="REPORTS.DELETE_REPORT"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="deleteTrip">
                    <div className="ngdialog-message">
                        <p><FormattedMessage id="REPORTS.CONFIRM_DELETE_TRIP"/></p>
                    </div>
                    <div className="ngdialog-buttons">
                        <div className="form-group">
                        <button type="button" className="badge badge-danger pull-right" onClick={() => deleteTripFunction(userMonthlyReportId)}>
                            <FormattedMessage id="REPORTS.DELETE"/>
                        </button>
                        <button type="button" className="badge badge-warning pull-right" onClick={() => setDeleteTrip(false)} >
                            <FormattedMessage id="REPORTS.TURN_BACK"/>
                        </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    
  );

}
const mapStateToProps = function(state) {
    return {
        devices: state.deviceListReducer.devices,
        drivers: state.driverListReducer.drivers,
        user: state.userInformationReducer.user
    }
};
export default connect(mapStateToProps)(MonthlyTrips);


