import React, {useState,} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {saveAs} from 'file-saver';
import Moment from 'moment';
import Menu from '../Menu';
import MonthlyTrips from '../MonthlyTrips';
import {pad} from '../../Assets/utils';
import '../index.css';

import SignalService from '../../../services/Signals';
import ReportService from '../../../services/Reports';
import CarService from '../../../services/Cars';
import Export from '../../../services/Export';

function MonthlyReport(props) {

    const deviceId = props.match.params.deviceId;
    const driverId = props.match.params.driverId;

    const [trips, setTrips] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [onlyPrivate, setOnlyPrivate] = useState(false);
    const [reportsWithDrivers, setReportsWithDrivers] = useState(false);
    const [showDeviceName, setShowDeviceName] = useState(false);
    const [total, setTotal] = useState({});
    const [start_mil, setStartMileage] = useState(0);
    const [end_mil, setEndtMileage] = useState(0);
    const [manuelTrip_, setManuelTrip] = useState(false);
    const [reportData, setReportData] = useState({name_:'', start_date_: new Date(), end_date_: new Date()});

    const signalService = new SignalService();
    const reportService = new ReportService();
    const carService = new CarService();
    const exportService = new Export();

    const handleFilter = async (name,id,start_date,end_date) => {
        if (deviceId !== 0 || driverId !== 0) {
            if (props.match.url.includes('device')) {
                try {
                    await reportService.getDeviceMonthlyTrips(id,start_date,end_date)
                      .then(async(response) => {
                        getStartAndEndMileage(id,response.data)
                        setMonthlyTrips(response.data);
                        setLoading(false);
                        setReportData({name_: name, start_date_: start_date, end_date_: end_date})
                        return response.data;
                      });
                    } catch (error) {
                }
            } 
            else if (props.match.url.includes('driver')) {
                try {
                    await reportService.getDriverMonthlyTrips(id,start_date,end_date)
                      .then(async(response) => {
                        setMonthlyTrips(response.data);
                        setLoading(false);
                        setReportData({name_: name, start_date_: start_date, end_date_: end_date})
                        return response.data;
                      });
                    } catch (error) {
                }
            }
        } 
        else{
            alert(<FormattedMessage id="REPORTS.PICK_DEVICE_OR_DRIVER"/>);
        }

         //unmount
        return () => {
            reportService.getCancelToken().cancel();
        }
        
    };

    const handleManuelTrip = (manuelTrip) => {
        setManuelTrip(manuelTrip);
    };

    const getStartAndEndMileage = async (deviceId,trips) => {
        let start_signal;
        let end_signal;
        let car;

        try {
            await signalService.getSignalBySignalId(deviceId,trips[0].start_signal_id)
              .then(async (response) => {
                start_signal = response.data;
            });
      
            await signalService.getSignalBySignalId(deviceId, trips[trips.length - 1].end_signal_id)
              .then((response) => {
                end_signal = response.data;
            });

            await carService.getCar(deviceId)
              .then((response) => {
                car = response.data;
            });

            if (start_signal.mileage) {
               setStartMileage(start_signal.mileage + car.car_mileage_at_install);
            }
            if (end_signal.mileage) {
                setEndtMileage(end_signal.mileage + car.car_mileage_at_install);
            }
      
          } catch (error) {
          }

    }

    const setMonthlyTrips = (reports) => {
        let monthly_total = {}, day, hour, min, sec, total_duration, total_active_time,
            total_waiting_time, total_parked_time, total_private_duration,
            total_private_duration_sec = 0, total_duration_sec = 0, total_parked_time_sec = 0,
            total_km = 0, total_private_km = 0, total_waiting_time_sec = 0;

        for (let i = 0; i < reports.length; i++) {
            reports[i]['start_time'] = reports[i]['start_time'] !== '' ? Moment(new Date(reports[i]['start_time'])).format("DD/MM/YYYY HH:mm:ss") : '';
            reports[i]['end_time'] = reports[i]['end_time'] !== '' ? Moment(new Date(reports[i]['end_time'])).format("DD/MM/YYYY HH:mm:ss") : '';

            // check if report add manually
            if (reports[i]['user_monthly_report_id']) {
                reports[i]['class'] = "warning";
            }
        }

        for (let i = 0; i < reports.length; i++) {

            total_km += reports[i]['km'];
            total_private_km += reports[i]['private_km'];
            total_duration_sec += reports[i]['duration_sec'];
            total_private_duration_sec += reports[i]['private_duration_sec'];
            total_waiting_time_sec += reports[i]['waiting_time_sec'];
            total_parked_time_sec += reports[i]['parked_time_sec'];
        }

        // calculate total duration
        day = Math.floor(total_duration_sec / (3600 * 24));
        hour = Math.floor((total_duration_sec / 3600) % 24);
        min = Math.floor((total_duration_sec / 60) % 60);
        sec = Math.floor(total_duration_sec % 60);
        total_duration = day + " " + 'Gün' + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total private duration
        day = Math.floor(total_private_duration_sec / (3600 * 24));
        hour = Math.floor((total_private_duration_sec / 3600) % 24);
        min = Math.floor((total_private_duration_sec / 60) % 60);
        sec = Math.floor(total_private_duration_sec % 60);
        total_private_duration = day + " " + 'Gün' + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total waiting time
        day = Math.floor(total_waiting_time_sec / (3600 * 24));
        hour = Math.floor((total_waiting_time_sec / 3600) % 24);
        min = Math.floor((total_waiting_time_sec / 60) % 60);
        sec = Math.floor(total_waiting_time_sec % 60);
        total_waiting_time = day + " " + 'Gün' + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total parked time
        day = Math.floor(total_parked_time_sec / (3600 * 24));
        hour = Math.floor((total_parked_time_sec / 3600) % 24);
        min = Math.floor((total_parked_time_sec / 60) % 60);
        sec = Math.floor(total_parked_time_sec % 60);
        total_parked_time = day + " " + 'Gün' + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total active time
        day = Math.floor((total_duration_sec + total_private_duration_sec + total_waiting_time_sec) / (3600 * 24));
        hour = Math.floor(((total_duration_sec + total_private_duration_sec + total_waiting_time_sec) / 3600) % 24);
        min = Math.floor(((total_duration_sec + total_private_duration_sec + total_waiting_time_sec) / 60) % 60);
        sec = Math.floor((total_duration_sec + total_private_duration_sec + total_waiting_time_sec) % 60);
        total_active_time = day + " " + 'Gün' + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        monthly_total.km = Number((total_km).toFixed(2));
        monthly_total.private_km = Number((total_private_km).toFixed(2));
        monthly_total.duration = total_duration;
        monthly_total.private_duration = total_private_duration;
        monthly_total.waiting_time = total_waiting_time;
        monthly_total.parked_time = total_parked_time;
        monthly_total.active = total_active_time;

        setTotal(monthly_total);
        setTrips(reports);
    };

    const handleChangeOnlyPrivate = (e) => {
        setOnlyPrivate(e.target.checked);
    };
    
    const handleChangeReportswithDrivers = (e) => {
        setReportsWithDrivers(e.target.checked);
    };
    
    const handleChangeShowDeviceName = (e) => {
        setShowDeviceName(e.target.checked);
    };

    // onclick handler
    const handleExport = async (selectedDateStart, selectedDateEnd, fileExtension, yearlyReport=false) => {
        const start = Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss");
        const end = Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss");
        const _trips = [...trips, total];

        if (yearlyReport)
            await exportService.exportYearlyReport(deviceId, start, end, _trips, fileExtension)
              .then((response) => {
                  let blob = new Blob([response.data]);
                  saveAs(blob, 'Yıllık_Rapor.' + fileExtension);
              });

        else if (deviceId > 0)
            await exportService.exportMonthlyReport(deviceId, start, end, _trips, fileExtension)
              .then((response) => {
                  let blob = new Blob([response.data]);
                  saveAs(blob, `Aylık_Rapor.` + fileExtension);
              });

        else
            await exportService.exportDriverMonthlyReport(driverId, start, end, _trips, fileExtension)
              .then((response) => {
                  let blob = new Blob([response.data]);
                  saveAs(blob, 'Aylık_Rapor.' + fileExtension);
              });
    };

    return (
        <div className="reports">
            <Menu
              history={props.history}
              deviceId={deviceId}
              driverId={driverId}
              onFilter={handleFilter}
              manuel_trip = {manuelTrip_}
              only_private={onlyPrivate}
              handleChangeOnlyPrivate={handleChangeOnlyPrivate}
              handleChangeReportswithDrivers={handleChangeReportswithDrivers}
              handleChangeShowDeviceName={handleChangeShowDeviceName}
              handleExport={handleExport}
              routerParams={props.match}
              mainReportType = {'monthly'}
            />
            {
                !isLoading
                  ?
                  <MonthlyTrips
                    reportData_={reportData}
                    routerParams={props.match}
                    deviceId={deviceId}
                    driverId={driverId}
                    manuelTrip={handleManuelTrip}
                    only_private={onlyPrivate}
                    reports_with_driver={reportsWithDrivers}
                    show_device_name={showDeviceName}
                    trips={trips}
                    total={total}
                    start_mil={start_mil}
                    end_mil={end_mil}
                    reportType={props.match}
                    mainReportType = {'monthly'}/>
                    :
                  null
            }
        </div>
  );

}
const mapStateToProps = function(state) {
    return {
      drivers: state.driverListReducer.drivers
    }
};
export default connect(mapStateToProps)(MonthlyReport);







