import React, {useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from "react-intl";
import 'bootstrap/dist/css/bootstrap.min.css';
import './DashboardLayout.css';

import {langTr, langEn, langDe, langNl} from "../lang";
import HeaderNav from '../components/HeaderNav';

import DeviceService from '../services/Devices';
import DriverService from '../services/Drivers';

import {updateDeviceList, updateDriverList} from "../redux/actions";

const langConfig = {...langTr, ...langEn, ...langDe, ...langNl};
const langs = ['de', 'en', 'tr', 'nl'];

const DashboardLayout = ({children}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const deviceService = new DeviceService();
  const driverService = new DriverService();

  const getDrivers = async () => {
    try {
      await driverService.getDrivers()
        .then(async (response) => {
          children.props.updateDriverList({
            drivers: response.data
          });
          setIsLoading(false);
        });

    } catch (error) {
    }
  };

  function handleNavbarTogglerClick() {
    setIsOpen(!isOpen);
  }

  const fetchData = async () => {
    try {
      await deviceService.getDevicesWithGroups(children.props.sortType, children.props.devices_on_display)
        .then(async (response) => {
          children.props.updateDeviceList({
            devices: response.data[0]["results"],
            totalCount: response.data[1]["total_count"]
          });
        });

    } catch (error) {
    }
  };

  useEffect(() => {
    let token = sessionStorage.getItem('access_token');
    if (!token) window.location.href = process.env.PUBLIC_URL + "/#/login";
  }, [redirect]);

  useEffect(() => {
    fetchData();

    //unmount
    return () => {
      deviceService.getCancelToken().cancel();
    }

  }, [children.props.sortType, children.props.devices_on_display]);

  useEffect(() => {
    if (children.props.drivers && children.props.drivers.length === 0) {
      getDrivers();

    }else{
      setIsLoading(false);
    }

    //unmount
    return () => {
      driverService.getCancelToken().cancel();
    }

  }, []);

  const redirectTo = () => {
    if (redirect)
      return <Redirect to={redirect}/>;
  };

  return (
    <div>
      {redirectTo()}
      {
        isLoading ?
          <div>
            <br/>
            <center><img src={require('../components/Assets/images/horizontal-loading.gif').default} alt=""/></center>
            <br/>
          </div>
       :
       <React.Fragment>
        <HeaderNav {...children.props} isOpen={isOpen} onClick={handleNavbarTogglerClick}/>

        <div className="container-fluid">
          <div className="main">{children}</div>
        </div>
      </React.Fragment>
      }
    </div>
  )
};

const DashboardLayoutRoute = ({component: Component, ...rest}) => {
  return (
    <IntlProvider locale={langs[rest.lang]} messages={langConfig[langs[rest.lang]]}>
      <Route {...rest} render={matchProps => (
        <DashboardLayout>
          <Component {...rest} {...matchProps} />
        </DashboardLayout>
      )}/>
    </IntlProvider>
  )
};

const mapStateToProps = function (state) {
  return {
    lang: state.userInformationReducer.user.language,
    devices_on_display: state.userInformationReducer.user.devices_on_display,
    sortType: state.deviceSortTypeReducer.sortType,
    drivers: state.driverListReducer.drivers
  }
};

const mapDispatchToProps = {
  updateDeviceList,
  updateDriverList
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayoutRoute);