import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import moment from 'moment';
import { connect } from 'react-redux';
import {Col, Row, Button, Table} from 'react-bootstrap';
import {FormattedMessage} from "react-intl";
import DateFilter from '../../Commons/dateFilter';

import TheftProtectionMap from '../../Map/theftProtection';

import GeofenceZones from '../../../services/GeofenceZones';
import Reports from '../../../services/Reports';

function AreaControlReports(props) {

    const deviceId = props.match.params.deviceId;
    const geofenceZones = new GeofenceZones();
    const reportService = new Reports();
    let _startDate = new Date();
    let _endDate = new Date();

    _startDate.setHours(0);
    _startDate.setMinutes(0);
    _endDate.setHours(23);
    _endDate.setMinutes(59);
    
    const [device, setDevice] = useState({});
    const [startDate, setStartDate] = useState(_startDate);
    const [endDate, setEndDate] = useState(_endDate);
    const [loading, setLoading] = useState(false);
    const [zones, setZones] = useState({});
    const [reports, setReports] = useState([]);

    const fetchData = async (_startDate, _endDate) => {
        setLoading(true);
        const responseZones = await geofenceZones.getAreaControlByDeviceId(deviceId);
        setZones(responseZones.data);

        const reportResults = await reportService.getAreaControlReports(deviceId,
            moment(_startDate).format("YYYY-MM-DD H:mm:00"),
            moment(_endDate).format("YYYY-MM-DD H:mm:59"));

        setReports(reportResults.data);
        setLoading(false);
    };

    const handleChangeStartDate = date => {
        setStartDate(date);
    };

    const handleChangeEndDate = date => {
        setEndDate(date);
    };

    const handleGeofence = (_startDate, _endDate) => {
        fetchData(_startDate, _endDate);
    };

    useEffect(() => {
        if (props.devices.length > 0) {
            fetchData(startDate, endDate);
            setDevice(props.devices.find(device => parseInt(device.device_id) === parseInt(deviceId)));
        }

        return () => {
            geofenceZones.getCancelToken().cancel();
        };
    }, [deviceId, props.devices]);
    
    return (
        <Row>
            <Col sm={3}>
                <h6><FormattedMessage id={"GENERAL.FILTER_OPTIONS"}/></h6>
                <DateFilter
                    arrows={false}
                    showTime={true}
                    dateFormat="dd-MM-yyyy HH:mm:00"
                    selectedDate={startDate}
                    onChange={handleChangeStartDate}
                    wrapperClassName="react-datepicker-wrapper-full-width mb-1" />
                    
                <DateFilter
                    arrows={false}
                    showTime={true}
                    dateFormat="dd-MM-yyyy HH:mm:59"
                    selectedDate={endDate}
                    onChange={handleChangeEndDate}
                    wrapperClassName="react-datepicker-wrapper-full-width" /><br />

                <Button onClick={() => handleGeofence(startDate, endDate)} variant="success" size="sm" block>
                    <FormattedMessage id="GENERAL.FILTER"/>
                </Button>
                <hr />

                <Link className="btn btn-primary btn-block btn-sm" to={`/signals/${deviceId}`}>
                    <FormattedMessage id="ROUTES.SHOW_ROUTES"/>
                </Link>

                <Link className="btn btn-primary btn-block btn-sm" to={`/geofence-reports/${deviceId}`}>
                    <FormattedMessage id="QMENU.GEOFENCE_REPORTS"/>
                </Link>

                <Link className="btn btn-primary btn-block btn-sm disabled" to={`/geofence-reports-2/${deviceId}`}>
                    <FormattedMessage id="QMENU.GEOFENCE_REPORTS"/> - 2
                </Link>

                <Link className="btn btn-primary btn-block btn-sm" to={`/speed-report/${deviceId}`}>
                    <FormattedMessage id="USER_RIGHTS.speed"/>
                </Link>
            </Col>

            <Col style={{minHeight: 600 }} sm={9}>
                <TheftProtectionMap
                    zones={zones}
                    selectedDevice={device}
                    {...props} />

                <Row>
                    <Col sm={12}>
                        <Table striped className="table" responsive="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Tarih</th>
                                    <th>Giriş / Çıkış</th>
                                    <th>Adı</th>
                                    <th>Toplam Süre</th>
                                </tr>
                            </thead>
                            <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="12">
                                        <img src={require('../../Assets/images/horizontal-loading.gif').default} />
                                    </td>
                                </tr>
                                ) : (
                                    reports.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>{index}</td>
                                                <td>{moment(item.violation_date).format("DD-MM-YYYY H:mm:ss")}</td>
                                                <td>{item.violation_type == 1 ? "Giriş" : "Çıkış"}</td>
                                                <td>{item.zone_name}</td>
                                                <td>{item.violation_duration}</td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const mapStateToProps = function(state) {
    return {
      devices: state.deviceListReducer.devices
    }
  };

export default connect(mapStateToProps)(AreaControlReports);