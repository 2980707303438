import React, {useState} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {saveAs} from 'file-saver';
import Moment from 'moment';
import {pad} from '../../Assets/utils';
import Menu from '../Menu';
import DailyTrips from '../DailyTrips';
import '../index.css';

import SignalService from '../../../services/Signals';
import ReportService from '../../../services/Reports';
import CarService from '../../../services/Cars';
import Export from '../../../services/Export';
import DriverService from '../../../services/Drivers';

function DailyReport(props) {

    const deviceId = props.match.params.deviceId;
    const driverId = props.match.params.driverId;

    const [trips, setTrips] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [onlyPrivate, setOnlyPrivate] = useState(false);
    const [reportsWithDrivers, setReportsWithDrivers] = useState(false);
    const [showDeviceName, setShowDeviceName] = useState(false);
    const [total, setTotal] = useState({});
    const [start_mil, setStartMileage] = useState(0);
    const [end_mil, setEndtMileage] = useState(0);
    const [manuelTrip_, setManuelTrip] = useState(false);

    const signalService = new SignalService();
    const reportService = new ReportService();
    const carService = new CarService();
    const exportService = new Export();
    const driverService = new DriverService();

    const handleFilter = async (name,id,start_date,end_date) => {
        
        if (deviceId !== 0 || driverId !== 0) {

            if (props.match.url.includes('device')) {

                try {
                    await Promise.all([
                        driverService.getDrivers(),
                        reportService.getDeviceDailyTrips(id, start_date, end_date),
                      ])
                      .then(([driversResponse, reportResponse]) => {
                          getStartAndEndMileage(id, reportResponse.data);
                          setDailyTrips(driversResponse.data, reportResponse.data);
                          return reportResponse.data;
                      });

                    } catch (error) {
                }

            } 
            else if (props.match.url.includes('driver')) {

                try {
                    await reportService.getDriverDailyTrips(id,start_date,end_date)
                      .then(async(response) => {
                        setDailyTrips(response.data);
                        setLoading(false);
                        return response.data;
                      });
                    } catch (error) {
                }
            }
        } 
        else{
            alert(<FormattedMessage id="REPORTS.PICK_DEVICE_OR_DRIVER"/>);
        }

         //unmount
        return () => {
            reportService.getCancelToken().cancel();
        }
        
    };

    const handleManuelTrip = (manuelTrip) => {
        setManuelTrip(manuelTrip);
    };

     const getStartAndEndMileage = async (deviceId,trips) => {
        try {
            // console.log(trips[trips.length - 1]);

            Promise.all([
                signalService.getSignalBySignalId(deviceId,trips[0].start_signal_id),
                signalService.getSignalBySignalId(deviceId, trips[trips.length - 1].end_signal_id),
                carService.getCar(deviceId)
            ])
              .then(([startSignalResponse, endSignalResponse, carResponse])=> {
                  const start_signal = startSignalResponse.data;
                  const end_signal = endSignalResponse.data;
                  const car = carResponse.data;

                  setStartMileage(start_signal.mileage + car.car_mileage_at_install);
                  setEndtMileage(end_signal.mileage + car.car_mileage_at_install);
              });
      
          } catch (error) {}

    };

    const setDailyTrips = async (drivers, trips) => {
        let dayText = 'Gün';

        var duration, day, hour, min, sec, ms, trips_arr = [], day_hour, day_min, day_sec, day_duration,
            day_waiting_time,
            waiting_time, total_active_time, day_end, day_total, i, last_trip_of_the_day, total_private_km = 0,
            total_duration = 0, total_waiting_time = 0, day_km = 0, total_km = 0, total_duration_sec = 0,
            total_waiting_time_sec = 0,
            private_total_duration = 0, private_total_waiting_time = 0, private_total_duration_sec = 0,
            private_total_waiting_time_sec = 0,
            private_total_active_time = 0;

        if(trips.length > 0) {
            // reset all values
            day_end = trips[0].start_date;
            day_waiting_time = new Date('01/01/1970 00:00:00');
            day_duration = new Date('01/01/1970 00:00:00');
            total_km = total_waiting_time_sec = total_duration_sec = day = hour = min = sec = 0;
        }



        for (let i = 0; i < trips.length; i++) {
            if (day_end === trips[i].start_date) {
                // convert start and end time to date format
                trips[i]['start_time'] = new Date(trips[i]['start_time']);
                trips[i]['end_time'] = new Date(trips[i]['end_time']);
                if (i !== trips.length - 1) {

                    /* checking if next day equal current day
                     * if not equal it means this trip is the last trip of the day */
                    last_trip_of_the_day = (day_end !== trips[i + 1]['start_date']) ? 1 : 0;

                    if (last_trip_of_the_day === 0) {

                        waiting_time = new Date('01/01/1970 ' + trips[i]['waiting_time']);
                        trips[i]['waiting_time'] = waiting_time;

                        // calculate standby times for each day
                        day_waiting_time.setSeconds(day_waiting_time.getSeconds() + waiting_time.getSeconds());
                        day_waiting_time.setMinutes(day_waiting_time.getMinutes() + waiting_time.getMinutes());
                        day_waiting_time.setHours(day_waiting_time.getHours() + waiting_time.getHours());

                        // plus waiting times as second, for calculate total waiting time
                        total_waiting_time_sec += trips[i]['waiting_time_sec'];

                        // private total
                        if (trips[i]['private_trip'] === 2) {
                            private_total_waiting_time_sec += trips[i]['waiting_time_sec'];
                        }

                    } else {
                        trips[i]['waiting_time'] = "";
                    }
                } else {
                    trips[i]['waiting_time'] = "";
                }

                // calculate day km and total km
                day_km = day_km + trips[i]['lenght_km'];
                total_km += trips[i]['lenght_km'];

                // calculate trip times
                duration = new Date('01/01/1970 ' + trips[i]['duration']);
                trips[i]['duration'] = duration;

                // calculate trip times for each day
                day_duration.setSeconds(day_duration.getSeconds() + duration.getSeconds());
                day_duration.setMinutes(day_duration.getMinutes() + duration.getMinutes());
                day_duration.setHours(day_duration.getHours() + duration.getHours());

                // plus trip times as second, for calculate total trip time
                total_duration_sec += trips[i]['duration_sec'];

                // private total
                if (trips[i]['private_trip'] === 2) {
                    private_total_duration_sec += trips[i]['duration_sec'];
                }

                // check if trip jump to next day
                if (trips[i]['start_time'].getDate() !== trips[i]['end_time'].getDate()) {
                    trips[i]['jump'] = true;
                }

                // check if trip added manually and mark it
                if (trips[i]['user_daily_report_id']) {
                    trips[i]['class'] = 'warning';
                }

                // check if trip is private
                if (trips[i]['private_trip'] === 2) {
                    total_private_km += trips[i]['lenght_km'];
                }

                // default driver
                trips[i]['driver'] = {
                    "driver_first_name": ""
                };

                // driver
                if (drivers.length > 0) {
                    for (let j = 0; j < drivers.length; j++) {
                        if (trips[i]['driver_id'] === drivers[j]['driver_id']) {
                            trips[i]['driver'] = drivers[j];
                            break;
                        }
                    }
                }

                trips[i]['start_time'] = trips[i]['start_time'] !== '' ? Moment(new Date(trips[i]['start_time'])).format("DD/MM/YYYY HH:mm:ss") : '';
                trips[i]['end_time'] = trips[i]['end_time'] !== '' ? Moment(new Date(trips[i]['end_time'])).format("DD/MM/YYYY HH:mm:ss") : '';
                trips[i]['waiting_time'] = trips[i]['waiting_time'] !== '' ? Moment(new Date(trips[i]['waiting_time'])).format("DD/MM/YYYY HH:mm:ss") : '';
                trips[i]['duration'] = trips[i]['duration'] !== '' ? Moment(new Date(trips[i]['duration'])).format("DD/MM/YYYY HH:mm:ss") : '';

                trips_arr.push(trips[i]);

            } else { // current day is finished, calculating day totals
                day_km = Number((day_km).toFixed(2));

                day_total = {
                    'dayTotal': true,
                    'start_date': day_end,
                    'duration':  Moment(day_duration).format("DD/MM/YYYY HH:mm:ss"),
                    'waiting_time':  Moment(day_waiting_time).format("DD/MM/YYYY HH:mm:ss"),
                    'lenght_km': day_km,
                    'class': 'day-total'
                };
                trips_arr.push(day_total);

                // reset and set new values
                day_hour = day_min = day_sec = day_km = 0;
                day_duration = new Date('01/01/1970 00:00:00');
                day_waiting_time = new Date('01/01/1970 00:00:00');
                day_end = trips[i]['start_date'];
                i = i - 1;
            }

            if (i === trips.length - 1) { // trips finished, calculate last day totals
                day_km = Number((day_km).toFixed(2));

                day_total = {
                    'dayTotal': true,
                    'start_date': day_end,
                    'duration':  Moment(day_duration).format("DD/MM/YYYY HH:mm:ss"),
                    'waiting_time':  Moment(day_waiting_time).format("DD/MM/YYYY HH:mm:ss"),
                    'lenght_km': day_km,
                    'class': 'day-total'
                };
                trips_arr.push(day_total);

                day_hour = day_min = day_sec = day_km = 0;
                day_duration = new Date('01/01/1970 00:00:00');
                day_waiting_time = new Date('01/01/1970 00:00:00');
                day_end = "";
            }
        }



        ////////////////////////////// NORMAL TRIPS /////////////////////////////////////////////////////
        // calculate total duration
        day = Math.floor(total_duration_sec / (3600 * 24));
        hour = Math.floor((total_duration_sec / 3600) % 24);
        min = Math.floor((total_duration_sec / 60) % 60);
        sec = Math.floor(total_duration_sec % 60);
        total_duration = day + " " + dayText + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total waiting time
        day = Math.floor(total_waiting_time_sec / (3600 * 24));
        hour = Math.floor((total_waiting_time_sec / 3600) % 24);
        min = Math.floor((total_waiting_time_sec / 60) % 60);
        sec = Math.floor(total_waiting_time_sec % 60);
        total_waiting_time = day + " " + dayText + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total active time
        day = Math.floor((total_duration_sec + total_waiting_time_sec) / (3600 * 24));
        hour = Math.floor(((total_duration_sec + total_waiting_time_sec) / 3600) % 24);
        min = Math.floor(((total_duration_sec + total_waiting_time_sec) / 60) % 60);
        sec = Math.floor((total_duration_sec + total_waiting_time_sec) % 60);
        total_active_time = day + " " + dayText + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);


        ////////////////////////////// PRIVATE TRIPS /////////////////////////////////////////////////////
        // calculate total duration
        day = Math.floor(private_total_duration_sec / (3600 * 24));
        hour = Math.floor((private_total_duration_sec / 3600) % 24);
        min = Math.floor((private_total_duration_sec / 60) % 60);
        sec = Math.floor(private_total_duration_sec % 60);
        private_total_duration = day + " " + dayText + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total waiting time
        day = Math.floor(private_total_waiting_time_sec / (3600 * 24));
        hour = Math.floor((private_total_waiting_time_sec / 3600) % 24);
        min = Math.floor((private_total_waiting_time_sec / 60) % 60);
        sec = Math.floor(private_total_waiting_time_sec % 60);
        private_total_waiting_time = day + " " + dayText + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total active time
        day = Math.floor((private_total_duration_sec + private_total_waiting_time_sec) / (3600 * 24));
        hour = Math.floor(((private_total_duration_sec + private_total_waiting_time_sec) / 3600) % 24);
        min = Math.floor(((private_total_duration_sec + private_total_waiting_time_sec) / 60) % 60);
        sec = Math.floor((private_total_duration_sec + private_total_waiting_time_sec) % 60);
        private_total_active_time = day + " " + dayText + " " + pad(hour) + ":" + pad(min); // + ":" + pad(sec);


        let total = {};
        total.km = Number((total_km).toFixed(2));
        total.private_km = Number((total_private_km).toFixed(2));
        total.duration = total_duration;
        total.waiting_time = total_waiting_time;
        total.active = total_active_time;
        total.private_duration = private_total_duration;
        total.private_waiting_time = private_total_waiting_time;
        total.private_active = private_total_active_time;

        setTotal(total);
        setTrips(trips_arr);
        setLoading(false);
    };

    const handleChangeOnlyPrivate = (e) => {
        setOnlyPrivate(e.target.checked);
    };
    
    const handleChangeReportswithDrivers = (e) => {
        setReportsWithDrivers(e.target.checked);
    };
    
    const handleChangeShowDeviceName = (e) => {
        setShowDeviceName(e.target.checked);
    };

    // onclick handler
    const handleExport = async (selectedDateStart, selectedDateEnd, fileExtension) => {
        const start = Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss");
        const end = Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss");

        if (deviceId > 0)
            await exportService.exportDailyReport(deviceId, start, end, trips, fileExtension)
              .then((response) => {
                  let blob = new Blob([response.data]);
                  saveAs(blob, `Günlük_Rapor.` + fileExtension);
              });
        else
            await exportService.exportDriverDailyReport(driverId, start, end, trips, fileExtension)
              .then((response) => {
                  let blob = new Blob([response.data]);
                  saveAs(blob, 'Günlük_Rapor.' + fileExtension);
              });
    };

    return (
        <div className="reports">
            <Menu
              history={props.history}
              deviceId={deviceId}
              driverId={driverId}
              onFilter={handleFilter}
              manuel_trip = {manuelTrip_}
              only_private={onlyPrivate}
              handleChangeOnlyPrivate={handleChangeOnlyPrivate}
              handleChangeReportswithDrivers={handleChangeReportswithDrivers}
              handleChangeShowDeviceName={handleChangeShowDeviceName}
              handleExport={handleExport}
              routerParams={props.match}
              mainReportType = {'daily'}
            />
            {
                !isLoading
                  ?
                  <DailyTrips
                    manuelTrip ={handleManuelTrip}
                    only_private={onlyPrivate}
                    reports_with_driver={reportsWithDrivers}
                    show_device_name={showDeviceName}
                    trips={trips}
                    total={total}
                    start_mil={start_mil}
                    end_mil={end_mil}
                    reportType={props.match}/>
                    :
                  null
            }
        </div>
  );

}
const mapStateToProps = function(state) {
    return {
      drivers: state.driverListReducer.drivers
    }
};
export default connect(mapStateToProps)(DailyReport);







