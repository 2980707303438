import axios from 'axios';
import querystring from 'querystring';
import config from '../config';

class Auth {

  getToken(username, password) {

    const options = {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };

    return axios.post(config.apiUrl + 'o/token/', querystring.stringify({
      username: username,
      password: password,
      grant_type: "password",
      client_id : config.client_id,
      client_secret : config.client_secret
    }), options);
  }

}

export default Auth;