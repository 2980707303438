import React from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import BatteryStatus from '../../Commons/batteryStatus';
import MapLinkGoogle from '../../Commons/mapLinkGoogle';
import MapLinkExport from '../../Commons/mapLinkExport';
import SignalInputs from '../../Commons/signalInputs';
import {toShow, toFixed, handleScroll} from '../../Assets/utils';
import Moment from 'moment';
import './index.css';

function DetailertList(props) {
    const device = props.data;
    const scroll_status = sessionStorage.getItem('scroll_status');
    let gps = device.nr_sat > 12 ? 12 : device.nr_sat; 

    const handleTripType = (trip_status) => {
        switch (trip_status) {
            case 0: 
                return <FormattedMessage id="REPORTS.TRIP_TYPE_0"/>;
            case 1:
                return <FormattedMessage id="REPORTS.TRIP_TYPE_1"/>;
            case 2:
                return <FormattedMessage id="REPORTS.TRIP_TYPE_2"/>;
            case 3:
                return <FormattedMessage id="REPORTS.TRIP_TYPE_3"/>;
            default:
                return <FormattedMessage id="REPORTS.TRIP_TYPE_0"/>;
        }
    };

    return (
        <div ref={props.scroll_ref[device.device_id]} style={{opacity: device.blink  ||  props.blur === 0.3 ? 0.3 : scroll_status === 'true' ? handleScroll(device.device_id, props.scroll_ref) : 1}} className="detailert" key={device.device_id}>
            <div className="device-modal"></div>
            <div className="updating-device">
                <img src={require("../../Assets/images/updating.gif").default} width="36" alt="updating"/>
            </div>
            <div className="left">
                <div className="name">
                    <Link to={`/home/route/${device.device_id}`}>{toShow(device.name, 11)}</Link>
                </div>
                <div className="car-driver">
                    <Link className="icon" to={`/home/location/${device.device_id}`}>
                        <img src={require('../../Assets/images/device-icons/car_' + device.type +'.gif').default}  alt="" width="25"/>
                    </Link>
                    <Link className="driver" to={`/electronic-reports/drivers/device/${device.device_id}`}>
                        <img src={require("../../Assets/images/man.png").default} alt="" width="14"/>
                        {device.driver_last_name !== '' ? device.driver_last_name : '...'}
                    </Link>
                    <div className="clearfix"></div>
                </div>
                <div className="trip-status">
                    {handleTripType(device.trip_status)}
                </div>
                <div>
                    {
                        (device.immobilizer_status) ?
                        <img style={{width:14, height:14}} src={require("../images/lock-icon.png").default} alt=""/>
                        : null
                    }
                </div>
            </div>

            <div className="right">
                <div className="top">
                    <div className="signal-date">
                        <Link to={`/signals/${device.device_id}`}>{Moment(device.latest_geocode_sent_at).format("DD/MM/YYYY HH:mm:ss")}</Link>
                    </div> 
                    <div className="ign">
                        <img src={device.ignition_status === 'on' ? require('./images/ign_on.png').default : require('./images/ign_off.png').default} alt=""/>
                    </div>
                    <div className="clearfix"></div>
                </div>
                
                <div className="middle">
                    <div className="gps-signal">
                        <img src={require('./images/gps_'+ gps +'.png').default} alt="" title="GPS"/>
                    </div>
                    <div className="gsm-signal">
                        <img src={require('./images/gsm_'+ device.signal_strenght +'.png').default} alt="" title={device.signal_strenght}/>
                    </div>
                    <div className="speed">
                        <Link to={`/home/speed-route/${device.device_id}`}>
                            <span className="value">{toFixed(device.speed_km, 0)}</span>km/h
                        </Link>
                    </div>
                    <div className="chart">
                        <Link to={`/home/graphs-reports/${device.device_id}`}>
                            <img src={require("../images/chart.png").default} alt=""/>
                        </Link>
                    </div>
                </div>
                <div className="clearfix"></div>

                <div className="bottom">
                    <div className="inputs">
                        <SignalInputs signal={device}/>
                    </div>
                    <div className="battery">
                    {
                        (device.internal_battery === 1) ?
                        <BatteryStatus type={device.device_type} voltage_data={device.battery_voltage} list='standart'/>
                        : null
                    }
                    </div>
                    <div className="map-link2">
                        <MapLinkExport deviceId={device.device_id} listType='detailert'/>
                    </div>
                    <div className="map-link">
                        <MapLinkGoogle lat={device.dec_lat} long={device.dec_long} deviceId={device.device_id} listType='detailert'/>
                    </div>
                    <div className="mileage">
                        <Link to={`/home/daily-report/${device.device_id}`}>
                            <span className="value">{toFixed(device.daily_mileage, 0)}</span>km
                        </Link>
                    </div>
                </div>
            </div>        
            <div className="clearfix"></div>
        </div>
    );
}

export default DetailertList;
