import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts)
}

function FuelConsumptionChart(props) {

  const startDate = `${props.startDate.getDate()}-${props.startDate.getMonth()}-${props.startDate.getFullYear()}`;
  const endDate = `${props.endDate.getDate()}-${props.endDate.getMonth()}-${props.endDate.getFullYear()}`;

  const options = {
    chart: {
      type: 'line'
    },
    title: {
      text: `${startDate} - ${endDate}`
    },
    yAxis: {
      title: {
        text: props.yAxisTitle
      },
      min: 0,
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    xAxis: {
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      backgroundColor: '#ffffff',
      xDateFormat: '%d-%m-%Y',
      borderWidth: 2,
      borderRadius: 7,
      headerFormat: `<span style='font-size:10px;'>{point.key}</span><table>`,
      pointFormat: `<tr><td style="padding:0;color:{series.color}"><b>{point.y:.2f} lt/100km</b></td></tr>`,
      footerFormat: '</table>',
      useHTML: true,
      crosshairs: {
        dashStyle: 'dash'
      },
    },
    series: [{
      name: 'Gerçek Tüketim (Hesaplanan)',
      data: [
        [props.startDate, props.calculatedAvgFuel],
        [props.endDate, props.calculatedAvgFuel]
      ],
      marker: { enabled: true },
      color: '#337ab7',
    }, {
      name: 'Yakıt Tüketimi Litre/100 km',
      data: [
        [props.startDate, props.avgFuel],
        [props.endDate, props.avgFuel]
      ],
      marker: { enabled: true },
      color: '#eea236',
    }],
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG'
          ]
        }
      }
    },
    credits: {
      enabled: false
    }

  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

FuelConsumptionChart.propTypes = {
  yAxisTitle: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  calculatedAvgFuel: PropTypes.number.isRequired,
  avgFuel: PropTypes.number.isRequired
};

export default FuelConsumptionChart;