import React from 'react';
import {Link} from 'react-router-dom';
import SignalAddress from '../../Commons/signalAddress';
import BatteryStatus from '../../Commons/batteryStatus';
import MapLinkGoogle from '../../Commons/mapLinkGoogle';
import SignalInputs from '../../Commons/signalInputs';
import {toShow, toFixed, handleScroll} from '../../Assets/utils';
import './index.css';

function StandartBigList(props) {
    const device = props.data;
    const scroll_status = sessionStorage.getItem('scroll_status');

    return (
      <div ref={props.scroll_ref[device.device_id]} style={{opacity: device.blink  ||  props.blur === 0.3 ? 0.3 : 1}} className="standart-big">
        <div key={device.device_id} style={{opacity:props.blur === 0.3 ? 0.3 : scroll_status === 'true' ? handleScroll(device.device_id, props.scroll_ref) : 1}}>
            <div className="device-modal" />
            <div className="updating-device">
                <img src={require("../../Assets/images/updating.gif").default} width="36" alt="updating"/>
            </div>
            <div className="header">
                <div className="header-left">
                    <Link to={`/signals/${device.device_id}`}></Link>
                </div>
                <div className="header-right">
                    <div className="mileage">
                        <Link className="dm" to={`/home/daily-report/${device.device_id}`}>
                            <span className="value">{toFixed(device.daily_mileage, 1)}</span>km
                        </Link>
                    </div>
                    <div className="ign">
                        <a href="#" style={{fontWeight:'bold'}}>+</a>
                        <img src={device.ignition_status === 'on' ? require('./images/standart_big_ign_on.png').default : require('./images/standart_big_ign_off.png').default}  width="24" height="9" border="0" alt=""/>
                    </div>
                </div>
                <Link className="name" to={`/home/route/${device.device_id}`}>{toShow(device.name, 11)}</Link>
            </div>
            <div className="body">
                <div className="body-left">
                    <Link to={`/vehicle-service/${device.device_id}`}>S</Link>
                    <Link to={`/home/daily-report/${device.device_id}`}>B</Link>
                    <Link to={`/home/monthly-report/${device.device_id}`}>M</Link>
                </div>
                <div className="body-car">
                    <Link to={`/home/location/${device.device_id}`}>
                        <img src={require('../../Assets/images/device-icons/car_' + device.type +'.gif').default}  alt="" width="25"/>
                    </Link>
                </div>
                <div className="body-middle">
                    <div className="speed">
                        <Link to={`/home/speed-route/${device.device_id}`}>
                            <span className="value">{toFixed(device.speed_km, 0)}</span>km/h
                        </Link>
                    </div>
                    <Link className="body-live" to={`/home/live/${device.device_id}`}>Live</Link>
                    <div className="clearfix" />

                    <div className="body-inputs">
                        <div className="chart">
                            <Link to={`/home/graphs-reports/${device.device_id}`}>
                                <img src={require("../images/chart.png").default} alt=""/>
                            </Link>
                        </div>

                        <div className="map-link">
                            <MapLinkGoogle lat={device.dec_lat} long={device.dec_long} deviceId={device.device_id} listType='standart'/>
                        </div>
                        <div className="inputs">
                            <SignalInputs signal={device}/>
                        </div>
                    </div>
                </div>

                <div className="body-middle-bot">
                    <div className="body-driver">
                        <Link to={`/electronic-reports/drivers/device/${device.device_id}`}>
                            <img src={require("../../Assets/images/man.png").default} alt="" width="14"/>
                            {device.driver_last_name !== '' ? device.driver_last_name : '...'}
                        </Link>
                    </div>
                    <div className="body-lock">
                    </div>
                </div>
                <div className="clearfix" />
            </div>
            <div className="clearfix" />
            <div className="footer">
                <ul>
                    <li className="footer-small"><Link to={`/home/anim-route/${device.device_id}`}>P</Link></li>
                    <li className="footer-small">
                        <Link to={`/home/auto-pilot/${device.device_id}`}>A</Link>
                    </li>
                    {/* <li className="footer-small"><a href="#">K</a></li> */}
                    <li>
                    {
                        (device.immobilizer_status) ?
                        <img style={{width:10, height:10, margin: '0px 0px 0px 0px'}} src={require("../images/lock-icon.png").default} alt=""/>
                        : null
                    }
                        <span style={{width:10, height:10, margin: '0px 0px 0px 0px'}}>&nbsp;</span>
                    </li>
                    <li style={{paddingTop:1}}><a href="#">{device.day_start}</a></li>
                    <li style={{borderRight:0, paddingTop:1}}><a href="#">{device.day_stop}</a></li>
                </ul>
                <div className="clearfix" />
                <div className="footer-bot">
                    <div style={{color: device.changed ? '#688509': '#101010'}} className={device.internal_battery === 1 ? "address-left" : "address"}>
                        <SignalAddress lat={device.dec_lat} lon={device.dec_long} deviceId={device.device_id} noGps={device.no_gps} hideStatus={device.hide_status}/>
                    </div>

                    <div className="battery">
                        {
                            (device.internal_battery === 1) ?
                            <BatteryStatus type={device.device_type} voltage_data={device.battery_voltage} list='standart'/>
                            : null
                        }
                    </div>

                    <div className="clearfix" />
                </div>
            </div> 
        </div>
    </div>
    
  );

}

export default StandartBigList;