import './index.css';

import React, {useState, useRef, useEffect} from 'react';
import HMap from './hmap';


function LocationsMap(props) {
	const [size, setSize] = useState([0, 800]);
	const longlat = [27.1699050, 38.4310990];
	const mapInstance = useRef(null);

	useEffect(() => {

		// initialize map - only once
		if (mapInstance.current === null) {
			mapInstance.current = new HMap(longlat);
			mapInstance.current.register("onpointdrawend", props.onDrawEnd);
			mapInstance.current.initMap(16, false, { drawPoint: true, imgSrc: require('../Assets/images/locations/0.png').default });
		}

		// clear previous points if exists
		mapInstance.current.getVectorSource().clear();

		props.maps.forEach(item => mapInstance.current.setLayer(item, props.active));

		// mark points
		props.points.forEach((point) => {
			if (point.dec_long === 0.0 || point.dec_lat === 0.0)
				return;

			let markerIcon = require(`../Assets/images/locations/${point.icon_type}.png`).default;
			mapInstance.current.addLocation([point.dec_long, point.dec_lat], markerIcon, {locationName: point.point_name});
		});

		// centralize
		if (mapInstance) {
			mapInstance.current.fitMap();
		}

	}, [props.points, props.maps, props.active]);

	return (
		<>
			<div id="map" style={{ width: "100%", height: `${size[1]}px` }}/>
			<div id="popup" className="ol-popup">
				<a href="#" id="popup-closer" className="ol-popup-closer"/>
				<div id="popup-content"/>
			</div>
		</>
	);
}

LocationsMap.defaultProps = {
    active: "horizont",
    maps: ["horizont"]
}

export default LocationsMap;
