import React from 'react';
import {Link} from 'react-router-dom';
import {toShow, toFixed, handleScroll} from '../../Assets/utils';
import SignalAddress from '../../Commons/signalAddress';
import BatteryStatus from '../../Commons/batteryStatus';
import MapLinkGoogle from '../../Commons/mapLinkGoogle';
import MapLinkExport from '../../Commons/mapLinkExport';
import SignalInputs from '../../Commons/signalInputs';
import './index.css';


function KleinList(props) {
  const device = props.data;
  const scroll_status = sessionStorage.getItem('scroll_status');

  return (
    <div ref={props.scroll_ref[device.device_id]} style={{opacity: device.blink  ||  props.blur === 0.3 ? 0.3 : 1}} className="klein">
      <div key={device.device_id} style={{opacity:props.blur === 0.3 ? 0.3 : scroll_status === 'true' ? handleScroll(device.device_id, props.scroll_ref) : 1}}>
        <div className="klein-device-modal"> </div>
        <div className="klein-updating-device">
          <img src={require("../../Assets/images/updating.gif").default} width="36" alt="updating"/>
        </div>
        <div className="klein-left">
          <a href='#'> </a>
        </div>
        <div className="klein-right">
          <div className="klein-top">
            <div className="klein-icon">
              <Link to={`/home/location/${device.device_id}`}>
                <img src={require('../../Assets/images/device-icons/car_' + device.type +'.gif').default}  alt="" width="25"/>
              </Link>
            </div>
            <div className="klein-name">
              <Link className="name" to={`/home/route/${device.device_id}`}>{toShow(device.name, 8)}</Link>
            </div>
            <div className="klein-mileage">
              <Link to={`/home/daily-report/${device.device_id}`}>
                {toFixed(device.daily_mileage, 0)}km
              </Link>
            </div>
            <div className="klein-speed">
              <Link to={`/home/speed-route/${device.device_id}`}>
                <span className="value">{toFixed(device.speed_km, 0)}</span>km/h
              </Link>
            </div>
            <div className="klein-live">
              <Link to={`/home/live/${device.device_id}`}>Live<sup>1</sup></Link>
            </div>
          </div>
          <div className="klein-bottom">
            <div className="klein-driver">
              <Link to={`/electronic-reports/drivers/device/${device.device_id}`}>
                {device.driver_last_name !== '' ? toShow(device.driver_last_name, 12) : '...'}
              </Link>
            </div>
            {
              (device.immobilizer_status) ?
                (
                  <div className="klein-tools">
                    <img src={require('../images/lock-icon.png').default} width="14px" height="14px" alt="lock-icon"/>
                  </div>)
                :
                (null)
            }
            <div className="klein-tools">
              <MapLinkGoogle lat={device.dec_lat} long={device.dec_long} deviceId={device.device_id} listType='klein'/>
            </div>
            <div className="klein-tools">
              <MapLinkExport deviceId={device.device_id} listType='klein'/>
            </div>
            <div className="klein-tools">
              <Link to={`/home/graphs-reports/${device.device_id}`}>
                <img src={require("../images/chart.png").default} alt="chart"/>
              </Link>
            </div>
            <div className="klein-inputs">
              <SignalInputs signal={device}/>
            </div>
          </div>
        </div>
        <div className="klein-ign">
          <img src={require('./images/ign_' + device.ignition_status + '.png').default} alt='ignition_status'/>
        </div>
      </div>
      <div style={{color: device.changed ? '#688509': '#101010'}} className="address">
        <SignalAddress lat={device.dec_lat} lon={device.dec_long} deviceId={device.device_id} noGps={device.no_gps} hideStatus={device.hide_status}/>
      </div>
      <div className="klein-battery">
        {
          (device.internal_battery === 1) ?
            <BatteryStatus type={device.device_type} voltage_data={device.battery_voltage} list='klein'/>
            : null
        }
      </div>
    </div>
  );
}

export default KleinList;
