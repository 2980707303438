import React from 'react';
import {Link} from "react-router-dom";
import './index.css';
import {toShow, toFixed, handleScroll} from '../../Assets/utils';

function MiniList(props) {
  const device = props.data;
  const scroll_status = sessionStorage.getItem('scroll_status');

  return (
    <div ref={props.scroll_ref[device.device_id]} style={{opacity: device.blink  ||  props.blur === 0.3 ? 0.3 : 1}} className="mini">
      <div key={device.device_id} style={{opacity:props.blur === 0.3 ? 0.3 : scroll_status === 'true' ? handleScroll(device.device_id, props.scroll_ref) : 1}}>
        <div className="device-modal"></div>
        <div className="updating-device">
            <img src={require("../../Assets/images/updating.gif").default} width="36" alt="updating"/>
        </div>
        <div className="left">
            <a> </a>
        </div>
        <div className="right">
            <div className="top">
                <div className="name">
                    <Link className="name" to={`/home/route/${device.device_id}`}>{toShow(device.name, 8)}</Link>
                </div>
                <div className="start">{toShow(device.day_start,5)}</div>
                <div className="mileage">
                    <Link to={`/home/daily-report/${device.device_id}`}>
                        <span className="value">{toFixed(device.daily_mileage, 0)}</span>km
                    </Link>
                </div>
            </div>
            <div className="bottom">
                <div className="driver">
                    <Link to={`/electronic-reports/drivers/device/${device.device_id}`}>
                        {toShow(device.driver_last_name !== '' ? device.driver_last_name : '...', 6)}
                    </Link>
                </div>
                <div className="stop">{toShow(device.day_stop,5)}</div>
                <div className="ign">
                    <img src={device.ignition_status === 'on' ? require('./images/ign_on.png').default : require('./images/ign_off.png').default} alt=""/>
                </div>
                <div className="color-route"><Link style={{fontWeight: 'bold'}} to={`/home/color-route/${device.device_id}`}>+</Link></div>
            </div>
        </div>
        <div className="clearfix"></div>
        </div>
    </div>
    
  );

}

export default MiniList;
