import React, { useState, useEffect } from 'react';
import {Col, Row, Form, Button} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import DatePicker from "react-datepicker";
import moment from 'moment';

function Step1(props) {
    const [disabled, setDisabled] = useState(true);

    const [days, setDays] = useState({
        "day_1_active": 0,
        "day_2_active": 0,
        "day_3_active": 0,
        "day_4_active": 0,
        "day_5_active": 0,
        "day_6_active": 0,
        "day_7_active": 0,
        "day_1_start": "",
        "day_2_start": "",
        "day_3_start": "",
        "day_4_start": "",
        "day_5_start": "",
        "day_6_start": "",
        "day_7_start": "",
        "day_1_end": "",
        "day_2_end": "",
        "day_3_end": "",
        "day_4_end": "",
        "day_5_end": "",
        "day_6_end": "",
        "day_7_end": "",
        "saveAll": 0
    });

    const handleChangeDay = e => {
        setDays({...days, [e.target.id]: e.target.checked ? 1 : 0});
    };

    useEffect(() => {
        if (props.deviceId) {
            setDays({...days, ...props.data});
            setDisabled(false);
        }
    }, [props.data]);

    return (
        <>
            <div className="theft-title"><FormattedMessage id="THEFT.STEP1"/></div>
            <Row>
                <Col xs={6}>
                    <b><FormattedMessage id="THEFT.ALARM"/></b>
                </Col>
                <Col xs={3}>
                    <b><FormattedMessage id="GENERAL.START"/></b>
                </Col>
                <Col xs={3}>
                    <b><FormattedMessage id="GENERAL.STOP"/></b>
                </Col>
            </Row>
            <Row style={{borderTop: '1px solid #ccc', marginTop: 5, paddingTop: 5}}>
                <Col xs={6}>
                    <Form.Check
                        type="switch"
                        id="day_1_active"
                        onChange={handleChangeDay}
                        checked={Boolean(days.day_1_active)}
                        disabled={disabled}
                        label={<FormattedMessage id="DAYS.MONDAY"/>}
                    />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_1_start} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_1_start: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_1_end} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_1_end: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
            </Row>
            <Row style={{borderTop: '1px solid #ccc', marginTop: 5, paddingTop: 5}}>
                <Col xs={6}>
                    <Form.Check
                        type="switch"
                        id="day_2_active"
                        onChange={handleChangeDay}
                        checked={Boolean(days.day_2_active)}
                        disabled={disabled}
                        label={<FormattedMessage id="DAYS.TUESDAY"/>}
                    />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_2_start} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_2_start: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_2_end} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_2_end: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
            </Row>
            <Row style={{borderTop: '1px solid #ccc', marginTop: 5, paddingTop: 5}}>
                <Col xs={6}>
                    <Form.Check
                        type="switch"
                        id="day_3_active"
                        onChange={handleChangeDay}
                        checked={Boolean(days.day_3_active)}
                        disabled={disabled}
                        label={<FormattedMessage id="DAYS.WEDNESDAY"/>}
                    />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_3_start} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_3_start: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_3_end} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_3_end: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
            </Row>
            <Row style={{borderTop: '1px solid #ccc', marginTop: 5, paddingTop: 5}}>
                <Col xs={6}>
                    <Form.Check
                        type="switch"
                        id="day_4_active"
                        onChange={handleChangeDay}
                        checked={Boolean(days.day_4_active)}
                        disabled={disabled}
                        label={<FormattedMessage id="DAYS.THURSDAY"/>}
                    />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_4_start} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_4_start: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_4_end} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_4_end: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
            </Row>
            <Row style={{borderTop: '1px solid #ccc', marginTop: 5, paddingTop: 5}}>
                <Col xs={6}>
                    <Form.Check
                        type="switch"
                        id="day_5_active"
                        onChange={handleChangeDay}
                        checked={Boolean(days.day_5_active)}
                        disabled={disabled}
                        label={<FormattedMessage id="DAYS.FRIDAY"/>}
                    />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_5_start} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_5_start: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_5_end} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_5_end: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
            </Row>
            <Row style={{borderTop: '1px solid #ccc', marginTop: 5, paddingTop: 5}}>
                <Col xs={6}>
                    <Form.Check
                        type="switch"
                        id="day_6_active"
                        onChange={handleChangeDay}
                        checked={Boolean(days.day_6_active)}
                        disabled={disabled}
                        label={<FormattedMessage id="DAYS.SATURDAY"/>}
                    />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_6_start} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_6_start: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_6_end} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_6_end: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
            </Row>
            <Row style={{borderTop: '1px solid #ccc', marginTop: 5, paddingTop: 5}}>
                <Col xs={6}>
                    <Form.Check
                        type="switch"
                        id="day_7_active"
                        onChange={handleChangeDay}
                        checked={Boolean(days.day_7_active)}
                        disabled={disabled}
                        label={<FormattedMessage id="DAYS.SUNDAY"/>}
                    />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_7_start} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_7_start: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
                <Col xs={3}>
                    <DatePicker value={days.day_7_end} showTimeSelect
                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                        onChange={date => setDays({...days, day_7_end: moment(date).format("HH:mm")})}
                        disabled={disabled}
                        dateFormat="HH:mm" timeFormat="HH:mm" />
                </Col>
            </Row>
            <Row style={{borderTop: '1px solid #ccc', marginTop: 5, paddingTop: 5}}>
                <Col xs={12}>
                    <Form.Check
                        type="switch"
                        id="saveAll_step1"
                        onChange={(event) => setDays({...days, saveAll:event.target.checked ? 1:0})}
                        checked={Boolean(days.saveAll)}
                        disabled={disabled}
                        label={<FormattedMessage id="USER_RIGHTS.SAVEALL"/>}
                    /><br />
                    <Button onClick={() => props.onSubmit(days)}
                        disabled={disabled}
                        block variant="success"><FormattedMessage id="GENERAL.SAVE"/></Button>
                </Col>
            </Row>
        </>
    )
};

export default Step1;