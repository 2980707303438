import React, {useState, useEffect} from 'react';
import HomeNav from './nav';
import DateFilter from '../Commons/dateFilter';
import {Table, Row, Col} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {getDay, getDayName, getMonthName, getTime} from '../Assets/utils';
import moment from 'moment';

import DailyDrivingTimes from '../Charts/dailyDrivingTimes';
import DailyDrivingKm from '../Charts/dailyDrivingKm';
import DailySignals from '../Charts/dailySignals';

import Reports from '../../services/Reports';
import Signals from '../../services/Signals';

function Graphs(props) {
  
  const deviceId = props.match.params.deviceId;
  const today = new Date();
  const startDay = moment(today).subtract(7, 'days');
  const [trips, setTrips] = useState([]);
  const [signals, setSignals] = useState([]);
  const [startDate, setStartDate] = useState(new Date(startDay));
  const [endDate, setEndDate] = useState(today);
  
  const reportService = new Reports();
  const signalService = new Signals();

  const fetchTrips = async () => {
    const tripsResult = await reportService.getDeviceMonthlyTrips(deviceId, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));
    setTrips(tripsResult.data);
  };
  
  const fetchSignals = async () => {
    const signalsResult = await signalService.getSignals(deviceId, moment(startDate).format("YYYY-MM-DD"));
    setSignals(signalsResult.data[0].signals);
  };

  const handleChangeStartDate = date => {
    setStartDate(date);
  };

  const handleChangeEndDate = date => {
    setEndDate(date);
  };

  useEffect(() => {
    fetchTrips();
    fetchSignals();
  }, [deviceId, startDate, endDate]);

  return (
    <>
      <HomeNav deviceId={deviceId} active="graphs" />
      <br />
      <center>
        Başlangıç: <div style={{display: "inline-block"}}>
          <DateFilter onChange={handleChangeStartDate} selectedDate={startDate} />
        </div>
        &nbsp; &nbsp; 
        Bitiş: <div style={{display: "inline-block"}}>
          <DateFilter onChange={handleChangeEndDate} selectedDate={endDate} />
        </div>
      </center>
      <hr />

      <Row style={{margin: 0}}>
        <Col md={12}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <td><FormattedMessage id="GENERAL.DAY"/>:</td>
                {
                  trips.map((trip, index) => {
                    return (
                      <td key={index}>
                        {getDay(trip.start_time)} {getMonthName(trip.start_time)}<br />
                        {getDayName(trip.start_time)}
                      </td>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><FormattedMessage id="GENERAL.START"/>:</td>
                {
                  trips.map((trip, index) => {
                    return (
                      <td key={index}>
                        {getTime(trip.start_time)}
                      </td>
                    )
                  })
                }
              </tr>
              <tr>
                <td><FormattedMessage id="GENERAL.STOP"/>:</td>
                {
                  trips.map((trip, index) => {
                    return (
                      <td key={index}>
                        {getTime(trip.end_time)}
                      </td>
                    )
                  })
                }
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row style={{margin: 0, marginTop: 20}}>
        <Col md={6}>
          <DailyDrivingTimes data={trips} />
        </Col>
        <Col md={6}>
          <DailyDrivingKm data={trips} />
        </Col>
      </Row>

      <Row style={{margin: 0, marginTop: 20}}>
        <Col md={12}>
          <DailySignals data={signals} />
        </Col>
      </Row>
    </>
  );
}

export default Graphs;
