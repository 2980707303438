import React, {useState, useEffect} from 'react';
import { FormattedMessage } from 'react-intl';
import HomeNav from './nav';
import MessageWindow from '../Commons/messageWindow';

import AutoPilotService from './../../services/AutoPilot';
import CarService from './../../services/Cars';
import UserService from './../../services/Users';

const dataInitialState = {
  autoPilot: {
    warning_at_daily_mileage: 0, // step 1
    warning_at_speed_km_1: 0, // step 2
    warning_at_speed_km_2: 0, // step 3
    warning_at_speed_km_3: 0, // step 4
    no_gps_alert: 0, // step 6
    warning_at_trip_duration: 0, // step 7
    warning_park_duration_start: 0, // step 8
    warning_park_duration_end: 0, // step 8
    warning_at_park_duration: 0, // step 8
    life_alert: 0, // step 9
    warning_at_ignitions: 0, // step 10
    daily_report: 0, // step 13
    speed_report: 0, // step 13
    low_wolt: 0, // step 12
    notification_hour: 0, // step 14
    saveAll: 0, // step 13
    beacon_device: 0 // step 15
  },
  car: {
    sms_ignition: 0, // step 5
    sms_contact: 0, // step 11
    email_1: "", // step 12
    email_2: "", // step 12
    email_3: "", // step 12
    phone_area_code: "", // step 15
    phone_area_code_2: "", // step 15
    phone_number: "", // step 15
    phone_number_2: "", // step 15
    tow_truck_notification: 0, // step 14
    saveAll: 0
  },
  userPreferences: {
    report_email_1: "",
    report_email_2: "",
    report_email_3: "",
    daily_report_all_devices: 0,
    monthly_report_all_devices: 0
  }
};

function AutoPilot(props) {

  const deviceId = props.match.params.deviceId;

  const autoPilotService = new AutoPilotService();
  const carService = new CarService();
  const userService = new UserService();
  const [data, setData] = useState(dataInitialState);
  const [showMessage, setShowMessage] = useState({
    message: '',
    title: '',
    status: false
  });

  let kmOptions = [{'value': 0, 'label': 'Passive'}];
  for (let i = 10; i <= 2000; i += 10) {
    kmOptions.push({'value': i, 'label': (i).toString() + ' km'});
  }

  let speedOptions = [{'value': 0, 'label': 'Passive'}];
  for (let i = 5; i <= 300; i += 5) {
    speedOptions.push({'value': i, 'label': (i).toString() + ' km/h'});
  }

  let timeOptions = [{'value': 0, 'label': 'Passive'},
    {'value': 600, 'label': '10 min'},
    {'value': 1200, 'label': '20 min'},
    {'value': 1800, 'label': '30 min'},
    {'value': 2400, 'label': '40 min'},
    {'value': 3000, 'label': '50 min'}];
  for (let i = 1; i <= 24; i++) {
    timeOptions.push({'value': i * 3600, 'label': (i).toString() + ' hour'});
  }

  let contactControlOptions = [{'value': 0, 'label': 'Passive'}];
  for (let i = 1; i <= 35; i++) {
    contactControlOptions.push({'value': i, 'label': (i).toString()});
  }

  let clockOptions = [];
  for (let i = 0; i < 24; i++) {
    clockOptions.push({'value': i, 'label': (i).toString() + ':00'});
  }

  let gpsTimeOptions = [{'value': 0, 'label': 'Passive'}];
  for (let i = 1; i <= 3; i++) {
    gpsTimeOptions.push({'value': i, 'label': (i * 24).toString()});
  }

  let notificationOptions = [{'value': 0, 'label': 'Passive'}, {'value': 1, 'label': 'Aktiv (E-Mail)'}];
  let notificationOptions2 = [{'value': 0, 'label': 'Passive'}, {'value': 2, 'label': 'Aktiv (E-Mail)'}];

  let notificationHourOptions = [
    {'value': 0, 'label': 'Passive'},
    {'value': 5, 'label': '5 dk'},
    {'value': 10, 'label': '10 dk'},
    {'value': 15, 'label': '15 dk'},
    {'value': 30, 'label': '30 dk'},
    {'value': 60, 'label': '60 dk'},
    {'value': 120, 'label': '120 dk'}
  ];

  const fetchData = async () => {
    try {
      const [autoPilotResponse, carResponse, userPreferencesResponse] = await Promise.all([
        autoPilotService.getAutoPilot(deviceId),
        carService.getCar(deviceId),
        userService.getUserPreferences()
      ]);

      setData({
        autoPilot: {
          warning_at_daily_mileage: autoPilotResponse.data.warning_at_daily_mileage,
          warning_at_speed_km_1: autoPilotResponse.data.warning_at_speed_km_1,
          warning_at_speed_km_2: autoPilotResponse.data.warning_at_speed_km_2,
          warning_at_speed_km_3: autoPilotResponse.data.warning_at_speed_km_3,
          no_gps_alert: autoPilotResponse.data.no_gps_alert,
          warning_at_trip_duration: autoPilotResponse.data.warning_at_trip_duration,
          warning_park_duration_start: autoPilotResponse.data.warning_park_duration_start,
          warning_park_duration_end: autoPilotResponse.data.warning_park_duration_end,
          warning_at_park_duration: autoPilotResponse.data.warning_at_park_duration,
          life_alert: autoPilotResponse.data.life_alert,
          warning_at_ignitions: autoPilotResponse.data.warning_at_ignitions,
          daily_report: autoPilotResponse.data.daily_report,
          speed_report: autoPilotResponse.data.speed_report,
          low_wolt: autoPilotResponse.data.low_wolt,
          notification_hour: autoPilotResponse.data.notification_hour,
          saveAll: 0,
          beacon_device: autoPilotResponse.data.beacon_device
        },
        car: {
          sms_ignition: carResponse.data.sms_ignition,
          sms_contact: carResponse.data.sms_contact,
          email_1: carResponse.data.email_1,
          email_2: carResponse.data.email_2,
          email_3: carResponse.data.email_3,
          phone_area_code: carResponse.data.phone_area_code,
          phone_area_code_2: carResponse.data.phone_area_code_2,
          phone_number: carResponse.data.phone_number,
          phone_number_2: carResponse.data.phone_number_2,
          tow_truck_notification: carResponse.data.tow_truck_notification,
          saveAll: 0
        },
        userPreferences: {
          report_email_1: userPreferencesResponse.data.report_email_1,
          report_email_2: userPreferencesResponse.data.report_email_2,
          report_email_3: userPreferencesResponse.data.report_email_3,
          daily_report_all_devices: userPreferencesResponse.data.daily_report_all_devices,
          monthly_report_all_devices: userPreferencesResponse.data.monthly_report_all_devices
        }
      })

    } catch (error) {
      setData(dataInitialState);
      setShowMessage({
        title: <h5>Error</h5>,
        message: <h5>Bir hata oluştu. Lütfen tekrar deneyiniz.</h5>,
        status: true
      })
    }
  };

  const handleChange = (event) => {
    if (event.target.name.indexOf(".") !== -1) {
      let _names = event.target.name.split(".");

      setData({
        ...data,
        [_names[0]]: {
          ...data[_names[0]],
          [_names[1]]: (event.target.type === "checkbox") ? (
            event.target.checked ? 1: 0
          ) : event.target.value
        }
      });
    }
  };

  const handleSaveAll = (event) => {
    setData({
      ...data,
      autoPilot: {
        ...data.autoPilot,
        saveAll: (event.target.checked) ? 1: 0
      },
      car: {
        ...data.car,
        saveAll: (event.target.checked) ? 1: 0
      }
    });
  };

  const saveAutoPilot = async (event) => {
    event.preventDefault();

    await Promise.all([
      autoPilotService.saveAutoPilot(deviceId, data.autoPilot),
      carService.saveCar(deviceId, data.car),
      userService.saveUserPreferences(data.userPreferences)
    ])
      .then(([autoPilotResponse, carResponse, userPreferencesResponse]) => {
        (autoPilotResponse.data.result === "success" && carResponse.data.result === "success" && userPreferencesResponse.data.result === "success") ?
          setShowMessage({
            title: <h5>Success</h5>,
            message: <h5><FormattedMessage id="USER_RIGHTS.SUCCESS_SAVE"/></h5>,
            status: true
          })
        :
          setShowMessage({
            title: <h5>Error</h5>,
            message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
            status: true
          })
      })
  };

  useEffect(() => {
    fetchData();

  }, [deviceId]);

  const AutoPilotCard = function (props) {

    return (
      <div className="card bg-light mb-1">
        <div className="card-body">
          {props.header}
          <div className="row">
            <div className="col-md-6">
              {(props.img) ? (
                <img className="rounded" width='70' height='70' src={props.img} alt="auto-pilot"/>) : (null)}
            </div>
            <div className="col-md-6">
              <select className="form-control" value={props.value} name={props.name} onChange={handleChange}>
                {props.options.map((option, index) => {
                  return <option key={index} value={option.value}>{option.label}</option>
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    )
  };

  return (
    <>
      <HomeNav deviceId={deviceId} active="auto-pilot"/>
      <div className="col-md-11">
        <form onSubmit={saveAutoPilot}>
          <div className="row">
            <div className="col-md-9">
              <h2><FormattedMessage id="AUTOPILOT.AUTOPILOT_SETTINGS"/></h2>
            </div>
          </div>
          <p>
            <b><FormattedMessage id="AUTOPILOT.MOBILE_COMMENT"/></b>
          </p>
          {/** row 1 **/}
          <div className="card-deck">
            <AutoPilotCard value={data.autoPilot.warning_at_daily_mileage}
                           name='autoPilot.warning_at_daily_mileage'
                           header={<p><b>1. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.DAILY_KM"/><br/><FormattedMessage id="AUTOPILOT.DEVIATION"/></p>}
                           img={require('./images/tageskm.png').default}
                           options={kmOptions}/>

            <AutoPilotCard value={data.autoPilot.warning_at_speed_km_1}
                           name='autoPilot.warning_at_speed_km_1'
                           header={<p><b>2. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.SPEED_CONTROL_1"/><br/><FormattedMessage id="AUTOPILOT.DEVIATION"/></p>}
                           img={require('./images/speedcontrol1.jpg').default}
                           options={speedOptions}/>

            <AutoPilotCard value={data.autoPilot.warning_at_speed_km_2}
                           name='autoPilot.warning_at_speed_km_2'
                           header={<p><b>3. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.SPEED_CONTROL_2"/><br/><FormattedMessage id="AUTOPILOT.DEVIATION"/></p>}
                           img={require('./images/speedcontrol2.jpg').default}
                           options={speedOptions}/>

            <AutoPilotCard value={data.autoPilot.warning_at_speed_km_3}
                           name='autoPilot.warning_at_speed_km_3'
                           header={<p><b>4. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.SPEED_CONTROL_3"/><br/><FormattedMessage id="AUTOPILOT.DEVIATION"/></p>}
                           img={require('./images/speedcontrol3.png').default}
                           options={speedOptions}/>
          </div>
          {/** row 2 **/}
          <div className="card-deck">
            <AutoPilotCard value={data.car.sms_ignition}
                           name='car.sms_ignition'
                           header={<p><b>5. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.IGNITION"/></p>}
                           img={require('./images/ignition.png').default}
                           options={notificationOptions2}/>

            <AutoPilotCard value={data.autoPilot.no_gps_alert}
                           name='autoPilot.no_gps_alert'
                           header={<p><b>6. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.OHNE_GSM"/></p>}
                           img={require('./images/gps.png').default}
                           options={notificationOptions}/>

            <AutoPilotCard value={data.autoPilot.warning_at_trip_duration}
                           name='autoPilot.warning_at_trip_duration'
                           header={<p><b>7. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.DRIVING_TIME"/> - <FormattedMessage id="AUTOPILOT.ATOB"/><br/><FormattedMessage id="AUTOPILOT.DEVIATION"/></p>}
                           img={require('./images/drivetime.png').default}
                           options={timeOptions}/>

            <AutoPilotCard value={data.autoPilot.warning_at_park_duration}
                           name='autoPilot.warning_at_park_duration'
                           header={
                             <p>
                               <b>8. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.DEPERTURE_TIME"/><br/>
                               <FormattedMessage id="AUTOPILOT.FROM"/> : <select style={{width: '80px', textAlign: 'center', verticalAlign: 'middle'}}
                                                  name='autoPilot.warning_park_duration_start'
                                                  value={data.autoPilot.warning_park_duration_start}
                                                  onChange={handleChange}>
                               {clockOptions.map((clock, index) => <option key={index} value={clock.value}>{clock.label}</option>)}
                             </select>
                             <FormattedMessage id="AUTOPILOT.UNTIL"/> : <select style={{width: '80px', textAlign: 'center', verticalAlign: 'middle'}}
                                               name='autoPilot.warning_park_duration_end'
                                               value={data.autoPilot.warning_park_duration_end}
                                               onChange={handleChange}>
                               {clockOptions.map((clock, index) => <option key={index} value={clock.value}>{clock.label}</option>)}
                             </select>
                               <br/>
                               <FormattedMessage id="AUTOPILOT.UNTIL"/> <FormattedMessage id="AUTOPILOT.ATOB"/>
                               <br/>
                               <FormattedMessage id="AUTOPILOT.DEVIATION"/>
                             </p>
                           }
                           img={require('./images/parktime.png').default}
                           options={timeOptions}/>
          </div>
          {/** row 3 **/}
          <div className="card-deck">
            <AutoPilotCard value={data.autoPilot.life_alert}
                           name='autoPilot.life_alert'
                           header={<p><b>9. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.LOCATION_TIME"/></p>}
                           options={gpsTimeOptions}/>

            <AutoPilotCard value={data.autoPilot.warning_at_ignitions}
                           name='autoPilot.warning_at_ignitions'
                           header={<p><b>10. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.IGNITION_CONTROL"/></p>}
                           img={require('./images/ign2.png').default}
                           options={contactControlOptions}/>

            <AutoPilotCard value={data.car.sms_contact}
                           name='car.sms_contact'
                           header={<p><b>11. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.IGNITION_CHANGE"/></p>}
                           options={notificationOptions2}/>

            <AutoPilotCard value={data.autoPilot.low_wolt}
                           name='autoPilot.low_wolt'
                           header={<p><b>12. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.12V"/></p>}
                           img={require('./images/gps.png').default}
                           options={notificationOptions}/>
          </div>
          {/** row 4 **/}
          <div className="card-deck">
            <AutoPilotCard value={data.autoPilot.notification_hour}
                           name='autoPilot.notification_hour'
                           header={<p><b>13. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.EMAIL_INTERVAL"/></p>}
                           options={notificationHourOptions}/>

            <AutoPilotCard value={data.autoPilot.beacon_device}
                           name='autoPilot.beacon_device'
                           header={<p><b>14. <FormattedMessage id="GENERAL.STEP"/>: </b><FormattedMessage id="AUTOPILOT.BEACON_DEVICE"/></p>}
                           options={notificationOptions}/>
          </div>
          {/** row 5 **/}
          <div className="card-deck">
            <div className="card bg-light auto-pilot-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-5">
                    <b>Araç bildirimleri ve gün sonu raporu: </b>

                    <div className="row">
                      <div className="col-md-10">
                        <b><FormattedMessage id="GENERAL.MAIL_WRITE"/>.</b>
                        <br/>
                        <div className="row" style={{marginLeft:1}}>
                          <FormattedMessage id="GENERAL.MAIL">
                            {
                                placeholder => (
                                    <input value={data.car.email_1}
                                          name="car.email_1"
                                          onChange={handleChange}
                                          placeholder={placeholder+" 1"}
                                          type="email"
                                          className="form-control mt-1"/>
                                )
                            }
                          </FormattedMessage>
                          <FormattedMessage id="GENERAL.MAIL">
                            {
                                placeholder => (
                                    <input value={data.car.email_2}
                                          name="car.email_2"
                                          onChange={handleChange}
                                          placeholder={placeholder+" 2"}
                                          type="email"
                                          className="form-control mt-1"/>
                                )
                            }
                          </FormattedMessage>
                          <FormattedMessage id="GENERAL.MAIL">
                            {
                                placeholder => (
                                    <input value={data.car.email_3}
                                          name="car.email_3"
                                          onChange={handleChange}
                                          placeholder={placeholder+" 3"}
                                          type="email"
                                          className="form-control mt-1"/>
                                )
                            }
                          </FormattedMessage>
                        </div>
                      </div>
                      <div className="col-md-10 mt-2">
                        <div className="form-check">
                          <input value={data.autoPilot.daily_report}
                                 checked={data.autoPilot.daily_report == 1}
                                 name="autoPilot.daily_report"
                                 onChange={handleChange}
                                 type="checkbox"
                                 className="form-check-input"/>
                          <label className="form-check-label" ><FormattedMessage id="REPORTS.DAILY_REPORT_THIS_DEVICE"/> </label>
                        </div>
                      </div>
                      <div className="col-md-10 mt-2">
                        <div className="form-check">
                          <input value={data.autoPilot.speed_report}
                                 checked={data.autoPilot.speed_report == 1}
                                 name="autoPilot.speed_report"
                                 onChange={handleChange}
                                 type="checkbox"
                                 className="form-check-input"/>
                          <label className="form-check-label" ><FormattedMessage id="REPORTS.SPEED_REPORT_THIS_DEVICE"/> </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-5">
                    <b>Bütün araçlar için raporlar: </b>

                    <div className="row">
                      <div className="col-md-10">
                        <b><FormattedMessage id="GENERAL.MAIL_WRITE"/>.</b>
                        <br/>
                        <div className="row" style={{marginLeft:1}}>
                          <FormattedMessage id="GENERAL.MAIL">
                            {
                                placeholder => (
                                    <input value={data.userPreferences.report_email_1}
                                          name="userPreferences.report_email_1"
                                          onChange={handleChange}
                                          placeholder={placeholder+" 1"}
                                          type="email"
                                          className="form-control mt-1"/>
                                )
                            }
                          </FormattedMessage>
                          <FormattedMessage id="GENERAL.MAIL">
                            {
                                placeholder => (
                                    <input value={data.userPreferences.report_email_2}
                                          name="userPreferences.report_email_2"
                                          onChange={handleChange}
                                          placeholder={placeholder+" 2"}
                                          type="email"
                                          className="form-control mt-1"/>
                                )
                            }
                          </FormattedMessage>
                          <FormattedMessage id="GENERAL.MAIL">
                            {
                                placeholder => (
                                    <input value={data.userPreferences.report_email_3}
                                          name="userPreferences.report_email_3"
                                          onChange={handleChange}
                                          placeholder={placeholder+" 3"}
                                          type="email"
                                          className="form-control mt-1"/>
                                )
                            }
                          </FormattedMessage>
                        </div>
                      </div>
                      <div className="col-md-10 mt-2">
                        <div className="form-check">
                          <input value={data.userPreferences.daily_report_all_devices}
                                 checked={data.userPreferences.daily_report_all_devices == 1}
                                 name="userPreferences.daily_report_all_devices"
                                 onChange={handleChange}
                                 type="checkbox"
                                 className="form-check-input" />
                          <label className="form-check-label" ><FormattedMessage id="REPORTS.DAILY_REPORT_FOR_ALL_DEVICE"/></label>
                        </div>
                        <div className="form-check">
                          <input value={data.userPreferences.monthly_report_all_devices}
                                 checked={data.userPreferences.monthly_report_all_devices == 1}
                                 name="userPreferences.monthly_report_all_devices"
                                 onChange={handleChange}
                                 type="checkbox"
                                 className="form-check-input" />
                          <label className="form-check-label" ><FormattedMessage id="REPORTS.MONTHLY_REPORT_FOR_ALL_DEVICE"/></label>
                        </div>
                        <div className="form-check">
                          <input value={data.autoPilot.saveAll}
                                 checked={data.autoPilot.saveAll == 1}
                                 onChange={handleSaveAll}
                                 type="checkbox"
                                 className="form-check-input"/>
                          <label className="form-check-label" ><FormattedMessage id="GENERAL.SAVE_FOR_ALL_DEVICE"/></label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-5">
                        <button type="submit" className="btn btn-success col"><FormattedMessage id="GENERAL.SAVE"/></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <MessageWindow
          size='md'
          show={showMessage.status}
          onHide={() => setShowMessage({...showMessage, status: false})}
          title={showMessage.title}
          message={showMessage.message}
        />
      </div>
    </>
  )
}

export default AutoPilot;
