import React from 'react';

function MapLinkGoogle(props) {
  const lat = props.lat;
  const long = props.long;
  const listType = props.listType;

  let span = '';
  if (listType === 'round-list') {
    span = '<span class="round"><b></b></span>';
  }

  let mapLink = 'http://www.google.com/maps/place/' + lat + ',' + long + '/@' + lat + ',' + long + ',423m/data=!3m1!1e3?hl=en&gl=US';

  return (
    <div>
        <a href= {mapLink} rel="noopener noreferrer" target="_blank"> <img src={require('../Assets/images/map_link.png').default}  alt="google maps link"/> {span} </a>
    </div>
  );
}

export default MapLinkGoogle;
