import './index.css';

import React, {useState, useRef, useEffect} from 'react';
import HMap from './hmap';

var lastDeviceId = 0;

const getColor = index => {
    switch (index % 31) {
        case 0 :
            return "#3300ff";
        case 1 :
            return "#FF0000";
        case 2 :
            return "#7FFF00";
        case 3 :
            return "#6495ED";
        case 4 :
            return "#FFD700";
        case 5 :
            return "#9400D3";
        case 6 :
            return "#00BFFF";
        case 7 :
            return "#ADFF2F";
        case 8 :
            return "#D3D3D3";
        case 9 :
            return "#FF7F50";
        case 10 :
            return "#7B68EE";
        case 11 :
            return "#FFE4E1";
        case 12 :
            return "#2E8B57";
        case 13 :
            return "#F4A460";
        case 14 :
            return "#A0522D";
        case 15 :
            return "#87CEEB";
        case 16 :
            return "#DA70D6";
        case 17 :
            return "#32CD32";
        case 18 :
            return "#FFFF00";
        case 19 :
            return "#0000FF";
        case 20 :
            return "#C0C0C0";
        case 21:
            return "#8B0000";
        case 22:
            return "#00CED1";
        case 23:
            return "#FF6347";
        case 24:
            return "#F5DEB3";
        case 25:
            return "#FFA500";
        case 26:
            return "#6B8E23";
        case 27:
            return "#F0E68C";
        case 28:
            return "#F08080";
        case 29:
            return "#ADD8E6";
        case 30:
            return "#7CFC00";
        default  :
            return "#3300ff";
    }
}

let stopCount = 0;

const ColorRouteMap = (props) => {
    const [size, setSize] = useState([0, 800]);
    const longlat = [27.1699050, 38.4310990];
    const mapInstance = useRef(null);

    const updateSize = () => {
        setSize([window.innerWidth, (window.innerHeight - 41)]);
        if (mapInstance) {
            mapInstance.current.fitMap();
        }
    }
    
    useEffect(() => {
        if (mapInstance.current === null && props.match.params.deviceId) {
            mapInstance.current = new HMap(longlat);
            mapInstance.current.initMap(13, false, { measure: true, deviceId: props.match.params.deviceId });
        }
        
        mapInstance.current.getVectorSource().clear();
        
        let signals = [];
        let itemsProcessed = 0;
        let first_start = false;
        let device = props.device;
        let lastSignal = props.signals[props.signals.length - 1];

        props.signals.forEach((element, index, array) => {
            itemsProcessed++;
            let coordinate = [element.dec_long, element.dec_lat];
            signals.push(coordinate);

            if (itemsProcessed % 8 === 0) {
                let arrowIcon = require(`../Assets/images/right2.png`).default;
                if (array[index+1]) {
                    let next_coordinate = [array[index+1].dec_long, array[index+1].dec_lat];
                    device['speed_km'] = element.speed_km;
                    device['dec_long'] = element.dec_long;
                    device['dec_lat'] = element.dec_lat;
                    device['latest_geocode_sent_at'] = element.geocode_sent_at;

                    let angle = mapInstance.current.calculateAngle(coordinate, next_coordinate);
                    mapInstance.current.addArrow(coordinate, arrowIcon, angle, {...device});
                }
            }

            if (element.signal_mode === 4) {
                let lineString = mapInstance.current.createLineString(signals);
                let featureLine = mapInstance.current.createLineFeature(5, getColor(props.stopCount || stopCount));
                featureLine.setGeometry(lineString);
                featureLine.getGeometry().transform('EPSG:4326', 'EPSG:3857');
                signals = [];
                stopCount++;

                device['dec_long'] = element.dec_long;
                device['dec_lat'] = element.dec_lat;
                device['latest_geocode_sent_at'] = element.geocode_sent_at;
                let stopIcon = require(`../Assets/images/stop.gif`).default;
                mapInstance.current.addStop(coordinate, stopIcon, {...device});
            }

            if (element.signal_mode === 3 && first_start === false) {
                device['dec_long'] = element.dec_long;
                device['dec_lat'] = element.dec_lat;
                device['latest_geocode_sent_at'] = element.geocode_sent_at;
                let startIcon = require(`../Assets/images/flag_green.gif`).default;
                mapInstance.current.addStop(coordinate, startIcon, {...device});
                first_start = true;
            }

            if (itemsProcessed === array.length) {

                let markerIcon = require(`../Assets/images/device-icons/car_${props.device.type}.gif`).default;
                if (lastSignal) {
                    let _data = props.device;
                    _data['total_km'] = (array[array.length - 1].mileage - array[0].mileage).toFixed(2);
                    mapInstance.current.addMarker([lastSignal.dec_long, lastSignal.dec_lat], markerIcon, _data, "color_route_device");
                }

                mapInstance.current.fitMap();
                stopCount = 0;
            }

        });

        if (lastDeviceId !== device.device_id) {
            lastDeviceId = device.device_id;
            mapInstance.current.setLayer(device.device_id);
        }
        
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
            signals = [];
            stopCount = 0;
            first_start = false;
        };
    }, [props.signals, props.device, props.maps, props.active]);
        
    return (
        <div>
            <div id="map" style={{ width: "100%", height: `${size[1]}px` }}></div>
            <div id="popup" className="ol-popup">
                <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                <div id="popup-content"></div>
            </div>
        </div>
    );
}

export default ColorRouteMap;
