import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Modal} from "react-bootstrap";
import UserService from '../../services/Users'
import DriverService from '../../services/Drivers';
import VehicleService from '../../services/VehicleGroups';
import MessageWindow from '../Commons/messageWindow';
import './index.css';

function VehicleGroups(props) {

	const driverService = new DriverService();
	const userService = new UserService();
	const vehicleService = new VehicleService();
	const [orderType, setOrderType] = useState('name');
	const [allGroups, setAllGroups] = useState([]);
	const [page, setPage] = useState('listedGroups');
	const [newGroupName, setNewGroupName] = useState('');
	const [groupName, setGroupName] = useState('');
	const [group_id, setGroupId] = useState(0);
	const [selectedDevices, setSelectedDevices] = useState([]);
	const [selectedDevices_, setSelectedDevices_] = useState([]);
	const [driverDevices, setDriverDevices] = useState([]);
	const [deleteGroup, setDeleteGroup] = useState(false);
    const [editGroupName, setEditGroupName] = useState(false);
    const [value, setValue] = useState('');
    const [filteredDeviceList, setDeviceList] = useState([]);
	const [showMessage, setShowMessage] = useState({
		"show": false,
		"title": "",
		"message": ""
    });
    
    let input;
    const handleChangeGroupName = () => {
        filterDeviceList(input.value);
    };

    const filterDeviceList = (text) => {
        let filteredList = [];
        let newData = [];
        if(text !== ''){
          newData = allGroups.filter(function(item){
            const itemData = item.group_name.toUpperCase()
            const textData = text.toUpperCase()
            return itemData.indexOf(textData) > -1
          })
          filteredList.pop();
          filteredList.push(newData);
        }else{
            filteredList.pop();
            filteredList.push(allGroups);
        }
        setValue(text);
        setDeviceList(filteredList[0])
    }

	const handleChange = (event, index) => {
		driverDevices[index]["checked"] = event.target.checked ? 1 : 0;
		setDriverDevices([...driverDevices]);
	};

	const changeOrderType = () => {
		if (orderType === 'name') {
			setOrderType('-name');
		} else {
			setOrderType('name');
		}
    };
    
    const newGroup = () => {
        setPage('newGroup');

        setNewGroupName('');// reset variables
        setSelectedDevices([]);
        setSelectedDevices_([]);

        for (var i = 0; i < props.devices.length; i++) { // reset devices
            props.devices[i]['checked'] = 0;
        };
        setDriverDevices(props.devices);
    };

	const createGroup = async () => {
		var data = {group_name: "", devices: []};
		if (newGroupName !== "") {

			data.group_name = newGroupName;
			data.devices = selectedDevices;

			try {
				await vehicleService.createNewGroup(data)
					.then((response) => {
						if (response.data.status === "success") {
							setGroupName(newGroupName);
							setGroupId(response.data.device_group_id);
                            groupDetail(response.data.device_group_id);
                            setPage("listedGroups");
						} else {
							alert('create error');
						}

					});
			} catch (error) {
			}
		}
	};

	const editGroup = (groupName, groupID) => {
		setPage('editGroup');
		setGroupName(groupName);
		setGroupId(groupID);
		groupDetail(groupID);
	};

	const addRemoveDevice = (device) => {

		var index = selectedDevices.indexOf(device.device_id);
		if (index > -1) {
			selectedDevices.splice(index, 1);
		} else {
			selectedDevices.push(device.device_id);
		}
    };
    
	const updateGroupDevices = async () => {
		var data = {"devices[]": selectedDevices};
		try {
			await vehicleService.updateGroupDevices(group_id, data)
				.then(async (response) => {
                    editGroup(groupName,group_id);
				});
		} catch (error) {
		}
	};

	const openDeletionPage = async (device_group_id) => {
		setDeleteGroup(true);
		setGroupId(device_group_id);
	};

	const deleteGroupFunction = async () => {
		try {
			await vehicleService.deleteGroup(group_id)
				.then(async (response) => {
					setDeleteGroup(false);
					setPage('listedGroups');
				});
		} catch (error) {
			alert('ERROR');
		}
	};

	const editGroupNameFunction = async () => {
		try {
			await vehicleService.updateGroupName(group_id, groupName)
				.then(async (response) => {
					setEditGroupName(false);
					setPage('listedGroups');
				});
		} catch (error) {
			alert('ERROR');
		}
	};

	const fetchData = async () => {
		await userService.getUserGroups(orderType)
			.then(async (response) => {
                setAllGroups(response.data[0].results);
                setDeviceList(response.data[0].results);
			});
	};

	const groupDetail = async (groupID) => {
		try {
			await vehicleService.getGroupDevices(groupID)
				.then(async (response) => {

					let selected_devices = [];
					for (var i = 0; i < response.data.length; i++) {
						selected_devices.push(response.data[i].device_id)
					}
					setSelectedDevices(selected_devices);
					setSelectedDevices_(response.data);
					await driverService.driver_mobile_all_devices(0)
						.then(async (res) => {
							let driverDevices = [];
							driverDevices = res.data;
							for (var i = 0; i < driverDevices.length; i++) {
								for (var j = 0; j < response.data.length; j++) {
									if (response.data[j].device_id === res.data[i].device_id) {
										driverDevices[i]['checked'] = 1;
										break;
									} else {
										driverDevices[i]['checked'] = 0;
									}
								}
                            }
                            setDriverDevices(driverDevices);
                            
						});
				});
		} catch (error) {
		}
	};


	useEffect(() => {
		fetchData();
	}, [page, deleteGroup,orderType]);

	return (
		<>
			<MessageWindow
				size="md"
				show={showMessage.show}
				onHide={() => setShowMessage({...showMessage, show: false})}
				title={showMessage.title}
				message={showMessage.message}/>
			<div className="device-groups">
				<div className="col-md-12"><h3><FormattedMessage id="MENU.GROUPS"/></h3></div>
				{
					page === 'listedGroups' ?
						<>
							<div className="col-md-2 col-sm-1 group-filter">
								<p><b><FormattedMessage id="GENERAL.FILTER"/></b></p>
								<FormattedMessage id="GROUPS.NAME">
									{
										placeholder => (
                                            <input 
                                                className="form-control"
                                                type="text" 
                                                ref={n => input = n}
                                                value={value}
                                                placeholder={placeholder}
                                                onChange={handleChangeGroupName}
                                                placeholder={placeholder}/>
										)
									}
								</FormattedMessage>
								<hr/>
								<button className="btn btn-success center-block btn-sm" onClick={() => newGroup()}>
									<FormattedMessage id="GROUPS.CREATE_NEW_GROUP"/></button>
							</div>
							<div className="col-md-9 col-sm-9 all-groups table-responsive ">
								<table className="table table-hover table-striped text-center">
									<thead>
									<tr>
										<th><a href="#" onClick={() => changeOrderType()}><FormattedMessage id="GROUPS.NAME"/>&nbsp;<i className="fa fa-sort" aria-hidden="true"/></a></th>
										<th><FormattedMessage id="GROUPS.UPDATE"/></th>
										<th><FormattedMessage id="GROUPS.DELETE"/></th>
									</tr>
									</thead>
									<tbody>
									{filteredDeviceList.map((group, index) => {
										return <tr key={'group-' + index}>
											<td>{group.group_name}</td>
											<td><i onClick={() => editGroup(group.group_name, group.device_group_id)} className="fa fa-pencil"
														 aria-hidden="true"/></td>
											<td><i onClick={() => openDeletionPage(group.device_group_id)} className="fa fa-trash"
														 aria-hidden="true"/></td>
										</tr>
									})}
									</tbody>
								</table>
							</div>
						</>
						:
						page === 'editGroup' ?
							<div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
								<p className="head pull-left">{groupName}<small><i onClick={() => setEditGroupName(true)} className="fa fa-pencil update-group-name" aria-hidden="true"/></small></p>
								<button className="btn btn-warning btn-sm bold pull-right" onClick={() => setPage('listedGroups')}>
									<FormattedMessage id="GENERAL.TURN_BACK"/></button>
								<div className="clearfix"/>
								<hr/>
								<div className=" group-detail">
									<div className="col-md-4 no-padding-left">
										<p><b><FormattedMessage id="GROUPS.DEVICES_IN_GROUP"/></b></p>
										{selectedDevices_.map((device, index) => {
											return <p key={'device-' + index}>{device.name}</p>
										})}

									</div>
									<div className="col-md-8 no-padding-left">
										<p><b><FormattedMessage id="GROUPS.ADD_REMOVE_DEVICE"/></b></p>
										<div className="selectbox form-group">
											{
												driverDevices.map((device, index) => {
													return (<label key={'device-' + index}>
															<input type="checkbox" onClick={() => addRemoveDevice(device)}
																		 checked={device.checked === 1} name={device.name}
																		 onChange={(event) => handleChange(event, index)}/>
															{device.name}
														</label>
													)
												})
											}
										</div>
										<br/>

										<div className="pull-right">
											<div className="form-group">
												<button className="btn btn-danger" onClick={() => setDeleteGroup(true)}><FormattedMessage
													id="GROUPS.DELETE"/></button>
												<button style={{marginLeft: 5}} className="btn btn-success"
																onClick={() => updateGroupDevices()}><FormattedMessage id="GROUPS.UPDATE"/></button>
											</div>
										</div>
									</div>
								</div>

							</div>
							:
							<div className="col-lg-6 col-md-8 col-sm-12 col-xs-12 createGroup">
								<div className="pull-left"><h4 className="mb-none"><FormattedMessage id="GROUPS.CREATE"/></h4></div>
								<div className="pull-right">
									<button className="btn btn-warning btn-sm bold pull-right" onClick={() => setPage('listedGroups')}>
										<FormattedMessage id="GENERAL.TURN_BACK"/></button>
								</div>
								<div className="clearfix"/>
								<hr/>
								<div className="form-group">
									<label><FormattedMessage id="GROUPS.NAME"/>*</label>
                                    <input className="form-control" onChange={(event) => setNewGroupName(event.target.value)} value={newGroupName} style={{width: 250}}/>
								</div>
								<p><b><FormattedMessage id="GROUPS.DEVICES_TO_ADD"/></b></p>
								<div className="selectbox form-group">
									{
										driverDevices.map((device, index) => {
											return (<label key={'device-' + index}>
													<input type="checkbox" onClick={() => addRemoveDevice(device)} checked={device.checked === 1}
																 name={device.name} onChange={(event) => handleChange(event, index)}/>
													{device.name}
												</label>
											)
										})
									}
								</div>
								<div className="pull-right">
									<div className="form-group">
										<button className="btn btn-success" onClick={() => createGroup()}><FormattedMessage id="GENERAL.SAVE"/>
										</button>
									</div>
								</div>
							</div>
				}
				<div className="clearfix"/>
			</div>
			<Modal
				size="lg"
				show={deleteGroup}
				aria-labelledby="deleteGroup"
				onHide={() => setDeleteGroup(false)}>

				<Modal.Header closeButton>
					<Modal.Title id="deleteTemplate">
						<FormattedMessage id="GROUPS.DELETE"/>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="deleteTemplate">
					<div className="ngdialog-message">
						<p><FormattedMessage id="GROUPS.CONFIRM_DELETE"/></p>
					</div>
					<div className="ngdialog-buttons">
						<div className="form-group">
							<button type="button" className="badge badge-danger pull-right"
											onClick={() => deleteGroupFunction(group_id)}><FormattedMessage id="GENERAL.DELETE"/></button>
							<button type="button" className="badge badge-warning pull-right" onClick={() => setDeleteGroup(false)}>
								<FormattedMessage id="GENERAL.TURN_BACK"/></button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				size="lg"
				show={editGroupName}
				aria-labelledby="editGroupName"
				onHide={() => setEditGroupName(false)}>

				<Modal.Header closeButton>
					<Modal.Title id="editTemplate">
						<FormattedMessage id="GROUPS.EDIT_NAME"/>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="editTemplate">
					<div className="ngdialog-message">
						<p><FormattedMessage id="GROUPS.NAME"/></p>
						<input className="form-control" onChange={(event) => setGroupName(event.target.value)} value={groupName}/>
					</div>
					<div className="ngdialog-buttons">
						<div className="form-group">
							<button type="button" className="badge badge-primary pull-right"
											onClick={() => editGroupNameFunction(group_id)}><FormattedMessage id="GENERAL.SAVE"/></button>
							<button type="button" className="badge badge-warning pull-right" onClick={() => setEditGroupName(false)}>
								<FormattedMessage id="GENERAL.TURN_BACK"/></button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

const mapStateToProps = function (state) {
	return {
		devices: state.deviceListReducer.devices
	}
};

export default connect(mapStateToProps)(VehicleGroups);
