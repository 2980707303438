import React from 'react';
import '../index.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../images/ufuk-arac-takip-sistemleri-logo.png";


function ResetPassword() {
    return (
        <div>
            <div className="container" style={{paddingTop: 20}}>
                <div className="row" style={{alignItems:'center', justifyContent:'center'}}>
                    <div className="col-md-4 col-md-offset-4">
                        
                        <div className="text-center">
                            <img src={Logo} alt="horizont"/>
                            <h4>Şifre Sıfırlama İsteğinde Bulun</h4>
                        </div> 
                        <br></br>
                        <form id="login_form">
                            <input type="hidden" name="action" value="reset" />

                            <div class="form-group">
                                <input type="text" name="user_email" required class="form-control" placeholder="E-Posta"/>
                            </div>

                            <button type="submit" class="btn btn-primary btn-block">Gönder</button>

                            <div className="form-group">
                                <p style={{textAlign:'center'}}><br></br><a>Giriş Yap</a></p>
                            </div>
                        </form>
                    </div>
                </div>
                
            </div>
        </div>
    );
  }
  export default ResetPassword;
                       