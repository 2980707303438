import './index.css';

import React, {useState, useRef, useEffect} from 'react';
import { FormattedMessage } from 'react-intl';
import HMap from './hmap';
import {Col, Row, Button, Badge, Container} from 'react-bootstrap';

const getColor = index => {

    if (index >= 0 && index <= 30) {
        return "#3300ff";
    }
    else if (index > 30 && index <= 60) {
        return "#7fff00";
    }
    else if (index > 60 && index <= 80) {
        return "#ffd700";
    }
    else if (index > 80 && index <= 100) {
        return "#9400d3";
    }
    else if (index > 100 && index <= 120) {
        return "#ffa500";
    }
    else if (index > 120 && index <= 130) {
        return "#f08080";
    }
    else if (index > 130) {
        return "#f00";
    }
    else {
        return "#3300ff";
    }
}
let signals = {
    "#3300ff": [],
    "#7fff00": [],
    "#ffd700": [],
    "#9400d3": [],
    "#ffa500": [],
    "#f08080": [],
    "#f00": []
};

const SpeedRouteMap = (props) => {
    const [size, setSize] = useState([0, 800]);
    const [signalsKm, setSignalsKm] = useState({
        "30": 0,
        "60": 0,
        "80": 0,
        "100": 0,
        "120": 0,
        "130": 0,
        "131": 0
    });

    const longlat = [27.1699050, 38.4310990];
    const mapInstance = useRef(null);

    const handleSignalsButton = (speed) => {
        mapInstance.current.getVectorSource().clear();
        signals[getColor(speed)].forEach((element) => {
            let lineString = mapInstance.current.createLineString(element);
            let featureLine = mapInstance.current.createLineFeature(7, getColor(speed));
            featureLine.setGeometry(lineString);
            featureLine.getGeometry().transform('EPSG:4326', 'EPSG:3857');
        });
    }

    const handleSignalsAll = () => {
        mapInstance.current.getVectorSource().clear();

        for (const [key, value] of Object.entries(signals)) {
            value.forEach((element) => {
                let lineString = mapInstance.current.createLineString(element);
                let featureLine = mapInstance.current.createLineFeature(7, key);
                featureLine.setGeometry(lineString);
                featureLine.getGeometry().transform('EPSG:4326', 'EPSG:3857');
            });
             
        }
    }

    const updateSize = () => {
        setSize([window.innerWidth, (window.innerHeight - 41)]);
        if (mapInstance) {
            mapInstance.current.fitMap();
        }
    }
    
    useEffect(() => {
        if (mapInstance.current === null && props.match.params.deviceId) {
            mapInstance.current = new HMap(longlat);
            mapInstance.current.initMap(13, false, { measure: true, deviceId: props.match.params.deviceId });
        }
        
        mapInstance.current.getVectorSource().clear();

        let itemsProcessed = 0;
        let device = props.device;
        let first_signal = null;
        let current_signal = null;
        let first_speed_range = 0;
        let current_speed_range = 0;
        
        let lastSignal = props.signals[props.signals.length - 1];

        props.signals.forEach((element, index, array) => {
            itemsProcessed++;
            let coordinate = [element.dec_long, element.dec_lat];

            if (itemsProcessed % 8 === 0) {
                let arrowIcon = require(`../Assets/images/right2.png`).default;
                if (array[index+1]) {
                    let next_coordinate = [array[index+1].dec_long, array[index+1].dec_lat];
                    device['speed_km'] = element.speed_km;
                    device['dec_long'] = element.dec_long;
                    device['dec_lat'] = element.dec_lat;
                    device['latest_geocode_sent_at'] = element.geocode_sent_at;

                    let angle = mapInstance.current.calculateAngle(coordinate, next_coordinate);
                    mapInstance.current.addArrow(coordinate, arrowIcon, angle, {...device});
                }
            }

            if (!first_signal) {
                first_signal = element;
            }

            if (first_signal.speed_km < 30) {
                first_speed_range = 30;
            }
            else if (first_signal.speed_km < 60) {
                first_speed_range = 60;
            }
            else if (first_signal.speed_km < 80) {
                first_speed_range = 80;
            }
            else if (first_signal.speed_km < 100) {
                first_speed_range = 100;
            }
            else if (first_signal.speed_km < 120) {
                first_speed_range = 120;
            }
            else if (first_signal.speed_km < 130) {
                first_speed_range = 130;
            }
            else if (first_signal.speed_km > 130) {
                first_speed_range = 131;
            }

            if (element.speed_km < 30) {
                current_speed_range = 30;
            }
            else if (element.speed_km < 60) {
                current_speed_range = 60;
            }
            else if (element.speed_km < 80) {
                current_speed_range = 80;
            }
            else if (element.speed_km < 100) {
                current_speed_range = 100;
            }
            else if (element.speed_km < 120) {
                current_speed_range = 120;
            }
            else if (element.speed_km < 130) {
                current_speed_range = 130;
            }
            else if (element.speed_km > 130) {
                current_speed_range = 131;
            }

            if (first_speed_range != current_speed_range) {
                current_signal = array[index - 1];

                signalsKm[first_speed_range] += (current_signal.mileage - first_signal.mileage);
                first_signal = current_signal;
            }


            if (array[index+1]) {
                let color = getColor(element.speed_km);
                
                let next_coordinate = [array[index+1].dec_long, array[index+1].dec_lat];
                let signal = [coordinate, next_coordinate];
                
                signals[color].push(signal);

                let lineString = mapInstance.current.createLineString(signal);
                let featureLine = mapInstance.current.createLineFeature(7, color);
                featureLine.setGeometry(lineString);
                featureLine.getGeometry().transform('EPSG:4326', 'EPSG:3857');
            }

            if (element.signal_mode === 4) {

                device['dec_long'] = element.dec_long;
                device['dec_lat'] = element.dec_lat;
                device['latest_geocode_sent_at'] = element.geocode_sent_at;
                let stopIcon = require(`../Assets/images/stop.gif`).default;
                mapInstance.current.addStop(coordinate, stopIcon, {...device});
            }

            if (itemsProcessed === array.length) {

                let markerIcon = require(`../Assets/images/device-icons/car_${props.device.type}.gif`).default;
                if (lastSignal) {
                    mapInstance.current.addMarker([lastSignal.dec_long, lastSignal.dec_lat], markerIcon, {...props.device}, "color_route_device");
                }

                mapInstance.current.fitMap();
            }

        });
        
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
            signalsKm[30] = 0;
            signalsKm[60] = 0;
            signalsKm[80] = 0;
            signalsKm[100] = 0;
            signalsKm[120] = 0;
            signalsKm[130] = 0;
            signalsKm[131] = 0;
            signals = {
                "#3300ff": [],
                "#7fff00": [],
                "#ffd700": [],
                "#9400d3": [],
                "#ffa500": [],
                "#f08080": [],
                "#f00": []
            };
        };
    }, [props.signals]);
        
    return (
        <div>
            <Container>
                <Row>
                    <Col style={{padding: 0}}>
                        <Button onClick={() => handleSignalsButton(30)} block style={{padding: '0.175rem .75rem', backgroundColor: getColor(0)}}>
                            0-30 km/h <Badge variant="light">{signalsKm[30].toFixed(2)} km</Badge>
                        </Button>
                    </Col>
                    <Col style={{padding: 0}}>
                        <Button onClick={() => handleSignalsButton(60)} block style={{padding: '0.175rem .75rem', color: '#6c757d', backgroundColor: getColor(31)}}>
                            30-60 km/h <Badge variant="secondary">{signalsKm[60].toFixed(2)} km</Badge>
                        </Button>
                    </Col>
                    <Col style={{padding: 0}}>
                        <Button onClick={() => handleSignalsButton(80)} block style={{padding: '0.175rem .75rem', backgroundColor: getColor(61)}}>
                            60-80 km/h <Badge variant="light">{signalsKm[80].toFixed(2)} km</Badge>
                        </Button>
                    </Col>
                    <Col style={{padding: 0}}>
                        <Button onClick={() => handleSignalsButton(100)} block style={{padding: '0.175rem .75rem', backgroundColor: getColor(81)}}>
                            80-100 km/h <Badge variant="light">{signalsKm[100].toFixed(2)} km</Badge>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col style={{padding: 0}}>
                        <Button onClick={() => handleSignalsButton(120)} block style={{padding: '0.175rem .75rem', backgroundColor: getColor(101)}}>
                            100-120 km/h <Badge variant="light">{signalsKm[120].toFixed(2)} km</Badge>
                        </Button>
                    </Col>
                    <Col style={{padding: 0}}>
                        <Button onClick={() => handleSignalsButton(130)} block style={{padding: '0.175rem .75rem', backgroundColor: getColor(121)}}>
                            120-130 km/h <Badge variant="light">{signalsKm[130].toFixed(2)} km</Badge>
                        </Button>
                    </Col>
                    <Col style={{padding: 0}}>
                        <Button onClick={() => handleSignalsButton(131)} block style={{padding: '0.175rem .75rem', backgroundColor: getColor(131)}}>
                            130+ km/h <Badge variant="light">{signalsKm[131].toFixed(2)} km</Badge>
                        </Button>
                    </Col>
                    <Col style={{padding: 0}}>
                        <Button onClick={handleSignalsAll} block style={{padding: '0.175rem .75rem', color: '#6c757d', backgroundColor: '#f0f0f0'}}>
                            <FormattedMessage id="GENERAL.ALL"/>
                        </Button>
                    </Col>
                </Row>
            </Container>
            <div id="map" style={{ width: "100%", height: `${size[1]}px` }}></div>
            <div id="popup" className="ol-popup">
                <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                <div id="popup-content"></div>
            </div>
        </div>
    );
}

export default SpeedRouteMap;
