import React, { useState, useEffect } from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import {timeToText} from '../Assets/utils';
import {FormattedMessage} from 'react-intl';
import ImagePicker from 'react-image-picker'
import 'react-image-picker/dist/index.css'

import deviceIcons from '../Assets/icons.json';

function BasicSettings(props) {

    const [data, setData] = useState({...props.data});

    const onPick = image => {
        setData({...data, type: image.value});
    };

    const handleChange = (event) => {
        let value;
        if (event.target.type === "checkbox") {
            value = event.target.checked ? 1 : 0;
        }
        else {
            value = event.target.value;
        }

        setData({...data, [event.target.id]: value});
    };

    const handleSubmit = () => {
        props.onSubmit(data);
    };

    useEffect(() => {
        setData({...props.data});
    }, [props.data]);

    return (
        <>
            <Row>
                <Col sm={3}>
                    <Form.Group controlId="name">
                        <Form.Label><FormattedMessage id="SERVICE.VEHICLE_NAME"/></Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.name} />
                    </Form.Group>

                    <Form.Group controlId="formTotalMileage">
                        <Form.Label><FormattedMessage id="SERVICE.CURRENT_MILEAGE"/></Form.Label>
                        <Form.Control readOnly type="text" value={data.total_mileage} />
                    </Form.Group>

                    <Form.Group controlId="formTotalMotorMins">
                        <Form.Label><FormattedMessage id="SERVICE.CURRENT_RUN_TIME"/> </Form.Label>
                        <Form.Control readOnly type="text" value={timeToText(data.total_motor_mins)} />
                    </Form.Group>

                    <Form.Group controlId="email_1">
                        <Form.Label>Email-1</Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="email"
                            value={data.email_1} />
                    </Form.Group>

                    <Form.Group controlId="email_2">
                        <Form.Label>Email-2</Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="email"
                            value={data.email_2} />
                    </Form.Group>

                    <Form.Group controlId="email_3">
                        <Form.Label>Email-3</Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="email"
                            value={data.email_3} />
                    </Form.Group>

                    <Form.Group controlId="warning_at_vehicle_service">
                        <Form.Check
                            onChange={handleChange}
                            checked={data.warning_at_vehicle_service}
                            type="checkbox"
                            label={<FormattedMessage id="SERVICE.INFORMED_BY_MAIL"/>}/>
                    </Form.Group>
                    
                    <Button onClick={handleSubmit} variant="primary" type="button">
                        Kaydet
                    </Button>
                </Col>
                <Col sm={3}>
                    <Form.Group controlId="avg_fuel_consumption">
                        <Form.Label><FormattedMessage id="SERVICE.AVG_FUEL"/> </Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.avg_fuel_consumption} />
                    </Form.Group>

                    <Form.Group controlId="car_mileage_at_install">
                        <Form.Label><FormattedMessage id="SERVICE.INSTALL_MILEAGE"/></Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.car_mileage_at_install} />
                    </Form.Group>

                    <Form.Group controlId="start_motor_mins">
                        <Form.Label><FormattedMessage id="SERVICE.INSTALL_RUN_TIME"/></Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            value={data.start_motor_mins} />
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <FormattedMessage id="SERVICE.CURRENT_ICON"/>: <img src={require(`../Assets/images/device-icons/car_${data.type}.gif`).default} />
                <ImagePicker 
                    images={deviceIcons.map((image) => ({src: require(`../Assets/images/device-icons/${image.type_src}`).default, value: image.type_id}))}
                    onPick={onPick} />
                </Col>
            </Row>
        </>
    );
}

export default BasicSettings;