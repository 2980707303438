import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {Col, Row, Form, Button, Table, Pagination} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import DateFilter from '../Commons/dateFilter';
import {dayDiff} from "../Assets/utils";

import RouteMap from '../Map/route';

import SignalService from '../../services/Signals';
import Export from '../../services/Export';
import MessageWindow from "../Commons/messageWindow";
import {saveAs} from "file-saver";

let _signalsWithAddress = [];

function Signals(props) {

    const deviceId = props.match.params.deviceId;
    const signalService = new SignalService();
    const exportService = new Export();

    let _startDate = new Date();
    let _endDate = new Date();

    _startDate.setHours(0);
    _startDate.setMinutes(0);
    _endDate.setHours(23);
    _endDate.setMinutes(59);

    const [device, setDevice] = useState({});
    const [signals, setSignals] = useState([]);
    const [signalsWithAddress, setSignalsWithAddress] = useState([]);
    const [startDate, setStartDate] = useState(_startDate);
    const [endDate, setEndDate] = useState(_endDate);
    const [showMap, setShowMap] = useState(true);
    const [exceptZero, setExceptZero] = useState(false);
    const [onlyStartStop, setOnlyStartStop] = useState(false);
    const [display, setDisplay] = useState("50");
    const [offset, setOffset] = useState("0");
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [showMessage, setShowMessage] = useState({
        message: '',
        title: '',
        status: false
    });

    const getSignalMode = signalMode => {
        if (signalMode === 0) {
            return "RGTK";
        } else if (signalMode === 3) {
            return (<b style={{color: "#008f00"}}>LACN</b>);
        } else if (signalMode === 4) {
            return (<b style={{color: "#f00"}}>LACF</b>);
        } else if (signalMode === 2) {
            return (<b>STBY</b>);
        } else if (signalMode === 5) {
            return (<b>IBTN</b>);
        } else if (signalMode === 6) {
            return (<b>IBTF</b>);
        } else if (signalMode === 1) {
            return (<span>LGTK</span>);
        } else if (signalMode === 36) {
            return (<b>SMS</b>);
        } else if (signalMode === 38) {
            return (<b title="Düşük Batarya">LACC</b>);
        } else {
            return signalMode;
        }
    };

    const fetchData = async () => {
        try {
            let diff = dayDiff(startDate, endDate);

            if (-1 > diff)
                setShowMessage({
                    title: <h5>Error</h5>,
                    message: <h5>Başlangıç tarihi bitiş tarihinden büyük olamaz.</h5>,
                    status: true
                });
            else if (diff > 2)
                setShowMessage({
                    title: <h5>Error</h5>,
                    message: <h5>Tarih aralığı 3 günden fazla olamaz.</h5>,
                    status: true
                });
            else {
                setLoading(true);
                setShowMap(false);
                setOnlyStartStop(false);

                Promise.all([
                    signalService.getSignalsDateRange(deviceId, moment(startDate).format("YYYY-MM-DD H:mm:00"), moment(endDate).format("YYYY-MM-DD H:mm:59")),
                    signalService.getDeviceSignalsWithAdr(deviceId, moment(startDate).format("YYYY-MM-DD H:mm:00"), moment(endDate).format("YYYY-MM-DD H:mm:59"), offset, display)])
                  .then(([signalsResponse, addressResponse]) => {
                      setDevice({
                          type: signalsResponse.data[0].type,
                          name: signalsResponse.data[0].name,
                          device_id: signalsResponse.data[0].device_id,
                          address: "",
                          speed_km: "0"
                      });
                      setSignals(signalsResponse.data[0].signals);
                      setSignalsWithAddress(addressResponse.data[0].results);
                      // global signals
                      _signalsWithAddress = addressResponse.data[0].results;
                      // pagination
                      setPageCount(Math.ceil(signalsResponse.data[0].signals.length / display));
                      // display components
                      setLoading(false);
                      setShowMap(true);
                  });
            }

        } catch (e) {
            setShowMessage({
                title: <h5>Error</h5>,
                message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
                status: true
            })
        }
    };

    const handleChangeOnlyStartStop = event => {
        setOnlyStartStop(event.target.checked);
        setExceptZero(false);

        if (event.target.checked) {
            setSignalsWithAddress(
              _signalsWithAddress.filter((items) => items.signal_mode === 3 || items.signal_mode === 4)
            );
        } else {
            setSignalsWithAddress(_signalsWithAddress);
        }
    };

    const handleChangeExceptZero = event => {
        setOnlyStartStop(false);
        setExceptZero(event.target.checked);

        if (event.target.checked) {
            setSignalsWithAddress(
              _signalsWithAddress.filter((items) => items.speed_km !== 0)
            );
        } else {
            setSignalsWithAddress(_signalsWithAddress);
        }
    };

    // onclick handler
    const handleExport = async () => {
        const start = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
        const end = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
        setLoading(true);

        try {
            await exportService.exportSignalReport(deviceId, start, end, signalsWithAddress, 'xlsx')
              .then((response) => {
                  let blob = new Blob([response.data]);
                  saveAs(blob, `Sinyal_Raporu_${deviceId}.xlsx`);
              });

        } catch (e) {
            setShowMessage({
                title: <h5>Error</h5>,
                message: <h5>Rapor oluşturulamadı.</h5>,
                status: true
            });

        } finally {
            setLoading(false);
        }

    };

    const handleClickPage = (pageNumber) => {
        setActivePage(pageNumber);
        setOffset(pageNumber * display);
        fetchData();
    };

    const pageItems = () => {
        let items = [];
        for (let index = 1; index < pageCount + 1; index++) {
            items.push(
              <Pagination.Item
                key={index}
                active={activePage === index}
                disabled={activePage === index}
                onClick={() => handleClickPage(index, startDate, endDate)}>
                  {index}
              </Pagination.Item>
            );
        }

        return items;
    };

    useEffect(() => {
        fetchData();

        return () => {
            signalService.getCancelToken().cancel();
        };
    }, [deviceId, display]);

    return (
      <>
          <Row>
              <Col sm={3}>
                  <h6><FormattedMessage id="GENERAL.FILTER_OPTIONS"/></h6>
                  <DateFilter
                    arrows={false}
                    showTime={true}
                    dateFormat="dd-MM-yyyy HH:mm:00"
                    selectedDate={startDate}
                    onChange={(date) => setStartDate(date)}
                    wrapperClassName="react-datepicker-wrapper-full-width mb-1"/>

                  <DateFilter
                    arrows={false}
                    showTime={true}
                    dateFormat="dd-MM-yyyy HH:mm:59"
                    selectedDate={endDate}
                    onChange={(date) => setEndDate(date)}
                    wrapperClassName="react-datepicker-wrapper-full-width"/><br/>

                  <Form.Group controlId="show-map">
                      <Form.Check
                        checked={showMap}
                        type="checkbox"
                        onChange={(event) => setShowMap(event.target.checked)}
                        label={<FormattedMessage id="SIGNALS.SHOW_MAP"/>}/>
                  </Form.Group>

                  <Form.Group controlId="only-start-stop">
                      <Form.Check
                        checked={onlyStartStop}
                        type="checkbox"
                        onChange={handleChangeOnlyStartStop}
                        label={<FormattedMessage id="SIGNALS.ONLY_STARTSTOP"/>}/>
                  </Form.Group>

                  <Form.Group controlId="except-zero">
                      <Form.Check
                        checked={exceptZero}
                        type="checkbox"
                        onChange={handleChangeExceptZero}
                        label={<FormattedMessage id="SIGNALS.EXCEPT_ZERO"/>}/>
                  </Form.Group>

                  <h6>{<FormattedMessage id="SIGNALS.SHOW_THESE"/>}</h6>
                  <Form.Row>
                      <Form.Group as={Col} controlId="display-50">
                          <Form.Check
                            checked={display === "50"}
                            type="radio"
                            value="50"
                            name="display"
                            onChange={(event) => setDisplay(event.target.value)}
                            label="50"/>
                      </Form.Group>

                      <Form.Group as={Col} controlId="display-100">
                          <Form.Check
                            checked={display === "100"}
                            type="radio"
                            value="100"
                            name="display"
                            onChange={(event) => setDisplay(event.target.value)}
                            label="100"/>
                      </Form.Group>

                      <Form.Group as={Col} controlId="display-200">
                          <Form.Check
                            checked={display === "200"}
                            type="radio"
                            value="200"
                            name="display"
                            onChange={(event) => setDisplay(event.target.value)}
                            label="200"/>
                      </Form.Group>

                      <Form.Group as={Col} controlId="display-1000">
                          <Form.Check
                            checked={display === "1000"}
                            type="radio"
                            value="1000"
                            name="display"
                            onChange={(event) => setDisplay(event.target.value)}
                            label="1000"/>
                      </Form.Group>

                      <Form.Group as={Col} controlId="display-all">
                          <Form.Check
                            checked={display === "5000"}
                            type="radio"
                            value="5000"
                            name="display"
                            onChange={(event) => setDisplay(event.target.value)}
                                label={<FormattedMessage id="GENERAL.ALL"/>}/>
                      </Form.Group>
                  </Form.Row>

                  <Button onClick={fetchData} variant="success" size="sm" block>
                      <FormattedMessage id="GENERAL.FILTER"/>
                  </Button>
                  <hr/>

                  <Link className="btn btn-primary btn-block btn-sm disabled" to={`/signals/${deviceId}`}>
                      <FormattedMessage id="ROUTES.SHOW_ROUTES"/>
                  </Link>

                  <Link className="btn btn-primary btn-block btn-sm" to={`/geofence-reports/${deviceId}`}>
                      <FormattedMessage id="QMENU.GEOFENCE_REPORTS"/>
                  </Link>

                  <Link className="btn btn-primary btn-block btn-sm" to={`/geofence-reports-2/${deviceId}`}>
                      <FormattedMessage id="QMENU.GEOFENCE_REPORTS"/> - 2
                  </Link>

                  <Link className="btn btn-primary btn-block btn-sm" to={`/speed-report/${deviceId}`}>
                      <FormattedMessage id="USER_RIGHTS.speed"/>
                  </Link>

                  <Button variant="primary" size="sm" block onClick={handleExport}>
                      <FormattedMessage id="GENERAL.EXPORT_EXCEL"/>
                  </Button>

              </Col>
              <Col sm={9}>
                  <div style={{display: showMap ? 'block' : 'none'}}>
                      <RouteMap device={device} signals={signals} {...props} />
                  </div>
                  <Row>
                      <Col sm={12}>
                          <Table className="table" responsive="sm">
                              <thead>
                              <tr>
                                  <th className="success"><FormattedMessage id="SERVICE.VEHICLE_NAME"/>: {device.name} </th>
                                  <th className="warning"><FormattedMessage id="GENERAL.TOTAL"/>: {signals.length} </th>
                                  <th className="info"><FormattedMessage id="HOME.SHOWING"/>: 0 - {display} </th>
                              </tr>
                              </thead>
                          </Table>
                      </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                      <Pagination size="sm">
                          {pageItems()}
                      </Pagination>
                  </Row>
                  <Row>
                      <Col sm={12}>
                          <Table striped className="table" responsive="sm">
                              <thead>
                              <tr>
                                  <th><FormattedMessage id="GENERAL.DATE"/></th>
                                  <th><FormattedMessage id="GENERAL.CITY"/></th>
                                  <th><FormattedMessage id="REPORTS.POST_CODE"/></th>
                                  <th/>
                                  <th><FormattedMessage id="GENERAL.ADDRESS"/></th>
                                  <th><FormattedMessage id="SIGNALS.SIGNAL_TYPE"/></th>
                                  <th>
                                      <table className="table">
                                          <tbody>
                                          <tr>
                                              <td colSpan="6"><FormattedMessage id="SIGNALS.LOC_CHG"/></td>
                                          </tr>
                                          <tr>
                                              <td>1</td>
                                              <td>2</td>
                                              <td>3</td>
                                              <td>4</td>
                                              <td>5</td>
                                              <td>6</td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </th>
                                  <th><FormattedMessage id="HOME.SPEED"/> (km/h)</th>
                                  <th><FormattedMessage id="SIGNALS.SIGNAL_STR"/></th>
                                  <th><FormattedMessage id="SIGNALS.NR_SAT"/></th>
                                  <th><FormattedMessage id="SIGNALS.AKU_VOLT"/> (V)</th>
                                  <th><FormattedMessage id="SIGNALS.BAT_VOLT"/> (V)</th>
                              </tr>
                              </thead>
                              <tbody>
                              {loading ? (
                                <tr>
                                    <td colSpan="12">
                                        <img src={require('../Assets/images/horizontal-loading.gif').default} alt={'loading'}/>
                                    </td>
                                </tr>
                              ) : (
                                signalsWithAddress.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                          <td>{moment(item.geocode_sent_at).format("DD-MM-YYYY H:mm:ss")}</td>
                                          <td>{item.city}</td>
                                          <td>{item.postcode}</td>
                                          <td>
                                              <a target="_blank"
                                                 href={`http://www.google.com/maps/place/${item.dec_lat},${item.dec_long}/@${item.dec_lat},${item.dec_long},423m/data=!3m1!1e3?hl=en&gl=US`}>
                                                  <img src={require('../Assets/images/map_link2.png').default} alt={'map_link2'}/>
                                              </a>
                                          </td>
                                          <td>{item.address}</td>
                                          <td>{getSignalMode(item.signal_mode)}</td>
                                          <td>
                                              <img src={require(`../Assets/images/icon_${item.input_1}.png`).default} alt={'input_1'}/>&nbsp;
                                              <img src={require(`../Assets/images/icon_${item.input_2}.png`).default} alt={'input_2'}/>&nbsp;
                                              <img src={require(`../Assets/images/icon_${item.input_3}.png`).default} alt={'input_3'}/>&nbsp;
                                              <img src={require(`../Assets/images/icon_${item.input_4}.png`).default} alt={'input_4'}/>&nbsp;
                                              <img src={require(`../Assets/images/icon_${item.input_5}.png`).default} alt={'input_5'}/>&nbsp;
                                              <img src={require(`../Assets/images/icon_${item.input_6}.png`).default} alt={'input_6'}/>
                                          </td>
                                          <td>{item.speed_km}</td>
                                          <td>{item.signal_strenght}</td>
                                          <td>{item.nr_sat}</td>
                                          <td>{item.input_1_voltage}</td>
                                          <td>{item.input_2_voltage}</td>
                                      </tr>
                                    )
                                })
                              )}
                              </tbody>
                          </Table>
                      </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                      <Pagination size="sm">
                          {pageItems()}
                      </Pagination>
                  </Row>
              </Col>
          </Row>
          <MessageWindow
            size='md'
            show={showMessage.status}
            onHide={() => setShowMessage({...showMessage, status: false})}
            title={showMessage.title}
            message={showMessage.message}
          />
      </>
    )
}

export default Signals;