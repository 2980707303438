const langNl = {
    nl: {
        "LANG.TR": "Türkçe",
        "LANG.EN": "İngilizce",
        "LANG.DE": "Almanca",
        "LANG.NL": "Hollandaca",
        "GENERAL.DEVICES": "Voertuig",
        "GENERAL.DRIVERS": "Bestuurders",
        "GENERAL.DATE": "Data",
        "GENERAL.KM": "Kilometer",
        "GENERAL.PRIVATE": "Prive",
        "GENERAL.SAVE": "Opslaan",
        "GENERAL.SAVE_ALL": "bewaar alles",
        "GENERAL.START": "Begin",
        "GENERAL.STOP": "Eind",
        "GENERAL.ADDRESS": "Adres",
        "GENERAL.START_DATE": "Begin Tijd",
        "GENERAL.END_DATE": "Eind Tijd",
        "GENERAL.START_ADDRESS": "Begin Adres",
        "GENERAL.END_ADDRESS": "Eind Adres",
        "GENERAL.TRIP_TIME": "Gemaamkt tijd",
        "GENERAL.WAIT_TIME": "Wacht Tijd",
        "GENERAL.IDLE_TIME": "Onsteken Tijd",
        "GENERAL.ACTIVE_TIME": "Aktief Tijd",
        "GENERAL.TOTAL_TIME": "Totaal Tijd",
        "GENERAL.TIME": "Tijd",
        "GENERAL.TOTAL": "Totaal",
        "GENERAL.DRIVER": "Bestuurders",
        "GENERAL.YEAR": "Jaar",
        "GENERAL.MONTH": "Maand",
        "GENERAL.DAY": "Dag",
        "GENERAL.DAYS": "Dagen",
        "GENERAL.KMH": "km/s",
        "GENERAL.HOUR": "Uur",
        "GENERAL.MINUTE": "Minuut",
        "GENERAL.MIN": "Min.",
        "GENERAL.SECOND": "Seconde",
        "GENERAL.SEC": "Sn.",
        "GENERAL.TURN_BACK": "Terug ",
        "GENERAL.YES": "Ja",
        "GENERAL.NO": "Nee",
        "GENERAL.NR": "No",
        "GENERAL.DEMO": "Demo",
        "GENERAL.BUY": "koop!",
        "GENERAL.NO_DATA": "Geen Data",
        "GENERAL.ERROR": "probeer nog een keer is fout opgetreden.",
        "GENERAL.PLS_PICK_DEVICE": "Selecteren een voertuig",
        "GENERAL.PICK_DEVICE": "Kies voertuig",
        "GENERAL.PICK_DRIVER": "Selecteer Bestuur",
        "GENERAL.CLOSE": "Dicht",
        "GENERAL.PLEASE_WAIT": "wachten a.u.b...",
        "GENERAL.ADD": "Toevoegen",
        "GENERAL.EDIT": "Aanpassen",
        "GENERAL.DELETE": "Verwijderen",
        "GENERAL.FILTER": "Filter",
        "GENERAL.FILTER_OPTIONS": "Filter opties",
        "GENERAL.PRINT": "Print",
        "GENERAL.EXPORT_PDF": "PDF Map",
        "GENERAL.EXPORT_EXCEL": "Excel Map",
        "GENERAL.UNDEFINED": "Onbekend",
        "GENERAL.USER_NAME": "Gebruiksnaam",
        "GENERAL.PASSWORD": "Wachtwoord",
        "GENERAL.PASSWORD_AGAIN": "Wachtwoord (nog een keer)",
        "GENERAL.NAME": "Naam",
        "GENERAL.SURNAME": "Achternaam",
        "GENERAL.MAIL": "E-Mail",
        "GENERAL.CITY": "Stad",
        "GENERAL.CHANGE": "Wijzigen",
        "GENERAL.ALL": "Alles",
        "GENERAL.PASSIVE": "Pasief",
        "GENERAL.SERVICE": "Dienst",
        "GENERAL.REQUIRED_FIELDS": "Verplichten velden",
        "GENERAL.SELECT": "Kies",
        "GENERAL.SELECTED": "is al gekozen",
        "GENERAL.ACTIVE": "Aktief",
        "GENERAL.SMS": "SMS",
        "GENERAL.NOTIFICATIONS": "Meldingen",
        "GENERAL.FAX": "Fax",
        "GENERAL.TEL": "Tel",
        "GENERAL.DESCRIPTION": "Opmerking",
        "GENERAL.TOTAL_MILEAGE": "Totaal gemaakte KM",
        "GENERAL.OK": "Ok",
        "GENERAL.NEW": "Nieuw",
        "GENERAL.CANCEL": "Annuleren",
        "GENERAL.DEVICE_NAME": "Naam voertuig",
        "GENERAL.MORNING": "Ochtend",
        "GENERAL.EVENING": "Avond",
        "GENERAL.AUTO_RELOAD_ON": "Klik op de pagina elke 2 minuten laten vernieuwen.",
        "GENERAL.AUTO_RELOAD_OFF": "Pagina 2 minuten verfrissend..",
        "GENERAL.PREVIOUS": "Vorig",
        "GENERAL.NEXT": "Volgende",
        "GENERAL.WARNING": "Waarschuwing",
        "GENERAL.RESULTS_PER_PAGE": "Resultaat per pagina :",
        "GENERAL.PREV_DAY": "vorige Dag",
        "GENERAL.NEXT_DAY": "Volgende Dag",
        "GENERAL.ICON": "İcon",
        "GENERAL.MAIL_WRITE": "Uw e-mail adres",
        "GENERAL.PHONE_WRITE": "Uw telefoon nummer",
        "GENERAL.CHOOSE": "Kies",
        "GENERAL.STEP": "Stap",
        "GENERAL.SAVE_FOR_ALL_DEVICE": "Opslaan voor alle voertuig",
        "GENERAL.SEARCH": "Search",
        "GENERAL.FILTER_RESULTS": "GENERAL.FILTER_RESULTS",
        "GENERAL.USER": "User",
        "GENERAL.INACTIVE": "Inactive",
        "GENERAL.TODAY": "Today",
        "GENERAL.21_FUNCTIONS": "21 Funktionen auf einen Blick!",
        "GENERAL.TOGGLEALL": "Toggle All",
        "GENERAL.PHONE_NUMBER": "020 894 3436",
        "GENERAL.PHONE_NUMBER_TEXT": "Techniche ondersteuning",
        "GENERAL.IDLE": "Leerlauf - Zündung An",
        "GENERAL.ONE_HOUR": "Kein GPS/GSM Seit 1 Std",
        "GENERAL.ONE_HOUR_LINK": "Geräte ohne aktuelle Position seit 1 Std",
        "GENERAL.LATEST_SIGNAL_DATE": "Letzte Signale Datum",
        "GENERAL.NORESULT": "Geen resultaten",
        "GENERAL.NO_ACCESS": "You can not buy this option with online. Please contact with support 030 3553 0677 0",
        "GENERAL.HIDE_DEVICE_CONTENT": "Privérit geen verzamelen van gegevens!",
        "GENERAL.HIDE_ATTENTION": "Let op! in active selectie een privé reis vindt plaats zonder het invoeren van gegevens!",
        "GENERAL.DISABLE": "Passief",
        "GENERAL.SOMETHING_WENT_WRONG": "Er ging iets mis. Vernieuw de pagina.",
        "GENERAL.HOURLY_REPORT": "Stündlicher Bericht",
        "DAYS.MONDAY": "Maandag",
        "DAYS.TUESDAY": "Dinsdag",
        "DAYS.WEDNESDAY": "Woensdag",
        "DAYS.THURSDAY": "Donderdag",
        "DAYS.FRIDAY": "Vrijdag",
        "DAYS.SATURDAY": "Zaterdag",
        "DAYS.SUNDAY": "Zondag",
        "DAYS_NUMERIC.DAY_1": "Maandag",
        "DAYS_NUMERIC.DAY_2": "Dinsdag",
        "DAYS_NUMERIC.DAY_3": "Woensdag",
        "DAYS_NUMERIC.DAY_4": "Donderdag",
        "DAYS_NUMERIC.DAY_5": "Vrijdag",
        "DAYS_NUMERIC.DAY_6": "Zaterdag",
        "DAYS_NUMERIC.DAY_0": "Zondag",
        "MONTHS_NUMERIC.MONTH_1": "Januari",
        "MONTHS_NUMERIC.MONTH_2": "Februari",
        "MONTHS_NUMERIC.MONTH_3": "Maart",
        "MONTHS_NUMERIC.MONTH_4": "April",
        "MONTHS_NUMERIC.MONTH_5": "Mei",
        "MONTHS_NUMERIC.MONTH_6": "Juni",
        "MONTHS_NUMERIC.MONTH_7": "Juli",
        "MONTHS_NUMERIC.MONTH_8": "Agustus",
        "MONTHS_NUMERIC.MONTH_9": "September",
        "MONTHS_NUMERIC.MONTH_10": "Oktober",
        "MONTHS_NUMERIC.MONTH_11": "November",
        "MONTHS_NUMERIC.MONTH_12": "December",
        "HOME.SORT": "Soorteren",
        "HOME.SORT_ALL_DEVICES": "Soorteren alle objecten/voertuigen.",
        "HOME.SORT_BY_GROUP": "İn groep soorteren.",
        "HOME.FIRST_RUN": "Eerste werkdag",
        "HOME.IGNITION": "Ontsteking",
        "HOME.DAILY_MILEAGE": "Dagelijks Km",
        "HOME.SPEED": "Snelheid",
        "HOME.RESULTS": "Resultaat",
        "HOME.SHOWING": "Weergaven",
        "HOME.DISPLAY": "Weergave",
        "HOME.LIST_TYPE": "Lijst Type",
        "HOME.LIST_TYPE_STANDART": "Standaart",
        "HOME.LIST_TYPE_STANDART_BIG": "Standaart Nieuw",
        "HOME.LIST_TYPE_KLEIN": "Klein",
        "HOME.LIST_TYPE_MINI": "Mini",
        "HOME.LIST_TYPE_GROSSANSICHT": "Grossansicht",
        "HOME.LIST_TYPE_DETAILERT": "Gedetailleerd",
        "HOME.LIST_TYPE_ROUND": "Ronde",
        "HOME.LIST_TYPE_MODERN": "Modern",
        "HOME.OPTIONS": "Opties",
        "HOME.DEVICE_SEARCH": "Voertuig zoeken",
        "HOME.ALL_ROUTES": "Alle Routes",
        "HOME.CUSTOMER_REPORTS": "Klanten Rapporten",
        "HOME.SHOW_SELECTED": "toon geslecteerde voertuig",
        "HOME.DEVICE_FILTER": "Voertuig weergave",
        "HOME.CURRENT_COORDINATES": "Recente Coordinatie's",
        "HOME.COPY_COORDINATES": "Kopieer de Coordinaten met CTRL-C en plak deze in de vak adres zoeken met CTRL-V.",
        "HOME.ROUTE_TRACKING": "Geplande route scherm",
        "HOME": "Home",
        "MENU.ALL_DEVICES": "Alle Voertuigen",
        "MENU.ROUTES": "Route Selecteren",
        "MENU.REPORTS": "Elektronische Berichten",
        "MENU.REPORTS2": "Rapporten",
        "MENU.FUNCTIONS": "Andere Functies",
        "MENU.THEFT": "Bescherming voor Diefstal",
        "MENU.SERVICE": "Service",
        "MENU.RENT": "Rent A Car",
        "MENU.GROUPS": "Groepen vooertuig",
        "MENU.USER_RIGHTS": "Toegangsrecheten",
        "MENU.TECHNICAL_SUPPORT": "Tecnische Diensten",
        "MENU.APPLICATIONS": "Gratis APPLICATIONS",
        "MENU.OPINIONS": "Uw Mening",
        "MENU.LANGUAGE": "Taal ",
        "MENU.TIMEZONE": "Tijd Zone",
        "MENU.SETTINGS": "İnstelling",
        "MENU.LOGOUT": "Loguit",
        "MENU.DRIVERS_LIST": "Bestuur Lijst",
        "MENU.MYLOCATIONS": "Mijn Locaties",
        "MENU.CUSTOMER_GROUPS": "Klant Groepen",
        "MENU.SMS_CREDITS": "SMS Orders",
        "MENU.APPS": "Kostenlose Apple-Android Programme --çeviri",
        "MENU.ANDROID_APP": "Horizont_Ortungssysteme app voor Android",
        "MENU.APPLE_APP": "Horizont_Ortungssysteme app voor Apple",
        "MENU.INSTRUCTIONS": "Handleiding",
        "MENU.APPLE_APP2": "Horizont Ortungssysteme app voor Apple",
        "MENU.SAVED_PLACES": "Route-query opgeslagen locaties",
        "MENU.EDIT_PLACES": "Locaties bewerken voor het zoeken van de route",
        "MENU.PLACES": "Route polling de selectie van locaties",
        "MENU.ROUTE_TRACKING": "Geplande route scherm",
        "MENU.GRAPHIC": "Tabel",
        "MENU.AUFTRAGS": "Auftrags App",
        "MENU.WEB_INTEGRATION": "Webintegratie in uw website",
        "MENU.NOTIFICATION_SETTINGS": "Air Alarm",
        "MENU.DUAL_SCREEN": "Dual Screen",
        "MENU.LATEST_LOGIN": "Laatste invoer",
        "MENU.LOGIN_DATE": "İngangstijd",
        "MENU.PLATFORM": "Platform",
        "MENU.BROWSER": "Browser",
        "MENU.BROWSER_VERSION": "Browserversie",
        "MENU.LOGIN_DEVICES": "Aangemelden Acountten",
        "MENU.APP_VERSION": "App Versie",
        "MENU.PLATFORM_VERSION": "Platform Versie",
        "MENU.DATA_DELETION": "Daten Löschen",
        "TEMPLATES.CHOOSE": "Thema ",
        "TEMPLATES.TEMPLATE_DEFAULT": "Varsayılan",
        "TEMPLATES.TEMPLATE_1": "Thema 1",
        "TEMPLATES.TEMPLATE_2": "Thema 2",
        "TEMPLATES.TEMPLATE_3": "Thema 3",
        "TEMPLATES.TEMPLATE_4": "Thema 4",
        "TEMPLATES.TEMPLATE_5": "Thema 5",
        "TEMPLATES.TEMPLATE_6": "Thema 6",
        "QMENU.LOCATION": "Locatie",
        "QMENU.ROUTE": "Route",
        "QMENU.LIVE": "Live",
        "QMENU.DAILY_REPORT": "Dagelijkse Rapport",
        "QMENU.MONTHLY_REPORT": "Maand Rapport",
        "QMENU.SPEED_CHART": "Snelheid Grafiek",
        "QMENU.FUEL": "brandstof ",
        "QMENU.AUTO_PILOT": "Auto Pilot",
        "QMENU.GRAPHS": "Grafieken",
        "QMENU.GEOFENCE_REPORTS": "Geofence Rapporten",
        "QMENU.OTHER": "Andere Functies",
        "QMENU.COLORED_ROUTE": "Gekleurde Route",
        "QMENU.AUTO_PLAY": "Auto Play",
        "GRAPHS.START_STOP_TIMES": "Begin - Eind uren",
        "GRAPHS.LAST_SEVEN_DAY": "Dagelijks rijden perioden",
        "GRAPHS.LAST_SEVEN_DAY_KM": "Dagelijks gereden routes",
        "GRAPHS.DRIVE_TIME": "Hoe lang gereden",
        "GRAPHS.DRIVE_KM": "Totaal Km",
        "GRAPHS.TODAY_SPEED": "De snelheid tijd / tijd",
        "GRAPHS.YESTER_CON": "Transactie Status van gisteren",
        "GRAPHS.MAX_SEVEN": "U kunt het datumbereik maximaal 1 week.",
        "GRAPHS.ALLDEVKM": "totaal gemaakte km ",
        "GRAPHS.ALLDEVSPEED": "Voertuig Snelheid",
        "GRAPHS.AVG_SPEED": "Gemiddelde snelheid",
        "GRAPHS.MAX_SPEED": "Max snelheid",
        "GRAPHS.downloadJPEG": "JPEG Foto douwload",
        "GRAPHS.downloadPNG": "PNG foto douwload",
        "GRAPHS.downloadSVG": "SVG douwload",
        "GRAPHS.downloadPDF": "PDF Douwload",
        "GRAPHS.printChart": "Grafiek uit printen",
        "GRAPHS.loading": "Laden...",
        "GRAPHS.contextButtonTitle": "Grafiek Menu",
        "GRAPHS.drillUpText": "{series.name} Back",
        "GRAPHS.invalidDate": "onbekend date",
        "GRAPHS.noData": "geen netwerk",
        "GRAPHS.resetZoom": "Zoom' op nul",
        "GRAPHS.ACT_STATUS": "Totaal routes weergave",
        "GRAPHS.FUEL_CHART": "De hoeveelheid brandstof dagelijkse grafiek",
        "GRAPHS.FUEL_TABLE": "De hoeveelheid brandstof Per dag",
        "GRAPHS.START_FUEL": "Begin Brandstof liter",
        "GRAPHS.END_FUEL": "Eind Brandstof liter",
        "GRAPHS.FUEL_CONSUME": "Brandstof verbruik",
        "GRAPHS.FUEL_BUY": "Brandstof",
        "GRAPHS.TEMP_CHART": "de hoeveelheid temperatuur ",
        "GRAPHS.TEMP_TABLE": "de hoeveelheid in temp actief",
        "GRAPHS.TEMP_AVG": "Dagelijkse gemiddelde temparatuur",
        "GRAPHS.FUEL_PER": "Brandstof verbruik",
        "GRAPHS.FUEL_LT": "Brandstof Lijst",
        "GRAPHS.WEATHER_FORECAST": "Weervoorspelling",
        "GRAPHS.FUEL_FULLING": "Benzin füllungsliste",
        "GRAPHS.RPM": "RPM",
        "GRAPHS.RPM_CHART": "Günlük Devir Grafiği",
        "GRAPHS.FUEL_GES": "Kilometerstand beim Tanken KM",
        "GRAPHS.FUEL_GETANKE": "Getankte Liter",
        "GRAPHS.VOLLTANK": "Details zum Volltank",
        "GRAPHS.WARM_CHART": "Motor Sıcaklığı Grafiği",
        "SIGNALS.SHOW_MAP": "Toon Kaart",
        "SIGNALS.SHOW_STREET_NAMES": "Naam van straat aantonen",
        "SIGNALS.SHOW_SIGNALS": "Signaal overzicht",
        "SIGNALS.ONLY_STARTSTOP": " Only start tijd",
        "SIGNALS.EXCEPT_ZERO": "Zeige nur Geschwindigkeit Informationen",
        "SIGNALS.SHOW_THESE": "Laten sensoren zien",
        "SIGNALS.SIGNAL_TYPE": "Signaal Typ",
        "SIGNALS.LOC_CHG": "Veranderen Locaties",
        "SIGNALS.SIGNAL_STR": "Signaal sterkte",
        "SIGNALS.NR_SAT": "Aantal sataliet",
        "SIGNALS.AKU_VOLT": "Acu status",
        "SIGNALS.BAT_VOLT": "Batterij status",
        "SIGNALS.IN_OUT": "İn / Uit",
        "SIGNALS.TOTAL_DURATION": "Totaal Tijd",
        "SIGNALS.IN": "İngang",
        "SIGNALS.OUT": "Uit",
        "SIGNALS.MAX_3_DAYS": "Er mag max 3 dagen geslecteren.",
        "SIGNALS.signal_mode_0": "Periodic Signal",
        "SIGNALS.signal_mode_1": "--",
        "SIGNALS.signal_mode_2": "Stand By",
        "SIGNALS.signal_mode_3": "Start",
        "SIGNALS.signal_mode_4": "Stop",
        "SIGNALS.signal_mode_5": "--",
        "SIGNALS.signal_mode_6": "--",
        "SIGNALS.signal_mode_7": "--",
        "SIGNALS.signal_mode_8": "--",
        "SIGNALS.signal_mode_9": "--",
        "SIGNALS.signal_mode_10": "--",
        "SIGNALS.signal_mode_11": "Digital Input 2 ON",
        "SIGNALS.signal_mode_12": "Digital Input 2 OFF",
        "SIGNALS.signal_mode_13": "Digital Input 3 ON",
        "SIGNALS.signal_mode_14": "Digital Input 3 OFF",
        "SIGNALS.signal_mode_15": "Digital Input 4 ON",
        "SIGNALS.signal_mode_16": "Digital Input 4 OFF",
        "SIGNALS.signal_mode_17": "--",
        "SIGNALS.signal_mode_18": "--",
        "SIGNALS.signal_mode_19": "--",
        "SIGNALS.signal_mode_20": "--",
        "SIGNALS.signal_mode_21": "--",
        "SIGNALS.signal_mode_22": "--",
        "SIGNALS.signal_mode_23": "--",
        "SIGNALS.signal_mode_24": "--",
        "SIGNALS.signal_mode_25": "--",
        "SIGNALS.signal_mode_26": "--",
        "SIGNALS.signal_mode_27": "--",
        "SIGNALS.signal_mode_28": "--",
        "SIGNALS.signal_mode_29": "--",
        "SIGNALS.signal_mode_30": "--",
        "SIGNALS.signal_mode_31": "--",
        "SIGNALS.signal_mode_32": "--",
        "SIGNALS.signal_mode_33": "--",
        "SIGNALS.signal_mode_34": "--",
        "SIGNALS.signal_mode_35": "--",
        "SIGNALS.signal_mode_36": "--",
        "SIGNALS.signal_mode_37": "--",
        "SIGNALS.signal_mode_38": "--",
        "SIGNALS.signal_mode_39": "--",
        "SIGNALS.signal_mode_40": "--",
        "SIGNALS.signal_mode_41": "--",
        "SIGNALS.signal_mode_42": "--",
        "SIGNALS.signal_mode_43": "--",
        "SIGNALS.signal_mode_44": "--",
        "SIGNALS.signal_mode_45": "--",
        "SIGNALS.signal_mode_46": "--",
        "SIGNALS.signal_mode_47": "--",
        "SIGNALS.signal_mode_48": "--",
        "SIGNALS.signal_mode_49": "--",
        "SIGNALS.signal_mode_50": "--",
        "SIGNALS.signal_mode_51": "--",
        "SIGNALS.signal_mode_52": "--",
        "SIGNALS.signal_mode_53": "--",
        "SIGNALS.signal_mode_54": "--",
        "SIGNALS.signal_mode_55": "--",
        "SIGNALS.signal_mode_56": "--",
        "SIGNALS.signal_mode_57": "--",
        "SIGNALS.signal_mode_58": "--",
        "SIGNALS.signal_mode_59": "--",
        "SIGNALS.signal_mode_60": "--",
        "SIGNALS.signal_mode_61": "--",
        "SIGNALS.LAT": "Breitengrad",
        "SIGNALS.LONG": "Längengrad",
        "SERVICE.VEHICLE_NAME": "Naam Voertuig",
        "SERVICE.CURRENT_MILEAGE": "İnstand KM",
        "SERVICE.INSTALL_MILEAGE": "Begin Km",
        "SERVICE.AVG_FUEL": "Gemiddelde Brandstof verbruik",
        "SERVICE.CURRENT_RUN_TIME": "İnstand werk tijd",
        "SERVICE.INSTALL_RUN_TIME": "Begin werk tijd",
        "SERVICE.EMAIL_ADDRESS": "Service Alert E-mails adressen",
        "SERVICE.INFORMED_BY_MAIL": "Service Alert E-mails adressen ontvangen",
        "SERVICE.CURRENT_ICON": "Voertuig Icon",
        "SERVICE.ICON_GALLERY": "ICcn Galerie",
        "SERVICE.CHOOSE_NEW_ICON": "Kies nieuwe Icon",
        "SERVICE.BASIC_SETTINGS": "İnstellingen",
        "SERVICE.IGNITION": "Contact",
        "SERVICE.IGNITIONS": "Contacten",
        "SERVICE.OIL_SERVICE": "Olie service",
        "SERVICE.BRAKE_SERVICE": "Rem Service",
        "SERVICE.BELT_SERVICE": "Riem service",
        "SERVICE.INSPECTION_SERVICE": "APK",
        "SERVICE.TACHOGRAPH_SERVICE": "Takograaf Service",
        "SERVICE.TRAILER_SERVICE": "Aanhangwagen service",
        "SERVICE.HIDE_SERVICE": "Voertuig verbergen",
        "SERVICE.HIDE_SERVICE_DATA": "Privé-reizen data-opslag",
        "SERVICE.FUEL_SERVICE": "Brandstof Sensoren",
        "SERVICE.TEMP_SERVICE": "temparatuur Sensoren",
        "SERVICE.SERVICE_INTERVAL": "Service tijd",
        "SERVICE.NEW_SERVICE_HISTORY": "Nieuwe service openen",
        "SERVICE.CREATE_SERVICE_HISTORY": "Nieuwe service openen",
        "SERVICE.DELETE_SERVICE_HISTORY": "Verwijderen",
        "SERVICE.DELETE_CONFIRM": "Wilt u zeker om te Verwijderen?",
        "SERVICE.DELETE_SUCCESS": "is Verwijdeerd",
        "SERVICE.DELETE_ERROR": "is niet verwijdeerd, fout opgetreden.",
        "SERVICE.BACK_TO_SERVICE": "terug naar service pagina",
        "SERVICE.SERVICE_HISTORY": "Historie van service balk",
        "SERVICE.UPDATE_SUCCESS": "is opgelagen.",
        "SERVICE.UPDATE_ERROR": "Er is een fout opgetreden tijdens het opslaan. Probeer het a.u.b. opnieuw.",
        "SERVICE.INTERVAL_MONTH": "Tijd (Maand)",
        "SERVICE.ACTIVATION_SETTINGS": "İnstelling actieveren",
        "SERVICE.DATE_RANGE": "data overzicht",
        "SERVICE.ACTIVE_STATUS": "Aktief status",
        "SERVICE.RESET_DATES": "Reset datas",
        "SERVICE.RESET_ALL_DATA": "Reset alle datas",
        "SERVICE.ROMORK_PLATE": "Kenteken Aanhangwagen",
        "SERVICE.ALERT_TOP": "Reset alle service!",
        "SERVICE.ALERT_KM": "De resterende kilometers de volgende service:",
        "SERVICE.ALERT_DATE": "De service op de volgende tijd resterende (dagen):",
        "SERVICE.ALERT_KM_EXCEEDED": "U kijkt verleden dienst kilometer Controleer uw verleden.",
        "SERVICE.ALERT_DATE_EXCEEDED": "Datum van dienst geschiedenis, historie, check service.",
        "SERVICE.INTERVAL_INFO": "Als de frequentie van kilometers of maandelijkse gebaseerde service kunt u",
        "SERVICE.CREATE_HISTORY_INFO": "wilt u nieuwe Km en date strip İnstellen klip op Box",
        "SERVICE.IN_DEMO": "U gebruikt de demo-versie van deze eigenschap.",
        "SERVICE.DEMO_EXPIRED": "De demo van deze eigenschap voor het apparaat dat u hebt geselecteerd is verlopen.",
        "SERVICE.DEMO_OR_BUY": "Als u deze functie wilt gebruiken voor het apparaat wilt u hebt geselecteerd, kunt u nu kopen, of u kunt de 10-daagse proefversie",
        "SERVICE.NOT_PERMITTED": "Er is geen toestemming voor het gebruik van deze functie voor het geselecteerde apparaat.",
        "SERVICE.NOT_PERMITTED_MULTI": "Sommige van het apparaat dat u hebt geselecteerd, of heeft geen toestemming voor het gebruik van deze functie voor alle.",
        "SERVICE.IVDC_DATE_ALERT": "Begindatum kan niet groter zijn dan einddatum",
        "SERVICE.HIDE_DEVICE_FOR_TIME": "Hide vehicle for specific days",
        "SERVICE.HIDE_DEVICE_FOR_ALWAYS": "Hide vehicle always",
        "SERVICE.HIDE_DEVICE_FOR_ALWAYS_COMMENT": "Hide the selected vehicle always",
        "SERVICE.HIDE_ALWAYS": "Hide Device",
        "SERVICE.SHOW_ALWAYS": "Show Device",
        "SERVICE.DEVICE_HIDDEN": "This device is hidden.",
        "SERVICE.DEVICE_PRIVATE": "Private driving is currently active. No data is saved!",
        "SERVICE.IVDC_INFO": "When you made your date and time choices, check this to activate your settings",
        "SERVICE.SERVICE_ALERT": "Service Melding!",
        "SERVICE.SERVICE_TYPE_1": "Olie Service",
        "SERVICE.SERVICE_TYPE_2": "Rem Service",
        "SERVICE.SERVICE_TYPE_3": "Riem Service",
        "SERVICE.SERVICE_TYPE_4": "APK",
        "SERVICE.SERVICE_TYPE_5": "Takograaf",
        "SERVICE.SERVICE_TYPE_6": "Aanhangwagen",
        "SERVICE.POSTPONE_ALERTS": "Servicemenu niet tonen voor 7 dagen",
        "SERVICE.PRIVATE_DRIVE": "Wenn Sie aktivieren, werden die Daten niemals aufgezeichnet!",
        "SERVICE.PRIVATE_DRIVE_FOR_TIME": "Private drive for a certain time. Please select the period.",
        "SERVICE.PRIVATE_DRIVE_FOR_ALWAYS": "Permanent private drive",
        "SERVICE.PRIVATE_DRIVE_FOR_ALWAYS_COMMENT": "Please click for a permanent private drive. If you activate the feature, data will not be recorded!",
        "SERVICE.PRIVATE_SHOW_ALWAYS": "Private Drive Deactivation",
        "SERVICE.PRIVATE_HIDE_ALWAYS": "Private Drive Activation",
        "SERVICE.FUEL_INFO2": "Na de installatie voert u 'Autoservice sectie zich onder de brandstof sensor, de hoeveelheid brandstof in procenten. Vervolgens worden de gegevens controleren mikltar brandstof naar de portal te gaan.",
        "SERVICE.STEP1": "Stap 1",
        "SERVICE.STEP2": "Stap 2",
        "GROUPS.ALL": "Alle groepen",
        "GROUPS.NAME": "Groep Naam",
        "GROUPS.UPDATE": "Groep vernieuwen",
        "GROUPS.CREATE": "Maak een Groep",
        "GROUPS.EDIT_NAME": "Naam van groep aanpassen",
        "GROUPS.DELETE": "Groep verwijderen",
        "GROUPS.CONFIRM_DELETE": "wilt u zeker om verwijderen?",
        "GROUPS.DELETE_SUCCESS": "is verwijdeerd.",
        "GROUPS.DELETE_ERROR": "Er is een fout opgetreden tijdens verwijderen.",
        "GROUPS.CREATE_NEW_GROUP": "Maak nieuwe groep",
        "GROUPS.FILL_GROUP_NAME": "Maak nieuwe groep op andere naam",
        "GROUPS.DEVICES_IN_GROUP": "Voertuig in groepen",
        "GROUPS.ADD_REMOVE_DEVICE": "Voertuig toevoegen - verwijderen",
        "GROUPS.SELECTED_DEVICES": "gekozen voertuig",
        "GROUPS.DEVICES_TO_ADD": "toevoegen aaan groepen",
        "REPORTS.DAILY": "Dagelijkse Rapporten",
        "REPORTS.MONTHLY": "Maandelijkse Rapporten",
        "REPORTS.ROUTE": "Route",
        "REPORTS.DEVICE_REPORTS": "Voertuigen Rapporten",
        "REPORTS.DRIVER_REPORTS": "Bestuurder Rapporten",
        "REPORTS.WITH_DRIVERS": "Naam van Bestuurder",
        "REPORTS.ONLY_PRIVATE": "Prive routes",
        "REPORTS.MANUAL_TRIP": "Toeveoegen Routes",
        "REPORTS.DAY_TOTAL": "Dagelijkse totaal",
        "REPORTS.ALL_TOTAL": "Algemeen Totaal",
        "REPORTS.ROUTE_NO": "Route No",
        "REPORTS.ADD_TRIP": "Routes toevoegen",
        "REPORTS.EDIT_TRIP": "Rouetes wijziging",
        "REPORTS.ADD_TRIP_INFO": "Klik hier als u wilt toevoegen van een nieuwe Rouetes",
        "REPORTS.DELETE_TRIP_INFO": "Om de route verwijderen",
        "REPORTS.DELETE_TRIP": "Route Verwijderen",
        "REPORTS.CONFIRM_DELETE_TRIP": "wilt u zeker om verwijderen?",
        "REPORTS.DAILY_STANDBY": "Standby tijd",
        "REPORTS.TOTAL_ACTIVE_TIME": "Totaal gamaakte tijd",
        "REPORTS.REPORT_DETAIL": "gedaileerde rapport",
        "REPORTS.ADD_REPORT": "Rapport toevoegen",
        "REPORTS.DELETE_REPORT": "Rapport verwijderen",
        "REPORTS.EDIT_REPORT": "Rapport actuelesieren",
        "REPORTS.PICK_DEVICE_OR_DRIVER": "Selecteren een Bestuur",
        "REPORTS.TRIP_TYPE": "Rit Type",
        "REPORTS.TRIP_TYPE_0": "Onbeken",
        "REPORTS.TRIP_TYPE_1": "Dienst",
        "REPORTS.TRIP_TYPE_2": "Prive",
        "REPORTS.TRIP_TYPE_3": "Thuis",
        "REPORTS.FAVORITES": "Favorieten",
        "REPORTS.PICK_FAV": "kies Favorieten",
        "REPORTS.FAV_DETAIL": "gedaileerde Favorieten",
        "REPORTS.FAV_INFO": "U hebt opgeslagen routes worden toegevoegd aan uw favorieten 'Waar' opgeslagen wordt genavigeerd. Als deze opgeslagen in de tabel hieronder die worden de volgende rit getoond en een laatste adres van de rit",
        "REPORTS.POST_CODE": "Postcode",
        "REPORTS.ABOUT": "Gemaakte route",
        "REPORTS.VISITED": "Bezochte plaats",
        "REPORTS.REPORT_TYPE": "Raporten type",
        "REPORTS.SHOW_DEVICE_NAME": "naam van voertuig tonen",
        "REPORTS.MONTHLY_REPORT_FOR_ALL_DEVICE": "Maandelijkse Raporten alle voertuigen",
        "REPORTS.DAILY_REPORT_FOR_ALL_DEVICE": "Dagelijkse Rapporten alle voertuigen",
        "REPORTS.DAILY_REPORT_THIS_DEVICE": "dagelijkse rapport",
        "REPORTS.SPEED_REPORT_THIS_DEVICE": "snelheid rapport",
        "REPORTS.SELECT_REPORT": "Selecteer gewenste rapporten",
        "REPORTS.YEARLY": "jaarverslag",
        "REPORTS.YEARLY_PDF": "jaarverslag PDF",
        "REPORTS.SELECT_YEAR": "Select jaar",
        "REPORTS.FILTER_BY_KM": "0.0 - 0.2 Weergeven / verbergen",
        "REPORTS.DATE_LIMIT_ALERT": "Auszuwählende max. Datumsspanne:",
        "REPORTS.DATE_LIMIT_ALERT_2": "Um die Datumsspanne zu vergrößern, benötigen Sie ein Paketupgrade. \nBitte kontaktieren Sie unseren Support, damit ein Paketupgrade durchgeführt werden kann.",
        "REPORTS.CANCELED_REPORTS": "abgesagte Berichte",
        "REPORTS.DAYS_7": "7 daagse data-archivering en afstand weergeven",
        "REPORTS.SHOW_SERVICE_TRIPS": "Alleen zakenreizen",
        "REPORTS.REPORT_NO": "Bericht Nummer",
        "ALERT.FILL_THE_BLANKS": "Verplichten velden.",
        "ALERT.UPDATE_SUCCESS": "Informatie is succesvol bijgewerkt.",
        "ALERT.DELETE_SUCCESS": "informatie succesvol verwijderd.",
        "ALERT.SAVE_SUCCESS": "informatie succesvol opgeslagen.",
        "ALERT.DEVICE_DISABLE": "Dit apparaat werd gesloten  bel ons a.u.b.",
        "ALERT.SMS_SENT_SUCCESS": "Sms , succesvol verstuurd.",
        "ALERT.SMS_SENT_FAIL": "Sms fout opgetreden .",
        "USER_RIGHTS.ALL_USERS": "Alle gebruikers",
        "USER_RIGHTS.ADD_REMOVE_USER_RIGHT": "Toegangsrecheten verwijderen - toevoegen",
        "USER_RIGHTS.NEW_USER": "nieuwe gebruikers",
        "USER_RIGHTS.REMOVE_USER": "gebruikers verwijderen",
        "USER_RIGHTS.UPDATE_USER": "gebruikers UPDATE",
        "USER_RIGHTS.USER_STATUS": "gebruikers actief",
        "USER_RIGHTS.ACT_START": "actieveren tijd",
        "USER_RIGHTS.ACT_END": "Aktieveren Eind",
        "USER_RIGHTS.ER_MAIL_AE": "deze mail is al ontvangen.",
        "USER_RIGHTS.ER_UN_AE": "is al in gebruik.",
        "USER_RIGHTS.ER_PW_1": "wachtwoord is bekent. probeer nog een keer.",
        "USER_RIGHTS.ER_NN": "Verplichten velden invullen a.b.u..",
        "USER_RIGHTS.FILL_OBLIGATION": "Verplichten veld.",
        "USER_RIGHTS.ER_MAIL_VAL": "mail adres onbekend.",
        "USER_RIGHTS.NOTHING_FOUND": "er is geen toestemming voor dit gebruikers.",
        "USER_RIGHTS.CHOOSE_USER": "Kkies gebruiksnaam of maak nieuwe gebruiksnaam. ",
        "USER_RIGHTS.CONFIRM_DELETE": "wilt u zeker om verwijderen ? ",
        "USER_RIGHTS.tracking": "volgsysteem",
        "USER_RIGHTS.live": "Live volgen",
        "USER_RIGHTS.all_tracks": "Alle Ritten",
        "USER_RIGHTS.velocity": "Localiecering",
        "USER_RIGHTS.tracking_route": "gedaileerde route",
        "USER_RIGHTS.unlimited_tracking": "7 dagen Historie",
        "USER_RIGHTS.routing": "Route",
        "USER_RIGHTS.daily": "Dagelijkse route",
        "USER_RIGHTS.monthly": "Maandelijkse Rapporten",
        "USER_RIGHTS.drive": "Fahrerbericht",
        "USER_RIGHTS.driver": "Auto Berichte",
        "USER_RIGHTS.sensoren": "Kontaktberichten",
        "USER_RIGHTS.speed": "Verslag van de snelheid",
        "USER_RIGHTS.electronic": "Elektronische Berichten",
        "USER_RIGHTS.theft": "Diefstalbeveiliging",
        "USER_RIGHTS.phone_2": "2. mobielno",
        "USER_RIGHTS.sms": "SMS",
        "USER_RIGHTS.service": "Voertuig service",
        "USER_RIGHTS.device_groups": "voertuig groepen",
        "USER_RIGHTS.roaming1": "Roaming",
        "USER_RIGHTS.zugriffsrechte": "Toegangsrecheten",
        "USER_RIGHTS.gsm_eplus": "GSM E-Plus",
        "USER_RIGHTS.gsm_tmobile": "GSM T-Mobile",
        "USER_RIGHTS.email_berichte": "Dagelijkse / maandelijkse verslagen verstuurd via e-mail",
        "USER_RIGHTS.play": "Animation",
        "USER_RIGHTS.USER_TYPE": "Zugriffslevel",
        "USER_RIGHTS.ACTIVE_USERS": "Active Benutzer",
        "USER_RIGHTS.PASSIVE_USERS": "Inactive Benutzer",
        "USER_RIGHTS.SUBUSER_DEVICE_NAME": "U kunt zender anders benoemen:",
        "USER_RIGHTS.EDIT_DEVICE_NAME": "Subuser can change device name",
        "USER_RIGHTS.autoplay": "Automatisch afspelen",
        "USER_RIGHTS.measure": "meten",
        "USER_RIGHTS.autopilot": "Autopilot",
        "USER_RIGHTS.planned_route": "Geplande route",
        "USER_RIGHTS.location": "Verblijfplaats",
        "USER_RIGHTS.graphical": "Diagram",
        "USER_RIGHTS.fuel": "Aardolie betaling",
        "USER_RIGHTS.optimize": "Routeoptimalisatie",
        "USER_RIGHTS.18_months": "18 Months Data",
        "USER_RIGHTS.ADD_FAVORI": "Add New Favorite",
        "USER_RIGHTS.DELETE_FAVORI": "Delete Favorite",
        "USER_RIGHTS.UPDATE_FAVORI": "Update Favorite",
        "USER_RIGHTS.FAVORI_NAME": "Favorite Name",
        "USER_RIGHTS.NAME_ALARM": "Kijk dan op de Favorite Name Dropping.",
        "USER_RIGHTS.SELECT_FAVORITE": "Selecteer favorieten",
        "USER_RIGHTS.ALARM_OPTIONS": "Selecteer ten minste één optie.",
        "USER_RIGHTS.CONFIRM_FAVORI_DELETE": "Weet u zeker dat u wilt verwijderen?",
        "USER_RIGHTS.SUCCESS_DELETE": "Succesvol wissen.",
        "USER_RIGHTS.SUCCESS_SAVE": "succesvolle Saving",
        "USER_RIGHTS.SUCCESS_UPDATE": "Werk Succesvolle.",
        "USER_RIGHTS.FAVORITE_OPTIONS": "Favoriete Opties",
        "USER_RIGHTS.FAVORITE_FUNC": "Favorieten Functie",
        "USER_RIGHTS.FAVORITE": "Favoriete",
        "USER_RIGHTS.SAVEALL": "Aanpassen voor alle voertuigen",
        "USER_RIGHTS.air_notifications": "Air alarm notifications",
        "RENTACAR.NEW_RENTACAR": "Toevoegen van nieuwe voertuig",
        "RENTACAR.OLD_RENTACAR": "oude klanten gegevens",
        "RENTACAR.IDENTITY_NO": "İD No",
        "RENTACAR.LICENSE_NO": "Rijbewijs No",
        "RENTACAR.LICENSE_CLASS": "Rijbewijs Groep",
        "RENTACAR.BIRTH_DATE": "Geboorte datem",
        "RENTACAR.PHONE_NO": "Telefoon No",
        "RENTACAR.ADDRESS": "Adres",
        "RENTACAR.RENT_DATE": "Begin tijd",
        "RENTACAR.RENT_BACK_DATE": "tot Eind tijd",
        "RENTACAR.VEHICLE_KM": "KM stand",
        "RENTACAR.FUEL_AMOUNT": "Brandstof weergave",
        "RENTACAR.EQUIPMENT": "Extra,s",
        "RENTACAR.NOTES": "Notitie",
        "RENTACAR.RENT_PRICE": "Bedrag dagelijks",
        "RENTACAR.BAILS": "Bails",
        "RENTACAR.READ_ACCEPT_AGR1": "Contract gegevens",
        "RENTACAR.READ_ACCEPT_AGR2": "'ik ga akkoord mee. ",
        "RENTACAR.CHOOSE_DEV": "selecteren voertuig.",
        "RENTACAR.DRIVER_NAME": "Naam Bestuurder",
        "RENTACAR.CHOOSE": "Selecteren",
        "RENTACAR.ADD_NEW": "Toevoegen",
        "RENTACAR.UPDATE": "Update",
        "RENTACAR.RENTER": "verhuren",
        "RENTACAR.DAMAGES": "Damages",
        "GAUGAE.FULL_SCREEN": "volledig scherm",
        "GAUGAE.LIVE_ON": "live vernieuwen",
        "GAUGAE.LIVE_OFF": "live vernieuwen niet actief",
        "GAUGAE.ANIM_ON": "live Animation actief",
        "GAUGAE.ANIM_OFF": "live Animation niet actief",
        "GAUGAE.GAUGAE_OPEN": "Open de indicatoren",
        "GAUGAE.GAUGAE_CLOSE": "indicatoren dicht",
        "GAUGAE.SEARCH_ADR": "Adres zoeken",
        "GAUGAE.PAGE_NOT_FOUND": "geen gegevens",
        "GAUGAE.FUEL_AMOUNT": "Brandstof hoeveelheid",
        "GAUGAE.TOTAL_KM": "Totaal km",
        "GAUGAE.DAILY_KM": "dagelijkse Km",
        "GAUGAE.TEMP": "temparatuur",
        "GAUGAE.HUMIDITY": "HUMIDITY",
        "GAUGAE.WEATHER_TEXT": "Wetterlage,Witterung und weitere Aussichten für die kommende Tage",
        "GAUGAE.CANBUS_TAGES": "7 Tage KM",
        "GAUGAE.ZURLIVE": "Canlı Yenileme",
        "GAUGAE.ZURANIM": "Canlı Animasyon",
        "GAUGAE.M_DISTANCE": "Distance",
        "DRIVERS.GENERAL_INFO": "Algemene informatie",
        "DRIVERS.DRIVER_NO": "Bestuurder No",
        "DRIVERS.DRIVER_TEL": "Telefoon",
        "DRIVERS.LICENSE_CONTROL": "Controle van de Rijbewijs",
        "DRIVERS.FAVORITE_DEVICE": "Favoriete voertuig",
        "DRIVERS.CREATE_DRIVER": "toevoegen nieuwe Bestuurder",
        "DRIVERS.BUTTON_LIST": "i-Button Lijst",
        "DRIVERS.BUTTON_NO": "Button No",
        "DRIVERS.BUTTON_SERIAL_NO": "i-Button Serie No",
        "DRIVERS.BUTTON_TYPE": "i-Button Type",
        "DRIVERS.ASSIGN_DRIVER": "Bestuurder toevoegen",
        "DRIVERS.NEW_DRIVER": "nieuwe Bestuurder",
        "DRIVERS.CREATE_NEW_DRIVER": "maak nieuwe Bestuurder",
        "DRIVERS.WORK_HOURS_CONTROL": "werk tijden Controlen",
        "DRIVERS.WORK_HOURS": "vaste werk tijden toevoegen",
        "DRIVERS.WORK_HOURS_START": "begin werk tijde",
        "DRIVERS.WORK_HOURS_STOP": "Eind werk tijd",
        "DRIVERS.MAX_OVERTIME": "Max. over uren",
        "DRIVERS.INSPECTION_INTERVAL": "inspectie tijd aanpassen",
        "DRIVERS.SPECIFIED_DATE": "gewenste tijd",
        "DRIVERS.UPDATE_DRIVER": "Bestuurder aanpassen",
        "DRIVERS.DELETE_DRIVER": "Bestuurder verwijderen",
        "DRIVERS.CONFIRM_DELETE_DRIVER": "wilt u zeker om bestand verwijderen?",
        "DRIVERS.DRIVER_NOT_FOUND": "geen Bestuurder",
        "DRIVERS.FAV_DRIVER": "Favoriete Bestuurder",
        "DRIVERS.HAS_FAV_DRIVER": "is geselecteerd voertuig voor uw favoriete coureur:",
        "DRIVERS.HAS_NO_FAV_DRIVER": "er is geen coureur aanwezig",
        "DRIVERS.UNSELECT_DRIVER": "om Bestuurder verwijderen",
        "DRIVERS.DRIVERS_NOTIFICATION": "In diefstal bescherming biedt dan het nummer van de tweede mobiele telefoon (niet voor IButton mogelijk)",
        "DRIVERS.ENTER_PHONE_NUMBER": "Het invoeren van de 2e gsm-nummer ter voorkoming van diefstal.",
        "DRIVERS.ENTER_PHONE_NUMBER_TITLE": "Automatische overname het 2'ten mobiel nummer voor de Diebstahschutz.",
        "DRIVERS.MOBILE_COMMUNICATION": "Toegang app voor bestuurder communicatie",
        "DRIVERS.DRIVER_NO_AUTO": "Driver nummer (automatisch)",
        "DRIVERS.DRIVER_PASS": "Driver password",
        "DRIVERS.MOBILE_APP_STATUS": "Mobile App Status",
        "DRIVERS.ACTIVE": "Actief",
        "DRIVERS.DISABLE": "Onbruikbaar maken",
        "DRIVERS.24_HOUR_DRIVER": "24 uur driver automatisch afgemeld",
        "DRIVERS.ALWAYS_DRIVER": "Chauffeurs moeten altijd ingelogd blijven (is aangemeld voor anderen)",
        "DRIVERS.CLOSE": "Dicht",
        "DRIVERS.MOBILE_DEVICES": "Smart apparaten",
        "THEFT.STEP1": "1. stap : Uw voertuig is actief",
        "THEFT.STEP1_REPORTS": "Diebstahlschutz Berichte",
        "THEFT.STEP2": "2. stap : Meldingen",
        "THEFT.STEP2_INFO": "Wanneer niet opgegeven is actieve 24 uur per dag. Geef het tijdsinterval dat u wilt.",
        "THEFT.STEP3": "3. stap : vul uw contact gegevens",
        "THEFT.STEP4": "4. stap : Geofence actieveren",
        "THEFT.WHEN_DEVICE_RUN": "Start tijd van voertuig -Nederlands",
        "THEFT.WHEN_DOOR_OPEN": "Deur of klepje voor bagage is geopend-Nederlands",
        "THEFT.ALARM": "Alarm",
        "THEFT.SAVEALL": "Aanpassen voor alle voertuigen",
        "THEFT.NO_ENTER_ZONE": "Het gebied Is een beperkt",
        "THEFT.SMS_ON": "Motor aan",
        "THEFT.SMS_OFF": "Motor uit",
        "THEFT.SMS_GPS": "Periodiek",
        "THEFT.SMS_RESET": "Restart",
        "THEFT.THEFT_TWO_STEP1": "1. Stap : Geofence actieveren",
        "THEFT.SAVE_ALL_STEP4": "Door te kiezen voor deze optie kiezen. geldig is, de instelling voor alle voertuigen. In niet-geselecteerde checkbox alleen voor de geselecteerde voertuig",
        "THEFT.IN": "Zone entry report",
        "THEFT.OUT": "Zone exit rapport",
        "CUSTOMER_GROUPS.NEW": "nieuwe Klant groepen",
        "CUSTOMER_GROUPS.NEW_CUSTOMER": "nieuwe Klant",
        "CUSTOMER_GROUPS.EDIT_CUSTOMER": "Klant Update",
        "CUSTOMER_GROUPS.CUSTOMER_NO": "Klant No",
        "CUSTOMER_GROUPS.FIRMA_NAME": "Naam van Klant",
        "CUSTOMER_GROUPS.RESP_PARTNER": "Vertegenwoordiger partner",
        "CUSTOMER_GROUPS.CHOOSE_FUNCTION": "Selecteer de groepsfuncties.",
        "CUSTOMER_GROUPS.FUNC1": "gekozen voertuigen selecteren en aanpassen",
        "CUSTOMER_GROUPS.FUNC2": "ETA is bediening",
        "CUSTOMER_GROUPS.FUNC3": "Controle van alle voertuigen voor het vervoer van aangewezen grondgebied",
        "CUSTOMER_GROUPS.CONFIRM_DELETE": "wilt u zeker om verwijderen?",
        "CUSTOMER_GROUPS.ADDRESS": "opgeslagen adressen",
        "CUSTOMER_GROUPS.PLS_PICK_CG": "selecteren klanten groepen a.u.b.",
        "CUSTOMER_GROUPS.PLS_PICK_ADR": "kies een adres",
        "CUSTOMER_GROUPS.NEW_ADR": "Nieuwe Adres",
        "CUSTOMER_GROUPS.NEW_ADR_EXP": "Typ een beschrijving voor het nieuwe adres en selecteer vervolgens het gebied van uw locatie en het adres op de kaart. Nadat u uw proces te slaan groen sparen knoop, stoppen met het toevoegen van nieuwe adres hebt voltooid, klikt u op de rode op te geven.",
        "CUSTOMER_GROUPS.REPORTS_PAGE": "Rapporten van klanten groepen",
        "CUSTOMER_GROUPS.SHOW_REPORTS": "Rapport gegevens",
        "CUSTOMER_GROUPS.REPORT_DETAIL_NOT_FOUND": "Er is geen rapporten aanwezig..",
        "CUSTOMER_GROUPS.ENTER_TIME": "Tijd İn",
        "CUSTOMER_GROUPS.EXIT_TIME": "Tijd uit",
        "CUSTOMER_GROUPS.PICK_GRP": "Listedeki gruplardan birini seçiniz. Açılan penceredeki yönergeleri izleyiniz.",
        "CUSTOMER_GROUPS.DEV_SYNC": "Voertuig aanpassing (de enige groep 1 en groep 2)",
        "SYSTEM._comment": "IMAGES_FOLDER  Het geschreven in het adres van de map die de afbeeldingen bevat. Er moet een goed systeem aandelen voor elk taalbestand, anders afbeeldingen kunnen niet worden geladen.",
        "SYSTEM.IMAGES_FOLDER": "./assets/img/tr",
        "FUEL.CREATE_RECEIPT": "Maak de bon, brandstof",
        "FUEL.PRICE_LITER": "Liter prijs",
        "FUEL.PRICE_TOTAL": "brandstofprijs (Totaal)",
        "FUEL.LITER": "Liter hoeveelheid",
        "FUEL.LT_100_KM": "Liter/100 km",
        "FUEL.KM_DEFLECTION": " +- 10% afwijking",
        "FUEL.USER_AVG": "U voert uw verbruik",
        "FUEL.REAL_AVG": "Werkelijke consumptie (berekend)",
        "SPEED_CHART.SHOW_SPEED": "Toon enkel de snelheid van uw gekozen.",
        "AUTOPILOT.AUTOPILOT_SETTINGS": "Auto pilot instelling",
        "AUTOPILOT.DAILY_KM": "Dagelijkse KM",
        "AUTOPILOT.SPEED_CONTROL_1": "binnenbouwde kom snelheid",
        "AUTOPILOT.SPEED_CONTROL_2": "Buitendeboude kom snelheid",
        "AUTOPILOT.SPEED_CONTROL_3": "Snelweg",
        "AUTOPILOT.IGNITION": "Ignıtıon",
        "AUTOPILOT.12V": "Apparaat 12V spanning lager",
        "AUTOPILOT.OHNE_GSM": "Apparaat GPS/GSM geen netwerk",
        "AUTOPILOT.ATOB": "Van Punt A naar Punt B controle",
        "AUTOPILOT.DRIVING_TIME": "Totaal gereden",
        "AUTOPILOT.FROM": "van",
        "AUTOPILOT.DEPERTURE_TIME": "Van het voertuig geschatte transactie tijd",
        "AUTOPILOT.UNTIL": "tot",
        "AUTOPILOT.PARKED": "Park status",
        "AUTOPILOT.LOCATION_TIME": "Locatie tij aanpassen",
        "AUTOPILOT.CHOOSE_PERIOD": "kies a.u.b. Periodiek",
        "AUTOPILOT.IGNITION_CONTROL": "start status",
        "AUTOPILOT.IGNITION_CHANGE": "starts Meldingen",
        "AUTOPILOT.TOWING": "towings melding",
        "AUTOPILOT.EMAIL_INTERVAL": "Selecteer de frequentie van het verzenden van e-mails",
        "AUTOPILOT.DEVIATION": "+/- 10% Afwijking",
        "AUTOPILOT.MOBILE_COMMENT": "Stap 1 tot en met 11 als de Apple-Android apparaat, de instellingen gemaakt ontvangt rechtstreekse ",
        "AUTOPILOT.BEACON_DEVICE": "Beacon device notification",
        "AUTOPILOT.VEHICLE_NOTIFICATIONS_END_OF_DAY_REPORT": "Vehicle notifications and end of day report",
        "AUTOPILOT.REPORTS_FOR_ALL_VEHICLES": "Reports for all vehicles",
        "AUTOPILOT.NOTIFICATION_TYPE": "Benachrichtigungstyp",
        "AUTOPILOT.SPEED_LIMIT": "Höchstgeschwindigkeit",
        "AUTOPILOT.VEHICLE_SPEED": "Fahrzeuggeschwindigkeit",
        "ROUTES.SHOW_ROUTES": "Route weergave",
        "ROUTES.RESPITE": "Rust",
        "ROUTES.WAITING": "Wachtende",
        "ROUTES.FOR_MISSION": "Dienst",
        "ROUTES.PRIVATE": "Prive",
        "ROUTES.TRIP": "Reizen",
        "ROUTES.DAILY": "Dagelijkse",
        "ROUTES.VEHICLE": "Voertuig",
        "ROUTES.VEHICLE_GROUP": "Voertuig groepen",
        "ROUTES.DATE": "Tijd",
        "ROUTES.ALL_VEHICLE_GROUP": "Alle voertuig Groepen",
        "ROUTES.DIGITAL_INPUT": "Digital Eingang",
        "ANKUFT.ANKUFT": "locatie",
        "ANKUFT.ANKUFT_TITLE": "locatie Live",
        "ANKUFT.STATUS_0": "Aankomend",
        "ANKUFT.STATUS_1": "Vertragd",
        "ANKUFT.STATUS_2": "gebestemd",
        "ANKUFT.PLATE": "Kenteken",
        "ANKUFT.PLAN": "Plan",
        "ANKUFT.EXCEPTED": "Wachtende",
        "ANKUFT.STATUS": "Status",
        "ANKUFT.ARRIVE_IN": "Locatie in",
        "ANKUFT.DISTANCE": "Afstand",
        "ANKUFT.ORDER": "toestemming",
        "ANKUFT.FROM": "van",
        "ANKUFT.TO": "nog",
        "TECHNICAL_SUPPORT.HEAD": "Technische helpdesk formulier",
        "TECHNICAL_SUPPORT.GENERAL": "Algemene informatie",
        "TECHNICAL_SUPPORT.MESSAGE": "Berichten",
        "TECHNICAL_SUPPORT.CALL_TIME": "Gewenst tijdstrip",
        "TECHNICAL_SUPPORT.NAME": "Voornaam Achternaam",
        "TECHNICAL_SUPPORT.FIRMA": "Bedrijfsnaam",
        "TECHNICAL_SUPPORT.PHONE": "Telefoonnummer",
        "TECHNICAL_SUPPORT.EMAIL": "E-mailadres",
        "TECHNICAL_SUPPORT.INFO": "Gratis technische ondersteuning u kunt u vragen op dit gebied schrijven.",
        "TECHNICAL_SUPPORT.NOW": "Nu",
        "TECHNICAL_SUPPORT.SEND": "Verzenden",
        "TECHNICAL_SUPPORT.REQUIRED_NAME": "Uw naam",
        "TECHNICAL_SUPPORT.REQUIRED_EMAIL": "Uw E-mailadres",
        "TECHNICAL_SUPPORT.REQUIRED_MESSAGE": "Uw bericht",
        "TECHNICAL_SUPPORT.SUCCESS": "Uw bericht is verzonden.",
        "SMS_OPTIONS.SMS_OPTIONS": "SMS Übersicht",
        "SMS_OPTIONS.SMS_DESCRIPTION": "Ihre gewählte Option wird Monatlich von Ihrer gewählten Zahlungsbedingung abgebucht. Zum abbestellen wenden Sie sich bitte an unseren Support via eMail.",
        "SMS_OPTIONS.EXTRA_PACKAGE": "Automatische SMS Buchung",
        "SMS_OPTIONS.SPENT": "Verbrauch",
        "SMS_OPTIONS.BALANCE": "aktuelle Guthaben",
        "SMS_OPTIONS.ORDER": "Bestellen",
        "SMS_OPTIONS.SMS_ORDER": "SMS Kaufeinstellungen",
        "SMS_OPTIONS.CHOOSE_PACKAGE": "Bitte Paket auswählen!",
        "SMS_OPTIONS.CHOOSE_DEVICE": "Fahrzeug Auswählen",
        "INOUT.PLACE": "Standort",
        "INOUT.DATE": "Datum",
        "INOUT.SEARCH": "Suchen",
        "INOUT.INSTRUCTION": "Stap 1: verblijfplaats collectie van een geselecteerde locatie",
        "INOUT.INFO1": "In deze stap kan worden bereid door de definitie van een plaats met een bepaalde straal, het uitvoeren van een zoekopdracht. Laat u advertenties welke voertuigen deze plek zo vaak hebben bezocht.",
        "INOUT.INFO2": "Als volgt:",
        "INOUT.INFO3": "Aan de rechterkant van het zoekvak en voer de top van deze het gewenste adres.",
        "INOUT.INFO4": "Selecteer het gewenste adres uit de gevonden documenten; U kunt ook zoeken met de pijl tool op de kaart te plaatsen.",
        "INOUT.INFO5": "Klik nu op de kaart het pictogram potlood en trek een cirkel in de gewenste locatie en radius.",
        "INOUT.INFO6": "Selecteer nu aan de rechterkant van de datum voor zoeken route in de geselecteerde locatie.",
        "INOUT.INFO7": "Als je alleen wilt een bepaalde tijd te controleren op deze datum, geeft u aan de rechterkant ook begin- en eindtijd voor de zoektocht.",
        "INOUT.INFO8": "Nadat u alle opties hebt geselecteerd, klikt u op het pictogram 'Zoeken'.",
        "INOUT.INFO9": "Als gevolg hiervan zullen alle voertuigen die nu worden weergegeven aan de geselecteerde datum en tijd, zijn de binnenkant van de door u gekozen locatie. Je krijgt die in tabelvorm. Met het eerste signaal opneemt uit de zone en het laatste signaalopname van de gedefinieerde zone.",
        "INOUT.INFO10": "Aan het einde van de rij in deze tabel, is er een vergrootglas. Wilt u een gedetailleerde lijst van de signalen van de zone, zodat u op deze. een andere tafel wordt geopend met een lijst waarin wanneer het voertuig raszuivere in deze zone, en wat het weer ging uit van deze zone.",
        "INOUT.INFO11": "Door bediening van het kenteken, de route die het voertuig geopend om de eerste en laatste zone signalen overeenkomt.",
        "INOUT.TIME": "tijd",
        "INOUT.PLEASE_INSERT": "Geef aan het gebied.",
        "INOUT.ENTRY": "Binnenkomst",
        "INOUT.EXIT": "Afrit",
        "INOUT.TOTAL": "Globaal",
        "INOUT.STOP": "Afbreken",
        "MYINOUT.INSTRUCTION": "Stap 2: geheugenplaatsen",
        "MYINOUT.INFO1": "Aan de rechterkant van de gewenste datum voor route zoeken.",
        "MYINOUT.INFO2": "Als je alleen wilt een bepaalde tijd op de datum van uw keuze te bekijken en dan op de juiste begin- en eindtijd.",
        "MYINOUT.INFO3": "Nadat u alle opties hebt geselecteerd, kunt u klikken op de naam van de site die u eerder hebt gemaakt te zijn wat opgevraagd.",
        "MYINOUT.INFO4": "Het resultaat: Het bevat een lijst apparaten die op de geselecteerde datum en tijd in hun site selectie gemaakt waren.",
        "MYINOUT.INFO5": "Klik op het gewenste voertuig als u wilt in een nieuw venster om de route op de kaart weer te geven.",
        "MYINOUT.INFO6": "Hier uw opgeslagen locaties voor route query.",
        "MYINOUT.TITLE": "Opgeslagen locaties",
        "MYINOUT.TABLE_TITLE": "Weergave van voertuigen in de aangewezen locatie",
        "MYINOUT.SAVED_PLACES": "Route-query opgeslagen locaties",
        "MYINOUT.DEVICE": "Kentekenbewijs",
        "MYINOUT.FIRST": "Eerste",
        "MYINOUT.ZONE": "Zone binnenkomst",
        "MYINOUT.LAST": "Laatste",
        "MYINOUTFORM.INSTRUCTION": "Stap 3: verzoek voor de locatie lijst met de opgeslagen locaties",
        "MYINOUTFORM.INFO1": "Voer in de adresbalk zoeken op het gewenste adres. U kunt ook zoeken met de pijl tool op de kaart te plaatsen.",
        "MYINOUTFORM.INFO2": "Klik nu op de kaart en sleep de cirkel om de gewenste locatie en radius.",
        "MYINOUTFORM.INFO3": "Vul uw gekozen locatie een naam en sla het op uit.",
        "MYINOUTFORM.INFO4": "Uw site selectie wordt weergegeven aan de onderkant van de lijst.",
        "MYINOUTFORM.INFO5": "Na het succesvol opslaan van uw locatie selectie onder de lijst wordt weergegeven.",
        "MYINOUTFORM.INFO6": "Om een eerder opgeslagen locatie bewerken, klikt u op het potloodpictogram.",
        "MYINOUTFORM.INFO7": "Nogmaals, selecteert u op de kaart de locatie om de route query. De oude selectie wordt wanneer u op de knop, Opslaan 'weer worden overschreven.",
        "MYINOUTFORM.NAME": "Locatie",
        "MYINOUTFORM.TABLE_TITLE": "Opgeslagen locaties voor route queries",
        "MYINOUTFORM.EDIT_PLACES": "Locaties bewerken voor het zoeken van de route",
        "ROUTE_TRACKING.START_BUTTON": "Handmatig invoeren beginnen op de kaart",
        "ROUTE_TRACKING.STOP_BUTTON": "Voer handmatig te stoppen op de kaart",
        "ROUTE_TRACKING.STATUS": "Geval",
        "ROUTE_TRACKING.DETAIL": "Detail",
        "ROUTE_TRACKING.START_ADRESS_INPUT": "Voer het vertrek adres",
        "ROUTE_TRACKING.DEVICE": "Voertuig",
        "ROUTE_TRACKING.DRIVER": "Bestuurder",
        "ROUTE_TRACKING.START_ADRESS": "Start Address",
        "ROUTE_TRACKING.END_ADRESS": "Eindadres",
        "ROUTE_TRACKING.PLAN_START_TIME": "Geplande aanvangsdatum",
        "ROUTE_TRACKING.EMAILS": "Notificatie e-mails",
        "ROUTE_TRACKING.PLAN_KM_TIME": "KM en verwachte",
        "ROUTE_TRACKING.PLAN_KM": "Geschatte KM",
        "ROUTE_TRACKING.PLAN_TIME": "Geschatte tijd",
        "ROUTE_TRACKING.SELECT_DEVICE": "Selecteer Vehicle",
        "ROUTE_TRACKING.SELECT_DRIVER": "Selecteer Driver",
        "ROUTE_TRACKING.INTERVAL": "Notificatie Interval",
        "ROUTE_TRACKING.SAVE": "Route opslaan Plan",
        "ROUTE_TRACKING.GENERAL_INFO": "Algemene informatie",
        "ROUTE_TRACKING.LATEST_ADRESS": "Nu te vinden in Adres",
        "ROUTE_TRACKING.ALERT_COORDINATE": "Vul de begin- en eindpunt.",
        "ROUTE_TRACKING.ALERT_DEVICE": "Selecteer Vehicle",
        "ROUTE_TRACKING.ALERT_EMAIL": "Voer ten minste 1 e-mail adres!",
        "ROUTE_TRACKING.ALERT_INTERVAL": "Vul het interval om meldingen te sturen.",
        "ROUTE_TRACKING.NOTIFICATION_TIME": "Melding Time",
        "ROUTE_TRACKING.NOTIFICATION_ADRESS": "Notification Address",
        "ROUTE_TRACKING.DONE_INFO_1": "Het werd voltooid op",
        "ROUTE_TRACKING.DONE_INFO_2": "",
        "ROUTE_TRACKING.DELETE_SURE": "Weet u zeker dat u wilt verwijderen?",
        "ROUTE_TRACKING.TITLE": "Geschatte route",
        "ROUTE_TRACKING.NOTIFICATION_REPORTS": "Notification Reports",
        "ROUTE_TRACKING.DEFINED_START": "Gedefinieerd uitgangspositie",
        "ROUTE_TRACKING.DEFINED_STOP": "Defined doelpositie",
        "AIR_ALARM_SETTINGS.AIR_ALARM": "Alarm instelling",
        "AIR_ALARM_SETTINGS.SOUND_SETTINGS": "Volume",
        "AIR_ALARM_SETTINGS.SOUND_SETTINGS_INFO": "Het volume bepalen hoeveel seconden de sirene klinkt wanneer het alarm afgaat.",
        "AIR_ALARM_SETTINGS.SENSITIVITY_SETTINGS": "Gevoeligheidsinstelling",
        "AIR_ALARM_SETTINGS.SENSITIVITY_SETTINGS_INFO": "De gevoeligheidsinstelling kan variëren afhankelijk van de omgeving en het voertuig waarin u zich bevindt. De aanbevolen instelling is 1300. Waarden onder deze waarde kunnen ervoor zorgen dat het alarm automatisch wordt geactiveerd.",
        "CHAT.IS_TYPING": " is aan het typen..",
        "CHAT.CHAT_INPUT_BOX": "Voer uw bericht in...",
        "CHAT.CHAT_PROGRESS": "Chatten als ",
        "CHAT.CHAT_QUICK_REPLY_YES": "JA",
        "CHAT.CHAT_QUICK_REPLY_NO": "NEE",
        "CHAT.CHAT_QUICK_REPLY_OK": "OK",
        "CHAT.CHAT_NOT_DELIVERED": "Bericht niet verzonden",
        "CHAT.DRIVER_LIST_HEADER": "Drivers Lijst",
        "CHAT.USER_LIST_HEADER": "Gebruikers Lijst",
        "CHAT.TOPUSER_LIST_HEADER": "Top gebruikerslijst",
        "CHAT.TOPUSER_NOT_FOUND": "Geen top gebruiker gevonden!",
        "CHAT.SUBUSER_LIST_HEADER": "Subgebruikerslijst",
        "CHAT.USER_NOT_FOUND": "Geen geregistreerde gebruiker gevonden!",
        "CHAT.DRIVER_NOT_FOUND": "Geen geregistreerde driver gevonden!",
        "CHAT.SUBUSER_NOT_FOUND": "Geen subgebruiker gevonden!",
        "CHAT.DRIVING_TYPE_MODAL_TITLE": "Selecteer uw rijtype",
        "CHAT.DRIVING_TYPE_MODAL_CLOSE": "Dichtbij",
        "CHAT.DRIVING_TYPE_PRIVATE": "PRIVATE",
        "CHAT.DRIVING_TYPE_PUBLIC": "WERK",
        "CHAT.DRIVING_TYPE_POPUP_TITLE": "Trip Type Fout",
        "CHAT.DRIVING_TYPE_ERROR": "Reis type kan niet worden opgeslagen!",
        "WEB_INTEGRATION.TITLE": "Webintegratie in uw website",
        "WEB_INTEGRATION.CONTENT1": "Klik op de onderstaande link om de broncode voor de webintegratie te genereren. Hiermee kan u of uw webdesigner uw voertuigweergave integreren in uw eigen website.",
        "BEACON.DEVICE_NAME": "Toestelnaam",
        "BEACON.LAST_SIGNAL_TIME": "Laatste signaaltijd",
        "BEACON.SIGNAL_STRENGTH": "Signaalsterkte",
        "BEACON.CONNECTION_STATUS": "Verbindingsstatus",
        "BEACON.DEVICES": "Beacon apparaten",
        "BEACON.PERFECT": "Perfect",
        "BEACON.VERY_GOOD": "Very Good",
        "BEACON.GOOD": "Good",
        "BEACON.BAD": "Bad",
        "BEACON.VERY_BAD": "Very Bad",
        "DATA_DELETE.DATA_TO_BE_DELETED": "Zu Löschende Daten",
        "DATA_DELETE.DEVICES_TO_BE_DELETED": "Zur Löschung freigegebene Geräte",
        "DATA_DELETE.DATA_DELETING": "Daten werden gelöscht",
        "DATA_DELETE.DELETED_DEVICES": "Gelöschte Geräte",
        "DATA_DELETE.CREATE_DATE": "Erstellungsdatum",
        "DATA_DELETE.DELETE_DATE": "Löschdatum",
        "DATA_DELETE.CONFIRMATION_CODE": "Bestätigungscode",
        "DATA_DELETE.TYPE_CONFIRMATION_CODE": "Bitte geben Sie Ihren Bestätigungscode ein",
        "DATA_DELETE.CONFIRMATION": "Bestätigung"
    }
};

export default langNl;