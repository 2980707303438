const langDe = {
    de: {
        "LANG.TR": "Türkisch",
        "LANG.EN": "Englisch",
        "LANG.DE": "Deutsch",
        "LANG.NL": "Holländisch",
        "GENERAL.DEVICES": "Geräte",
        "GENERAL.DRIVERS": "Fahrer",
        "GENERAL.DATE": "Datum",
        "GENERAL.KM": "Kilometer",
        "GENERAL.PRIVATE": "Privat",
        "GENERAL.SAVE": "Speichern",
        "GENERAL.SAVE_ALL": "Alle Speichern",
        "GENERAL.SAVED": "Gespeichert",
        "GENERAL.START": "Start",
        "GENERAL.STOP": "Stopp",
        "GENERAL.ADDRESS": "Anschrift",
        "GENERAL.START_DATE": "Anfangsdatum",
        "GENERAL.END_DATE": "Enddatum",
        "GENERAL.START_ADDRESS": "Startadresse",
        "GENERAL.END_ADDRESS": "Zieladresse",
        "GENERAL.TRIP_TIME": "Fahrzeit",
        "GENERAL.WAIT_TIME": "Parkzeit",
        "GENERAL.IDLE_TIME": "Leerlaufzeit",
        "GENERAL.ACTIVE_TIME": "Aktivzeit",
        "GENERAL.TOTAL_TIME": "Gesamtzeit",
        "GENERAL.TIME": "Zeit",
        "GENERAL.TOTAL": "Gesamt",
        "GENERAL.DRIVER": "Fahrer",
        "GENERAL.YEAR": "Jahr",
        "GENERAL.MONTH": "Monat",
        "GENERAL.DAY": "Tag",
        "GENERAL.DAYS": "Tage",
        "GENERAL.KMH": "km/h",
        "GENERAL.HOUR": "Stunde",
        "GENERAL.MINUTE": "Minute",
        "GENERAL.MIN": "Min.",
        "GENERAL.SECOND": "Sekunde",
        "GENERAL.SEC": "Sek.",
        "GENERAL.TURN_BACK": "Zurück",
        "GENERAL.YES": "Ja",
        "GENERAL.NO": "Nein",
        "GENERAL.NR": "Nr.",
        "GENERAL.DEMO": "Demo",
        "GENERAL.BUY": "Kaufen!",
        "GENERAL.NO_DATA": "Keine Daten",
        "GENERAL.ERROR": "Es liegt ein Fehler vor. Bitte erneut versuchen.",
        "GENERAL.PLS_PICK_DEVICE": "Wählen Sie bitte ein Fahrzeug aus",
        "GENERAL.PICK_DEVICE": "Wählen Sie ein Fahrzeug aus",
        "GENERAL.PICK_DRIVER": "Wählen Sie einen Fahrer aus",
        "GENERAL.CLOSE": "Schließen",
        "GENERAL.PLEASE_WAIT": "Bitte warten..",
        "GENERAL.ADD": "Hinzufügen",
        "GENERAL.EDIT": "Bearbeiten",
        "GENERAL.DELETE": "Löschen",
        "GENERAL.FILTER": "Filtern",
        "GENERAL.FILTER_OPTIONS": "Filteroptionen",
        "GENERAL.PRINT": "Ausdrucken",
        "GENERAL.EXPORT_PDF": "Als PDF ausdrucken",
        "GENERAL.EXPORT_EXCEL": "Als Excel ausdrucken",
        "GENERAL.UNDEFINED": "Undefiniert",
        "GENERAL.USER_NAME": "Benutzername",
        "GENERAL.PASSWORD": "Passwort",
        "GENERAL.PASSWORD_AGAIN": "Passwort (erneut)",
        "GENERAL.NAME": "Vorname",
        "GENERAL.SURNAME": "Nachname",
        "GENERAL.MAIL": "E-Mail",
        "GENERAL.CITY": "Wohnort",
        "GENERAL.CHANGE": "Ändern",
        "GENERAL.ALL": "Alle",
        "GENERAL.PASSIVE": "Passiv",
        "GENERAL.SERVICE": "Service",
        "GENERAL.REQUIRED_FIELDS": "Pflichtfelder",
        "GENERAL.SELECT": "Auswählen",
        "GENERAL.SELECTED": "Ausgewählt",
        "GENERAL.ACTIVE": "Aktiv",
        "GENERAL.SMS": "SMS",
        "GENERAL.NOTIFICATIONS": "Benachrichtigungen",
        "GENERAL.FAX": "Fax",
        "GENERAL.TEL": "Tel.",
        "GENERAL.DESCRIPTION": "Beschreibung",
        "GENERAL.TOTAL_MILEAGE": "Gesamtkilometer",
        "GENERAL.OK": "Ok",
        "GENERAL.NEW": "Neu",
        "GENERAL.CANCEL": "Abbrechen",
        "GENERAL.DEVICE_NAME": "Fahrzeugname",
        "GENERAL.MORNING": "Morgens",
        "GENERAL.EVENING": "Abends",
        "GENERAL.AUTO_RELOAD_ON": "Zur Aktualisierung der Seite im 2 Min. Takt bitte hier klicken.",
        "GENERAL.AUTO_RELOAD_OFF": "Seite wird alle 2 Min. aktualisiert",
        "GENERAL.PREVIOUS": "Vorherige",
        "GENERAL.NEXT": "Nächste",
        "GENERAL.WARNING": "Warnung",
        "GENERAL.RESULTS_PER_PAGE": "Ergebnisse pro Seite :",
        "GENERAL.PREV_DAY": "Vorheriger Tag",
        "GENERAL.NEXT_DAY": "Nächster Tag",
        "GENERAL.ICON": "Icon",
        "GENERAL.MAIL_WRITE": "E-Mail-Adresse",
        "GENERAL.PHONE_WRITE": "Tragen Sie Ihre Telefonnummer ein",
        "GENERAL.CHOOSE": "Wählen",
        "GENERAL.STEP": "Schritt",
        "GENERAL.SAVE_FOR_ALL_DEVICE": "Diese Schritte für alle Fahrzeuge aktivieren",
        "GENERAL.SEARCH": "Suchen",
        "GENERAL.FILTER_RESULTS": "Ergebnisfilter",
        "GENERAL.USER": "Benutzer",
        "GENERAL.INACTIVE": "inaktif",
        "GENERAL.TODAY": "Heute",
        "GENERAL.21_FUNCTIONS": "21 Funktionen auf einen Blick!",
        "GENERAL.TOGGLEALL": "Alle aktivieren",
        "GENERAL.LAST": "Letzte",
        "GENERAL.PHONE_NUMBER": "030 355 306 77 0",
        "GENERAL.PHONE_NUMBER_TEXT": "Telefonische Beratung",
        "GENERAL.IDLE": "Leerlauf - Zündung An",
        "GENERAL.ONE_HOUR": "Kein GPS/GSM Seit 1 Std",
        "GENERAL.ONE_HOUR_LINK": "Geräte ohne aktuelle Position seit 1 Std",
        "GENERAL.LATEST_SIGNAL_DATE": "Letzte Signale Datum",
        "GENERAL.NORESULT": "Keine Ergebnisse",
        "GENERAL.NO_ACCESS": "Sie können diese Option nicht Online erwerben, bitte wenden Sie sich an unseren Support unter 030 3553 0677 0",
        "GENERAL.HIDE_DEVICE_CONTENT": "Privatfahrt keine Datenerfassung!",
        "GENERAL.HIDE_ATTENTION": "Achtung! Bei einer Aktiven Auswahl der Privatfahrt, erfolgt keine Datenerfassung! Alle Routen, und elektrische Berichte gehen verloren!",
        "GENERAL.DISABLE": "Deaktiv",
        "GENERAL.SOMETHING_WENT_WRONG": "Etwas ist schief gelaufen. Bitte aktualisieren Sie die Seite.",
        "GENERAL.HOURLY_REPORT": "Stündlicher Bericht",
        "DAYS.MONDAY": "Montag",
        "DAYS.TUESDAY": "Dienstag",
        "DAYS.WEDNESDAY": "Mittwoch",
        "DAYS.THURSDAY": "Donnerstag",
        "DAYS.FRIDAY": "Freitag",
        "DAYS.SATURDAY": "Samstag",
        "DAYS.SUNDAY": "Sonntag",
        "DAYS_NUMERIC.DAY_1": "Montag",
        "DAYS_NUMERIC.DAY_2": "Dienstag",
        "DAYS_NUMERIC.DAY_3": "Mittwoch",
        "DAYS_NUMERIC.DAY_4": "Donnerstag",
        "DAYS_NUMERIC.DAY_5": "Freitag",
        "DAYS_NUMERIC.DAY_6": "Samstag",
        "DAYS_NUMERIC.DAY_0": "Sonntag",
        "MONTHS_NUMERIC.MONTH_1": "Januar",
        "MONTHS_NUMERIC.MONTH_2": "Februar",
        "MONTHS_NUMERIC.MONTH_3": "März",
        "MONTHS_NUMERIC.MONTH_4": "April",
        "MONTHS_NUMERIC.MONTH_5": "Mai",
        "MONTHS_NUMERIC.MONTH_6": "Juni",
        "MONTHS_NUMERIC.MONTH_7": "Juli",
        "MONTHS_NUMERIC.MONTH_8": "August",
        "MONTHS_NUMERIC.MONTH_9": "September",
        "MONTHS_NUMERIC.MONTH_10": "Oktober",
        "MONTHS_NUMERIC.MONTH_11": "November",
        "MONTHS_NUMERIC.MONTH_12": "Dezember",
        "HOME.SORT": "Sortieren",
        "HOME.SORT_ALL_DEVICES": "Sortieren Sie alle Geräte.",
        "HOME.SORT_BY_GROUP": "Sortieren Sie nach Gruppen.",
        "HOME.FIRST_RUN": "Erster Start",
        "HOME.IGNITION": "Zündung",
        "HOME.DAILY_MILEAGE": "Tageskilometer",
        "HOME.SPEED": "Geschwindigkeit",
        "HOME.RESULTS": "Ergebnisse",
        "HOME.SHOWING": "Anzeigen",
        "HOME.DISPLAY": "Ansicht",
        "HOME.LIST_TYPE": "Listentyp",
        "HOME.LIST_TYPE_STANDART": "Kompakt",
        "HOME.LIST_TYPE_STANDART_BIG": "Kompakt",
        "HOME.LIST_TYPE_KLEIN": "Klein",
        "HOME.LIST_TYPE_MINI": "Minimal",
        "HOME.LIST_TYPE_GROSSANSICHT": "Grossansicht",
        "HOME.LIST_TYPE_DETAILERT": "Detailliert",
        "HOME.LIST_TYPE_ROUND": "Rund",
        "HOME.LIST_TYPE_MODERN": "Modern",
        "HOME.OPTIONS": "Optionen",
        "HOME.DEVICE_SEARCH": "KFZ Suchen",
        "HOME.ALL_ROUTES": "Alle Strecken",
        "HOME.CUSTOMER_REPORTS": "Kunden",
        "HOME.SHOW_SELECTED": "Anzeigen",
        "HOME.DEVICE_FILTER": "Geräte anzuzeigen",
        "HOME.CURRENT_COORDINATES": "Satelliten-ansicht anzeigen",
        "HOME.COPY_COORDINATES": "Mit Strg+C Text kopieren und ihn mit Strg+V einfügen. Gewünschte Landkarte Objekt anzeigen lassen.",
        "HOME": "Startseite",
        "MENU.ALL_DEVICES": "Alle Fahrzeuge",
        "MENU.ROUTES": "Routen Anzeige",
        "MENU.REPORTS": "Elektronische Berichte",
        "MENU.REPORTS2": "Berichte",
        "MENU.FUNCTIONS": "Weitere Funktionen",
        "MENU.THEFT": "Diebstahlschutz einrichten",
        "MENU.THEFT2": "Diebstahlschutz II  Individuell",
        "MENU.SERVICE": "Fahrzeug Service",
        "MENU.RENT": "Rent A Car",
        "MENU.GROUPS": "Fahrzeuggruppen Übersicht",
        "MENU.USER_RIGHTS": "Zugriffsrechte",
        "MENU.TECHNICAL_SUPPORT": "Support",
        "MENU.APPLICATIONS": "Kostenlose Apps",
        "MENU.OPINIONS": "Ihre Meinungen",
        "MENU.LANGUAGE": "Sprachauswahl",
        "MENU.TIMEZONE": "Zeitzone",
        "MENU.SETTINGS": "Grundeinstellung",
        "MENU.LOGOUT": "Logout",
        "MENU.DRIVERS_LIST": "Fahrerliste",
        "MENU.MYLOCATIONS": "Meine Standorte Erfassen",
        "MENU.CUSTOMER_GROUPS": "Kundengruppen Übersicht",
        "MENU.SMS_CREDITS": "SMS Kaufeinstellungen",
        "MENU.APPS": "Kostenlose Apple-Android Programme",
        "MENU.ANDROID_APP": "Horizont Ortungssysteme Anwendung für Android",
        "MENU.APPLE_APP": "Horizont Profi Ortung Anwendung für Apple",
        "MENU.INSTRUCTIONS": "Bedienungsanleitung",
        "MENU.APPLE_APP2": "Horizont Ortungssysteme Anwendung für Apple",
        "MENU.SAVED_PLACES": "Abfrage gespeicherter Standort",
        "MENU.EDIT_PLACES": "Standortauswahl bearbeiten",
        "MENU.PLACES": "Routenabfrage Standortauswahl",
        "MENU.ROUTE_TRACKING": "Routen Aufzeichnung",
        "MENU.GRAPHIC": "Charts",
        "MENU.AUFTRAGS": "Auftrags App",
        "MENU.WEB_INTEGRATION": "Web Einbindung in Ihre Webseite",
        "MENU.NOTIFICATION_SETTINGS": "Air Alarm",
        "MENU.DUAL_SCREEN": "Doppelbildschirm",
        "MENU.LATEST_LOGIN": "Letzte Anmeldung",
        "MENU.LOGIN_DATE": "Logindatum",
        "MENU.PLATFORM": "Plattform",
        "MENU.BROWSER": "Browser",
        "MENU.BROWSER_VERSION": "Browserversion",
        "MENU.LOGIN_DEVICES": "Aktive Geräte",
        "MENU.APP_VERSION": "App Version",
        "MENU.PLATFORM_VERSION": "Platform Version",
        "MENU.DATA_DELETION": "Daten Löschen",
        "TEMPLATES.CHOOSE": "Themenoptionen",
        "TEMPLATES.TEMPLATE_DEFAULT": "Varsayılan",
        "TEMPLATES.TEMPLATE_1": "Tema 1",
        "TEMPLATES.TEMPLATE_2": "Tema 2",
        "TEMPLATES.TEMPLATE_3": "Tema 3",
        "TEMPLATES.TEMPLATE_4": "Tema 4",
        "TEMPLATES.TEMPLATE_5": "Tema 5",
        "TEMPLATES.TEMPLATE_6": "Tema 6",
        "QMENU.LOCATION": "Standort",
        "QMENU.ROUTE": "Strecke",
        "QMENU.LIVE": "Live",
        "QMENU.DAILY_REPORT": "Tages-km",
        "QMENU.MONTHLY_REPORT": "Monats-Km",
        "QMENU.SPEED_CHART": "Geschwindigkeit",
        "QMENU.FUEL": "Tankabrechnung",
        "QMENU.AUTO_PILOT": "AutoPilot",
        "QMENU.GRAPHS": "Grafische Darstellung",
        "QMENU.GEOFENCE_REPORTS": "Geofence Berichte",
        "QMENU.OTHER": "Weitere Funktionen",
        "QMENU.COLORED_ROUTE": "Route in Farbe",
        "QMENU.OP_ROUTE": "Streckenoptimierung",
        "QMENU.AUTO_PLAY": "Auto Play",
        "GRAPHS.START_STOP_TIMES": "Start - Stop Tabelle",
        "GRAPHS.LAST_SEVEN_DAY": "Tagesfahrzeiten",
        "GRAPHS.LAST_SEVEN_DAY_KM": "Tageskilometer",
        "GRAPHS.DRIVE_TIME": "Fahrzeit",
        "GRAPHS.DRIVE_KM": "Gefahrene Kilometer",
        "GRAPHS.TODAY_SPEED": "Heutige Geschwindigkeit / Zeitendiagramm",
        "GRAPHS.YESTER_CON": "Gestriger Bewegungsstatus",
        "GRAPHS.MAX_SEVEN": "Auswählbarer maximaler Zeitraum beträgt 1 Woche",
        "GRAPHS.ALLDEVKM": "Tageskilometer der Fahrzeuge",
        "GRAPHS.ALLDEVSPEED": "Geschwindigkeit der Fahrzeuge",
        "GRAPHS.AVG_SPEED": "Durchschnittliche Geschwindigkeit",
        "GRAPHS.MAX_SPEED": "Höchstgeschwindigkeit",
        "GRAPHS.downloadJPEG": "JPEG Bild herunterladen",
        "GRAPHS.downloadPNG": "PNG Bild herunterladen",
        "GRAPHS.downloadSVG": "SVG Vektor herunterladen",
        "GRAPHS.downloadPDF": "PDF Dokument herunterladen",
        "GRAPHS.printChart": "Grafik ausdrucken",
        "GRAPHS.loading": "Wird geladen...",
        "GRAPHS.contextButtonTitle": "Grafikmenü",
        "GRAPHS.drillUpText": "{series.name} Zurück",
        "GRAPHS.invalidDate": "Falsches Datum",
        "GRAPHS.noData": "Keine Daten vorhanden",
        "GRAPHS.resetZoom": "Zoom zurücksetzen",
        "GRAPHS.ACT_STATUS": "Total Action-Status",
        "GRAPHS.FUEL_CHART": "Tägliche Kraftstoffmenge als Grafik",
        "GRAPHS.FUEL_TABLE": "Tägliche Kraftstoffmenge als Tabelle",
        "GRAPHS.START_FUEL": "Tägliche Kraftstoffmenge",
        "GRAPHS.END_FUEL": "Kraftstoffmenge am Ende des Tages",
        "GRAPHS.FUEL_CONSUME": "Benzinverbrauch",
        "GRAPHS.FUEL_BUY": "Benzinkauf",
        "GRAPHS.TEMP_CHART": "Grafik des täglichen Temperatursensors",
        "GRAPHS.TEMP_TABLE": "Tabelle des täglichen Temperatursensors",
        "GRAPHS.TEMP_AVG": "Tagesdurchschnittstemperatur",
        "GRAPHS.FUEL_PER": "Benzinanzeige in Prozent",
        "GRAPHS.FUEL_LT": "Benzinanzeige in Liter",
        "GRAPHS.WEATHER_FORECAST": "Wettervorhersage",
        "GRAPHS.FUEL_FULLING": "Benzin füllungsliste",
        "GRAPHS.RPM": "RPM",
        "GRAPHS.RPM_CHART": "Grafik der täglichen RPM",
        "GRAPHS.FUEL_GES": "Kilometerstand beim Tanken KM",
        "GRAPHS.FUEL_GETANKE": "Getankte Liter",
        "GRAPHS.VOLLTANK": "Details zum Volltank",
        "GRAPHS.WARM_CHART": "Motor Sıcaklığı Grafiği",
        "GRAPHS.SEVEN_FIRST_LAST": "7 tage erste Start und letzte stop",
        "GRAPHS.INFO": "Wenn Sie mit der Maus auf eine Grafik zeigen, erhalten Sie weitere Informationen.",
        "GRAPHS.FUEL_INFO2": "Nach der Installation geben Sie 'Auto-Service-Abschnitt unter dem Kraftstoffsensor angeordnet, die Kraftstoffmenge in Prozent. Dann werden die Daten überprüfen mikltar Kraftstoff das Portal eingeben.",
        "GRAPHS.STEP1": "Schritt 1",
        "GRAPHS.STEP2": "Schritt 2",
        "SIGNALS.SHOW_MAP": "Karte anzeigen",
        "SIGNALS.SHOW_STREET_NAMES": "Zeige einzelne Straßennamen",
        "SIGNALS.SHOW_SIGNALS": "Signale anzeigen",
        "SIGNALS.ONLY_STARTSTOP": "Zeige nur Start & Stop",
        "SIGNALS.EXCEPT_ZERO": "Zeige nur Geschwindigkeit Informationen",
        "SIGNALS.SHOW_THESE": "Zeige nur Kontakte",
        "SIGNALS.SIGNAL_TYPE": "Signaltyp",
        "SIGNALS.LOC_CHG": "Schalterstellung",
        "SIGNALS.SIGNAL_STR": "Signalstärke",
        "SIGNALS.NR_SAT": "Sat. Anzahl",
        "SIGNALS.AKU_VOLT": "Akku Spannung",
        "SIGNALS.BAT_VOLT": "Batterie Spannung",
        "SIGNALS.IN_OUT": "Eingang / Ausgang",
        "SIGNALS.TOTAL_DURATION": "Gesamtzeit",
        "SIGNALS.IN": "Eingang",
        "SIGNALS.OUT": "Ausgang",
        "SIGNALS.MAX_3_DAYS": "Es können max. 3 Tage gewählt werden.",
        "SIGNALS.signal_mode_0": "Periodisches Zeitsignal",
        "SIGNALS.signal_mode_1": "--",
        "SIGNALS.signal_mode_2": "Stand By",
        "SIGNALS.signal_mode_3": "Fahrzeug Start",
        "SIGNALS.signal_mode_4": "Spannung Aus",
        "SIGNALS.signal_mode_5": "--",
        "SIGNALS.signal_mode_6": "--",
        "SIGNALS.signal_mode_7": "--",
        "SIGNALS.signal_mode_8": "--",
        "SIGNALS.signal_mode_9": "--",
        "SIGNALS.signal_mode_10": "--",
        "SIGNALS.signal_mode_11": "Kontakt 2 geschaltet",
        "SIGNALS.signal_mode_12": "Kontakt 2 abgeschaltet",
        "SIGNALS.signal_mode_13": "Kontakt 3 geschaltet",
        "SIGNALS.signal_mode_14": "Kontakt 3 abgeschaltet",
        "SIGNALS.signal_mode_15": "Kontakt 4 geschaltet",
        "SIGNALS.signal_mode_16": "Kontakt 5 abgeschaltet",
        "SIGNALS.signal_mode_17": "--",
        "SIGNALS.signal_mode_18": "--",
        "SIGNALS.signal_mode_19": "--",
        "SIGNALS.signal_mode_20": "--",
        "SIGNALS.signal_mode_21": "--",
        "SIGNALS.signal_mode_22": "--",
        "SIGNALS.signal_mode_23": "--",
        "SIGNALS.signal_mode_24": "--",
        "SIGNALS.signal_mode_25": "Richtungsänderung",
        "SIGNALS.signal_mode_26": "Entfernungssignal",
        "SIGNALS.signal_mode_27": "--",
        "SIGNALS.signal_mode_28": "--",
        "SIGNALS.signal_mode_29": "--",
        "SIGNALS.signal_mode_30": "--",
        "SIGNALS.signal_mode_31": "--",
        "SIGNALS.signal_mode_32": "--",
        "SIGNALS.signal_mode_33": "--",
        "SIGNALS.signal_mode_34": "--",
        "SIGNALS.signal_mode_35": "--",
        "SIGNALS.signal_mode_36": "--",
        "SIGNALS.signal_mode_37": "--",
        "SIGNALS.signal_mode_38": "--",
        "SIGNALS.signal_mode_39": "--",
        "SIGNALS.signal_mode_40": "--",
        "SIGNALS.signal_mode_41": "--",
        "SIGNALS.signal_mode_42": "--",
        "SIGNALS.signal_mode_43": "--",
        "SIGNALS.signal_mode_44": "--",
        "SIGNALS.signal_mode_45": "--",
        "SIGNALS.signal_mode_46": "--",
        "SIGNALS.signal_mode_47": "--",
        "SIGNALS.signal_mode_48": "--",
        "SIGNALS.signal_mode_49": "--",
        "SIGNALS.signal_mode_50": "--",
        "SIGNALS.signal_mode_51": "--",
        "SIGNALS.signal_mode_52": "--",
        "SIGNALS.signal_mode_53": "--",
        "SIGNALS.signal_mode_54": "--",
        "SIGNALS.signal_mode_55": "--",
        "SIGNALS.signal_mode_56": "--",
        "SIGNALS.signal_mode_57": "--",
        "SIGNALS.signal_mode_58": "--",
        "SIGNALS.signal_mode_59": "--",
        "SIGNALS.signal_mode_60": "--",
        "SIGNALS.signal_mode_61": "--",
        "SIGNALS.LAT": "Breitengrad",
        "SIGNALS.LONG": "Längengrad",
        "SERVICE.VEHICLE_NAME": "Name des Fahrzeugs",
        "SERVICE.CURRENT_MILEAGE": "Aktueller Km Stand",
        "SERVICE.INSTALL_MILEAGE": "Einrichtungskilometer",
        "SERVICE.AVG_FUEL": "Durchschnittlicher Kraftstoffverbrauch",
        "SERVICE.CURRENT_RUN_TIME": "Instant Laufzeit",
        "SERVICE.INSTALL_RUN_TIME": "Arbeitsaufwand bei Installation",
        "SERVICE.EMAIL_ADDRESS": "Benachrichtigung per E-Mail",
        "SERVICE.INFORMED_BY_MAIL": "Ich möchte per E-Mail informiert werden.",
        "SERVICE.CURRENT_ICON": "Bestehende Fahrzeugsymbole",
        "SERVICE.ICON_GALLERY": "Symbolgalerie",
        "SERVICE.CHOOSE_NEW_ICON": "Wählen Sie hier ein neues Symbol aus",
        "SERVICE.BASIC_SETTINGS": "Grundeinstellungen",
        "SERVICE.IGNITION": "Kontakt",
        "SERVICE.IGNITIONS": "Kontakte",
        "SERVICE.OIL_SERVICE": "Ölservice",
        "SERVICE.BRAKE_SERVICE": "Bremsservice",
        "SERVICE.BELT_SERVICE": "Zahnriemenservice",
        "SERVICE.INSPECTION_SERVICE": "HU/AU",
        "SERVICE.TACHOGRAPH_SERVICE": "Taxometer",
        "SERVICE.TRAILER_SERVICE": "Anhänger/Auflieger",
        "SERVICE.HIDE_SERVICE": "Fahrzeug unsichtbar",
        "SERVICE.HIDE_SERVICE_DATA": "Privatfahrt",
        "SERVICE.FUEL_SERVICE": "Benzinsensor",
        "SERVICE.TEMP_SERVICE": "Wärmesensor",
        "SERVICE.SERVICE_INTERVAL": "Service Intervall",
        "SERVICE.NEW_SERVICE_HISTORY": "Service Neuanmeldung",
        "SERVICE.CREATE_SERVICE_HISTORY": "Erstellen Sie eine neue Serviceleistung",
        "SERVICE.DELETE_SERVICE_HISTORY": "Serviceanmeldung löschen?",
        "SERVICE.DELETE_CONFIRM": "Wollen Sie wirklich löschen?",
        "SERVICE.DELETE_SUCCESS": "Gelöscht",
        "SERVICE.DELETE_ERROR": "Es liegt ein Fehler vor. Daten wurden nicht gelöscht.",
        "SERVICE.BACK_TO_SERVICE": "Zurück zu Service",
        "SERVICE.SERVICE_HISTORY": "Vergangene Serviceleistungen",
        "SERVICE.UPDATE_SUCCESS": "Die Daten wurden erfolgreich gespeichert.",
        "SERVICE.UPDATE_ERROR": "Es trat ein Fehler auf. Bitte versuchen Sie erneut abzuspeichern.",
        "SERVICE.INTERVAL_MONTH": "Monatsintervall",
        "SERVICE.ACTIVATION_SETTINGS": "Einstellungen zur Aktivierung",
        "SERVICE.DATE_RANGE": "Zeitraum",
        "SERVICE.ACTIVE_STATUS": "Aktiver Status",
        "SERVICE.RESET_DATES": "Daten zurücksetzen",
        "SERVICE.RESET_ALL_DATA": "Alle Daten zurücksetzen",
        "SERVICE.ROMORK_PLATE": "Sattelzug",
        "SERVICE.ALERT_TOP": "Sie haben Servicemitteilungen!",
        "SERVICE.ALERT_KM": "Kilometer bis zum nächsten Servicetermin:",
        "SERVICE.ALERT_DATE": "Bis zum nächsten Servicetermin (Tage):",
        "SERVICE.ALERT_KM_EXCEEDED": "Sie haben die Kilomter bis zum nächsten Service überschritten. Bitte kontrollieren Sie Ihre vergangenen Serviceeinträge.",
        "SERVICE.ALERT_DATE_EXCEEDED": "Sie haben den Termin bis zum nächsten Service überschritten. Bitte kontrollieren Sie Ihre vergangenen Serviceeinträge.",
        "SERVICE.INTERVAL_INFO": "Ihre Servicetermin können Sie nach Kilomterstand oder monatlich einstellen.",
        "SERVICE.CREATE_HISTORY_INFO": "Um mit den Daten und dem Kilometerstand einen neuen Eintrag zu erstellen, klicken Sie in das Kästchen.",
        "SERVICE.IN_DEMO": "Sie verwenden derzeit die Demo-Version.",
        "SERVICE.DEMO_EXPIRED": "Die Demo-Version des ausgewählten Geräts ist abgelaufen.",
        "SERVICE.DEMO_OR_BUY": "Kaufen Sie jetzt die Vollversion für das ausgewählte Fahrzeug oder nutzen Sie die 10 tägige Demo-Version",
        "SERVICE.NOT_PERMITTED": "Sie haben keine Erlaubnis, das ausgewählte Fahrzeug mit diesen Funktionen zu nutzen.",
        "SERVICE.NOT_PERMITTED_MULTI": "Sie haben nicht die Erlaubnis diese Funktion für einige oder alle Fahrzeuge zu nutzen.",
        "SERVICE.IVDC_DATE_ALERT": "Das Anfangsdatum darf nicht größer sein als das Enddatum",
        "SERVICE.HIDE_DEVICE_FOR_TIME": "Fahrzeug unsichtbar nach Zeit",
        "SERVICE.HIDE_DEVICE_FOR_ALWAYS": "Fahrzeug unsichtbar",
        "SERVICE.HIDE_DEVICE_FOR_ALWAYS_COMMENT": "Fahrzeug unsichtbar an-aus",
        "SERVICE.HIDE_ALWAYS": "Das Fahrzeug immer unsichtbar",
        "SERVICE.SHOW_ALWAYS": "Das Fahrzeug sichtbar machen",
        "SERVICE.DEVICE_HIDDEN": "Das Fahrzeug ist momentan unsichtbar.",
        "SERVICE.DEVICE_PRIVATE": "Privatfahrt ist aktiviert. Es werden keine Daten aufgezeichnet!",
        "SERVICE.IVDC_INFO": "Bitte aktivieren Sie das Häckchen zur Bestätigung der Zeit und Datum Auswahl",
        "SERVICE.SERVICE_ALERT": "Fahrzeug Service fällig!",
        "SERVICE.SERVICE_TYPE_1": "Ölservice",
        "SERVICE.SERVICE_TYPE_2": "Bremsservice",
        "SERVICE.SERVICE_TYPE_3": "Zahnriemenservice",
        "SERVICE.SERVICE_TYPE_4": "HU/AU",
        "SERVICE.SERVICE_TYPE_5": "Taxometer",
        "SERVICE.SERVICE_TYPE_6": "Anhänger/Auflieger",
        "SERVICE.POSTPONE_ALERTS": "Serviceanzeige fuer 7 Tage nicht anzeigen",
        "SERVICE.PRIVATE_DRIVE": "Wenn Sie aktivieren, werden die Daten niemals aufgezeichnet!",
        "SERVICE.PRIVATE_DRIVE_FOR_TIME": "Privatfahrt für eine bestimmte Zeit. Bitte wählen Sie den Zeitraum aus!",
        "SERVICE.PRIVATE_DRIVE_FOR_ALWAYS": "Bitte Klicken Sie für eine durchgehende Privatfahrt",
        "SERVICE.PRIVATE_DRIVE_FOR_ALWAYS_COMMENT": "Bitte Klicken Sie für eine ständige Privatfahrt. Die Daten werden niemals aufgezeichnet!",
        "SERVICE.PRIVATE_SHOW_ALWAYS": "Daten Erfassung deaktivieren",
        "SERVICE.PRIVATE_HIDE_ALWAYS": "Daten Erfassung aktivieren",
        "SERVICE.FUEL_INFO": "Kraftstoffmenge (lt): eintragen wenn Fahrzeug vollgetankt ist:",
        "SERVICE.INFO_CURRENT_MILEAGE": "Gesamtkilometer des Fahrzeuges ( inkl. Kilometer bei Einbau )",
        "SERVICE.INFO_AVG_FUEL": "Kraftstoffverbrauch pro gefahrene 100 Km",
        "SERVICE.INFO_INSTALL_MILEAGE": "Kilometerstand des Fahrzeuges vor Einbau des Ortungsgerätes eintragen",
        "SERVICE.INFO_INSTALL_RUN_TIME": "Geschätzte aktive Arbeitszeit des Fahrzeuges vor Einbau des Ortungsgerätes eintragen",
        "SERVICE.INFO_CURRENT_RUN_TIME": "Gesamte Arbeitszeit des Fahrzeuges",
        "SERVICE.FUEL_INFO2": "Nach der Installation geben Sie 'Auto-Service-Abschnitt unter dem Kraftstoffsensor angeordnet, die Kraftstoffmenge in Prozent. Dann werden die Daten überprüfen mikltar Kraftstoff das Portal eingeben.",
        "SERVICE.STEP1": "Schritt 1",
        "SERVICE.STEP2": "Schritt 2",
        "GROUPS.ALL": "Alle Gruppen",
        "GROUPS.NAME": "Name der Gruppe",
        "GROUPS.UPDATE": "Gruppe aktualisieren",
        "GROUPS.CREATE": "Gruppe erstellen",
        "GROUPS.EDIT_NAME": "Gruppe umbenennen",
        "GROUPS.DELETE": "Gruppe löschen",
        "GROUPS.CONFIRM_DELETE": "Sind Sie sich sicher, dass Sie diese Gruppe löschen möchten?",
        "GROUPS.DELETE_SUCCESS": "Gruppe erfolgreich gelöscht.",
        "GROUPS.DELETE_ERROR": "Fehler beim Löschen der Gruppe. Bitte versuchen Sie es erneut.",
        "GROUPS.CREATE_NEW_GROUP": "Neue Gruppe erstellen",
        "GROUPS.FILL_GROUP_NAME": "Bitte benennen Sie Ihre Gruppe",
        "GROUPS.DEVICES_IN_GROUP": "Fahrzeuge der Gruppe",
        "GROUPS.ADD_REMOVE_DEVICE": "Fahrzeug einfügen - löschen",
        "GROUPS.SELECTED_DEVICES": "Ausgewählte Fahrzeuge",
        "GROUPS.DEVICES_TO_ADD": "Hinzuzufügende Fahrzeuge",
        "REPORTS.DAILY": "Tagesbericht",
        "REPORTS.MONTHLY": "Monatsbericht",
        "REPORTS.ROUTE": "Fahrtenbuch",
        "REPORTS.DEVICE_REPORTS": "Fahrzeugbezogene Berichte",
        "REPORTS.DRIVER_REPORTS": "Fahrerbezogene Berichte",
        "REPORTS.WITH_DRIVERS": "Mit Fahrererkennung",
        "REPORTS.ONLY_PRIVATE": "Nur Privatfahrten",
        "REPORTS.MANUAL_TRIP": "Manuell hinzugefügte Fahrten",
        "REPORTS.DAY_TOTAL": "gesamter Tagesbericht",
        "REPORTS.ALL_TOTAL": "Gesamt allgemein",
        "REPORTS.ROUTE_NO": "Routennr.",
        "REPORTS.ADD_TRIP": "Fahrt hinzufügen",
        "REPORTS.EDIT_TRIP": "Fahrt bearbeiten",
        "REPORTS.ADD_TRIP_INFO": "Klicken Sie hier, um zu dieser Fahrt eine weitere hinzuzufügen",
        "REPORTS.DELETE_TRIP_INFO": "Fahrteninformation löschen",
        "REPORTS.DELETE_TRIP": "Fahrt löschen",
        "REPORTS.CONFIRM_DELETE_TRIP": "Sind Sie sicher, dass Sie diese Fahrt löschen möchten?",
        "REPORTS.DAILY_STANDBY": "Ruhezeit",
        "REPORTS.TOTAL_ACTIVE_TIME": "gesamte Aktivzeit ",
        "REPORTS.REPORT_DETAIL": "Bericht Details",
        "REPORTS.ADD_REPORT": "Berichte hinzufügen",
        "REPORTS.DELETE_REPORT": "Berichte löschen",
        "REPORTS.EDIT_REPORT": "Berichte aktualisieren",
        "REPORTS.PICK_DEVICE_OR_DRIVER": "Wählen Sie bitte ein Fahrzeug oder einen Fahrer aus",
        "REPORTS.TRIP_TYPE": "Dienst/Privat",
        "REPORTS.TRIP_TYPE_0": "Unbekannt",
        "REPORTS.TRIP_TYPE_1": "Dienst",
        "REPORTS.TRIP_TYPE_2": "Privat",
        "REPORTS.TRIP_TYPE_3": "Heimweg",
        "REPORTS.FAVORITES": "Favoriten",
        "REPORTS.PICK_FAV": "Wählen Sie einen Favoriten aus",
        "REPORTS.FAV_DETAIL": "Details der Favoriten",
        "REPORTS.FAV_INFO": "Ziele, die vorher abgespeichert wurden, werden den Favoriten hinzugefügt. Wenn die Einträge bei der nächsten Fahrt mit dem Zielübereinstimmen, wird dies in der unteren Tabelle angezeigt.",
        "REPORTS.POST_CODE": "PLZ",
        "REPORTS.ABOUT": "Grund",
        "REPORTS.VISITED": "Ziel",
        "REPORTS.REPORT_TYPE": "Art der Berichte",
        "REPORTS.SHOW_DEVICE_NAME": "Anzeigen des Fahrzeugnamen",
        "REPORTS.MONTHLY_REPORT_FOR_ALL_DEVICE": "Monatsbericht von allen Fahrzeugen",
        "REPORTS.DAILY_REPORT_FOR_ALL_DEVICE": "Tagesbericht von allen Fahrzeugen",
        "REPORTS.DAILY_REPORT_THIS_DEVICE": "Tagesbericht nur für dieses Fahrzeug",
        "REPORTS.SPEED_REPORT_THIS_DEVICE": "Geschwindigkeitsbericht für dieses Fahrzeug",
        "REPORTS.SELECT_REPORT": "Bitte wählen sie gewünschte Berichte",
        "REPORTS.YEARLY": "Jahresbericht",
        "REPORTS.YEARLY_PDF": "Jahresbericht PDF Export",
        "REPORTS.SELECT_YEAR": "Jahr wählen",
        "REPORTS.FILTER_BY_KM": "0.0 - 0.2 anzeigen/ausblenden",
        "REPORTS.DATE_LIMIT_ALERT": "Auszuwählende max. Datumsspanne:",
        "REPORTS.DATE_LIMIT_ALERT_2": "Um die Datumsspanne zu vergrößern, benötigen Sie ein Paketupgrade. \nBitte kontaktieren Sie unseren Support, damit ein Paketupgrade durchgeführt werden kann.",
        "REPORTS.CANCELED_REPORTS": "Liste der widerrufenen Einträge",
        "REPORTS.DAYS_7": "Streckenanzeige mit 7 Tage Datenarchivierung",
        "REPORTS.SHOW_SERVICE_TRIPS": "Zeige nur Dienstfahrten",
        "REPORTS.REPORT_NO": "Bericht Nummer",
        "ALERT.FILL_THE_BLANKS": "Bitte füllen Sie alles aus",
        "ALERT.UPDATE_SUCCESS": "Die Daten wurden erfolgreich aktualisiert",
        "ALERT.DELETE_SUCCESS": "Daten erfolgreich gelöscht.",
        "ALERT.SAVE_SUCCESS": "Daten erfolgreich gespeichert.",
        "ALERT.SMS_SENT_SUCCESS": "SMS wurde erfolgreich versendet.",
        "ALERT.SMS_SENT_FAIL": "Beim SMS Versand ist ein Fehler aufgetreten.",
        "USER_RIGHTS.ALL_USERS": "Alle Benutzer",
        "USER_RIGHTS.ADD_REMOVE_USER_RIGHT": "Zugriffsrechte hinzufügen - löschen",
        "USER_RIGHTS.NEW_USER": "Neuer Benutzer",
        "USER_RIGHTS.REMOVE_USER": "Benutzer löschen",
        "USER_RIGHTS.UPDATE_USER": "Benutzerdaten aktualisieren",
        "USER_RIGHTS.USER_STATUS": "Benutzer aktiv",
        "USER_RIGHTS.ACT_START": "Aktivieren",
        "USER_RIGHTS.ACT_END": "Deaktivieren",
        "USER_RIGHTS.ER_MAIL_AE": "Diese E-Mailadresse wurde verwendet.",
        "USER_RIGHTS.ER_UN_AE": "Dieser Bentuzername wurde verwendet.",
        "USER_RIGHTS.ER_PW_1": "Passwörter stimmen nicht überein. Bitte überprüfen Sie Ihre Eingabe.",
        "USER_RIGHTS.ER_NN": "Bitte füllen Sie alle Pflichtfelder aus.",
        "USER_RIGHTS.FILL_OBLIGATION": "Dies ist ein Pflichtfeld.",
        "USER_RIGHTS.ER_MAIL_VAL": "Ungültige E-Mailadresse.",
        "USER_RIGHTS.NOTHING_FOUND": "Kein Zugriff gewährtleistet.",
        "USER_RIGHTS.CHOOSE_USER": "Bitte wählen Sie einen Benutzer aus oder fügen einen neuen Benutzer hinzu. ",
        "USER_RIGHTS.CONFIRM_DELETE": "Möchten Sie diesen Unterbenutzer wirklich löschen ? ",
        "USER_RIGHTS.tracking": "Fahrzeug Ortung",
        "USER_RIGHTS.live": "Live Ortung",
        "USER_RIGHTS.all_tracks": "Alle Strecken",
        "USER_RIGHTS.velocity": "Geschwindigkeit",
        "USER_RIGHTS.tracking_route": "Anzeige der Strecke",
        "USER_RIGHTS.unlimited_tracking": "ohne 7 tägige Begrenzung",
        "USER_RIGHTS.routing": "Route",
        "USER_RIGHTS.daily": "Tagesbericht",
        "USER_RIGHTS.monthly": "Monatsbericht",
        "USER_RIGHTS.drive": "Fahrtenbuch",
        "USER_RIGHTS.driver": "Fahrerbericht",
        "USER_RIGHTS.sensoren": "Kontaktbericht",
        "USER_RIGHTS.speed": "Geschwindigkeitsbericht",
        "USER_RIGHTS.electronic": "Elektronischer Bericht",
        "USER_RIGHTS.theft": "Diebstahl Schutz",
        "USER_RIGHTS.phone_2": "2. Handy",
        "USER_RIGHTS.sms": "SMS",
        "USER_RIGHTS.service": "Fahrzeug Service",
        "USER_RIGHTS.device_groups": "Fahrzeuggruppen",
        "USER_RIGHTS.roaming1": "Roaming",
        "USER_RIGHTS.zugriffsrechte": "Zugriffsrechte",
        "USER_RIGHTS.gsm_eplus": "GSM E-Plus",
        "USER_RIGHTS.gsm_tmobile": "GSM T-Mobile",
        "USER_RIGHTS.email_berichte": "Tages- / Monats- Berichte email Versandt",
        "USER_RIGHTS.play": "Animation",
        "USER_RIGHTS.USER_TYPE": "Zugriffslevel",
        "USER_RIGHTS.ACTIVE_USERS": "Active Benutzer",
        "USER_RIGHTS.PASSIVE_USERS": "Inactive Benutzer",
        "USER_RIGHTS.SUBUSER_DEVICE_NAME": "Unterbenutzer soll diesen Fahrzeugnamen benutzen:",
        "USER_RIGHTS.EDIT_DEVICE_NAME": "Fahrzeugnamensänderung durch Unterbenutzer",
        "USER_RIGHTS.autoplay": "Autoplay",
        "USER_RIGHTS.measure": "Strecke Messen",
        "USER_RIGHTS.autopilot": "Autopilot",
        "USER_RIGHTS.planned_route": "Geplante Strecke",
        "USER_RIGHTS.location": "Aufenthaltsort",
        "USER_RIGHTS.graphical": "Grafische Darstellung",
        "USER_RIGHTS.fuel": "Tankabrechnung",
        "USER_RIGHTS.optimize": "Streckenoptimierung",
        "USER_RIGHTS.18_months": "18 Months Data",
        "USER_RIGHTS.ADD_FAVORI": "Neuen Favoriten hinzufügen",
        "USER_RIGHTS.DELETE_FAVORI": "Löschen",
        "USER_RIGHTS.UPDATE_FAVORI": "Aktualisierung",
        "USER_RIGHTS.FAVORI_NAME": "Favoritennamen",
        "USER_RIGHTS.NAME_ALARM": "Bitte überprüfen Sie die Favoritennamen Dropping.",
        "USER_RIGHTS.SELECT_FAVORITE": "Wählen Sie einen Favoriten",
        "USER_RIGHTS.ALERT_FAVORITE": "Wählen Sie Favorit",
        "USER_RIGHTS.ALARM_OPTIONS": "Bitte wählen Sie mindestens eine Option.",
        "USER_RIGHTS.CONFIRM_FAVORI_DELETE": "Sind Sie sicher, dass Sie löschen möchten?",
        "USER_RIGHTS.SUCCESS_DELETE": "Erfolgreiche Löschung.",
        "USER_RIGHTS.SUCCESS_SAVE": "Erfolgreiche Speichern.",
        "USER_RIGHTS.SUCCESS_UPDATE": "Erfolgreiche Aktualisierung.",
        "USER_RIGHTS.FAVORITE_OPTIONS": "Favoritenoption",
        "USER_RIGHTS.FAVORITE_FUNC": "Favoritenfunktion",
        "USER_RIGHTS.FAVORITE": "Favoriten",
        "USER_RIGHTS.SAVEALL": "Diese Einstellung für alle Fahrzeuge übernehmen",
        "USER_RIGHTS.air_notifications": "Air alarm notifications",
        "RENTACAR.NEW_RENTACAR": "Neuer Eintrag zur Autovermietung",
        "RENTACAR.OLD_RENTACAR": "Alter Eintrag zur Autovermietung",
        "RENTACAR.IDENTITY_NO": "Ausweisnummer",
        "RENTACAR.LICENSE_NO": "Fahrzeugscheinnummer",
        "RENTACAR.LICENSE_CLASS": "Führerscheinklasse",
        "RENTACAR.BIRTH_DATE": "Geburtsdatum",
        "RENTACAR.PHONE_NO": "Telefonnummer",
        "RENTACAR.ADDRESS": "Anschrift",
        "RENTACAR.RENT_DATE": "Datum der Anmietung",
        "RENTACAR.RENT_BACK_DATE": "Datum der Rückgabe",
        "RENTACAR.VEHICLE_KM": "Kilometerstand",
        "RENTACAR.FUEL_AMOUNT": "Benzinstand",
        "RENTACAR.EQUIPMENT": "Austattung",
        "RENTACAR.NOTES": "Notizen",
        "RENTACAR.RENT_PRICE": "Mietpreis",
        "RENTACAR.BAILS": "Kaution",
        "RENTACAR.READ_ACCEPT_AGR1": "Allgemeine Geschäftsbedingungen",
        "RENTACAR.READ_ACCEPT_AGR2": "'habe ich gelesen; stimme ich zu.",
        "RENTACAR.CHOOSE_DEV": "Wählen Sie ein Fahrzeug aus.",
        "RENTACAR.DRIVER_NAME": "Name des Fahrers",
        "RENTACAR.CHOOSE": "Auswahl",
        "RENTACAR.ADD_NEW": "Neu hinzufügen",
        "RENTACAR.UPDATE": "Aktualisieren",
        "RENTACAR.RENTER": "Mieten",
        "RENTACAR.DAMAGES": "Beschädigung",
        "GAUGAE.FULL_SCREEN": "Vollbild",
        "GAUGAE.LIVE_ON": "Live Aktualisierung an",
        "GAUGAE.LIVE_OFF": "Live Aktualisierung aus",
        "GAUGAE.ANIM_ON": "Live Übertragung an",
        "GAUGAE.ANIM_OFF": "Live Übertragung aus",
        "GAUGAE.GAUGAE_OPEN": "Anzeige an",
        "GAUGAE.GAUGAE_CLOSE": "Anzeige aus",
        "GAUGAE.SEARCH_ADR": "Nach Adresse suchen",
        "GAUGAE.PAGE_NOT_FOUND": "Seite konnte nicht gefunden werden",
        "GAUGAE.FUEL_AMOUNT": "Benzinstand",
        "GAUGAE.TOTAL_KM": "Gesamtkilometer",
        "GAUGAE.DAILY_KM": "Tageskilometer",
        "GAUGAE.TEMP": "Temperatur",
        "GAUGAE.HUMIDITY": "Feuchtigkeit",
        "GAUGAE.WEATHER_TEXT": "Wetterlage,Witterung und weitere Aussichten für die kommende Tage.",
        "GAUGAE.CANBUS_TAGES": "7 Tage KM",
        "GAUGAE.ZURLIVE": "Zur live",
        "GAUGAE.ZURANIM": "Live Animation Starten",
        "GAUGAE.M_DISTANCE": "ausgemessener Abstand",
        "DRIVERS.GENERAL_INFO": "Allgemeine Informationen",
        "DRIVERS.DRIVER_NO": "Fahrernummer",
        "DRIVERS.DRIVER_TEL": "Telefonnummer des Fahrers",
        "DRIVERS.LICENSE_CONTROL": "Führerscheinkontrolle",
        "DRIVERS.FAVORITE_DEVICE": "Bevorzugtes Fahrzeug",
        "DRIVERS.CREATE_DRIVER": "Neuen Fahrer hinzufügen",
        "DRIVERS.BUTTON_LIST": "Liste der I-Buttons",
        "DRIVERS.BUTTON_NO": "Schlüsselnummer",
        "DRIVERS.BUTTON_SERIAL_NO": "Seriennummer der I-Buttons",
        "DRIVERS.BUTTON_TYPE": "I-Button Typ",
        "DRIVERS.ASSIGN_DRIVER": "Fahrer zuweisen",
        "DRIVERS.NEW_DRIVER": "Neuer Fahrer",
        "DRIVERS.CREATE_NEW_DRIVER": "Neuen Fahrer anlegen",
        "DRIVERS.WORK_HOURS_CONTROL": "Kontrolle der Arbeitszeiten",
        "DRIVERS.WORK_HOURS": "regelmäßige Arbeitszeiten",
        "DRIVERS.WORK_HOURS_START": "Arbeitsstartzeit",
        "DRIVERS.WORK_HOURS_STOP": "Arbeitsendzeit",
        "DRIVERS.MAX_OVERTIME": "max. Überstunden",
        "DRIVERS.INSPECTION_INTERVAL": "Inspektionsabstände",
        "DRIVERS.SPECIFIED_DATE": "Bestimmtes Datum",
        "DRIVERS.UPDATE_DRIVER": "Fahrer bearbeiten",
        "DRIVERS.DELETE_DRIVER": "Fahrer löschen",
        "DRIVERS.CONFIRM_DELETE_DRIVER": "Sind Sie sich sicher, dass Sie den Fahrer löschen möchten?",
        "DRIVERS.DRIVER_NOT_FOUND": "Fahrer konnte nicht gefunden werden.",
        "DRIVERS.FAV_DRIVER": "Bevorzugter Fahrer",
        "DRIVERS.HAS_FAV_DRIVER": "Bevorzugter Fahrer für dieses Fahrzeug:",
        "DRIVERS.HAS_NO_FAV_DRIVER": "Für dieses Fahrzeug wurde kein Fahrer ausgewählt.",
        "DRIVERS.UNSELECT_DRIVER": "Um die Fahrerauswahl zu löschen, bitte hier klicken",
        "DRIVERS.DRIVERS_NOTIFICATION": "In Diebstahlschutz als 2. Handynummer übernehmen (nicht für I-Button Möglich)",
        "DRIVERS.ENTER_PHONE_NUMBER": "Eingabe der 2ten Mobilfunknummer für Diebstahlschutz",
        "DRIVERS.ENTER_PHONE_NUMBER_TITLE": "Automatisches übernehmen der 2'ten Mobilfunknummer für den  Diebstahschutz",
        "DRIVERS.MOBILE_COMMUNICATION": "Zugangsdaten App zur Fahrerkommunikation",
        "DRIVERS.DRIVER_NO_AUTO": "Benutzername bei App (Automatisch)",
        "DRIVERS.DRIVER_PASS": "Fahrer Passwort",
        "DRIVERS.MOBILE_APP_STATUS": "Mobile App Status",
        "DRIVERS.ACTIVE": "Aktiv",
        "DRIVERS.DISABLE": "Deaktiv",
        "DRIVERS.24_HOUR_DRIVER": "24 stunden fahrer automatisch angemeldet bleiben",
        "DRIVERS.ALWAYS_DRIVER": "Fahrer soll immer angemeldet bleiben (bis andere angemeldet wird)",
        "DRIVERS.CLOSE": "Schliessen",
        "DRIVERS.MOBILE_DEVICES": "Mobile Geräte",
        "THEFT.STEP1": "Schritt 1: Folgen Sie der Fahrzeugstrecke.",
        "THEFT.STEP1_REPORTS": "Diebstahlschutz Berichte",
        "THEFT.STEP2": "Schritt 2: Benachrichtigungen",
        "THEFT.STEP2_INFO": "Sollte keine Uhrzeit ausgewählt werden, ist diese Funktion 24 Stunden aktiv. Bitte wählen Sie die gewünschte Uhrzeit aus.",
        "THEFT.STEP3": "Schritt 3: Bitte tragen Sie Ihre Kontaktdaten ein.",
        "THEFT.STEP4": "Schritt 4: Aktivierung der Geofence Zone.",
        "THEFT.WHEN_DEVICE_RUN": "Immer wenn das Fahrzeug in Betrieb ist.",
        "THEFT.WHEN_DOOR_OPEN": "Immer wenn die Tür oder der Kofferraum geöffnet wird.",
        "THEFT.ALARM": "Alarm",
        "THEFT.SAVEALL": "Diese Einstellung für alle Fahrzeuge übernehmen",
        "THEFT.NO_ENTER_ZONE": "Zone Eintritt verboten",
        "THEFT.SMS_ON": "Motor an",
        "THEFT.SMS_OFF": "Motor aus",
        "THEFT.SMS_GPS": "Periodisch",
        "THEFT.SMS_RESET": "Neustart",
        "THEFT.THEFT_TWO_STEP1": "Schritt 1: Aktivierung der Geofence Zone.",
        "THEFT.SAVE_ALL_STEP4": "Durch die Auswahl der Checkbox, sind die Einstellungen für alle Fahrezeuge gültig. Bei nicht selektierter Checkbox nur für das ausgewählte Fahrzeug.",
        "THEFT.AIR_PRESSURE_TIMER": "Nur für die Fahrzeug Alarmanlage einstellung",
        "THEFT.IN": "Zone Eintritt Melden",
        "THEFT.OUT": "Zone Austritt Melden",
        "CUSTOMER_GROUPS.NEW": "Neue Kundengruppe",
        "CUSTOMER_GROUPS.NEW_CUSTOMER": "Neue Kunden",
        "CUSTOMER_GROUPS.EDIT_CUSTOMER": "Kunden bearbeiten",
        "CUSTOMER_GROUPS.CUSTOMER_NO": "Kundennr.",
        "CUSTOMER_GROUPS.FIRMA_NAME": "Firma",
        "CUSTOMER_GROUPS.RESP_PARTNER": "Ansprechpartner",
        "CUSTOMER_GROUPS.CHOOSE_FUNCTION": "Bitte wählen Sie die gewünschte Gruppe",
        "CUSTOMER_GROUPS.FUNC1": "Verschiedene Bereiche der (Kunden) Transportsteuerung für gepaarte Fahrzeuge",
        "CUSTOMER_GROUPS.FUNC2": "Kontrolle der Ankunftszeit",
        "CUSTOMER_GROUPS.FUNC3": "Kontrolle aller Fahrzeuge bei Eintritt in die Zone",
        "CUSTOMER_GROUPS.CONFIRM_DELETE": "Möchten Sie diese Daten wirklich löschen?",
        "CUSTOMER_GROUPS.ADDRESS": "Vorhandene Adressen",
        "CUSTOMER_GROUPS.PLS_PICK_CG": "Bitte wählen Sie eine Kundengruppe aus",
        "CUSTOMER_GROUPS.PLS_PICK_ADR": "BItte wählen Sie eine Adresse aus",
        "CUSTOMER_GROUPS.NEW_ADR": "Neue Adresse",
        "CUSTOMER_GROUPS.NEW_ADR_EXP": "Fügen Sie der neuen Adresse eine Beschreibung hinzu und markieren auf der Karte die Adresse und die Zone. Nach dem Sie Ihre Daten eingetragen haben, klicken Sie auf den grünen Button um zu speichern. Klicken Sie bitte auf den roten Button, wenn Sie keinen weiteren Eintrag wünschen.",
        "CUSTOMER_GROUPS.REPORTS_PAGE": "Berichte der Kundengruppen",
        "CUSTOMER_GROUPS.SHOW_REPORTS": "Anzeigen der Berichte",
        "CUSTOMER_GROUPS.REPORT_DETAIL_NOT_FOUND": "Keine Details für diesen Bericht vorhanden..",
        "CUSTOMER_GROUPS.ENTER_TIME": "Eintrittszeit",
        "CUSTOMER_GROUPS.EXIT_TIME": "Austrittszeit",
        "CUSTOMER_GROUPS.PICK_GRP": "Bitte wählen Sie eine Gruppe aus der Liste aus. Bitte folgen Sie den weiteren Anweisungen.",
        "CUSTOMER_GROUPS.DEV_SYNC": "Fahrzeugbestimmung ( Nur die Gruppen 1 und 2 )",
        "SYSTEM._comment": " IMAGES_FOLDER : Der in dieser Sprache verfasster Ordner enthält Bilder. Damit das System richtig funktioniert, muss jede Sprache vorhanden sein, da sonst die Bilder nicht hochgeladen werden können.",
        "SYSTEM.IMAGES_FOLDER": "./assets/img/de",
        "FUEL.CREATE_RECEIPT": "Erstellen von Benzinbelegen",
        "FUEL.PRICE_LITER": "Literpreis",
        "FUEL.PRICE_TOTAL": "Gesamter Kraftstoffpreis",
        "FUEL.LITER": "Liter",
        "FUEL.LT_100_KM": "Liter/100 km",
        "FUEL.KM_DEFLECTION": " Abweichung +/- 10%",
        "FUEL.USER_AVG": "Der von Ihnen angegebene Verbrauch",
        "FUEL.REAL_AVG": "Errechneter Verbrauch",
        "AUTOPILOT.AUTOPILOT_SETTINGS": "Auto - Pilot Einstellung",
        "AUTOPILOT.DAILY_KM": "Tages km kontrolle",
        "AUTOPILOT.SPEED_CONTROL_1": "Geschwindigkeit kontrolle Stadtgebiete",
        "AUTOPILOT.SPEED_CONTROL_2": "Geschwindigkeit kontrolle Außerhalb Ortschaft ",
        "AUTOPILOT.SPEED_CONTROL_3": "Geschwindigkeit kontrolle Autobahn",
        "AUTOPILOT.IGNITION": "Fahrzeug Zündung Benachrichtigung",
        "AUTOPILOT.12V": "Geräte unter 12V Benachrichtigung",
        "AUTOPILOT.OHNE_GSM": "Geräte ohne GSM / GPS Benachrichtigung",
        "AUTOPILOT.ATOB": "Punkt A zum Punkt B kontrolle",
        "AUTOPILOT.DRIVING_TIME": "Fahrzeit",
        "AUTOPILOT.FROM": "von",
        "AUTOPILOT.DEPERTURE_TIME": "Voraussichtliche Bewegung eines Fahrzeugs ",
        "AUTOPILOT.UNTIL": "bis",
        "AUTOPILOT.PARKED": "Parkzeit",
        "AUTOPILOT.LOCATION_TIME": "Standzeit Benachrichtigung",
        "AUTOPILOT.CHOOSE_PERIOD": "Bitte wählen Sie einen Zeitraum",
        "AUTOPILOT.IGNITION_CONTROL": "Zündung kontrolle",
        "AUTOPILOT.IGNITION_CHANGE": "Benachrichtigung bei Kontaktänderung",
        "AUTOPILOT.TOWING": "Benachrichtigung beim Abschleppen ",
        "AUTOPILOT.EMAIL_INTERVAL": "Wählen Sie die Sendefrequenz für die E-Mail",
        "AUTOPILOT.DEVIATION": "+/- 10% Abweichung",
        "AUTOPILOT.MOBILE_COMMENT": "Die in Schritt 1 bis 11 gemachten Einstellungen erhalten Sie direkt als Benachrichtigung auf Ihrem Apple und Android Gerät wenn Sie eingeloggt bleiben.",
        "AUTOPILOT.BEACON_DEVICE": "Beacon device notification",
        "AUTOPILOT.VEHICLE_NOTIFICATIONS_END_OF_DAY_REPORT": "Fahrzeugbenachrichtigungen und Tagesendbericht",
        "AUTOPILOT.REPORTS_FOR_ALL_VEHICLES": "Berichte für alle Fahrzeuge",
        "AUTOPILOT.NOTIFICATION_TYPE": "Benachrichtigungstyp",
        "AUTOPILOT.SPEED_LIMIT": "Höchstgeschwindigkeit",
        "AUTOPILOT.VEHICLE_SPEED": "Fahrzeuggeschwindigkeit",
        "ROUTES.SHOW_ROUTES": "Routen Anzeige ",
        "ROUTES.RESPITE": "Ruhezeit",
        "ROUTES.WAITING": "Standzeit",
        "ROUTES.FOR_MISSION": "Dienstfahrt",
        "ROUTES.PRIVATE": "Privatfahrt",
        "ROUTES.TRIP": "Fahrtzeiten",
        "ROUTES.DAILY": "Tages",
        "ROUTES.VEHICLE": "Fahrzeuge",
        "ROUTES.VEHICLE_GROUP": "Fahrzeugegruppen",
        "ROUTES.DATE": "Von",
        "ROUTES.ALL_VEHICLE_GROUP": "Alle Fahrzeugegruppen",
        "ROUTES.DIGITAL_INPUT": "Digital Eingang",
        "ANKUFT.ANKUFT": "Ankunft",
        "ANKUFT.ANKUFT_TITLE": "Ankünfte Live",
        "ANKUFT.STATUS_0": "Ankommend",
        "ANKUFT.STATUS_1": "Verspätung",
        "ANKUFT.STATUS_2": "Ziel erreicht",
        "ANKUFT.PLATE": "Kennzeichen",
        "ANKUFT.PLAN": "Plan",
        "ANKUFT.EXCEPTED": "Erwarted",
        "ANKUFT.STATUS": "Status",
        "ANKUFT.ARRIVE_IN": "Ankunft in",
        "ANKUFT.DISTANCE": "Entfernung",
        "ANKUFT.ORDER": "Auftrag",
        "ANKUFT.FROM": "Von",
        "ANKUFT.TO": "Nach",
        "TECHNICAL_SUPPORT.HEAD": "Technischer Support",
        "TECHNICAL_SUPPORT.GENERAL": "Allgemeine Informationen",
        "TECHNICAL_SUPPORT.MESSAGE": "Nachricht",
        "TECHNICAL_SUPPORT.CALL_TIME": "Rückruf Zeit",
        "TECHNICAL_SUPPORT.NAME": "Name",
        "TECHNICAL_SUPPORT.FIRMA": "Firma",
        "TECHNICAL_SUPPORT.PHONE": "Telefon",
        "TECHNICAL_SUPPORT.EMAIL": "Email",
        "TECHNICAL_SUPPORT.INFO": "Unser  technischer Support ist kostenlos, schreiben Sie hier Ihre Fragen",
        "TECHNICAL_SUPPORT.NOW": "Sofort",
        "TECHNICAL_SUPPORT.SEND": "Senden",
        "TECHNICAL_SUPPORT.REQUIRED_NAME": "Bitte Ihren Namen eingeben",
        "TECHNICAL_SUPPORT.REQUIRED_EMAIL": "Bitte Ihre E-Mai Adresse schreiben",
        "TECHNICAL_SUPPORT.REQUIRED_MESSAGE": "Bitte Ihre Nachricht eintragen",
        "TECHNICAL_SUPPORT.SUCCESS": "Ihre Nachricht ist gesendet, Unser Team wird sich in Kürze mit Ihnen in Verbindung setzten.",
        "SMS_OPTIONS.SMS_OPTIONS": "SMS Übersicht",
        "SMS_OPTIONS.SMS_DESCRIPTION": "Ihre gewählte Option wird Monatlich von Ihrer gewählten Zahlungsbedingung abgebucht. Zum abbestellen wenden Sie sich bitte an unseren Support via eMail.",
        "SMS_OPTIONS.EXTRA_PACKAGE": "Automatische SMS Buchung",
        "SMS_OPTIONS.SPENT": "Verbrauch",
        "SMS_OPTIONS.BALANCE": "aktuelle Guthaben",
        "SMS_OPTIONS.ORDER": "Bestellen",
        "SMS_OPTIONS.SMS_ORDER": "SMS Kaufeinstellungen",
        "SMS_OPTIONS.CHOOSE_PACKAGE": "Bitte Paket auswählen!",
        "SMS_OPTIONS.CHOOSE_DEVICE": "Fahrzeug Auswählen",
        "INOUT.PLACE": "Standort",
        "INOUT.DATE": "Datum",
        "INOUT.SEARCH": "Suchen",
        "INOUT.INSTRUCTION": "Schritt 1: Aufenthaltsortauflistung eines ausgewählten Standorts",
        "INOUT.INFO1": "In diesem Schritt können Sie durch die Definierung eines Standortes mit einem gewissen Radius, eine Abfrage durchführen. Lassen sie sich Anzeigen wie oft welche Fahrzeuge diesen Ort aufgesucht haben. ",
        "INOUT.INFO2": "Wie folgt :",
        "INOUT.INFO3": "Wählen Sie auf der rechten Seite das Suchfeld und geben Sie oben in diesem die gewünschte Adresse ein.",
        "INOUT.INFO4": "Wählen Sie die gewünschte Adresse aus den gefundenen Ergebnissen; Alternativ können Sie mit dem Pfeil Werkzeug den Ort auf der Karte suchen.",
        "INOUT.INFO5": "Klicken Sie nun auf der Landkarte das Bleistift-Symbol an und ziehen einen Kreis am gewünschten Standort und Radius.",
        "INOUT.INFO6": "Wählen Sie nun auf der rechten Seite das Datum zur Routenabfrage im ausgewählten Standort aus.",
        "INOUT.INFO7": "Wenn Sie an diesem Datum nur eine bestimmte Zeit abfragen möchten, geben Sie auf der rechten Seite auch Anfangs- und Endzeit für die Suche an.",
        "INOUT.INFO8": "Nachdem Sie alle Optionen ausgewählt haben klicken Sie bitte auf das Symbol 'Suchen'.",
        "INOUT.INFO9": "Als Ergebnis werden Ihnen nun alle Fahrzeuge angezeigt, die sich zu dem ausgewählten Datum und der Zeitspanne, innerhalb des von Ihnen gewählten Standortes befinden. Sie bekommen diese tabellarisch aufgelistet. Mit der ersten Signalaufzeichnung aus der Zone, und der letzten Signalaufzeichnung aus der definierten Zone.",
        "INOUT.INFO10": "Am Ende der Zeile in dieser Tabelle, befindet sich eine Lupe. Möchten sie eine detaillierte Auflistung der Signale aus der Zone, so betätigen Sie diese. Es öffnet sich eine weitere Tabelle mit einer detaillieren Auflistung, wann das Fahrzeug in diese Zone rein-, und was es aus dieser Zone wieder rausgefahren ist. ",
        "INOUT.INFO11": "Durch Betätigen des Fahrzeugkennzeichens öffnet sich die Route, welches das Fahrzeug gefahren ist, passend zu dem ersten und letzten Zonensignale.",
        "INOUT.TIME": "Zeit",
        "INOUT.PLEASE_INSERT": "Bitte geben Sie den Bereich.",
        "INOUT.ENTRY": "Eintritt",
        "INOUT.EXIT": "Austritt",
        "INOUT.TOTAL": "Gesamt",
        "INOUT.STOP": "Abbrechen",
        "MYINOUT.INSTRUCTION": "Schritt 2: Abspeicherung der Standorte",
        "MYINOUT.INFO1": "Wählen Sie auf der rechten Seite das gewünschte Datum zur Routenabfrage aus.",
        "MYINOUT.INFO2": "Wenn Sie am von Ihnen gewählten Datum nur eine bestimmte Zeit abfragen möchten, dann wählen Sie auf der rechten Seite Anfangs- und Endzeit aus.",
        "MYINOUT.INFO3": "Nachdem Sie alle Optionen ausgewählt haben klicken Sie bitte auf den Namen des Standortes den Sie vorher angelegt haben welcher abgefragt werden soll.",
        "MYINOUT.INFO4": "Das Ergebnis: Es werden alle Geräte angezeigt, die sich zu dem ausgewählten Datum und der Zeitspanne in ihrer gemachten Standortauswahl befanden.",
        "MYINOUT.INFO5": "Klicken Sie auf das gewünschte Fahrzeug, wenn Sie in einem neuen Fenster die Route auf der Karte anzeigen möchten.",
        "MYINOUT.INFO6": "Hier werden die von Ihnen gespeicherten Standorte zur Routenabfrage angezeigt.",
        "MYINOUT.TITLE": "Gespeicherte Standortauswahl",
        "MYINOUT.TABLE_TITLE": "Anzeige der Fahrzeuge im ausgewählten Standort",
        "MYINOUT.SAVED_PLACES": "Routenabfrage nach gespeicherten Standorten",
        "MYINOUT.DEVICE": "Fahrzeugkennzeichen",
        "MYINOUT.FIRST": "Erster",
        "MYINOUT.ZONE": "Zoneneintrag",
        "MYINOUT.LAST": "Letzter",
        "MYINOUTFORM.INSTRUCTION": "Schritt 3: Abfrage nach der Aufenthaltsortauflistung mit den gespeicherten Standorten",
        "MYINOUTFORM.INFO1": "Geben Sie oben in der Adresssuchleiste die gewünschte Adresse ein. Alternativ können Sie mit dem Pfeil Werkzeug den Ort auf der Karte suchen.",
        "MYINOUTFORM.INFO2": "Klicken Sie oben in der Mitte auf das Bleistift-Symbol nachdem Sie den Standort, der abgefragt werden soll, gefunden haben.",
        "MYINOUTFORM.INFO3": "Klicken Sie nun auf die Karte und ziehen den Kreis im gewünschten Standort und Radius.",
        "MYINOUTFORM.INFO4": "Geben Sie Ihrem gewählten Standort einen Namen und speichern diesen ab.",
        "MYINOUTFORM.INFO5": "Nach erfolgreicher Speicherung wird Ihre Standortauswahl nun unterhalb in der Liste angezeigt.",
        "MYINOUTFORM.INFO6": "Wenn Sie einen bereits vorher gespeicherten Standort bearbeiten möchten, klicken Sie bitte auf das Stiftsymbol.",
        "MYINOUTFORM.INFO7": "Wählen Sie erneut auf der Karte den Ort zur Routenabfrage aus. Die alte Auswahl wird überschrieben sobald Sie den Button ‚Speichern‘ erneut drücken.",
        "MYINOUTFORM.NAME": "Name Standort",
        "MYINOUTFORM.TABLE_TITLE": "Gespeicherte Standorte zur Routenabfrage",
        "MYINOUTFORM.EDIT_PLACES": "Standorte zur Routenabfrage bearbeiten",
        "ROUTE_TRACKING.START_BUTTON": "Gewünschte Startposition eingeben",
        "ROUTE_TRACKING.STOP_BUTTON": "Gewünschte Zielposition eingeben",
        "ROUTE_TRACKING.STATUS": "Status",
        "ROUTE_TRACKING.DETAIL": "Detail",
        "ROUTE_TRACKING.START_ADRESS_INPUT": "Geben Sie die Startadresse",
        "ROUTE_TRACKING.DEVICE": "Fahrzeug",
        "ROUTE_TRACKING.DRIVER": "Fahrer",
        "ROUTE_TRACKING.START_ADRESS": "Start Adresse",
        "ROUTE_TRACKING.END_ADRESS": "End Adresse",
        "ROUTE_TRACKING.PLAN_START_TIME": "Geplante Startzeit",
        "ROUTE_TRACKING.EMAILS": "Benachrichtigungs E-Mails",
        "ROUTE_TRACKING.PLAN_KM_TIME": "KM und die geschätzte Zeit",
        "ROUTE_TRACKING.PLAN_KM": "Geschätzte KM",
        "ROUTE_TRACKING.PLAN_TIME": "Geschätzte Zeit",
        "ROUTE_TRACKING.SELECT_DEVICE": "Fahrzeug auswählen",
        "ROUTE_TRACKING.SELECT_DRIVER": "Fahrer auswählen",
        "ROUTE_TRACKING.INTERVAL": "Benachrichtigungsintervall",
        "ROUTE_TRACKING.SAVE": "Speichern Routenplan",
        "ROUTE_TRACKING.GENERAL_INFO": "Allgemeine Informationen",
        "ROUTE_TRACKING.LATEST_ADRESS": "Jetzt in Adresse gefunden",
        "ROUTE_TRACKING.ALERT_COORDINATE": "Bitte geben Sie die Start- und Zielposition ein.",
        "ROUTE_TRACKING.ALERT_DEVICE": "Bitte wählen Sie ein Fahrzeug aus",
        "ROUTE_TRACKING.ALERT_EMAIL": "Bitte geben Sie mindestens eine E-Mail-Adresse ein!",
        "ROUTE_TRACKING.ALERT_INTERVAL": "Bitte geben Sie den Intervallzyklus für die Benachrichtigungen an",
        "ROUTE_TRACKING.NOTIFICATION_TIME": "Benachrichtigungen über Zeit",
        "ROUTE_TRACKING.NOTIFICATION_ADRESS": "Benachrichtigungsadresse",
        "ROUTE_TRACKING.DONE_INFO_1": "Es wurde am",
        "ROUTE_TRACKING.DONE_INFO_2": "abgeschlossen",
        "ROUTE_TRACKING.DELETE_SURE": "Sind Sie sicher, dass Sie löschen möchten?",
        "ROUTE_TRACKING.TITLE": "Geplante Streckenanzeige",
        "ROUTE_TRACKING.NOTIFICATION_REPORTS": "Benachrichtigung Berichte",
        "ROUTE_TRACKING.DEFINED_START": "Definierte Startposition",
        "ROUTE_TRACKING.DEFINED_STOP": "Definierte Zielposition",
        "AIR_ALARM_SETTINGS.AIR_ALARM": "Alarmeinstellungen",
        "AIR_ALARM_SETTINGS.SOUND_SETTINGS": "Lautstärke",
        "AIR_ALARM_SETTINGS.SOUND_SETTINGS_INFO": "Die Lautstärke bestimmt, wie viele Sekunden die Sirene ertönen soll, wenn der Alarm ausgelöst wird.",
        "AIR_ALARM_SETTINGS.SENSITIVITY_SETTINGS": "Empfindlichkeitseinstellung",
        "AIR_ALARM_SETTINGS.SENSITIVITY_SETTINGS_INFO": "Die Empfindlichkeitseinstellung kann sich abhängig von der Umgebung und dem Fahrzeug, in dem Sie sich befinden, variieren. Die empfohlene Einstellung ist 1300. Werte unter dieser Angabe können dazu führen, dass der Alarm automatisch aktiviert wird.",
        "CHAT.IS_TYPING": " Schreibend...",
        "CHAT.CHAT_INPUT_BOX": "Geben Sie Ihre Nachricht...",
        "CHAT.CHAT_PROGRESS": "Chat mit ",
        "CHAT.CHAT_QUICK_REPLY_YES": "Ja",
        "CHAT.CHAT_QUICK_REPLY_NO": "Nein",
        "CHAT.CHAT_QUICK_REPLY_OK": "OK",
        "CHAT.CHAT_NOT_DELIVERED": "Nachricht nicht versendet",
        "CHAT.DRIVER_LIST_HEADER": "Fahrerliste",
        "CHAT.USER_LIST_HEADER": "Benutzerliste",
        "CHAT.SUBUSER_LIST_HEADER": "Unterbenutzer Liste",
        "CHAT.TOPUSER_LIST_HEADER" : "Top User List",
        "CHAT.TOPUSER_NOT_FOUND": "No top user found!",
        "CHAT.USER_NOT_FOUND": "Keine Benutzer gefunden!",
        "CHAT.DRIVER_NOT_FOUND": "Keine Fahrer gefunden!",
        "CHAT.SUBUSER_NOT_FOUND": "No sub user found!",
        "CHAT.DRIVING_TYPE_MODAL_TITLE" : "Fahrertype auswählen",
        "CHAT.DRIVING_TYPE_MODAL_CLOSE" : "Schliessen",
        "CHAT.DRIVING_TYPE_PRIVATE": "Privat",
        "CHAT.DRIVING_TYPE_PUBLIC" : "Dienst",
        "CHAT.DRIVING_TYPE_POPUP_TITLE" : "Fahrtauswahl fehlerhaft",
        "CHAT.DRIVING_TYPE_ERROR" : "Fahrtauswahl kann nicht gespeichert werden!",
        "WEB_INTEGRATION.TITLE": "Web Einbindung in Ihre Webseite",
        "WEB_INTEGRATION.CONTENT1": "Bitte klicken Sie den unten stehenden Link um den Quellcode für die Web Einbindung zu erzeugen. Mit diesem können Sie oder Ihr Webdesigner dann Ihre Fahrzeugansicht in Ihre eigene Webseite einbinden und nutzen.",
        "BEACON.DEVICE_NAME": "Gerätename",
        "BEACON.LAST_SIGNAL_TIME": "Letzte Signalzeit ",
        "BEACON.SIGNAL_STRENGTH": "Signalstärke",
        "BEACON.CONNECTION_STATUS": "Verbindungsstatus",
        "BEACON.DEVICES": "Beacon Geräte",
        "BEACON.PERFECT": "Perfekt",
        "BEACON.VERY_GOOD": "Sehr Stark",
        "BEACON.GOOD": "Stark",
        "BEACON.BAD": "Schwach",
        "BEACON.VERY_BAD": "Sehr Schwach",
        "DATA_DELETE.DATA_TO_BE_DELETED": "Zu Löschende Daten",
        "DATA_DELETE.DEVICES_TO_BE_DELETED": "Zur Löschung freigegebene Geräte",
        "DATA_DELETE.DATA_DELETING": "Daten werden gelöscht",
        "DATA_DELETE.DELETED_DEVICES": "Gelöschte Geräte",
        "DATA_DELETE.CREATE_DATE": "Erstellungsdatum",
        "DATA_DELETE.DELETE_DATE": "Löschdatum",
        "DATA_DELETE.CONFIRMATION_CODE": "Bestätigungscode",
        "DATA_DELETE.TYPE_CONFIRMATION_CODE": "Bitte geben Sie Ihren Bestätigungscode ein",
        "DATA_DELETE.CONFIRMATION": "Bestätigung"
    }
};

export default langDe;