import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import StandartList from './StandartList';
import KleinList from './KleinList';
import DetailertList from './DetailertList';
import MiniList from './MiniList';
import StandartBigList from './StandartBigList';
import GroupName from './GroupName';
import './index.css';

import {
  updateDeviceList
} from '../../redux/actions';

let ws = null;
let timeout = 250;
let bluredDevices = [];

function DeviceList(props) {

  const [ref, setRef] = useState([]);

  const refs = props.devices.reduce((acc, value) => {
    acc[value.device_id] = React.createRef();
    return acc;
  }, {});

  const wsCheck = () => {
    if (!ws || ws.readyState == WebSocket.CLOSED) wsConnect();
  };

  const wsConnect = () => {
    let token = "";
    if (sessionStorage.getItem('access_token')) {
      token = decodeURIComponent(escape(window.atob(sessionStorage.getItem('access_token'))));
    }

    ws = new WebSocket("wss://www.gps-takip-sistemi.com/ws?token=" + token);
    var connectInterval;

    ws.onopen = () => {
      timeout = 250;
      clearTimeout(connectInterval);
    };

    ws.onerror = () => {
      ws.close();
      timeout += timeout;
      connectInterval = setTimeout(wsCheck, Math.min(10000, timeout));
    };
  };

  useEffect(() => {
    wsConnect();
    setRef(refs);

    return () => {
      if (ws) {
        ws.close();
      }
    }
  }, []);

  useEffect(() => {
    if (ws !== null) {
      ws.onmessage = evt => {
        const socketData = JSON.parse(evt.data);

        if ('device_id' in socketData) {
          let devices = [];
          props.devices.forEach(device => {
            if (device.device_id == socketData.device_id) {
              device.speed_km = socketData.speed_km;
              device.dec_lat = socketData.dec_lat;
              device.dec_long = socketData.dec_long;
              device.latest_geocode_sent_at = socketData.geocode_sent_at;
              device.ignition_status = (socketData.signal_mode == 3 || socketData.signal_mode == 0) ? "on" : "off";
              device.daily_mileage = socketData.current_kilometers.toFixed(2);
              device.blink = true;
              device.changed = true;
              devices.push(device);
            } else {
              device.blink = false;
              devices.push(device);
            }
          });
          props.updateDeviceList({devices: devices, totalCount: props.totalCount});
        }
      }
    }
  }, [props.devices]);

  const opacityOfDevices = (deviceGroup) => {
    bluredDevices = [];
    for (var i = 0; i < deviceGroup.length; i++) {
      for (var j = 0; j < props.contents.length; j++) {
        if (props.listType === 'standart' && props.contents[j].device_id === deviceGroup[i].device_id) {
          bluredDevices.push(<StandartList scroll_ref={ref} data={deviceGroup[i]}
                                           blur={props.contents[j].device_id === deviceGroup[i].device_id ? props.contents[j].opacity_check : null}
                                           key={deviceGroup[i].device_id}/>)
        }
        if (props.listType === 'klein' && props.contents[j].device_id === deviceGroup[i].device_id) {
          bluredDevices.push(<KleinList scroll_ref={ref} data={deviceGroup[i]}
                                        blur={props.contents[j].device_id === deviceGroup[i].device_id ? props.contents[j].opacity_check : null}
                                        key={deviceGroup[i].device_id}/>)
        }
        if (props.listType === 'detailed' && props.contents[j].device_id === deviceGroup[i].device_id) {
          bluredDevices.push(<DetailertList scroll_ref={ref} data={deviceGroup[i]}
                                            blur={props.contents[j].device_id === deviceGroup[i].device_id ? props.contents[j].opacity_check : null}
                                            key={deviceGroup[i].device_id}/>)
        }
        if (props.listType === 'mini' && props.contents[j].device_id === deviceGroup[i].device_id) {
          bluredDevices.push(<MiniList scroll_ref={ref} data={deviceGroup[i]}
                                       blur={props.contents[j].device_id === deviceGroup[i].device_id ? props.contents[j].opacity_check : null}
                                       key={deviceGroup[i].device_id}/>)
        }
        if (props.listType === 'standart-big' && props.contents[j].device_id === deviceGroup[i].device_id) {
          bluredDevices.push(<StandartBigList scroll_ref={ref} data={deviceGroup[i]}
                                              blur={props.contents[j].device_id === deviceGroup[i].device_id ? props.contents[j].opacity_check : null}
                                              key={deviceGroup[i].device_id}/>)
        }
      }
    }
    return bluredDevices;
  };

  return (
    <div className="device-list-box">
      {
        props.listType !== 'big' ? 
          (
            props.devicesWithGroups.map((deviceGroup, index) => {
              return (
                (deviceGroup[1].length < 1) ? (null) :
                  <div key={index}>
                    <GroupName groupName={deviceGroup[0]} listType={props.listType}/>
                    {
                      props.contents.length !== 0 ?
                        opacityOfDevices(deviceGroup[1])
                        :
                        deviceGroup[1].map((device) => {
                          if (props.listType === 'standart') return <StandartList scroll_ref={ref} data={device} blur={null} key={device.device_id}/>;
                          if (props.listType === 'klein') return <KleinList scroll_ref={ref} data={device} blur={null} key={device.device_id}/>;
                          if (props.listType === 'detailed') return <DetailertList scroll_ref={ref} data={device} blur={null} key={device.device_id}/>;
                          if (props.listType === 'mini') return <MiniList scroll_ref={ref} data={device} blur={null} key={device.device_id}/>;
                          if (props.listType === 'standart-big') return <StandartBigList scroll_ref={ref} data={device} blur={null} key={device.device_id}/>;
                        })
                    }
                  </div>
              )
            })
          ) : <Redirect to={'/big-list'}/>
      }
    </div>
  );

}

const mapStateToProps = function (state) {
  return {
    devices: state.deviceListReducer.devices,
    devicesWithGroups: state.deviceListWithGroupsReducer.devicesWithGroups,
    totalCount: state.deviceListReducer.totalCount,
    contents: state.deviceSearchContent.contents
  }
};

const mapDispatchToProps = {
  updateDeviceList
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceList);
