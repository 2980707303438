import BaseService from './BaseService';

class Export extends BaseService {

	exportDailyReport(deviceId, start, end, trips, fileExtension) {
		const _trips = trips.map((json) => JSON.stringify(json));
		const data = {start: start, end: end, data: _trips, export_type: fileExtension};
		return this.axiosInstance.post(`/export/daily_report/device/${deviceId}/?`, data, { responseType: 'blob'});
	}

	exportMonthlyReport(deviceId, start, end, trips, fileExtension) {
		const _trips = trips.map((json) => JSON.stringify(json));
		const data = {start: start, end: end, data: _trips, export_type: fileExtension};
		return this.axiosInstance.post(`/export/monthly_report/device/${deviceId}/?`, data, { responseType: 'blob'});
	}

	exportRouteReport(deviceId, start, end, trips, fileExtension) {
		const _trips = trips.map((json) => JSON.stringify(json));
		const data = {start: start, end: end, data: _trips, export_type: fileExtension};
		return this.axiosInstance.post(`/export/route_report/device/${deviceId}/?`, data, { responseType: 'blob'});
	}

	exportYearlyReport(deviceId, start, end, trips, fileExtension) {
		const _trips = trips.map((json) => JSON.stringify(json));
		const data = {start: start, end: end, data: _trips, export_type: fileExtension};
		return this.axiosInstance.post(`/export/yearly_report/device/${deviceId}/?`, data, { responseType: 'blob'});
	}

	exportSignalReport(deviceId, start, end, signals, fileExtension) {
		const _signals = signals.map((json) => JSON.stringify(json));
		const data = {start: start, end: end, data: _signals, export_type: fileExtension};
		return this.axiosInstance.post(`/export/signal_report/device/${deviceId}/?`, data, { responseType: 'blob'});
	}

	exportSpeedReport(deviceId, start, end, speeds, fileExtension) {
		const _speeds = speeds.map((json) => JSON.stringify(json));
		const data = {start: start, end: end, data: _speeds, export_type: fileExtension};
		return this.axiosInstance.post(`/export/speed_report/device/${deviceId}/?`, data, { responseType: 'blob'});
	}

	exportDriverDailyReport(driverId, start, end, trips, fileExtension) {
		const _trips = trips.map((json) => JSON.stringify(json));
		const data = {start: start, end: end, data: _trips, export_type: fileExtension};
		return this.axiosInstance.post(`/export/daily_report/driver/${driverId}/?`, data, { responseType: 'blob'});
	}

	exportDriverMonthlyReport(driverId, start, end, trips, fileExtension) {
		const _trips = trips.map((json) => JSON.stringify(json));
		const data = {start: start, end: end, data: _trips, export_type: fileExtension};
		return this.axiosInstance.post(`/export/monthly_report/driver/${driverId}/?`, data, { responseType: 'blob'});
	}

}

export default Export;