import BaseService from './BaseService';

class FuelService extends BaseService {

  get(deviceId) {
    return this.axiosInstance.get(`/fuel_sensor/${deviceId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }
  
  getReports(deviceId, startDate, endDate) {
    return this.axiosInstance.get(`/fuel_sensor/reports/${deviceId}/
    ?start_date=${startDate}&end_date=${endDate}&nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }

  getReportDetail(deviceId, startDate, endDate) {
    return this.axiosInstance.get(`/fuel_sensor/report_details/${deviceId}/
    ?start_date=${startDate}&end_date=${endDate}&nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }

}

export default FuelService;