import BaseService from './BaseService';

class GeofencePoints extends BaseService {

	getGeofencePoints() {
		return this.axiosInstance.get(`/geofence_points/?`, {cancelToken: this.cancelTokenSource.token});
	}

	createGeofencePoint(point) {
		return this.axiosInstance.post(`/geofence_points/create/?`, {data: JSON.stringify(point)});
	}

	updateGeofencePoints(geofencePoints) {
		return this.axiosInstance.post(`/geofence_points/update/?`, {data: JSON.stringify(geofencePoints)});
	}

	deleteGeofencePoints(pointId) {
		return this.axiosInstance.post(`/geofence_points/delete/${pointId}/?`);
	}

}

export default GeofencePoints;