import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {FormattedMessage} from 'react-intl';
import MessageWindow from "../../Commons/messageWindow";
import '../index.css';
import Menu from '../Menu';
import Loading from '../../Commons/loading';
import DriverService from '../../../services/Drivers';
import Moment from 'moment';

let ibuttonTypes = [
    {label: 'GENERAL.PASSIVE', value: 0},
    {label: 'GENERAL.PRIVATE', value: 1},
    {label: 'GENERAL.SERVICE', value: 2}
];

let GLOBALS = {
    work_hours_toggle: [
        {'value': "-1", 'label': 'Seçiniz'},
        {'value': "0531", 'label': '0531'},
        {'value': "0532", 'label': '0532'},
        {'value': "0533", 'label': '0533'},
        {'value': "0534", 'label': '0534'},
        {'value': "0535", 'label': '0535'},
        {'value': "0536", 'label': '0536'},
        {'value': "0537", 'label': '0537'},
        {'value': "0538", 'label': '0538'},
        {'value': "0539", 'label': '0539'},
        {'value': "0541", 'label': '0541'},
        {'value': "0542", 'label': '0542'},
        {'value': "0543", 'label': '0543'},
        {'value': "0544", 'label': '0544'},
        {'value': "0545", 'label': '0545'},
        {'value': "0546", 'label': '0546'},
        {'value': "0505", 'label': '0505'},
        {'value': "0506", 'label': '0506'},
        {'value': "0507", 'label': '0507'},
        {'value': "0551", 'label': '0551'},
        {'value': "0552", 'label': '0552'},
        {'value': "0553", 'label': '0553'},
        {'value': "0554", 'label': '0554'},
        {'value': "0555", 'label': '0555'},
        {'value': "0556", 'label': '0556'},
        {'value': "0557", 'label': '0557'},
        {'value': "0558", 'label': '0558'},
        {'value': "0559", 'label': '0559'}
    ]
};

function DriverList(props) {

    const deviceId = props.match.params.deviceId;
    const driverId = props.match.params.driverId;

    const [drivers, setDrivers] = useState([]);
    const [ibuttons, setIButtons] = useState([]);
    const [favoriteDriver, setFavoriteDriver] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [deleteDriver_, setDeleteDriver] = useState(false);
    const [createEditForm, setCreateEditForm] = useState(false);
    const [createEditWord, setCreateEditWord] = useState('');
    const [driver, setDriver] = useState({});
    const [driverDevices, setDriverDevices] = useState([]);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [phone_area, setPhoneArea] = useState(undefined);
    const [phone_number, setPhoneNumber] = useState(undefined);
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [post_code, setPostCode] = useState("");
    const [city, setCity] = useState("");
    const [max_overtime, setMaxOvertime] = useState(0);
    const [driverID, setDriverID] = useState(0);
    const [work_hours_toggle, setWorkHoursToggle] = useState(false);
    const [license_toggle, setLicenseToggle] = useState(false);
    const [license_control, setLicenseControl] = useState(0);
    const [mobile_app_status, setMobileAppStatus] = useState(1);
    const [hour_drive, setHourDrive] = useState(0);
    const [always_drive, setAlwaysDrive] = useState(0);
    const [activation, setActivation] = useState('');
    const [user_pass, setUserPass] = useState('');
    const [alert, setAlert] = useState(false);
    const [workingStartTime, setWorkingStartTime] = useState(new Date(1970,1,1,0,0,0));
    const [workingEndTime, setWorkingEndTime] = useState(new Date(1970,1,1,0,0,0));
    const [licenseValidityDate, setLicenseValidityDate] = useState(new Date());
    const [old_driver_phone_area, setOldDriverPhoneArea] = useState("");
    const [old_driver_phone_number, setOldDriverPhoneNumber] = useState("");
    const [showMessage, setShowMessage] = useState({
        message: '',
        title: '',
        status: false
      });
    


    const driverService = new DriverService();

    const handleFilter = async () => {
        await Promise.all([
			driverService.getDrivers(),
            driverService.getUserIButtons(),
            driverService.getFavoriteDriver(deviceId),
		])
			.then(([driversResponse, userIButtonsResponse, favoriteDriverResponse]) => {
                setDriversData(driversResponse.data,favoriteDriverResponse.data);
                setIButtonsData(driversResponse.data,userIButtonsResponse.data);
                setFavoriteDriver(favoriteDriverResponse.data);
                setLoading(false);
			});

         //unmount
        return () => {
            driverService.getCancelToken().cancel();
        }
        
    };

    const setDriversData = (drivers,favoriteDriver) => {

        var working_start, working_end;

        for (let i = 0; i < drivers.length; i++) {
            working_start = new Date();
            working_start.setHours(drivers[i]['working_start_hour']);
            working_start.setMinutes(drivers[i]['working_start_minute']);
            drivers[i]['working_start'] = working_start;

            working_end = new Date();
            working_end.setHours(drivers[i]['working_end_hour']);
            working_end.setMinutes(drivers[i]['working_end_minute']);
            drivers[i]['working_end'] = working_end;

            if (drivers[i]['license_validity'] == "1970/01/01") {
                drivers[i]['license_validity_date'] = Moment(new Date()).format("YYYY/MM/DD");
            } else {
                drivers[i]['license_validity_date'] = Moment(new Date(drivers[i]['license_validity'])).format("YYYY/MM/DD");
            }

            // set favorite column
            drivers[i]['favorite_driver'] = false; // reset favorite
            if (drivers[i]['driver_id'] == favoriteDriver['driver_id']) {
                drivers[i]['favorite_driver'] = true;
            } else {
                drivers[i]['favorite_driver'] = false;
            }

            if (drivers[i]['driver_notification'] == "1") {
                drivers[i]['driver_notification'] = true;
            } else {
                drivers[i]['driver_notification'] = false;
            }

            if (drivers[i]['continuous_active'] == "1") {
                drivers[i]['always_drive'] = true;
                drivers[i]['hour_drive'] = false;
                setAlwaysDrive(true);
                setHourDrive(false);
            } else {
                drivers[i]['always_drive'] = false;
                drivers[i]['hour_drive'] = true;
                setAlwaysDrive(false);
                setHourDrive(true);
            }

            drivers[i]["driverphone"] = "";
            if (drivers[i]['driver_phone_area'] != "-1" && drivers[i]['driver_phone_area']) {
                drivers[i]["driverphone"] = drivers[i]['driver_phone_area'] + "" + drivers[i]['driver_phone_number'];
            }
        }
        setDrivers(drivers);
    };

    const setIButtonsData = (drivers,ibuttons) => {

        for (var i = 0; i < ibuttons.length; i++) {
            // set driver
            if (ibuttons[i]['driver_id'] > 0) {
                for (var j = 0; j < drivers.length; j++) {
                    if (drivers[j]['driver_id'] == ibuttons[i]['driver_id']) {
                        ibuttons[i]['driver'] = drivers[j];
                        ibuttons[i]['update'] = true;
                    }
                }
            } else ibuttons[i]['driver'] = drivers[0];

            // set button type
            ibuttons[i]['type'] = ibuttonTypes[ibuttons[i]['trip_typ']];
        }
        setIButtons(ibuttons);
    };

    const handleIButton = (event, index) => {
        ibuttons[index][event.target.name] = event.target.value;
		setIButtons([...ibuttons]);
    }; 

    // I-Button'a sürücü ataması yapar.
    const updateIButton = async (ibutton) => {
        let i_btn_id, i_btn_number, trip_typ, driver_id;

        i_btn_id = ibutton.i_btn_id;
        i_btn_number = ibutton.i_btn_number;
        trip_typ = ibutton.type.value === undefined ? ibutton.type : ibutton.type.value;
        driver_id = ibutton.driver_id;
        setLoading(true);
        try {
            await driverService.updateIButton(i_btn_id, i_btn_number, trip_typ, driver_id)
              .then(async(response) => {
                if (response.data === "success") {
                    await driverService.getUserIButtons().then(async(resp) => {
                        setIButtonsData(drivers,resp.data);
                        setLoading(false);
                    });
                }
                else{
                    alert("updateIButton error")
                }
              });
            } catch (error) {
        }
    }

    const updateFavoriteDriver = async (driver_id) => {
        setLoading(true);
        try {
            await driverService.updateFavoriteDriver(deviceId,driver_id)
              .then(async(response) => {
                if (response) {
                    setFavoriteDriver(response.data);
                    setDriversData(drivers,response.data);
                    setLoading(false);
                }
              });
            } catch (error) {
        }
    }

    const deleteDriver = async (driver_id) => {
        try {
            await driverService.deleteDriver(driver_id)
              .then(async(response) => {
                  handleFilter();
                  setDeleteDriver(false);
              });
            } catch (error) {
        }
    }

    const deleteFavoriteDriver = async (deviceId) => {
        try {
            await driverService.deleteFavoriteDriver(deviceId)
              .then(async(response) => {
                  handleFilter();
              });
            } catch (error) {
        }
    }

    const handleDeleteDriver = (id) => {
        setDriverID(id);
        setDeleteDriver(true);
    }

    const handleCreateDriver = () => {
        createNewDriver();
        setCreateEditWord("create");
        setCreateEditForm(true);
    }

    const handleEditDriver = async (driver) => {
        setName(driver.driver_first_name);
        setSurname(driver.driver_last_name);
        setEmail(driver.driver_email);
        setAddress(driver.driver_address);
        setPostCode(driver.driver_plz);
        setCity(driver.driver_ort);
        setPhoneArea(driver.driver_phone_area);
        setPhoneNumber(driver.driver_phone_number);
        setLicenseControl(driver.license_control);
        setLicenseValidityDate(new Date(driver.license_validity));
        setWorkingStartTime(new Date(driver.working_start));
        setWorkingEndTime(new Date(driver.working_end));
        setMaxOvertime(driver.max_overtime);
        setMobileAppStatus(driver.mobile_app_status);
        setAlwaysDrive(driver.continuous_active);
        setOldDriverPhoneArea(driver.driver_phone_area);
        setOldDriverPhoneNumber(driver.driver_phone_number);
        setUserPass(driver.user_pass);
        setAlert(false);
       
        if (parseInt(driver.working_start_hour) === 0 &&
            parseInt(driver.working_start_minute) === 0 &&
            parseInt(driver.working_end_hour) === 0 &&
            parseInt(driver.working_end_minute) === 0 &&
            parseInt(driver.max_overtime) === 0) {
            setWorkHoursToggle(false);
        } else {
            setWorkHoursToggle(true);
        }

        if (parseInt(driver.license_control) === 0) {
            setLicenseToggle(false);
        } else 
        {
            setLicenseToggle(true);
        }

        try {
            await driverService.driver_mobile_all_devices(driver.driver_id)
              .then(async(response) => {
                  setDriverDevices(response.data);
              });
            } catch (error) {
        }

        setDriver(driver);
        setCreateEditWord("edit");
        setCreateEditForm(true);
    }

    const createNewDriver = async () => {

        setWorkHoursToggle(false);
        setLicenseToggle(false);

        try {
            await driverService.driver_mobile_all_devices(0)
              .then(async(response) => {
                  setDriverDevices(response.data);
              });
            } catch (error) {
        }
    };

    const insertDriver = async () => {
        let qs = [];
        let arr = [];
        let data={};

        if (name === "" || surname === "") {
            setShowMessage({
                title: <h5><FormattedMessage id="GENERAL.WARNING"/></h5>,
                message: <h5><FormattedMessage id="ALERT.FILL_THE_BLANKS"/></h5>,
                status: true
              })
        } else {
            data.driver_first_name = name;
            data.driver_last_name = surname;
            data.driver_email = email;
            data.driver_address = address;
            data.driver_plz = post_code;
            data.driver_ort = city;
            data.driver_phone_area = phone_area;
            data.driver_phone_number = phone_number;
            data.license_control = (license_toggle) ? parseInt(license_control) : 0;
            data.working_start_hour = (work_hours_toggle) ? workingStartTime.getHours() : 0;
            data.working_start_minute = (work_hours_toggle) ? workingStartTime.getMinutes() : 0;
            data.working_end_hour = (work_hours_toggle) ? workingEndTime.getHours() : 0;
            data.working_end_minute = (work_hours_toggle) ? workingEndTime.getMinutes() : 0;
            data.max_overtime = (work_hours_toggle) ? max_overtime : 0;
            data.driver_notification = (driver.driver_notification) ? 1 : 0;
            data.mobile_app_status = mobile_app_status;
            data.continuous_active = always_drive ? 1 : 0;
            data.user_pass = user_pass;
            data.device_id = parseInt(deviceId);
    
            if (parseInt(data.license_control) === 3) {
                data.license_validity = Moment(new Date(licenseValidityDate)).format('YYYY-MM-DD');
            } else {
                data.license_validity = "1970-01-01";
            }

            setDriver(data);

            for (var i = 0; i <= driverDevices.length - 1; i++) {
                if (driverDevices[i].checked) {
                    arr.push(driverDevices[i].device_id);
                }
            }
            qs = arr.toString();
            try {
                await driverService.getDriverByPhoneNumber(phone_area, phone_number)
                    .then(async(driverResp) => {
                        if (driverResp.data.results === "fail") {
                            await driverService.insertDriver(data).then(async(resp) => {
                                if (resp.data.result = 'success') {
                                    await driverService.update_devices(resp.data.insert_id, qs).then(async(response) => {
                                        if (response.data.result == "success") {
                                            driver["driver_id"]= resp.data.insert_id;
                                            setAlert(true);
                                            handleFilter();
                                        } else {
                                            alert('error');
                                        }
                                    });
                                } else {
                                    alert('errorrr');
                                }
                            });
                        }
                        else {
                            alert('errorrrr');
                        }
                    });
            } catch (error) {
            }
        }
    }

    const updateDriverFunction = async (driver) => {
        let data = {}, arr = [], qs;
        
        data.driver_first_name = name;
        data.driver_last_name = surname;
        data.driver_email = email;
        data.driver_address = address;
        data.driver_plz = post_code;
        data.driver_ort = city;
        data.driver_phone_area = phone_area;
        data.driver_phone_number = phone_number;
        data.license_control = (license_toggle) ? parseInt(license_control) : 0;
        data.license_validity = Moment(new Date(licenseValidityDate)).format('YYYY-MM-DD');
        data.working_start_hour = (work_hours_toggle) ? workingStartTime.getHours() : 0;
        data.working_start_minute = (work_hours_toggle) ? workingStartTime.getMinutes() : 0;
        data.working_end_hour = (work_hours_toggle) ? workingEndTime.getHours() : 0;
        data.working_end_minute = (work_hours_toggle) ? workingEndTime.getMinutes() : 0;
        data.max_overtime = (work_hours_toggle) ? max_overtime : 0;
        data.driver_notification = (driver.driver_notification) ? 1 : 0;
        data.mobile_app_status = mobile_app_status;
        data.continuous_active = always_drive ? 1 : 0;
        data.user_pass = user_pass;
        data["device_id"] = parseInt(deviceId);

        if (parseInt(data.license_control) === 3) {
            data.license_validity = Moment(new Date(licenseValidityDate)).format('YYYY-MM-DD');
        } else {
            data.license_validity = "1970-01-01";
        }

        for (var i = 0; i <= driverDevices.length - 1; i++) {
            if (driverDevices[i].checked) {
                arr.push(driverDevices[i].device_id);
            }
        }
        qs = arr.toString();

        if (old_driver_phone_area !== phone_area || old_driver_phone_number !== phone_number) {
            if (phone_area !== "" || phone_number !== "") {
                await driverService.getDriverByPhoneNumber(phone_area, phone_number)
                    .then(async(response) => {
                        if (response.data.result = 'fail') {
                            updateDriver(data, driver, qs);
                        }
                        else {
                            setPhoneArea(old_driver_phone_area);
                            setPhoneNumber(old_driver_phone_number);
                            alert("error");
                            setCreateEditForm(false);
                        }
                    });
            }
            else {
                updateDriver(data, driver, qs);
            }
        } else {
            updateDriver(data, driver, qs);
        }
    };

    const updateDriver = async (query_string, driver, qs) => {
        try {
            await driverService.updateDriver(driver.driver_id, query_string)
                .then(async(driverResp) => {
                    if (driverResp.data === "success") {
                        await driverService.update_devices(driver.driver_id, qs).then(async(response) => {
                            if (response.data.result = 'success') {
                                setAlert(true);
                                handleFilter();
                            } else {
                                alert('errorrr');
                            }
                        });
                    }
                    else {
                        alert('errorrrr');
                    }
                });
        } catch (error) {
        }
    }

    const back = () => {
        setCreateEditForm(false);
    }

    const classActivation = (value) => {
        setLicenseControl(value);
        if(parseInt(value) !== 3){
            setActivation('deactive');
        }else{
            setActivation('');
        }
    }

    const handleChange = (event,index) => {
        driverDevices[index]["checked"] = event.target.checked ? 1 : 0;
        setDriverDevices([...driverDevices]);
    }

    const drive_change = (value, type) => {
        if (type === "hour") {
            if (value === "1") {
                driver["always_drive"] = 0;
                setAlwaysDrive(false);
                setHourDrive(true);
            }
            else if (value === "0" || value === false) {
                driver["always_drive"] = 1;
                setAlwaysDrive(true);
                setHourDrive(false);
            }
        }
        if (type === "always") {
            if (value === "1" || value === true) {
                driver["hour_drive"] = 0;
                setHourDrive(false);
                setAlwaysDrive(true);
            }
            else if (value === "0" || value === false) {
                driver["hour_drive"] = 1;
                setHourDrive(true);
                setAlwaysDrive(false);
            }
        }
        setDriver(driver);
    };

    return (
        <div className="reports">
            <div className="drivers-list">
                {isLoading ? (<Loading/>) : null}
                {
                    !createEditForm ? 
                    <div className="drivers-body">
                    <Menu history={props.history} deviceId={deviceId} driverId={driverId} onFilter={handleFilter} routerParams={props.match} mainReportType = {'drivers'}/>
                    <div>
                        <div className="drivers-table table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th width="100"><FormattedMessage id="DRIVERS.DRIVER_NO"/></th>
                                        <th><FormattedMessage id="GENERAL.NAME"/></th>
                                        <th><FormattedMessage id="GENERAL.SURNAME"/></th>
                                        <th><FormattedMessage id="DRIVERS.DRIVER_TEL"/></th>
                                        <th><FormattedMessage id="GENERAL.MAIL"/></th>
                                        <th><FormattedMessage id="DRIVERS.LICENSE_CONTROL"/></th>
                                        <th><FormattedMessage id="DRIVERS.FAV_DRIVER"/></th>
                                        <th width="80"></th>
                                        <th width="50"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {   drivers.map((driver, index) => {
                                                return  <tr key={'driver-' + index} id={'driver-' + index}>
                                                            <td>{driver.driver_id}</td>
                                                            <td>{driver.driver_first_name}</td>
                                                            <td>{driver.driver_last_name}</td>
                                                            <td>{driver.driver_phone_area} {driver.driver_phone_number}</td>
                                                            <td>{driver.driver_email}</td>
                                                            <td>{driver.license_validity_date}</td>
                                                            <td>
                                                                {
                                                                    driver.favorite_driver ?    
                                                                    null : 
                                                                    <button className="btn btn-info btn-sm" onClick={() => updateFavoriteDriver(driver.driver_id)}>
                                                                        <FormattedMessage id="GENERAL.SELECT"/>
                                                                    </button>

                                                                }
                                                                {
                                                                    driver.favorite_driver ?    
                                                                    <span onClick={() => deleteFavoriteDriver(deviceId)}> 
                                                                        <FormattedMessage id="GENERAL.SELECTED"/> &nbsp;
                                                                        <FormattedMessage id="DRIVERS.UNSELECT_DRIVER">
                                                                        {
                                                                            value => (
                                                                                <span className="glyphicon glyphicon-remove glybutton" title={value} onClick={() => deleteFavoriteDriver(deviceId)}></span>
                                                                            )
                                                                        }
                                                                        </FormattedMessage>
                                                                    </span> : null

                                                                }
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-warning btn-sm" onClick={() => handleEditDriver(driver)}>
                                                                    <FormattedMessage id="GENERAL.EDIT"/>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteDriver(driver.driver_id)}>
                                                                    <FormattedMessage id="GENERAL.DELETE"/>
                                                                </button>
                                                            </td> 
                                                        </tr>
                                            })
                                        }
                                        <tr>
                                            <td colSpan="7">
                                                <span><b>{favoriteDriver.device_name}</b> <FormattedMessage id="DRIVERS.HAS_FAV_DRIVER"/><b> {favoriteDriver.driver_first_name} {favoriteDriver.driver_last_name}</b></span>
                                                <span>. <FormattedMessage id="DRIVERS.HAS_NO_FAV_DRIVER"/>.</span>
                                            </td>
                                            <td colSpan="2">
                                                <button className="btn btn-success btn-sm btn-block" onClick={() => handleCreateDriver()}>
                                                    <FormattedMessage id="DRIVERS.NEW_DRIVER"/>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4><FormattedMessage id="DRIVERS.BUTTON_LIST"/></h4>
                            <hr/>
                            <div className="ibuttons-table table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th width="100"><FormattedMessage id="DRIVERS.BUTTON_NO"/></th>
                                            <th width="200"><FormattedMessage id="DRIVERS.ASSIGN_DRIVER"/></th>
                                            <th><FormattedMessage id="DRIVERS.BUTTON_SERIAL_NO"/></th>
                                            <th><FormattedMessage id="DRIVERS.BUTTON_TYPE"/></th>
                                            <th width="100"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        ibuttons.map((ibutton, index) => {
                                            return  <tr key={'ibutton-' + index} id={'ibutton-' + index}>
                                                        <td>{ibutton.key_id}</td>
                                                        <td>
                                                            <select className="form-control input-sm" value={parseInt(ibutton.driver_id)} name='driver_id' onChange={(event) => handleIButton(event, index)}>
                                                                <FormattedMessage id="GENERAL.PICK_DRIVER">
                                                                    {(message) => <option value=''>{message}</option>}
                                                                </FormattedMessage>
                                                                {
                                                                    drivers.map((driver, index) => {
                                                                        return (
                                                                            <option key={index} id={'driver-'+index} value={parseInt(driver.driver_id)}>
                                                                                {driver.driver_first_name} {driver.driver_last_name}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </td>
                                                        <td>{ibutton.i_btn_number}</td>
                                                        <td>
                                                            <select className="form-control input-sm" value={ibutton.type.value} name='type' onChange={(event) => handleIButton(event, index)}>
                                                            {
                                                                ibuttonTypes.map((ibuttonType, index) => {
                                                                    return (
                                                                        <FormattedMessage id={ibuttonType.label} key={ibuttonType.value}>
                                                                        {(message) => <option key={index} id={'ibuttonType-'+index} value={ibuttonType.value}>
                                                                                            {message}
                                                                                    </option>}
                                                                        </FormattedMessage>
                                                                    )
                                                                })
                                                            }
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <button className={ibutton.update ? 'btn btn-warning btn-sm btn-block' : 'btn btn-success btn-sm btn-block'} onClick={() => updateIButton(ibutton)}>
                                                                <FormattedMessage id="GENERAL.SAVE"/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                :
                <div className="driver-detail">
                <h4>
                    <a href=""><FormattedMessage id="MENU.DRIVERS_LIST"/></a> >&nbsp;
                    {
                        createEditWord === 'create' ? <span><FormattedMessage id="DRIVERS.CREATE_NEW_DRIVER"/></span>
                        :
                        null
                    }
                      {
                        createEditWord === 'edit' ? <span><FormattedMessage id="DRIVERS.UPDATE_DRIVER"/></span>
                        :
                        null
                    }
                </h4>
                <hr/>
                <div className="row">
                    <div className="col-md-6" style={{paddingLeft:0}}>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th colSpan="2"><FormattedMessage id="DRIVERS.GENERAL_INFO"/></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td width="150"><FormattedMessage id="GENERAL.NAME"/>*</td>
                                <td width="200"><input type="text" onChange={(event) => setName(event.target.value)} value={name} name='driver_first_name' className="form-control" required/></td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.SURNAME"/>*</td>
                                <td><input type="text" onChange={(event) => setSurname(event.target.value)} value={surname} name='driver_last_name' className="form-control" required/></td>
                            </tr>
                            <tr>
                                <td width="150"><FormattedMessage id="DRIVERS.DRIVER_TEL"/></td>
                                <td width="200">
                                    <select id="driver_phone_area" onChange={(event) => setPhoneArea(event.target.value)} name='driver_phone_area' className="form-control" value={phone_area} style={{width:100, float:'left'}}>
                                    {
                                        GLOBALS.work_hours_toggle.map((phone_area) => {
                                            return (
                                                <option key={phone_area.label} value={phone_area.value}>
                                                    {phone_area.label}
                                                </option>
                                            )
                                        })
                                    }
                                    </select>
                                    <input type="text" onChange={(event) => setPhoneNumber(event.target.value)} value={phone_number} name='driver_phone_number' className="form-control" style={{width:118, float:'left'}}/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.MAIL"/></td>
                                <td><input type="text" onChange={(event) => setEmail(event.target.value)} value={email} name='driver_email' className="form-control"/></td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.ADDRESS"/></td>
                                <td><input type="text" onChange={(event) => setAddress(event.target.value)} value={address} name='driver_address' className="form-control"/></td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="REPORTS.POST_CODE"/></td>
                                <td><input type="text" onChange={(event) => setPostCode(event.target.value)} value={post_code} name='driver_plz' className="form-control"/></td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="GENERAL.CITY"/></td>
                                <td><input type="text" onChange={(event) => setCity(event.target.value)} value={city} name='driver_ort' className="form-control"/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th colSpan="2">
                                    <div className="checkbox"><label><input type="checkbox" onChange={() => setWorkHoursToggle(!work_hours_toggle)} defaultChecked={work_hours_toggle} value={work_hours_toggle}/><b> <FormattedMessage id="DRIVERS.WORK_HOURS_CONTROL"/></b></label></div>
                                </th>
                            </tr>
                            </thead>
                            <tbody className={work_hours_toggle ? '' : 'deactive'}>
                            <tr>
                                <td><FormattedMessage id="DRIVERS.WORK_HOURS_START"/></td>
                                <td className="working-hours">
                                    <DatePicker
                                        name='working_start'
                                        value={workingStartTime}
                                        selected={workingStartTime}
                                        onChange={(date) => setWorkingStartTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="DRIVERS.WORK_HOURS_STOP"/></td>
                                <td className="working-hours">
                                        <DatePicker
                                            name='working_end'
                                            value={workingEndTime}
                                            selected={workingEndTime}
                                            onChange={(date) => setWorkingEndTime(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            timeFormat="HH:mm"/>
                                </td>
                            </tr>
                            <tr>
                                <td><FormattedMessage id="DRIVERS.MAX_OVERTIME"/><br/><br/></td>
                                <td><input type="number" onChange={(event) => setMaxOvertime(event.target.value)} value={max_overtime} name='max_overtime' className="form-control"/><br/></td>
                            </tr>
                            </tbody>
                        </table>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th colSpan="2">
                                        <div className="checkbox"><label><input type="checkbox" onChange={() => setLicenseToggle(!license_toggle)} defaultChecked={license_toggle} value={license_toggle}/><b> <FormattedMessage id="DRIVERS.LICENSE_CONTROL"/></b></label></div>
                                    </th>
                                </tr>
                                <tr></tr>
                            </thead>
                            <tbody className={license_toggle ? '' : 'deactive'}>
                                <tr>
                                    <td><FormattedMessage id="DRIVERS.INSPECTION_INTERVAL"/></td>
                                    <td>
                                        <label className="radio-inline"><input type="radio"  checked={parseInt(license_control) === 1} onChange={(event) => classActivation(event.target.value)} name='license_control' value={1}/> 7 <FormattedMessage id="GENERAL.DAY"/></label>&nbsp;
                                        <label className="radio-inline"><input type="radio"  checked={parseInt(license_control) === 2} onChange={(event) => classActivation(event.target.value)} name='license_control' value={2}/> 14 <FormattedMessage id="GENERAL.DAY"/></label>
                                    </td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="DRIVERS.SPECIFIED_DATE"/></td>
                                    <td>
                                        <div className="form-inline">
                                            <div className="form-group">
                                                <div className="radio"><label><input type="radio"  checked={parseInt(license_control) === 3} onChange={(event) => classActivation(event.target.value)} name='license_control' value={3}/> </label></div>
                                            </div>&nbsp;
                                            <div className="form-group">
                                                <DatePicker
                                                    className={activation}
                                                    name='license_validity_date'
                                                    value={licenseValidityDate}
                                                    onChange={(date) => setLicenseValidityDate(date)}
                                                    maxDate={new Date()}
                                                    dateFormat="yyyy/MM/dd"
                                                    selected={licenseValidityDate}/> 
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                        {
                            alert ? <p className="alert alert-success text-right"><FormattedMessage id="ALERT.UPDATE_SUCCESS"/></p> : null
                        }
                    </div>
                </div>
                <div className="clearfix"></div>
                <hr/>
                <div className="col-md-12" style={{borderWidth:1, border: 'solid', marginTop:0, marginRight:0, marginBottom:15, marginLeft:0, paddingTop:20, paddingRight:15, paddingBottom:15, paddingLeft:15  }}>
                    <div className="row">
                        <div className="col-md-7">
                            <h4><FormattedMessage id="DRIVERS.MOBILE_COMMUNICATION"/></h4>
                            <div className="row" style={{marginBottom:10}}>
                                <div className="col-md-7" style={{paddingTop:7}}><FormattedMessage id="DRIVERS.DRIVER_NO_AUTO"/></div>
                                <div className="col-md-5"><input type="text" className="form-control" disabled value={driver.driver_id}/></div>
                            </div>
                            <div className="row" style={{marginBottom:10}}>
                                <div className="col-md-7" style={{paddingTop:7}}><FormattedMessage id="GENERAL.PASSWORD"/></div>
                                <div className="col-md-5"><input type="text" onChange={(event) => setUserPass(event.target.value)}  className="form-control" value={user_pass}/></div>
                            </div>
                            <div className="row" style={{marginBottom:10}}>
                                <div className="col-md-7" style={{paddingTop:7}}><FormattedMessage id="DRIVERS.24_HOUR_DRIVER"/></div>
                                <div className="col-md-5">
                                    <input type="radio" onChange={(event) => drive_change(event.target.value, 'hour')} name='hour_drive' value={1} checked={hour_drive} /><FormattedMessage id="DRIVERS.ACTIVE"/>
                                    <input type="radio" onChange={(event) => drive_change(event.target.value, 'hour')} name='hour_drive' value={0} checked={!hour_drive} style={{marginLeft:50}}/><FormattedMessage id="DRIVERS.DISABLE"/>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom:10}}>
                                <div className="col-md-7" style={{paddingTop:7}}><FormattedMessage id="DRIVERS.ALWAYS_DRIVER"/></div>
                                <div className="col-md-5">
                                    <input type="radio" onChange={(event) => drive_change(event.target.value, 'always')} name='always_drive' value={1} checked={always_drive}/><FormattedMessage id="DRIVERS.ACTIVE"/>
                                    <input type="radio" onChange={(event) => drive_change(event.target.value, 'always')} name='always_drive' value={0} checked={!always_drive} style={{marginLeft:50}}/><FormattedMessage id="DRIVERS.DISABLE"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{textAlign:'right'}}>
                                    <button className="btn btn-warning" onClick={() => back()}><FormattedMessage id="GENERAL.TURN_BACK"/></button>&nbsp;
                                    {
                                        createEditWord == 'edit' ?
                                        <button className="btn btn-success" onClick={() => updateDriverFunction(driver)}><FormattedMessage id="GENERAL.SAVE"/></button>
                                        :
                                        <button className="btn btn-success" onClick={() => insertDriver(driver)}><FormattedMessage id="GENERAL.SAVE"/></button>
                                    }
                                  {/*   &nbsp;
                                    <button className="btn btn-danger" onClick={() => closeDriverDetail(driver)}><FormattedMessage id="DRIVERS.CLOSE"/></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <table className="table table-bordered table-hover driver_devices" border="0" width="100%">
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage id="GENERAL.DEVICES"/>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    driverDevices.map((device,index) => {
                                        return ( <tr key={index}>
                                                    <td style={{align:'left'}}>
                                                        <label style={{fontWeight:'inherit'}}>
                                                            <input type="checkbox" checked={device.checked === 1 ? true : false} name={device.name} onChange={(event) => handleChange(event,index)}/>
                                                            {device.name}
                                                        </label>
                                                    </td>
                                                </tr>
                                                )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="clearfix"></div>
                </div>
                }
                </div>
                <div className="clearfix"></div>
                <Modal
                    size="lg"
                    show={deleteDriver_}
                    aria-labelledby="deleteTemplate"
                    onHide={ () => setDeleteDriver(false)}>
                    
                    <Modal.Header closeButton>
                        <Modal.Title id="deleteTemplate">
                            <FormattedMessage id="DRIVERS.DELETE_DRIVER"/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="deleteTemplate">
                        <div className="ngdialog-message">
                            <p><FormattedMessage id="DRIVERS.CONFIRM_DELETE_DRIVER"/></p>
                        </div>
                        <div className="ngdialog-buttons">
                            <div className="form-group">
                            <button type="button" className="badge badge-danger pull-right" onClick={() => deleteDriver(driverID)}><FormattedMessage id="GENERAL.DELETE"/></button>
                            <button type="button" className="badge badge-warning pull-right" onClick={() => setDeleteDriver(false)} ><FormattedMessage id="GENERAL.TURN_BACK"/></button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <MessageWindow
                    size='md'
                    show={showMessage.status}
                    onHide={() => setShowMessage({...showMessage, status: false})}
                    title={showMessage.title}
                    message={showMessage.message}
                />
        </div>
  );

}
const mapStateToProps = function(state) {
    return {
      drivers: state.driverListReducer.drivers
    }
};
export default connect(mapStateToProps)(DriverList);







