import './index.css';

import React, {useState, useEffect, useRef} from 'react';
import HMap from './hmap';

function HomeMap(props) {
    const [size, setSize] = useState([0, 800]);
    const [zoomFix, setZoomFix] = useState(false);
    const longlat = [27.1699050, 38.4310990];
    const mapInstance = useRef(null);

    const updateSize = () => {
        setSize([window.innerWidth, (window.innerHeight - 41)]);
        if (mapInstance && mapInstance.current) {
            mapInstance.current.fitMap();
        }
    };
    
    useEffect(() => {
        if (mapInstance.current === null) {
            mapInstance.current = new HMap(longlat);
            mapInstance.current.initMap(13, true);
            mapInstance.current.setLayer(0, true);
        }

        mapInstance.current.getVectorSource().clear();

        if (props.devices !== undefined && props.devices.length > 0) {
            props.devices.forEach((device, index, array) => {
                if (device.hide_status || device.dec_long === 0.0 || device.dec_lat === 0.0)
                    return;
    
                device['address'] = sessionStorage.getItem(device.dec_lat + "_" + device.dec_long);
    
                let markerIcon = require(`../Assets/images/device-icons/car_${device.type}.gif`).default;
                mapInstance.current.addMarker([device.dec_long, device.dec_lat], markerIcon, device, "all_devices");
            });

            if (!zoomFix) {
                window.addEventListener('resize', updateSize);
                updateSize();
            }
        }

        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, [props.devices]);
        
    return (
        <div>
            <div id="map" style={{ width: "100%", height: `${size[1]}px` }}></div>
            <div id="zoomFix">Zoom Kilitle
                <input
                    onChange={(event) => setZoomFix(event.target.checked)}
                    defaultChecked={zoomFix}
                    type="checkbox" />
            </div>
            <div id="popup" className="ol-popup">
                <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                <div id="popup-content"></div>
            </div>
        </div>
    );
}

HomeMap.defaultProps = {
    active: "horizont",
    maps: ["horizont"]
}

export default HomeMap;
