import BaseService from './BaseService';

class AutoPilotSpeedWarningsService extends BaseService {

  get(deviceId, startDate, endDate) {
    return this.axiosInstance.get(`/auto_pilot_speed_warnings/${deviceId}/?start_date=${startDate}&end_date=${endDate}`,
      {cancelToken: this.cancelTokenSource.token});
  }

}

export default AutoPilotSpeedWarningsService;