import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import HomeNav from './nav';
import {FormattedMessage} from 'react-intl';
import {Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import MessageWindow from '../Commons/messageWindow';
import {pad,toFixed} from '../Assets/utils';
import Moment from 'moment';
import IconTextWithFa from '../Commons/iconTextWithFa';
import {saveAs} from 'file-saver';

import SignalService from '../../services/Signals';
import ReportService from '../../services/Reports';
import CarService from '../../services/Cars';
import DriverService from '../../services/Drivers';
import Export from '../../services/Export';


function MonthlyReport(props) {

    let start_date = new Date();
    let end_date = new Date();

    start_date = new Date(start_date.getFullYear(), start_date.getMonth(), 1, 0, 0, 0);
    end_date = new Date(end_date.getFullYear(), end_date.getMonth() + 1, 0, 0, 0, 0);

    const deviceId = props.match.params.deviceId;

    const [trips, setTrips] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [changeMonthControl, setChangeMonthControl] = useState(false);
    const [total, setTotal] = useState({});
    const [start_mil, setStartMileage] = useState(0);
    const [end_mil, setEndtMileage] = useState(0);
    const [showDailyTrip, setShowDailyTrip] = useState(false);
    const [selectedDateStart, setSelectedDateStart] = useState(start_date);
    const [selectedDateEnd, setSelectedDateEnd] = useState(end_date);
    const [dailyTrips, setDailyTrips] = useState([]);

    const exportService = new Export();
    const carService = new CarService();
    const signalService = new SignalService();
    const reportService = new ReportService();
    const driverService = new DriverService();

    const handleFilter = async (id,start_date,end_date) => {
      if (deviceId !== 0) {
          try {
            await reportService.getDeviceMonthlyTrips(id,start_date,end_date)
              .then(async(response) => {
                getStartAndEndMileage(id,response.data)
                setMonthlyTrips(response.data);
                setLoading(false);
                setChangeMonthControl(false);
                return response.data;
              });
          } catch (error) {
          }
      } 
      else{
          alert(<FormattedMessage id="REPORTS.PICK_DEVICE"/>);
      }

       //unmount
      return () => {
          reportService.getCancelToken().cancel();
      }
  }

     const getStartAndEndMileage = async (deviceId,trips) => {
        let start_signal;
        let end_signal;
        let car;

        try {
            await signalService.getSignalBySignalId(deviceId,trips[0].start_signal_id)
              .then(async (response) => {
                start_signal = response.data;
            });
      
            await signalService.getSignalBySignalId(deviceId, trips[trips.length - 1].end_signal_id)
              .then((response) => {
                end_signal = response.data;
            });

            await carService.getCar(deviceId)
              .then((response) => {
                car = response.data;
            });

            if (start_signal.mileage) {
               setStartMileage(start_signal.mileage + car.car_mileage_at_install);
            }
            if (end_signal.mileage) {
                setEndtMileage(end_signal.mileage + car.car_mileage_at_install);
            }
      
          } catch (error) {
          }
    }

    const reportDetail = async (report) => {
      setShowDailyTrip(false);
      let start_time = Moment(report.start_time, "DD/MM/YYYY HH:mm:ss").toDate();
      let start_date = Moment(new Date(start_time)).format("YYYY-MM-DD HH:mm:ss");


      let end_time = Moment(report.end_time, "DD/MM/YYYY HH:mm:ss").toDate();
      let end_date = Moment(new Date(end_time)).format("YYYY-MM-DD HH:mm:ss");

      try {
        await Promise.all([
          driverService.getDrivers(),
          reportService.getDeviceDailyTrips(deviceId,start_date,end_date),
        ])
        .then(([driversResponse, reportResponse]) => {
          setDailyTrips(setReportDetail(driversResponse.data,reportResponse.data));
          setShowDailyTrip(true);
        });
        } catch (error) {
      }
    }

    const setReportDetail = (drivers,trips) => {
        
      for (let i = 0; i < trips.length; i++) {
          // convert date object start_time and end_time
          if (!(trips[i]['start_time'] instanceof Date)) {
              trips[i]['start_time'] = Moment(new Date(Moment(trips[i]['start_time'], "YYYY/MM/DD HH:mm:ss").toDate())).format("YYYY/MM/DD HH:mm:ss");
              trips[i]['end_time'] = Moment(new Date(Moment(trips[i]['end_time'], "YYYY/MM/DD HH:mm:ss").toDate())).format("YYYY/MM/DD HH:mm:ss");
          }

          trips[i]['duration'] =  Moment(new Date(Moment('1970/01/01 ' + trips[i]['duration'], "YYYY/MM/DD HH:mm:ss").toDate())).format("YYYY/MM/DD HH:mm:ss");
          trips[i]['waiting_time'] = Moment(new Date(Moment('1970/01/01' + trips[i]['waiting_time'], "YYYY/MM/DD HH:mm:ss").toDate())).format("YYYY/MM/DD HH:mm:ss");
          // check if trip jump
          if (Moment(new Date(trips[i]['start_time'])).format("YYYY/MM/DD") !== Moment(new Date(trips[i]['end_time'])).format("YYYY/MM/DD")) {
              trips[i]['jump'] = true;

              // if trip is jump from previous day, class: 'danger'
              // if trip is jump to next day, class: ''
              trips[i]['jump_class'] = (i === 0) ? 'danger' : '';
          }

          // set driver
          for (let j = 0; j < drivers.length; j++) {
              if (trips[i]['driver_id'] === drivers[j]['driver_id']) {
                  trips[i]['driver'] = drivers[j];
              }
          }
      }
      return trips;
  };

    const setMonthlyTrips = (trips) => {
      for (let i = 0; i < trips.length; i++) {
        // convert start and end time to date format
        trips[i]['start_time'] = trips[i]['start_time'] !== '' ? Moment(new Date(trips[i]['start_time'])).format("DD/MM/YYYY HH:mm:ss") : '';
        trips[i]['end_time'] = trips[i]['end_time'] !== '' ? Moment(new Date(trips[i]['end_time'])).format("DD/MM/YYYY HH:mm:ss") : '';
        trips[i]['waiting_time'] = trips[i]['waiting_time'] !== '' ? Moment(new Date('01/01/1970 ' + trips[i]['waiting_time'])).format("DD/MM/YYYY HH:mm:ss") : '';
        trips[i]['duration'] = trips[i]['duration'] !== '' ? Moment(new Date('01/01/1970 ' + trips[i]['duration'])).format("DD/MM/YYYY HH:mm:ss") : '';

        // check if trip jump to next day
        if (trips[i]['start_date'] != trips[i]['end_date']) {
            trips[i]['jump'] = true;
        }
      }
      
        let dayText = 'Gün';

        var monthly_total = {}, day, hour, min, sec, total_duration, total_active_time, 
        total_waiting_time, total_parked_time, total_private_duration,
        total_private_duration_sec = 0, total_duration_sec = 0, total_parked_time_sec = 0,
        total_km = 0, total_private_km = 0, total_waiting_time_sec = 0;

        for (let i = 0; i < trips.length; i++) {
            total_km += trips[i]['km'];
            total_private_km += trips[i]['private_km'];
            total_duration_sec += trips[i]['duration_sec'];
            total_private_duration_sec += trips[i]['private_duration_sec'];
            total_waiting_time_sec += trips[i]['waiting_time_sec'];
            total_parked_time_sec += trips[i]['parked_time_sec'];
        };

        // calculate total duration        
        day = Math.floor(total_duration_sec / (3600 * 24));
        hour = Math.floor((total_duration_sec / 3600) % 24);
        min = Math.floor((total_duration_sec / 60) % 60);
        sec = Math.floor(total_duration_sec % 60);
        total_duration = day +" "+ dayText +" "+ pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total private duration        
        day = Math.floor(total_private_duration_sec / (3600 * 24));
        hour = Math.floor((total_private_duration_sec / 3600) % 24);
        min = Math.floor((total_private_duration_sec / 60) % 60);
        sec = Math.floor(total_private_duration_sec % 60);
        total_private_duration = day +" "+ dayText +" "+ pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total waiting time
        day = Math.floor(total_waiting_time_sec / (3600 * 24));
        hour = Math.floor((total_waiting_time_sec / 3600) % 24);
        min = Math.floor((total_waiting_time_sec / 60) % 60);
        sec = Math.floor(total_waiting_time_sec % 60);
        total_waiting_time = day +" "+ dayText +" "+ pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total parked time
        day = Math.floor(total_parked_time_sec / (3600 * 24));
        hour = Math.floor((total_parked_time_sec / 3600) % 24);
        min = Math.floor((total_parked_time_sec / 60) % 60);
        sec = Math.floor(total_parked_time_sec % 60);
        total_parked_time = day +" "+ dayText +" "+ pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        // calculate total active time
        day = Math.floor((total_duration_sec + total_private_duration_sec + total_waiting_time_sec) / (3600 * 24));
        hour = Math.floor(((total_duration_sec + total_private_duration_sec + total_waiting_time_sec) / 3600) % 24);
        min = Math.floor(((total_duration_sec + total_private_duration_sec + total_waiting_time_sec) / 60) % 60);
        sec = Math.floor((total_duration_sec + total_private_duration_sec + total_waiting_time_sec) % 60);
        total_active_time = day +" "+ dayText +" "+ pad(hour) + ":" + pad(min); // + ":" + pad(sec);

        monthly_total.km = Number(toFixed(total_km, 2));
        monthly_total.private_km = Number(toFixed(total_private_km, 2));
        monthly_total.duration = total_duration;
        monthly_total.private_duration = total_private_duration;
        monthly_total.waiting_time = total_waiting_time;
        monthly_total.parked_time = total_parked_time;
        monthly_total.active = total_active_time;

        setTotal(monthly_total);
        setTrips(trips);
        setLoading(false);
    }

    const movePrevMonth = () => {
      let prevDateStart = new Date(selectedDateStart.getFullYear(), selectedDateStart.getMonth() - 1, 1, 0, 0, 0);
      let prevDateEnd = new Date(selectedDateEnd.getFullYear(), selectedDateEnd.getMonth(), 0, 23, 59, 59);
      let dateStart = new Date(prevDateStart.toISOString());
      let dateEnd = new Date(prevDateEnd.toISOString());
      setSelectedDateStart(dateStart);
      setSelectedDateEnd(dateEnd);
      setChangeMonthControl(true);
    }
  
    const moveNextMonth = () => {
      let nextDateStart = new Date(selectedDateStart.getFullYear(), selectedDateStart.getMonth() + 1, 1, 0, 0, 0);
      let nextDateEnd = new Date(selectedDateEnd.getFullYear(), selectedDateEnd.getMonth() + 2, 0, 23, 59, 59);
      let dateStart = new Date(nextDateStart.toISOString());
      let dateEnd = new Date(nextDateEnd.toISOString());
      if (dateStart <= new Date()) {
        setSelectedDateStart(dateStart);
        setSelectedDateEnd(dateEnd);
      }
      setChangeMonthControl(true);
    }

    // onclick handler
    const handleExport = async (selectedDateStart, selectedDateEnd, fileExtension, yearlyReport=false) => {
      const start = Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss");
      const end = Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss");
      const _trips = [...trips, total];

      if (yearlyReport)
          await exportService.exportYearlyReport(deviceId, start, end, _trips, fileExtension)
            .then((response) => {
                let blob = new Blob([response.data]);
                saveAs(blob, 'Yıllık_Rapor.' + fileExtension);
            });

      else if (deviceId > 0)
          await exportService.exportMonthlyReport(deviceId, start, end, _trips, fileExtension)
            .then((response) => {
                let blob = new Blob([response.data]);
                saveAs(blob, `Aylık_Rapor.` + fileExtension);
            });
    };

    useEffect(() => {
      handleFilter(deviceId,Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss"),Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss"));
    }, [changeMonthControl]);
    
    return (
      <div className="home-monthly">
      <MessageWindow
        title="Yetkiniz yok!"
        message="Paketiniz bu özelliği içermiyor. Bilgi için: 0(232) 339 60 06"
        show={!props.access} />

      <HomeNav deviceId={deviceId} active="monthly-report" />
      <>
        <div className="filters">
          <div className="filter-1">
            <p><b><FormattedMessage id="GENERAL.FILTER_OPTIONS"/></b></p>
            <p className="change-day"><a style={{color: '#333'}} href="#" onClick={() => movePrevMonth()}><IconTextWithFa icon="arrow-alt-circle-left"/></a> <FormattedMessage id="GENERAL.MONTH"/> <a style={{color: '#333'}} href="#" onClick={() => moveNextMonth()}><IconTextWithFa icon="arrow-alt-circle-right"/></a></p>
            <p className="daily-km"><span className="badge badge-danger"><FormattedMessage id="GENERAL.TOTAL"/> Km {total.km}</span></p>
          </div>
        </div>
        <div className="filters">
          <div className="row filter-1">
                <DatePicker
                  className="mb-1"
                  todayButton="Today"
                  maxDate={new Date()}
                  dateFormat="dd-MM-yyyy HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  timeCaption="time"
                  selected={selectedDateStart}
                  onChange={(date) => setSelectedDateStart(date)}/>&nbsp; &nbsp; &nbsp;
                <DatePicker
                  todayButton="Today"
                  maxDate={new Date()}
                  dateFormat="dd-MM-yyyy HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  timeCaption="time"
                  selected={selectedDateEnd}
                  onChange={(date) => setSelectedDateEnd(date)}/>&nbsp; &nbsp; &nbsp;
              <div className="pull-left no-padding-left col-md-2">
                <div className="form-group">
                  <button className="btn btn-primary btn-sm btn-block" onClick={() => handleFilter(deviceId,Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss"),Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss"))}>
                    <FormattedMessage id="GENERAL.FILTER"/>
                  </button>
                </div>
              </div>
              <div className="filter-3 export-icons">
                <div className="pull-left">
                  <p className="report-icon" style={{cursor: 'pointer'}}
                    onClick={() => handleExport(selectedDateStart, selectedDateEnd, 'xlsx')}>
                    <img src={require("./images/excel-icon.png").default} width="40" alt='excel-icon'/>
                    <br/>
                      <a>
                        <FormattedMessage id="GENERAL.EXPORT_EXCEL"/>
                      </a>
                  </p>
                  <p className="report-icon" style={{cursor: 'pointer'}}
                    onClick={() => handleExport(selectedDateStart, selectedDateEnd, 'pdf')}>
                    <img src={require("./images/pdf-icon.png").default} width="40" alt='pdf-icon'/>
                    <br/>
                      <a>
                        <FormattedMessage id="GENERAL.EXPORT_PDF"/>
                      </a>
                  </p>
                </div>
              </div>
          </div>
        </div>
        {
          !isLoading ? 
          <React.Fragment>
            <div> 
                <div className="trips table-responsive" id="monthly-trips">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th><FormattedMessage id="GENERAL.DATE"/></th>
                                <th><FormattedMessage id="GENERAL.START"/></th>
                                <th><FormattedMessage id="GENERAL.STOP"/></th>
                                <th>Km - <FormattedMessage id="REPORTS.TRIP_TYPE_2"/></th>
                                <th>Km - <FormattedMessage id="REPORTS.TRIP_TYPE_1"/></th>
                                <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
                                <th><FormattedMessage id="GENERAL.TRIP_TIME"/>(<FormattedMessage id="GENERAL.PRIVATE"/>)</th>
                                <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
                                <th><FormattedMessage id="REPORTS.DAILY_STANDBY"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                trips.length !== 0 ? 
                                trips.map((trip,index) => {
                                    let date = Moment(new Date(trip.start_date)).format("DD/MM/YYYY").substring(0,11);
                                        return  <tr key={'trip-' + index} id={'trip-' + index} className={trip.class !== undefined ? trip.class : index%2 !== 0 ? 'active' : ''}>
                                                    {
                                                        props.user.user_group_id === 2 ?
                                                            <td><img onClick={() => reportDetail(trip)} className="report-detail-icon" src={require("./images/search-icon.png").default} width="15" alt=""/></td>
                                                            :
                                                            null
                                                    }
                                                    <td>{date}</td>
                                                    <td>{trip.start_time !== undefined ?  trip.start_time.substring(11,16) : ''}</td>
                                                    <td>{trip.end_time !== undefined ?  trip.end_time.substring(11,16) : ''}</td>
                                                    <td>{trip.private_km !== undefined ?  trip.private_km : ''}</td>
                                                    <td>{trip.km !== undefined ?  trip.km : ''}</td>
                                                    <td>{trip.duration !== undefined ?  trip.duration.substring(11,16) : ''}</td>
                                                    <td>{trip.private_duration !== undefined ?  trip.private_duration.substring(0,5) : ''}</td>
                                                    <td>{trip.waiting_time !== undefined ?  trip.waiting_time.substring(11,16) : ''}</td>
                                                    <td>{trip.parked_time !== undefined ?  trip.parked_time.substring(0,5) : ''}</td>
                                                </tr>
                                }): null
                            }
                        </tbody>
                    </table>
                </div>
                <div className="trips-total table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th><FormattedMessage id="GENERAL.ACTIVE_TIME"/></th>
                                <th>Km - <FormattedMessage id="REPORTS.TRIP_TYPE_2"/></th>
                                <th>Km - <FormattedMessage id="REPORTS.TRIP_TYPE_1"/></th>       
                                <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
                                <th><FormattedMessage id="GENERAL.TRIP_TIME"/>(<FormattedMessage id="GENERAL.PRIVATE"/>)</th>
                                <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
                                <th><FormattedMessage id="REPORTS.DAILY_STANDBY"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="info">
                            <td><b><FormattedMessage id="GENERAL.TOTAL"/></b></td>
                            <td>{total.active}</td>
                            <td>{total.private_km}</td>
                            <td>{total.km}</td>
                            <td>{total.duration}</td>
                            <td>{total.private_duration}</td>
                            <td>{total.waiting_time}</td>
                            <td>{total.parked_time}</td>
                            </tr>
                        </tbody>
                    </table>  
                </div>
                <div ng-hide="reportType == 'driver'">
                  <p><span className="badge badge-primary">Km - <FormattedMessage id="GENERAL.MORNING"/> : {toFixed(start_mil, 2)}</span></p>
                  <p><span className="badge badge-primary">Km - <FormattedMessage id="GENERAL.EVENING"/> : {toFixed(end_mil, 2)}</span></p>
                </div>
                <div className="clearfix"></div>
            </div>
            <Modal
                size="lg"
                show={showDailyTrip}
                aria-labelledby="showDailyTrip"
                onHide={ () => setShowDailyTrip(false)}>
                
                <Modal.Header closeButton>
                    <Modal.Title id="showDailyTrip">
                        <FormattedMessage id="REPORTS.REPORT_DETAIL"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="showDailyTrip">
                    <div className="ngdialog-message">
                        <table className="table table-hover report-detail">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="REPORTS.ROUTE_NO"/></th>
                                    <th><FormattedMessage id="GENERAL.DATE"/></th>
                                    <th><FormattedMessage id="GENERAL.START"/></th>
                                    <th><FormattedMessage id="GENERAL.START_ADDRESS"/></th>
                                    <th><FormattedMessage id="GENERAL.STOP"/></th>
                                    <th><FormattedMessage id="GENERAL.END_ADDRESS"/></th>
                                    <th><FormattedMessage id="GENERAL.KM"/></th>
                                    <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
                                    <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
                                    <th><FormattedMessage id="GENERAL.DRIVER"/></th>
                                    <th className="last"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dailyTrips.length !== 0 ? 
                                    dailyTrips.map((trip,index) => {
                                            let date = Moment(new Date(trip.start_date)).format("DD/MM/YYYY").substring(0,11);
                                            return  <tr key={'trip-' + index} id={'trip-' + index} className={trip.jump ? trip.jump_class : index%2 !== 0 ? 'active' : ''}>
                                                        <td>
                                                            <span> {index} </span>
                                                        </td>
                                                        <td>{date}</td>
                                                        <td>{trip.start_time !== undefined ?  Moment(new Date(trip.start_time)).format("DD/MM/YYYY HH:mm").substring(11,16) : ''}</td>
                                                        <td width="500">{trip.start_address !== undefined ?  trip.start_address : ''}</td>
                                                        <td>{trip.end_time !== undefined ?   Moment(new Date(trip.end_time)).format("DD/MM/YYYY HH:mm").substring(11,16) : ''}</td>
                                                        <td width="500">{trip.end_address !== undefined ?  trip.end_address : ''}</td>
                                                        <td>{trip.lenght_km !== undefined ?  trip.lenght_km : ''}</td>
                                                        <td>{trip.duration !== undefined ?  trip.duration.substring(11,16) : ''}</td>
                                                        <td>{trip.waiting_time !== undefined ?  trip.waiting_time.substring(11,16) : ''}</td>
                                                        <td style={{fontWeight:'normal'}}>{trip.driver !== undefined ? trip.driver.driver_last_name : ''}</td>
                                                    </tr>
                                    }): null
                                }
                            </tbody>
                        </table>
                        </div>
                        <div className="ngdialog-buttons">
                            <div className="form-group">
                                <button onClick={() => setShowDailyTrip(false)} type="button" className="badge badge-warning pull-right"><FormattedMessage id="GENERAL.TURN_BACK"/></button>
                            </div>
                        </div>
                </Modal.Body>
            </Modal>
        </React.Fragment> : null 
        }
      
      <div className="clearfix"/>
      </>
    </div>
    
  );

}
const mapStateToProps = function(state) {
    return {
        devices: state.deviceListReducer.devices,
        drivers: state.driverListReducer.drivers,
        user: state.userInformationReducer.user
    }
};
export default connect(mapStateToProps)(MonthlyReport);


