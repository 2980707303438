import BaseService from './BaseService';

class GeofenceZones extends BaseService {

  getByDeviceId(deviceId) {
    return this.axiosInstance.get(`/geofence_zones/${deviceId}/?`, {cancelToken: this.cancelTokenSource.token});
  }
  
  getAreaControlByDeviceId(deviceId) {
    return this.axiosInstance.get(`/area_control/${deviceId}/?`, {cancelToken: this.cancelTokenSource.token});
  }

  insertAreaControl(data) {
    return this.axiosInstance.post(`/area_control/update/${data.device_id}/`, data);
  }
  
  insert(data) {
    return this.axiosInstance.post(`/geofence_zones/update/${data.device_id}/`, data);
  }

}

export default GeofenceZones;