import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import {getTime} from '../Assets/utils';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts)
}

function DailySignals(props) {

  const signals = props.data;
  let dates = [];
  let speed = [];
  
  signals.forEach(signal => {
    dates.push(`${getTime(signal.geocode_sent_at)}`);
    speed.push(signal.speed_km);
  });

  const options = {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Bugüne Ait Hız / Zaman Grafiği '
    },
    yAxis: {
      title: {
          text: 'Hız'
      }
    },
    xAxis: {
      categories: dates,
    },
    tooltip: {
      backgroundColor: '#ffffff',
      xDateFormat: '%d-%m-%Y',
      borderWidth: 2,
      borderRadius: 7,
      headerFormat: `<span style='font-size:10px;'>{point.key}</span><br />`,
      pointFormat: `Hız: <b>{point.y}</b>`,
      useHTML: true,
      crosshairs: {
        dashStyle: 'dash'
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: true
      }
    },
    series: [
      {
        showInLegend: false,
        name: "Hız",
        data: speed
      }
    ]
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default DailySignals;