import React, { useState } from 'react';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import UserComponent from "./userComponent";
import MessageWindow from "../Commons/messageWindow";
import IconTextWithFa from "../Commons/iconTextWithFa";

import UserInformation from "../../services/UserInformation";

function NewUser(props) {
	const [user, setUser] = useState({
		user_id: 0,
		user_first_name: '',
		user_last_name: '',
		user_name: '',
		user_email: '',
		user_pass: '',
		active: 1,
		users_added_at: new Date(),
		active_until: null
	});
	const [showMessage, setShowMessage] = useState({
		message: '',
		title: '',
		status: false
	});

	const userInformationService = new UserInformation();

	const handleUserChange = (name, value) => {
		setUser({
			...user,
			[name]: value
		})
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		try{
			const sendData = {
				...user,
				users_added_at : (user.users_added_at) ? Moment(user.users_added_at).format("YYYY-MM-DD 00:00:00") : user.users_added_at,
				active_until: (user.active_until) ? Moment(user.active_until).format("YYYY-MM-DD 00:00:00") : user.active_until
			};

			await userInformationService.createSubUser(sendData)
				.then((response) => {
					if (response.data.result !== 'error' && response.data.result > 0) {
						setUser({...user, user_id: response.data.result});

						setShowMessage({
							status: true,
							title: "İşlem Başarılı",
							message: "Kullanıcı başarıyla kaydedildi."
						});

					} else {
						setShowMessage({
							status: true,
							title: "Hata",
							message: "Kullanıcı kaydedilemedi. Lütfen bilgileri gözden geçirip tekrar deneyiniz."
						});
					}

				});

		}catch (e) {}
	};

	return (
		<div className='container-fluid mt-2 size-lg'>
			<button className="btn btn-danger" onClick={() => props.history.goBack()}>
				<IconTextWithFa icon="arrow-alt-circle-left"/>
				<FormattedMessage id='GENERAL.TURN_BACK'/>
			</button>
			<br/><br/>
			<div className="col-md-3">
				<UserComponent
					user={user}
					title={<FormattedMessage id='USER_RIGHTS.NEW_USER'/>}
					isValid={{user_name: false, user_email: false}}
					handleUserChange={handleUserChange}
					handleSubmit={handleSubmit}
				/>
			</div>
			<MessageWindow
				show={showMessage.status}
				title={showMessage.title}
				onHide={() => {
					setShowMessage({message: '', title: '', status: false});
					if (user.user_id > 0) props.history.push(`/user-rights/edit/${user.user_id}`);
				}}
				message={showMessage.message}
			/>
		</div>
	);
}

export default NewUser;