const langEn = {
    en: {
        "LANG.TR": "Turkish",
        "LANG.EN": "English",
        "LANG.DE": "German",
        "LANG.NL": "Dutch",
        "GENERAL.DEVICES": "Vehicles",
        "GENERAL.DRIVERS": "Drivers",
        "GENERAL.DATE": "Date",
        "GENERAL.KM": "Kilometer",
        "GENERAL.PRIVATE": "Private",
        "GENERAL.SAVE": "Save",
        "GENERAL.SAVE_ALL": "Save All",
        "GENERAL.START": "Start",
        "GENERAL.STOP": "Stop",
        "GENERAL.ADDRESS": "Address",
        "GENERAL.START_DATE": "Start Date",
        "GENERAL.END_DATE": "End Date",
        "GENERAL.START_ADDRESS": "Start Address",
        "GENERAL.END_ADDRESS": "End Address",
        "GENERAL.TRIP_TIME": "Travel Time ",
        "GENERAL.WAIT_TIME": "Waiting Time",
        "GENERAL.IDLE_TIME": "Standby Time",
        "GENERAL.ACTIVE_TIME": "Active Time",
        "GENERAL.TOTAL_TIME": "Total Time",
        "GENERAL.TIME": "Time",
        "GENERAL.TOTAL": "Total",
        "GENERAL.DRIVER": "Driver",
        "GENERAL.YEAR": "Year",
        "GENERAL.MONTH": "Month",
        "GENERAL.DAY": "Day",
        "GENERAL.DAYS": "Days",
        "GENERAL.KMH": "km/s",
        "GENERAL.HOUR": "Hour",
        "GENERAL.MINUTE": "Minute",
        "GENERAL.MIN": "Min",
        "GENERAL.SECOND": "Sec",
        "GENERAL.SEC": "Sn.",
        "GENERAL.TURN_BACK": "Turn Back",
        "GENERAL.YES": "Yes",
        "GENERAL.NO": "No",
        "GENERAL.NR": "No",
        "GENERAL.DEMO": "Demo",
        "GENERAL.BUY": "Buy!",
        "GENERAL.NO_DATA": "No Data Found",
        "GENERAL.ERROR": "An error occurred, please try again.",
        "GENERAL.PLS_PICK_DEVICE": "Please pick a vehicle",
        "GENERAL.PICK_DEVICE": "Pick a vehicle",
        "GENERAL.PICK_DRIVER": "Pick a driver",
        "GENERAL.CLOSE": "Close",
        "GENERAL.PLEASE_WAIT": "Please wait..",
        "GENERAL.ADD": "Add",
        "GENERAL.EDIT": "Edit",
        "GENERAL.DELETE": "Delete",
        "GENERAL.FILTER": "Filter",
        "GENERAL.FILTER_OPTIONS": "Filter Options",
        "GENERAL.PRINT": "Print",
        "GENERAL.EXPORT_PDF": "Export PDF",
        "GENERAL.EXPORT_EXCEL": "Export Excel",
        "GENERAL.UNDEFINED": "Undefined",
        "GENERAL.USER_NAME": "Username",
        "GENERAL.PASSWORD": "Password",
        "GENERAL.PASSWORD_AGAIN": "Password (again)",
        "GENERAL.NAME": "Name",
        "GENERAL.SURNAME": "Last Name",
        "GENERAL.MAIL": "E-mail",
        "GENERAL.CITY": "City",
        "GENERAL.CHANGE": "Change",
        "GENERAL.ALL": "All",
        "GENERAL.PASSIVE": "Passive",
        "GENERAL.SERVICE": "Service",
        "GENERAL.REQUIRED_FIELDS": "Required Fields",
        "GENERAL.SELECT": "Select",
        "GENERAL.SELECTED": "Selected",
        "GENERAL.ACTIVE": "Active",
        "GENERAL.SMS": "SMS",
        "GENERAL.NOTIFICATIONS": "Notifications",
        "GENERAL.FAX": "Fax",
        "GENERAL.TEL": "Tel",
        "GENERAL.DESCRIPTION": "Description",
        "GENERAL.TOTAL_MILEAGE": "Total Traveled KM",
        "GENERAL.OK": "Ok",
        "GENERAL.NEW": "New",
        "GENERAL.CANCEL": "Cancel",
        "GENERAL.DEVICE_NAME": "Vehicle Name",
        "GENERAL.MORNING": "Morning",
        "GENERAL.EVENING": "Evening",
        "GENERAL.AUTO_RELOAD_ON": "To refresh the page automatically in every 2 minutes, click here.",
        "GENERAL.AUTO_RELOAD_OFF": "The page is refresing in every 2 minutes..",
        "GENERAL.PREVIOUS": "Previous",
        "GENERAL.NEXT": "Next",
        "GENERAL.WARNING": "Warning",
        "GENERAL.RESULTS_PER_PAGE": "Results per page:",
        "GENERAL.PREV_DAY": "Previous Day",
        "GENERAL.NEXT_DAY": "Next Day",
        "GENERAL.ICON": "Icon",
        "GENERAL.MAIL_WRITE": "Enter your email addresses",
        "GENERAL.PHONE_WRITE": "Enter your phone number",
        "GENERAL.CHOOSE": "Select",
        "GENERAL.STEP": "Step",
        "GENERAL.SAVE_FOR_ALL_DEVICE": "Save for all vehicles",
        "GENERAL.SEARCH": "Search",
        "GENERAL.FILTER_RESULTS": "Filter Results",
        "GENERAL.USER": "User",
        "GENERAL.INACTIVE": "Inactive",
        "GENERAL.TODAY": "Today",
        "GENERAL.21_FUNCTIONS": "21 Functions at a glance!",
        "GENERAL.TOGGLEALL": "Toggle All",
        "GENERAL.LAST": "Last",
        "GENERAL.PHONE_NUMBER": "030 355 306 77 0",
        "GENERAL.PHONE_NUMBER_TEXT": "Telefonic Consultancy",
        "GENERAL.IDLE": "Idle - Ignition",
        "GENERAL.ONE_HOUR": "Can't get more than 1 hour GPS / GSM signal",
        "GENERAL.ONE_HOUR_LINK": "Devices without current position for 1 hour",
        "GENERAL.LATEST_SIGNAL_DATE": "Last signal date",
        "GENERAL.NORESULT": "No Results",
        "GENERAL.NO_ACCESS": "You can not buy this option with online. Please contact with support 030 3553 0677 0",
        "GENERAL.HIDE_DEVICE_CONTENT": "Private journey no data collection!!",
        "GENERAL.HIDE_ATTENTION": "Attention! With a active selection a private journey with no data acquisition!",
        "GENERAL.DISABLE": "Disable",
        "GENERAL.SOMETHING_WENT_WRONG": "Something went wrong. Please refresh the page.",
        "GENERAL.HOURLY_REPORT": "Hourly Report",
        "DAYS.MONDAY": "Monday",
        "DAYS.TUESDAY": "Tuesday",
        "DAYS.WEDNESDAY": "Wednesday",
        "DAYS.THURSDAY": "Thursday",
        "DAYS.FRIDAY": "Friday",
        "DAYS.SATURDAY": "Saturday",
        "DAYS.SUNDAY": "Sunday",
        "DAYS_NUMERIC.DAY_1": "Monday",
        "DAYS_NUMERIC.DAY_2": "Tuesday",
        "DAYS_NUMERIC.DAY_3": "Wednesday",
        "DAYS_NUMERIC.DAY_4": "Thursday",
        "DAYS_NUMERIC.DAY_5": "Friday",
        "DAYS_NUMERIC.DAY_6": "Saturday",
        "DAYS_NUMERIC.DAY_0": "Sunday",
        "MONTHS_NUMERIC.MONTH_1": "January",
        "MONTHS_NUMERIC.MONTH_2": "February",
        "MONTHS_NUMERIC.MONTH_3": "March",
        "MONTHS_NUMERIC.MONTH_4": "April",
        "MONTHS_NUMERIC.MONTH_5": "May",
        "MONTHS_NUMERIC.MONTH_6": "June",
        "MONTHS_NUMERIC.MONTH_7": "July",
        "MONTHS_NUMERIC.MONTH_8": "August",
        "MONTHS_NUMERIC.MONTH_9": "September",
        "MONTHS_NUMERIC.MONTH_10": "October",
        "MONTHS_NUMERIC.MONTH_11": "November",
        "MONTHS_NUMERIC.MONTH_12": "December",
        "HOME.SORT": "Sort",
        "HOME.SORT_ALL_DEVICES": "Sort by vehicle",
        "HOME.SORT_BY_GROUP": "Sort by group",
        "HOME.FIRST_RUN": "First Start",
        "HOME.IGNITION": "Ignition",
        "HOME.DAILY_MILEAGE": "Daily Km",
        "HOME.SPEED": "Speed",
        "HOME.RESULTS": "Results",
        "HOME.SHOWING": "Showing",
        "HOME.DISPLAY": "Display",
        "HOME.LIST_TYPE": "List Type",
        "HOME.LIST_TYPE_STANDART": "Standart",
        "HOME.LIST_TYPE_STANDART_BIG": "Standart New",
        "HOME.LIST_TYPE_KLEIN": "Plate",
        "HOME.LIST_TYPE_MINI": "Mini",
        "HOME.LIST_TYPE_GROSSANSICHT": "Large",
        "HOME.LIST_TYPE_DETAILERT": "Detailed",
        "HOME.LIST_TYPE_ROUND": "Round",
        "HOME.LIST_TYPE_MODERN": "Modern",
        "HOME.OPTIONS": "Options",
        "HOME.DEVICE_SEARCH": "Search Vehicle",
        "HOME.ALL_ROUTES": "All Routes",
        "HOME.CUSTOMER_REPORTS": "Customer Reports",
        "HOME.SHOW_SELECTED": "Show selected ones",
        "HOME.DEVICE_FILTER": "Vehicles to display",
        "HOME.CURRENT_COORDINATES": "Current Coordinates",
        "HOME.COPY_COORDINATES": "You can copy the coordinates with CTRL-C, then paste into a map's address search box with CTRL-V.",
        "HOME": "Home",
        "MENU.ALL_DEVICES": "All Vehicles",
        "MENU.ROUTES": "Routes",
        "MENU.REPORTS": "Electronic Reports",
        "MENU.REPORTS2": "Reports",
        "MENU.FUNCTIONS": "Other Functions",
        "MENU.THEFT": "Theft Protection",
        "MENU.SERVICE": "Vehicle Service",
        "MENU.RENT": "Rent A Car",
        "MENU.GROUPS": "Vehicle Groups",
        "MENU.USER_RIGHTS": "User Rights",
        "MENU.TECHNICAL_SUPPORT": "Technical Support",
        "MENU.APPLICATIONS": "Free Applications",
        "MENU.OPINIONS": "Your Opinions",
        "MENU.LANGUAGE": "Language",
        "MENU.TIMEZONE": "Time Zone",
        "MENU.SETTINGS": "Settings",
        "MENU.LOGOUT": "Logout",
        "MENU.DRIVERS_LIST": "Driver List",
        "MENU.MYLOCATIONS": "My Locations",
        "MENU.CUSTOMER_GROUPS": "Customer Groups",
        "MENU.SMS_CREDITS": "SMS Orders",
        "MENU.APPS": "Apple & Android Apps",
        "MENU.ANDROID_APP": "Horizont_Ortungssysteme app for Android",
        "MENU.APPLE_APP": "Horizont Ortungssysteme app for Apple",
        "MENU.INSTRUCTIONS": "Instructions Manual",
        "MENU.APPLE_APP2": "Horizont Ortungssysteme app for Apple",
        "MENU.SAVED_PLACES": "Route search for stored locations",
        "MENU.EDIT_PLACES": "Edit locations for the route request",
        "MENU.PLACES": "Route selection Location selection",
        "MENU.ROUTE_TRACKING": "Route Tracking",
        "MENU.GRAPHIC": "Graphic",
        "MENU.AUFTRAGS": "Auftrags App",
        "MENU.WEB_INTEGRATION": "Web integration into your website",
        "MENU.NOTIFICATION_SETTINGS": "Air Alarm",
        "MENU.DUAL_SCREEN": "Dual Screen",
        "MENU.LATEST_LOGIN": "Latest Login",
        "MENU.LOGIN_DATE": "Login Date",
        "MENU.PLATFORM": "Platform",
        "MENU.BROWSER": "Browser",
        "MENU.BROWSER_VERSION": "Browser Version",
        "MENU.LOGIN_DEVICES": "Logged In Devices",
        "MENU.APP_VERSION": "App Version",
        "MENU.PLATFORM_VERSION": "Platform Version",
        "MENU.DATA_DELETION": "Data Deletion",
        "TEMPLATES.CHOOSE": "Templates",
        "TEMPLATES.TEMPLATE_DEFAULT": "Default",
        "TEMPLATES.TEMPLATE_1": "Template 1",
        "TEMPLATES.TEMPLATE_2": "Template 2",
        "TEMPLATES.TEMPLATE_3": "Template 3",
        "TEMPLATES.TEMPLATE_4": "Template 4",
        "TEMPLATES.TEMPLATE_5": "Template 5",
        "TEMPLATES.TEMPLATE_6": "Template 6",
        "QMENU.LOCATION": "Location",
        "QMENU.ROUTE": "Route",
        "QMENU.LIVE": "Live",
        "QMENU.DAILY_REPORT": "Daily Report",
        "QMENU.MONTHLY_REPORT": "Monthly Report",
        "QMENU.SPEED_CHART": "Speed Chart",
        "QMENU.FUEL": "Fuel Consumption",
        "QMENU.AUTO_PILOT": "Auto Pilot",
        "QMENU.GRAPHS": "Graphs",
        "QMENU.GEOFENCE_REPORTS": "Geofence Reports",
        "QMENU.OTHER": "Other Functions",
        "QMENU.COLORED_ROUTE": "Colored Routes",
        "QMENU.OP_ROUTE": "Optimized Routes",
        "QMENU.AUTO_PLAY": "Auto Play",
        "GRAPHS.START_STOP_TIMES": "Start - End Times",
        "GRAPHS.LAST_SEVEN_DAY": "Daily Travel Time",
        "GRAPHS.LAST_SEVEN_DAY_KM": "Daily Traveled KM",
        "GRAPHS.DRIVE_TIME": "Travel Time",
        "GRAPHS.DRIVE_KM": "Total Km",
        "GRAPHS.TODAY_SPEED": "Speed-Time Graph For Today",
        "GRAPHS.YESTER_CON": "Travel Stats For Yesterday",
        "GRAPHS.MAX_SEVEN": "You can select max. one week date range!",
        "GRAPHS.ALLDEVKM": "Daily Km of Vehicles",
        "GRAPHS.ALLDEVSPEED": "Speed of Vehicles",
        "GRAPHS.AVG_SPEED": "Speed Average",
        "GRAPHS.MAX_SPEED": "Maximum Speed",
        "GRAPHS.downloadJPEG": "Download JPEG",
        "GRAPHS.downloadPNG": "Download PNG",
        "GRAPHS.downloadSVG": "Download SVG",
        "GRAPHS.downloadPDF": "Download PDF",
        "GRAPHS.printChart": "Print Graph",
        "GRAPHS.loading": "Loading...",
        "GRAPHS.contextButtonTitle": "Graph Menu",
        "GRAPHS.drillUpText": "Turn back to {series.name}",
        "GRAPHS.invalidDate": "Invalid Date",
        "GRAPHS.noData": "No Data Found",
        "GRAPHS.resetZoom": "Reset Zoom",
        "GRAPHS.ACT_STATUS": "Total Travel Stats",
        "GRAPHS.FUEL_CHART": "Daily Fuel Status Graph",
        "GRAPHS.FUEL_TABLE": "Daily Fuel Status Table",
        "GRAPHS.START_FUEL": "Fuel Status (start of day)",
        "GRAPHS.END_FUEL": "Fuel Status (end of day)",
        "GRAPHS.FUEL_CONSUME": "Fuel Consumption",
        "GRAPHS.FUEL_BUY": "Fuel Bought",
        "GRAPHS.TEMP_CHART": "Daily Temperature Sensor Graph",
        "GRAPHS.TEMP_TABLE": "Daily Temperature Sensor Table",
        "GRAPHS.TEMP_AVG": "Daily Temperature Average",
        "GRAPHS.FUEL_PER": "Fuel Percent",
        "GRAPHS.FUEL_LT": "Fuel Liter",
        "GRAPHS.WEATHER_FORECAST": "Weather Forecast",
        "GRAPHS.FUEL_FULLING": "Benzin füllungsliste",
        "GRAPHS.RPM": "RPM",
        "GRAPHS.RPM_CHART": "Günlük Devir Grafiği",
        "GRAPHS.FUEL_GES": "Kilometerstand beim Tanken KM",
        "GRAPHS.FUEL_GETANKE": "Getankte Liter",
        "GRAPHS.VOLLTANK": "Details zum Volltank",
        "GRAPHS.WARM_CHART": "Motor Sıcaklığı Grafiği",
        "GRAPHS.SEVEN_FIRST_LAST": "7 Days First Start and Last Stop",
        "GRAPHS.INFO": "If you point your mouse over a graphic, you will get more information",
        "SIGNALS.SHOW_MAP": "Show Map",
        "SIGNALS.SHOW_STREET_NAMES": "Show Street Names",
        "SIGNALS.SHOW_SIGNALS": "Show Signals",
        "SIGNALS.ONLY_STARTSTOP": "Show Only Starts & Stops",
        "SIGNALS.EXCEPT_ZERO": "Show Only Speed Information",
        "SIGNALS.SHOW_THESE": "Show these sensors",
        "SIGNALS.SIGNAL_TYPE": "Signal Type",
        "SIGNALS.LOC_CHG": "Change Locations",
        "SIGNALS.SIGNAL_STR": "Signal Strenght",
        "SIGNALS.NR_SAT": "Satellite Count",
        "SIGNALS.AKU_VOLT": "Accumulator ",
        "SIGNALS.BAT_VOLT": "Battery Voltage",
        "SIGNALS.IN_OUT": "In / Out",
        "SIGNALS.TOTAL_DURATION": "Total Duration",
        "SIGNALS.IN": "In",
        "SIGNALS.OUT": "Out",
        "SIGNALS.MAX_3_DAYS": "You can select max. 3 days range!",
        "SIGNALS.signal_mode_0": "Periodic Signal",
        "SIGNALS.signal_mode_1": "--",
        "SIGNALS.signal_mode_2": "Stand By",
        "SIGNALS.signal_mode_3": "Start",
        "SIGNALS.signal_mode_4": "Stop",
        "SIGNALS.signal_mode_5": "--",
        "SIGNALS.signal_mode_6": "--",
        "SIGNALS.signal_mode_7": "--",
        "SIGNALS.signal_mode_8": "--",
        "SIGNALS.signal_mode_9": "--",
        "SIGNALS.signal_mode_10": "--",
        "SIGNALS.signal_mode_11": "Digital Input 2 ON",
        "SIGNALS.signal_mode_12": "Digital Input 2 OFF",
        "SIGNALS.signal_mode_13": "Digital Input 3 ON",
        "SIGNALS.signal_mode_14": "Digital Input 3 OFF",
        "SIGNALS.signal_mode_15": "Digital Input 4 ON",
        "SIGNALS.signal_mode_16": "Digital Input 4 OFF",
        "SIGNALS.signal_mode_17": "--",
        "SIGNALS.signal_mode_18": "--",
        "SIGNALS.signal_mode_19": "--",
        "SIGNALS.signal_mode_20": "--",
        "SIGNALS.signal_mode_21": "--",
        "SIGNALS.signal_mode_22": "--",
        "SIGNALS.signal_mode_23": "--",
        "SIGNALS.signal_mode_24": "--",
        "SIGNALS.signal_mode_25": "--",
        "SIGNALS.signal_mode_26": "--",
        "SIGNALS.signal_mode_27": "--",
        "SIGNALS.signal_mode_28": "--",
        "SIGNALS.signal_mode_29": "--",
        "SIGNALS.signal_mode_30": "--",
        "SIGNALS.signal_mode_31": "--",
        "SIGNALS.signal_mode_32": "--",
        "SIGNALS.signal_mode_33": "--",
        "SIGNALS.signal_mode_34": "--",
        "SIGNALS.signal_mode_35": "--",
        "SIGNALS.signal_mode_36": "--",
        "SIGNALS.signal_mode_37": "--",
        "SIGNALS.signal_mode_38": "--",
        "SIGNALS.signal_mode_39": "--",
        "SIGNALS.signal_mode_40": "--",
        "SIGNALS.signal_mode_41": "--",
        "SIGNALS.signal_mode_42": "--",
        "SIGNALS.signal_mode_43": "--",
        "SIGNALS.signal_mode_44": "--",
        "SIGNALS.signal_mode_45": "--",
        "SIGNALS.signal_mode_46": "--",
        "SIGNALS.signal_mode_47": "--",
        "SIGNALS.signal_mode_48": "--",
        "SIGNALS.signal_mode_49": "--",
        "SIGNALS.signal_mode_50": "--",
        "SIGNALS.signal_mode_51": "--",
        "SIGNALS.signal_mode_52": "--",
        "SIGNALS.signal_mode_53": "--",
        "SIGNALS.signal_mode_54": "--",
        "SIGNALS.signal_mode_55": "--",
        "SIGNALS.signal_mode_56": "--",
        "SIGNALS.signal_mode_57": "--",
        "SIGNALS.signal_mode_58": "--",
        "SIGNALS.signal_mode_59": "--",
        "SIGNALS.signal_mode_60": "--",
        "SIGNALS.signal_mode_61": "--",
        "SIGNALS.LAT": "Latitude",
        "SIGNALS.LONG": "Longitude",
        "SERVICE.VEHICLE_NAME": "Vehicle Name",
        "SERVICE.CURRENT_MILEAGE": "Current Mileage",
        "SERVICE.INSTALL_MILEAGE": "Install Mileage",
        "SERVICE.AVG_FUEL": "Average Fuel Consumption",
        "SERVICE.CURRENT_RUN_TIME": "Current Working Time",
        "SERVICE.INSTALL_RUN_TIME": "Install Working Time",
        "SERVICE.EMAIL_ADDRESS": "Service Warning Email Addresses",
        "SERVICE.INFORMED_BY_MAIL": "I want to receive my service warnings via e-mail",
        "SERVICE.CURRENT_ICON": "Current Vehicle Icon",
        "SERVICE.ICON_GALLERY": "Icon Gallery",
        "SERVICE.CHOOSE_NEW_ICON": "You can choose a new icon",
        "SERVICE.BASIC_SETTINGS": "Main Settings",
        "SERVICE.IGNITION": "Ignition",
        "SERVICE.IGNITIONS": "Ignitions",
        "SERVICE.OIL_SERVICE": "Oil Service",
        "SERVICE.BRAKE_SERVICE": "Brake Service",
        "SERVICE.BELT_SERVICE": "Timing Belt Service",
        "SERVICE.INSPECTION_SERVICE": "Inspection Service",
        "SERVICE.TACHOGRAPH_SERVICE": "Tachograph Service",
        "SERVICE.TRAILER_SERVICE": "Trailer Service",
        "SERVICE.HIDE_SERVICE": "Hide Vehicles",
        "SERVICE.HIDE_SERVICE_DATA": "Private data storage",
        "SERVICE.FUEL_SERVICE": "Fuel Service",
        "SERVICE.TEMP_SERVICE": "Temperature Service",
        "SERVICE.SERVICE_INTERVAL": "Service Interval",
        "SERVICE.NEW_SERVICE_HISTORY": "New Service Record",
        "SERVICE.CREATE_SERVICE_HISTORY": "Create new service record",
        "SERVICE.DELETE_SERVICE_HISTORY": "Delete Record",
        "SERVICE.DELETE_CONFIRM": "Are you sure you want to delete this record?",
        "SERVICE.DELETE_SUCCESS": "Record has deleted",
        "SERVICE.DELETE_ERROR": "We couldnt delete the record, please try again.",
        "SERVICE.BACK_TO_SERVICE": "Turn back to vehicle service",
        "SERVICE.SERVICE_HISTORY": "Service Records",
        "SERVICE.UPDATE_SUCCESS": "Saving completed successfully.",
        "SERVICE.UPDATE_ERROR": "An error occurred while saving the data, please try again.",
        "SERVICE.INTERVAL_MONTH": "Interval Time (Month)",
        "SERVICE.ACTIVATION_SETTINGS": "Activation Settings",
        "SERVICE.DATE_RANGE": "Date Range",
        "SERVICE.ACTIVE_STATUS": "Active Status",
        "SERVICE.RESET_DATES": "Reset Date",
        "SERVICE.RESET_ALL_DATA": "Reset All Data",
        "SERVICE.ROMORK_PLATE": "Trailer Plate",
        "SERVICE.ALERT_TOP": "You have service warnings!",
        "SERVICE.ALERT_KM": "Km remaining to next service:",
        "SERVICE.ALERT_DATE": "Days remaining to next service (gün):",
        "SERVICE.ALERT_KM_EXCEEDED": "Service kilometer exceeded! Please check you service records.",
        "SERVICE.ALERT_DATE_EXCEEDED": "Service date exceeded! Please check you service records.",
        "SERVICE.INTERVAL_INFO": "You can define the service interval as kilometer or date (month)",
        "SERVICE.CREATE_HISTORY_INFO": "If you want to create a new service record with the date and km above, tick the checkbox",
        "SERVICE.IN_DEMO": "You are using the demo version of this feature.",
        "SERVICE.DEMO_EXPIRED": "Vehicle service demo time has expired for the selected vehicle.",
        "SERVICE.DEMO_OR_BUY": "You can buy the vehicle service feature or you can start 10 days demo version for selected vehicle.",
        "SERVICE.NOT_PERMITTED": "You dont have the permission to use this feature for the selected vehicle.",
        "SERVICE.NOT_PERMITTED_MULTI": "You dont have the permission to use this feature for the selected vehicles",
        "SERVICE.IVDC_DATE_ALERT": "Start date cannot greater than the end date",
        "SERVICE.HIDE_DEVICE_FOR_TIME": "Hide vehicle for specific days",
        "SERVICE.HIDE_DEVICE_FOR_ALWAYS": "Hide vehicle always",
        "SERVICE.HIDE_DEVICE_FOR_ALWAYS_COMMENT": "Hide the selected vehicle always",
        "SERVICE.HIDE_ALWAYS": "Hide Device",
        "SERVICE.SHOW_ALWAYS": "Show Device",
        "SERVICE.DEVICE_HIDDEN": "This device is hidden.",
        "SERVICE.DEVICE_PRIVATE": "Private driving is currently active. No data is saved!",
        "SERVICE.IVDC_INFO": "When you made your date and time choices, check this to activate your settings",
        "SERVICE.SERVICE_ALERT": "Service Alert!",
        "SERVICE.SERVICE_TYPE_1": "Oil",
        "SERVICE.SERVICE_TYPE_2": "Breaks",
        "SERVICE.SERVICE_TYPE_3": "Time Belt",
        "SERVICE.SERVICE_TYPE_4": "Inspection",
        "SERVICE.SERVICE_TYPE_5": "Tachograph",
        "SERVICE.SERVICE_TYPE_6": "Trailer",
        "SERVICE.POSTPONE_ALERTS": "Serviceanzeige fuer 7 Tage nicht anzeigen",
        "SERVICE.PRIVATE_DRIVE": "If you activate the feature, data will not be recorded!",
        "SERVICE.PRIVATE_DRIVE_FOR_TIME": "Private drive for a certain time. Please select the period.",
        "SERVICE.PRIVATE_DRIVE_FOR_ALWAYS": "Permanent private drive",
        "SERVICE.PRIVATE_DRIVE_FOR_ALWAYS_COMMENT": "Please click for a permanent private drive. If you activate the feature, data will not be recorded!",
        "SERVICE.PRIVATE_SHOW_ALWAYS": "Private Drive Deactivation",
        "SERVICE.PRIVATE_HIDE_ALWAYS": "Private Drive Activation",
        "SERVICE.FUEL_INFO": "Tank capacity (lt): After fill the tank full, enter the tank capacity as liter:",
        "SERVICE.INFO_CURRENT_MILEAGE": "Total mileage of vehicle (include setup mileage)",
        "SERVICE.INFO_AVG_FUEL": "The average amount of fuel consumed per 100 kilometers",
        "SERVICE.INFO_INSTALL_MILEAGE": "Total mileage of vehicle before the device installed",
        "SERVICE.INFO_INSTALL_RUN_TIME": "Total vehicle active work time before the device installed",
        "SERVICE.INFO_CURRENT_RUN_TIME": "Total vehicle active work time (include install working time)",
        "SERVICE.FUEL_INFO2": "After installation, enter 'auto service section located underneath the fuel sensor, the fuel quantity in percent. Then the data will check mikltar fuel to enter the portal.",
        "SERVICE.STEP1": "Step 1",
        "SERVICE.STEP2": "Step 2",
        "GROUPS.ALL": "All Groups",
        "GROUPS.NAME": "Group Name",
        "GROUPS.UPDATE": "Update Group",
        "GROUPS.CREATE": "Create Group",
        "GROUPS.EDIT_NAME": "Update Group Name",
        "GROUPS.DELETE": "Delete Group",
        "GROUPS.CONFIRM_DELETE": "Are you sure that you want delete this group?",
        "GROUPS.DELETE_SUCCESS": "Group has deleted successfully.",
        "GROUPS.DELETE_ERROR": "An error occurred while deleting the group, please try again",
        "GROUPS.CREATE_NEW_GROUP": "Create New Group",
        "GROUPS.FILL_GROUP_NAME": "Please enter a group name",
        "GROUPS.DEVICES_IN_GROUP": "Vehicles in Group",
        "GROUPS.ADD_REMOVE_DEVICE": "Add-Remove Vehicle",
        "GROUPS.SELECTED_DEVICES": "Selected Vehicles",
        "GROUPS.DEVICES_TO_ADD": "Vehicles will add to the group",
        "REPORTS.DAILY": "Daily Report",
        "REPORTS.MONTHLY": "Monthly Report",
        "REPORTS.ROUTE": "Route Report",
        "REPORTS.DEVICE_REPORTS": "Vehicle Reports",
        "REPORTS.DRIVER_REPORTS": "Driver Reports",
        "REPORTS.WITH_DRIVERS": "Show drivers",
        "REPORTS.ONLY_PRIVATE": "Only Private Trips",
        "REPORTS.MANUAL_TRIP": "Manuel Trips",
        "REPORTS.DAY_TOTAL": "Daily Total",
        "REPORTS.ALL_TOTAL": "Total",
        "REPORTS.ROUTE_NO": "Route No",
        "REPORTS.ADD_TRIP": "Add Trip",
        "REPORTS.EDIT_TRIP": "Edit Trip",
        "REPORTS.ADD_TRIP_INFO": "Click to add a new trip below this trip",
        "REPORTS.DELETE_TRIP_INFO": "Click to delete this trip",
        "REPORTS.DELETE_TRIP": "Delete Trip",
        "REPORTS.CONFIRM_DELETE_TRIP": "Are you sure to delete this trip?",
        "REPORTS.DAILY_STANDBY": "Standby Time",
        "REPORTS.TOTAL_ACTIVE_TIME": "Total Active Time",
        "REPORTS.REPORT_DETAIL": "Report Details",
        "REPORTS.ADD_REPORT": "Add Report",
        "REPORTS.DELETE_REPORT": "Delete Report",
        "REPORTS.EDIT_REPORT": "Update Report",
        "REPORTS.PICK_DEVICE_OR_DRIVER": "Please select a device or driver",
        "REPORTS.TRIP_TYPE": "Trip Type",
        "REPORTS.TRIP_TYPE_0": "Unknown",
        "REPORTS.TRIP_TYPE_1": "Service",
        "REPORTS.TRIP_TYPE_2": "Private",
        "REPORTS.TRIP_TYPE_3": "Home",
        "REPORTS.FAVORITES": "Favorites",
        "REPORTS.PICK_FAV": "Pick Favorite",
        "REPORTS.FAV_DETAIL": "Favorite Detail",
        "REPORTS.FAV_INFO": "Your previous 'visited place' records adds to your favorites. If there favorites will match your next trip's end addresses, they will show in below table.",
        "REPORTS.POST_CODE": "Post Code",
        "REPORTS.ABOUT": "Route",
        "REPORTS.VISITED": "Visited Place",
        "REPORTS.REPORT_TYPE": "Report Type",
        "REPORTS.SHOW_DEVICE_NAME": "Show Vehicle Name",
        "REPORTS.MONTHLY_REPORT_FOR_ALL_DEVICE": "Monthly report for all vehicles",
        "REPORTS.DAILY_REPORT_FOR_ALL_DEVICE": "Daily report for all vehicles",
        "REPORTS.DAILY_REPORT_THIS_DEVICE": "Daily report for only this vehicle",
        "REPORTS.SPEED_REPORT_THIS_DEVICE": "Speed report for only this vehicle",
        "REPORTS.SELECT_REPORT": "Select the desired reports",
        "REPORTS.YEARLY": "Yearly Report",
        "REPORTS.YEARLY_PDF": "Yearly Report PDF Export",
        "REPORTS.SELECT_YEAR": "Select Year",
        "REPORTS.FILTER_BY_KM": "0.0 - 0.2 show/hide",
        "REPORTS.DATE_LIMIT_ALERT": "Maximum date range that you can select is",
        "REPORTS.DATE_LIMIT_ALERT_2": "You should upgrade your package to increase that date range.  \nPlease contact our customer service to upgrade your package.",
        "REPORTS.CANCELED_REPORTS": "Canceled Reports",
        "REPORTS.DAYS_7": "Distance display with 7 day data archiving",
        "REPORTS.SHOW_SERVICE_TRIPS": "Show only service trips",
        "REPORTS.REPORT_NO": "Report No",
        "ALERT.FILL_THE_BLANKS": "Please fill the blanks.",
        "ALERT.UPDATE_SUCCESS": "Update completed successfully.",
        "ALERT.DELETE_SUCCESS": "Data successfully deleted.",
        "ALERT.SAVE_SUCCESS": "Data saved successfully",
        "ALERT.DEVICE_DISABLE": "This devices is closed to use. Please contact with us for more information.",
        "ALERT.SMS_SENT_SUCCESS": "SMS sent successfully.",
        "ALERT.SMS_SENT_FAIL": "An error occurred while sending the sms.",
        "USER_RIGHTS.ALL_USERS": "All Users",
        "USER_RIGHTS.ADD_REMOVE_USER_RIGHT": "User Right Add-Remove",
        "USER_RIGHTS.NEW_USER": "New User",
        "USER_RIGHTS.REMOVE_USER": "Delete User",
        "USER_RIGHTS.UPDATE_USER": "Update User",
        "USER_RIGHTS.USER_STATUS": "User Active",
        "USER_RIGHTS.ACT_START": "Activation Start",
        "USER_RIGHTS.ACT_END": "Activation End",
        "USER_RIGHTS.ER_MAIL_AE": "Email address is already taken",
        "USER_RIGHTS.ER_UN_AE": "Username is already taken.",
        "USER_RIGHTS.ER_PW_1": "Passwords doesnt match, please check and try again.",
        "USER_RIGHTS.ER_NN": "Please fill the required fields.",
        "USER_RIGHTS.FILL_OBLIGATION": "Required field.",
        "USER_RIGHTS.ER_MAIL_VAL": "Invalid email address.",
        "USER_RIGHTS.NOTHING_FOUND": "No user right found.",
        "USER_RIGHTS.CHOOSE_USER": "Select a user or add a new user",
        "USER_RIGHTS.CONFIRM_DELETE": "Are you sure to delete this subuser?",
        "USER_RIGHTS.tracking": "Tracking",
        "USER_RIGHTS.live": "Live",
        "USER_RIGHTS.all_tracks": "Tüm Rotalar",
        "USER_RIGHTS.velocity": "Speed Chart",
        "USER_RIGHTS.tracking_route": "Route tracking",
        "USER_RIGHTS.unlimited_tracking": "without 7 day limit",
        "USER_RIGHTS.routing": "Route",
        "USER_RIGHTS.daily": "Günlük Rapor",
        "USER_RIGHTS.monthly": "Aylık Rapor",
        "USER_RIGHTS.drive": "Rota Raporu",
        "USER_RIGHTS.driver": "Sürücü Menüsü",
        "USER_RIGHTS.sensoren": "Sensors",
        "USER_RIGHTS.speed": "Speed",
        "USER_RIGHTS.electronic": "Electronic Reports",
        "USER_RIGHTS.theft": "Theft Protection",
        "USER_RIGHTS.phone_2": "2.Phone",
        "USER_RIGHTS.sms": "SMS",
        "USER_RIGHTS.service": "Vehicle Service",
        "USER_RIGHTS.device_groups": "Vehicle Groups",
        "USER_RIGHTS.roaming1": "Roaming",
        "USER_RIGHTS.zugriffsrechte": "User Rights",
        "USER_RIGHTS.gsm_eplus": "GSM E-Plus",
        "USER_RIGHTS.gsm_tmobile": "GSM T-Mobile",
        "USER_RIGHTS.email_berichte": "Daily/Monthly Reports via Email",
        "USER_RIGHTS.play": "Animation",
        "USER_RIGHTS.USER_TYPE": "User Type",
        "USER_RIGHTS.ACTIVE_USERS": "Active Users",
        "USER_RIGHTS.PASSIVE_USERS": "Passive Users",
        "USER_RIGHTS.SUBUSER_DEVICE_NAME": "Subuser view this device as:",
        "USER_RIGHTS.EDIT_DEVICE_NAME": "Subuser can change device name",
        "USER_RIGHTS.autoplay": "Autoplay",
        "USER_RIGHTS.measure": "measure",
        "USER_RIGHTS.autopilot": "Autopilot",
        "USER_RIGHTS.planned_route": "Planned Route",
        "USER_RIGHTS.location": "Route selection Location selection",
        "USER_RIGHTS.graphical": "Graphic representation",
        "USER_RIGHTS.fuel": "Petroleum payment",
        "USER_RIGHTS.optimize": "Route Optimization",
        "USER_RIGHTS.diebstahlschutz_indi": "Theft Protection 2",
        "USER_RIGHTS.18_months": "18 Months Data",
        "USER_RIGHTS.ADD_FAVORI": "Add New Favorite",
        "USER_RIGHTS.DELETE_FAVORI": "Delete Favorite",
        "USER_RIGHTS.UPDATE_FAVORI": "Update Favorite",
        "USER_RIGHTS.FAVORI_NAME": "Favorite Name",
        "USER_RIGHTS.NAME_ALARM": "Please do not leave your Favorite Name blank.",
        "USER_RIGHTS.SELECT_FAVORITE": "Select Favorite",
        "USER_RIGHTS.ALARM_OPTIONS": "Please select at least one of the options.",
        "USER_RIGHTS.CONFIRM_FAVORI_DELETE": "Are you sure you want to delete it?",
        "USER_RIGHTS.SUCCESS_DELETE": "Delete Successful.",
        "USER_RIGHTS.SUCCESS_SAVE": "Save Successful",
        "USER_RIGHTS.SUCCESS_UPDATE": "Update Successful",
        "USER_RIGHTS.FAVORITE_OPTIONS": "Favorite Option",
        "USER_RIGHTS.FAVORITE_FUNC": "Favorite Function",
        "USER_RIGHTS.FAVORITE": "Favorite",
        "USER_RIGHTS.SAVEALL": "Save these settings for all vehicle",
        "USER_RIGHTS.air_notifications": "Air alarm notifications",
        "RENTACAR.NEW_RENTACAR": "New Rent Record",
        "RENTACAR.OLD_RENTACAR": "Rent Records History",
        "RENTACAR.IDENTITY_NO": "ID",
        "RENTACAR.LICENSE_NO": "Licence No",
        "RENTACAR.LICENSE_CLASS": "Licence Class",
        "RENTACAR.BIRTH_DATE": "Birthday",
        "RENTACAR.PHONE_NO": "Tel No",
        "RENTACAR.ADDRESS": "Address",
        "RENTACAR.RENT_DATE": "Rent Date",
        "RENTACAR.RENT_BACK_DATE": "Rent End Date",
        "RENTACAR.VEHICLE_KM": "Vehicle Km",
        "RENTACAR.FUEL_AMOUNT": "Fuel Amount",
        "RENTACAR.EQUIPMENT": "Equipment",
        "RENTACAR.NOTES": "Notes",
        "RENTACAR.RENT_PRICE": "Rent Price",
        "RENTACAR.BAILS": "Bails",
        "RENTACAR.READ_ACCEPT_AGR1": "Terms of Agreement",
        "RENTACAR.READ_ACCEPT_AGR2": " read this agreement and I accept the terms. ",
        "RENTACAR.CHOOSE_DEV": "Select a vehicle",
        "RENTACAR.DRIVER_NAME": "Driver Name",
        "RENTACAR.CHOOSE": "Select",
        "RENTACAR.ADD_NEW": "Add New",
        "RENTACAR.UPDATE": "Update",
        "RENTACAR.RENTER": "Renter",
        "RENTACAR.DAMAGES": "Damages",
        "GAUGAE.FULL_SCREEN": "Full Screen",
        "GAUGAE.LIVE_ON": "Live On",
        "GAUGAE.LIVE_OFF": "Live Off",
        "GAUGAE.ANIM_ON": "Live Animation On",
        "GAUGAE.ANIM_OFF": "Live Animation Off",
        "GAUGAE.GAUGAE_OPEN": "Open Gaugaes",
        "GAUGAE.GAUGAE_CLOSE": "Close Gaugaes",
        "GAUGAE.SEARCH_ADR": "Search Addresses",
        "GAUGAE.PAGE_NOT_FOUND": "Page Not Found",
        "GAUGAE.FUEL_AMOUNT": "Fuel Amount",
        "GAUGAE.TOTAL_KM": "Total Km",
        "GAUGAE.DAILY_KM": "Daily Km",
        "GAUGAE.TEMP": "Temperature",
        "GAUGAE.HUMIDITY": "Humidity",
        "GAUGAE.WEATHER_TEXT": "Weather forecast for next days.",
        "GAUGAE.CANBUS_TAGES": "7 Days KM",
        "GAUGAE.ZURLIVE": "Live Refresh",
        "GAUGAE.ZURANIM": "Live Animation",
        "GAUGAE.M_DISTANCE": "Distance",
        "DRIVERS.GENERAL_INFO": "General Informations",
        "DRIVERS.DRIVER_NO": "Driver No",
        "DRIVERS.DRIVER_TEL": "Driver Tel",
        "DRIVERS.LICENSE_CONTROL": "Licence Control",
        "DRIVERS.FAVORITE_DEVICE": "Favorite Vehicle",
        "DRIVERS.CREATE_DRIVER": "Create New Driver",
        "DRIVERS.BUTTON_LIST": "i-Button List",
        "DRIVERS.BUTTON_NO": "Button No",
        "DRIVERS.BUTTON_SERIAL_NO": "i-Button Serial No",
        "DRIVERS.BUTTON_TYPE": "i-Button Type",
        "DRIVERS.ASSIGN_DRIVER": "Assign Driver",
        "DRIVERS.NEW_DRIVER": "New Driver",
        "DRIVERS.CREATE_NEW_DRIVER": "Create New Driver",
        "DRIVERS.WORK_HOURS_CONTROL": "Working Hours Control",
        "DRIVERS.WORK_HOURS": "Working Hours",
        "DRIVERS.WORK_HOURS_START": "Working Hours Start",
        "DRIVERS.WORK_HOURS_STOP": "Working Hours End",
        "DRIVERS.MAX_OVERTIME": "Max. Work Overtime",
        "DRIVERS.INSPECTION_INTERVAL": "Inspection Interval",
        "DRIVERS.SPECIFIED_DATE": "Specified Date",
        "DRIVERS.UPDATE_DRIVER": "Update Driver",
        "DRIVERS.DELETE_DRIVER": "Delete Driver",
        "DRIVERS.CONFIRM_DELETE_DRIVER": "Are you sure to delete this driver?",
        "DRIVERS.DRIVER_NOT_FOUND": "Driver not found",
        "DRIVERS.FAV_DRIVER": "Favorite Driver",
        "DRIVERS.HAS_FAV_DRIVER": " for this vehicle, the selected driver is:",
        "DRIVERS.HAS_NO_FAV_DRIVER": "No driver select for this vehicle",
        "DRIVERS.UNSELECT_DRIVER": "Click to deselect the driver",
        "DRIVERS.DRIVERS_NOTIFICATION": "In theft protection as 2nd Handynummer (not for IButton Possible)",
        "DRIVERS.ENTER_PHONE_NUMBER": "Enter the 2nd mobile phone number for theft protection",
        "DRIVERS.ENTER_PHONE_NUMBER_TITLE": "Automatic transfer of the 2nd mobile number for the anti-theft protection",
        "DRIVERS.MOBILE_COMMUNICATION": "Access data app for mobile communication",
        "DRIVERS.DRIVER_NO_AUTO": "Driver No (Automatisch)",
        "DRIVERS.DRIVER_PASS": "Driver Password",
        "DRIVERS.MOBILE_APP_STATUS": "Mobile App Status",
        "DRIVERS.ACTIVE": "Aktiv",
        "DRIVERS.DISABLE": "Deaktiv",
        "DRIVERS.24_HOUR_DRIVER": "24 hour drivers remain automatically logged on",
        "DRIVERS.ALWAYS_DRIVER": "Driver should always stay logged in (until others are registered)",
        "DRIVERS.CLOSE": "Close",
        "DRIVERS.MOBILE_DEVICES": "Mobile Devices",
        "THEFT.STEP1": "Step 1: Tracking Vehicle",
        "THEFT.STEP1_REPORTS": "Theft Protection Reports",
        "THEFT.STEP2": "Step 2: Notifications",
        "THEFT.STEP2_INFO": "You can specify the date range, if there's no specified date it will be active for 24 hours",
        "THEFT.STEP3": "Step 3: Enter your contact info.",
        "THEFT.STEP4": "Step 4: Geofence Fields Activation",
        "THEFT.WHEN_DEVICE_RUN": "Everytime when ignition on",
        "THEFT.WHEN_DOOR_OPEN": "Everytime when vehicle doors open",
        "THEFT.ALARM": "Alarm",
        "THEFT.SAVEALL": "Save these settings for all vehicle",
        "THEFT.NO_ENTER_ZONE": "Enter the zone is forbidden",
        "THEFT.SMS_ON": "Motor On",
        "THEFT.SMS_OFF": "Motor Off",
        "THEFT.SMS_GPS": "Periodical",
        "THEFT.SMS_RESET": "Restart",
        "THEFT.THEFT_TWO_STEP1": "Step 1: Geofence Fields Activation",
        "THEFT.SAVE_ALL_STEP4": "By selecting the checkbox. The setting is valid for all vehicles. If the checkbox is not selected, only for the selected vehicle",
        "THEFT.IN": "Zone entry report",
        "THEFT.OUT": "Zone exit report",
        "CUSTOMER_GROUPS.NEW": "New Customer Group",
        "CUSTOMER_GROUPS.NEW_CUSTOMER": "New Customer",
        "CUSTOMER_GROUPS.EDIT_CUSTOMER": "Update Customer",
        "CUSTOMER_GROUPS.CUSTOMER_NO": "Customer No",
        "CUSTOMER_GROUPS.FIRMA_NAME": "Customer Name",
        "CUSTOMER_GROUPS.RESP_PARTNER": "Sorumlu Partner",
        "CUSTOMER_GROUPS.CHOOSE_FUNCTION": "Lütfen gruba fonksiyon seçiniz.",
        "CUSTOMER_GROUPS.FUNC1": "Transportation control for matched vehicles to different areas(customers)",
        "CUSTOMER_GROUPS.FUNC2": " Arrive Time Control",
        "CUSTOMER_GROUPS.FUNC3": "Transportation  control for all vehicles to the specified areas",
        "CUSTOMER_GROUPS.CONFIRM_DELETE": "Are you sure to delete this data?",
        "CUSTOMER_GROUPS.ADDRESS": "Address Records",
        "CUSTOMER_GROUPS.PLS_PICK_CG": "Please select a customer group",
        "CUSTOMER_GROUPS.PLS_PICK_ADR": "Please select an address",
        "CUSTOMER_GROUPS.NEW_ADR": "New Address",
        "CUSTOMER_GROUPS.NEW_ADR_EXP": "Enter a description, then mark the location on map. After done, click save button to save. Or click cancel button to cancel.",
        "CUSTOMER_GROUPS.REPORTS_PAGE": "Customer Groups Reports",
        "CUSTOMER_GROUPS.SHOW_REPORTS": "Show Reports",
        "CUSTOMER_GROUPS.REPORT_DETAIL_NOT_FOUND": "No detail found for this report...",
        "CUSTOMER_GROUPS.ENTER_TIME": "Enter Time",
        "CUSTOMER_GROUPS.EXIT_TIME": "Exit Time",
        "CUSTOMER_GROUPS.PICK_GRP": "Select one of these groups in list, then follow follow the instructions.",
        "CUSTOMER_GROUPS.DEV_SYNC": "Matching Vehicles (only group 1 and group 2)",
        "SYSTEM._comment": "IMAGES_FOLDER  bu dilde yazılmış görselleri içeren klasörün adresi.Sistemin düzgün çalısması için her dil dosyasında bulunmalıdır, aksi halde resimler yüklenemez.",
        "SYSTEM.IMAGES_FOLDER": "./assets/img/en",
        "FUEL.CREATE_RECEIPT": "Create Receipt",
        "FUEL.PRICE_LITER": "Liter Price",
        "FUEL.PRICE_TOTAL": "Fuel Price (Total)",
        "FUEL.LITER": "Liter Quantity",
        "FUEL.LT_100_KM": "Liter/100 km",
        "FUEL.KM_DEFLECTION": " +/- 10% deviation",
        "FUEL.USER_AVG": "Your Consumption (you entered)",
        "FUEL.REAL_AVG": "Real Consumption (calculated)",
        "AUTOPILOT.AUTOPILOT_SETTINGS": "Auto-Pilot Settings",
        "AUTOPILOT.DAILY_KM": "Daily km control",
        "AUTOPILOT.SPEED_CONTROL_1": "Velocity control Urban areas ",
        "AUTOPILOT.SPEED_CONTROL_2": "Velocity control Outside village",
        "AUTOPILOT.SPEED_CONTROL_3": "Velocity control Highway",
        "AUTOPILOT.IGNITION": "Vehicle ignition alert",
        "AUTOPILOT.12V": "Devices without 12V alerts",
        "AUTOPILOT.OHNE_GSM": "Devices without GSM / GPS alerts",
        "AUTOPILOT.ATOB": "Point A to point B. control",
        "AUTOPILOT.DRIVING_TIME": " Driving time",
        "AUTOPILOT.FROM": "From",
        "AUTOPILOT.DEPERTURE_TIME": "Estimated movement of a vehicle",
        "AUTOPILOT.UNTIL": "to",
        "AUTOPILOT.PARKED": "Park",
        "AUTOPILOT.LOCATION_TIME": "Life Alert",
        "AUTOPILOT.CHOOSE_PERIOD": "Please select a period",
        "AUTOPILOT.IGNITION_CONTROL": "Ignition control",
        "AUTOPILOT.IGNITION_CHANGE": "Kontak change notification",
        "AUTOPILOT.TOWING": "Tow alert",
        "AUTOPILOT.EMAIL_INTERVAL": "Select the email send frequency",
        "AUTOPILOT.DEVIATION": "+/- 10% Deviation",
        "AUTOPILOT.MOBILE_COMMENT": "From step 1 to 11 you will receive push notification to your Apple and Android device",
        "AUTOPILOT.BEACON_DEVICE": "Beacon device notification",
        "AUTOPILOT.VEHICLE_NOTIFICATIONS_END_OF_DAY_REPORT": "Vehicle notifications and end of day report",
        "AUTOPILOT.REPORTS_FOR_ALL_VEHICLES": "Reports for all vehicles",
        "AUTOPILOT.NOTIFICATION_TYPE": "Notification Type",
        "AUTOPILOT.SPEED_LIMIT": "Speed Limit",
        "AUTOPILOT.VEHICLE_SPEED": "Vehicle Speed",
        "ROUTES.SHOW_ROUTES": "Show Routes",
        "ROUTES.RESPITE": "Respite",
        "ROUTES.WAITING": "Waiting",
        "ROUTES.FOR_MISSION": "Mission",
        "ROUTES.PRIVATE": "Private",
        "ROUTES.TRIP": "Trip",
        "ROUTES.DAILY": "Daily",
        "ROUTES.VEHICLE": "Vehicle",
        "ROUTES.VEHICLE_GROUP": "Vehicle Group",
        "ROUTES.DATE": "Date",
        "ROUTES.ALL_VEHICLE_GROUP": "All Vehicle Group",
        "ROUTES.DIGITAL_INPUT": "Digital Input",
        "ANKUFT.ANKUFT": "Arrive",
        "ANKUFT.ANKUFT_TITLE": "Live Arrive Check",
        "ANKUFT.STATUS_0": "Close",
        "ANKUFT.STATUS_1": "Late",
        "ANKUFT.STATUS_2": "Arrived in Time",
        "ANKUFT.PLATE": "Id. Plate",
        "ANKUFT.PLAN": "Planned",
        "ANKUFT.EXCEPTED": "Excepted",
        "ANKUFT.STATUS": "Status",
        "ANKUFT.ARRIVE_IN": "Arrive in",
        "ANKUFT.DISTANCE": "Distance",
        "ANKUFT.ORDER": "Order",
        "ANKUFT.FROM": "From",
        "ANKUFT.TO": "To",
        "TECHNICAL_SUPPORT.HEAD": "Technical Support",
        "TECHNICAL_SUPPORT.GENERAL": "General Informations",
        "TECHNICAL_SUPPORT.MESSAGE": "Message",
        "TECHNICAL_SUPPORT.CALL_TIME": "When we call you:",
        "TECHNICAL_SUPPORT.NAME": "Adınız Soyadınz",
        "TECHNICAL_SUPPORT.FIRMA": "Firma",
        "TECHNICAL_SUPPORT.PHONE": "Phone Number",
        "TECHNICAL_SUPPORT.EMAIL": "Email",
        "TECHNICAL_SUPPORT.INFO": "Our support service is free. Please write your problem here.",
        "TECHNICAL_SUPPORT.NOW": "Now",
        "TECHNICAL_SUPPORT.SEND": "Send",
        "TECHNICAL_SUPPORT.REQUIRED_NAME": "Please write your name.",
        "TECHNICAL_SUPPORT.REQUIRED_EMAIL": "Please write your email.",
        "TECHNICAL_SUPPORT.REQUIRED_MESSAGE": "Please write your message.",
        "TECHNICAL_SUPPORT.SUCCESS": "Message sent successfully. We will contact with you as soon as possible.",
        "SMS_OPTIONS.SMS_OPTIONS": "SMS Options",
        "SMS_OPTIONS.SMS_DESCRIPTION": "Your Choosen Option will be Charge by to your account monthly until cancellation. Please send us an email to our Support Team if you wish to cancel.",
        "SMS_OPTIONS.EXTRA_PACKAGE": "Monthly Extra Package",
        "SMS_OPTIONS.SPENT": "Used",
        "SMS_OPTIONS.BALANCE": "Balance",
        "SMS_OPTIONS.ORDER": "Order",
        "SMS_OPTIONS.SMS_ORDER": "SMS Orders",
        "SMS_OPTIONS.CHOOSE_PACKAGE": "Please choose a package.",
        "SMS_OPTIONS.CHOOSE_DEVICE": "Please choose a device",
        "INOUT.PLACE": "Place",
        "INOUT.DATE": "Date",
        "INOUT.SEARCH": "Search",
        "INOUT.INSTRUCTION": "Schritt 1: Aufenthaltsortauflistung eines ausgewählten Standorts",
        "INOUT.INFO1": "In this step, you can perform a query by defining a location with a certain radius. See how many times the vehicles have visited this location.",
        "INOUT.INFO2": "As follows :",
        "INOUT.INFO3": "On the right, select the search field and enter the desired address at the top of the page.",
        "INOUT.INFO4": "Select the desired address from the results found; Alternatively, use the arrow tool to locate the location on the map.",
        "INOUT.INFO5": "Now click the pencil icon on the map and drag a circle at the desired location and radius.",
        "INOUT.INFO6": "On the right, select the date for the route query in the selected location.",
        "INOUT.INFO7": "If you only want to request a specific time on this date, enter the start and end time for the search on the right.",
        "INOUT.INFO8": "After you have selected all the options, click on the 'Search' icon.",
        "INOUT.INFO9": "As a result, you will now see all the vehicles that are on the selected date and time within the location you selected. They are listed in tabular form. With the first signal recording from the zone, and the last signal recording from the defined zone.",
        "INOUT.INFO10": "At the end of the line in this table, is a magnifying glass. If you want a detailed listing of the signals from the zone, press this. A further table opens with a detailed listing of when the vehicle is entering this zone, and what has been driven out of this zone.",
        "INOUT.INFO11": "By activating the vehicle identifier, the route which the vehicle has traveled is adapted to match the first and last zone signals.",
        "INOUT.TIME": "Time",
        "INOUT.PLEASE_INSERT": "Please Specify Area.",
        "INOUT.ENTRY": "Entry",
        "INOUT.EXIT": "Exit",
        "INOUT.TOTAL": "Total",
        "INOUT.STOP": "Abort",
        "MYINOUT.INSTRUCTION": "Step 2: Saving the locations",
        "MYINOUT.INFO1": "On the right-hand side, select the desired date for the route query.",
        "MYINOUT.INFO2": "If you only want to request a specific time on the date you selected, select the start and end times on the right side.",
        "MYINOUT.INFO3": "After you have selected all options, please click on the name of the location you have created before.",
        "MYINOUT.INFO4": "The result: All devices that were on the selected date and time in their made location selection are displayed.",
        "MYINOUT.INFO5": "Click on the desired vehicle if you want to display the route on the map in a new window.",
        "MYINOUT.INFO6": "This displays the locations you have saved for the route query.",
        "MYINOUT.TITLE": "Saved location selection",
        "MYINOUT.TABLE_TITLE": "Displays the vehicles in the selected location",
        "MYINOUT.SAVED_PLACES": "Route search for stored locations",
        "MYINOUT.DEVICE": "Device",
        "MYINOUT.FIRST": "First",
        "MYINOUT.ZONE": "Zonene entry",
        "MYINOUT.LAST": "Last",
        "MYINOUTFORM.INSTRUCTION": "Step 3: Query for the location listing with the stored locations",
        "MYINOUTFORM.INFO1": "Enter the desired address at the top of the address search bar. Alternatively, use the arrow tool to locate the location on the map",
        "MYINOUTFORM.INFO2": "Click the pencil icon at the top of the center after finding the location to be queried.",
        "MYINOUTFORM.INFO3": "Now click on the map and drag the circle in the desired location and radius.",
        "MYINOUTFORM.INFO4": "Give your chosen location a name and save it.",
        "MYINOUTFORM.INFO5": "After successful storage, your location selection will now appear below in the list.",
        "MYINOUTFORM.INFO6": "If you want to edit a previously saved location, click on the pencil symbol.",
        "MYINOUTFORM.INFO7": "On the map, select the location for the route query again. The old selection is overwritten as soon as you press the 'Save' button again.",
        "MYINOUTFORM.NAME": "Name Location",
        "MYINOUTFORM.TABLE_TITLE": "Stored locations for route queries",
        "MYINOUTFORM.EDIT_PLACES": "Edit locations for the route request",
        "ROUTE_TRACKING.START_BUTTON": "Enter manual start on map",
        "ROUTE_TRACKING.STOP_BUTTON": "Enter manual end on map",
        "ROUTE_TRACKING.STATUS": "Status",
        "ROUTE_TRACKING.DETAIL": "Detail",
        "ROUTE_TRACKING.START_ADRESS_INPUT": "Enter the departure address",
        "ROUTE_TRACKING.DEVICE": "Vehicle",
        "ROUTE_TRACKING.DRIVER": "Driver",
        "ROUTE_TRACKING.START_ADRESS": "Start Address",
        "ROUTE_TRACKING.END_ADRESS": "Emd Address",
        "ROUTE_TRACKING.PLAN_START_TIME": "Planned Start Date",
        "ROUTE_TRACKING.EMAILS": "Notification E-mails",
        "ROUTE_TRACKING.PLAN_KM_TIME": "Estimated KM and Duration",
        "ROUTE_TRACKING.PLAN_KM": "Estimated KM",
        "ROUTE_TRACKING.PLAN_TIME": "Estimated Time",
        "ROUTE_TRACKING.SELECT_DEVICE": "Select Vehicle",
        "ROUTE_TRACKING.SELECT_DRIVER": "lect Driver",
        "ROUTE_TRACKING.INTERVAL": "Notification Interval",
        "ROUTE_TRACKING.SAVE": "Save Route Plan",
        "ROUTE_TRACKING.GENERAL_INFO": "General Information",
        "ROUTE_TRACKING.LATEST_ADRESS": "Current Address",
        "ROUTE_TRACKING.ALERT_COORDINATE": "Please enter Start and End Locations.",
        "ROUTE_TRACKING.ALERT_DEVICE": "Please Select Vehicle",
        "ROUTE_TRACKING.ALERT_EMAIL": "Please write at least 1 email address!",
        "ROUTE_TRACKING.ALERT_INTERVAL": "Please enter the notification interval.",
        "ROUTE_TRACKING.NOTIFICATION_TIME": "Notification Time",
        "ROUTE_TRACKING.NOTIFICATION_ADRESS": "Notification Address",
        "ROUTE_TRACKING.DONE_INFO_1": "Complete on",
        "ROUTE_TRACKING.DONE_INFO_2": "",
        "ROUTE_TRACKING.DELETE_SURE": "Are you sure you want to delete?",
        "ROUTE_TRACKING.TITLE": "Route Forecast",
        "ROUTE_TRACKING.NOTIFICATION_REPORTS": "Notification Reports",
        "ROUTE_TRACKING.DEFINED_START": "Defined start position",
        "ROUTE_TRACKING.DEFINED_STOP": "Defined target position",
        "AIR_ALARM_SETTINGS.AIR_ALARM": "Alarm Settings",
        "AIR_ALARM_SETTINGS.SOUND_SETTINGS": "Volume",
        "AIR_ALARM_SETTINGS.SOUND_SETTINGS_INFO": "The volume determines how many seconds the siren sounds when the alarm is triggered.",
        "AIR_ALARM_SETTINGS.SENSITIVITY_SETTINGS": "Sensitivity",
        "AIR_ALARM_SETTINGS.SENSITIVITY_SETTINGS_INFO": "The sensitivity setting may vary depending on the environment and the vehicle you are using. The recommended setting is 1300, values below this value may cause the alarm to be automatically activated.",
        "CHAT.IS_TYPING": " is typing...",
        "CHAT.CHAT_INPUT_BOX": "Enter your message...",
        "CHAT.CHAT_PROGRESS": "Chatting as ",
        "CHAT.CHAT_QUICK_REPLY_YES": "YES",
        "CHAT.CHAT_QUICK_REPLY_NO": "NO",
        "CHAT.CHAT_QUICK_REPLY_OK": "OK",
        "CHAT.CHAT_NOT_DELIVERED": "Message not sent",
        "CHAT.DRIVER_LIST_HEADER": "Drivers List",
        "CHAT.USER_LIST_HEADER": "User List",
        "CHAT.TOPUSER_LIST_HEADER" : "Top User List",
        "CHAT.TOPUSER_NOT_FOUND": "No top user found!",
        "CHAT.SUBUSER_LIST_HEADER": "Sub Users List",
        "CHAT.USER_NOT_FOUND": "No registered user found!",
        "CHAT.DRIVER_NOT_FOUND": "No registered driver found!",
        "CHAT.SUBUSER_NOT_FOUND": "No sub user found!",
        "CHAT.DRIVING_TYPE_MODAL_TITLE" : "Select you driving type",
        "CHAT.DRIVING_TYPE_MODAL_CLOSE" : "Close",
        "CHAT.DRIVING_TYPE_PRIVATE": "PRIVATE",
        "CHAT.DRIVING_TYPE_PUBLIC" : "WORK",
        "CHAT.DRIVING_TYPE_POPUP_TITLE" : "Trip Type Error",
        "CHAT.DRIVING_TYPE_ERROR" : "Trip type couldn't be saved!",
        "WEB_INTEGRATION.TITLE": "Web integration into your website",
        "WEB_INTEGRATION.CONTENT1": "Please click on the link below to generate the source code for the web integration. With this, you or your web designer can then integrate your vehicle view into your own website.",
        "BEACON.DEVICE_NAME": "Device Name",
        "BEACON.LAST_SIGNAL_TIME": "Last Signal Time",
        "BEACON.SIGNAL_STRENGTH": "Signal Strength",
        "BEACON.CONNECTION_STATUS": "Connection Status",
        "BEACON.DEVICES": "Beacon Devices",
        "BEACON.PERFECT": "Perfect",
        "BEACON.VERY_GOOD": "Very Good",
        "BEACON.GOOD": "Good",
        "BEACON.BAD": "Bad",
        "BEACON.VERY_BAD": "Very Bad",
        "DATA_DELETE.DATA_TO_BE_DELETED": "Data To Be Deleted",
        "DATA_DELETE.DEVICES_TO_BE_DELETED": "Devices To Be Deleted",
        "DATA_DELETE.DATA_DELETING": "Data Deleting",
        "DATA_DELETE.DELETED_DEVICES": "Deleted Devices",
        "DATA_DELETE.CREATE_DATE": "Create Date",
        "DATA_DELETE.DELETE_DATE": "Delete Date",
        "DATA_DELETE.CONFIRMATION_CODE": "Confirmation Code",
        "DATA_DELETE.TYPE_CONFIRMATION_CODE": "Please enter your confirmation code",
        "DATA_DELETE.CONFIRMATION": "Confirmation",
    }
};

export default langEn;