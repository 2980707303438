import './index.css';

import React, {useState, useRef, useEffect} from 'react';
import HMap from './hmap';
import animate from '../../libs/animations';

import rotating_icons from '../Assets/rotating_icons.json';

var lastLongLat = null;
var routes = [];
var lastDeviceId = 0;
var lineString = null;
var featureLine = null;
var marker = null;
var markerIcon;


function LiveMap(props) {
    const [size, setSize] = useState([0, 800]);
    const longlat = [27.1699050, 38.4310990];
    const mapInstance = useRef(null);

    const updateSize = () => {
        setSize([window.innerWidth, (window.innerHeight - 41)]);
        if (mapInstance) {
            mapInstance.current.fitMap();
        }
    }
    
    useEffect(() => {

        if (mapInstance.current === null && props.device.device_id) {
            mapInstance.current = new HMap(longlat);
            mapInstance.current.initMap(16, false, {deviceId: props.device.device_id});
            setTimeout(() => {
                updateSize();
            }, 1000);
        }

        let device = props.device;
        let newLonglat = [device.dec_long, device.dec_lat];

        if (!newLonglat[0]) {
            return;
        }
        
        if (lastDeviceId !== device.device_id) {
            mapInstance.current.getVectorSource().clear();
            routes = [newLonglat];
            lastDeviceId = device.device_id;
            lineString = mapInstance.current.createLineString(routes);
            featureLine = mapInstance.current.createLineFeature(5);
            featureLine.setGeometry(lineString);
            featureLine.getGeometry().transform('EPSG:4326', 'EPSG:3857');
            featureLine.setGeometry(lineString);
            mapInstance.current.setCenter(newLonglat, true);
            let startIcon = require(`../Assets/images/flag_green.gif`).default;
            mapInstance.current.addStop(newLonglat, startIcon, {...device});
            
            markerIcon = require(`../Assets/images/device-icons/car_${device.type}.gif`).default;
            marker = mapInstance.current.addMarker(newLonglat, markerIcon, device, "live");
            device['address'] = "";

            mapInstance.current.setLayer(device.device_id);
            
            window.addEventListener('resize', updateSize);
        }
        else
        {
            if (lastLongLat === null) {
                lastLongLat = newLonglat;
            }

            if (marker) {
                mapInstance.current.removeMarker(marker);
            }
            marker = mapInstance.current.addMarker(lastLongLat, markerIcon, device, "live");

            if (rotating_icons.indexOf(parseInt(device.type)) !== -1 ) {
                let angle = mapInstance.current.calculateAngle(lastLongLat, newLonglat);
                if (angle) {
                    mapInstance.current.setMarkerRotation(marker, angle);
                }
            }
            mapInstance.current.setCenter(newLonglat, true);
            mapInstance.current.setMarkerText(marker, `${device.name} - Hız: ${device.speed_km} km/h`);

            animate({
                duration: 3000,
                timing(timeFraction) {
                  return timeFraction;
                },
                prevLongLat: lastLongLat,
                nextLongLat: newLonglat,
                draw(progress, _lastLongLat, _newLonglat) {
                    let result = (_newLonglat[0] - _lastLongLat[0]) * progress + _lastLongLat[0];
                    let result2 = (_newLonglat[1] - _lastLongLat[1]) * progress + _lastLongLat[1];
                    let coordinates = [result, result2];
                    
                    mapInstance.current.setMarkerPosition(marker, coordinates);
                    lineString.appendCoordinate(mapInstance.current.fromLonLat(coordinates));
                }
            });
        }

        lastLongLat = newLonglat;

        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, [props.device.dec_lat]);
        
    return (
        <div>
            <div id="map" style={{ width: "100%", height: `${size[1]}px` }}></div>
            <div id="popup" className="ol-popup">
                <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                <div id="popup-content"></div>
            </div>
        </div>
    );
}

export default LiveMap;
