import './index.css';

import React, {useState, useRef, useEffect} from 'react';
import Slider from 'react-input-slider';
import HMap from './hmap';
import IconTextWithFa from '../Commons/iconTextWithFa';
import animate from '../../libs/animations';

import rotating_icons from '../Assets/rotating_icons.json';

let marker;
let interval;
let currentSignalIndex = 0;
var lastDeviceId = 0;

const AnimRouteMap = (props) => {
    const [size, setSize] = useState([0, 800]);
    const [sliderValue, setSliderValue] = useState({ x: 0 });
    const [play, setPlay] = useState(true);
    const longlat = [27.1699050, 38.4310990];
    const mapInstance = useRef(null);
    let markerIcon = require(`../Assets/images/device-icons/car_${props.device.type}.gif`).default;

    const updateSize = () => {
        setSize([window.innerWidth, (window.innerHeight - 61)]);
        if (mapInstance) {
            mapInstance.current.fitMap();
        }
    };

    const playAnimation = (signals, device) => {
        interval = setInterval(() => {
            currentSignalIndex++;
            setSliderValue(currentSignalIndex);

            moveDevice(signals, device);

        }, 1000);
    };
    
    const moveDevice = (signals, device) => {
        try {
            device['speed_km'] = signals[currentSignalIndex].speed_km;
            device['latest_geocode_sent_at'] = signals[currentSignalIndex].geocode_sent_at;
            device['name'] = device.name;
            device['total_km'] = (signals[currentSignalIndex].mileage - signals[0].mileage).toFixed(2);

            if (!marker) {
                // mapInstance.current.removeMarker(marker);
                marker = mapInstance.current.addMarker([signals[currentSignalIndex].dec_long, signals[currentSignalIndex].dec_lat], markerIcon, device, "anim_route_device");
            }

            let lastLongLat = [signals[currentSignalIndex-1].dec_long, signals[currentSignalIndex-1].dec_lat];
            let newLonglat = [signals[currentSignalIndex].dec_long, signals[currentSignalIndex].dec_lat];

            if (rotating_icons.indexOf(parseInt(device.type)) !== -1 ) {
                let angle = mapInstance.current.calculateAngle(lastLongLat, newLonglat);
                if (angle) {
                    mapInstance.current.setMarkerRotation(marker, angle);
                }
            }
            // mapInstance.current.setMarkerPosition(marker, newLonglat);
            mapInstance.current.setCenter(newLonglat);

            animate({
                duration: 1000,
                timing(timeFraction) {
                  return timeFraction;
                },
                prevLongLat: lastLongLat,
                nextLongLat: newLonglat,
                draw(progress, _lastLongLat, _newLonglat) {
                    
                    let result = (_newLonglat[0] - _lastLongLat[0]) * progress + _lastLongLat[0];
                    let result2 = (_newLonglat[1] - _lastLongLat[1]) * progress + _lastLongLat[1];
                    let coordinates = [result, result2];
                    
                    if (marker) {
                        mapInstance.current.setMarkerPosition(marker, coordinates);
                        let _text = ``;
                        _text += `${device['name']} \r\n`;
                        _text += ` ${device['latest_geocode_sent_at']}`;
                        _text += ` - (${device['speed_km']} km/h)`;
                        _text += `\r\n Total km: ${device['total_km']} km`;
                        device['dec_lat'] = _newLonglat[0];
                        device['dec_long'] = _newLonglat[1];

                        mapInstance.current.setMarkerText(marker, _text);
                        mapInstance.current.setMarkerProperties(marker, device);
                    }
                }
            });
        }
        catch {
            clearInterval(interval);
        }
    };

    const handleSliderChange = ({x}) => {
        setSliderValue(x);
        currentSignalIndex = x;
        moveDevice(props.signals, props.device);
    };

    const handlePlayPuase = () => {
        if (play === true) {
            playAnimation(props.signals, props.device);
        }
        else {
            clearInterval(interval);
        }
        setPlay(!play);
    };
    
    useEffect(() => {
        let signals = [];
        let first_start = false;
        let itemsProcessed = 0;

        if (mapInstance.current === null && props.match.params.deviceId) {
            mapInstance.current = new HMap(longlat);
            mapInstance.current.initMap(13, false, { measure: true, deviceId: props.match.params.deviceId });
        }

        let device = props.device;
        mapInstance.current.getVectorSource().clear();

        props.signals.forEach((element, index, array) => {
            itemsProcessed++;
            let coordinate = [element.dec_long, element.dec_lat];
            signals.push(coordinate);

            if (itemsProcessed % 8 === 0) {
                let arrowIcon = require(`../Assets/images/right2.png`).default;
                if (array[index+1]) {
                    let next_coordinate = [array[index+1].dec_long, array[index+1].dec_lat];
                    device['speed_km'] = element.speed_km;
                    device['dec_long'] = element.dec_long;
                    device['dec_lat'] = element.dec_lat;
                    device['latest_geocode_sent_at'] = element.geocode_sent_at;

                    let angle = mapInstance.current.calculateAngle(coordinate, next_coordinate);
                    mapInstance.current.addArrow(coordinate, arrowIcon, angle, {...device});
                }
            }

            if (element.signal_mode === 4) {
                device['dec_long'] = element.dec_long;
                device['dec_lat'] = element.dec_lat;
                device['latest_geocode_sent_at'] = element.geocode_sent_at;
                let stopIcon = require(`../Assets/images/stop.gif`).default;
                mapInstance.current.addStop(coordinate, stopIcon, {...device});
            }

            if (element.signal_mode === 3 && first_start === false) {
                device['dec_long'] = element.dec_long;
                device['dec_lat'] = element.dec_lat;
                device['latest_geocode_sent_at'] = element.geocode_sent_at;
                let startIcon = require(`../Assets/images/flag_green.gif`).default;
                mapInstance.current.addStop(coordinate, startIcon, {...device});
                first_start = true;
            }

            if (itemsProcessed === array.length) {
                let lineString = mapInstance.current.createLineString(signals);
                let featureLine = mapInstance.current.createLineFeature(5);
                featureLine.setGeometry(lineString);
                featureLine.getGeometry().transform('EPSG:4326', 'EPSG:3857');

                mapInstance.current.fitMap();
                updateSize();
            }
        });

        if (lastDeviceId !== device.device_id) {
            lastDeviceId = device.device_id;
            mapInstance.current.setLayer(device.device_id);
        }
        
        playAnimation(props.signals, props.device);
        clearInterval(interval);
        
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
            clearInterval(interval);
            currentSignalIndex = 0;
            setSliderValue(0);
            marker = null;
            first_start = false;
            setPlay(true);
        };
    }, [props.signals, props.device]);
        
    return (
        <>
            <IconTextWithFa style={{
                    position: 'absolute',
                    bottom: 37,
                    left: 15,
                    zIndex: 9999,
                    cursor: 'pointer'
                }} icon={play ? "play" : "pause"} onClick={handlePlayPuase} />
            <Slider
                axis="x"
                x={sliderValue}
                onChange={handleSliderChange}
                styles={{track: {
                        width: '94%', height: 30, position: 'absolute',
                        bottom: '30px', left: 50, zIndex: 9999,
                        backgroundColor: '#333', opacity: '0.8'
                    },
                    thumb: {width: 30, height: 30},
                    active: {
                        backgroundColor: '#abcf38'
                    }
                    }}
                xmin={0}
                xmax={props.signals ? props.signals.length : 0}
            />
            <div id="map" style={{ width: "100%", height: `${size[1]}px` }}></div>
            <div id="popup" className="ol-popup">
                <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                <div id="popup-content"></div>
            </div>
        </>
    );
}

export default AnimRouteMap;
