import BaseService from './BaseService';

class Users extends BaseService {

  getUserGroups = (order_by='group_name') => {
    return this.axiosInstance.get(`/user/groups/?order_by=${order_by}`, {cancelToken: this.cancelTokenSource.token});
  };

  getUserPreferences = () => {
    return this.axiosInstance.get(`/user_preferences/?`, {cancelToken: this.cancelTokenSource.token});
  };

  saveUserPreferences = (data) => {
    return this.axiosInstance.post(`/user_preferences/update/?`, data);
  };

  getUserFavouriteTrips() {
    return this.axiosInstance.get(`/user/trips_favorite/?`)
  };

  deleteUserFavouriteTrip(visited, endAddress) {
    return this.axiosInstance.get(`/user/trips_favorite/delete/?visited=${visited}&end_address=${endAddress}`)
  };

}

export default Users;