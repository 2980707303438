import BaseService from './BaseService';

class IvdcDevicesService extends BaseService {

  get(deviceId) {
    return this.axiosInstance.get(`/ivdc_devices/${deviceId}/?nocache=true`, {cancelToken: this.cancelTokenSource.token});
  }

  update(deviceId, data) {
    return this.axiosInstance.post(`/ivdc_device/update/${deviceId}/?`, data);
  }

}

export default IvdcDevicesService;