import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import {
  faUser, faClock, faGlobeAsia,
  faMobile, faSignOutAlt, faArrowAltCircleLeft,
  faArrowAltCircleRight, faPlay, faPause, faHandPointRight,
  faCar, faSearch, faEdit, faTimes
} from '@fortawesome/free-solid-svg-icons';

library.add(faUser, faClock, faGlobeAsia,
  faMobile, faSignOutAlt, faArrowAltCircleLeft,
  faArrowAltCircleRight, faPlay, faPause, faHandPointRight,
  faCar, faSearch, faEdit, faTimes);

function IconTextWithFa(props) {
  return (
    <React.Fragment>
      <FontAwesomeIcon {...props} icon={props.icon} /> <span>{props.text}</span>
    </React.Fragment>
  );
}

IconTextWithFa.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string
};

export default IconTextWithFa;