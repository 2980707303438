import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import UserComponent from "./userComponent";
import FavoriteOptionWindow from "./favoriteOptionWindow";
import MessageWindow from "../Commons/messageWindow";
import IconTextWithFa from "../Commons/iconTextWithFa";
import Loading from "../Commons/loading";

import UserInformation from "../../services/UserInformation";
import DeviceOptions from "../../services/DeviceOptions";

const initSelectedUserDevice = {
	device: {
		device_name: '',
		device_id: 0,
	},
	user_device: {
		user_device_name: '',
		edit_device_name: 0,
		favorite_name: '',
		user_favorite_device_options_id: 0,
	},
	user_device_options: [],
	toggle_all: false,
	save_all: false
};

function EditUser(props) {
	const userId = props.match.params.subUserId;
	const [isLoading, setIsLoading] = useState(true);
	const [userData, setUserData] = useState({
		user: {
			user_id: 0,
			user_first_name: '',
			user_last_name: '',
			user_name: '',
			user_email: '',
			user_pass: '',
			active: 0,
			users_added_at: null,
			active_until: null
		},
		userDevices: [],
		favoriteOptions: []
	});
	const [selectedUserDevice, setSelectedUserDevice] = useState({...initSelectedUserDevice});
	const [showFavoriteOption, setShowFavoriteOption] = useState({
		favorite: {
			user_favorite_device_options_id: 0,
			favorite_name: '',
			device_options: []
		},
		status: false
	});
	const [showMessage, setShowMessage] = useState({
		message: '',
		title: '',
		status: false
	});

	const userInformationService = new UserInformation();
	const deviceOptionsService = new DeviceOptions();

	const handleUserChange = (name, value) => {
		setUserData({
			...userData,
			user: {
				...userData.user,
				[name]: value
			}
		})
	};

	const handleUserSubmit = async (event) => {
		event.preventDefault();

		try{
			const sendData = {
				...userData.user,
				users_added_at : (userData.user.users_added_at) ? Moment(userData.user.users_added_at).format("YYYY-MM-DD 00:00:00") : userData.user.users_added_at,
				active_until: (userData.user.active_until) ? Moment(userData.user.active_until).format("YYYY-MM-DD 00:00:00") : userData.user.active_until
			};

			await userInformationService.editSubUser(userData.user.user_id, sendData)
				.then((response) => {
					if (response.data.result === 'success') {
						setShowMessage({
							status: true,
							title: "İşlem Başarılı",
							message: "Değişiklikler başarıyla kaydedildi."
						});

					} else {
						setShowMessage({
							status: true,
							title: "Hata",
							message: "Değişiklikler kaydedilemedi. Lütfen bilgileri gözden geçirip tekrar deneyiniz."
						});
					}
				});

		}catch (e) {}
	};

	const handleOptionsSubmit = async (event) => {
		event.preventDefault();

		try {
			const optionsSelected = [];
			selectedUserDevice.user_device_options.forEach((option) => {if (option.checked) optionsSelected.push(option.device_option_id)});

			const optionsData = {
				user_favorite_device_options_id: selectedUserDevice.user_device.user_favorite_device_options_id,
				save_all: selectedUserDevice.save_all ? 1 : 0,
				options: `[${optionsSelected.toString()}]`
			};

			const userDeviceData = {
				user_device_name: selectedUserDevice.user_device.user_device_name,
				edit_device_name: selectedUserDevice.user_device.edit_device_name
			};

			setIsLoading(true);

			await deviceOptionsService.updateSubUserDeviceOptions(selectedUserDevice.device.device_id, userId, optionsData)
				.then(async (optionResponse) => {
					if (optionResponse.data.result === 'success') {
						if (optionResponse.data.user_device_id > 0) {
							// save all option causes bug on bulk update because of the user_device_id value
							if (!selectedUserDevice.save_all) {
								await userInformationService.updateSubUserDevice(optionResponse.data.user_device_id, userDeviceData)
									.then((deviceResponse) => {
										setShowMessage({
											status: true,
											title: "İşlem Başarılı",
											message: <span><b>{selectedUserDevice.device.device_name}</b> aracınız başarıyla güncellendi.</span>
										});
									});

							} else {
								setShowMessage({
									status: true,
									title: "İşlem Başarılı",
									message: "Seçili opsiyonlar bütün araçlar için uygulandı."
								});
							}
						} else {
							setShowMessage({
								status: true,
								title: "İşlem Başarılı",
								message: "Araç kaldırıldı."
							});
						}

					} else {
						setShowMessage({
							status: true,
							title: "Hata",
							message: "Değişiklikler kaydedilemedi. Lütfen tekrar deneyiniz."
						});
					}

				})
				.finally(() => {
					setSelectedUserDevice({...initSelectedUserDevice});
					setIsLoading(false);
					fetchUserData();
				})

		} catch (e) {}
	};

	const handleDeviceSelect = async (device) => {
		try {
			await Promise.all([
				userInformationService.getSubUserDevice(userId, device.device_id),
				deviceOptionsService.getSubUserDeviceOptions(userId, device.device_id)
			])
				.then(([deviceResponse, optionsResponse]) => (
					setSelectedUserDevice({
						device: {
							device_name: device.name,
							device_id: device.device_id
						},
						user_device: {
							...deviceResponse.data,
							user_device_name: deviceResponse.data.user_device_name ? deviceResponse.data.user_device_name : device.name,
							edit_device_name: deviceResponse.data.edit_device_name ? deviceResponse.data.edit_device_name : 0,
							user_favorite_device_options_id: deviceResponse.data.user_favorite_device_options_id ? deviceResponse.data.user_favorite_device_options_id : 0
						},
						user_device_options: optionsResponse.data,
						toggle_all: false,
						save_all: false
					})
				));

		} catch (e) {}

	};

	const handleFavoriteSelect = async (event) => {
		event.preventDefault();

		try {
			const value = userData.favoriteOptions.find((favoriteOption) => favoriteOption.user_favorite_device_options_id.toString() === event.target.value.toString());
			const valueList = value.device_options.slice(1,-1).split(',');
			const optionsSelected = [];

			selectedUserDevice.user_device_options.forEach((option) => {
				const checked = valueList.includes(option.device_option_id.toString()) ? 1 : 0;
				optionsSelected.push({...option, checked: checked});
			});

			setSelectedUserDevice({
				...selectedUserDevice,
				user_device: {
					...selectedUserDevice.user_device,
					favorite_name: value.favorite_name,
					user_favorite_device_options_id: value.user_favorite_device_options_id
				},
				user_device_options: optionsSelected,
				toggle_all: false
			});

		} catch (e) {}
	};

	const handleOptionSelect = async (event, index) => {
		try {
			const optionsClone = Object.assign([], selectedUserDevice.user_device_options);
			optionsClone[index].checked = event.target.checked ? 1 : 0;

			setSelectedUserDevice({
				...selectedUserDevice,
				user_device: {
					...selectedUserDevice.user_device,
					favorite_name: '',
					user_favorite_device_options_id: 0
				},
				user_device_options: [...optionsClone],
				toggle_all: false
			});

		} catch (e) {}
	};

	const handleToggleAll = async (event) => {
		try {
			const toggle = event.target.checked ? 1 : 0;
			const toggled = selectedUserDevice.user_device_options.map((option) => {
				return {...option, checked: toggle};
			});

			setSelectedUserDevice({
				...selectedUserDevice,
				user_device: {
					...selectedUserDevice.user_device,
					favorite_name: '',
					user_favorite_device_options_id: 0
				},
				user_device_options: toggled,
				toggle_all: event.target.checked
			});

		} catch (e) {}
	};

	const favoriteDetails = async (event) => {
		event.preventDefault();

		let favorite;

		if (selectedUserDevice.user_device.user_favorite_device_options_id > 0) {
			favorite = {
				user_favorite_device_options_id: selectedUserDevice.user_device.user_favorite_device_options_id,
				favorite_name: selectedUserDevice.user_device.favorite_name,
				device_options: selectedUserDevice.user_device_options
			};

		} else {
			favorite = {
				user_favorite_device_options_id: 0,
				favorite_name: 'Yeni Favori Opsiyon',
				device_options: selectedUserDevice.user_device_options.map((option) => {
					return {...option, checked: 0};
				})
			}
		}

		setShowFavoriteOption({
			status: true,
			favorite: favorite
		});

	};

	const fetchUserData = async () => {
		try {
			setIsLoading(true);

			await Promise.all([
				userInformationService.getSubUser(userId),
				deviceOptionsService.getFavoriteOptions()
			])
				.then(([userResponse, optionsResponse]) => {
					setUserData({
						user: {
							user_id: userResponse.data.user_id,
							user_first_name: userResponse.data.user_first_name,
							user_last_name: userResponse.data.user_last_name,
							user_name: userResponse.data.user_name,
							user_email: userResponse.data.user_email,
							user_pass: userResponse.data.user_pass,
							active: userResponse.data.active,
							users_added_at: new Date(userResponse.data.users_added_at),
							active_until: new Date(userResponse.data.active_until)
						},
						userDevices: userResponse.data.devices,
						favoriteOptions: optionsResponse.data
					});
				})
				.finally(() => setIsLoading(false));

		} catch (error) {}

	};

	useEffect(() => {
		fetchUserData();

	}, [userId]);

	return (
		<div className='container-fluid mt-2 size-lg'>
			{isLoading ? (<Loading/>) : null}
			<button className="btn btn-danger" onClick={() => props.history.goBack()}>
				<IconTextWithFa icon="arrow-alt-circle-left"/>
				<FormattedMessage id='GENERAL.TURN_BACK'/>
			</button>
			<br/><br/>
			<div className='row'>
				<div className="col-md-3">
					<UserComponent
						user={userData.user}
						title={<FormattedMessage id='USER_RIGHTS.UPDATE_USER'/>}
						isValid={{user_name: true, user_email: true}}
						handleUserChange={handleUserChange}
						handleSubmit={handleUserSubmit}
					/>
				</div>
				<div className="col-md-4">
					<h4><FormattedMessage id='GENERAL.DEVICES'/></h4>
					<div style={{marginBottom: '2rem'}}/>
					<table className="table table-bordered table-hover">
						<thead>
							<tr>
								<th scope="col"><FormattedMessage id='GENERAL.DEVICES'/></th>
								<th scope="col"><FormattedMessage id='USER_RIGHTS.FAVORITE_FUNC'/></th>
							</tr>
						</thead>
						<tbody>
						{
							props.devices.map((device) => {
								const userDevice = userData.userDevices.find((_userDevice) => _userDevice.device_id === device.device_id);
								const favoriteName = userDevice ? userDevice.favorite_name : null;

								return (
									<tr key={device.device_id} onClick={() => handleDeviceSelect(device)}
											className={`${device.device_id === selectedUserDevice.device.device_id ? 'active-user-device' : ''}`}>
										<td style={{cursor: "pointer"}}>
											{device.name}
											&nbsp;
											{
												userDevice ? <span style={{color: "#419641"}}><i className="fa fa-check"/></span> : null
											}
										</td>
										<td>{favoriteName}</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
				{
					selectedUserDevice.device.device_id > 0
						?
							<div className="col-md-4">
								<h4><FormattedMessage id='HOME.OPTIONS'/></h4>
								<br/>
								<h5>{selectedUserDevice.device.device_name}</h5>
								<hr/>
								<form onSubmit={handleOptionsSubmit}>
									<div style={{marginBottom: '1rem'}}>
										<label><b><FormattedMessage id='USER_RIGHTS.SUBUSER_DEVICE_NAME'/></b></label>
										<input type="text"
													 className='form-control'
													 value={selectedUserDevice.user_device.user_device_name}
													 onChange={(event) =>
														 setSelectedUserDevice({
															 ...selectedUserDevice,
															 user_device: {...selectedUserDevice.user_device, user_device_name: event.target.value}
														 })}
													 required
										/>
									</div>
									<div className='form-check'>
										<input checked={selectedUserDevice.user_device.edit_device_name === 1}
													 onChange={(event) => setSelectedUserDevice({
														 ...selectedUserDevice,
														 user_device: {...selectedUserDevice.user_device, edit_device_name: event.target.checked ? 1 : 0}
													 })}
													 type="checkbox"
													 className="form-check-input" />
										<label className="form-check-label" ><FormattedMessage id='USER_RIGHTS.EDIT_DEVICE_NAME'/></label>
									</div>
									<hr/>
									<div className='form-check' style={{marginBottom: '1rem', float: 'right'}}>
										<input checked={selectedUserDevice.toggle_all}
													 onChange={handleToggleAll}
													 type="checkbox"
													 className="form-check-input" />
										<label className="form-check-label" ><b><FormattedMessage id='GENERAL.TOGGLEALL'/></b></label>
									</div>
									<h6><FormattedMessage id='USER_RIGHTS.ADD_REMOVE_USER_RIGHT'/></h6>
									<div className="input-group">
										<select className="custom-select" onChange={handleFavoriteSelect}
														value={selectedUserDevice.user_device.user_favorite_device_options_id}>
											<FormattedMessage id='USER_RIGHTS.SELECT_FAVORITE'>
												{(message) => <option value={0}>{message}</option>}
											</FormattedMessage>
											{
												userData.favoriteOptions.map((option, index) => (
													<option key={index} value={option.user_favorite_device_options_id}>
														{option.favorite_name}
													</option>
													))
											}
										</select>
										<button className="btn btn-primary ml-1" onClick={favoriteDetails}>
											<FormattedMessage id='USER_RIGHTS.FAVORITE_FUNC'/>
										</button>
									</div>
									<div style={{marginBottom: '1rem'}}/>
									<ul className='list-group sap-options-list'>
										{
											selectedUserDevice.user_device_options.map((option, index) => (
												<li key={index} className='list-group-item'>
													<input checked={option.checked}
																 onChange={(event) => handleOptionSelect(event, index)}
																 type="checkbox"
																 className="form-check-input"
																 style={{marginLeft: '-0.25rem'}}/>
													<label className="form-check-label" style={{marginLeft: '1rem'}}>
														<FormattedMessage id={`USER_RIGHTS.${option.sap_id}`}/>
													</label>
												</li>
											))
										}
									</ul>
									<div className='form-check'>
										<input checked={selectedUserDevice.save_all}
													 onChange={(event) => setSelectedUserDevice({...selectedUserDevice, save_all: event.target.checked})}
													 type="checkbox"
													 className="form-check-input" />
										<label className="form-check-label" ><FormattedMessage id='USER_RIGHTS.SAVEALL'/></label>
									</div>
									<div className="form-group mt-2">
										<button className="btn btn-block btn-success" type="submit">
											<FormattedMessage id='GENERAL.SAVE'/>
										</button>
									</div>
								</form>
							</div>
						:
							null
				}
			</div>
			<FavoriteOptionWindow
				show={showFavoriteOption.status}
				onHide={() => {
					setShowFavoriteOption({...showFavoriteOption, status: false});
					fetchUserData();
				}}
				favorite={showFavoriteOption.favorite}
			/>
			<MessageWindow
				show={showMessage.status}
				title={showMessage.title}
				onHide={() => {setShowMessage({message: '', title: '', status: false})}}
				message={showMessage.message}
			/>
		</div>
	);
}

const mapStateToProps = function (state) {
	return {
		devices: state.deviceListReducer.devices
	}
};

export default connect(mapStateToProps)(EditUser);