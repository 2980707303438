import {combineReducers} from "redux";
import {
  UPDATE_USER_INFORMATION,
  UPDATE_USER_GROUPS,
  UPDATE_DEVICE_LIST,
  UPDATE_DEVICE_LIST_WITH_GROUPS,
  UPDATE_DEVICE_LIST_TYPE,
  UPDATE_DEVICE_SORT_TYPE,
  UPDATE_DEVICE_GROUP_SORT,
  UPDATE_SEARCH_CONTENT,
  UPDATE_DRIVER_LIST} from '../actions';


const defaultUserInformation = {
  user: {
    devices_on_display: 5,
    language: 2
  }
};

function userInformationReducer(state, action) {

  if (typeof state === 'undefined') {
    return defaultUserInformation
  }

  switch (action.type) {
    case UPDATE_USER_INFORMATION:
      return action.user;

    default:
      return state;
  }
}


const defaultUserGroups = {
  userGroups: []
};

function userGroupsReducer(state, action) {

  if (typeof state === 'undefined') {
    return defaultUserGroups
  }

  switch (action.type) {
    case UPDATE_USER_GROUPS:
      return action.userGroups;

    default:
      return state;
  }
}


const defaultDeviceList = {
  devices: [],
  totalCount: 0
};

function deviceListReducer(state, action) {

  if (typeof state === 'undefined') {
    return defaultDeviceList
  }

  switch (action.type) {
    case UPDATE_DEVICE_LIST:
      return action;

    default:
      return state;
  }
}


const defaultDeviceListWithGroups = {
  devicesWithGroups: []
};

function deviceListWithGroupsReducer(state, action) {

  if (typeof state === 'undefined') {
    return defaultDeviceListWithGroups
  }

  switch (action.type) {
    case UPDATE_DEVICE_LIST_WITH_GROUPS:
      return action.devicesWithGroups;

    default:
      return state;
  }
}


const defaultDeviceListType = {
  listType: (localStorage.getItem('listType') === null) ? 'standart' : (localStorage.getItem('listType'))
};

function deviceListTypeReducer(state, action) {

  if (typeof state === 'undefined') {
    return defaultDeviceListType
  }

  switch (action.type) {
    case UPDATE_DEVICE_LIST_TYPE:
      return action.listType;

    default:
      return state;
  }
}


const defaultSortType = {
  sortType: (localStorage.getItem('sortType') === null) ? 'name' : (localStorage.getItem('sortType'))
};

function deviceSortTypeReducer(state, action) {

  if (typeof state === 'undefined') {
    return defaultSortType
  }

  switch (action.type) {
    case UPDATE_DEVICE_SORT_TYPE:
      return action.sortType;

    default:
      return state;
  }
}


const defaultGroupSort = {
  groupSort: (localStorage.getItem('groupSort') === null) ? true : ((localStorage.getItem('groupSort')) === 'true')
};

function deviceGroupSortReducer(state, action) {

  if (typeof state === 'undefined') {
    return defaultGroupSort
  }

  switch (action.type) {
    case UPDATE_DEVICE_GROUP_SORT:
      return action.groupSort;

    default:
      return state;
  }
}

const defaultSearchContent = {
  contents: []
};

function deviceSearchContent(state, action) {

  if (typeof state === 'undefined') {
    return defaultSearchContent
  }

  switch (action.type) {
    case UPDATE_SEARCH_CONTENT:
      return action.contents;

    default:
      return state;
  }
}

const defaultDriverList = {
  drivers: []
};

function driverListReducer(state, action) {

  if (typeof state === 'undefined') {
    return defaultDriverList
  }

  switch (action.type) {
    case UPDATE_DRIVER_LIST:
      return action.drivers;

    default:
      return state;
  }
}


export default combineReducers({
  userInformationReducer,
  userGroupsReducer,
  deviceListReducer,
  deviceListWithGroupsReducer,
  deviceListTypeReducer,
  deviceSortTypeReducer,
  deviceGroupSortReducer,
  deviceSearchContent,
  driverListReducer
});