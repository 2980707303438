import './index.css';

import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {Col, Row, Form} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import DateFilter from '../Commons/dateFilter';
import HourChart from '../HourChart';

import TrafficOn from '../Assets/images/traffic_on.gif';
import TrafficOff from '../Assets/images/traffic_off.gif';

import Reports from '../../services/Reports';

function Routes(props) {

  const [size, setSize] = useState([window.innerWidth - 200, 50]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hours, setHours] = useState([]);
  const [waitingTimes, setWaitingTimes] = useState([]);
  const [durationTimes, setDurationTimes] = useState([]);
  const [privateKm, setPrivateKm] = useState([]);
  const reportService = new Reports();
  const [devices, setDevices] = useState(() => props.devices);
  
  const handleChangeDate = date => {
    setSelectedDate(date);
    setHours([]);
  };

  const handleChangeGroup = event => {
    if (event.target.value)
      setDevices(props.devices.filter(device => device.groups.includes(event.target.value) ));
    else
      setDevices(props.devices);    
  };

  const updateSize = () => {
    setSize([window.innerWidth - 200, 50]);
  };

  const getTotal = (values) => {
    let total = 0;
    values.forEach((item) => {
      total += item.value
    });

    return total;
  };

  const fetchData = () => {
    devices.forEach(async (item) => {
      let response = await reportService.getDeviceDailyTrips(item.device_id,
        moment(selectedDate).format('YYYY-MM-DD 00:00:00'),
        moment(selectedDate).format('YYYY-MM-DD 23:59:59'));
        response.data.forEach((trip) => {
          setHours(hours => [...hours, {device_id: item.device_id,
            startDate: new Date(trip.start_time),
            endDate: new Date(trip.end_time),
            selectedDate: selectedDate}]);
          
          setWaitingTimes(waitingTimes => [...waitingTimes, {
            device_id: item.device_id,
            value: trip.waiting_time_sec}]);
          
          setDurationTimes(durationTimes => [...durationTimes, {
            device_id: item.device_id,
            value: trip.duration_sec}]);
          
          setPrivateKm(privateKm => [...privateKm, {
            device_id: item.device_id,
            value: trip.lenght_km}]);
        });
    });
  };

  useEffect(() => {
    fetchData();
    
    window.addEventListener('resize', updateSize);
    
    return () => {
      window.removeEventListener('resize', updateSize);
    };

  }, [devices, selectedDate]);
  
  return (
    <>
      <h3><FormattedMessage id="GENERAL.FILTER_OPTIONS"/></h3>
      <Row>
        <Col md={6}>
          <b><FormattedMessage id="ROUTES.SHOW_ROUTES"/></b><br />
          <DateFilter wrapperClassName="react-datepicker-wrapper-full-width" arrows={false} onChange={handleChangeDate} />
        </Col>
        <Col md={6}>
          <b><FormattedMessage id="ROUTES.VEHICLE_GROUP"/></b><br />
          <Form.Control onChange={handleChangeGroup} as="select">
            <FormattedMessage id="ROUTES.ALL_VEHICLE_GROUP">
              {(message) => <option key="-1" value="">{message}</option>}
            </FormattedMessage>
            {props.userGroups.map((item, index) => {
              return <option key={index} value={item.group_name}>{item.group_name}</option>;
            })}
          </Form.Control>
        </Col>
      </Row>
      {devices.map((device, index) => {
          return (
            <Row key={index} style={{marginTop: 10}}>
              <Col md={12}>
                <div className="route-table">
                  <table>
                    <tbody>
                      <tr style={{height: 25}}>
                        <td style={{width: 50, backgroundColor: '#dad9d9'}}>
                          <img src={device.ignition_status == "on" ? TrafficOn : TrafficOff} />
                        </td>
                        <td style={{minWidth: 140, width: 140, backgroundColor: '#dad9d9'}}><FormattedMessage id="ROUTES.RESPITE"/>: </td>
                        <td style={{minWidth: 150, backgroundColor: '#5068ab', color: 'white'}}><FormattedMessage id="ROUTES.VEHICLE"/>: {device.name} </td>
                        <td style={{minWidth: 300, width: 300, backgroundColor: '#8E99B9', color: 'white'}} >
                          {
                            device.groups.map((group, index) => {
                              return <span key={index}>{group}, </span>
                            })
                          }
                        </td>
                        <td style={{width: 400, backgroundColor: '#BECDF7'}}>
                          <FormattedMessage id="ROUTES.TRIP"/>: 
                          {
                            moment.unix(
                              getTotal(durationTimes.filter(element => element.device_id === device.device_id)))
                              .utc().format('H:mm:ss')
                          }
                        </td>
                        <td style={{width: 150, backgroundColor: '#384978', color: 'white'}}>
                          <FormattedMessage id="ROUTES.DAILY"/>: {getTotal(privateKm.filter(element => element.device_id === device.device_id)).toFixed(2)} km
                        </td>
                      </tr>
                      <tr style={{height: 20}}>
                        <td style={{backgroundColor: '#edecec'}}></td>
                        <td style={{backgroundColor: '#edecec'}}><FormattedMessage id="ROUTES.WAITING"/>: 
                        {
                          moment.unix(
                            getTotal(waitingTimes.filter(element => element.device_id === device.device_id)))
                            .utc().format('H:mm:ss')
                        }
                        </td>
                        <td style={{padding: 0}} colSpan={4} rowSpan={3} valign="top">
                          <HourChart color={false} size={size}
                          hours={hours.filter(element => element.device_id === device.device_id)} />
                        </td>
                      </tr>
                      <tr style={{height: 30}}>
                        <td style={{backgroundColor: '#dad9d9'}}>
                          <img src={require(`../Assets/images/device-icons/car_${device.type}.gif`).default} />
                        </td>
                        <td style={{backgroundColor: '#dad9d9'}} colSpan={5} ><FormattedMessage id="ROUTES.FOR_MISSION"/> 0.0 km</td>
                      </tr>
                      <tr style={{height: 20}}>
                        <td style={{backgroundColor: '#edecec'}}></td>
                        <td style={{backgroundColor: '#edecec'}} colSpan={5} >
                          <FormattedMessage id="ROUTES.PRIVATE"/>: {getTotal(privateKm.filter(element => element.device_id === device.device_id)).toFixed(2)} km
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          );
        })}
    </>
  );
}

const mapStateToProps = function(state) {
  return {
    userGroups: state.userGroupsReducer.userGroups,
    devices: state.deviceListReducer.devices
  }
};

export default connect(mapStateToProps)(Routes);
