import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import Moment from 'moment';
import {printData} from '../../Assets/utils';
import MessageWindow from "../../Commons/messageWindow";
import '../index.css';

import DeviceService from '../../../services/Devices';
import DriverService from '../../../services/Drivers';
import ReportService from '../../../services/Reports';

let today = new Date();
let years=[
  {id:0, year: today.getFullYear()-2},
  {id:1, year: today.getFullYear()-1},
  {id:2, year: today.getFullYear()}];

function Menu(props) {

  let start_date = new Date();
  let end_date = new Date();

  // set first and last days of the month
  if (props.mainReportType === 'monthly') {
    start_date = new Date(start_date.getFullYear(), start_date.getMonth(), 1, 0, 0, 0);
    end_date = new Date(end_date.getFullYear(), end_date.getMonth() + 1, 0, 0, 0, 0);

  } else {
    start_date.setHours(0, 0, 0);
    end_date.setHours(23, 59, 0);

  }

  const reportType = props.routerParams.url.includes('device') ? 'device' :  'driver';

  const deviceService = new DeviceService();
  const driverService = new DriverService();
  const reportService = new ReportService();

  const [selectedDateStart, setSelectedDateStart] = useState(start_date);
  const [selectedDateEnd, setSelectedDateEnd] = useState(end_date);
  const [selectedVehicleName, setSelectedVehicleName] = useState('');
  const [selectedDriverName, setselectedDriverName] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState({value:props.deviceId,label:''});
  const [selectedDriver, setSelectedDriver] = useState({value:props.driverId,label:''});
  const [selectedYear, setSelectedYear] = useState('');
  const [yearlyTrip, setYearlyTrip] = useState([]);
  const [yearlyTotal, setYearlyTotal] = useState({});
  const [selectedFavorite, setSelectedFavorite] = useState({});
  const [userId, setUserId] = useState('');
  const [showMessage, setShowMessage] = useState({
    message: '',
    title: '',
    status: false
  });

  const getDeviceDetails = async () => {
    try {
      if (selectedVehicle.value > 0) {
        await deviceService.getDevice(selectedVehicle.value)
          .then((response) => {
            setSelectedVehicleName(response.data.name);
            setSelectedVehicle({value:selectedVehicle.value, label:response.data.name});
            // get initial data
            props.onFilter(response.data.name,selectedVehicle.value,
              Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss"),
              Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss"))
          });

      } else {
        setShowMessage({
          title: <h5><FormattedMessage id="GENERAL.WARNING"/></h5>,
          message: <h5><FormattedMessage id="GENERAL.PLS_PICK_DEVICE"/></h5>,
          status: true
        })
      }

    } catch (err) {
      setShowMessage({
        title: <h5><FormattedMessage id="GENERAL.ERROR"/></h5>,
        message: <h5><FormattedMessage id="GENERAL.ERROR"/></h5>,
        status: true
      })
    }
  };

  const getDriverDetails = async () => {
    try {
      if (selectedDriver.value > 0) {
        await driverService.getDriver(selectedDriver.value)
          .then((response) => {
            setselectedDriverName(response.data.driver_first_name + ' ' + response.data.driver_last_name);
            setSelectedDriver({value:selectedDriver.value, label:response.data.driver_first_name + ' ' + response.data.driver_last_name})
            // get initial data
            props.onFilter(response.data.driver_first_name, selectedDriver.value,
              Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss"),
              Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss"))
          });

      } else {
        setShowMessage({
          title: <h5><FormattedMessage id="GENERAL.WARNING"/></h5>,
          message: <h5><FormattedMessage id="GENERAL.PLS_PICK_DEVICE"/></h5>,
          status: true
        })
      }

    } catch (err) {
      setShowMessage({
        title: <h5><FormattedMessage id="GENERAL.ERROR"/></h5>,
        message: <h5><FormattedMessage id="GENERAL.ERROR"/></h5>,
        status: true
      })
    }
  };

  const handleYearlyReport = (event) =>{
    getYearlyReports(event.target.value);
  };

  const getYearlyReports = async(selectedYear) => {
    setSelectedYear(selectedYear);
    var total = {}, startDate, endDate;
    if (selectedYear !== "") {
        startDate = selectedYear + '-01-01 00:00:00';
        endDate = selectedYear + '-12-31 23:59:59';
        if (reportType === 'device') { // get device reports
          try {
            await reportService.getDeviceYearlyReport(selectedVehicle.value, startDate, endDate)
              .then(async(response) => {
                  for (var i = 0; i < response.data.length; i++) {
                    response.data[i]['month_text'] = 'MONTHS_NUMERIC.MONTH_' + response.data[i]['month'];
                  }
                  setYearlyTrip(response.data);
                  total = calculateYearlyReportTotal(response.data);
                  setYearlyTotal(total);
                });
            } catch (error) {
          }
        } 
        else if (reportType == 'driver') { // get driver reports
          try {
            await reportService.getDriverYearlyReport(selectedDriver.value, startDate, endDate)
              .then(async(response) => {
                  for (var i = 0; i < response.data.length; i++) {
                    response.data[i]['month_text'] = 'MONTHS_NUMERIC.MONTH_' + response.data[i]['month'];
                  }
                  setYearlyTrip(response.data);
                  total = calculateYearlyReportTotal(response.data);
                  setYearlyTotal(total);
                });
            } catch (error) {
          }
        }
    }
  };

  const calculateYearlyReportTotal = (data) => {
    let total = {}; 
    let total_km = 0; 
    let total_office_km = 0; 
    let total_private_km = 0; 
    let total_home_km = 0 ;
    for (var i = 0; i < data.length; i++){
      total_home_km += data[i]['total_home_km'];
      total_private_km += data[i]['total_private_km'];
      total_office_km += data[i]['total_office_km'];
      total_km += data[i]['total_km'];
    }   
    // calculate total km
    total.total_home_km = parseInt(total_home_km);
    total.total_private_km = parseInt(total_private_km);
    total.total_office_km = parseInt(total_office_km);
    total.total_km = parseInt(total_km);
    // calculate total ratio
    total.home_km_ratio = (100 * total_home_km) / total_km;
    total.private_km_ratio = (100 * total_private_km) / total_km;
    total.office_km_ratio = (100 * total_office_km) / total_km;
    return total;
  };

  const handleFilter = () => {
    props.onFilter(reportType === 'device' ? selectedVehicleName : selectedDriverName,
      (reportType === 'device') ? selectedVehicle.value : selectedDriver.value,
      Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss"),
      Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss"))
  };

  const handleSelectVehicle = (selectedOption) => {
    setSelectedVehicle(selectedOption);
    props.history.push(`/electronic-reports/${props.mainReportType}/${reportType}/${selectedOption.value}`);
  };

  const handleSelectDriver = (selectedOption) => {
    setSelectedDriver(selectedOption);
    props.history.push(`/electronic-reports/${props.mainReportType}/${reportType}/${selectedOption.value}`);
  };

  useEffect(() => {

    if (reportType === 'driver') {
      getDriverDetails();
    } else {
      getDeviceDetails();
    }

    setUserId(sessionStorage.getItem('userId'));

  }, [selectedVehicle.value, selectedDriver.value, props.manuel_trip]);
  

  const DeviceSelect = () => {

    return (
      <>
        <div className="col-md-2 col-sm-4 col-xs-11 select">
          <p className="mb-0"><b><FormattedMessage id="SERVICE.VEHICLE_NAME"/></b></p>
            <Select
              value={selectedVehicle}
              onChange={handleSelectVehicle}
              placeholder={<FormattedMessage id="GENERAL.PICK_DEVICE"/>}
              options={props.devices.map((device) => {
                return {value: device.device_id, label: device.name}
              })}
            />
        </div>
        <div className="pull-left">
          {
            (props.mainReportType !== 'daily') ?
              <p className="report-icon">
                <Link to={`/electronic-reports/daily/device/${selectedVehicle.value}`}>
                  <img src={require("../images/daily-reports.gif").default} alt='daily-reports'/>
                  <br/>
                    <FormattedMessage id="REPORTS.DAILY"/>
                </Link>
              </p> : null
          }
          {
            props.mainReportType !== 'monthly' ?
              <p className="report-icon">
                <Link to={`/electronic-reports/monthly/device/${selectedVehicle.value}`}>
                  <img src={require("../images/montly-reports.gif").default} alt='monthly-reports'/>
                  <br/>
                    <FormattedMessage id="REPORTS.MONTHLY"/>
                </Link>
              </p> : null
          }
          {
            props.mainReportType !== 'route' ?
              <p className="report-icon">
                <Link to={`/electronic-reports/route/device/${selectedVehicle.value}`}>
                  <img src={require("../images/route-reports.gif").default} alt='route-reports'/>
                  <br/>
                    <FormattedMessage id="REPORTS.ROUTE"/>
                </Link>
              </p> : null
          }
            <p className="report-icon">
                <a href={`https://gps-takip-sistemi.com/driver-api/qr/${userId}`} target="_blank">
                    <img src={require("../images/qr-code.png").default} alt='route-reports'/>
                    <br/>
                    QR
                </a>
            </p>
          {
            props.mainReportType !== 'drivers' ?
              <p className="report-icon">
                <a href="#"><img src={require("../images/driver-reports.gif").default} alt='driver-reports'/><br/><FormattedMessage id="MENU.DRIVERS_LIST"/></a>
              </p> : null
          }
        </div>
        <div className="clearfix"/>
      </>
    );
  };

  const DriverSelect = () => {

    return (
      <>
        <div className="col-md-2 col-sm-4 col-xs-11 select">
          <p className="mb-0"><b><FormattedMessage id="GENERAL.PICK_DRIVER"/></b></p>
            <Select
                value={selectedDriver}
                onChange={handleSelectDriver}
                placeholder={<FormattedMessage id="GENERAL.DRIVERS"/>}
                options={props.drivers.map((driver) => {
                  return {value: driver.driver_id, label: (driver.driver_first_name + " " + driver.driver_last_name)}
                })}
            />
        </div>
        <div className="pull-left">
          {
            props.mainReportType !== 'daily' ?
              <p className="report-icon">
                <Link to={`/electronic-reports/daily/driver/${selectedDriver.value}`}>
                  <img src={require("../images/daily-reports.gif").default} alt='daily-reports'/>
                  <br/>
                  <FormattedMessage id="REPORTS.DAILY"/>
                </Link>
              </p> : null
          }
          {
            props.mainReportType !== 'monthly' ?
              <p className="report-icon">
               <Link to={`/electronic-reports/monthly/driver/${selectedDriver.value}`}>
                  <img src={require("../images/montly-reports.gif").default} alt='monthly-reports'/>
                  <br/>
                  <FormattedMessage id="REPORTS.MONTHLY"/>
                </Link>
              </p> : null
          }
          {
            props.mainReportType !== 'drivers' ?
              <p className="report-icon">
                <a href="#">
                  <img src={require("../images/driver-reports.gif").default} alt='driver-reports'/>
                  <br/>
                  <FormattedMessage id="MENU.DRIVERS_LIST"/>
                </a>
              </p> : null
          }
        </div>
        <div className="clearfix"/>
      </>
    );
  };

  return (
    <>
      <h5 className='pt-2'>
        {
          reportType === 'device' ?
          <span> <FormattedMessage id="REPORTS.DEVICE_REPORTS"/> >&nbsp;
              {props.mainReportType === 'daily' ?  <FormattedMessage id="REPORTS.DAILY"/> : props.mainReportType === 'monthly' ?  <FormattedMessage id="REPORTS.MONTHLY"/> : props.mainReportType === 'drivers' ?  <FormattedMessage id="DRIVERS.ASSIGN_DRIVER"/> : <FormattedMessage id="REPORTS.ROUTE"/> }
          </span>
            : <span> <FormattedMessage id="REPORTS.DRIVER_REPORTS"/> >&nbsp;
              {props.mainReportType === 'daily' ?   <FormattedMessage id="REPORTS.DAILY"/> : <FormattedMessage id="REPORTS.MONTHLY"/>}
            </span>
        }
      </h5>
      <hr/>
      {
        (reportType === 'device') ? <DeviceSelect/> : <DriverSelect/>
      }
      <hr/>
      {
      props.mainReportType !== 'drivers' ? 
        <div className="col-md-12">
          <div className="row">
            {
              props.mainReportType === 'daily' ?
                <div className="col-md-2 form-group filters">
                  <p className="mb-0"><b><FormattedMessage id="GENERAL.FILTER_OPTIONS"/></b></p>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" onChange={(e) => props.handleChangeOnlyPrivate(e)}/>
                    <label className="form-check-label" ><FormattedMessage id="REPORTS.ONLY_PRIVATE"/></label>
                  </div>
                  {
                    reportType === 'device' ?
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={(e) => props.handleChangeReportswithDrivers(e)}/>
                        <label className="form-check-label" ><FormattedMessage id="REPORTS.WITH_DRIVERS"/></label>
                      </div> : null
                  }
                  {
                    reportType === 'driver' ?
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={(e) => props.handleChangeShowDeviceName(e)}/>
                        <label className="form-check-label" ><FormattedMessage id="REPORTS.SHOW_DEVICE_NAME"/></label>
                      </div> : null
                  }
                </div>
              :
                props.mainReportType === 'route' ?
                  <div className="col-md-2 form-group filters">
                    <p className="mb-0"><b><FormattedMessage id="GENERAL.FILTER_OPTIONS"/></b></p>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" onChange={(e) => props.handleChangeOnlyService(e)}/>
                      <label className="form-check-label" ><FormattedMessage id="REPORTS.SHOW_SERVICE_TRIPS"/></label>
                    </div>
                  </div>
                :
                  <div className="col-md-2 form-group filters">
                    <p className="mb-0"><b><FormattedMessage id="GENERAL.FILTER_OPTIONS"/></b></p>
                  </div>
            }
            <div className="col-md-2">
              <DatePicker
                className="mb-1"
                todayButton="Today"
                maxDate={new Date()}
                dateFormat="dd-MM-yyyy HH:mm"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                timeCaption="time"
                selected={selectedDateStart}
                onChange={(date) => setSelectedDateStart(date)}/>
              <DatePicker
                todayButton="Today"
                maxDate={new Date()}
                dateFormat="dd-MM-yyyy HH:mm"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                timeCaption="time"
                selected={selectedDateEnd}
                onChange={(date) => setSelectedDateEnd(date)}/>
            </div>
            <div className="pull-left no-padding-left col-md-1">
              <div className="form-group">
                <button className="btn btn-primary btn-sm btn-block"
                        onClick={() => handleFilter()}>
                  <FormattedMessage id="GENERAL.FILTER"/>
                </button>
                <button className="btn btn-warning btn-sm btn-block"
                        onClick={() => printData(props.mainReportType + '-trips')}>
                  <FormattedMessage id="GENERAL.PRINT"/>
                </button>
              </div>
            </div>
            <div className="col-md-2 col-xs-12 form-group">
              <p className="report-icon" style={{cursor: 'pointer'}}
                 onClick={() => props.handleExport(selectedDateStart, selectedDateEnd, 'xlsx')}>
                <img src={require("../images/excel-icon.png").default} width="40" alt='excel-icon'/>
                <br/>
                  <a>
                    <FormattedMessage id="GENERAL.EXPORT_EXCEL"/>
                  </a>
              </p>
              <p className="report-icon" style={{cursor: 'pointer'}}
                 onClick={() => props.handleExport(selectedDateStart, selectedDateEnd, 'pdf')}>
                <img src={require("../images/pdf-icon.png").default} width="40" alt='pdf-icon'/>
                <br/>
                  <a>
                    <FormattedMessage id="GENERAL.EXPORT_PDF"/>
                  </a>
              </p>
            </div>
            {
              props.mainReportType === 'monthly' ?
                <div>
                  <p><b><FormattedMessage id="REPORTS.YEARLY_PDF"/></b></p>
                  <div className="form-group form-inline">
                    <select className="form-control select-year" onChange={(event) => handleYearlyReport(event)}> 
                      <FormattedMessage id="REPORTS.SELECT_YEAR">
                          {(message) => <option key={0} value={-1}>{message}</option>}
                      </FormattedMessage>
                      {
                        years.map((year, index) => {
                          return <option key={index} value={year.year}>{year.year}</option>
                        })
                      }
                    </select>
                    &nbsp;
                    <div className="form-group form-inline">
                      <p style={{cursor: 'pointer'}}
                         onClick={() => props.handleExport(selectedDateStart, selectedDateEnd, 'pdf', true)}>
                        <img src={require("../images/pdf-icon.png").default} width="20" alt='pdf-icon'/>
                        &nbsp;
                        <a>
                          <FormattedMessage id="REPORTS.YEARLY"/>
                        </a>
                      </p>
                    </div>
                  </div>
                </div> : null
          }
          {
            props.mainReportType === 'route' ?
              <div className="col-md-3 col-xs-12">
                <label className="p-0">
                  <small><strong><FormattedMessage id="REPORTS.FAVORITES"/></strong>&nbsp;<span className="fa fa-info-circle"/></small>
                </label>
                <div className="row">
                  <div className="col-md-7">
                    <select className="form-control form-control-sm" name='favorites' value={JSON.stringify(selectedFavorite)}
                            onChange={(event) => setSelectedFavorite(JSON.parse(event.target.value))}>
                      <FormattedMessage id="REPORTS.PICK_FAV">
                        {(message) => <option value='' disabled>{message}</option>}
                      </FormattedMessage>
                      {
                        props.userFavorites.map((favorite, index) => {
                          return (
                            <option key={index} value={JSON.stringify(favorite)}>
                              {favorite.visited}
                            </option>
                          )
                        })
                      }
                    </select>
                  </div>
                  <div className="col-md-5">
                    <button className='btn btn-secondary btn-sm btn-block' onClick={() => props.handleFavoriteDetails(selectedFavorite)}><FormattedMessage id="REPORTS.FAV_DETAIL"/></button>
                  </div>
                </div>
              </div>
            : null
          }
          <div style={{marginLeft: 'auto', marginRight: 0}} className="t-right">
            {
              props.mainReportType === 'daily' ? 
              <>
                <p className='mb-0'><span style={{color: 'white'}} className="badge badge-warning"><FormattedMessage id="REPORTS.MANUAL_TRIP"/></span></p>
                <p className='mb-0'><span className="badge badge-success"><FormattedMessage id="REPORTS.DAY_TOTAL"/></span></p>
              </>
              :
              null
            }
            <p className='mb-0'><span className="badge badge-info"><FormattedMessage id="REPORTS.ALL_TOTAL"/></span></p>
          </div>
        </div>
      </div> : null
      }
      
      <div className="clearfix"/>
      <MessageWindow
        size='md'
        show={showMessage.status}
        onHide={() => setShowMessage({...showMessage, status: false})}
        title={showMessage.title}
        message={showMessage.message}
      />
    </>

  );
}

const mapStateToProps = function (state) {
  return {
    devices: state.deviceListReducer.devices,
    drivers: state.driverListReducer.drivers,
    user: state.userInformationReducer.user
  }
};

Menu.propTypes = {
  onFilter: PropTypes.func.isRequired,
  routerParams: PropTypes.object.isRequired,
  mainReportType: PropTypes.string.isRequired,
  userFavorites: PropTypes.array,
  manuel_trip: PropTypes.bool,
  only_private: PropTypes.bool,
  handleChangeShowDeviceName: PropTypes.func,
  handleChangeOnlyPrivate: PropTypes.func,
  handleChangeOnlyService: PropTypes.func,
  handleChangeReportswithDrivers: PropTypes.func,
  handleFavoriteDetails: PropTypes.func,
  handleExport: PropTypes.func,
};

export default connect(mapStateToProps)(Menu);