import React, {useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import Loading from '../Commons/loading';
import MessageWindow from '../Commons/messageWindow';

import UserInformation from '../../services/UserInformation';

import './index.css';

function UserRights(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [subUsers, setSubUsers] = useState([]);
	const [filteredSubUsers, setFilteredSubUsers] = useState([]);
	const [searchCredentials, setSearchCredentials] = useState({
		user_name: '',
		user_first_name: '',
		user_last_name: '',
		user_email: ''
	});
	const [showMessage, setShowMessage] = useState({
		message: '',
		title: '',
		status: false
	});

	const userInformationService = new UserInformation();

	const activeFilter = (event) => {
		let data = Object.assign([], subUsers);

		if (event.target.value !== 'all') {
			data = subUsers.filter((subUser) => {
				return parseInt(subUser[event.target.name]) === parseInt(event.target.value);
			});
		}

		setFilteredSubUsers(data);

	};

	const searchUsers = (event) => {
		const credentials = {...searchCredentials, [event.target.name]: event.target.value.toLowerCase()};
		let data = Object.assign([], subUsers);

		Object.keys(credentials).forEach((key) =>
			data = data.filter((subUser) => subUser[key].includes(credentials[key]))
		);

		setFilteredSubUsers(data);
		setSearchCredentials(credentials);
	};

	const toggleActive = async (status, userId) => {
		setIsLoading(true);

		await userInformationService.editSubUser(userId, {active: status})
			.then(response => {
				fetchData();
			});
	};

	const remove = async (userId) => {
		const user = subUsers.find((user) => user.user_id === userId);

		setShowMessage(
			{
				status: true,
				title: "Uyarı",
				message: <>
					<p><FormattedMessage id='USER_RIGHTS.CONFIRM_DELETE'/> <b>{user.user_name}</b></p>
					<div>
						<div className="form-group" >
							<button type = "button" className="btn btn-primary pull-right" style={{width: '150px'}}
											onClick={async () => {
												setShowMessage({...showMessage, status: false});
												setIsLoading(true);

												await userInformationService.deleteSubUser(userId)
													.then(response => {
														fetchData();
													})
											}}>
								<FormattedMessage id='GENERAL.DELETE'/>
							</button>
						</div>
					</div>
				</>
			});
	};

	const fetchData = async () => {
		setIsLoading(true);

		await userInformationService.getAllSubUsers()
			.then(response => {
				setSubUsers(response.data);
				setFilteredSubUsers(response.data);
			});

		setIsLoading(false);
	};

	useEffect(() => {
		fetchData();

	}, []);

	return (
		<>
			<div className="container-fluid user-rights size-lg">
				{isLoading ? (<Loading/>) : null}
				<div className="row">
					<div className="col-md-2 col-sm-2">
						<i className="fa fa-search"/>&nbsp;&nbsp;<FormattedMessage id="GENERAL.FILTER_RESULTS"/> :
						<ul className="user-rights-filter pl-0">
							<li>
								<select className="form-control" name='active' onChange={activeFilter}>
									<FormattedMessage id='USER_RIGHTS.ALL_USERS'>
										{(message) => <option key={0} value={'all'}>{message}</option>}
									</FormattedMessage>
									<FormattedMessage id='USER_RIGHTS.ACTIVE_USERS'>
										{(message) => <option key={1} value={1}>{message}</option>}
									</FormattedMessage>
									<FormattedMessage id='USER_RIGHTS.PASSIVE_USERS'>
										{(message) => <option key={2} value={0}>{message}</option>}
									</FormattedMessage>
								</select>
							</li>
							<li>
								<FormattedMessage id='GENERAL.USER_NAME'>
									{(message) => <input type="text" className="form-control" placeholder={message} name="user_name"
																			 onChange={searchUsers}/>}
								</FormattedMessage>
							</li>
							<li>
								<FormattedMessage id='GENERAL.NAME'>
									{(message) => <input type="text" className="form-control" placeholder={message} name="user_first_name"
																			 onChange={searchUsers}/>}
								</FormattedMessage>
							</li>
							<li>
								<FormattedMessage id='GENERAL.SURNAME'>
									{(message) => <input type="text" className="form-control" placeholder={message} name="user_last_name"
																			 onChange={searchUsers}/>}
								</FormattedMessage>
							</li>
							<li>
								<FormattedMessage id='GENERAL.MAIL'>
									{(message) => <input type="text" className="form-control" placeholder={message} name="user_email"
																			 onChange={searchUsers}/>}
								</FormattedMessage>
							</li>
							<li>
								<button className="btn btn-primary btn-sm" onClick={() => props.history.push('/user-rights/new/')}>
									<FormattedMessage id='USER_RIGHTS.NEW_USER'/>
								</button>
							</li>
						</ul>

					</div>
					<div className="col-md-10 col-sm-10">
						<div className="table-responsive">
							<h3><FormattedMessage id='MENU.USER_RIGHTS'/></h3>
							<table className="table t-left table-hover">
								<thead>
								<tr>
									<th><FormattedMessage id='GENERAL.USER_NAME'/></th>
									<th><FormattedMessage id='GENERAL.NAME'/></th>
									<th><FormattedMessage id='GENERAL.SURNAME'/></th>
									<th><FormattedMessage id='USER_RIGHTS.USER_TYPE'/></th>
									<th><FormattedMessage id='GENERAL.MAIL'/></th>
									<th><FormattedMessage id='ROUTES.VEHICLE'/></th>
									<th><FormattedMessage id='MENU.FUNCTIONS'/></th>
								</tr>
								</thead>
								<tbody>
								{
									filteredSubUsers.map((subUser) => {
										return (
											<>
												<tr key={subUser.user_id} className="alert-info">
													<td style={{cursor: "pointer"}} onClick={() => props.history.push(`/user-rights/edit/${subUser.user_id}`)}>{subUser.user_name}</td>
													<td>{subUser.user_first_name}</td>
													<td>{subUser.user_last_name}</td>
													<td><FormattedMessage id='GENERAL.USER'/></td>
													<td>{subUser.user_email}</td>
													<td>
														{
															subUser.devices.map((device) => <p key={device.device_id}>{device.device_name}</p>)
														}
													</td>
													<td>
														{
															(subUser.active > 0) ?
																<a className="col-md-4" style={{cursor: "pointer", display: "inline-block"}} onClick={() => toggleActive(0, subUser.user_id)}>
																	<span className="green"><i className="fa fa-check"/></span>
																	<p className="green"><FormattedMessage id='GENERAL.ACTIVE'/></p>
																</a>
																:
																<a className="col-md-4" style={{cursor: "pointer", display: "inline-block"}} onClick={() => toggleActive(1, subUser.user_id)}>
																	<span className="red"><i className="fa fa-times"/></span>
																	<p className="red"><FormattedMessage id='GENERAL.INACTIVE'/></p>
																</a>
														}
														{/** edit */}
														<a className="col-md-4" style={{cursor: "pointer", display: "inline-block"}} onClick={() => props.history.push(`/user-rights/edit/${subUser.user_id}`)}>
															<span className="yellow"><i className="fa fa-pencil"/></span>
															<p className="yellow"><FormattedMessage id='GENERAL.EDIT'/></p>
														</a>
														{/** remove */}
														<a className="col-md-4" style={{cursor: "pointer", display: "inline-block"}} onClick={() => remove(subUser.user_id)}>
															<span className="red"><i className="fa fa-trash"/></span>
															<p className="red"><FormattedMessage id='GENERAL.DELETE'/></p>
														</a>
													</td>
												</tr>
												{
													subUser.two_sub_users.map((subSubUser) => {
														return (
															<tr key={subSubUser.user_id} className="alert-warning">
																<td style={{paddingLeft: "20px !important"}}>
																	<i className="fa fa-angle-right"/>&nbsp;&nbsp;{subSubUser.user_name}
																</td>
																<td>{subSubUser.user_first_name}</td>
																<td>{subSubUser.user_last_name}</td>
																<td/>
																<td>{subSubUser.user_email}</td>
																<td>
																	{
																		subSubUser.devices.map((subSubDevice) => <p key={subSubDevice.user_device_id}>{subSubDevice.device_name}</p>)
																	}
																</td>
																<td>
																	{
																		(subSubUser.active > 0) ?
																			<a className="col-md-4 pull-left">
																				<span className="green"><i className="fa fa-check"/></span>
																				<p className="green"><FormattedMessage id='GENERAL.ACTIVE'/></p>
																			</a>
																			:
																			<a className="col-md-4 pull-left">
																				<span className="red"><i className="fa fa-times"/></span>
																				<p className="red"><FormattedMessage id='GENERAL.INACTIVE'/></p>
																			</a>
																	}
																</td>
															</tr>
														)
													})
												}
											</>
										)
									})
								}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<MessageWindow
				show={showMessage.status}
				title={showMessage.title}
				onHide={() => setShowMessage({...showMessage, status: false})}
				message={showMessage.message}
				size="lg"/>
		</>
	)
}

export default UserRights;