import axios from 'axios';
import querystring from 'querystring';
import config from '../config';

class BaseService {
  constructor() {

    this.axiosInstance = axios.create({
      baseURL: config.apiUrl
    });

    // add request interceptor
    this.axiosInstance.interceptors.request.use((request) => {

      let token = "";

      if (sessionStorage.getItem('access_token')) {
        token = decodeURIComponent(escape(window.atob(sessionStorage.getItem('access_token'))));
      }

      if (!token) {
        window.location.href = process.env.PUBLIC_URL + "/#/login";
      }

      if(request['url'].indexOf('?') === -1)
        request['url'] += `?access_token=${token}`;
      else
        request['url'] += `&access_token=${token}`;

      if (request['method'] === 'post') {
        request['headers']['Content-Type'] ='application/x-www-form-urlencoded';
        request['data'] = querystring.stringify(request['data']);
      }

      return request;
    });

    let cancelToken = axios.CancelToken;
    this.cancelTokenSource = cancelToken.source();
  }

  getCancelToken() {
    return this.cancelTokenSource;
  }
}

export default BaseService;