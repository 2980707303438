import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import HomeNav from './nav';
import {FormattedMessage} from 'react-intl';
import DatePicker from "react-datepicker";
import MessageWindow from '../Commons/messageWindow';
import {pad} from '../Assets/utils';
import Moment from 'moment';
import IconTextWithFa from '../Commons/iconTextWithFa';
import {saveAs} from 'file-saver';

import SignalService from '../../services/Signals';
import ReportService from '../../services/Reports';
import CarService from '../../services/Cars';
import DriverService from '../../services/Drivers';
import Export from '../../services/Export';

function DailyReport(props) {
  const deviceId = props.match.params.deviceId;

  const [isLoading, setLoading] = useState(true);
  const [changeDateControl, setChangeDateControl] = useState(false);
  const [total, setTotal] = useState({});
  const [mileage, setMileage] = useState({
    start: 0,
    end: 0
  });
  const [trips, setTrips] = useState({
    dailyTrips: [],
    filteredTrips: []
  });
  const [selectedDate, setSelectedDate] = useState({
    start: new Date().setHours(0, 0, 0),
    end: new Date().setHours(23, 59, 0)
  });

  const exportService = new Export();
  const carService = new CarService();
  const signalService = new SignalService();
  const reportService = new ReportService();
  const driverService = new DriverService();

  const handleFilter = async (id, start_date, end_date) => {
    if (deviceId !== 0) {
      try {
        await Promise.all([
          driverService.getDrivers(),
          reportService.getDeviceDailyTrips(id, start_date, end_date),
        ])
          .then(([driversResponse, reportResponse]) => {
            getStartAndEndMileage(id, reportResponse.data);
            setDailyTrips(driversResponse.data, reportResponse.data);
            setChangeDateControl(false);
          });

      } catch (error) {
      }
    } else {
      alert(<FormattedMessage id="REPORTS.PICK_DEVICE"/>);
    }

    //unmount
    return () => {
      driverService.getCancelToken().cancel();
      reportService.getCancelToken().cancel();
    }
  };

  const getStartAndEndMileage = async (deviceId, trips) => {
    try {
      await Promise.all([
        signalService.getSignalBySignalId(deviceId, trips[0].start_signal_id),
        signalService.getSignalBySignalId(deviceId, trips[trips.length - 1].end_signal_id),
        carService.getCar(deviceId)
      ])
        .then(([startSignalResponse, endSignalResponse, carResponse]) => {
          let start_signal = startSignalResponse.data;
          let end_signal = endSignalResponse.data;
          let car = carResponse.data;

          if (start_signal.mileage && end_signal.mileage) {
            setMileage({
              start: start_signal.mileage + car.car_mileage_at_install,
              end: end_signal.mileage + car.car_mileage_at_install
            })
          }
        });

    } catch (error) {
      setMileage({
        start: 0,
        end: 0
      });
    }
  };

  const getTripType = (trip_type_number) => {

    switch (trip_type_number) {
      case 0:
        return {label: "REPORTS.TRIP_TYPE_0", value: 0};
      case 1:
        return {label: "REPORTS.TRIP_TYPE_1", value: 1};
      case 2:
        return {label: "REPORTS.TRIP_TYPE_2", value: 2};
      case 3:
        return {label: "REPORTS.TRIP_TYPE_3", value: 3};
    }
  };


  const getSleepReports = async () => {
    var noData = true;
    setLoading(true);

    try {
      await reportService.getDeviceSleepTrips(deviceId,
        Moment(selectedDate.start).format("YYYY-MM-DD HH:mm:ss"),
        Moment(selectedDate.end).format("YYYY-MM-DD HH:mm:ss"))
        .then(async (response) => {
          if (response.data.length > 0) {
            var sleeps = response.data;
            for (let i = 0; i < sleeps.length; i++) {
              for (let j = 0; j < trips.dailyTrips.length; j++) {
                if (trips.dailyTrips[j]['trip_id'] == sleeps[i]['trip_id']) {
                  trips.dailyTrips[j]['sleep_time'] = sleeps[i]['duraction_sec'];
                  if (sleeps[i]['duraction_sec'].toString().length > 1) {
                    noData = false;
                  }
                  break;
                }
              }
            }
          }

          if (noData) {
            alert("No Data");
          }

          setTrips({
            dailyTrips: trips.dailyTrips,
            filteredTrips: trips.dailyTrips
          });
          setLoading(false);
        });
    } catch (error) {}
  };

  const setDailyTrips = (drivers, trips) => {
    let dayText = 'Gün';
    let day, hour, min, i;
    let total = {}, km = 0, office_km = 0, private_km = 0, home_km = 0, total_duration_sec = 0, total_waiting_time_sec = 0;

    for (i = 0; i < trips.length; i++) {
      // convert start and end time to date format
      trips[i]['start_time'] = trips[i]['start_time'] !== '' ? Moment(new Date(trips[i]['start_time'])).format("DD/MM/YYYY HH:mm:ss") : '';
      trips[i]['end_time'] = trips[i]['end_time'] !== '' ? Moment(new Date(trips[i]['end_time'])).format("DD/MM/YYYY HH:mm:ss") : '';
      trips[i]['waiting_time'] = trips[i]['waiting_time'] !== '' ? Moment(new Date('01/01/1970 ' + trips[i]['waiting_time'])).format("DD/MM/YYYY HH:mm:ss") : '';
      trips[i]['duration'] = trips[i]['duration'] !== '' ? Moment(new Date('01/01/1970 ' + trips[i]['duration'])).format("DD/MM/YYYY HH:mm:ss") : '';

      // check if trip jump to next day
      if (trips[i]['start_date'] != trips[i]['end_date']) {
        trips[i]['jump'] = true;
      }

      // default driver
      trips[i]['driver'] = {
        "driver_first_name": ""
      };

      // driver
      if (drivers.length > 0) {
        for (let j = 0; j < drivers.length; j++) {
          if (trips[i]['driver_id'] === drivers[j]['driver_id']) {
            trips[i]['driver'] = drivers[j];
            break;
          }
        }
      }

      // set trip type
      trips[i]['tripType'] = getTripType(trips[i]['private_trip']);

      // calculate total km by trip type
      km += trips[i]['lenght_km'];

      if (trips[i]['private_trip'] == 1 || trips[i]['private_trip'] == 0) { // görev // office
        office_km += trips[i]['lenght_km'];
      } else if (trips[i]['private_trip'] == 2) { // özel // privat
        private_km += trips[i]['lenght_km'];
      } else if (trips[i]['private_trip'] == 3) { // ev
        home_km += trips[i]['lenght_km'];
      }

      total_waiting_time_sec += trips[i]['waiting_time_sec'];
      total_duration_sec += trips[i]['duration_sec'];
    }

    // total kms
    total.office_km = Number(office_km.toFixed(2));
    total.private_km = Number(private_km.toFixed(2));
    total.home_km = Number(home_km.toFixed(2));
    total.km = Number(km.toFixed(2));

    // calculate percents
    total.office_km_percent = (office_km > 0) ? Number(((office_km * 100) / km).toFixed(1)) : 0;
    total.private_km_percent = (private_km > 0) ? Number(((private_km * 100) / km).toFixed(1)) : 0;

    // calculate total duration
    day = Math.floor(total_duration_sec / (3600 * 24));
    hour = Math.floor((total_duration_sec / 3600) % 24);
    min = Math.floor((total_duration_sec / 60) % 60);
    total.duration = day + " " + dayText + " " + pad(hour) + ":" + pad(min);

    // calculate total waiting time
    day = Math.floor(total_waiting_time_sec / (3600 * 24));
    hour = Math.floor((total_waiting_time_sec / 3600) % 24);
    min = Math.floor((total_waiting_time_sec / 60) % 60);
    total.waiting_time = day + " " + dayText + " " + pad(hour) + ":" + pad(min);

    // calculate total active time
    day = Math.floor((total_duration_sec + total_waiting_time_sec) / (3600 * 24));
    hour = Math.floor(((total_duration_sec + total_waiting_time_sec) / 3600) % 24);
    min = Math.floor(((total_duration_sec + total_waiting_time_sec) / 60) % 60);
    total.active_time = day + " " + dayText + " " + pad(hour) + ":" + pad(min);

    setTotal(total);
    setTrips({
      dailyTrips: trips,
      filteredTrips: trips
    });
    setLoading(false);
  };

  const movePrevDay = (event) => {
    event.preventDefault();

    let prevDateStart = Moment(selectedDate.start).subtract(1, 'day');
    let prevDateEnd = Moment(selectedDate.end).subtract(1, 'day');
    let dateStart = new Date(prevDateStart.toISOString());
    let dateEnd = new Date(prevDateEnd.toISOString());

    setSelectedDate({
      start: dateStart,
      end: dateEnd
    });
    setChangeDateControl(true);
  };

  const moveNextDay = (event) => {
    event.preventDefault();

    let nextDateStart = Moment(selectedDate.start).add(1, 'day');
    let nextDateEnd = Moment(selectedDate.end).add(1, 'day');
    let dateStart = new Date(nextDateStart.toISOString());
    let dateEnd = new Date(nextDateEnd.toISOString());

    if (dateStart <= new Date()) {
      setSelectedDate({
        start: dateStart,
        end: dateEnd
      });
    }

    setChangeDateControl(true);
  };

  const filterByKm = (event) => {
    if (event.target.checked) {
      setTrips({
        ...trips,
        filteredTrips: trips.dailyTrips.filter((trip) => trip.lenght_km > 0.2)
      });
    } else {
      setTrips({
        ...trips,
        filteredTrips: trips.dailyTrips
      });
    }
  };

  // onclick handler
  const handleExport = async (selectedDateStart, selectedDateEnd, fileExtension) => {
    const start = Moment(selectedDateStart).format("YYYY-MM-DD HH:mm:ss");
    const end = Moment(selectedDateEnd).format("YYYY-MM-DD HH:mm:ss");
    if (deviceId > 0)
        await exportService.exportDailyReport(deviceId, start, end, trips.filteredTrips, fileExtension)
          .then((response) => {
              let blob = new Blob([response.data]);
              saveAs(blob, `Günlük_Rapor.` + fileExtension);
          });
  };

  useEffect(() => {
    handleFilter(deviceId,
      Moment(selectedDate.start).format("YYYY-MM-DD HH:mm:ss"),
      Moment(selectedDate.end).format("YYYY-MM-DD HH:mm:ss"));

  }, [changeDateControl, props.match.params.deviceId]);

  return (
    <div className="home-daily">
      <MessageWindow
        title="Yetkiniz yok!"
        message="Paketiniz bu özelliği içermiyor. Bilgi için: 0(232) 339 60 06"
        show={!props.access}/>

      <HomeNav deviceId={deviceId} active="daily-report"/>
      <>
        <div className="filters">
          <div className="filter-1">
            <p><b><FormattedMessage id="GENERAL.FILTER_OPTIONS"/></b></p>
            <p className="change-day">
              <a style={{color: '#333', cursor: 'pointer'}} onClick={movePrevDay}>
                <IconTextWithFa icon="arrow-alt-circle-left"/>
              </a>
              <FormattedMessage id="GENERAL.DAY"/> &nbsp;
              <a style={{color: '#333', cursor: 'pointer'}} onClick={moveNextDay}>
                <IconTextWithFa icon="arrow-alt-circle-right"/>
              </a>
            </p>
            <p className="daily-km">
              <span className="badge badge-danger">
                <FormattedMessage id="GENERAL.TOTAL"/> Km {total.km}
              </span>
            </p>
          </div>
        </div>
        <div className="filters">
          <div className="row filter-1">
            <DatePicker
              className="mb-1"
              todayButton="Today"
              maxDate={new Date()}
              dateFormat="dd-MM-yyyy HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              timeCaption="time"
              selected={selectedDate.start}
              onChange={(date) => setSelectedDate({...selectedDate, start: date})}/>&nbsp; &nbsp; &nbsp;
            <DatePicker
              todayButton="Today"
              maxDate={new Date()}
              dateFormat="dd-MM-yyyy HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              timeCaption="time"
              selected={selectedDate.end}
              onChange={(date) => setSelectedDate({...selectedDate, end: date})}/>&nbsp; &nbsp; &nbsp;
            <div className="pull-left no-padding-left col-md-2">
              <div className="form-group">
                <button className="btn btn-primary btn-sm btn-block"
                        onClick={() =>
                          handleFilter(deviceId,
                            Moment(selectedDate.start).format("YYYY-MM-DD HH:mm:ss"),
                            Moment(selectedDate.end).format("YYYY-MM-DD HH:mm:ss")
                          )}>
                  <FormattedMessage id="GENERAL.FILTER"/>
                </button>
              </div>
            </div>
            <div className="filter-3 export-icons">
              <div className="pull-left">
                <p className="report-icon" style={{cursor: 'pointer'}}
                  onClick={() => handleExport(selectedDate.start, selectedDate.end, 'xlsx')}>
                  <img src={require("./images/excel-icon.png").default} width="40" alt='excel-icon'/>
                  <br/>
                    <a>
                      <FormattedMessage id="GENERAL.EXPORT_EXCEL"/>
                    </a>
                </p>
                <p className="report-icon" style={{cursor: 'pointer'}}
                  onClick={() => handleExport(selectedDate.start, selectedDate.end, 'pdf')}>
                  <img src={require("./images/pdf-icon.png").default} width="40" alt='pdf-icon'/>
                  <br/>
                    <a>
                      <FormattedMessage id="GENERAL.EXPORT_PDF"/>
                    </a>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" onChange={filterByKm}/>
              <label className="form-check-label" ><FormattedMessage id="REPORTS.FILTER_BY_KM"/></label>
            </div>
          </div>
        </div>
        {

          !isLoading ?

            <div>
              <div className="trips table-responsive">
                <table className="table table-hover" id="daily-trips">
                  <thead>
                  <tr>
                    <th><FormattedMessage id="GENERAL.DATE"/></th>
                    <th><FormattedMessage id="GENERAL.START"/></th>
                    <th><FormattedMessage id="GENERAL.STOP"/></th>
                    <th><FormattedMessage id="GENERAL.KM"/></th>
                    <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
                    <th width="80">
                      <button className="btn-warning" onClick={() => getSleepReports()}>
                        <b><FormattedMessage id="GENERAL.IDLE_TIME"/></b></button>
                    </th>
                    <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
                    <th><FormattedMessage id="GENERAL.DRIVER"/></th>
                    <th><FormattedMessage id="REPORTS.TRIP_TYPE"/></th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    trips.filteredTrips.length !== 0 ?
                      trips.filteredTrips.map((trip, index) => {
                        let date = Moment(new Date(trip.start_date)).format("DD-MM-YYYY").substring(0, 11);
                        
                        return <tr key={'trip-' + index} id={'trip-' + index} className={index % 2 !== 0 ? 'active' : ''}>
                          <td>{date}
                            {
                              trip.jump ? <span><br/>{trip.end_date}</span> : null
                            }
                          </td>
                          <td width="220">{trip.start_time.substring(11, 16)}<br/>{trip.start_address}</td>
                          <td width="220">{trip.end_time.substring(11, 16)}<br/>{trip.end_address}</td>
                          <td>{trip.lenght_km}</td>
                          <td>{trip.duration.substring(11, 16)}</td>
                          <td>{trip.sleep_time}</td>
                          <td>{trip.waiting_time.substring(11, 16)}</td>
                          <td>{trip.driver.driver_first_name}</td>
                          <td>
                            {
                              trip.tripType !== undefined ? <FormattedMessage id={trip.tripType.label}/> : ''
                            }
                            </td>
                        </tr>
                      }) : null
                  }
                  </tbody>
                </table>
              </div>
              <div className="trips-total table-responsive">
                <div className="col-md-3 table-responsive pull-left">
                  <table className="table table-hover">
                    <thead>
                    <tr>
                      <th>Km - <FormattedMessage id="GENERAL.MORNING"/></th>
                      <th>Km - <FormattedMessage id="GENERAL.EVENING"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="warning">
                      <td>{mileage.start}</td>
                      <td>{mileage.end}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-9 pull-right">
                  <table className="table table-hover" id="daily-trips-total">
                    <thead>
                    <tr>
                      <th/>
                      <th><FormattedMessage id="GENERAL.TRIP_TIME"/></th>
                      <th><FormattedMessage id="GENERAL.WAIT_TIME"/></th>
                      <th>Km <FormattedMessage id="REPORTS.TRIP_TYPE_2"/></th>
                      <th>Km <FormattedMessage id="REPORTS.TRIP_TYPE_1"/></th>
                      <th><FormattedMessage id="GENERAL.TOTAL"/> Km</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="info">
                      <td><b><FormattedMessage id="GENERAL.TOTAL"/></b></td>
                      <td>{total.duration}</td>
                      <td>{total.waiting_time}</td>
                      <td>{total.private_km} ({total.private_km_percent}%)</td>
                      <td>{total.office_km} ({total.office_km_percent}%)</td>
                      <td>{total.km} (100%)</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> : null
        }
      </>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    devices: state.deviceListReducer.devices,
    drivers: state.driverListReducer.drivers,
    user: state.userInformationReducer.user
  }
};
export default connect(mapStateToProps)(DailyReport);