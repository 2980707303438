export const UPDATE_USER_INFORMATION = "UPDATE_USER_INFORMATION";
export const UPDATE_USER_GROUPS = "UPDATE_USER_GROUPS";
export const UPDATE_DEVICE_LIST = "UPDATE_DEVICE_LIST";
export const UPDATE_DEVICE_LIST_WITH_GROUPS = "UPDATE_DEVICE_LIST_WITH_GROUPS";
export const UPDATE_DEVICE_LIST_TYPE = "UPDATE_DEVICE_LIST_TYPE";
export const UPDATE_DEVICE_SORT_TYPE = "UPDATE_DEVICE_SORT_TYPE";
export const UPDATE_DEVICE_GROUP_SORT = "UPDATE_DEVICE_GROUP_SORT";
export const UPDATE_SEARCH_CONTENT = "UPDATE_SEARCH_CONTENT";
export const UPDATE_DRIVER_LIST = "UPDATE_DRIVER_LIST";

export function updateUserInformation(user) {
    return {
        type: UPDATE_USER_INFORMATION,
        user
    }
}

export function updateUserGroups(userGroups) {
    return {
        type: UPDATE_USER_GROUPS,
        userGroups
    }
}

export function updateDeviceList(data) {
    return {
        type: UPDATE_DEVICE_LIST,
        devices: data.devices,
        totalCount: data.totalCount
    }
}

export function updateDeviceListWithGroups(devicesWithGroups) {

    return {
        type: UPDATE_DEVICE_LIST_WITH_GROUPS,
        devicesWithGroups
    }
}

export function updateDeviceListType(listType) {
    localStorage.setItem('listType', listType.listType);

    return {
        type: UPDATE_DEVICE_LIST_TYPE,
        listType
    }
}

export function updateDeviceSortType(sortType) {
    localStorage.setItem('sortType', sortType.sortType);

    return {
        type: UPDATE_DEVICE_SORT_TYPE,
        sortType
    }
}

export function updateDeviceGroupSort(groupSort) {
    localStorage.setItem('groupSort', groupSort.groupSort);

    return {
        type: UPDATE_DEVICE_GROUP_SORT,
        groupSort
    }
}

export function updateSearchContent(contents) {

    return {
        type: UPDATE_SEARCH_CONTENT,
        contents
    }
}

export function updateDriverList(drivers) {

    return {
        type: UPDATE_DRIVER_LIST,
        drivers
    }
}
