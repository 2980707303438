import React, {useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import Select from "react-select";
import {Card, ListGroup} from "react-bootstrap";
import Loading from "../Commons/loading";
import MessageWindow from "../Commons/messageWindow";
import moment from 'moment';
import closeIcon from "../Assets/images/close.png";
import './index.css';

import DataDelete from "../../services/DataDelete";

import {
	updateDeviceList
} from '../../redux/actions';

function DataDeleteDevice(props) {

	const [selectedDevice, setSelectedDevice] = useState(null);
	const [tokenDevices, setTokenDevices] = useState([]);
	const [deleteDevices, setDeleteDevices] = useState([]);
	const [deleteProgressDevices, setDeleteProgressDevices] = useState([]);
	const [deleteHistory, setDeleteHistory] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showMessage, setShowMessage] = useState({
		message: '',
		title: '',
		status: false
	});

	const dataDeleteService = new DataDelete();

	const deviceList = props.devices.map(item => {
		return {name: item['name'], device_id: item['device_id']}
	});

	const handleSelectVehicle = async () => {
		try {
			setLoading(true);

			await dataDeleteService.createToken(selectedDevice.value)
				.then((response) => {
					if (response.data.result === "success") {
						setShowMessage({
							title: <h5>Success</h5>,
							message: <h5>{selectedDevice.label} aracınız için {response.data.email} adresine onay kodunuz gönderilmiştir.</h5>,
							status: true
						});

					} else if (response.data.result === "token already created") {
						setShowMessage({
							title: <h5>Warning</h5>,
							message: <h5>{selectedDevice.label} aracınız için zaten onay kodu oluşturuldu. Lütfen onay kodunuzu doğrulayınız.</h5>,
							status: true
						});

					} else if (response.data.result === "device is waiting for delete") {
						setShowMessage({
							title: <h5>Warning</h5>,
							message: <h5>{selectedDevice.label} aracınız için işleminiz daha önce alınmıştır.</h5>,
							status: true
						});

					} else if (response.data.result === "mail error") {
						setShowMessage({
							title: <h5>Error</h5>,
							message: <h5>{response.data.email} adresine onay kodu gönderiminde hata oluştu. Lütfen daha sonra tekrar deneyiniz.</h5>,
							status: true
						});

					}
				});

			fetchDeletionOrderDevices();

		} catch (e) {
			setShowMessage({
				title: <h5>Error</h5>,
				message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
				status: true
			});

		} finally {
			setLoading(false);
		}
	};

	const fetchDeletionOrderDevices = async () => {
		try {
			setLoading(true);

			await dataDeleteService.getDevicesWithDeletionOrder()
				.then((response) => {
					if (("token_devices" in response.data) && ("delete_devices" in response.data) && ("delete_history" in response.data)){
						let tokenDevices = response.data.token_devices;
						tokenDevices.map((device) => device['user_token'] = '');
						setTokenDevices(tokenDevices);
						setDeleteDevices(response.data.delete_devices);
						setDeleteProgressDevices(response.data.delete_progress);
						setDeleteHistory(response.data.delete_history);

					} else {
						setShowMessage({
							title: <h5>Error</h5>,
							message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
							status: true
						});

					}
				});

		} catch (e) {
			setShowMessage({
				title: <h5>Error</h5>,
				message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
				status: true
			});

		} finally {
			setLoading(false);
		}
	};

	const confirmToken = async (device) => {
		try {
			setLoading(true);

			await dataDeleteService.confirmToken(device.device_id, {token: device.user_token})
				.then((response) => {
					if (response.data.result === "success") {
						setShowMessage({
							title: <h5>Success</h5>,
							message: <h5>{device.name} aracınız için veri silme işleminiz alınmıştır.</h5>,
							status: true
						});

					} else if (response.data.result === "token expired") {
						setShowMessage({
							title: <h5>Warning</h5>,
							message: <h5>{selectedDevice.label} aracınız için onay kodunuzun süresi dolmuştur. Lütfen yeni bir onay kodu alınız.</h5>,
							status: true
						});

					} else if (response.data.result === "token mismatch") {
						setShowMessage({
							title: <h5>Warning</h5>,
							message: <h5>Onay kodu yanlış! Lütfen tekrar deneyiniz.</h5>,
							status: true
						});

					} else {
						setShowMessage({
							title: <h5>Error</h5>,
							message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
							status: true
						});
					}
				});

				fetchDeletionOrderDevices();

		} catch (e) {
			setShowMessage({
				title: <h5>Error</h5>,
				message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
				status: true
			});

		} finally {
			setLoading(false);
		}
	};

	const handleCancel = (device) => {
		try {
			setLoading(true);

			dataDeleteService.cancel(device.device_id)
				.then((response) => {
					if (response.data.result === "success") {
						setShowMessage({
							title: <h5>Success</h5>,
							message: <h5>{device.name} aracınız için veri silme işleminiz iptal edilmiştir.</h5>,
							status: true
						});

					} else {
						setShowMessage({
							title: <h5>Warning</h5>,
							message: <h5>{device.name} aracınız için silme işlemi iptal edilemedi. Lütfen terar deneyiniz.</h5>,
							status: true
						});

					}

					fetchDeletionOrderDevices();

				});

		} catch (e) {
			setShowMessage({
				title: <h5>Error</h5>,
				message: <h5><FormattedMessage id="GENERAL.ERROR"/>.</h5>,
				status: true
			});

		} finally {
			setLoading(false);
		}
	};

	// onchange handler
	const handleTokenDeviceChange = (event, index) => {
		tokenDevices[index][event.target.name] = event.target.value;
		setTokenDevices([...tokenDevices]);
	};

	useEffect(() => {
		fetchDeletionOrderDevices();

	}, []);

	return (
		<div className="mt-2 container-box">
			{loading ? (<Loading/>) : null}
			<div className="row">
				<div className="col-md-5">
					<Select
						value={selectedDevice}
						onChange={(option) => setSelectedDevice(option)}
						placeholder={<FormattedMessage id="GENERAL.PICK_DEVICE"/>}
						options={deviceList.map((device) => {
							return {value: device.device_id, label: device.name}
						})}
					/>
				</div>
				<div className="col-md-2">
					<button onClick={handleSelectVehicle} type="button" className="btn btn-primary btn-block">
						<FormattedMessage id="GENERAL.SELECT"/>
					</button>
				</div>
			</div>
			<hr className="mt-3" />
			<div className="row">
				{
					(tokenDevices.length > 0) ?
						<div className="col-md-7">
							{
								tokenDevices.map((device, index) => {
									return (
										<Card key={device.device_id} className="mt-3">
											<Card.Header><b>{device.name} - <FormattedMessage id='DATA_DELETE.CONFIRMATION_CODE'/></b></Card.Header>
											<Card.Body>
												<Card.Text>
													<input type="text" className="form-control" name='user_token' value={device.user_token}
																 placeholder={props.intl.formatMessage({ id: 'DATA_DELETE.TYPE_CONFIRMATION_CODE'})}
																 onChange={(event) => handleTokenDeviceChange(event, index)}/>
												</Card.Text>
												<button type="button" className="btn btn-primary pull-right" onClick={() => confirmToken(device)}>
													<FormattedMessage id='TECHNICAL_SUPPORT.SEND'/>
												</button>
											</Card.Body>
										</Card>
									)
								})
							}
						</div>
					: null
				}
				<div className="col-md-5">
					{
						(deleteDevices.length > 0) ?
							<Card className="mt-3">
							<Card.Header><b><FormattedMessage id='DATA_DELETE.DEVICES_TO_BE_DELETED'/></b></Card.Header>
								{
									deleteDevices.map((device) => {
										return (
											<ListGroup variant="flush" key={device.device_id}>
												<ListGroup.Item>
													{device.name}
													<img onClick={() => handleCancel(device)} className='pull-right' style={{cursor: 'pointer'}}
															 src={closeIcon} alt="" />
												</ListGroup.Item>
											</ListGroup>
										)
									})
								}
							</Card>
						: null
					}
					{
						(deleteProgressDevices.length > 0) ?
							<Card className="mt-3">
								<Card.Header><b><FormattedMessage id='DATA_DELETE.DATA_DELETING'/></b></Card.Header>
								{
									deleteProgressDevices.map((device, index) => {
										return (
											<ListGroup variant="flush" key={index}>
												<ListGroup.Item>
													{device.name}
													<span className='row pull-right'>
															<b><FormattedMessage id='DATA_DELETE.CREATE_DATE'/>:</b>&nbsp;{moment(device.create_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
													</span>
												</ListGroup.Item>
											</ListGroup>
										)
									})
								}
							</Card>
							: null
					}
					{
						(deleteHistory.length > 0) ?
							<Card className="mt-3">
								<Card.Header><b><FormattedMessage id='DATA_DELETE.DELETED_DEVICES'/></b></Card.Header>
								{
									deleteHistory.map((device, index) => {
										return (
											<ListGroup variant="flush" key={index}>
												<ListGroup.Item>
													{device.name}
													<span className='row pull-right'>
															<b><FormattedMessage id='DATA_DELETE.CREATE_DATE'/>:</b>&nbsp;{moment(device.create_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
															<div style={{marginRight: '10px'}}/>
															<b><FormattedMessage id='DATA_DELETE.DELETE_DATE'/>:</b>&nbsp;{moment(device.delete_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
													</span>
												</ListGroup.Item>
											</ListGroup>
										)
									})
								}
							</Card>
							: null
					}
				</div>
			</div>
			<MessageWindow
				size='lg'
				show={showMessage.status}
				onHide={() => setShowMessage({...showMessage, status: false})}
				title={showMessage.title}
				message={showMessage.message}
			/>
		</div>
	);

}

const mapStateToProps = function (state) {
	return {
		devices: state.deviceListReducer.devices
	}
};

const mapDispatchToProps = {
	updateDeviceList
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DataDeleteDevice));
