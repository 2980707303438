import React, {useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from "react-intl";
import 'bootstrap/dist/css/bootstrap.min.css';
import './DashboardLayout.css';
import {optionPermissions} from '../config';

import {langTr, langEn, langDe, langNl} from "../lang";
import HeaderNav from '../components/HeaderNav';
import BigList from '../components/DeviceList/BigList';
import {sortAllDevices, sortByGroup} from "../components/Assets/utils";

import UserService from "../services/Users";
import DeviceService from "../services/Devices";
import DeviceOptionsService from '../services/DeviceOptions';

import {
  updateDeviceList,
  updateDeviceListWithGroups,
  updateUserGroups
} from "../redux/actions";

const langConfig = {...langTr, ...langEn, ...langDe, ...langNl};
const langs = ['de', 'en', 'tr', 'nl'];

const DashboardLayoutBigList = ({children}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [redirect, setRedirect] = useState('');

  const userService = new UserService();
  const deviceService = new DeviceService();

  function handleNavbarTogglerClick() {
    setIsOpen(!isOpen);
  }

  const fetchData = async () => {
    try {
      const [devices, groups] = await Promise.all([
        deviceService.getDevicesWithGroups(children.props.sortType, children.props.devices_on_display),
        userService.getUserGroups()
      ])
        .then(([devicesResponse, groupsResponse]) => {
          children.props.updateDeviceList({
            devices: devicesResponse.data[0]["results"],
            totalCount: devicesResponse.data[1]["total_count"]
          });

          children.props.updateUserGroups({userGroups: groupsResponse.data[0]["results"]});

          return [devicesResponse.data[0]["results"], groupsResponse.data[0]["results"]];
        });

      return [devices, groups];

    } catch (error) {
    }
  };

  useEffect(() => {
    let token = sessionStorage.getItem('access_token');
    if (!token) setRedirect('/login');
  }, [redirect]);

  useEffect(() => {

    fetchData()
      .then((data) => {
        if (data) {
          let deviceListWithGroup = (children.props.groupSort) ? sortByGroup(data[0], data[1]) : sortAllDevices(data[0], data[1]);
          children.props.updateDeviceListWithGroups({devicesWithGroups: deviceListWithGroup});
        }
      });

    //unmount
    return () => {
      deviceService.getCancelToken().cancel();
    }
  }, [children.props.sortType, children.props.groupSort, children.props.devices_on_display]);

  const redirectTo = () => {
    if (redirect)
      return <Redirect to={redirect}/>;
  };

  return (
    <React.Fragment>
      {redirectTo()}
      <HeaderNav {...children.props} isOpen={isOpen} onClick={handleNavbarTogglerClick}/>
        <BigList {...children.props} />

        <div className="container-fluid" style={{marginTop:35}}>
          <div className="main">{children}</div>
        </div>
    </React.Fragment>
  )
};

const DashboardLayoutBigListRoute = ({component: Component, ...rest}) => {

  const [access, setAccess] = useState(true);

  if (!sessionStorage.getItem('access_token')) {
    window.location.href = "/login";
  }

  useEffect(() => {
    const deviceOptionsService = new DeviceOptionsService();
    let options = [];

    let url = rest.computedMatch.path;
    if (url.indexOf('deviceId') !== -1) {
      let deviceId = rest.computedMatch.params.deviceId;
      let option = optionPermissions[url];

      if (!sessionStorage.getItem(`options_${deviceId}`)) {
        deviceOptionsService.getByDeviceId(deviceId).then((response) => {
          sessionStorage.setItem(`options_${deviceId}`, JSON.stringify(response.data));
          options = response.data;
        });
      }
      else {
        options = JSON.parse(sessionStorage.getItem(`options_${deviceId}`));
      }

      if (option) {
        if (options.length > 0) {
          let found = options.find((element) => element.device_option_id === option);
          if (found) {
            setAccess(Boolean(found.active));
          }
          else {
            setAccess(false);
          }
        }
      }
    }
    
  }, [rest.computedMatch]);
  
  return (
    <IntlProvider locale={langs[rest.lang]} messages={langConfig[langs[rest.lang]]}>
      <Route {...rest} render={matchProps => (
        <DashboardLayoutBigList>
          <Component access={access} {...rest} {...matchProps} />
        </DashboardLayoutBigList>
      )}/>
    </IntlProvider>
  )
};

const mapStateToProps = function (state) {
  return {
    lang: state.userInformationReducer.user.language,
    devices_on_display: state.userInformationReducer.user.devices_on_display,
    listType: state.deviceListTypeReducer.listType,
    sortType: state.deviceSortTypeReducer.sortType,
    groupSort: state.deviceGroupSortReducer.groupSort
  }
};

const mapDispatchToProps = {
  updateUserGroups,
  updateDeviceList,
  updateDeviceListWithGroups
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayoutBigListRoute);