import './index.css';

import React, {useEffect, useRef, useState} from 'react';
import HMap from "./hmap";

var lastDeviceId = 0;

const SpeedReportsMap = (props) => {
  const [size, setSize] = useState([0, 800]);
  const longlat = [27.1699050, 38.4310990];
  const mapInstance = useRef(null);

  const updateSize = () => {
    setSize([window.innerWidth, (window.innerHeight - 41)]);
    if (mapInstance) {
      mapInstance.current.fitMap();
    }
  };

  useEffect(() => {

    // initialize map - only once
    if (mapInstance.current === null) {
      mapInstance.current = new HMap(longlat);
      mapInstance.current.initMap(16, false);
    }

    // clear previous points if exists
    mapInstance.current.getVectorSource().clear();

    props.maps.forEach(item => mapInstance.current.setLayer(item, props.active));

    let signals = [];
    let first_start = false;
    let itemsProcessed = 0;
    let device = props.device;

    props.signals.forEach((element, index, array) => {
      itemsProcessed++;
      let coordinate = [element.dec_long, element.dec_lat];
      signals.push(coordinate);

      if (itemsProcessed % 6 === 0) {
        let arrowIcon = require(`../Assets/images/right2.png`).default;
        if (array[index+1]) {
          let next_coordinate = [array[index+1].dec_long, array[index+1].dec_lat];
          device['speed_km'] = element.speed_km;
          device['dec_long'] = element.dec_long;
          device['dec_lat'] = element.dec_lat;
          device['latest_geocode_sent_at'] = element.geocode_sent_at;

          let angle = mapInstance.current.calculateAngle(coordinate, next_coordinate);
          mapInstance.current.addArrow(coordinate, arrowIcon, angle, {...device});
        }
      }

      if (element.signal_mode === 4) {
        device['dec_long'] = element.dec_long;
        device['dec_lat'] = element.dec_lat;
        device['latest_geocode_sent_at'] = element.geocode_sent_at;
        let stopIcon = require(`../Assets/images/stop.gif`).default;
        mapInstance.current.addStop(coordinate, stopIcon, {...device});
      }

      if (element.signal_mode === 3 && first_start === false) {
        device['dec_long'] = element.dec_long;
        device['dec_lat'] = element.dec_lat;
        device['latest_geocode_sent_at'] = element.geocode_sent_at;
        let startIcon = require(`../Assets/images/flag_green.gif`).default;
        mapInstance.current.addStop(coordinate, startIcon, {...device});
        first_start = true;
      }

      if (itemsProcessed === array.length) {
        let lineColor = props.lineColor || 'rgba(50, 0, 255, .6)';
        let lineString = mapInstance.current.createLineString(signals);
        let featureLine = mapInstance.current.createLineFeature(5, lineColor);
        featureLine.setGeometry(lineString);
        featureLine.getGeometry().transform('EPSG:4326', 'EPSG:3857');

        let markerIcon = require(`../Assets/images/device-icons/car_${props.device.type}.gif`).default;

        if (signals[signals.length - 1]) {
          let _data = props.device;
          if (array.length > 1) {
            _data['total_km'] = (array[array.length - 1].mileage - array[0].mileage).toFixed(2);
          }
          else {
            _data['total_km'] = 0.0;
          }

          _data['dec_long'] = element.dec_long;
          _data['dec_lat'] = element.dec_lat;
          _data['latest_geocode_sent_at'] = element.geocode_sent_at || element.latest_geocode_sent_at;

          mapInstance.current.addMarker(signals[signals.length - 1], markerIcon, _data, "route_device");
        }

        mapInstance.current.fitMap();
        updateSize();
      }
    });

    if (lastDeviceId !== device.device_id) {
      lastDeviceId = device.device_id;
      mapInstance.current.setLayer(device.device_id);
    }

    window.addEventListener('resize', updateSize);

    // mark points
    props.points.forEach((point) => {
      if (point.dec_long === 0.0 || point.dec_lat === 0.0)
        return;

      let markerIcon = require(`../Assets/images/warning.png`).default;
      mapInstance.current.addLocation([point.dec_long, point.dec_lat], markerIcon, {locationName: point.speed_km});
    });

    // centralize
    if (mapInstance) {
      mapInstance.current.fitMap();
    }

    return () => {
      window.removeEventListener('resize', updateSize);
      first_start = false;
      signals = [];
    };

  }, [props.signals, props.points, props.maps, props.active]);

  return (
    <>
      <div id="map" style={{ width: "100%", height: `${size[1]}px` }} />
      <div id="popup" className="ol-popup">
        <a href="#" id="popup-closer" className="ol-popup-closer"/>
        <div id="popup-content"/>
      </div>
    </>
  );
};

SpeedReportsMap.defaultProps = {
  active: "horizont",
  maps: ["horizont"]
};

export default SpeedReportsMap;
