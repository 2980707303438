import './index.css';

import React, {useRef, useEffect} from 'react';
import HMap from './hmap';

var lastDeviceId = 0;

function TheftProtectionMap(props) {
    const longlat = [27.1699050, 38.4310990];
    const mapInstance = useRef(null);
    
    useEffect(() => {
        if (mapInstance.current === null && props.selectedDevice) {
            mapInstance.current = new HMap(longlat);
            mapInstance.current.register("onareadrawend", props.onDrawEnd);
            mapInstance.current.initMap(16, false, { measure: true, drawArea: true, deviceId: props.selectedDevice.device_id });
        }

        mapInstance.current.getVectorSource().clear();
        
        if (props.selectedDevice) {
            let device = props.selectedDevice;
            
            if (device.hide_status || device.dec_long === 0.0 || device.dec_lat === 0.0)
                return;

            device['address'] = sessionStorage.getItem(device.dec_lat + "_" + device.dec_long);

            let markerIcon = require(`../Assets/images/device-icons/car_${device.type}.gif`).default;
            mapInstance.current.addMarker([device.dec_long, device.dec_lat], markerIcon, device, "theft_protection");

            if (lastDeviceId !== device.device_id) {
                lastDeviceId = device.device_id;
                mapInstance.current.setLayer(device.device_id);
            }
        }
        
        if ("geojson" in props.zones) {
            let json = JSON.parse(props.zones.geojson);

            json.features.forEach((zone) => {
                mapInstance.current.addPolygon(zone.geometry.coordinates, zone.properties.name);
            });
        }

        if (mapInstance.current) {
            mapInstance.current.fitMap();
        }
        
    }, [props.selectedDevice, props.zones]);
        
    return (
        <>
            <div id="map" style={{ width: "100%", height: "100%" }}></div>
            <div id="popup" className="ol-popup">
                <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                <div id="popup-content"></div>
            </div>
        </>
    );
}

TheftProtectionMap.defaultProps = {
    devices: [],
    onDrawEnd: () => {},
    zones: {}
};

export default TheftProtectionMap;
