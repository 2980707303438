import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import {getDay, getDayName, getMonthName, getTime} from '../Assets/utils';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts)
}

function DailyDrivingKm(props) {

  const trips = props.data;
  let dates = [];
  let km = [];
  
  trips.forEach(trip => {
    dates.push(`${getDay(trip.start_time)} ${getMonthName(trip.start_time)} ${getDayName(trip.start_time)}`);
    km.push(parseInt(trip.km));
  });

  const options = {
    chart: {
      type: 'bar'
    },
    title: {
      text: 'Günlük Katedilen Yollar'
    },
    yAxis: {
      title: {
          text: 'Katedilen Yol'
      }
    },
    xAxis: {
      categories: dates,
    },
    tooltip: {
      backgroundColor: '#ffffff',
      xDateFormat: '%d-%m-%Y',
      borderWidth: 2,
      borderRadius: 7,
      headerFormat: `<span style='font-size:10px;'>{point.key}</span><br />`,
      pointFormat: `Katedilen Yol: <b>{point.y} km</b>`,
      useHTML: true,
      crosshairs: {
        dashStyle: 'dash'
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: true
      }
    },
    series: [
      {
        showInLegend: false,
        name: "Yol",
        data: km
      }
    ]
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default DailyDrivingKm;