const config = {
    // apiUrl: 'http://127.0.0.1:8000/',
    apiUrl: 'https://www.gps-takip-sistemi.com/api/v1/',
    client_id:'Dm1pS83VeHr3d33AWbBNqZHAwYC8v1I9c25PppWW',
    client_secret : 'R3mzUKrEoSuASrMkskDEHhNlJP3QbF4BZPPF3SLmPKkDYuvwSOWlvyC07fRg8VGAy5vBbcoYb1bMYPXNbrrcHVOJsaYIuDpIn6elTT0z1yCYxTi1Rnm6tTXheH4kifnw',
};

const options = {
    'tracking': 1,
    'live': 3,
    'all_tracks': 5,
    'velocity': 7,
    'tracking_route': 9,
    'unlimited_tracking': 11,
    'routing': 13,
    'daily': 15,
    'monthly': 17,
    'drive': 19,
    'driver': 21,
    'sensoren': 23,
    'speed': 25,
    'electronic': 27,
    'theft': 29,
    'phone_2': 31,
    'sms': 33,
    'service': 35,
    'device_groups': 37,
    'roaming1': 39,
    'roaming2': 41,
    'roaming3': 43,
    'zugriffsrechte': 45,
    'gsm_eplus': 47,
    'gsm_o2': 49,
    'gsm_orange': 51,
    'gsm_tmobile': 53,
    'gsm_vodafone': 55,
    'customers': 57,
    'garmin': 59,
    'data_flow': 61,
    'warranty': 63,
    'customer_grp': 65,
    'play': 67,
    'activation': 69,
    '15mb_limit_de': 70,
    '40mb_limit_de': 71,
    'tomtom': 1094,
    '24h_data': 2118,
    '7d_data': 2119,
    'signal_tabelle': 2120,
    'rent_a_car': 2121,
    'diebstahlschutz_indi': 2122,
    'meine_standorte': 2123,
    'auto_pilot': 2124,
    'mobil': 2125,
    'basarsoft_map': 2126,
    'gesperrt': 2127,
    'sms_server': 3151,
    '18_months': 4175
};

const optionPermissions = {
    '/home/location/:deviceId': options['tracking'],
    '/home/route/:deviceId': options['tracking_route'],
    '/home/anim-route/:deviceId': options['tracking_route'],
    '/home/live/:deviceId': options['live'],
    '/home/optimized-route/:deviceId': options['tracking_route'],
    '/home/speed-route/:deviceId': options['velocity'],
    '/home/graphs-reports/:deviceId': options['monthly'],
    '/routes': options['daily'],
};

export {options, optionPermissions};
export default config;