import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import GaugeChart from 'react-gauge-chart';
import {Modal} from "react-bootstrap";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MessageWindow from '../Commons/messageWindow';
import RentACarService from '../../services/RentACar';
import './index.css';

let licenseClassOptions = [
    {'value': "A1", 'label': 'A1'},
    {'value': "A2", 'label': 'A2'},
    {'value': "B", 'label': 'B'},
    {'value': "C", 'label': 'C'},
    {'value': "D", 'label': 'D'},
    {'value': "E", 'label': 'E'},
    {'value': "F", 'label': 'F'},
    {'value': "G", 'label': 'G'},
    {'value': "K", 'label': 'K'}
];
function RentACar(props) {

  const rentACarService = new RentACarService();

  const [driverName, setDriverName] = useState('');
  const [id, setID] = useState('');
  const [licenceNumber, setLicenceNumber] = useState('');
  const [licenceClass, setLicenceClass] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [rentDate, setRentDate] = useState('');
  const [rentBackDate, setRentBackDate] = useState('');
  const [vehicleKm, setVehicleKm] = useState('');
  const [fuelAmount, setFuelAmount] = useState(0);
  const [gaugaeFuelAmount, setGaugaeFuelAmount] = useState(0);
  const [equipment, setEquipment] = useState('');
  const [notes, setNotes] = useState('');
  const [rentPrice, setRentPrice] = useState('');
  const [accepted, setAccepted] = useState(false);
  const [rentACarId, setRentACarId] = useState(-1);
  const [status, setStatus] = useState('');
  const [guarantor, setGuarantor] = useState('');
  const [saveText, setSaveText] = useState('RENTACAR.ADD_NEW');
  const [rentACarAgreement, setRentACarAgreement] = useState(false);
  const [rentACarDetail, setRentACarDetail] = useState(false);
  const [detailRentACarRecord, setDetailRentACarRecord] = useState({});

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [oldRentRecord, setOldRentRecord] = useState([]);
  const [showMessage, setShowMessage] = useState({
    "show": false,
    "title": "",
    "message": ""
  });



  const deviceList = props.devices.map(item => {
    return {name: item['name'], device_id: item['device_id']}
  });

  const handleSelectVehicle = (selectedOption) => {
    setSelectedVehicle(selectedOption);
  };

  const setGaugeAmount = (fuelAmount) => {
    setFuelAmount(parseInt(fuelAmount));
    setGaugaeFuelAmount(parseInt(fuelAmount)/100);
  };

  const print = (table_id) => {
    var table, newWin, link;
    
    table = document.getElementById(table_id);
    newWin = window.open("", "Rent A Car", "width=767, height=500");
    
    newWin.document.write(table.outerHTML);

    console.log("newWin",newWin)

    link = newWin.document.createElement('link');
    link.id = "bootstrap";
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.media = 'all';
    link.href = 'assets/css/bootstrap.min.css';
    
    newWin.print();
  };

  const resetFormElements = () => {
    setDriverName('');
    setID('');
    setLicenceNumber('');
    setLicenceClass('');
    setBirthDate('');
    setPhoneNumber('');
    setAddress('');
    setGaugeAmount(0);
    setRentDate('');
    setRentBackDate('');
    setVehicleKm('');
    setEquipment('');
    setNotes('');
    setRentPrice('');
    setGuarantor('');
    setAccepted(false);
    setStatus('new');
    setRentACarId(-1);
    setSaveText('RENTACAR.ADD_NEW');
  }

  const loadRentAcar = async (id) => {
    await rentACarService.getRentAcar(id)
    .then(async(data) => {
        setDriverName(data.data.driver_name);
        setID(data.data.tckimlikno);
        setLicenceNumber(parseInt(data.data.licence_number));
        setLicenceClass(data.data.licence_class);
        setBirthDate(new Date(data.data.birth_day));
        setPhoneNumber(data.data.telno);
        setAddress(data.data.address);
        setRentDate(new Date(data.data.start_date));
        setRentBackDate(new Date(data.data.end_date));
        setVehicleKm(data.data.vehicle_km);
        setGaugeAmount(data.data.vehicle_fuel);
        setEquipment(data.data.equip);
        setNotes(data.data.notes);
        setRentPrice(data.data.price);
        setGuarantor(data.data.kefiller);
        setAccepted(false);
        setStatus('updata');
        setRentACarId(data.data.rentacar_id);
        setSaveText('RENTACAR.UPDATE');
    });
  };

  const removeRentAcar = async (id) => {
    await rentACarService.deleteDeviceRentAcar(id)
    .then(async(response) => {
        fetchData();
      });
  };

  const detailRentAcar = async (id) => {
    await rentACarService.getRentAcar(id)
    .then(async(response) => {
        setDetailRentACarRecord(response.data)
        setRentACarDetail(true);
    });
  }

  const handleSubmit = async () => {

    let sendData = {
        driver_name: driverName,
        tckimlikno: id,
        licence_number: licenceNumber,
        licence_class: licenceClass,
        birth_day: moment(birthDate).format("YYYY-MM-DD"),
        telno: phoneNumber,
        address: address,
        start_date: moment(rentDate).format("YYYY-MM-DD"),
        end_date: moment(rentBackDate).format("YYYY-MM-DD"),
        vehicle_km: vehicleKm,
        vehicle_fuel: fuelAmount,
        equip: equipment,
        notes: notes,
        price: rentPrice,
        kefiller: guarantor
    }

    if (rentACarId > 0) {//update
        await rentACarService.updateDeviceRentAcar(rentACarId, sendData)
        .then(async(response) => {
            resetFormElements();
            fetchData();
        });
    } else {//add
        await rentACarService.insertDeviceRentAcar(selectedVehicle.value, sendData)
        .then(async(response) => {
            resetFormElements();
            fetchData();
        });
    }
  };

  const fetchData = async () => {
    await rentACarService.getDeviceRentAcar(parseInt(selectedVehicle.value))
        .then(async(response) => {
            setOldRentRecord(response.data)
        });
  };

  useEffect(() => {
    if (selectedVehicle) {
      fetchData();
    }
  }, [selectedVehicle]);
  
  return (
    <>
      <MessageWindow
        size="md"
        show={showMessage.show}
        onHide={() => setShowMessage({...showMessage, show: false})}
        title={showMessage.title}
        message={showMessage.message} />

      <div className="sidebar">
            <h4><FormattedMessage id="MENU.RENT"/></h4>
            <div className="col-md-3">
                <Select
                    value={selectedVehicle}
                    onChange={handleSelectVehicle}
                    placeholder={<FormattedMessage id="GENERAL.PICK_DEVICE"/>}
                    options={deviceList.map((device) => {
                    return {value: device.device_id, label: device.name}
                    })}
                /><br />
            </div>
            <hr className="no-mobile" style={{height:50}}/>
    </div>
    <div className="content">

        <div className="row">
                <div className="col-md-6">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th colSpan="4"><FormattedMessage id="RENTACAR.NEW_RENTACAR"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.DRIVER_NAME"/></td>
                            <td colSpan="2">
                                <FormattedMessage id="RENTACAR.DRIVER_NAME">
                                {
                                    placeholder => (
                                        <p style={{marginBottom:-1}}><input className="form-control" type="text" onChange={(event) => setDriverName(event.target.value)} value={driverName}  placeholder={placeholder}/></p>
                                    )
                                }
                                </FormattedMessage>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.IDENTITY_NO"/></td>
                            <td colSpan="2"><InputMask mask="99999999999" className="form-control" type="text" onChange={(event) => setID(event.target.value)} value={id}/></td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.LICENSE_NO"/></td>
                            <td colSpan="2">
                                <FormattedMessage id="RENTACAR.LICENSE_NO">
                                    {
                                        placeholder => (
                                            <p style={{marginBottom:-1}}><input className="form-control" type="number" onChange={(event) => setLicenceNumber(event.target.value)} value={licenceNumber} placeholder={placeholder}/></p>
                                        )
                                    }
                                </FormattedMessage>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.LICENSE_CLASS"/></td>
                            <td colSpan="2">
                                <select className="form-control" onChange={(event) => setLicenceClass(event.target.value)} value={licenceClass} style={{height:34}}>
                                    <FormattedMessage id="RENTACAR.CHOOSE">
                                        {(message) => <option value=''>{message}</option>}
                                    </FormattedMessage>
                                    {
                                        licenseClassOptions.map((license, index) => {
                                            return (
                                                <option key={index} id={'license-'+index} value={license.value}>
                                                    {license.label}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.BIRTH_DATE"/></td>
                            <td>
                                    <DatePicker
                                        value={birthDate}
                                        todayButton="Today"
                                        maxDate={new Date()}
                                        dateFormat="dd-MM-yyyy"
                                        selected={birthDate}
                                        wrapperClassName="react-datepicker-wrapper-full-width"
                                        onChange={(date)=> setBirthDate(date)} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.PHONE_NO"/></td>
                            <td colSpan="2"><InputMask mask="(999) 999-99-99" className="form-control" type="text" onChange={(event) => setPhoneNumber(event.target.value)} value={phoneNumber}/></td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.ADDRESS"/></td>
                            <td colSpan="2">
                                <FormattedMessage id="RENTACAR.ADDRESS">
                                    {(placeholder) =>  <textarea className="form-control" style={{resize:"none",height:100}}
                                                            placeholder={placeholder}
                                                            onChange={(event) => setAddress(event.target.value)} value={address}>
                                                        </textarea>}
                                </FormattedMessage>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.RENT_DATE"/></td>
                            <td colSpan="2">
                                    <DatePicker
                                        value={rentDate}
                                        todayButton="Today"
                                        maxDate={new Date()}
                                        dateFormat="dd-MM-yyyy"
                                        selected={rentDate}
                                        wrapperClassName="react-datepicker-wrapper-full-width"
                                        onChange={(date)=> setRentDate(date)} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.RENT_BACK_DATE"/></td>
                            <td colSpan="2">
                                    <DatePicker
                                        value={rentBackDate}
                                        todayButton="Today"
                                        maxDate={new Date()}
                                        dateFormat="dd-MM-yyyy"
                                        selected={rentBackDate}
                                        wrapperClassName="react-datepicker-wrapper-full-width"
                                        onChange={(date)=> setRentBackDate(date)} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.VEHICLE_KM"/></td>
                            <td colSpan="2">
                                <FormattedMessage id="RENTACAR.VEHICLE_KM">
                                    {(placeholder) => <input type="number"
                                                        className="form-control" placeholder={placeholder}
                                                        onChange={(event) => setVehicleKm(event.target.value)} value={vehicleKm} min="0"/>}
                                </FormattedMessage>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.FUEL_AMOUNT"/> (%)</td>
                            <td className="fuel-canvas">
                                <FormattedMessage id="RENTACAR.FUEL_AMOUNT">
                                    {(placeholder) => <input type="number"
                                                        className="form-control" placeholder={placeholder}
                                                        onChange={(event) => setGaugeAmount(event.target.value)} value={fuelAmount} min="0"/>}
                                </FormattedMessage>
                                <GaugeChart id="gauge-chart2" 
                                    nrOfLevels={4} 
                                    textColor="#495057"
                                    percent={gaugaeFuelAmount} 
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.EQUIPMENT"/></td>
                            <td colSpan="2">
                                <FormattedMessage id="RENTACAR.EQUIPMENT">
                                    {(placeholder) =>  <textarea className="form-control" style={{resize:"none",height:100}} 
                                                            placeholder={placeholder}
                                                            onChange={(event) => setEquipment(event.target.value)} value={equipment}>
                                                        </textarea>}
                                </FormattedMessage>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.NOTES"/></td>
                            <td colSpan="2">
                                <FormattedMessage id="RENTACAR.NOTES">
                                    {(placeholder) =>   <textarea className="form-control" style={{resize:"none",height:100}}
                                                            placeholder={placeholder}
                                                            onChange={(event) => setNotes(event.target.value)} value={notes}>
                                                        </textarea>}
                                </FormattedMessage>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><FormattedMessage id="RENTACAR.RENT_PRICE"/></td>
                            <td colSpan="2">
                                <FormattedMessage id="RENTACAR.RENT_PRICE">
                                    {(placeholder) =>   <input type="number"
                                                            className="form-control" placeholder={placeholder}
                                                            onChange={(event) => setRentPrice(event.target.value)} value={rentPrice} min="0"/>}
                                </FormattedMessage>
                            </td>
                        </tr>
                        </tbody>

                        <tfoot>
                        <tr>
                            <td colSpan="4"><input onChange={(event) => setAccepted(event.target.value)} value={accepted} type="checkbox" id="all1"/> 
                                <label>
                                    <a href="#" data-toggle="modal" onClick={() => setRentACarAgreement(true)}><FormattedMessage id="RENTACAR.READ_ACCEPT_AGR1"/></a>
                                    <FormattedMessage id="RENTACAR.READ_ACCEPT_AGR2"/>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4" style={{textAlign:"center"}}>
                                <input type="hidden" name="status" ng-model="formElements.status"/>
                                <button style={{margin:0}} type="submit" className="btn btn-success" onClick={() => handleSubmit()}><FormattedMessage id={saveText}/></button>
                            </td>
                        </tr>
                        </tfoot>

                    </table>
                </div>
            <div className="col-md-6">
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th colSpan="4"><FormattedMessage id="RENTACAR.OLD_RENTACAR"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th><FormattedMessage id="RENTACAR.RENTER"/></th>
                        <th><FormattedMessage id="RENTACAR.RENT_DATE"/></th>
                        <th><FormattedMessage id="RENTACAR.RENT_BACK_DATE"/></th>
                        <th>#</th>
                    </tr>
                    {oldRentRecord.map((record,index) => {
                        return  <tr key={'record-' + index}>
                                    <td>{record.driver_name}</td>
                                    <td>{record.start_date}</td>
                                    <td>{record.end_date}</td>
                                    <td>
                                        <span style={{cursor:"pointer"}} onClick={() => detailRentAcar(record.rentacar_id)}><FontAwesomeIcon icon="search"/></span>&nbsp;
                                        <span style={{cursor:"pointer"}} onClick={() => loadRentAcar(record.rentacar_id)}><FontAwesomeIcon icon="edit"/></span>&nbsp;
                                        <span style={{cursor:"pointer"}} onClick={() => removeRentAcar(record.rentacar_id)}><FontAwesomeIcon icon="times"/></span>&nbsp;
                                    </td>
                                </tr>
                    })}
                    </tbody>
                    <tfoot>
                    </tfoot>
                </table>
            </div>
            <div className="clearfix"></div>
        </div>
    </div>
    <Modal
        size="lg"
        show={rentACarDetail}
        aria-labelledby="rentACarDetail"
        onHide={() => setRentACarDetail(false)}>
        
        <Modal.Header closeButton>
            <Modal.Title id="rentACarDetail">
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="ngdialog-message">
            <div id="rentContent" className="ngdialog-message">
                <div className="row">
                    <div className="col-md-12">
                        <h3>SÖZLEŞME ŞARTLARI</h3>
                        <ol style={{marginLeft: 20,marginBottom: 30}}>
                            <li>T.C kanunlarına ve yasalarına göre davranılacak</li>
                            <li>Esrar, eroin, uyuşturucu ve kaçak maddeler taşınmayacak</li>
                            <li>Silah, yanıcı ve patlayıcı maddeler taşınmayacak</li>
                            <li>Kasten adam öldürme, hırsızlık, kapkaç ve her türlü terörist eylemlerde kullanılmayacak</li>
                            <li>Araç alkollü kullanılmayacak</li>
                            <li>Trafik kurallarına uyulacak</li>
                            <li>Araç kaza yaptığında sanayide veya serviste kaldığı sürece günlük 100 TL kiralayandan alınacak
                            </li>
                            <li>Kaza raporunda kiralayan kusurlu ise maddi hasar kiralayandan tahsil edilecektir</li>
                            <li>Kiralamada aracın teslim zamanına dikkat edilmelidir</li>
                            <li>İmzalanan sözleşmedeki senet, trafik cezalarından doğabilecek bedel sebebiyle 30 gün sonra
                                kiracıya teslim edilir
                            </li>
                            <li>Araç temiz verilir ve temiz teslim alınır, ziftli veya kirli teslim edilirse 100 TL kiralayandan
                                alınır 
                            </li>
                            <li>Hız limiti şehir içi 50 km şehir dışı 90 km olacaktır</li>
                            <li>24 saat içinde 300 km yol kullanım mesafesini aşan kiracıdan 2 günlük ücret tahsil edilir</li>
                            <li>Aracın geç getirilmesi halinde en az 3 saat önceden bilgi verilmesi gerekmektedir.</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <th colSpan="2" align="left">
                                            {selectedVehicle !== null ? selectedVehicle.label : ''} cihazı {detailRentACarRecord.end_date} tarihli araç kiralama bilgileri
                                        </th>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.DRIVER_NAME"/></td>
                                        <td><label>{detailRentACarRecord.driver_name}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.IDENTITY_NO"/></td>
                                        <td><label>{detailRentACarRecord.tckimlikno}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.LICENSE_NO"/></td>
                                        <td><label>{detailRentACarRecord.licence_number}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.LICENSE_CLASS"/></td>
                                        <td><label>{detailRentACarRecord.licence_class}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.BIRTH_DATE"/></td>
                                        <td>
                                            {
                                                detailRentACarRecord.birth_day !== 'None' ? <label ng-if="formElements.birth_day != 'None'">{detailRentACarRecord.birth_day}</label> : null
                                            }
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.PHONE_NO"/></td>
                                        <td><label>{detailRentACarRecord.telno}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.ADDRESS"/></td>
                                        <td><label>{detailRentACarRecord.address}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.RENT_DATE"/></td>
                                        <td><label>{detailRentACarRecord.start_date}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.RENT_BACK_DATE"/></td>
                                        <td><label>{detailRentACarRecord.end_date}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.VEHICLE_KM"/></td>
                                        <td><label>{detailRentACarRecord.vehicle_km}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.FUEL_AMOUNT"/></td>
                                        <td><label>{detailRentACarRecord.vehicle_fuel}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.EQUIPMENT"/></td>
                                        <td><label>{detailRentACarRecord.equip}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.NOTES"/></td>
                                        <td><label>{detailRentACarRecord.notes}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.RENT_PRICE"/></td>
                                        <td><label>{detailRentACarRecord.price}</label></td>
                                    </tr>
                                    <tr>
                                        <td><FormattedMessage id="RENTACAR.BAILS"/></td>
                                        <td><label>{detailRentACarRecord.kefiller}</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ngdialog-buttons">
                <div className="form-group">
                    <button type="button" className="btn btn-warning pull-right" onClick={() => setRentACarDetail(false)}><FormattedMessage id="GENERAL.TURN_BACK"/></button>
                    <button type="button" className="btn btn-danger pull-right" onClick={() => print('rentContent')}><FormattedMessage id="GENERAL.PRINT"/></button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
    <Modal
        size="lg"
        show={rentACarAgreement}
        aria-labelledby="rentACarAgreement"
        onHide={ () => setRentACarAgreement(false)}>
        
        <Modal.Header closeButton>
            <Modal.Title id="rentACarAgreement">
                <h3>SÖZLEŞME ŞARTLARI</h3>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div>
                <ol className="pl-sm">
                    <li>T.C kanunlarına ve yasalarına göre davranılacak</li>
                    <li>Esrar, eroin, uyuşturucu ve kaçak maddeler taşınmayacak</li>
                    <li>Silah, yanıcı ve patlayıcı maddeler taşınmayacak</li>
                    <li>Kasten adam öldürme, hırsızlık, kapkaç ve her türlü terörist eylemlerde kullanılmayacak</li>
                    <li>Araç alkollü kullanılmayacak</li>
                    <li>Trafik kurallarına uyulacak</li>
                    <li>Araç kaza yaptığında sanayide veya serviste kaldığı sürece günlük 100 TL kiralayandan alınacak
                    </li>
                    <li>Kaza raporunda kiralayan kusurlu ise maddi hasar kiralayandan tahsil edilecektir</li>
                    <li>Kiralamada aracın teslim zamanına dikkat edilmelidir</li>
                    <li>İmzalanan sözleşmedeki senet, trafik cezalarından doğabilecek bedel sebebiyle 30 gün sonra
                        kiracıya teslim edilir
                    </li>
                    <li>Araç temiz verilir ve temiz teslim alınır, ziftli veya kirli teslim edilirse 100 TL kiralayandan
                        alınır
                    </li>
                    <li>Hız limiti şehir içi 50 km şehir dışı 90 km olacaktır</li>
                    <li>24 saat içinde 300 km yol kullanım mesafesini aşan kiracıdan 2 günlük ücret tahsil edilir</li>
                    <li>Aracın geç getirilmesi halinde en az 3 saat önceden bilgi verilmesi gerekmektedir.</li>
                </ol>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => setRentACarAgreement(false)}><FormattedMessage id="GENERAL.CLOSE"/></button>
            </div>
        </Modal.Body>
    </Modal>
    </>
  );
}

const mapStateToProps = function(state) {
  return {
    devices: state.deviceListReducer.devices
  }
};

export default connect(mapStateToProps)(RentACar);
