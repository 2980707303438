import React, {useState, useEffect} from 'react';
import {Form, Button} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import DatePicker from "react-datepicker";
import moment from 'moment';

import closeIcon from '../Assets/images/close.png';

function Step4(props) {

    const [disabled, setDisabled] = useState(true);
    const [zones, setZones] = useState({
        "features": [],
        "saveAll": 0
    });

    const handleDelete = (id) => {
        let features = zones.features.filter(item => item.id !== id);
        let data = {
            "device_id": props.deviceId,
            "json_avg_lat": zones.json_avg_lat,
            "json_avg_lon": zones.json_avg_lon,
            "json_max_lat": zones.json_max_lat,
            "json_max_lon": zones.json_max_lon,
            "json_min_lat": zones.json_min_lat,
            "json_min_lon": zones.json_min_lon
        };

        let json = {
            "features": features,
            ...data
        };
        setZones(json);
        props.onDelete(json);
    };

    const handleChange = (event, _date, _id, _index) => {
        let features = zones.features;
        let data = {
            "device_id": props.deviceId,
            "json_avg_lat": zones.json_avg_lat,
            "json_avg_lon": zones.json_avg_lon,
            "json_max_lat": zones.json_max_lat,
            "json_max_lon": zones.json_max_lon,
            "json_min_lat": zones.json_min_lat,
            "json_min_lon": zones.json_min_lon
        };
        
        if (!_date) {
            let id = event.target.id.split("-");
            let key = id[0];
            let index = id[1];
            
            if (event.target.type === "checkbox") {
                features[index].properties[key] = event.target.checked.toString();
            }
            else if (event.target.type === "text") {
                features[index].properties[key] = event.target.value;
            }
        }
        else {
            features[_index].properties[_id] =  moment(_date).format("HH:mm");
        }

        setZones({
            "features": features,
            ...data
        });
    };

    useEffect(() => {
        
        setZones({
            "features": [],
        });

        if ("geojson" in props.zones) {
            setZones({...zones, ...JSON.parse(props.zones.geojson)})
        }

        if (props.deviceId) {
            setDisabled(false);
        }
    }, [props.zones]);

    return (
        <>
            <div className="theft-title"><FormattedMessage id="THEFT.STEP4"/></div>
                <FormattedMessage id="THEFT.STEP2_INFO"/>
            <table cellPadding="2px">
            <thead>
                    <tr style={{fontSize: 12}}>
                        <th style={{
                            textAlign: 'center',
                            color: '#31708f',
                            backgroundColor: '#eee',
                            width: 210
                            }}> <FormattedMessage id="GENERAL.NAME"/>
                        </th>
                        
                        <th style={{
                            textAlign: 'center',
                            color: '#31708f',
                            backgroundColor: '#eee',
                            width: 30
                            }}> <FormattedMessage id="GENERAL.ACTIVE"/>
                        </th>

                        <th style={{
                            textAlign: 'center',
                            color: '#31708f',
                            backgroundColor: '#eee',
                            width: 40,
                            maxWidth: 40
                            }}> <FormattedMessage id="GENERAL.START"/>
                        </th>

                        <th style={{
                            textAlign: 'center',
                            color: '#31708f',
                            backgroundColor: '#eee',
                            width: 40,
                            maxWidth: 40
                            }}> <FormattedMessage id="GENERAL.STOP"/>
                        </th>

                        <th style={{
                            textAlign: 'center',
                            color: '#31708f',
                            backgroundColor: '#eee',
                            width: 30
                            }}> <FormattedMessage id="THEFT.NO_ENTER_ZONE"/>
                        </th>

                        <th style={{
                            textAlign: 'center',
                            color: '#31708f',
                            backgroundColor: '#eee',
                            width: 30
                            }}> <FormattedMessage id="INOUT.ENTRY"/>
                        </th>
                        
                        <th style={{
                            textAlign: 'center',
                            color: '#31708f',
                            backgroundColor: '#eee',
                            width: 30
                            }}> <FormattedMessage id="INOUT.EXIT"/>
                        </th>

                        <th style={{
                            textAlign: 'center',
                            color: '#31708f',
                            backgroundColor: '#eee',
                            width: 30
                            }}> <FormattedMessage id="GENERAL.MAIL"/>
                        </th>
                        <th style={{
                            textAlign: 'center',
                            color: '#31708f',
                            backgroundColor: '#eee',
                            width: 30
                            }}>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {zones["features"].map((item, index) => {
                        return (
                            <tr key={index} style={{textAlign: "center", border:"none", borderTop: '1px solid #DEDEDE'}}>
                                <td>
                                    <input
                                        id={`name-${index}`}
                                        onChange={handleChange}
                                        type="text"
                                        value={item.properties.name}
                                        style={{width: "100%"}} />
                                </td>
                                <td>
                                    <Form.Check
                                        id={`active-${index}`}
                                        onChange={handleChange}
                                        checked={item.properties.active === "true" ? true: false}
                                        type="checkbox" />
                                </td>
                                <td>
                                    <DatePicker
                                        value={item.properties.start_hour} showTimeSelect
                                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                                        onChange={date => handleChange(window.event, date, "start_hour", index)}
                                        dateFormat="HH:mm" timeFormat="HH:mm" />
                                </td>
                                <td>
                                    <DatePicker
                                        value={item.properties.end_hour} showTimeSelect
                                        showTimeSelectOnly timeIntervals={1} timeCaption="Time"
                                        onChange={date => handleChange(window.event, date, "end_hour", index)}
                                        dateFormat="HH:mm" timeFormat="HH:mm" />
                                </td>
                                <td>
                                    <Form.Check
                                        id={`ban_area-${index}`}
                                        onChange={handleChange}
                                        checked={item.properties.ban_area === "true" ? true: false}
                                        type="checkbox" />
                                </td>
                                <td>
                                    <Form.Check
                                        id={`in-${index}`}
                                        onChange={handleChange}
                                        checked={item.properties.in === "true" ? true: false}
                                        type="checkbox" />
                                </td>
                                <td>
                                    <Form.Check
                                        id={`out-${index}`}
                                        onChange={handleChange}
                                        checked={item.properties.out === "true" ? true: false}
                                        type="checkbox" />
                                </td>
                                <td>
                                    <Form.Check
                                        id={`email-${index}`}
                                        onChange={handleChange}
                                        checked={item.properties.email === "true" ? true: false}
                                        type="checkbox" />
                                </td>
                                <td>
                                    <img onClick={() => handleDelete(item.id)}
                                    style={{cursor: 'pointer'}} src={closeIcon} alt="" />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Form.Check
                type="switch"
                id="saveAll_step4"
                onChange={(event) => setZones({...zones, saveAll:event.target.checked ? 1:0})}
                checked={Boolean(zones.saveAll)}
                disabled={disabled}
                label={<FormattedMessage id="THEFT.SAVEALL"/>}/><br />
            <Button
                disabled={disabled}
                style={{marginTop: 10}}
                onClick={() => props.onSubmit(zones)}
                variant="success"><FormattedMessage id="GENERAL.SAVE"/></Button>
        </>
    )
};

export default Step4;